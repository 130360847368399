import React, { PureComponent } from "react";
import { connect } from "react-redux";
import {
  Tabs,
  Select,
  Input,
  Row,
  Col,
  Timeline,
  Menu,
  Spin,
  Empty,
  Collapse,
  Icon
} from "antd";
import PositionVacantModal from "store/reducers/positionVacantM";
import InternDetail from "pages/TalentPool/Intern/Detail";
import SocialDetail from "pages/TalentPool/SocialCandidate/Detail";
import fileDownload from "utils/fileDownload.js";
import CustomIcon from "components/CustomIcon";
import RecommendModify from "./recommend-modify";
import styles from "./progress-module.scss";

const TabPane = Tabs.TabPane;
const Option = Select.Option;
const TextArea = Input.TextArea;
const InputGroup = Input.Group;
const Panel = Collapse.Panel;

/**
 * 人选进展操作流
 */
@connect(
  ({ PositionVacantModal, user }) => {
    return { ...PositionVacantModal, roles: user.roles };
  },
  {
    ...PositionVacantModal.actions
  }
)
export default class ProgressProfileRecord extends PureComponent {
  state = {
    reverse: false, // 人选进展当前选择状态
    statusColor: [
      "#38a4e9",
      "#38a4e9",
      "#fa9965",
      "#52c41a",
      "#5fccc3",
      "#ff4b54",
      "#999999",
      "#999999"
    ], //0.已推荐 1.已预约 2.安排面试 3.面试通过 4.offer时间 5.onboard 6.已淘汰
    propsInfo: {} //修改推荐弹窗所需信息
  };
  //判断是否是编辑 row信息
  isEdit = row => {
    const {create_by,status}=row;
    if (status === -1||(status === 0&&!!create_by) || status === 2 || status === 3 || status === 4) return true;
    return false;
  };
  //赋值时间
  goEdit = (item,index) => {
    this.setState({
      propsInfo: {...item,index}
    });
    this.isClose();
  };
  //打开或关闭
  isClose = () => {
    const { setUpdateState, recommendModifyStatus } = this.props;
    setUpdateState({
      filed: "recommendModifyStatus",
      value: !recommendModifyStatus
    });
  };

  render() {
    const { currentType, statusColor } = this.state;
    const {
      positionDetail = {},
      candidatePersonProgress,
      candidateProgressLoading,
      className = ""
    } = this.props;
    return (
      <div className={`${className} profile-record-container`}>
        <Spin spinning={candidateProgressLoading}>
          <div className="profile-record-header">跟进记录</div>
          {candidatePersonProgress.list ? (
            <div className="profile-record-content">
              <Timeline reverse={this.state.reverse}>
                {candidatePersonProgress.list.map((itemPerson, index) => {
                  return (
                    <Timeline.Item key={index}>
                      <div className="progress clear">
                        <div
                          className="color-status left"
                          style={{
                            background: `${
                              itemPerson.status !== -1 &&
                              itemPerson.status !== -2 &&
                              itemPerson.status !== 99
                                ? statusColor[itemPerson.status]
                                : "#5080ef"
                            }`
                          }}
                        >
                          {itemPerson.status_name}
                        </div>
                        <div className="right">
                          <p className="mark" style={{ marginBottom: "4px" }}>
                            <span title={itemPerson.event_name}>
                              {itemPerson.event_name}
                            </span>
                            {itemPerson.status !== -1 && itemPerson.remark && (
                              <span title={itemPerson.remark}>
                                ：{itemPerson.remark}
                              </span>
                            )}
                          </p>
                          {itemPerson.status === -1 && itemPerson.remark && (
                            <p
                              className="mark"
                              style={{ marginBottom: "4px" }}
                              title={itemPerson.remark}
                            >
                              备注：{itemPerson.remark}
                            </p>
                          )}
                          <p className="time" style={{ marginBottom: "4px" }}>
                            {itemPerson.time_name}：{itemPerson.event_time_str}
                            {this.isEdit(itemPerson) && (
                              <span>
                                <Icon
                                  type="edit"
                                  style={{ marginLeft: 5, cursor: "pointer" }}
                                  onClick={this.goEdit.bind(this, itemPerson,index)}
                                />
                              </span>
                            )}
                          </p>
                          <p className="time">
                            {!!itemPerson.communication_length && (
                              <span>
                                时长：{itemPerson.communication_length}
                              </span>
                            )}
                          </p>
                        </div>
                      </div>
                    </Timeline.Item>
                  );
                })}
              </Timeline>
            </div>
          ) : (
            <Empty
              style={{ marginTop: "48px" }}
              image={Empty.PRESENTED_IMAGE_SIMPLE}
            />
          )}
        </Spin>
        <RecommendModify
          propsInfo={this.state.propsInfo}
          isClose={this.isClose}
        />
        <style jsx>{styles}</style>
      </div>
    );
  }
}
