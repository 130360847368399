import React, { PureComponent } from "react";

/**
 * 更新简历完成度
 * @param {*} CandidateBlock
 */
export default function updateCompletewrapper(CandidateBlock) {
  return class extends PureComponent {
    toUpdateComplete = complete => {
      if (complete) this.props.updateBaseInfo({ compete: complete });
    };

    baseUpdateInfo = data => {
      if (data) {
        this.props.updateBaseInfo({
          compete: data.compete,
          education: data.education
        });
      }
    };

    eduUpdateBaseInfo = data => {
      if (data) {
        this.props.updateBaseInfo({
          compete: data.compete,
          education: data.education,
          education_label: data.education
        });
      }
    };

    render() {
      const props = {
        ...this.props,
        toUpdateComplete: this.toUpdateComplete,
        eduUpdateBaseInfo: this.eduUpdateBaseInfo,
        baseUpdateInfo: this.baseUpdateInfo
      };
      return <CandidateBlock {...props} />;
    }
  };
}
