import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { Row, Col, Form, Input, DatePicker, Select, Radio } from "antd";
import { EditViewSingle, EditViewMulti } from "components/EditView";
import socialCandModel from "store/reducers/socialCandModel";
import { commonData } from "services/commonService";
import moment from "moment";
import _ from "underscore";
import { MonthPickerSofar } from "components/DatePickerSofarWrap";
import updateCompletewrapper from "./update-complete-wrapper";

const RadioGroup = Radio.Group;
const FormItem = Form.Item;
const { MonthPicker } = DatePicker;
const Option = Select.Option;

export function disabledEndDate(start_date, current) {
  if (!current) {
    return false;
  }
  if (!start_date || !current) {
    return false;
  }

  return current.valueOf() <= start_date.valueOf();
}

export function disabledStartDate(end_date, current) {
  if (!current) {
    return false;
  }
  if (!end_date || !current) {
    return false;
  }

  return current.valueOf() >= end_date.valueOf();
}

function mapPropsToFields(props) {
  const { item } = props;
  const { end_time } = item;
  const formFileds = _.mapObject(item, function(val, key) {
    return Form.createFormField({
      // value: val && val
      ...val,
      value: val && val.value
    });
  });
  // formFileds.begin_time = Form.createFormField({
  //   value: item.begin_time ? moment(item.begin_time, "YYYY/MM") : undefined
  // });
  // formFileds.end_time = Form.createFormField({
  //   value: end_time
  //     ? /\d{4}\/\d{2}/.test(end_time)
  //       ? moment(end_time, "YYYY/MM")
  //       : end_time
  //     : undefined
  // });
  return formFileds;
}

export function fieldsToProps(values) {
  const { begin_time, end_time } = values;
  values.begin_time = begin_time && begin_time.format("YYYY/MM");
  if (end_time) {
    values.end_time =
      typeof end_time !== "string" ? end_time.format("YYYY/MM") : end_time;
  }
  return values;
}

export function onFieldsChange(props, changedFields) {
  // let fields = {};
  // _.keys(changedFields).forEach(item => {
  //   fields[item] = changedFields[item].value;
  // })
  props.onChange(changedFields);
}

export function createEducationEditForm(mapPropsToFields, fieldsToProps, onFieldsChange) {
  return Form.create({
    mapPropsToFields,
    onFieldsChange
  })(
    class EditForm extends PureComponent {
      state = {
        profileEducationOptions: [],
        beginTime: undefined,
        endTime: undefined
      };
      componentDidMount() {
        commonData("profile_education").then(options => {
          this.setState({
            profileEducationOptions: options.profile_education
          });
        });
      }

      handleSubmit = () => {
        return new Promise((resolve, reject) => {
          this.props.form.validateFieldsAndScroll({scroll: {offsetBottom: 60}},(err, values) => {
            if (!err) {
              resolve(fieldsToProps(values));
            } else {
              reject(err);
            }
          });
        });
      };

      onDateChange = (field, date) => {
        this.setState({ [field]: date });
      };

      render() {
        const formItemLayout = {
          labelCol: { span: 5 },
          wrapperCol: { span: 19 }
        };

        const { getFieldDecorator } = this.props.form;
        const { profileEducationOptions, beginTime, endTime } = this.state;
        return (
          <Form className="edit-form" layout="horizontal">
            <Row className="one" gutter={35}>
              <Col span={12}>
                <FormItem
                  label="学校"
                  {...formItemLayout}
                >
                  {getFieldDecorator("school_name", {
                    rules: [
                      {
                        required: true,
                        max: 60,
                        message: "请输入学校,且最大60个字符"
                      }
                    ]
                  })(<Input placeholder="请输入学校" size="large" />)}
                </FormItem>
              </Col>
              <Col span={12}>
                <div className="form-item-period clear">
                  <FormItem
                    label="起止时间"
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 15 }}
                  >
                    {getFieldDecorator("begin_time", {
                      rules: [
                        {
                          required: true,
                          message: "请选择起止时间"
                        }
                      ]
                    })(
                      <MonthPicker
                        size="large"
                        format="YYYY/MM"
                        disabledDate={disabledStartDate.bind(null, endTime)}
                        onChange={this.onDateChange.bind(this, "beginTime")}
                      />
                    )}
                  </FormItem>
                  &nbsp;&nbsp;~&nbsp;&nbsp;
                  <FormItem>
                    {getFieldDecorator("end_time", {
                      rules: [
                        {
                          required: true,
                          message: "请选择起止时间"
                        }
                      ]
                    })(
                      <MonthPickerSofar
                        size="large"
                        format="YYYY/MM"
                        placeholder="请选择日期"
                        disabledDate={disabledEndDate.bind(null, beginTime)}
                        onChange={this.onDateChange.bind(this, "endTime")}
                      />
                    )}
                  </FormItem>
                </div>
              </Col>
            </Row>
            <Row gutter={35}>
              <Col span={12}>
                <FormItem
                  label="学历"
                  {...formItemLayout}
                >
                  {getFieldDecorator("education", {
                    rules: [
                      {
                        required: true,
                        message: "请选择学历"
                      }
                    ]
                  })(
                    <Select placeholder="请选择学历" size="large">
                      {profileEducationOptions &&
                        profileEducationOptions.map(item => (
                          <Option value={item.value} key={item.value}>
                            {item.label}
                          </Option>
                        ))}
                    </Select>
                  )}
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem
                  label="专业"
                  {...formItemLayout}
                >
                  {getFieldDecorator("major_name", {
                    rules: [
                      {
                        required: true,
                        max: 60,
                        message: "请输入专业,且最大60个字符"
                      }
                    ]
                  })(<Input placeholder="请输入专业" size="large" />)}
                </FormItem>
              </Col>
            </Row>
            <Row gutter={35}>
              <Col span={12}>
                <FormItem
                  label="统招"
                  {...formItemLayout}
                >
                  {getFieldDecorator("is_unified", {
                    rules: []
                  })(
                    <RadioGroup>
                      <Radio value={2}>否</Radio>
                      <Radio value={1}>是</Radio>
                    </RadioGroup>
                  )}
                </FormItem>
              </Col>
            </Row>
            <style jsx global>{`
              .form-item-period {
                .ant-form-item {
                  display: inline-block;
                }
                .ant-form-item:first-child {
                  width: 58%;
                  .ant-form-item-label {
                    width: 35%;
                  }
                }
                .ant-form-item:last-child {
                  width: 34%;
                }
              }
            `}</style>
          </Form>
        );
      }
    }
  );
}

export const EducationEditForm = createEducationEditForm(
  mapPropsToFields,
  fieldsToProps
);

export const newEducationEditForm = createEducationEditForm(
  mapPropsToFields,
  fieldsToProps,
  onFieldsChange
);

export function EducationView(props) {
  let { item } = props;
  item = _.mapObject(item, (val, key) => {
    return val && val.value;
  });
  return (
    <div className="education-view">
      <Row gutter={5}>
        <Col span={4}>
          {item.begin_time.format("YYYY/MM")}-
          {typeof item.end_time === "string"
            ? item.end_time
            : item.end_time.format("YYYY/MM")}
        </Col>
        <Col span={18}>
          {item.school_name} | {item.major_name} | {item.education}
        </Col>
      </Row>
      <style jsx>
        {`
          .education-view {
            :global(:ant-col:first-child) {
              color: #666;
            }
          }
        `}
      </style>
    </div>
  );
}

class Education extends PureComponent {
  state = {
    topPlaceholderBg: "#ffffff",
    bottomPlaceholderBg: "#ffffff"
  };

  formRef = [];

  setPlaceholderColor = (index, color) => {
    const { educations } = this.props;
    index === educations.length - 1
      ? this.setState({ bottomPlaceholderBg: color })
      : !1;
    index === 0 ? this.setState({ topPlaceholderBg: color }) : !1;
  };

  onEdit = index => {
    this.setPlaceholderColor(index, "#f0f0f0");
    this.props.updateEducation({ index, values: { isEdit: true } });
  };

  onCancel = index => {
    this.setPlaceholderColor(index, "#ffffff");
    this.props.cancelEducation(index);
  };

  onSave = index => {
    const { saveEducation } = this.props;
    this.formRef[index].handleSubmit().then(values => {
      saveEducation(index, values)
        .then(res => {
          if (res.code === 0) this.setPlaceholderColor(index, "#ffffff");
          return res;
        })
        .then(res => (!res.code ? res.data : null))
        .then(this.props.eduUpdateBaseInfo);
    });
  };

  onDelete = index => {
    this.props
      .removeEducation(index)
      .then(res => (!res.code ? res.data : null))
      .then(this.props.eduUpdateBaseInfo);
  };

  onAdd = () => {
    const { educations } = this.props;
    if (!educations.length) {
      this.setPlaceholderColor(-1, "#f0f0f0");
      this.setPlaceholderColor(0, "#f0f0f0");
    } else {
      this.setState({ bottomPlaceholderBg: "#f0f0f0" });
    }
    this.props.addEducation({ limit: true });
  };

  render() {
    const { topPlaceholderBg, bottomPlaceholderBg } = this.state;
    const { educations, updateEducation } = this.props;
    return (
      <EditViewMulti
        className="info-education"
        title="教育经历"
        onAdd={this.onAdd}
      >
        <div className="placeholder" style={{ background: topPlaceholderBg }} />
        {educations.map((item, index) => (
          <EditViewSingle
            key={item.education_id}
            className={`${index === educations.length - 1 ? "last" : ""}`}
            isView={!item.isEdit}
            onEdit={() => this.onEdit(index)}
            onCancel={() => this.onCancel(index)}
            onSave={() => this.onSave(index)}
            onDelete={() => this.onDelete(index)}
          >
            <div role="view" className="view">
              <EducationView item={item} />
            </div>
            <div role="edit" className="edit">
              <EducationEditForm
                item={item}
                wrappedComponentRef={inst => (this.formRef[index] = inst)}
              />
            </div>
          </EditViewSingle>
        ))}
        <div
          className="placeholder"
          style={{ background: bottomPlaceholderBg }}
        />
        <style jsx>{`
          .placeholder {
            height: 20px;
          }
        `}</style>
        <style jsx global>
          {`
            .info-education {
              .ant-collapse-content-box {
                padding: 0;
              }
              .edit-view-single {
                margin-bottom: 0;
              }
            }
          `}
        </style>
      </EditViewMulti>
    );
  }
}

export default updateCompletewrapper(Education);
