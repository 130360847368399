import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { Collapse, Icon, Modal, Input, Pagination, Tooltip } from "antd";
import PositionVacantModal from "store/reducers/positionVacantM";
import CustomIcon from "components/CustomIcon";
const Panel = Collapse.Panel;
const { TextArea } = Input;

@connect(
  ({ PositionVacantModal }) => {
    return { ...PositionVacantModal };
  },
  {
    ...PositionVacantModal.actions
  }
)
export default class PositionCommunicateRecords extends PureComponent {
  state = {
    visible: false,
    areaValue: "",
    currentPage: 1
  };
  // componentDidMount() {
  //   this.props.getChats({
  //     expand: "create_time_str,create_by_name",
  //     jd_id: this.props.positionDetail.id,
  //     "per-page": 6
  //   });
  // }
  onOk = () => {
    const { addChat, positionDetail } = this.props;
    addChat({
      jd_id: positionDetail.id,
      remark: this.state.areaValue,
      currentPage: this.state.currentPage
    }).then(res => {
      if (res.code == 0) {
        this.setState({
          visible: false,
          areaValue: ""
        });
      }
    });
  };
  onCancel = () => {
    this.setState({
      visible: false,
      areaValue: ""
    });
  };
  onAdd = e => {
    e.stopPropagation();
    this.setState({
      visible: true
    });
  };
  // handlePageChange = (page, pageSize) => {
  //   const { getChats, positionDetail } = this.props;
  //   getChats({
  //     expand: "create_time_str,create_by_name",
  //     jd_id: positionDetail.id,
  //     "per-page": 6,
  //     page: page
  //   });
  //   this.setState({
  //     currentPage: page
  //   });
  // };
  render() {
    const { visible, areaValue } = this.state;
    const { modelRef, positionChat } = this.props;
    return (
      <React.Fragment>
        <Modal
          title="添加沟通记录"
          visible={visible}
          onOk={this.onOk}
          getContainer={() => modelRef.current}
          onCancel={this.onCancel}
        >
          <div className="position-contact">
            <div style={{ marginBottom: "20px" }}>请详细备注本次沟通记录</div>
            <div className="comm-detail">
              <TextArea
                placeholder="您可以更详细的备注本次沟通记录"
                value={areaValue}
                onChange={e => this.setState({ areaValue: e.target.value })}
              />
            </div>
          </div>

          <style jsx>
            {`
              .position-contact {
                padding: 0 24px;
                .comm-type {
                  margin-bottom: 20px;
                  :global(.ant-select) {
                    width: 200px;
                    margin-left: 5px;
                    height: 32px;
                  }
                }
                .comm-detail {
                  :global(.ant-input) {
                    padding: 20px;
                    width: 450px;
                    height: 140px;
                    resize: none;
                  }
                }
              }
            `}
          </style>
        </Modal>
        <Collapse defaultActiveKey={["10"]} className="collapse-handle-box">
          <Panel
            header={
              <React.Fragment>
                <div className="position-Communicate-head clear">
                  <div className="left"><CustomIcon
                          type="icon-comment-copy"
                          style={{ fontSize: 20, verticalAlign: "bottom",paddingRight:"7px" }}
                        />
                        <span style={{lineHeight:"19px"}}>职位沟通</span> 
                        {
                           positionChat._meta && positionChat._meta.totalCount
                           ? `(${positionChat._meta.totalCount})`
                           : ""
                        }
                        </div>
                  <div className="right" onClick={this.onAdd}>
                    添加职位沟通
                  </div>
                </div>
              </React.Fragment>
            }
            key="10"
          >
          <div className="position-detail-collapse-communicate">
          {positionChat.list &&
              positionChat.list.map(item => {
                return (
                  <div className="process record" key={item.id}>
                    <h4>
                      {item.create_time_str}
                      <Tooltip title={item.create_by_org_name}>
                        <span> {item.create_by_name}</span>
                      </Tooltip>
                    </h4>
                    <div>备注 : {item.remark}</div>
                  </div>
                );
              })}
            {positionChat.list && positionChat.list.length == 0 ? (
              "暂无沟通记录"
            ) : (""
              // <Pagination
              //   className="right"
              //   style={{ marginBottom: "20px" }}
              //   defaultCurrent={1}
              //   current={positionChat._meta && positionChat._meta.currentPage}
              //   pageSize={6}
              //   total={positionChat._meta && positionChat._meta.totalCount}
              //   onChange={this.handlePageChange}
              // />
            )}
          </div>
           
          </Panel>
          <style jsx>
            {`
              .record {
                h2 {
                  font-weight: normal;
                  color: #999999;
                  margin-bottom: 14px;
                }
                div {
                  color: #333333;
                }
              }
              .position-Communicate-head{
                .left{
                  font-weight:600;
                }
                .right{
                  color: #1890ff;
                }
              }
              .process {
                position: relative;
                margin-left: 4px;
                margin-bottom: 8px;
                color:#333333;
                line-height:20px;
                h4 {
                  color: #999999;
                  margin-bottom: 4px;
                }
                .process-status {
                  position: absolute;
                  top: 0;
                  right: 0;
                  color: #666666;
                  padding: 7px 10px 7px 30px;
                  border-bottom-left-radius: 35px;
                }
              }
              .position-detail-collapse-communicate{
                max-height:146px;
                overflow-y: scroll;
                padding-left:18px;
              }
            `}
          </style>
        </Collapse>
      </React.Fragment>
    );
  }
}
