import React, { PureComponent } from "react";
import { connect } from "react-redux";
import detailWrapper from "../detailWrapper";
import SmartCommunicateRecords from "../CommunicateRecords";
import BaseInfo from "../BaseInfo";
import JobStatus from "../JobStatus";
import SmartEducation from "../Education";
import JobExperience from "../JobExperience";
import ProjectExperience from "../ProjectExperience";
import SmartCertificate from "../Certificate";
import SmartResumeAffix from "../ResumeAffix";
import RecommandToPos from "../RecommandToPos";
import ResumeEmailSend from "../ResumeEmailSend";
import socialCandModel from "store/reducers/socialCandModel";
import recomCandToPosModel from "store/reducers/recomCandToPosModel";
import candidateAudit from "store/reducers/candidateAudit";
import global from "store/reducers/global";

const CommunicateRecords = connect(
  ({ socialCandidate, user }) => ({
    user: user,
    candList: socialCandidate.candList,
    communicationRecords: socialCandidate.candidate.communicationRecords
  }),
  { ...socialCandModel.actions, 
    updateCandList: socialCandModel.actions.updateCandList,
    updateStack: global.actions.updateDrawerStack }
)(SmartCommunicateRecords);

const ResumeAffix = connect(
  ({ socialCandidate }) => ({
    resumeAttachment: socialCandidate.candidate.detail.resumeAttachment,
    candidateId: socialCandidate.candidate.id
  }),
  { ...socialCandModel.actions }
)(SmartResumeAffix);

const Education = connect(
  ({ socialCandidate }) => {
    return {
      educations: socialCandidate.candidate.detail.educations || []
    };
  },
  { ...socialCandModel.actions }
)(SmartEducation);

const Certificate = connect(
  ({ socialCandidate }) => {
    return {
      certificates: socialCandidate.candidate.detail.certificates || []
    };
  },
  { ...socialCandModel.actions }
)(SmartCertificate);

function Resume() {
  return (
    <React.Fragment>
      <BaseInfo />
      <JobStatus />
      <Education />
      <JobExperience />
      <ProjectExperience />
      <Certificate />
      {/* <RecommandToPos />
      <ResumeEmailSend type={0} /> */}
    </React.Fragment>
  );
}

export default connect(
  ({ socialCandidate, user }) => ({
    profileDetail: socialCandidate.profileDetail,
    // candidateId: socialCandidate.candidate.id,
    communicationRecords: socialCandidate.candidate.communicationRecords,
    baseInfo: socialCandidate.candidate.detail.baseInfo,
    is_collection: socialCandidate.candidate.detail.is_collection,
    positionProgresses: socialCandidate.candidate.positionProgresses,
    candStatus: socialCandidate.candidate.candStatus,
    resumeAttachment: socialCandidate.candidate.detail.resumeAttachment,
    isIntern: false,
    user: user
  }),
  { 
    ...socialCandModel.actions,
    ...recomCandToPosModel.actions,
    updateStack: global.actions.updateDrawerStack,
    updateAuditModel: candidateAudit.actions.updateAuditModel,
  }
)(detailWrapper(Resume, CommunicateRecords, ResumeAffix));
