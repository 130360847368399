import xhr from './index.js'

// 获取公司列表
export function gboxCompanyList(param) {
  return xhr({
    url: 'admin/v1/gbox-companies',
    body: param,
    method: "get"
  })
}

// 列表
export function companyList(param) {
  return xhr({
    url: 'admin/v1/company/list',
    body: param,
    method: "post"
  })
}
// 详情
export function companyDetail(param) {
  return xhr({
    url: 'admin/v1/companies/' + param.id,
    body: param
  })
}
// 新增
export function insertCompany(param) {
  return xhr({
    url: 'admin/v1/companies',
    body: param,
    method: "post"
  })
}
// 编辑
export function modifyCompany(param) {
  return xhr({
    url: 'admin/v1/companies/' + param.id,
    body: param,
    method: "put"
  })
}
// 新增合同
export function insertCompanyContract(param) {
  return xhr({
    url: 'admin/v1/company/contract-add',
    body: param,
    method: "post"
  })
}
// 合同列表
export function getContractList(param) {
  return xhr({
    url: 'admin/v1/company/contract-list',
    body: param,
  })
}
// 招聘职位列表
export function getJDList(param) {
  return xhr({
    url: 'admin/v1/company/jds',
    body: param,
  })
}
// 联系人列表
export function getContactList(param) {
  return xhr({
    url: 'admin/v1/company/contact-list',
    body: param,
  })
}
// 联系人列表
export function modContact(param) {
  return xhr({
    url: 'admin/v1/company/contact-add',
    body: param,
    method: "post"
  })
}

// 新增沟通记录
export function insertChat(param) {
  return xhr({
    url: 'admin/v1/company/chat-add',
    body: param,
    method: "post"
  })
}

// 沟通记录列表
export function chatList(param) {
  return xhr({
    url: 'admin/v1/company/chat-list',
    body: param,
  })
}

// 重名公司列表
export function companyByNameList(param) {
  return xhr({
    url: 'admin/v1/company/get-companies-by-name',
    body: param,
  })
}

// 切换公司类型
export function changeCompanyType(param) {
  return xhr({
    url: 'admin/v1/company/change-type',
    body: param,
    method: "post"
  })
}

// 切换公司负责人
export function changeManager(param) {
  return xhr({
    url: 'admin/v1/company/change-manager',
    body: param,
    method: "post"
  })
}

// 根据职位名称查询职位
export function getJdsByName(param){
  return xhr({
    url: '/admin/v1/jd/get-jds-by-name',
    body: param,
    method: "GET"
  })
}
