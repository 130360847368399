import xhr from "./index.js";
import cacheOptions from "utils/cacheOptions";

// 选项列表
function getCommonData(param) {
  return xhr({
    url: "admin/v1/common/static",
    body: { data_name: param }
  });
}

export function globalSearch(param) {
  return xhr({
    url: 'admin/v1/workbench/global-search',
    body: param,
    method: "post"
  });
}

export function mapTips(param) {
  return xhr({
    url: 'admin/v1/common/map-inputtips',
    body: param,
    method: "get"
  });
}
export function verifyCompany(param) {
  return xhr({
    url: 'admin/v1/company/verify',
    body: param,
    method: "post"
  });
}

export const commonData = cacheOptions(getCommonData);
