import xhr from "./index.js";

// 审核人选列表
function getAuditList(param) {
  return xhr({
    url: "admin/v1/profile-audits",
    body: param
  });
}

//提交审核结果
function audit(param) {
  return xhr({
    url: "admin/v1/profile-audit/do-verify",
    body: param,
    method: "POST"
  });
}

//批量审核人选
function batchAudit(param) {
  return xhr({
    url: "admin/v1/profile-audit/batch-audit",
    body: param,
    method: "POST"
  });
}

export { getAuditList,audit,batchAudit };
