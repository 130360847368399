import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  Icon,
  Dropdown,
  Avatar,
  Spin,
  Empty,
  Message,
  Row,
  Col,
  Input
} from "antd";
import CustomIcon from "components/CustomIcon";

export default class AdvanceSearch extends PureComponent {
  state = {
    resultIsVisible: false,
    currentValue: "",
    businessList: [],
    clueList: [],
    companyList: [],
    jdList: [],
    profileList: [],
    profileInternList: [],
    businessTotal: 0,
    clueTotal: 0,
    companyTotal: 0,
    jdTotal: 0,
    profileTotal: 0,
    profileInternTotal: 0
  };

  renderTitle = (name, total) => {
    const {userAuth} = this.props;
    return (
      <Row
        className="advance-dropdown-title"
        type="flex"
        justify="space-between"
        // align="center"
      >
        <Col className="title-col">
          {name === "ongoing职位匹配" && (
            <CustomIcon
              type="icon-zhiwei1"
              style={{ color: "#999999", marginRight: "10px", fontSize: "16px" }}
            />
          )}
          {name === "公司匹配" && (
            <CustomIcon
              type="icon-gongsi"
              style={{ color: "#999999", marginRight: "10px", fontSize: "16px" }}
            />
          )}
          {name === "人才库匹配" && (
            <CustomIcon
              type="icon-rencaiku"
              style={{ color: "#999999", marginRight: "10px", fontSize: "16px" }}
            />
          )}
          <span className="title-name">{name}</span>
          {`${total ? `:共${total}项` : ""}`}
        </Col>
        {total ? (
          <Col className="">
            {
              !((userAuth === "business" || userAuth === "operator") && name === "ongoing职位匹配") && <a onClick={() => this.goTotalLook(name)}>查看全部</a>
            }
          </Col>
        ) : null}
      </Row>
    );
  };

  goTotalLook = name => {
    const { currentValue } = this.state;
    const { history, globalDispatch, userAuth } = this.props;
    if (name === "ongoing职位匹配") {
      globalDispatch("PositionVacantModal", "setUpdateState", {
        filed: "statusParam",
        value: { type: "0", post_name: currentValue, "per-page": 5 }
      });
      this.handleGoLink("/position/vacant");
    }
  };
  goClue = name => {
    this.handleGoLink("/custom/clue");
  };
  goBusiness = name => {
    this.handleGoLink("/custom/business");
  };
  goCompany = name => {
    this.handleGoLink("/custom/company");
  };
  goProfile = name => {
    this.handleGoLink("/candidate/talent-pool", {type: "social"});
  };
  goIntern = name => {
    this.handleGoLink("/candidate/talent-pool", {type: "intern"});
  };

  handleGoLink = (link, param) => {
    const { currentValue } = this.state;
    const { history, globalDispatch } = this.props;
    history.replace({
      pathname: link,
      state: {
        keyword: currentValue,
        needRefresh: new Date().getTime(),
        ...param
      }
    });
    this.visibleChange(false);
  };

  handleFocus = () => {
    clearTimeout(this.blurTimeout);
  };

  handleBlur = () => {
    clearTimeout(this.blurTimeout);
    this.blurTimeout = setTimeout(() => this.visibleChange(false), 0);
  };

  handleOpen = () => {
    if (this.state.currentValue.trim()) {
      this.visibleChange(true);
    }
  };

  visibleChange = (visible, e) => {
    this.setState(
      {
        resultIsVisible: visible
      },
      () => {
        // if (visible) this.menuRef.focus();
      }
    );
  };

  valueChange = e => {
    // this.setState({
    //   currentValue: e.target.value
    // })
  };

  searchKey = (value, event) => {
    const { globalSearch } = this.props;
    if (!value.trim()) return;
    this.setState(
      {
        currentValue: value
      },
      () => {
        this.handleOpen();
      }
    );
    globalSearch({ keyword: value }).then(res => {
      console.log("globalSearch", res);
      if (res.code == 0) {
        this.setState({
          businessList: res.data.business.list,
          clueList: res.data.clue.list,
          companyList: res.data.company.list,
          jdList: res.data.jd.list,
          profileList: res.data.profile.list,
          profileInternList: res.data.profile_intern.list,
          businessTotal: res.data.business.total,
          clueTotal: res.data.clue.total,
          companyTotal: res.data.company.total,
          jdTotal: res.data.jd.total,
          profileTotal: res.data.profile.total,
          profileInternTotal: res.data.profile_intern.total
        });
      }
    });
  };

  interceptEvent = e => {
    console.log("interceptEvent", e.target, document.activeElement);
    e.preventDefault();
    e.stopPropagation();
  };

  render() {
    const {globalLoading, userAuth} = this.props;
    const {
      resultIsVisible,
      businessList,
      clueList,
      companyList,
      jdList,
      profileList,
      profileInternList,
      businessTotal,
      clueTotal,
      companyTotal,
      jdTotal,
      profileTotal,
      profileInternTotal
    } = this.state;
    return (
      <React.Fragment>
        <div
          tabIndex="0"
          className="advance-search-wrapper"
          onFocus={e => this.handleFocus(e)}
          onBlur={e => this.handleBlur(e)}
        >
          <Input.Search
            tabIndex="1001"
            onClick={this.handleOpen}
            onChange={this.valueChange}
            onSearch={this.searchKey}
            placeholder="编号/公司名/职位名"
            style={{ width: 254 }}
          />
          <div
            ref={element => (this.menuRef = element)}
            tabIndex="1000"
            onFocusCapture={() => console.log("Dsdds")}
            className="advance-search-dropdown"
            style={{ display: resultIsVisible ? "" : "none" }}
          >
            <Spin spinning={globalLoading}>
              <div className="position-content">
                {this.renderTitle("ongoing职位匹配", jdTotal)}
                <div className="position-list">
                  {jdList && jdList.length === 0 ? (
                    <Empty
                      image={Empty.PRESENTED_IMAGE_SIMPLE}
                      description="暂无匹配结果"
                    />
                  ) : (
                    jdList.map(jd => (
                      <Row
                        className="position-item list-item"
                        type="flex"
                        justify="space-between"
                        align="center"
                      >
                        <Col className="item-name ellipsis">{jd.post_name}</Col>
                        <Col className="item-detail ellipsis">
                          {jd.company.name}
                        </Col>
                      </Row>
                    ))
                  )}
                </div>
              </div>
              <div className="company-content">
                {this.renderTitle("公司匹配")}
                {businessList &&
                businessList.length === 0 &&
                (clueList && clueList.length === 0) &&
                (companyList && companyList.length === 0) ? (
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description="暂无匹配结果"
                  />
                ) : (
                  <Row
                    type="flex"
                    justify="space-between"
                    align="center"
                    className="company-content-wrapper"
                  >
                    {clueList && clueList.length !== 0 && (
                      <Col className="clue-content content-detail">
                        <Row type="flex" justify="space-between" align="center">
                          <Col className="content-title">线索：共{clueTotal}项</Col>
                          <Col className="">
                            {
                              // (userAuth === "admin" || userAuth === "team_leader") && 
                            }
                              <a onClick={this.goClue}>查看全部</a>
                           
                          </Col>
                        </Row>
                        {clueList.map(clue => (
                          <div className="ellipsis item">{clue.company_name}</div>
                        ))}
                      </Col>
                    )}
                    {businessList && businessList.length !== 0 && (
                      <Col className="business-content content-detail">
                        <Row type="flex" justify="space-between" align="center">
                          <Col className="content-title">商机：共{businessTotal}项</Col>
                          <Col className="">
                          {
                            (userAuth !== "consultant" && userAuth !== "channel") && <a onClick={this.goBusiness}>查看全部</a>
                          }
                          </Col>
                        </Row>
                        {businessList.map(business => (
                          <div className="ellipsis item">
                            {business.company.name}
                          </div>
                        ))}
                      </Col>
                    )}
                    {companyList && companyList.length !== 0 && (
                      <Col className="customer-content content-detail">
                        <Row type="flex" justify="space-between" align="center">
                          <Col className="content-title">客户：共{companyTotal}项</Col>
                          <Col className="">
                          {
                            (userAuth !== "consultant" && userAuth !== "consultant360" && userAuth !== "channel" && userAuth !== "operator") 
                              && <a onClick={this.goCompany}>查看全部</a>
                          }
                          </Col>
                        </Row>
                        {companyList.map(company => (
                          <div className="ellipsis item">{company.name}</div>
                        ))}
                      </Col>
                    )}
                  </Row>
                )}
              </div>
              <div className="talent-content">
                {this.renderTitle("人才库匹配")}
                {profileList &&
                profileList.length === 0 &&
                (profileInternList && profileInternList.length === 0) ? (
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description="暂无匹配结果"
                  />
                ) : (
                  <Row
                    type="flex"
                    justify="space-between"
                    align="center"
                    className="company-content-wrapper"
                  >
                    {profileList && profileList.length !== 0 && (
                      <Col className="clue-content content-detail">
                        <Row type="flex" justify="space-between" align="center">
                          <Col className="content-title">社会人选：共{profileTotal}项</Col>
                          <Col className="">
                            <a onClick={this.goProfile}>查看全部</a>
                          </Col>
                        </Row>
                        {profileList.map(profile => {
                          return (
                            <div className="ellipsis item">
                              {profile.name}
                              <CustomIcon
                                type={`${
                                  profile.sex === "男" ? "icon-nan" : "icon-nv1"
                                }`}
                              />
                            </div>
                          );
                        })}
                      </Col>
                    )}
                    {profileInternList && profileInternList.length !== 0 && (
                      <Col className="business-content content-detail">
                        <Row type="flex" justify="space-between" align="center">
                          <Col className="content-title">实习生：共{profileInternTotal}项</Col>
                          <Col className="">
                            <a onClick={this.goIntern}>查看全部</a>
                          </Col>
                        </Row>
                        {profileInternList.map(intern => {
                          return (
                            <div className="ellipsis item">
                              {intern.name}
                              <CustomIcon
                                type={`${
                                  intern.sex === "男" ? "icon-nan" : "icon-nv1"
                                }`}
                              />
                            </div>
                          );
                        })}
                      </Col>
                    )}
                  </Row>
                )}
              </div>
            </Spin>
          </div>
        </div>
        <style jsx global>{`
          .advance-search-wrapper {
            position: relative;
            display: inline-block;
          }
          .advance-search-dropdown {
            position: absolute;
            width: 500px;
            top: 43px;
            z-index: 2;
            background: #fff;
            box-shadow: 0px 4px 10px 0px rgba(35, 37, 38, 0.2);
            border-radius: 0px 0px 4px 4px;
            .advance-dropdown-title {
              padding: 0 26px;
              background: #f5f5f5;
              height: 36px;
              line-height: 36px;
 
              color: #999999;

            }
            .list-item {
              .item-name {
                max-width: 150px;
              }
              .item-detail {
                max-width: 230px;
              }
            }
            .position-content {
              .position-list {
                padding: 6px 20px;
                .position-item {
                  line-height: 26px;
                }
              }
            }

            .company-content {
              .company-content-wrapper {
                padding: 6px 20px;
              }
              .content-detail {
                width: 219px;
                line-height: 26px;
                .content-title {
                  color: #999999;
                }
              }
              .item {
                max-width: 219px;
              }
            }
            .talent-content {
              .company-content-wrapper {
                padding: 6px 20px;
              }
              .content-detail {
                width: 219px;
                line-height: 26px;
                .content-title {
                  color: #999999;
                }
              }
              .item {
                max-width: 219px;
                .anticon {
                  margin-left: 5px;
                }
              }
            }
          }
        `}</style>
      </React.Fragment>
    );
  }
}
