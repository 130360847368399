import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { Drawer, Icon, Button, Modal } from "antd";
import { BaseInfoEditForm as ImportBaseInfoEditForm } from "../BaseInfo";
import {
  createCertificateEditForm,
  fieldsToProps as certFieldsToProps,
  CertificateEditForm as ImportCertificateEditForm
} from "../Certificate";
import {
  EducationEditForm as ImportEducationEditForm,
  fieldsToProps as educationFieldsToProps,
  createEducationEditForm
} from "../Education";
import { AddJobExpEditForm } from "../JobExperience";
import { AddProExpEditForm } from "../ProjectExperience";
import { JobStatusEditForm as ImportJobStatusEditForm } from "../JobStatus";
import socialCandModel from "store/reducers/socialCandModel";
const confirm = Modal.confirm;

const CertificateEditForm = createCertificateEditForm(
  undefined,
  certFieldsToProps
);

const EducationEditForm = createEducationEditForm(
  undefined,
  educationFieldsToProps
);

function stateEditForm(EditForm, name, idMaps) {
  return class StateList extends PureComponent {
    crefs = [];
    state = {
      items: []
    };

    submit = () => {
      const promiseList = this.crefs
        .filter(ref => !!ref)
        .map(ref => ref.handleSubmit());
      return promiseList;
    };

    onAddItem = () => {
      const { items } = this.state;
      items.push({
        [idMaps[name].id]: `create-${new Date().getTime()}`,
        isEdit: true
      });
      this.setState({ items: [...items] });
    };

    deleteItem = index => {
      const { items } = this.state;
      items.splice(index, 1);
      this.setState({ items: [...items] });
    };

    render() {
      const { items } = this.state;
      return (
        <React.Fragment>
          {items.map((item, index) => {
            return (
              <div key={item[idMaps[name].id]}>
                <div style={{ marginBottom: 10 }} className="clear">
                  <span
                    className="right"
                    style={{ cursor: "pointer" }}
                    onClick={() => this.deleteItem(index)}
                  >
                    <Icon type="delete" style={{ marginRight: 5 }} />
                    删除此条编辑
                  </span>
                </div>
                <EditForm
                  item={item}
                  wrappedComponentRef={inst => (this.crefs[index] = inst)}
                />
              </div>
            );
          })}
        </React.Fragment>
      );
    }
  };
}

function importEditForm(ImportEditForm, name, idMaps, connectFunc) {
  class ImportList extends PureComponent {
    crefs = [];

    submit = () => {
      const promiseList = this.crefs
        .filter(ref => !!ref)
        .map(ref => ref.handleSubmit());
      return promiseList;
    };

    onAddItem = () => {
      this.props.addImportItem({ limit: false });
    };

    deleteImportItem = index => {
      this.props.removeImportItem(index);
    };

    render() {
      const {
        importItems,
        removeImportItem,
        addImportItem,
        ...props
      } = this.props;
      return (
        <React.Fragment>
          {importItems.map((item, index) => (
            <div key={item[idMaps[name].id]}>
              <div style={{ marginBottom: 10 }} className="clear">
                <span
                  className="right"
                  style={{ cursor: "pointer" }}
                  onClick={() => this.deleteImportItem(index)}
                >
                  <Icon type="delete" style={{ marginRight: 5 }} />
                  删除此条编辑
                </span>
              </div>
              <ImportEditForm
                item={item}
                {...props}
                onChange={changedFields => {
                  this.props.onChange({ index, values: changedFields });
                }}
                wrappedComponentRef={inst => (this.crefs[index] = inst)}
              />
            </div>
          ))}
        </React.Fragment>
      );
    }
  }

  return connectFunc(function(props) {
    const { cref, ...otherprops } = props;
    return <ImportList {...otherprops} ref={cref} />;
  });
}

function listEditForm(ImportEditForm, EditForm, name, connectFunc) {
  const idMaps = {
    education: {
      id: "education_id",
      title: "教育经历"
    },
    job: {
      id: "job_id",
      title: "工作经历"
    },
    certificate: {
      id: "cert_id",
      title: "证书&认证"
    }
  };

  const StateEditForm = stateEditForm(EditForm, name, idMaps);
  ImportEditForm = importEditForm(ImportEditForm, name, idMaps, connectFunc);
  return class extends PureComponent {
    onAddItem = () => {
      this.props.refc.current.onAddItem();
    };

    render() {
      const { importRefc, refc } = this.props;
      return (
        <div className="item education-form">
          <h2 className="clear">
            {idMaps[name].title}
            <Icon
              type="plus-square"
              theme="outlined"
              className="right"
              onClick={this.onAddItem}
            />
          </h2>
          <ImportEditForm cref={importRefc} />
          <StateEditForm ref={refc} />
        </div>
      );
    }
  };
}

/**
 * next: listEditForm改写版本
 * 目前只对工作经历和项目经历做改写，下一步会改写全部
 */
function next_listEditForm(EditForm, name, connectFunc) {
  const idMaps = {
    project: {
      id: "project_id",
      title: "项目经历"
    },
    job: {
      id: "job_id",
      title: "工作经历"
    }
  };

  EditForm = importEditForm(EditForm, name, idMaps, connectFunc);
  return class extends PureComponent {
    onAddItem = () => {
      this.props.refc.current.onAddItem();
    };

    render() {
      const { refc } = this.props;
      return (
        <div className="item education-form">
          <h2 className="clear">
            {idMaps[name].title}
            <Icon
              type="plus-square"
              theme="outlined"
              className="right"
              onClick={this.onAddItem}
            />
          </h2>
          <EditForm cref={refc} />
        </div>
      );
    }
  };
}

const EducationForm = function(props) {
  const { importRefc, refc } = props;
  const EducationFormList = listEditForm(
    ImportEducationEditForm,
    EducationEditForm,
    "education",
    connect(
      ({ socialCandidate }) => ({
        importItems: socialCandidate.newCandidate.educations || []
      }),
      { removeImportItem: socialCandModel.actions.removeNewEducation }
    )
  );
  return <EducationFormList importRefc={importRefc} refc={refc} />;
};

const JobForm = function(props) {
  const { refc } = props;
  const JobFormList = next_listEditForm(
    AddJobExpEditForm,
    "job",
    connect(
      ({ socialCandidate }) => ({
        importItems: socialCandidate.newCandidate.jobExperiences || []
      }),
      {
        removeImportItem: socialCandModel.actions.removeNewJobExperience,
        addImportItem: socialCandModel.actions.addNewJobExperience,
        onChange: socialCandModel.actions.updateNewJobExperience
      }
    )
  );
  return <JobFormList refc={refc} />;
};

const ProjectForm = function(props) {
  const { refc } = props;
  const ProjectFormList = next_listEditForm(
    AddProExpEditForm,
    "project",
    connect(
      ({ socialCandidate }) => ({
        importItems: socialCandidate.newCandidate.proExperiences || [],
        jobCompanies: (socialCandidate.newCandidate.jobExperiences || []).map(
          item => (item.company_name || {}).value
        )
      }),
      {
        removeImportItem: socialCandModel.actions.removeNewProExperience,
        addImportItem: socialCandModel.actions.addNewProExperience,
        onChange: socialCandModel.actions.updateNewProExperience
      }
    )
  );
  return <ProjectFormList refc={refc} />;
};

const CertForm = function(props) {
  const { importRefc, refc } = props;
  const CertFormList = listEditForm(
    ImportCertificateEditForm,
    CertificateEditForm,
    "certificate",
    connect(
      ({ socialCandidate }) => ({
        importItems: socialCandidate.newCandidate.certificates || []
      }),
      { removeImportItem: socialCandModel.actions.removeNewCertificate }
    )
  );
  return <CertFormList importRefc={importRefc} refc={refc} />;
};
//手动创建简历
@connect(
  ({ socialCandidate }) => ({
    createResumeDrawer: socialCandidate.createResumeDrawer,
    baseInfo: socialCandidate.newCandidate.baseInfo || {},
    jobStatus: socialCandidate.newCandidate.jobStatus || {}
  }),
  { ...socialCandModel.actions }
)
export default class AddResume extends PureComponent {
  educationRef = React.createRef();
  eduImportRefc = React.createRef();
  jobRef = React.createRef();
  certRef = React.createRef();
  proRef = React.createRef();
  certImportRefc = React.createRef();

  componentDidUpdate(prevProps) {
    const { createResumeDrawer } = this.props;
    if (
      createResumeDrawer.visible !== prevProps.createResumeDrawer.visible &&
      createResumeDrawer.visible === true &&
      createResumeDrawer.openType === "create"
    ) {
      this.props.updateNewCandidate({
        baseInfo: {},
        jobStatus: {},
        educations: [],
        jobExperiences: [],
        certificates: [],
        proExperiences: []
      });
    }
  }

  onSave = () => {
    const { createCandidate } = this.props;
    const baseformPromise = this.baseformRef.handleSubmit(),
      jobStatusPromise = this.jobStatusRef.handleSubmit();
    const educationPromiseList = this.educationRef.current
      .submit()
      .concat(this.eduImportRefc.current.submit());
    const jobExperiencePromiseList = this.jobRef.current.submit();
    const certificatePromiseList = this.certRef.current
      .submit()
      .concat(this.certImportRefc.current.submit());
    const proPromiseList = this.proRef.current.submit();

    const educationLen = educationPromiseList.length,
      jobExperienceLen = jobExperiencePromiseList.length,
      certificateLen = certificatePromiseList.length,
      proLen = proPromiseList.length;
    Promise.all([
      baseformPromise,
      jobStatusPromise,
      ...educationPromiseList,
      ...jobExperiencePromiseList,
      ...certificatePromiseList,
      ...proPromiseList
    ])
      .then(values => {
        let baseInfo = values[0],
          jobStatus = values[1],
          profile_school = collectToGroup(educationLen, values, 2),
          profile_job = collectToGroup(jobExperienceLen, values),
          profile_certificate = collectToGroup(certificateLen, values),
          profile_project = collectToGroup(proLen, values);

        const { city = {}, native = {}, tags = [] } = baseInfo;
        const baseInfoParam = {
          ...baseInfo,
          city: `${city.province_label ? city.province_label : ""}${
            city.city_label ? `-${city.city_label}` : ""
          }${city.region_label ? `-${city.region_label}` : ""}`,
          native: `${native.province_label ? native.province_label : ""}${
            native.city_label ? `-${native.city_label}` : ""
          }${native.region_label ? `-${native.region_label}` : ""}`,
          tags: tags.map(item => item.value)
        };
        const { salary_current, salary_expect } = jobStatus;
        const jobStatusParam = {
          ...jobStatus,
          salary_current: salary_current && String(salary_current),
          salary_expect: salary_expect && String(salary_expect)
        };
        const param = {
          ...baseInfoParam,
          ...jobStatusParam,
          profile_school,
          profile_job,
          profile_certificate,
          profile_project
        };
        createCandidate(param);
      })
      .catch(err => {
        console.log(err);
      });
  };

  onClose = () => {
    confirm({
      title: "确定要放弃正在编辑的简历吗？",
      zIndex: 1005,
      cancelText: "取消",
      okText: "确定",
      onOk: () => {
        this.props.updateCreateResumeDrawer({ visible: false });
      },
      onCancel() {}
    });
  };

  render() {
    const { baseInfo, jobStatus } = this.props;
    const { createResumeDrawer, title } = this.props;
    return (
      <Drawer
        width={900}
        placement="right"
        className="creat-drawer-box create-resume-drawer drawer-modal"
        title={createResumeDrawer.title}
        onClose={this.onClose}
        visible={createResumeDrawer.visible}
        style={{
          zIndex: 1002,
          paddingBottom: 53
        }}
      >
        <div className="item base-info-form">
          <h2>基本信息</h2>
          <ImportBaseInfoEditForm
            wrappedComponentRef={inst => (this.baseformRef = inst)}
            baseInfo={baseInfo}
          />
        </div>
        <div className="item job-status-form">
          <h2>求职状态</h2>
          <ImportJobStatusEditForm
            wrappedComponentRef={inst => (this.jobStatusRef = inst)}
            jobStatus={jobStatus}
          />
        </div>
        <EducationForm
          refc={this.educationRef}
          importRefc={this.eduImportRefc}
        />
        <JobForm refc={this.jobRef} />
        <ProjectForm refc={this.proRef} />
        <CertForm refc={this.certRef} importRefc={this.certImportRefc} />
        <div
          style={{
            position: "absolute",
            bottom: 0,
            width: "100%",
            borderTop: "1px solid #e8e8e8",
            padding: "10px 16px",
            textAlign: "left",
            left: 0,
            background: "#fff",
            borderRadius: "0 0 4px 4px"
          }}
        >
          <Button
            onClick={this.onSave}
            type="primary"
            style={{
              marginRight: 20
            }}
          >
            保存
          </Button>
          <Button onClick={this.onClose}>取消</Button>
        </div>
        <style jsx global>
          {`
            :global(.create-resume-drawer) {
              z-index: 1002;
              h2 {
                font-weight: 600;
                font-size: 16px;
                margin: 38px 0;
              }
              .base-info-form h2 {
                margin-top: 10px;
              }
              .item {
                :global(.ant-form) {
                  padding-left: 20px;
                }
                :global(.anticon) {
                  cursor: pointer;
                }
              }
            }
          `}
        </style>
      </Drawer>
    );
  }
}

/**
 * 收集数据到组中
 * @param {*} increment
 * @param {*} values
 * @param {*} start
 * @param {*} dataConvert 数据转换函数
 */
function collectToGroup(increment, values, start, dataConvert) {
  const group = [];
  start =
    collectToGroup.lastEnd && collectToGroup.lastEnd < values.length
      ? collectToGroup.lastEnd
      : start;
  const end = start + increment;
  for (let i = start; i < end; i++) {
    group.push(dataConvert ? dataConvert(values[i]) : values[i]);
  }
  collectToGroup.lastEnd = end;
  return group;
}
