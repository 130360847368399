import App, { configureStore, staticRoutes } from "./app";
import React from "react";
import { Provider } from "react-redux";
import { Router } from "react-router";
import createHashHistory from "history/createHashHistory";
import { ConfigProvider } from "antd";
import zhCN from "antd/lib/locale-provider/zh_CN";
import ErrorBoundary from "components/ErrorBoundary/ErrorBoundary";
import { PathnameRecord } from "utils/hoc/pathname-record";

export const store = configureStore.createStore(window.__PRELOADED_STATE__);
export const history = new createHashHistory();

export default function root() {
  return (
    <Provider store={store}>
      <Router history={history}>
        <ConfigProvider locale={zhCN}>
          <ErrorBoundary>
            <PathnameRecord store={store}>
              <App />
            </PathnameRecord>
          </ErrorBoundary>
        </ConfigProvider>
      </Router>
    </Provider>
  );
}

export function childRoot(child) {
  return (
    <Provider store={store}>
      <Router history={history}>
        <ConfigProvider locale={zhCN}>
          <ErrorBoundary>{child}</ErrorBoundary>
        </ConfigProvider>
      </Router>
    </Provider>
  );
}
