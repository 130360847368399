import React from "react";
import { Modal, Form, Cascader, Message, DatePicker, Icon } from "antd";
import { connect } from "react-redux";
import PositionVacantModal from "store/reducers/positionVacantM";
import moment from "moment";
import locale from "antd/lib/date-picker/locale/zh_CN";
import { modifyEvent } from "services/positionS";

const FormItem = Form.Item;
function range(start, end) {
  const result = [];
  for (let i = start; i < end; i++) {
    result.push(i);
  }
  return result;
}

const RecommendModify = Form.create()(
  // 修改推荐时间
  @connect(
    ({ PositionVacantModal }) => {
      return { ...PositionVacantModal };
    },
    {
      ...PositionVacantModal.actions
    }
  )
  class Recommend extends React.PureComponent {
    onCancel = () => {
      this.props.isClose();
      this.clearForm();
    };
    clearForm = () => {
      this.props.form.resetFields();
    };
    onOk = () => {
      const form = this.props.form;
      const { propsInfo, getCandidateProgress, progressId } = this.props;
      form.validateFields((err, values) => {
        console.log("Received values of form: ", values);
        if (err) {
          return;
        }
        values.id = propsInfo.id;
        values.event_time = moment(values.time).format("YYYY-MM-DD HH:mm");
        delete values.time;
        modifyEvent(values).then(res => {
          if (res.code == 0) {
            getCandidateProgress({
              jd_profile_id: progressId
            });
            this.onCancel();
            form.resetFields();
            this.clearForm();
          }
        });
      });
    };
    //禁用时间区间
    disabledDate = current => {
      const { propsInfo = {} } = this.props;
      const { next_event_time, prev_event_time } = propsInfo;
      console.log(prev_event_time, next_event_time);
      let start =
        prev_event_time &&
        moment(prev_event_time.substring(0, 10)).add(0, "day");
      // let end = next_event_time && moment(next_event_time.substring(0, 10));
      let end =
        next_event_time &&
        moment(next_event_time.substring(0, 10)).add(1, "day");
      console.log("end", start, end);
      return (
        (prev_event_time ? current < start : undefined) ||
        (next_event_time ? current > end : undefined)
      );
    };

    render() {
      const { getFieldDecorator, getFieldValue } = this.props.form;
      const { recommendModifyStatus, propsInfo = {} } = this.props;
      const size = "default";
      const formItemLayout = {
        labelCol: { span: 6 },
        wrapperCol: { span: 14 }
      };
      return (
        <React.Fragment>
          <Modal
            zIndex={1001}
            title="人选推荐"
            visible={recommendModifyStatus}
            onOk={this.onOk}
            onCancel={this.onCancel}
          >
            <div className="recommend-modify">
              <Form>
                <div className="name clear">
                  <div className="left-label">
                    候选人： {propsInfo.profile_name}（ID:{propsInfo.profile_no}
                    ）
                  </div>
                </div>
                {propsInfo.profile_no && (
                  <FormItem {...formItemLayout} label={propsInfo.time_name}>
                    {getFieldDecorator("time", {
                      initialValue: undefined,
                      rules: [{ required: true, message: "请选择时间" }]
                    })(
                      <DatePicker
                        disabledDate={this.disabledDate}
                        showTime
                        showTime
                        size={size}
                        locale={locale}
                        style={{ width: "100%" }}
                        format="YYYY-MM-DD HH:mm"
                        placeholder="请选择时间"
                      />
                    )}
                  </FormItem>
                )}
              </Form>
            </div>

            <style jsx global>
              {`
                .recommend-modify {
                  .name {
                    font-size: 16px;
                    margin-bottom: 11px;
                    .left-label {
                      margin-left: 62px;
                    }
                  }
                  .ant-form-item {
                    margin-bottom: 5px;
                  }
                  .left-label,
                  .ant-form-item,
                  .ant-form-item label,
                  .ant-form-item input {
                    font-size: 12px;
                  }
                }
              `}
            </style>
          </Modal>
        </React.Fragment>
      );
    }
  }
);
export default RecommendModify;
