import React, { PureComponent } from "react";
import { connect } from "react-redux";
import detailWrapper from "../detailWrapper";
import SmartCommunicateRecords from "../CommunicateRecords";
import BaseInfo from "../BaseInfoIntern";
import JobStatus from "../JobStatusIntern";
import SmartEducation from "../Education";
import PracticeExperience from "../PracticeExperience";
import CampusPractice from "../CampusPractice";
import SmartCertificate from "../Certificate";
import PersonalWork from "../PersonalWork";
import RecommandToPos from "../RecommandToPos";
import ResumeEmailSend from "../ResumeEmailSend";
import SmartResumeAffix from "../ResumeAffix";
import intern from "store/reducers/internModel";
import recomCandToPosModel from "store/reducers/recomCandToPosModel";
import candidateAudit from "store/reducers/candidateAudit";
import global from "store/reducers/global";

const CommunicateRecords = connect(
  ({ intern, user }) => ({
    user: user,
    candList: intern.internlist,
    communicationRecords: intern.candidate.communicationRecords
  }),
  { ...intern.actions,
    updateCandList: intern.actions.updateInternList,
    updateStack: global.actions.updateDrawerStack }
)(SmartCommunicateRecords);

const ResumeAffix = connect(
  ({ intern }) => ({
    resumeAttachment: intern.candidate.detail.resumeAttachment,
    candidateId: intern.candidate.id
  }),
  { ...intern.actions }
)(SmartResumeAffix);

const Education = connect(
  ({ intern }) => {
    return {
      educations: intern.candidate.detail.educations || []
    };
  },
  { ...intern.actions }
)(SmartEducation);

const Certificate = connect(
  ({ intern }) => {
    return {
      certificates: intern.candidate.detail.certificates || []
    };
  },
  { ...intern.actions }
)(SmartCertificate);

function Resume() {
  return (
    <React.Fragment>
      <BaseInfo />
      <JobStatus />
      <Education />
      <PracticeExperience />
      <CampusPractice />
      <Certificate />
      <PersonalWork />
      {/* <RecommandToPos />
      <ResumeEmailSend type={1} /> */}
    </React.Fragment>
  );
}

export default connect(
  ({ intern, user }) => ({
    profileDetail: intern.profileDetail,
    // candidateId: intern.candidate.id,
    communicationRecords: intern.candidate.communicationRecords,
    baseInfo: intern.candidate.detail.baseInfo,
    is_collection: intern.candidate.detail.is_collection,
    positionProgresses: intern.candidate.positionProgresses,
    candStatus: intern.candidate.candStatus,
    resumeAttachment: intern.candidate.detail.resumeAttachment,
    isIntern: true,
    user: user
  }),
  {
    ...intern.actions,
    ...recomCandToPosModel.actions,
    updateAuditModel: candidateAudit.actions.updateAuditModel,
    updateStack: global.actions.updateDrawerStack
  }
)(detailWrapper(Resume, CommunicateRecords, ResumeAffix));
