import React, { PureComponent } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  Radio,
  Drawer,
  Button,
  Select,
  Spin,
  Tag,
  Tabs,
  Badge,
  Collapse,
  Message,
  Icon,
  Modal,
  Form,
  Upload,
  Input,
  Tooltip,
  Popconfirm
} from "antd";
import CommunicateModal from "./CommunicateModal";
import ResumeAffix from "./ResumeAffix";
import { commonData } from "services/commonService";
import { filter } from "utils/validComponentChildren";
import ProfileDetail from "./profile_detail";
import Empty from "components/empty";
import OriginResume from "pages/TalentPool/OriginResume";
import PositionDetail from "pages/PositionVacant/detail.js";
import AuditModal from "pages/CandidateAudit/audit-modal.js";
import CustomIcon from "components/CustomIcon";
import { isCollect } from "services/socialCandidate";
import StepIntro, {HintsIntro} from "components/step-intro";

const TextArea = Input.TextArea;
const Option = Select.Option;
const TabPane = Tabs.TabPane;
const Panel = Collapse.Panel;
const Dragger = Upload.Dragger;

export default function detailWrapper(Resume, CommunicateRecords, ResumeAffix) {
  return class extends PureComponent {
    static propTypes = {
      candidateId: PropTypes.any.isRequired,
      baseInfo: PropTypes.any.isRequired,
      positionProgresses: PropTypes.any.isRequired,
      candStatus: PropTypes.any.isRequired,

      getSocialCandDetail: PropTypes.func.isRequired,
      updateCandStatus: PropTypes.func.isRequired,
      commitAudit: PropTypes.func.isRequired,
      selectCandidate: PropTypes.func.isRequired
    };

    state = {
      profileOnline: [],
      previewVisible: false,
      statusReason: {
        help: "",
        validateStatus: ""
      },
      loading: false,
      resumeType: "",
    };

    modelRef = React.createRef();

    componentDidMount() {
      commonData("profile_status").then(data => {
        this.setState({
          profileOnline: data.profile_status
        });
      });
      const { candidateId, getSocialCandDetail, selectCandidate, resumeAttachment } = this.props;
      this.setState({ loading: true });
      setTimeout(() => {
        getSocialCandDetail(candidateId).then(() => {
          this.setState({ loading: false });
        });
      }, 0);
      selectCandidate(candidateId);
    }

    componentDidUpdate(prevProps) {
      const {
        candidateId,
        getSocialCandDetail,
        stackVisible,
        fromTalent,
        selectCandidate,
        resumeAttachment
      } = this.props;
      if (prevProps.stackVisible != stackVisible && stackVisible) {
        this.setState({ loading: true });
        getSocialCandDetail(candidateId).then(() => {
          this.setState({ loading: false });
        });
        selectCandidate(candidateId);
      }
      const { is } = require("immutable");
      if (!is(resumeAttachment, prevProps.resumeAttachment) && fromTalent) {
        this.setState({
          resumeType: "1"
        })
      }
      if (!is(resumeAttachment, prevProps.resumeAttachment) && !fromTalent) {
        this.setState({
          resumeType: resumeAttachment.length > 0 ? "2" : "1"
        })
      }
    }

    onStatusSelect = value => {
      const { baseInfo } = this.props;
      if (baseInfo.compete < 70 && value.key == 1) {
        Message.error("人选信息完善度低于70%，无法上架，请完善更多信息");
        return;
      }
      this.props.updateCandStatus({
        statusModal: true,
        selectedStatusValue: value
      });
    };

    statusModalOk = () => {
      const { validateStatus } = this.state.statusReason;
      if (!validateStatus || validateStatus === "error") {
        this.setState({
          statusReason: {
            validateStatus: "error",
            help: "请输入原因，且至少5个字符"
          }
        });
        return;
      }
      this.props.commitAudit();
      this.clearStatusReason();
    };

    statusModalCancel = () => {
      this.clearStatusReason();
      this.props.updateCandStatus({ statusModal: false, statusReason: "" });
    };

    clearStatusReason = () => {
      this.setState({
        statusReason: {
          validateStatus: "",
          help: ""
        }
      });
    };

    successStatusReason = () => {
      this.setState({
        statusReason: {
          validateStatus: "success",
          help: ""
        }
      });
    };

    onStatusReason = e => {
      const value = e.target.value;
      if (value.length < 5) {
        this.setState({
          statusReason: {
            validateStatus: "error",
            help: "请输入原因，且至少5个字符"
          }
        });
      } else {
        this.setState({
          statusReason: {
            validateStatus: "success",
            help: ""
          }
        });
      }
      this.props.updateCandStatus({ statusReason: e.target.value });
    };

    onDrawerClose = () => {
      const { onClose } = this.props;
      this.props.selectCandidate("");
      this.setState({ previewVisible: false });
      // this.props.clearDetail();
      if (onClose) {
        onClose();
      }
    };

    //打开推荐职位
    onRecomPos = () => {
      const { candidateId, isIntern, baseInfo } = this.props;
      console.log("onRecomPos",this)
      let cands = {
        key: candidateId,
        name: baseInfo.name,
        no: baseInfo.no,
      }
      if (isIntern) {
        this.props.updateSelectedInternCandIds([candidateId]);
        this.props.updateSelectedInternCands([cands]);
      } else {
        this.props.updateSelectedCandIds([candidateId]);
        this.props.updateSelectedCands([cands]);
      } 
      this.props.updateRecommandPos({ visible: true, isIntern: isIntern });
    };

    processColor = type => {
      const color = {
        0: "#38a4e9",
        2: "#fa9965",
        3: "#52c41a",
        4: "#5fccc3",
        5: "#ff4b54",
        6: "#999999",
        1: "#f65eff",
        7: "#999999"
      }[type];
      return color || "#38a4e9";
    };

    setReasonOnJob = () => {
      this.props.updateCandStatus({ statusReason: "目前在职，在看机会" });
      this.successStatusReason();
    };

    setReasonAgain = () => {
      this.props.updateCandStatus({ statusReason: "简历完善，重新上架" });
      this.successStatusReason();
    };

    setReasonUnline = () => {
      this.props.updateCandStatus({ statusReason: "下架，更新信息" });
      this.successStatusReason();
    };

    setReasonNO = () => {
      this.props.updateCandStatus({ statusReason: "人选更新，不看机会" });
      this.successStatusReason();
    };

    resumePreview = () => {
      this.setState({ previewVisible: true });
    };

    onResumePreChange = visible => {
      this.setState({ previewVisible: visible });
    };

    goPosition = id => {
      const { updateStack, user } = this.props;
      if (user.roles === "special_group_leader" || user.roles === "business" || user.roles === "operator") return;
      updateStack({
        type: "add",
        componentRef: PositionDetail,
        componentProps: {
          onClose: () => updateStack({ type: "pop" }),
          stackVisible: true,
          id: id
        }
      });
    };

    addCommunication = () => {
      this.props.updateCommunicationRecords({ addModal: true });
    };

    auditClick = () => {
      this.props.updateAuditModel(true);
    };

    auditCallBack = res => {
      const { candidateId, getSocialCandDetail } = this.props;
      if (res.code == 0) {
        this.setState({ loading: true });
        getSocialCandDetail(candidateId).then(() => {
          this.setState({ loading: false });
        });
      }
    };
    confirm = () => {
      const { getDetail, candidateId ,getSocialCandDetail} = this.props;
      console.log(this.props,'djjj');
      let params = {
        profile_id: candidateId
      };
      isCollect(params).then(res => {
        if (res.code === 0) getSocialCandDetail(candidateId);
      });
    };
    changeResume = (e) => {
      console.log(e)
      this.setState({
        resumeType: e.target.value
      })
    }

    render() {
      const { profileOnline, previewVisible, loading, resumeType } = this.state;
      const {
        candStatus,
        baseInfo,
        candidateId,
        openFrom,
        positionProgresses = [],
        communicationRecords,
        addCommunicate,
        updateCommunicationRecords,
        getJDList,
        is_collection,
        resumeAttachment,
        user
      } = this.props;
      const {
        statusModal,
        nowStatus,
        selectedStatusValue,
        statusReason
      } = candStatus;
      const steps = [
        {
          element: ".recommend-position-icon",
          hintPosition: 'top-right',
          highlightClass: "talent-helper",
          intro:
            "这个小飞机，是给这个候选人推荐职位"
        },
        {
          element: ".add-record-icon",
          hintPosition: 'top-right',
          highlightClass: "talent-helper",
          intro:
            "这个小本子点开也是添加comments，懂了吧"
        },
      ];
      return (
        <React.Fragment>
          <Drawer
            width={900}
            placement="right"
            className="info-drawer-box resume-drawer drawer-modal"
            title={
              <div className="drawer-title clear">
                <span>创建人: </span>
                <Tooltip
                  title={baseInfo.created_by_org_name}
                  placement="bottomLeft"
                >
                  {baseInfo.created_by}
                </Tooltip>
                <span
                  className="drawer-title-resumeview"
                  onClick={this.resumePreview}
                >
                  <Icon type="eye" /> 简历预览
                </span>
                <span className="right">入库时间：{baseInfo.created_time}</span>
              </div>
            }
            onClose={this.onDrawerClose}
            visible={true}
            style={{
              paddingBottom: 53
            }}
          >
           
            <div ref={this.modelRef} />
            <Modal
              // getContainer={() => this.modelRef.current}
              title={
                <React.Fragment>
                  <span>人选{selectedStatusValue.label}</span>
                  <span
                    style={{
                      color: "#666",
                      fontSize: 14,
                      fontWeight: 500,
                      marginLeft: 5
                    }}
                  />
                </React.Fragment>
              }
              visible={statusModal}
              className="cand-status-modal"
              onOk={this.statusModalOk}
              onCancel={this.statusModalCancel}
            >
              <p>
                请填写{selectedStatusValue.label}理由，由负责人审核通过后完成
                {selectedStatusValue.label}
              </p>
              <Form>
                <Form.Item
                  validateStatus={this.state.statusReason.validateStatus}
                  help={this.state.statusReason.help}
                >
                  <TextArea
                    value={statusReason}
                    onChange={this.onStatusReason}
                    placeholder="请输入至少5个字符"
                  />
                </Form.Item>
              </Form>
              {selectedStatusValue.key === 1 ? (
                <React.Fragment>
                  <Button
                    className="defaultReason"
                    onClick={this.setReasonOnJob}
                  >
                    目前在职，在看机会
                  </Button>
                  <Button
                    className="defaultReason"
                    onClick={this.setReasonAgain}
                  >
                    简历完善，重新上架
                  </Button>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <Button
                    className="defaultReason"
                    onClick={this.setReasonUnline}
                  >
                    下架，更新信息
                  </Button>
                  <Button className="defaultReason" onClick={this.setReasonNO}>
                    人选更新，不看机会
                  </Button>
                </React.Fragment>
              )}
              {selectedStatusValue.key === 1 ? (
                <div
                  className="clear cand-status-ps"
                  style={{ marginTop: 20, color: "#ff4b54" }}
                >
                  <label className="left">提示：</label>
                  <div className="left">
                    <p style={{ marginBottom: 5 }}>
                      1. 未上架人选已支持推荐，上架目的是加入官网供客户预约；
                    </p>
                    <p style={{ marginBottom: 5 }}>
                      2. 上架前请先预览样式，完善更多信息，调整格式，再上架！
                    </p>
                  </div>
                </div>
              ) : null}
            </Modal>
            <CommunicateModal
              getJDList={getJDList}
              communicationRecords={communicationRecords}
              addCommunicate={addCommunicate}
              candidateId={candidateId}
              updateCommunicationRecords={updateCommunicationRecords}
              callBack={this.communicationRefresh}
            />
            <Spin spinning={loading}>
              <div className="second-header clear">
                <div className="left">
                  <div className="who">
                    <span className="name ellipsis" title={baseInfo.name}
                      style={{display: "inline-block", verticalAlign: "top", maxWidth: "160px"}}>{baseInfo.name}</span>
                    <span>No:{baseInfo.no}</span>
                    <Tag color="green">{baseInfo.job_intention_label}</Tag>
                    {
                      baseInfo.is_ugeetemp === 1 &&
                        <Tag color="purple"><CustomIcon style={{fontSize: "14px"}} type="icon-jp_logo"/>优职用户</Tag>
                    }
                  </div>
                  <div className="complete-level">
                    资料完善度 : <span>{baseInfo.compete}%</span>
                  </div>
                </div>
                <div className="right">
                  {openFrom === "sysmsg" && !!baseInfo.audit ? (
                    <React.Fragment>
                      <AuditModal
                        auditTarget={baseInfo.audit_id}
                        auditCallBack={this.auditCallBack}
                      />
                      <Button
                        type="primary"
                        className="audit-btn"
                        onClick={this.auditClick}
                      >
                        审核
                      </Button>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <span className="status">
                        <Badge
                          status={
                            !!baseInfo.audit
                              ? "warning"
                              : baseInfo.online == 1
                              ? "success"
                              : "default"
                          }
                          text={
                            !!baseInfo.audit ? "审核中" : baseInfo.status_label
                          }
                        />
                      </span>
                      <Select
                        disabled={!!baseInfo.audit}
                        placeholder="切换状态"
                        onSelect={this.onStatusSelect}
                        labelInValue
                        value={
                          baseInfo.audit
                            ? baseInfo.online == 1
                              ? { key: 0, label: "下架" }
                              : { key: 1, label: "上架" }
                            : undefined
                        }
                        // value={nowStatus}
                      >
                        {baseInfo.online == 1 ? (
                          <Option key={0} value={0}>
                            下架
                          </Option>
                        ) : (
                          <Option key={1} value={1}>
                            上架
                          </Option>
                        )}
                      </Select>
                    </React.Fragment>
                  )}
                  <div className="icon-sets">
                    <Popconfirm
                        placement="bottom"
                        title={`${
                          is_collection===1 ? "取消后，已收藏的人选不在显示" : "确认收藏吗"
                        }`}
                        onConfirm={this.confirm}
                        okText="确定"
                        cancelText="取消"
                      >
                      <span style={{cursor:"pointer"}} className="collect-talent"  title="收藏人选">
                        {is_collection===1 ? (
                          <React.Fragment>
                            <Icon
                              type="heart"
                              theme="filled"
                              twoToneColor="#f03a50"
                              style={{ color: "#f03a50", fontSize:"30px"}}
                            />
                            <div>已收藏</div>
                          </React.Fragment>
                        ) : (
                          <React.Fragment>
                            <Icon type="heart" style={{ fontSize:"30px"}} />
                            <div>收藏</div>
                          </React.Fragment>
                        )}
                      </span>
                    </Popconfirm>
                    {(baseInfo.audit == 0 && user.roles !== "special_group_leader" && user.roles !== "business" && user.roles !== "operator") ? (
                      <span className="recommend-span" >
                        <CustomIcon
                          className="icon-button recommend-position-icon"
                          type="icon-icon_tuisong"
                          title="推荐职位"
                          onClick={this.onRecomPos}
                        />
                        <div>推荐职位</div>
                      </span>
                    ) : null}
                    <span className="record-span" >
                      <CustomIcon
                        className="icon-button icon-record add-record-icon"
                        type="icon-icon_tianjiagoutongjilu"
                        title="添加沟通记录"
                        onClick={this.addCommunication}
                      />
                      <div>沟通记录</div>
                    </span>
                 </div>
                </div>
              </div>
              {/* { baseInfo && baseInfo.no && <StepIntro stepEnable={true} name="talentDetail" steps={steps}/> } */}
              <Tabs
                type="card"
                className="resume-drawer-tabs"
                defaultActiveKey="details"
              >
                <TabPane tab="详细信息" key="details" className="details">
                  <Radio.Group className="resume-radio" value={resumeType} buttonStyle="solid" onChange={this.changeResume}>
                    <Radio.Button value="1">标准简历</Radio.Button>
                    <Radio.Button value="2">原始简历</Radio.Button>
                  </Radio.Group>
                  {
                    resumeType === "1" ? 
                      <Resume />
                      :
                      <OriginResume resumeAttachment={resumeAttachment} candidateId={candidateId}/>
                  }
                </TabPane>
                <TabPane 
                  tab={`职位进展${
                    positionProgresses.length > 0
                    ? `(${positionProgresses.length}条)`
                    : ""
                  }`} 
                  key="progress">
                  <Collapse
                    defaultActiveKey={["1"]}
                    className="collapse-handle-box"
                  >
                    <Panel header="关联职位" key="1">
                      {positionProgresses.length ? (
                        positionProgresses.map((item, index) => (
                          <div
                            className="process position"
                            key={item.jd_id}
                            onClick={this.goPosition.bind(this, item.jd_id)}
                          >
                            <h2>
                              <div className="ellipsis post_name">{item.post_name}</div>/{item.require_num}人{" "}
                              <span>NO.{item.jd_no}</span>
                            </h2>
                            <p>{item.company}</p>
                            <p>
                              {item.update_time}{" "}
                              {item.update_user
                                ? `由${item.update_user}推荐到职位`
                                : ""}
                            </p>
                            <span
                              className="process-status"
                              style={{
                                backgroundColor: this.processColor(item.status)
                              }}
                            >
                              {item.status_label}
                            </span>
                            <span className="jd-status">
                              <Tooltip title={item.jd_status_label}>
                                {item.jd_status === 5 && (
                                  <CustomIcon
                                    className="icon-status"
                                    type="icon-close-circle-fill"
                                  />
                                )}
                                {item.jd_status === 3 && (
                                  <CustomIcon
                                    className="icon-status"
                                    type="icon-poweroff-circle-fill"
                                  />
                                )}
                                {item.jd_status === 1 && (
                                  <CustomIcon
                                    className="icon-status"
                                    type="icon-time-circle-fill"
                                  />
                                )}
                                {item.jd_status === 4 && (
                                  <CustomIcon
                                    className="icon-status"
                                    type="icon-check-circle-fill"
                                  />
                                )}
                              </Tooltip>
                            </span>
                          </div>
                        ))
                      ) : (
                        <Empty description="暂无关联职位" />
                      )}
                    </Panel>
                  </Collapse>
                </TabPane>
                <TabPane
                  tab={`沟通记录${
                    communicationRecords.list.length > 0
                      ? `(${communicationRecords.list.length}条)`
                      : ""
                  } `}
                  key="records"
                >
                  <CommunicateRecords modelRef={this.modelRef} />
                </TabPane>
                {/* <TabPane tab={`简历附件${resumeAttachment.length > 0 ? `(${resumeAttachment.length}条)` : "" }`} key="affix">
                  <ResumeAffix />
                </TabPane> */}
              </Tabs>
            </Spin>
          </Drawer>
          <ProfileDetail
            visible={previewVisible}
            onChange={this.onResumePreChange}
            getProfileDetail={this.props.getProfileDetail}
            profileDetail={this.props.profileDetail}
            candId={this.props.candidateId}
          />
          <style jsx global>{`
            .resume-drawer {
              .drawer-title-resumeview {
                cursor: pointer;
                margin-left: 30px;
              }
              .ant-drawer-body {
                font-size: 12px;
                padding: 0;
                position: relative;
                .resume-affix-upload {
                  .ant-upload.ant-upload-drag .ant-upload-btn {
                    height: 480px;
                  }
                }
                .resume-drawer-tabs {
                  padding: 0 20px;
                  .resume-radio {
                    margin-top: 10px;
                    margin-bottom: 20px; 
                  }
                }

                .ant-tabs-bar {
                  margin-bottom: 10px;
                }

                .details {
                  .ant-alert-info {
                    padding: 0 10px;
                    margin-bottom: 5px;
                  }
                }
              }
              .edit-view-multi {
                .edit-view-single {
                  position: relative;
                  .func-group {
                    position: absolute;
                    top: 10px;
                    right: 0;
                    z-index: 100;
                  }
                }
              }
            }
            .cand-status-modal {
              .defaultReason {
                margin-top: 10px;
                margin-right: 5px;
              }
              .ant-form-item {
                margin-bottom: 0;
              }
            }
            .talent-helper {
              background-color: rgba(255,255,255,.5);
            }
          `}</style>
          <style jsx>
            {`
              .second-header {
                height: 94px;
                background-color: #f9f9f9;
                margin-bottom: 18px;
                padding: 0 25px;
                .right {
                  margin-right: 38px;
                  margin-top: 31px;
                  .status {
                    margin-right: 10px;
                  }
                  :global(.ant-select) {
                    margin-right: 20px;
                    width: 120px;
                  }
                  :global(.icon-button) {
                    margin-right: 20px;
                    font-size: 30px;
                    vertical-align: top;
                    cursor: pointer;
                  }
                  :global(.anticon-heart) {
                    margin-right: 20px;
                  }
                  :global(.icon-record) {
                    margin-right: 0;
                  }
                  :global(.audit-btn) {
                    margin-right: 10px;
                  }
                  .icon-sets {
                    display: inline-block;
                    .collect-talent, .recommend-span, .record-span {
                      display: inline-block;
                      width: 50px;
                      margin-right: 6px;
                      vertical-align: middle;
                      :global(.anticon) {
                        margin-left: 9px;
                      }
                      div {
                        margin-top: 7px;
                        font-size: 12px;
                        color: #666666;
                        text-align: center;
                      }
                    }
                  }
                }
                .left {
                  margin-top: 23px;
                  .name {
                    font-size: 20px;
                    color: #333333;
                    margin-right: 20px;
                  }

                  .name + span {
                    font-size: 14px;
                    color: #666666;
                    margin-right: 10px;
                  }
                  .complete-level {
                    font-size: 14px;
                    color: #666666;
                    margin-top: 15px;
                    span {
                      font-size: 14px;
                      color: #ff4b54;
                    }
                  }
                }
              }
              .position {
                h2 {
                  font-size: 16px;
                  font-weight: 600;
                  margin-bottom: 13px;
                  .post-name {
                    font-size: 16px;
                    font-weight: 600;
                    margin-left: 0;
                    color: #333;
                    cursor: pointer;
                  }
                  span {
                    margin-left: 20px;
                    font-size: 12px;
                    font-weight: normal;
                    color: #666666;
                  }
                }
                .post_name {
                  display: inline-block;
                  max-width: 400px;
                  vertical-align: top;
                }
                h2 + p {
                  color: #666666;
                  margin-bottom: 16px;
                }
                p + p {
                  color: #999999;
                }
              }
              .process {
                position: relative;
                margin-left: 4px;
                width: 798px;
                height: 115px;
                background-color: #fafafa;
                border-radius: 4px;
                padding: 20px 10px;
                border: solid 1px #d9d9d9;
                margin-bottom: 10px;
                .process-status {
                  position: absolute;
                  top: 0;
                  right: 0;
                  color: #fff;
                  padding: 7px 10px 7px 30px;
                  border-bottom-left-radius: 35px;
                }
                .jd-status {
                  position: absolute;
                  bottom: 10px;
                  right: 10px;
                  :global(.icon-status) {
                    font-size: 20px;
                  }
                }
              }
            `}
          </style>
        </React.Fragment>
      );
    }
  };
}
