import React from "react";

import configureStore from "store";
import routes from "routes";
import "react-quill/dist/quill.snow.css";
import styles from "assets/styles/global.scss";
import createStaticRoutes from "utils/createStaticRoutes";
import renderRoutes from "utils/renderRoutes";

// import * as Sentry from "@sentry/react";

const requireModels = require.context("./store/reducers", false, /\.js$/);
requireModels.keys().forEach(filename => {
  configureStore.pushModel(requireModels(filename).default);
});

// if (process.env.NODE_ENV !== "development") {
//   Sentry.init({
//     dsn: "https://19b84cb04a234b1596ac66f52937da21@gt.geedos.com/15",
//   });
// }

let staticRoutes = createStaticRoutes(routes());
const App = () => (
  <React.Fragment>
    {renderRoutes(staticRoutes)}
    <style jsx global>
      {styles}
    </style>
  </React.Fragment>
);

export default App;
export { configureStore, staticRoutes };
