import React, { PureComponent } from "react";
import { connect } from "react-redux";
import {
  Icon,
  Select,
  Input,
  Row,
  Col,
  Button,
  Form,
  Cascader,
  Spin,
  InputNumber,
  Checkbox,
  Message,
  Radio,
} from "antd";
import PositionVacantModal from "store/reducers/positionVacantM";
import CityCascade from "components/CityCascade";
import InputComplete from "components/InputComplete";
import MultiCascade from "components/OrgMultiCascade";
import * as companyService from "services/companyService";
import _ from "underscore";

const InputGroup = Input.Group;
const Option = Select.Option;
const FormItem = Form.Item;
const TextArea = Input.TextArea;
const RadioGroup = Radio.Group;

let timeout;
let currentValue;
const CreatePosition = Form.create()(
  class CreatePositionForm extends PureComponent {
    state = {
      optionCompany: [],
      value: undefined,
      fetching: false,
      contactsoption: [],
      contactId: undefined, //默认的联系人id
      positionName: {},
      keys: [0],
      getCompanyId: "" //选择公司的名字给职位进行搜索
    };
    handleSubmit = e => {
      const { validateFieldsAndScroll } = this.props.form;
      const { getPositionList, positionAdd, onClose } = this.props;
      e.preventDefault();
      validateFieldsAndScroll((err, values) => {
        if (!err) {
          const dataParam = {
            ...values
          };
          let jd_manager = _.filter(values.jd_manager);
          dataParam.address = values.address.map((item, index) => {
            let currentCity = values.city[index] ? values.city[index] : [];
            return {
              address: item,
              province_id: currentCity[0] ? currentCity[0] : "",
              city_id: currentCity[1] ? currentCity[1] : "",
              region_id: currentCity[2] ? currentCity[2] : ""
            };
          });
          if (
            parseFloat(dataParam.salary_from) > parseFloat(dataParam.salary_to)
          ) {
            Message.error("起始薪资不能大于终止薪资");
            return;
          }
          dataParam.salary_from && (dataParam.salary_from += "");
          dataParam.salary_to && (dataParam.salary_to += "");
          // dataParam.salary_from = dataParam.salary_from ? (dataParam.salary_from + "") : "";
          // dataParam.salary_to = dataParam.salary_to ? (dataParam.salary_to + "") : "";
          dataParam.salary_ind = values.salary_ind ? 1 : 0;
          dataParam.hh_id = jd_manager[jd_manager.length - 1];
          dataParam.source = values.source[values.source.length - 1];
          dataParam.position =
            values.positionArray &&
            values.positionArray[values.positionArray.length - 1];
          dataParam.hh_executive_id =
            values.hh_executive_id &&
            values.hh_executive_id.map(item => {
              return item.lastUserId;
            });
          if (dataParam.some) dataParam.require_num = 0;

          delete dataParam.city;
          delete dataParam.jd_manager;
          positionAdd({ ...dataParam }).then(res => {
            if (res.code === 0) {
              getPositionList();
              onClose("success");
            }
          });
        }
      });
    };

    /**
     * @description 获取公司
     * @param {number} value   改变的值
     */
    fetch = (value, callback) => {
      if (timeout) {
        clearTimeout(timeout);
        timeout = null;
      }
      if (!value) return;
      const _this = this;
      const {getFieldValue} = this.props.form;
      let isOpen = getFieldValue("is_gbox_public");
      currentValue = value;
      function fake() {
        _this.setState({ fetching: true });
        const expand =
          "bd_name,city_name,industry_name,contacts,jds,jd_profiles,add_time_str,last_follow_time,source_p";
        if (isOpen) {
          companyService.gboxCompanyList({
            name: value,
            from: 1,
            status: 3,
            expand: "contacts",
            "per-page": 99999
          }).then(res => {
            debugger
            if (currentValue === value && res.code === 0) {
              const result = res.data.list || [];
              const data = [];
              result.forEach(r => {
                data.push({
                  value: r.id,
                  text: r.name,
                  contacts: r.contacts,
                  dataSource: r
                });
              });
              callback(data);
            }
            _this.setState({ fetching: false });
          });
        } else {
        companyService
          .companyList({ expand, "per-page": 99999, keyword: value })
          .then(res => {
            if (currentValue === value && res.code === 0) {
              const result = res.data.list || [];
              const data = [];
              result.forEach(r => {
                data.push({
                  value: r.id,
                  text: r.name,
                  contacts: r.contacts,
                  dataSource: r
                });
              });
              callback(data);
            }
            _this.setState({ fetching: false });
          });
        }
      }
      timeout = setTimeout(fake, 500);
    };

    /**
     * @description 输入搜索公司
     * @param {number} value   改变的值
     */
    handleSearch = value => {
      this.fetch(value, optionCompany => this.setState({ optionCompany }));
    };
    /**
     * @description 输入赋值
     * @param {number} value   改变的值
     */
    handleChange = (value, option) => {
      debugger;
      const { setFieldsValue } = this.props.form;
      const { contactsoption = [],  data: { dataSource } } = option.props;
      //由于要取选中的公司下大联系人和电话所写
      setFieldsValue({
        company_id: value,
        company_contact_id: contactsoption[0].id,
        contactor: undefined,
        source: [dataSource.source_p, dataSource.source].filter(item => !!item)
      });
      this.setState({
        contactsoption: contactsoption,
        contactId: contactsoption[0].id,
        getCompanyId: value
      });
    };

    addWorkRow = () => {
      const { keys } = this.state;
      let currentKey = keys[keys.length - 1] ? keys[keys.length - 1] : 0;
      this.setState({
        keys: keys.concat(currentKey + 1)
      });
    };

    removeWorkRow = key => {
      const { keys } = this.state;
      this.setState({
        keys: keys.filter(item => item !== key)
      });
    };

    clearCompanyContactor = () => {
      const { resetFields } = this.props.form;
      resetFields(["company_id","company_contact_id"])
      this.setState({
        contactsoption: [],
        contactId: undefined,
        optionCompany: [],
      })
    }

    render() {
      
      const formItemLayout = {
        labelCol: { span: 5 },
        wrapperCol: { span: 19 }
      };
      const formNumber = {
        labelCol: { span: 6 },
        wrapperCol: { span: 17 }
      };
      const formItemLayoutSingle = {
        labelCol: { span: 2 },
        wrapperCol: { span: 20 }
      };
      const colItemLayout = {
        span: 11
      };
      const size = "large";
      const { onClose, commonData = {}, user = {} } = this.props;
      const { org_info = {} } = user;
      const {
        org_city = {},
        org_team = {},
        org_group = {},
        org_user = {}
      } = org_info;
      const { getFieldDecorator, getFieldValue, resetFields } = this.props.form;
      let salaryInd = getFieldValue("salary_ind");
      let some = getFieldValue("some");
      let source = getFieldValue("source");
      let isRecruit = source && source[1] === 31;
      const {
        fetching,
        contactsoption,
        optionCompany,
        contactId,
        keys
      } = this.state;
      return (
        <div className="position-info-create">
          <Form
            onSubmit={this.handleSubmit}
            className="edit-form"
            layout="horizontal"
          >
            <div className="companyInfo">
              <h4>公司信息</h4>
              <Row gutter={35}>
                <FormItem label="是否对客户公开职位进展（客户公司需在禾蛙盒子认证通过）" labelCol={{span: 12}} wrapperCol={{span: 12}}>
                    {getFieldDecorator("is_gbox_public", {
                      initialValue: 0,
                      rules: [{ required: false, message: "请选择" }]
                    })(
                      <RadioGroup onChange={this.clearCompanyContactor}>
                        <Radio value={1}>是</Radio>
                        <Radio value={0}>否</Radio>
                      </RadioGroup>
                    )}
                </FormItem>
              </Row>
              <Row gutter={35}>
                <Row>
                  <Col {...colItemLayout}>
                    <FormItem label="公司名称" {...formItemLayout}>
                      {getFieldDecorator("company_id", {
                        rules: [{ required: true, message: "请输入公司名称" }]
                      })(
                        <Select
                          allowClear
                          size={size}
                          showSearch
                          placeholder="请输入"
                          defaultActiveFirstOption={false}
                          showArrow={false}
                          filterOption={false}
                          onSearch={this.handleSearch}
                          onChange={this.handleChange}
                          notFoundContent={
                            fetching ? <Spin size="small" /> : null
                          }
                        >
                          {optionCompany.map((d, index) => (
                            <Option
                              key={d.value}
                              value={d.value}
                              data={d}
                              contactsoption={d.contacts}
                            >
                              {d.text}
                            </Option>
                          ))}
                        </Select>
                      )}
                    </FormItem>
                  </Col>
                  <Col {...colItemLayout}>
                    <FormItem label="联系人" {...formItemLayout}>
                      {getFieldDecorator("company_contact_id", {
                        initialValue: contactId,
                        rules: [{ required: true, message: "请输入联系人" }]
                      })(
                        <Select
                          size={size}
                          allowClear
                          placeholder="请选择"
                          disabled={contactsoption && !contactsoption.length}
                        >
                          {contactsoption &&
                            contactsoption.map((item, index) => {
                              return (
                                <Option key={index} value={item.id}>
                                  {item.contactor}/{item.phone}
                                </Option>
                              );
                            })}
                        </Select>
                      )}
                    </FormItem>
                  </Col>
                </Row>
              </Row>
            </div>

            <div className="positionInfo">
              <h4>职位信息</h4>
              <Row gutter={35}>
                <Row>
                  <Col {...colItemLayout}>
                    <FormItem label="职位名称" {...formItemLayout}>
                      {getFieldDecorator("post_name", {
                        rules: [{ required: true, message: "请输入职位名称" }]
                      })(
                        <InputComplete
                          size={size}
                          setSearchVal={this.state.getCompanyId}
                          requestFunc={companyService.getJdsByName}
                          placeholder="请输入"
                        />
                      )}
                    </FormItem>
                  </Col>
                  <Col {...colItemLayout}>
                    <FormItem
                      label="职&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;能"
                      {...formItemLayout}
                    >
                      {getFieldDecorator("positionArray", {
                         rules: [{ required: true, message: "请选择职能" }]
                      })(
                        <Cascader
                          size={size}
                          options={commonData.dispatch_position}
                          fieldNames={{
                            label: "label",
                            value: "value",
                            children: "list"
                          }}
                          placeholder="请选择"
                        />
                      )}
                    </FormItem>
                  </Col>
                </Row>
                <Row>
                  <Col {...colItemLayout}>
                    <Col span={19}>
                      <FormItem label="需求人数" {...formNumber}>
                        {getFieldDecorator("require_num", {
                          rules: [
                            { required: !some, message: "请输入需求人数" }
                          ]
                        })(
                          <Input
                            disabled={some}
                            style={{ marginLeft: 4 }}
                            placeholder="请输入"
                            size={size}
                          />
                        )}
                      </FormItem>
                    </Col>
                    <Col span={5}>
                      <FormItem label="">
                        {getFieldDecorator("some", {
                          initialValue: false,
                          rules: [],
                          valuePropName: "checked"
                        })(
                          <Checkbox
                            onChange={() => resetFields(["require_num"])}
                            style={{ width: "100%" }}
                          >
                            若干
                          </Checkbox>
                        )}
                      </FormItem>
                    </Col>
                  </Col>
                  <Col {...colItemLayout}>
                    <FormItem
                      label="学&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;历"
                      {...formItemLayout}
                    >
                      {getFieldDecorator("education", {
                        initialValue: 5,
                        rules: [{ required: false }]
                      })(
                        <Select size={size} allowClear placeholder="请输选择">
                          {commonData.jd_education &&
                            commonData.jd_education.map(item => {
                              return (
                                <Option key={item.value} value={item.value}>
                                  {item.label}
                                </Option>
                              );
                            })}
                        </Select>
                      )}
                    </FormItem>
                  </Col>
                </Row>
                <Row>
                  <Col {...colItemLayout}>
                    <FormItem label="服务类型" {...formItemLayout}>
                      {getFieldDecorator("service_type", {
                        initialValue: 1,
                        rules: [{ required: true, message: "请选择服务类型" }]
                      })(
                        <Select size={size} allowClear placeholder="请输选择">
                          {commonData.service_type &&
                            commonData.service_type.map((item, index) => {
                              return (
                                <Option key={index} value={item.value}>
                                  {item.label}
                                </Option>
                              );
                            })}
                        </Select>
                      )}
                    </FormItem>
                  </Col>
                  <Col {...colItemLayout}>
                    <FormItem label="职位性质" {...formItemLayout}>
                      {getFieldDecorator("work_type", {
                        initialValue: 1,
                        rules: [{ required: false }]
                      })(
                        <Select
                          size={size}
                          allowClear
                          placeholder="请选择职位性质"
                        >
                          {commonData.work_type &&
                            commonData.work_type.map((item, index) => {
                              return (
                                <Option key={index} value={item.value}>
                                  {item.label}
                                </Option>
                              );
                            })}
                        </Select>
                      )}
                    </FormItem>
                  </Col>
                </Row>

                <Row>
                  {/* <Col {...colItemLayout}>
                    <FormItem
                      label="薪资"
                      {...formItemLayout}
                    >
                      {getFieldDecorator("salary", {
                        initialValue: 3,
                        rules: [{ required: false }]
                      })(
                        <Select size={size} allowClear placeholder="请输选择">
                          {commonData.jd_salary &&
                            commonData.jd_salary.map((item, index) => {
                              return (
                                <Option key={index} value={item.value}>
                                  {item.label}
                                </Option>
                              );
                            })}
                        </Select>
                      )}
                    </FormItem>
                  </Col> */}
                  <Col {...colItemLayout}>
                    <FormItem label="工作经验" {...formItemLayout}>
                      {getFieldDecorator("work_time", {
                        initialValue: 1,
                        rules: [{ required: true, message: "请选择工作经验" }]
                      })(
                        <Select
                          size={size}
                          allowClear
                          placeholder="请选择工作经验"
                        >
                          {commonData.jd_work_time &&
                            commonData.jd_work_time.map((item, index) => {
                              return (
                                <Option key={index} value={item.value}>
                                  {item.label}
                                </Option>
                              );
                            })}
                        </Select>
                      )}
                    </FormItem>
                  </Col>
                  <Col {...colItemLayout}>
                    <FormItem label="薪酬结构" {...formItemLayout}>
                      {getFieldDecorator("salary_structure", {
                        rules: [{ required: false }]
                      })(<Input placeholder="请输入" size={size} />)}
                    </FormItem>
                  </Col>
                </Row>

                <Row>
                  <Col {...colItemLayout}>
                    <FormItem label="薪资类型" {...formItemLayout}>
                      {getFieldDecorator("salary_type", {
                        rules: [
                          { required: !salaryInd, message: "请选择薪资类型" }
                        ]
                      })(
                        <Select
                          size={size}
                          disabled={salaryInd}
                          allowClear
                          placeholder="请选择薪资类型"
                        >
                          {commonData.jd_salary_type &&
                            commonData.jd_salary_type.map((item, index) => {
                              return (
                                <Option key={index} value={item.value}>
                                  {item.label}
                                </Option>
                              );
                            })}
                        </Select>
                      )}
                    </FormItem>
                  </Col>
                  <Col {...colItemLayout}>
                    <Row gutter={8} className="input-group-row ">
                      <InputGroup>
                        <Col
                          span={3}
                          className="col-label col-label-3 ant-form-item-required"
                        >
                          薪资(元)
                        </Col>
                        <Col span={6}>
                          <FormItem>
                            {getFieldDecorator("salary_from", {
                              rules: [
                                { required: !salaryInd, message: "最低薪资" }
                              ]
                            })(
                              <InputNumber
                                disabled={salaryInd}
                                min={1}
                                precision={0}
                                placeholder="最低"
                                size={size}
                                style={{ width: "100%" }}
                              />
                            )}
                          </FormItem>
                        </Col>
                        <Col span={1} className="col-label">
                          -
                        </Col>
                        <Col span={6}>
                          <FormItem label="">
                            {getFieldDecorator("salary_to", {
                              rules: [
                                { required: !salaryInd, message: "最高薪资" }
                              ]
                            })(
                              <InputNumber
                                disabled={salaryInd}
                                min={1}
                                precision={0}
                                placeholder="最高"
                                size={size}
                                style={{ width: "100%" }}
                              />
                            )}
                          </FormItem>
                        </Col>
                        <Col span={6}>
                          <FormItem label="">
                            {getFieldDecorator("salary_ind", {
                              initialValue: false,
                              rules: [],
                              valuePropName: "checked"
                            })(
                              <Checkbox
                                onChange={() =>
                                  resetFields([
                                    "salary_type",
                                    "salary_from",
                                    "salary_to"
                                  ])
                                }
                                style={{ width: "100%" }}
                              >
                                面议
                              </Checkbox>
                            )}
                          </FormItem>
                        </Col>
                      </InputGroup>
                    </Row>
                  </Col>
                </Row>

                {keys &&
                  keys.map(key => {
                    return (
                      <Row key={key}>
                        <Col {...colItemLayout}>
                          <FormItem label="工作地点" {...formItemLayout}>
                            {getFieldDecorator(`city[${key}]`, {
                              rules: [
                                { required: true, message: "请选择工作地点" }
                              ]
                            })(
                              <CityCascade
                                changeOnSelect
                                size={size}
                                options={commonData.cities}
                                fieldNames={{
                                  label: "name",
                                  value: "id",
                                  children: "list"
                                }}
                                placeholder="请选择"
                              />
                            )}
                          </FormItem>
                        </Col>
                        <Col {...colItemLayout}>
                          <FormItem label="详细地址" {...formItemLayout}>
                            {getFieldDecorator(`address[${key}]`, {
                              rules: [{ required: false }]
                            })(<Input placeholder="请输入" size={size} />)}
                          </FormItem>
                        </Col>
                        <Col span={1}>
                          <span
                            className="ant-row ant-form-item"
                            style={{ paddingTop: "12px" }}
                          >
                            {keys.length > 1 && (
                              <Icon
                                type="minus-circle-o"
                                onClick={() => this.removeWorkRow(key)}
                              />
                            )}
                          </span>
                        </Col>
                      </Row>
                    );
                  })}

                <Row>
                  <Col
                    {...colItemLayout}
                    style={{ paddingLeft: "83px", marginBottom: "20px" }}
                  >
                    <Button
                      icon="plus"
                      size="large"
                      onClick={() => this.addWorkRow()}
                    >
                      新增工作地点
                    </Button>
                  </Col>
                </Row>

                <Row className="single-row">
                  <FormItem label="福利" {...formItemLayoutSingle}>
                    {getFieldDecorator("benefits", {
                      rules: [{ required: false }]
                    })(
                      <Select
                        mode="multiple"
                        allowClear showSearch optionFilterProp="children"
                        size={size}
                        style={{ width: "100%" }}
                        placeholder="请输选择"
                      >
                        {commonData.jd_benefit &&
                          commonData.jd_benefit.map((item, index) => {
                            return (
                              <Option key={index} value={item.value}>
                                {item.label}
                              </Option>
                            );
                          })}
                      </Select>
                    )}
                  </FormItem>
                </Row>
                <Row className="single-row">
                  <FormItem label="职位描述" {...formItemLayoutSingle}>
                    {getFieldDecorator("jd_desc", {
                      rules: [{ required: true, message: "请输入职位描述" }]
                    })(<TextArea rows={5} placeholder="请输入职位描述" />)}
                  </FormItem>
                </Row>
                <Row className="single-row">
                  <FormItem label="任职要求" {...formItemLayoutSingle}>
                    {getFieldDecorator("requirements", {
                      // rules: [{ required: true, message: "请输入任职要求" }]
                    })(<TextArea rows={5} placeholder="请输入任职要求" />)}
                  </FormItem>
                </Row>
              </Row>
            </div>
            <div className="principal">
              <h4>负责人</h4>
              <Row gutter={35}>
                <Col span={12}>
                  <FormItem label="负责顾问" {...formItemLayout}>
                    {getFieldDecorator("jd_manager", {
                      initialValue: [
                        org_city.id,
                        org_team.id,
                        org_group.id,
                        org_user.id
                      ],
                      rules: [{ required: true, message: "请选择负责顾问" }]
                    })(
                      <Cascader
                        changeOnSelect={false}
                        expandTrigger={"click"}
                        showSearch
                        size={size}
                        options={commonData.jd_manager}
                        fieldNames={{
                          label: "name",
                          value: "id",
                          children: "list"
                        }}
                        placeholder="请选择"
                      />
                    )}
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem label="协助顾问" {...formItemLayout}>
                    {getFieldDecorator("hh_executive_id", {
                      // initialValue: [user.city_id, user.org_city_id, user.id],
                      rules: [{ required: false, message: "请选择协助顾问" }]
                    })(
                      <MultiCascade
                        showSearch={true}
                        changeOnSelect={false}
                        expandTrigger={"click"}
                        needUser={true}
                        selsize={2}
                        options={commonData.jd_manager}
                        fieldNames={{
                          label: "name",
                          value: "id",
                          children: "list"
                        }}
                        placeholder="请选择协助顾问"
                        notFoundContent="没有选项"
                        size="large"
                      />
                    )}
                  </FormItem>
                </Col>
              </Row>
              <Row gutter={35}>
                <Col span={12}>
                  <FormItem label="职位来源" {...formItemLayout}>
                    {getFieldDecorator("source", {
                      // initialValue: [9,1],
                      rules: [{ required: true, message: "请选择职位来源" }]
                    })(
                      <Cascader
                        className="gee-cascader custome-type"
                        expandTrigger="hover"
                        changeOnSelect
                        size="large"
                        fieldNames={{
                          label: "label",
                          value: "value",
                          children: "list"
                        }}
                        options={commonData.jd_source}
                        placeholder="请选择来源"
                      />
                    )}
                  </FormItem>
                </Col>
                {isRecruit &&
                  <Col span={12}>
                    <FormItem label="直线经理" {...formItemLayout}>
                      {getFieldDecorator("line_manager", {
                        // initialValue: [9,1],
                        // rules: [{ required: true, message: "请选择直线经理" }]
                      })(
                        <Input placeholder="请输入" size={size} />
                      )}
                    </FormItem>
                  </Col>
                }
              </Row>
              {isRecruit &&
                <Row gutter={35}>
                  <Col span={12}>
                    <FormItem label="部门主管" {...formItemLayout}>
                      {getFieldDecorator("dep_heads", {
                        // initialValue: [9,1],
                        // rules: [{ required: true, message: "请选择部门主管" }]
                      })(
                        <Input placeholder="请输入" size={size} />
                      )}
                    </FormItem>
                  </Col>
                  <Col span={12}>
                    <FormItem label="部门架构" {...formItemLayout}>
                      {getFieldDecorator("dep_structure", {
                        // initialValue: [9,1],
                        // rules: [{ required: true, message: "请选择部门架构" }]
                      })(
                        <Input placeholder="请输入" size={size} />
                      )}
                    </FormItem>
                  </Col>
                </Row>
              }
            </div>

            <div
              style={{
                position: "absolute",
                bottom: 0,
                width: "100%",
                borderTop: "1px solid #e8e8e8",
                padding: "10px 16px",
                textAlign: "left",
                left: 0,
                background: "#fff",
                borderRadius: "0 0 4px 4px"
              }}
            >
              <Button
                style={{ marginRight: 8 }}
                type="primary"
                onClick={this.handleSubmit}
              >
                保存
              </Button>
              <Button onClick={onClose}>取消</Button>
            </div>
          </Form>
          <style jsx global>{`
            .position-info-create {
              padding: 24px 24px 55px 24px;
              .companyInfo h4 {
                margin-top: 0;
              }
              h4 {
                font-size: 16px;
                font-weight: 600;
                margin-bottom: 40px;
                margin-top: 40px;
              }
              .ant-row {
                padding-left: 10px;
              }
              .input-group-row .ant-row {
                padding-left: 0;
              }
              .col-label-3 {
                width: 20.33%;
                text-align: right;
              }
              .col-label {
                display: inline-block;
                padding-top: 10px;
              }
              .single-row .ant-form-item-label {
                width: 9.333333%;
              }
            }
          `}</style>
        </div>
      );
    }
  }
);

export default connect(
  ({ PositionVacantModal, user }) => {
    return { ...PositionVacantModal, user };
  },
  { ...PositionVacantModal.actions }
)(CreatePosition);
