import xhr from "./index.js";

// 列表
export function internList(param) {
  return xhr({
    url: "admin/v1/profile-manage/list",
    body: { is_intern: 1, ...param },
    method: "POST",
  });
}

//详情
export function internDetail(param) {
  return xhr({
    url: "admin/v1/intern-manage/info",
    body: param
  });
}

//新增实习生
export function internCreate(param) {
  return xhr({
    url: "admin/v1/intern-manage/create-intern",
    body: param,
    method: "POST"
  });
}

//新增沟通记录
export function addCommunicateRecord(param) {
  return xhr({
    url: "admin/v1/intern-manage/create-record",
    body: param,
    method: "POST"
  });
}

//新增沟通记录
export function deleteCommunicateRecord(param) {
  return xhr({
    url: "admin/v1/profile-manage/del-communication",
    body: param,
    method: "POST"
  });
}


//提交审核
export function commitAudit(param) {
  return xhr({
    url: "admin/v1/profile-audit/to-verify",
    body: param,
    method: "POST"
  });
}

//编辑基本信息
export function editBaseInfo(param) {
  param.edit_type = "basic";
  return xhr({
    url: "admin/v1/intern-manage/edit-info",
    body: param,
    method: "POST",
    needMessage: false
  });
}

//编辑求职状态
export function editJobStatus(param) {
  param.edit_type = "status";
  return xhr({
    url: "admin/v1/intern-manage/edit-info",
    body: param,
    method: "POST"
  });
}

//删除各项
export function removeItem(param) {
  return xhr({
    url: "admin/v1/profile-manage/remove-record",
    body: param,
    method: "POST"
  });
}

//编辑或新增实习生学历
export function editOrAddEducation(param) {
  return xhr({
    url: "admin/v1/profile-manage/add-education",
    body: param,
    method: "POST"
  });
}

//编辑或新增实习生证书
export function editOrAddCertificate(param) {
  return xhr({
    url: "admin/v1/profile-manage/add-cert",
    body: param,
    method: "POST"
  });
}

//编辑或新增个人作品
export function editOrAddPersonalWork(param) {
  return xhr({
    url: "admin/v1/intern-manage/add-works",
    body: param,
    method: "POST"
  });
}

//编辑或新增校园实践
export function editOrAddCampusPractice(param) {
  return xhr({
    url: "admin/v1/intern-manage/add-campus",
    body: param,
    method: "POST"
  });
}

//编辑或新增实习经历
export function editOrAddPracticeExperience(param) {
  return xhr({
    url: "admin/v1/intern-manage/job-intern",
    body: param,
    method: "POST"
  });
}

//候选人创建
export function candCreate(param) {
  return xhr({
    url: "admin/v1/intern-manage/create-intern",
    body: param,
    method: "POST",
    needMessage: false
  });
}

//获取推荐职位列表
export function getRecomPosList(param) {
  param.status = 2;
  param.type = 1;
  return xhr({
    url: "admin/v1/jd/list",
    body: param,
    method: "POST"
  });
}

//推荐到职位
export function recommandToPos(param) {
  return xhr({
    url: "/admin/v1/jd-profiles",
    body: param,
    method: "POST"
  });
}

// 删除头像
export function deleteAvatar(param) {
  return xhr({
    url: "/admin/v1/profile-manage/avatar-del",
    body: param,
    method: "POST"
  });
}

//获取预览简历详情
export function getProfileDetail(param){
  return xhr({
    url: "admin/v1/profile-manage/preview",
    body: param,
    method: "GET"
  });
}

// 获取职位列表
export function getJDs(param){
  return xhr({
    url: "admin/v1/jd/list",
    body: param,
    method: "POST"
  });
}

// 简历覆盖
export function coverProfiles(param){
  return xhr({
    url: "admin/v1/profile-manage/cover-profile",
    body: param,
    method: "POST"
  });
}
//导出沟通记录
export function exportRecords(param) {
  return xhr({
    url: "admin/v1/profile-manage/export",
    body: param,
    method: "GET"
  });
}