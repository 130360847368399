import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { EditView, EditViewSingle, EditViewMulti } from "components/EditView";
import {
  Avatar,
  Row,
  Col,
  Drawer,
  Upload,
  Modal,
  Form,
  Input,
  Icon,
  Cascader,
  Select,
  DatePicker,
  Checkbox,
  message,
  Radio
} from "antd";
import CityCascade from "components/CityCascade";
import internModel from "store/reducers/internModel";
import { commonData ,mapTips} from "services/commonService";
import { deleteAvatar } from "services/Intern";
import _ from "underscore";
import moment from "moment";
import UserAvatarUpload from "components/UserAvatarUpload";
import { provincialCapitalShow } from "./interfaceDataConvert";
import { internBaseInfoEditFieldnames } from "./fieldsConfig";
import { flatHierarchyOptions } from "utils/dataConvert";
import updateCompletewrapper from "./update-complete-wrapper";
import { history } from "root";
import global from "store/reducers/global";
import InternDetail from "pages/TalentPool/Intern/Detail";
import SocialDetail from "pages/TalentPool/SocialCandidate/Detail";
import SelectComplete from "components/SelectComplete";
import * as positionS from "services/positionS";
import InputComplete from "components/InputComplete.js";
import {emailReg} from "utils/constant.js";

const FormItem = Form.Item;
const Option = Select.Option;
const CheckboxGroup = Checkbox.Group;
const TextArea = Input.TextArea;
const RadioGroup = Radio.Group;
const confirm = Modal.confirm;

function mapPropsToFields(props) {
  const { baseInfo } = props;
  const filtedEditBaseInfo = _.pick(baseInfo, internBaseInfoEditFieldnames);
  const { profile_source_data, tags = [] } = filtedEditBaseInfo;
  const formFields = _.mapObject(filtedEditBaseInfo, function(val, key) {
    // ["sex"].indexOf(key) !== -1
    if (["education"].indexOf(key) !== -1) {
      return Form.createFormField({
        value: val
          ? {
              key: val,
              label: baseInfo[`${key}_label`]
            }
          : undefined
      });
    }
    if (key == "birth") {
      return Form.createFormField({
        value: val ? moment(val, "YYYY/MM/DD") : undefined
      });
    }
    if (key == "city" || key == "native") {
      return Form.createFormField({
        value:
          val && Object.keys(val).length
            ? [val.province_id, val.city_id, val.region_id]
            : undefined
      });
    }
    return Form.createFormField({
      value: val && val
    });
  });
  formFields.profile_source = Form.createFormField({
    value:
      profile_source_data && profile_source_data.length
        ? profile_source_data.map(item => parseInt(item.value))
        : undefined
  });
  formFields.tags = Form.createFormField({
    value: tags.map(item => `${item.value}-${item.lable}`)
  });
  return formFields;
}

function findFlatOption(flatOptions, value, field = "id") {
  return (flatOptions.filter(item => item[field] === value) || [])[0] || {};
}

export function fieldsToProps(values, options) {
  const { flatCitiesOption, flatProfileSource } = options;
  const { birth, city, native, tags = [], profile_source = [] } = values;
  values.birth = birth && birth.format("YYYY-MM-DD");
  values.city = city && {
    province_id: city[0],
    province_label: findFlatOption(flatCitiesOption, city[0]).name,
    city_id: city[1],
    city_label: findFlatOption(flatCitiesOption, city[1]).name,
    region_id: city[2],
    region_label: findFlatOption(flatCitiesOption, city[2]).name
  };
  values.native = native && {
    province_id: native[0],
    province_label: findFlatOption(flatCitiesOption, native[0]).name,
    city_id: native[1],
    city_label: findFlatOption(flatCitiesOption, native[1]).name,
    region_id: native[2],
    region_label: findFlatOption(flatCitiesOption, native[2]).name
  };
  values.profile_source_data = profile_source.map(value =>
    findFlatOption(flatProfileSource, value, "value")
  );
  values.profile_source = profile_source[1] || profile_source[0];
  values.tags = tags.map(item => ({
    value: item.split("-")[0],
    lable: item.split("-")[1]
  }));
  // "sex",
  for (const key of ["education"]) {
    const value = values[key] || {};
    values[key] = value.key;
    values[`${key}_label`] = value.label;
  }
  return values;
}

// 创建表单
export function createBaseInfoCreateForm(mapPropsToFields, fieldsToProps) {
  return Form.create({ mapPropsToFields })(
    class EditForm extends PureComponent {
      state = {
        cityOptions: [],
        intentionOptions: [],
        tagOptions: [],
        sourceOptions: [],
        eduOptions: [],
        avatar: ""
      };

      componentDidMount() {
        commonData(
          "profile_education,profile_job_intention,cities,profile_tag,profile_source"
        ).then(options => {
          const cityOptions = options.cities || [],
            sourceOptions = options.profile_source || [];
          this.setState({
            cityOptions,
            intentionOptions: options.profile_job_intention || [],
            tagOptions:
              options.profile_tag &&
              options.profile_tag.map(item => ({
                value: `${item.value}-${item.label}`,
                label: item.label
              })),
            sourceOptions,
            eduOptions: options.profile_education || []
          });
          this.flatCitiesOption = flatHierarchyOptions(cityOptions, "list");
          this.flatProfileSource = flatHierarchyOptions(sourceOptions, "list");
        });
      }

      handleSubmit = () => {
        const { baseInfo } = this.props;
        return new Promise((resolve, reject) => {
          this.props.form.validateFieldsAndScroll((err, values) => {
            values.avatar = this.state.avatar;
            console.log(values);
            if (!err) {
              resolve(
                fieldsToProps(values, {
                  flatCitiesOption: this.flatCitiesOption || [],
                  flatProfileSource: this.flatProfileSource || []
                })
              );
            } else {
              reject(err);
            }
          });
        });
      };
      companyAddressChange = (param) => {
        const { getFieldValue } = this.props.form;
        let companyCity = getFieldValue(`city`);
        return new Promise((resolve, reject) => {
          mapTips({
            province_id: companyCity[0],
            city_id: companyCity[1],
            region_id: companyCity[2],
            address: param.name
          }).then(res => {
            let autoResult = { code: 0 };
            if (res.code === 0) {
              autoResult.data = res.data && res.data.length !== 0 ? res.data.map(item => item.name) : [];
            } else {
              autoResult.data = [];
            }
            resolve(autoResult);
          })
        })
      }

      render() {
        const {
          cityOptions,
          intentionOptions,
          tagOptions,
          sourceOptions,
          eduOptions
        } = this.state;
        const formItemLayout = {
          labelCol: { span: 5 },
          wrapperCol: { span: 19 }
        };
        const { baseInfo } = this.props;
        const { getFieldDecorator, getFieldValue,setFieldsValue } = this.props.form;
        const uploadAffixProps = {
          name: "avatar",
          showUploadList: false,
          action: "admin/v1/profile-manage/avatar",
          // data: { is_intern: isIntern },
          onChange: info => {
            if (info.file.status === "uploading") {
              this.setState({ avatarloading: true });
              return;
            }
            if (info.file.status === "done") {
              const res = info.file.response;
              // Get this url from response in real world.
              // getBase64(info.file.originFileObj, imageUrl => {

              // });
              if (res.code === 0) {
                // this.props.updateNewBaseInfo({ avatar: res.data.avatar });
                this.setState({
                  avatar: res.data.avatar
                });
              } else {
                message.error(res.msg);
              }
            } else {
              message.error("上传头像失败");
            }
            this.setState({ avatarloading: false });
          }
        };
        // let defaultAvatar = require("assets/imgs/student-man.jpg");
        // if (getFieldValue("sex") && getFieldValue("sex").label == "女") {
        //   defaultAvatar = require("assets/imgs/student-woman.jpg")
        // }
        
        let hasAddress = getFieldValue && getFieldValue("profile_source") || [];
        const hasCity = getFieldValue(`city`) && getFieldValue(`city`).length !== 0;
          if(!hasCity&&getFieldValue(`address`)){
            setFieldsValue({address: undefined });
          }
        return (
          <div className="base-info-edit">
            <Form
              onSubmit={this.handleSubmit}
              className="edit-form"
              layout="horizontal"
            >
              {/* <Row>
                <Col span={24}>
                  <FormItem labelCol={{span: 2}} wrapperCol={{span: 20}} label=" " className="special-item">
                    <img src={this.state.avatar || defaultAvatar}></img>
                    <Upload {...uploadAffixProps}>
                      <span className="mod-avatar">修改头像</span>
                    </Upload> 
                  </FormItem>
                </Col>
              </Row> */}
              <Row gutter={35} className="one">
                <Col span={12}>
                  <FormItem label="求职意向" {...formItemLayout}>
                    {getFieldDecorator("job_intention", {
                      initialValue: (intentionOptions[0] || {}).value
                      // rules: [{ required: true, message: "请选择意向" }]
                    })(
                      <Select size="large" placeholder="请选择意向">
                        {intentionOptions.map(item => (
                          <Option value={item.value} key={item.value}>
                            {item.label}
                          </Option>
                        ))}
                      </Select>
                    )}
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem label="人选来源" {...formItemLayout}>
                    {getFieldDecorator("profile_source", {
                      rules: [
                        {
                          required: false
                        }
                      ]
                    })(
                      <Cascader
                        options={sourceOptions}
                        showSearch={true}
                        notFoundContent="没有选项"
                        fieldNames={{ children: "list" }}
                        changeOnSelect
                        size="large"
                        placeholder="请选择人选来源"
                      />
                    )}
                  </FormItem>
                </Col>
              </Row>
              <Row gutter={35} className="one">
                <Col span={12}>
                  <FormItem label="姓名" {...formItemLayout}>
                    {getFieldDecorator("name", {
                      rules: [
                        {
                          required: true,
                          max: 10,
                          message: "请输入姓名，且最大10个字符"
                        }
                      ]
                    })(<Input placeholder="请输入姓名" size="large" />)}
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem label="性别" {...formItemLayout}>
                    {getFieldDecorator("sex", {
                      rules: [
                        {
                          required: true,
                          message: "请选择性别"
                        }
                      ]
                    })(
                      // <Select
                      //   placeholder="请选择性别"
                      //   size="large"
                      //   labelInValue
                      // >
                      //   <Option value={1}>男</Option>
                      //   <Option value={2}>女</Option>
                      // </Select>
                      <RadioGroup>
                        <Radio value={1}>男</Radio>
                        <Radio value={2}>女</Radio>
                      </RadioGroup>
                    )}
                  </FormItem>
                </Col>
                {/* <Col span={12}>
                  <FormItem
                    label="意向"
                    {...formItemLayout}
                  >
                    
                  </FormItem>
                </Col> */}
              </Row>

              <Row gutter={35} className="one">
                <Col span={12}>
                  <FormItem label="出生年月" {...formItemLayout}>
                    {getFieldDecorator("birth", {
                      rules: [{ required: false, message: "请选择出生年月" }]
                    })(
                      <DatePicker
                        format="YYYY/MM/DD"
                        style={{ width: "100%" }}
                        size="large"
                        placeholder="请选择出生年月"
                      />
                    )}
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem label="籍贯" {...formItemLayout}>
                    {getFieldDecorator("native", {
                      rules: []
                    })(
                      <Cascader
                        options={cityOptions}
                        showSearch={true}
                        notFoundContent="没有选项"
                        fieldNames={{
                          label: "name",
                          value: "id",
                          children: "list"
                        }}
                        changeOnSelect
                        placeholder="请选择籍贯"
                        size="large"
                      />
                    )}
                  </FormItem>
                </Col>
              </Row>
              <Row gutter={35} className="three">
                <Col span={12}>
                  <FormItem label="手机号码" {...formItemLayout}>
                    {getFieldDecorator("phone", {
                      rules: [
                        // {
                        //   required: true,
                        //   pattern: /^1[3456789]\d{9}$/,
                        //   message: "请输入正确的手机号码"
                        // }
                      ]
                    })(<Input placeholder="请输入手机号码" size="large" />)}
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem label="邮箱" {...formItemLayout}>
                    {getFieldDecorator("email", {
                      rules: [
                        {
                          required: false,
                          pattern: emailReg,
                          message: "请输入正确的邮箱"
                        }
                      ]
                    })(<Input placeholder="请输入邮箱" size="large" />)}
                  </FormItem>
                </Col>
              </Row>
              <Row gutter={35}>
              <Col span={12}>
                  <FormItem label="居住城市" {...formItemLayout}>
                    {getFieldDecorator("city", {
                      rules: []
                    })(
                      <CityCascade
                        placeholder="请选择居住城市"
                        options={cityOptions}
                        showSearch={true}
                        notFoundContent="没有选项"
                        fieldNames={{
                          label: "name",
                          value: "id",
                          children: "list"
                        }}
                        changeOnSelect
                        size="large"
                      />
                    )}
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem label="学历" {...formItemLayout}>
                    {getFieldDecorator("education", {
                      rules: [{ required: false, message: "请选择学历" }]
                    })(
                      <Select
                        placeholder="请选择学历"
                        size="large"
                        labelInValue
                        allowClear
                      >
                        {eduOptions.map(item => (
                          <Option value={item.value} key={item.value}>
                            {item.label}
                          </Option>
                        ))}
                      </Select>
                    )}
                  </FormItem>
                </Col>
              </Row>
              <Row gutter={35} className="two">
                {hasAddress[0] === 16 &&
                  <Col span={12}>
                    <FormItem label="详细地址" {...formItemLayout}>
                      {getFieldDecorator("address", {
                        rules: [
                          {
                            // required: true,
                            // max: 10,
                            message: "请输入详情地址"
                          }
                        ]
                      })(<InputComplete disabled={!hasCity} custom={true} size="large"
                      requestFunc={(param) => this.companyAddressChange(param)}
                      placeholder="请输入详细地址"
                    />)}
                    </FormItem>
                  </Col>
                }
              </Row>
              <Row className="seven" gutter={35}>
                <Col span={24}>
                  <FormItem
                    label="标签"
                    labelCol={{ span: 2 }}
                    wrapperCol={{ span: 20 }}
                  >
                    {getFieldDecorator("tags", {
                      rules: []
                    })(<CheckboxGroup options={tagOptions} />)}
                  </FormItem>
                </Col>
              </Row>
              <Row className="nine" gutter={35} style={{ marginBottom: 20 }}>
                <Col span={24}>
                  <FormItem
                    label="自我评价"
                    labelCol={{ span: 2 }}
                    wrapperCol={{ span: 20 }}
                  >
                    {getFieldDecorator("self_introduction", {
                      rules: [
                        {
                          required: true,
                          message: "请输入自我评价"
                        }
                      ]
                    })(<TextArea placeholder="自我评价" />)}
                  </FormItem>
                </Col>
              </Row>
              <Row className="ten" gutter={35} style={{ marginBottom: 20 }}>
                <Col span={24}>
                  <FormItem
                    label="顾问评价"
                    labelCol={{ span: 2 }}
                    wrapperCol={{ span: 20 }}
                  >
                    {getFieldDecorator("appraisal", {
                      rules: []
                    })(<TextArea placeholder="顾问评价" />)}
                  </FormItem>
                </Col>
              </Row>
              <Row className="ten" gutter={35}>
                <Col span={24}>
                  <FormItem
                    label="沟通记录"
                    labelCol={{ span: 2 }}
                    wrapperCol={{ span: 20 }}
                  >
                    {getFieldDecorator("content", {
                      // rules: [
                      //   {
                      //     required: true,
                      //     message: "请输入沟通记录"
                      //   }
                      // ]
                    })(<TextArea placeholder="填写最新沟通记录" />)}
                  </FormItem>
                </Col>
              </Row>
              <Row
                className="ten"
                style={{
                  marginTop: "10px"
                }}
              >
                <Col span={24}>
                  <FormItem
                    label="关联职位"
                    labelCol={{ span: 2 }}
                    wrapperCol={{ span: 18 }}
                  >
                    {getFieldDecorator("content_jd_id", {})(
                      <SelectComplete
                        initLoad
                        setSearchParams={{
                          type: 3
                        }}
                        size="large"
                        style={{ width: 410 }}
                        requestFunc={positionS.positionList}
                        placeholder="请输入"
                      />
                    )}
                  </FormItem>
                  <div
                    style={{
                      fontSize: 12,
                      color: "#ff4b54",
                      margin: "10px 0 10px 71px"
                    }}
                  >
                    注：关联职位仅是信息关联，非推荐到职位
                  </div>
                </Col>
              </Row>
            </Form>
            <style jsx>{`
              .base-info-edit {
                :global(.seven),
                :global(.eight),
                :global(.nine),
                :global(.ten) {
                  :global(.ant-form-item-label) {
                    width: 10%;
                  }
                  :global(.ant-form-item) {
                    margin-bottom: 0;
                  }
                }
                :global(.ant-form-explain) {
                  position: absolute;
                  bottom: -15px;
                }
                :global(.mod-avatar) {
                  margin-left: 20px;
                  margin-right: 40px;
                  font-size: 16px;
                  color: #38a4e9;
                  cursor: pointer;
                }
                :global(.intention-select) {
                  width: 150px;
                }
                :global(.special-item) {
                  :global(.ant-form-item-label) {
                    width: 9.6%;
                  }
                  img {
                    width: 100px;
                    height: 120px;
                  }
                }
              }
            `}</style>
          </div>
        );
      }
    }
  );
}

export function createBaseInfoEditForm(mapPropsToFields, fieldsToProps) {
  return Form.create({ mapPropsToFields })(
    class EditForm extends PureComponent {
      state = {
        cityOptions: [],
        intentionOptions: [],
        tagOptions: [],
        sourceOptions: [],
        eduOptions: []
      };

      componentDidMount() {
        commonData(
          "profile_education,profile_job_intention,cities,profile_tag,profile_source"
        ).then(options => {
          const cityOptions = options.cities || [],
            sourceOptions = options.profile_source || [];
          this.setState({
            cityOptions,
            intentionOptions: options.profile_job_intention || [],
            tagOptions:
              options.profile_tag &&
              options.profile_tag.map(item => ({
                value: `${item.value}-${item.label}`,
                label: item.label
              })),
            sourceOptions,
            eduOptions: options.profile_education || []
          });
          this.flatCitiesOption = flatHierarchyOptions(cityOptions, "list");
          this.flatProfileSource = flatHierarchyOptions(sourceOptions, "list");
        });
      }

      handleSubmit = () => {
        return new Promise((resolve, reject) => {
          this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
              resolve(
                fieldsToProps(values, {
                  flatCitiesOption: this.flatCitiesOption || [],
                  flatProfileSource: this.flatProfileSource || []
                })
              );
            } else {
              reject(err);
            }
          });
        });
      };
      companyAddressChange = (param) => {
        const { getFieldValue } = this.props.form;
        let companyCity = getFieldValue(`city`);
        return new Promise((resolve, reject) => {
          mapTips({
            province_id: companyCity[0],
            city_id: companyCity[1],
            region_id: companyCity[2],
            address: param.name
          }).then(res => {
            let autoResult = { code: 0 };
            if (res.code === 0) {
              autoResult.data = res.data && res.data.length !== 0 ? res.data.map(item => item.name) : [];
            } else {
              autoResult.data = [];
            }
            resolve(autoResult);
          })
        })
      }

      render() {
        const {
          cityOptions,
          intentionOptions,
          tagOptions,
          sourceOptions,
          eduOptions
        } = this.state;
        const formItemLayout = {
          labelCol: { span: 5 },
          wrapperCol: { span: 19 }
        };

        const { getFieldDecorator,getFieldValue,setFieldsValue } = this.props.form;
        let {baseInfo={}}=this.props;
        let hasAddress = getFieldValue && getFieldValue("profile_source")  || [];
        const hasCity = getFieldValue(`city`) && getFieldValue(`city`).length !== 0;
        if(!hasCity&&getFieldValue(`address`)){
          setFieldsValue({address: undefined });
        }
        return (
          <div className="base-info-edit">
            <Form
              onSubmit={this.handleSubmit}
              className="edit-form"
              layout="horizontal"
            >
              <Row gutter={35} className="one">
                <Col span={12}>
                  <FormItem label="姓名" {...formItemLayout}>
                    {getFieldDecorator("name", {
                      rules: [
                        {
                          required: true,
                          max: 10,
                          message: "请输入姓名，且最大10个字符"
                        }
                      ]
                    })(<Input placeholder="请输入姓名" size="large" />)}
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem label="意向" {...formItemLayout}>
                    {getFieldDecorator("job_intention", {
                      initialValue: (intentionOptions[0] || {}).value,
                      rules: [{ required: true, message: "请选择意向" }]
                    })(
                      <Select size="large" placeholder="请选择意向">
                        {intentionOptions.map(item => (
                          <Option value={item.value} key={item.value}>
                            {item.label}
                          </Option>
                        ))}
                      </Select>
                    )}
                  </FormItem>
                </Col>
              </Row>

              <Row gutter={35} className="one">
                <Col span={12}>
                  <FormItem label="性别" {...formItemLayout}>
                    {getFieldDecorator("sex", {
                      rules: [
                        {
                          required: true,
                          message: "请选择性别"
                        }
                      ]
                    })(
                      // <Select
                      //   placeholder="请选择性别"
                      //   size="large"
                      //   labelInValue
                      // >
                      //   <Option value={1}>男</Option>
                      //   <Option value={2}>女</Option>
                      // </Select>
                      <RadioGroup>
                        <Radio value={1}>男</Radio>
                        <Radio value={2}>女</Radio>
                      </RadioGroup>
                    )}
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem label="籍贯" {...formItemLayout}>
                    {getFieldDecorator("native", {
                      rules: []
                    })(
                      <Cascader
                        options={cityOptions}
                        showSearch={true}
                        notFoundContent="没有选项"
                        fieldNames={{
                          label: "name",
                          value: "id",
                          children: "list"
                        }}
                        changeOnSelect
                        placeholder="请选择籍贯"
                        size="large"
                      />
                    )}
                  </FormItem>
                </Col>
              </Row>

              <Row gutter={35} className="two">
              <Col span={12}>
                  <FormItem label="人选来源" {...formItemLayout}>
                    {getFieldDecorator("profile_source", {
                      rules: [
                        {
                          required: false
                        }
                      ]
                    })(
                      <Cascader
                        options={sourceOptions}
                        showSearch={true}
                        notFoundContent="没有选项"
                        fieldNames={{ children: "list" }}
                        changeOnSelect
                        size="large"
                        placeholder="请选择人选来源"
                      />
                    )}
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem label="出生年月" {...formItemLayout}>
                    {getFieldDecorator("birth", {
                      rules: [{ required: false, message: "请选择出生年月" }]
                    })(
                      <DatePicker
                        format="YYYY/MM/DD"
                        style={{ width: "100%" }}
                        size="large"
                        placeholder="请选择出生年月"
                      />
                    )}
                  </FormItem>
                </Col>
              </Row>

              <Row gutter={35} className="three">
                <Col span={12}>
                  <FormItem label="手机号码" {...formItemLayout}>
                    {getFieldDecorator("phone", {
                      rules: [
                        // {
                        //   required: true,
                        //   pattern: /^1[3456789]\d{9}$/,
                        //   message: "请输入正确的手机号码"
                        // }
                      ]
                    })(<Input placeholder="请输入手机号码" size="large" />)}
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem label="学历" {...formItemLayout}>
                    {getFieldDecorator("education", {
                      rules: [{ required: false, message: "请选择学历" }]
                    })(
                      <Select
                        placeholder="请选择学历"
                        size="large"
                        labelInValue
                        allowClear
                      >
                        {eduOptions.map(item => (
                          <Option value={item.value} key={item.value}>
                            {item.label}
                          </Option>
                        ))}
                      </Select>
                    )}
                  </FormItem>
                </Col>
              </Row>
              <Row gutter={35}>
              <Col span={12}>
                  <FormItem label="居住城市" {...formItemLayout}>
                    {getFieldDecorator("city", {
                      rules: []
                    })(
                      <CityCascade
                        placeholder="请选择居住城市"
                        options={cityOptions}
                        showSearch={true}
                        notFoundContent="没有选项"
                        fieldNames={{
                          label: "name",
                          value: "id",
                          children: "list"
                        }}
                        changeOnSelect
                        size="large"
                      />
                    )}
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem label="邮箱" {...formItemLayout}>
                    {getFieldDecorator("email", {
                      rules: [
                        {
                          required: false,
                          pattern: emailReg,
                          message: "请输入正确的邮箱"
                        }
                      ]
                    })(<Input placeholder="请输入邮箱" size="large" />)}
                  </FormItem>
                </Col>
                
              </Row>
              <Row  gutter={35}>
                {hasAddress[0] == 16 &&
                      <Col span={12}>
                        <FormItem label="详细地址" {...formItemLayout}>
                          {getFieldDecorator("address", {
                            rules: [
                              {
                                // required: true,
                                // max: 10,
                                message: "请输入详情地址"
                              }
                            ]
                          })(<InputComplete disabled={!hasCity} custom={true} size="large"
                          requestFunc={(param) => this.companyAddressChange(param)}
                          placeholder="请输入详细地址"
                        />)}
                        </FormItem>
                      </Col>
                    }
              </Row>
              <Row className="seven" gutter={35}>
                <Col span={24}>
                  <FormItem
                    label="标签"
                    labelCol={{ span: 2 }}
                    wrapperCol={{ span: 20 }}
                  >
                    {getFieldDecorator("tags", {
                      rules: []
                    })(<CheckboxGroup options={tagOptions} />)}
                  </FormItem>
                </Col>
              </Row>
              <Row className="nine" gutter={35} style={{ marginBottom: 20 }}>
                <Col span={24}>
                  <FormItem
                    label="自我评价"
                    labelCol={{ span: 2 }}
                    wrapperCol={{ span: 20 }}
                  >
                    {getFieldDecorator("self_introduction", {
                      rules: [
                        {
                          required: true,
                          message: "请输入自我评价"
                        }
                      ]
                    })(<TextArea placeholder="自我评价" />)}
                  </FormItem>
                </Col>
              </Row>
              <Row className="ten" gutter={35}>
                <Col span={24}>
                  <FormItem
                    label="顾问评价"
                    labelCol={{ span: 2 }}
                    wrapperCol={{ span: 20 }}
                  >
                    {getFieldDecorator("appraisal", {
                      rules: []
                    })(<TextArea placeholder="顾问评价" />)}
                  </FormItem>
                </Col>
              </Row>
            </Form>
            <style jsx>{`
              .base-info-edit {
                :global(.seven),
                :global(.eight),
                :global(.nine),
                :global(.ten) {
                  :global(.ant-form-item-label) {
                    width: 10%;
                  }
                  :global(.ant-form-item) {
                    margin-bottom: 0;
                  }
                }
              }
            `}</style>
          </div>
        );
      }
    }
  );
}

export const BaseInfoCreateForm = createBaseInfoCreateForm(
  mapPropsToFields,
  fieldsToProps
);

export const BaseInfoEditForm = createBaseInfoEditForm(
  mapPropsToFields,
  fieldsToProps
);

export function BaseInfoShow(props) {
  const { baseInfo, updateBaseInfo, candId, communicationRecords } = props;
  const { city = {}, native = {} } = baseInfo;
  const{profile_source_data=[]}=baseInfo;
  const[sourceObj={}]=profile_source_data
  const handledCity = provincialCapitalShow(city);
  const handleNative = provincialCapitalShow(native);
  const setTagColor = type => {
    return {
      IS_DISPATCH: "#fa9965",
      IS_RIGHT: "#38a4e9",
      IS_OUTSOURCE: "#5fccc3",
      IS_CLERK: "#52c41a"
    }[type];
  };

  const onUserAvatarChange = res => {
    if (res.code === 0) {
      updateBaseInfo({ avatar: res.data.avatar });
    } else {
      message.error(res.msg);
    }
  };
  const onUserAvatarDelete = res => {
    if (res.code === 0) {
      updateBaseInfo({ avatar: null });
      message.success("删除成功");
    } else {
      message.error(res.msg);
    }
  };
  return (
    <React.Fragment>
      <div className="top-info">
        <Row gutter={5}>
          <Col span={4}>
            <UserAvatarUpload
              avatar={baseInfo.avatar}
              defaultAvatar={
                baseInfo.sex === 1
                  ? require("assets/imgs/student-man.jpg")
                  : require("assets/imgs/student-woman.jpg")
              }
              action="admin/v1/profile-manage/avatar"
              deletefunc={deleteAvatar}
              name="avatar"
              data={{ profile_id: candId }}
              onDelete={onUserAvatarDelete}
              onChange={onUserAvatarChange}
            />
          </Col>
          <Col span={20}>
            <h4
              dangerouslySetInnerHTML={{
                __html: [
                  baseInfo.name,
                  baseInfo.sex_label,
                  baseInfo.age ? `${baseInfo.age}岁` : ""
                ]
                  .filter(item => !!item)
                  .join("<i>.</i>")
              }}
            />
            <p>
              {baseInfo.education}
              {baseInfo.major_name ? `.${baseInfo.major_name}` : ""}
            </p>
            <p>{baseInfo.last_school}</p>
            <p>
              人选来源 :{" "}
              {(baseInfo.profile_source_data || [])
                .map(item => item.label)
                .join("/")}
            </p>
          </Col>
        </Row>
      </div>
      <div className="down-info">
        <div className="life">
          <h4>生活信息</h4>
          <Row gutter={5} style={{ maxWidth: 650 }}>
            <Col span={8}>
              <label>居住城市 : </label>
              <span>{`${handledCity.province_label}${handledCity.city_label}${
                handledCity.region_label
              }`}</span>
            </Col>
            {sourceObj.value==16&&
             <Col span={8}>
              <label>详细地址 : </label>
              <span style={{display:"inline-block",width:"115px",verticalAlign:"bottom"}} className="ellipsis" title={baseInfo.address}>{baseInfo.address}</span>
            </Col>
            }
            <Col span={8}>
              <label>籍贯 : </label>
              <span>{`${handleNative.province_label}${handleNative.city_label}${
                handleNative.region_label
              }`}</span>
            </Col>
          </Row>
        </div>
        <Row className="contact" gutter={10}>
          <Col span={13}>
            <h4>联系方式</h4>
            <div className="phone">
              <Icon type="phone" theme="outlined" style={{ marginRight: 5 }} />
              <span>{baseInfo.phone}</span>
            </div>
            <div className="mail">
              <Icon type="mail" theme="outlined" style={{ marginRight: 5 }} />
              <span>{baseInfo.email}</span>
            </div>
          </Col>
          <Col span={11}>
            <h4>标签&能力</h4>
            <div className="tags">
              {(baseInfo.tags || []).map(item => (
                <span
                  style={{ color: setTagColor(item.value) }}
                  key={item.value}
                >
                  {item.lable}
                </span>
              ))}
              <span style={{ color: "#38a4e9" }}>
                {baseInfo.position_p_label}/{baseInfo.position_label}
              </span>
            </div>
            <div className="ables">
              <span>
                {baseInfo.communicate_label
                  ? `#${baseInfo.communicate_label}`
                  : ""}
              </span>
              <span>
                {baseInfo.office_label ? `#${baseInfo.office_label}` : ""}
              </span>
              <span>
                {baseInfo.english_label ? `#${baseInfo.english_label}` : ""}
              </span>
            </div>
          </Col>
        </Row>
        <div className="self-introduction">
          <h4>自我介绍</h4>
          <pre>{baseInfo.self_introduction}</pre>
        </div>
        {baseInfo.appraisal && (
          <Row className="evaluate">
            <Col span={24}>
              <h4>顾问评价</h4>
              <pre>{baseInfo.appraisal}</pre>
            </Col>
          </Row>
        )}
        <Row className="evaluate">
          <Col span={24}>
            <h4>最近沟通</h4>
            <pre>
              {communicationRecords.list &&
                communicationRecords.list.length > 0 &&
                communicationRecords.list[0].content}
            </pre>
          </Col>
        </Row>
      </div>
      <style jsx>
        {`
          .top-info {
            margin-bottom: 30px;
            h4 {
              font-size: 18px;
              margin-bottom: 18px;
              font-weight: 600;
              i {
                vertical-align: text-top;
                margin: 0 3px;
              }
            }
            :global(.row1) {
              color: #666666;
              margin-bottom: 18px;
            }
            :global(.row2) {
              color: #666666;
            }
          }
          .down-info {
            :global(.ant-row) {
              color: #666;
            }
            .self-introduction {
              margin-bottom: 28px;
            }
            h4 {
              font-weight: 600;
              font-size: 16px;
              margin-bottom: 18px;
            }
            .life {
              margin-bottom: 28px;
            }
            :global(.contact) {
              margin-bottom: 28px;
              .phone {
                margin-bottom: 15px;
              }
              .tags {
                margin-bottom: 15px;
                span {
                  margin-right: 5px;
                }
              }
              .ables {
                span {
                  margin-right: 5px;
                }
              }
            }
          }
        `}
      </style>
    </React.Fragment>
  );
}

class BaseInfo extends PureComponent {
  onEdit = () => {
    this.props.updateBaseInfo({ isEdit: true });
  };

  onCancel = () => {
    this.props.updateBaseInfo({ isEdit: false });
  };

  onSave = () => {
    const { editBaseInfo, pathname, updateStack } = this.props;
    this.formRef.handleSubmit().then(values => {
      values.existedCallBack = res => {
        let confirmCover = {
          title: `人选（${res.data.profile_name}）已存在`,
          content: (
            <React.Fragment>
              <p style={{ marginTop: "10px" }}>
                录入人：{res.data.profile_created_by}
              </p>
              <p>录入时间：{res.data.profile_created_date}</p>
            </React.Fragment>
          ),
          okText: "查看人选",
          cancelText: "取消",
          onOk(close) {
            // 查看人选
            close();
            if (pathname == "/candidate/talent-pool") {
              updateStack({
                type: "add",
                componentRef: res.data.is_intern ? InternDetail : SocialDetail,
                componentProps: {
                  onClose: () => updateStack({ type: "pop" }),
                  stackVisible: true,
                  candidateId: res.data.profile_id
                }
              });
            } else {
              history.push({
                pathname: "/candidate/talent-pool",
                state: {
                  open: res.data.profile_id,
                  is_intern: res.data.is_intern
                }
              });
            }
          }
        };
        confirm(confirmCover);
      };
      editBaseInfo(values)
        .then(res => (!res.code ? res.data : null))
        .then(this.props.baseUpdateInfo);
    });
  };

  render() {
    const {
      baseInfo,
      updateBaseInfo,
      candId,
      communicationRecords
    } = this.props;
    return (
      <div className="base-info entity">
        <EditViewSingle
          title="基本信息"
          isView={!baseInfo.isEdit}
          onEdit={this.onEdit}
          onCancel={this.onCancel}
          onSave={this.onSave}
        >
          <div role="view" className="view">
            <BaseInfoShow
              baseInfo={baseInfo}
              communicationRecords={communicationRecords}
              updateBaseInfo={updateBaseInfo}
              candId={candId}
            />
          </div>
          <div role="edit" className="edit">
            <BaseInfoEditForm
              wrappedComponentRef={inst => (this.formRef = inst)}
              baseInfo={baseInfo}
            />
          </div>
        </EditViewSingle>
      </div>
    );
  }
}

export default connect(
  ({ intern, global }) => {
    return {
      candId: intern.candidate.id,
      baseInfo: intern.candidate.detail.baseInfo || {},
      pathname: global.pathname,
      communicationRecords: intern.candidate.communicationRecords
    };
  },
  { ...internModel.actions, updateStack: global.actions.updateDrawerStack }
)(updateCompletewrapper(BaseInfo));
