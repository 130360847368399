import React, { PureComponent } from "react";
import { Form, Icon, Input, Button, Checkbox, message } from "antd";
import { connect } from "react-redux";
import _get from 'lodash/get'
import { v4 as uuid } from 'uuid'
import { history } from "root";
import userModel from "store/reducers/user";
import SmsInput from 'components/SmsInput'
import { reloadAuthorized } from "utils/Authorized";
import { clueNotification, businessNotification } from "components/notification";
import containerBg from 'assets/imgs/login-container-bg.png';
import { getCaptchaImage, resetPassword, sendMsg } from "../../services/user";
import { phoneRegex } from "../../utils/regex";
let phoneCache = ''
const onFieldsChange = (props, changedValues, allValues) => {
  phoneCache = _get(changedValues, 'phone.value', '')
}

const FormItem = Form.Item;
const LoginForm = Form.create({onFieldsChange})(
  class Login extends PureComponent {
    state = {
      isGetAuth: false
    }
    handleSubmit = e => {
      const { login, history } = this.props;
      e.preventDefault();
      this.props.form.validateFields((err, values) => {
        if (!err) {
          resetPassword(values).then(res => {
            if (res.code === 0) {
              message.success(res.msg, 5)
              this.handleGoLogin()
              // if (res.data.clue_remind) {
              //   clueNotification();
              // }
              // if (res.data.business_remind) {
              //   businessNotification();
              // }
            }
          })
        }
      });
    };
    componentDidMount() {
    }


    handlePhoneChange = (e) => {
      this.setState({
        phone: e.target.value
      })
    }

    handleGetAuth = () => {
      if(this.state.isGetAuth) return;

      const isPhone = phoneRegex.test(phoneCache)

      return new Promise((resolve, reject) => {
        if (!isPhone) {
          message.error('请输入正确的手机号')
          reject({
            status: 'error',
            message: 'Invalid phone'
          })
          return;
        }
  
        sendMsg({ phone: phoneCache }).then(res => {
          if (res.code === 0) {
            this.setState({ isGetAuth: true })
            message.success(res.msg)
            resolve({
              status: 'success',
              message: res.msg
            })
          } else {
            message.error(res.msg)
            reject({
              status: 'error',
              message: res.msg
            })
            return
          }
        })

      })
    }

    handleTimerClear = () => {
      this.setState({ isGetAuth: false })
    }

    handleGoLogin = () => {
      history.replace("/user/login");
    }

    render() {
      const { getFieldDecorator } = this.props.form;

      const { phone } = this.state;
      return (
        <div className="login-container">
          <div className="left-content"></div>
          <Form onSubmit={this.handleSubmit} className="login-form">
            <div className="title">重置密码</div>
            <div className="title-tip">高效的青年白领灵活用工平台</div>
            <FormItem
            >
              {getFieldDecorator("phone", {
                rules: [{ required: true, message: '请输入手机号' }]
              })(
                <Input size="large"
                  prefix={
                    <Icon type="phone" style={{ color: "rgba(0,0,0,.25)" }} />
                  }
                  placeholder="请输入您的手机号"
                />
              )}
            </FormItem>
            <FormItem>
              {getFieldDecorator("verify_code", {
                rules: [{ required: true, message: "请输入验证码" }]
              })(
                <SmsInput placeholder="请输入验证码" onClick={this.handleGetAuth} onTimerClear={this.handleTimerClear} />
              )}
            </FormItem>
            <FormItem>
              {getFieldDecorator("new_password", {
                rules: [{ required: true, message: "请输入您的密码" }]
              })(
                <Input size="large"
                  prefix={
                    <Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />
                  }
                  type="password"
                  placeholder="请输入您的密码"
                />
              )}
            </FormItem>
            <FormItem>
              {getFieldDecorator("confirm_password", {
                rules: [{ required: true, message: "请重复输入您的密码" }]
              })(
                <Input size="large"
                  prefix={
                    <Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />
                  }
                  type="password"
                  placeholder="请重复输入您的密码"
                />
              )}
            </FormItem>
            <FormItem>
              <Button
                type="primary" shape="round" block size="large"
                htmlType="submit"
                className="login-form-button"
              >
                重置密码
              </Button>
            </FormItem>
            <a onClick={this.handleGoLogin}>返回登录</a>
          </Form>
          <style jsx global>
            {`
                .login-container {
                  display: flex;
                  width: 860px;
                  height: 600px;
                  background-color: #ffffff;
                  box-shadow: 0px 6px 20px 0px rgba(0, 0, 0, 0.04);
                  border-radius: 4px;
                  margin: 0 auto;
                  .left-content {
                    width: 500px;
                    height: 100%;
                    background: url('${containerBg}') center no-repeat;
                  }
                  .login-form {
                    padding-right: 70px;
                    width: 360px;
                    .title {
                      padding-top: 117px;
                      padding-bottom: 17px;
                      font-size: 24px;
                      font-weight: bold;
                    }
                    .title-tip {
                      padding-bottom: 34px;
                      font-size: 15px;
                      font-weight: bold;
                    }

                    input {
                      border-radius: 21px;
                      background-color: #f0f1f5;
                      border: 0;
                      &:-webkit-autofill {
                        background-color: #f0f1f5 !important;
                      }
                    }
                    .ant-form-item-control {
                      line-height: 1;
                    }
                    .ant-form-explain {
                      padding-left: 20px;
                      padding-top: 3px;
                    }
                    .checkbox-item {
                      padding-left: 20px;
                      .ant-checkbox-wrapper {
                        color: #bbbbbf;
                      }
                      .ant-checkbox-inner {
                        border-color: #f0f1f5;
                        background-color: #f0f1f5;
                      }
                      .ant-checkbox-checked .ant-checkbox-inner {
                        border-color: #00DD95;
                        background-color: #00DD95;
                      }
                    }
                    .login-form-button {
                      font-size: 15px;
                      background-color: #00dc94;
                      border-color: #00dc94;
                    }
                  }
                }
              `}
          </style>
        </div>
      );
    }
  }
);

export default connect(
  null,
  { ...userModel.actions }
)(LoginForm);
