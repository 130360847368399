import React, { PureComponent } from "react";
import { connect } from "react-redux";
import {
  Select,
  Input,
  Row,
  Col,
  Button,
  Form,
  Modal,
  Radio,
  Icon
} from "antd";
import PositionVacantModal from "store/reducers/positionVacantM";

const Option = Select.Option;
const FormItem = Form.Item;
const TextArea = Input.TextArea;
const RadioGroup = Radio.Group;
/**
 * 职位变更弹窗
 */

const AlterationDialog = Form.create()(
  class AlterationDialogForm extends React.PureComponent {

    handleSubmit = e => {
      const { validateFieldsAndScroll } = this.props.form;
      const {
        positionStatusRevamp,
        positionRowInfo = {},
        setUpdateState,
        getDetail,
        positionDetail:{id},
        updateReduxHead,
        headStatus:{openStatus}
      } = this.props;
      e.preventDefault();
      validateFieldsAndScroll((err, values) => {
        if (!err) {
          const dataParam = { ...values };
          dataParam.operate_type = openStatus;
          dataParam.jd_id = id;
          positionStatusRevamp(dataParam).then(res => {
            updateReduxHead({ openStatus: 0 });
            //设置改变职位的状态
            if (res.code === 0) {
              getDetail(id).then(res => {
                if (res.code === 0)
                  updateReduxHead({ nowStatus: res.data.status });
              });
              setUpdateState({
                filed: "isLoadingList",
                value: true
              });
            } else {
              updateReduxHead({ nowStatus: res.data.status });
            }
          });
        }
      });
    };
    handleCancel = e => {
      this.props.updateReduxHead({ openStatus: 0 });
    };

    render() {
      const { getFieldDecorator } = this.props.form;
      const { headStatus, commonData = {} } = this.props;
      const { openStatus = 0 } = headStatus;
      const  size  = "large";
      const formItemLayout = {
        labelCol: {
          span: 3
        },
        wrapperCol: {
          span: 21
        }
      };
      const styleWidth280 = {
        width: "280px"
      };

      let modalWidth = openStatus === 5 ? 846 : 520;
      return (
        <Modal
          width={modalWidth}
          title={openStatus === 5 ? "提示" : "变更职位状态"}
          visible={openStatus > 0 ? true : false}
          onOk={this.handleSubmit}
          onCancel={this.handleCancel}
          okText="确认"
          cancelText="取消"
        >
          <div className="alteration-dialog">
            <Form layout="vertical" onSubmit={this.handleSubmit}>
              {openStatus <= 4 && (
                <FormItem label="请输入状态变更的备注">
                  {getFieldDecorator("remark", {
                    rules: [{ required: true, message: "请填写信息" }]
                  })(<TextArea rows={4} placeholder="请填写" />)}
                </FormItem>
              )}
              {openStatus === 5 && (
                <div>
                  <div className="des">
                    <Icon
                      type="exclamation-circle"
                      style={{ color: "#fbbe46" }}
                    />
                    关闭职位后，将直接取消客户需求！
                  </div>
                  <FormItem label="">
                    {getFieldDecorator("remark", {
                      rules: [{ required: true, message: "请填写信息" }]
                    })(<TextArea rows={4} placeholder="请填写" />)}
                  </FormItem>
                  <FormItem label="">
                    {getFieldDecorator("invalid_reason", {
                      initialValue: 1,
                      rules: [{ required: true, message: "请填选择" }]
                    })(
                      <RadioGroup
                        options={commonData.business_invalid_reason}
                      />
                    )}
                  </FormItem>
                </div>
              )}
            </Form>
          </div>
          <style jsx global>
            {`
              .alteration-dialog {
                padding: 0 30px;
                .des {
                  font-size: 16px;
                  margin-bottom: 22px;
                  i {
                    margin-right: 15px;
                  }
                }
                .ant-checkbox-group-item {
                  margin-right: 20px;
                  line-height: 27px;
                }
              }
            `}
          </style>
        </Modal>
      );
    }
  }
);

export default connect(
  ({ PositionVacantModal }) => {
    return { ...PositionVacantModal };
  },
  { ...PositionVacantModal.actions }
)(AlterationDialog);
