import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { Collapse, Icon, Modal, Select, Message } from "antd";
import moment from "moment";
import CustomIcon from "components/CustomIcon";
import socialCandModel from "store/reducers/socialCandModel";
import PropTypes from "prop-types";
import Empty from "components/empty";
import PositionDetail from "pages/PositionVacant/detail.js";

const Panel = Collapse.Panel;
const Option = Select.Option;
const { confirm } = Modal;

export default class CommunicateRecords extends PureComponent {
  static propTypes = {
    communicationRecords: PropTypes.any.isRequired,
    updateCommunicationRecords: PropTypes.func.isRequired
  };

  onAdd = e => {
    e.stopPropagation();
    this.props.updateCommunicationRecords({ addModal: true });
  };

  linkPos = id => {
    const {user} = this.props;
    if (user.roles === "special_group_leader" || user.roles === "business" || user.roles === "operator") return;
    this.props.updateStack({
      type: "add",
      componentRef: PositionDetail,
      componentProps: { onClose: () => this.props.updateStack({ type: "pop" }), stackVisible: true, id: id },
    })
  }

  deleteRecord = (item, index) => {
    const { candlist, updateCandList, updateCommunicationRecords, deleteCommunicate, communicationRecords } = this.props;
    confirm({
      title: '确定删除该条沟通记录吗？',
      okText: "确定",
      cancelText: "取消",
      onOk() {
        deleteCommunicate({id: item.communication_id}).then(res => {
          if (res.code === 0) {
            communicationRecords.list.splice(index, 1);
            updateCommunicationRecords(communicationRecords)
          } else {
            Message.error(res.transformError);
          }
        })
      },
      onCancel() {
        
      },
    });
  }

  render() {
    const {
      modelRef,
      communicationRecords: { list = [] },
      user,
    } = this.props;
    return (
      <React.Fragment>
        <Collapse defaultActiveKey={["2"]} className="collapse-handle-box">
          <Panel
            header={
              <React.Fragment>
                <span>沟通记录</span>
                <Icon
                  type="plus-square"
                  theme="outlined"
                  className="right"
                  style={{ fontSize: 18 }}
                  onClick={this.onAdd}
                />
              </React.Fragment>
            }
            key="2"
          >
            {list.length ? (
              list.map((item, index) => (
                <div className="process record" key={index}>
                  <h2>
                    {item.communication_time}{" "}
                    {item.create_user}
                  </h2>
                  <p>沟通时长 : {item.communication_length}</p>
                  <p>备注 : {item.content}</p>
                  {
                    item.jd_info && item.jd_info.jd_name &&
                      <p>
                        关联职位：<span className="link-pos" onClick={() => this.linkPos(item.jd_info.jd_id)}>{item.jd_info.jd_name}</span>
                        {item.jd_info.is_open ? <span>
                          <CustomIcon type="icon-gongkaiwangzhi"
                            style={{
                              fontSize: 18,
                              verticalAlign: "bottom",
                              color: "#cccccc",
                              margin: "0 5px 0 8px"
                            }}
                            />
                          公开
                        </span> : null}
                      </p>
                  }
                  {(item.jd_info && item.jd_info.is_open && item.jd_info.hh_name) ? <p>职位负责人：{item.jd_info.hh_name}</p> : null}
                  <span
                    className="process-status"
                    style={{ backgroundColor: "#d9d9d9" }}
                  >
                    {item.communication_type}
                  </span>
                  {
                    item.create_by_id == user.id &&
                      <span className="delete"><CustomIcon onClick={() => this.deleteRecord(item, index)} type="icon-delete"/></span>
                  }
                </div>
              ))
            ) : (
              <Empty description="暂无沟通记录"/>
            )}
          </Panel>
          <style jsx>
            {`
              .record {
                h2 {
                  font-weight: normal;
                  color: #999999;
                  font-size: 14px;
                  margin-bottom: 14px;
                }
                p {
                  color: #666666;
                  .link-pos {
                    color: rgb(56, 164, 233);
                    cursor: pointer;
                  }
                }
              }
              .process {
                position: relative;
                margin-left: 4px;
                // width: 798px;
                min-height: 115px;
                background-color: #fafafa;
                border-radius: 4px;
                padding: 20px 10px;
                border: solid 1px #d9d9d9;
                margin-bottom: 10px;
                .process-status {
                  position: absolute;
                  top: 0;
                  right: 0;
                  color: #666666;
                  padding: 7px 10px 7px 30px;
                  border-bottom-left-radius: 35px;
                }
                .delete {
                  position: absolute;
                  right: 20px;
                  bottom: 20px;
                  font-size: 16px;
                  color: #999999;
                }
              }
            `}
          </style>
        </Collapse>
      </React.Fragment>
    );
  }
}
