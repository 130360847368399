import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { Row, Col, Form, Input, DatePicker, Select, Cascader } from "antd";
import { EditViewSingle, EditViewMulti } from "components/EditView";
import { getSomeCitys, flatHierarchyOptions } from "utils/dataConvert";

import internModel from "store/reducers/internModel";
import { commonData } from "services/commonService";
import _ from "underscore";
import moment from "moment";
import { disabledEndDate, disabledStartDate } from "utils/formVerify";
import { MonthPickerSofar } from "components/DatePickerSofarWrap";
import updateCompletewrapper from "./update-complete-wrapper";

const FormItem = Form.Item;
const Option = Select.Option;
const { MonthPicker } = DatePicker;
const { TextArea } = Input;

function mapPropsToFields(props) {
  const { item } = props;
  const { province, city, end_time } = item;
  const formFileds = _.mapObject(item, function(val, key) {
    // if (["company_scale"].indexOf(key) !== -1) {
    //   return Form.createFormField({
    //     value: item[`${key}_label`]
    //       ? { key: val, label: item[`${key}_label`] }
    //       : undefined
    //   });
    // }
    // if (key == "city") {
    //   return Form.createFormField({
    //     value: city ? [province, city] : undefined
    //   });
    // }
    return Form.createFormField({
      // value: val && val
      ...val,
      value: val && val.value
    });
  });

  // formFileds.begin_time = Form.createFormField({
  //   value: item.begin_time ? moment(item.begin_time, "YYYY/MM") : undefined
  // });
  // formFileds.end_time = Form.createFormField({
  //   value: end_time
  //     ? /\d{4}\/\d{2}/.test(end_time)
  //       ? moment(end_time, "YYYY/MM")
  //       : end_time
  //     : undefined
  // });

  return formFileds;
}

export function fieldsToProps(values, cityOptions) {
  const flatedCityOptions = flatHierarchyOptions(cityOptions, "list");

  const { begin_time, end_time, city = [] } = values;
  values.begin_time = begin_time && begin_time.format("YYYY/MM");
  if (end_time) {
    values.end_time =
      typeof end_time !== "string" ? end_time.format("YYYY/MM") : end_time;
  }
  values.province = city[0];
  values.city = city[1];
  values.province_label = (
    flatedCityOptions.filter(item => item.id == values.province)[0] || {}
  ).name;
  values.city_label = (
    flatedCityOptions.filter(item => item.id == values.city)[0] || {}
  ).name;
  //if Municipalities, only show province
  if (values.province_label === values.city_label) values.city_label = "";
  for (const key of ["company_scale"]) {
    const value = values[key] || {};
    values[key] = value.key;
    values[`${key}_label`] = value.label;
  }
  return values;
}

export function onFieldsChange(props, changedFields) {
  props.onChange(changedFields);
}

export function createPracticeExperience(mapPropsToFields, fieldsToProps, onFieldsChange) {
  return Form.create({ mapPropsToFields, onFieldsChange })(
    class EditForm extends PureComponent {
      state = {
        cityOptions: [],
        companyScaleOptions: [],
        beginTime: undefined,
        endTime: undefined
      };

      componentDidMount() {
        commonData("cities,company_scale").then(options => {
          const { cities } = options;
          this.setState({
            cityOptions: getSomeCitys("list")(cities, 2),
            companyScaleOptions: options.company_scale || []
          });
        });
      }

      onDateChange = (field, date) => {
        this.setState({ [field]: date });
      };

      handleSubmit = () => {
        const { cityOptions } = this.state;
        return new Promise((resolve, reject) => {
          this.props.form.validateFieldsAndScroll({scroll: {offsetBottom: 60}},(err, values) => {
            if (!err) {
              resolve(fieldsToProps(values, cityOptions));
            } else {
              reject(err);
            }
          });
        });
      };

      render() {
        const formItemLayout = {
          labelCol: { span: 5 },
          wrapperCol: { span: 19 }
        };

        const {
          cityOptions,
          companyScaleOptions,
          beginTime,
          endTime
        } = this.state;
        const { getFieldDecorator } = this.props.form;
        return (
          <div className="job-experience-edit">
            <Form
              onSubmit={this.handleSubmit}
              className="edit-form"
              layout="horizontal"
            >
              <Row className="one" gutter={35}>
                <Col span={12}>
                  <FormItem label="公司名称" {...formItemLayout}>
                    {getFieldDecorator("company_name", {
                      rules: [
                        {
                          required: true,
                          max: 60,
                          message: "请输入公司名称，且最大60个字符"
                        }
                      ]
                    })(<Input placeholder="请输入公司名称" size="large" />)}
                  </FormItem>
                </Col>
                <Col span={12}>
                  <div className="form-item-period clear">
                    <FormItem
                      label="起止时间"
                      labelCol={{ span: 8 }}
                      wrapperCol={{ span: 15 }}
                    >
                      {getFieldDecorator("begin_time", {
                        rules: [
                          {
                            required: true,
                            message: "请选择起止时间"
                          }
                        ]
                      })(
                        <MonthPicker
                          size="large"
                          format="YYYY/MM"
                          disabledDate={disabledStartDate.bind(null, endTime)}
                          onChange={this.onDateChange.bind(this, "beginTime")}
                        />
                      )}
                    </FormItem>
                    &nbsp;&nbsp;~&nbsp;&nbsp;
                    <FormItem>
                      {getFieldDecorator("end_time", {
                        rules: [
                          {
                            required: true,
                            message: "请选择起止时间"
                          }
                        ]
                      })(
                        <MonthPickerSofar
                          size="large"
                          format="YYYY/MM"
                          disabledDate={disabledEndDate.bind(null, beginTime)}
                          onChange={this.onDateChange.bind(this, "endTime")}
                        />
                      )}
                    </FormItem>
                  </div>
                </Col>
              </Row>
              <Row className="two" gutter={35}>
                <Col span={12}>
                  <FormItem
                    label="城市"
                    {...formItemLayout}
                  >
                    {getFieldDecorator("city", {
                      rules: []
                    })(
                      <Cascader
                        options={cityOptions}
                        showSearch={true}
                        notFoundContent="没有选项"
                        fieldNames={{
                          label: "name",
                          value: "id",
                          children: "list"
                        }}
                        placeholder="请选择城市"
                        size="large"
                      />
                    )}
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem
                    label="职位"
                    {...formItemLayout}
                  >
                    {getFieldDecorator("position", {
                      rules: [
                        {
                          max: 60,
                          message: "请输入职位，且最大60个字符"
                        }
                      ]
                    })(<Input placeholder="请输入职位" size="large" />)}
                  </FormItem>
                </Col>
              </Row>

              <Row className="six" gutter={35}>
                <Col span={12}>
                  <FormItem label="公司规模" {...formItemLayout}>
                    {getFieldDecorator("company_scale", {
                      rules: []
                    })(
                      <Select
                        placeholder="请选择公司规模"
                        size="large"
                        labelInValue
                      >
                        {companyScaleOptions.map(item => (
                          <Option value={item.value} key={item.value}>
                            {item.label}
                          </Option>
                        ))}
                      </Select>
                    )}
                  </FormItem>
                </Col>
              </Row>

              <Row className="five" gutter={35}>
                <Col span={24}>
                  <FormItem
                    label="实习描述"
                    labelCol={{ span: 2 }}
                    wrapperCol={{ span: 22 }}
                  >
                    {getFieldDecorator("work_desc", {
                      rules: []
                    })(
                      <TextArea
                        placeholder="实习描述"
                        autosize={{ minRows: 2, maxRows: 6 }}
                      />
                    )}
                  </FormItem>
                </Col>
              </Row>
            </Form>
            <style jsx global>
              {`
                .form-item-period {
                  .ant-form-item {
                    display: inline-block;
                  }
                  .ant-form-item:first-child {
                    width: 58%;
                    .ant-form-item-label {
                      width: 35%;
                    }
                  }
                  .ant-form-item:last-child {
                    width: 34%;
                  }
                }
                .job-experience-edit {
                  :global(.five) {
                    :global(.ant-form-item-label) {
                      width: 10%;
                    }
                    :global(.ant-form-item-control-wrapper) {
                      width: 90%;
                      :global(.ant-input) {
                        height: 100px;
                        resize: none;
                      }
                    }
                  }
                }
              `}
            </style>
          </div>
        );
      }
    }
  );
}
export const PracticeExperienceEditForm = createPracticeExperience(
  mapPropsToFields,
  fieldsToProps
);

export const newPracticeExperienceEditForm = createPracticeExperience(
  mapPropsToFields,
  fieldsToProps,
  onFieldsChange
);

export function PracticeExperienceView(props) {
  let { item } = props;
  item = _.mapObject(item, (val, key) => {
    return val && val.value;
  });
  return (
    <div className="job-experience-view">
      <Row gutter={10} className="job-experience-item">
        <Col span={4}>
          {item.begin_time.format("YYYY/MM")}-
          {typeof item.end_time === "string"
            ? item.end_time
            : item.end_time.format("YYYY/MM")}
        </Col>
        <Col span={18}>
          <Row gutter={5} className="job-experience-title">
            <Col span={24}>
              {item.company_name} {item.company_scale_label ? "|" : ""}
              {item.company_scale_label}
            </Col>
          </Row>
          <Row gutter={5}>
            <Col span={24}>
              城市 : {item.province_label}
              {item.province_label === item.city_label ? "" : item.city_label} |
              职位 : {item.position}
            </Col>
          </Row>
          <div className="job-content">
            <div>实习描述: </div>
            <pre>{item.work_desc}</pre>
          </div>
        </Col>
      </Row>
      <style jsx>
        {`
          .job-experience-view {
            :global(.job-experience-item) {
              margin-bottom: 28px;
              &:last-child {
                margin-bottom: 0;
              }
              .job-content {
                > div {
                  font-weight: 600;
                }
                p {
                  line-height: 28px;
                  margin-bottom: 0;
                }
              }
            }
            :global(.ant-row) {
              margin-bottom: 18px;
              color: #666;
            }
            :global(.job-experience-title) {
              font-weight: 600;
            }
          }
        `}
      </style>
    </div>
  );
}

class PracticeExperience extends PureComponent {
  state = {
    topPlaceholderBg: "#ffffff",
    bottomPlaceholderBg: "#ffffff"
  };

  formRef = [];

  setPlaceholderColor = (index, color) => {
    const { practiceExperiences } = this.props;
    index === practiceExperiences.length - 1
      ? this.setState({ bottomPlaceholderBg: color })
      : !1;
    index === 0 ? this.setState({ topPlaceholderBg: color }) : !1;
  };

  onEdit = index => {
    this.setPlaceholderColor(index, "#f0f0f0");
    this.props.updatePracticeExperience({ index, values: { isEdit: true } });
  };

  onCancel = index => {
    this.setPlaceholderColor(index, "#ffffff");
    this.props.cancelPracticeExperience(index);
  };

  onSave = index => {
    const { savePracticeExperience } = this.props;
    this.formRef[index].handleSubmit().then(values => {
      savePracticeExperience(index, values)
        .then(res => {
          if (res.code === 0) this.setPlaceholderColor(index, "#ffffff");
          return res;
        })
        .then(res => (!res.code ? res.data.compete : null))
        .then(this.props.toUpdateComplete);
    });
  };

  onDelete = index => {
    this.props
      .removePracticeExperience(index)
      .then(res => (!res.code ? res.data.compete : null))
      .then(this.props.toUpdateComplete);
  };

  onAdd = () => {
    const { practiceExperiences } = this.props;
    if (!practiceExperiences.length) {
      this.setPlaceholderColor(-1, "#f0f0f0");
      this.setPlaceholderColor(0, "#f0f0f0");
    } else {
      this.setState({ bottomPlaceholderBg: "#f0f0f0" });
    }
    this.props.addPracticeExperience({ limit: true });
  };
  render() {
    const { topPlaceholderBg, bottomPlaceholderBg } = this.state;
    const { practiceExperiences } = this.props;
    return (
      <EditViewMulti
        className="job-experience"
        title="实习经历"
        onAdd={this.onAdd}
      >
        <div
          className="placeholder"
          style={{ background: topPlaceholderBg, height: 20 }}
        />
        {practiceExperiences.map((item, index) => (
          <EditViewSingle
            isView={!item.isEdit}
            className={`${
              index === practiceExperiences.length - 1 ? "last" : ""
            }`}
            onEdit={() => this.onEdit(index)}
            onCancel={() => this.onCancel(index)}
            onSave={() => this.onSave(index)}
            onDelete={() => this.onDelete(index)}
            key={item.job_id}
          >
            <div role="view" className="view">
              <PracticeExperienceView item={item} />
            </div>
            <div role="edit" className="edit">
              <PracticeExperienceEditForm
                item={item}
                wrappedComponentRef={inst => (this.formRef[index] = inst)}
              />
            </div>
          </EditViewSingle>
        ))}
        <div
          className="placeholder"
          style={{ background: bottomPlaceholderBg, height: 20 }}
        />
      </EditViewMulti>
    );
  }
}

export default connect(
  ({ intern }) => {
    return {
      practiceExperiences: intern.candidate.detail.practiceExperiences || []
    };
  },
  { ...internModel.actions }
)(updateCompletewrapper(PracticeExperience));
