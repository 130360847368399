import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { Icon, Tooltip } from "antd";
import CityCascade from "components/CityCascade";
import InputComplete from "components/InputComplete";
import * as companyService from "services/companyService";
import AlterationDialog from "./AlterationDialog";
import CustomIcon from "components/CustomIcon";
import PositionVacantModal from "store/reducers/positionVacantM";
import CompanyDetail from "pages/company/companyDetail.js";
import companyModel from "store/reducers/companyModel";
import global from "store/reducers/global";
import SetPerson from "./setPositionPerson.js";

/**
 * 职位详情来源部分
 */
@connect(
  ({ PositionVacantModal, user }) => {
    return { ...PositionVacantModal, roles: user.roles };
  },
  {
    ...PositionVacantModal.actions
  }
)
export default class DetailPositionSource extends PureComponent {
  state = {
    setPersonVisible: false,
    ishh_executive: false
  };
  handleModifyOwnerOk = type => {
    let ishh_executive;
    if (type === true) ishh_executive = { ishh_executive: type };
    this.setState({ setPersonVisible: true, ...ishh_executive });
  };

  handleClosePerson = () => {
    this.setState({ setPersonVisible: false, ishh_executive: false });
  };
  render() {
    const {
      positionDetail = {},
      statusParam,
      headStatus,
      isAll,
      roles
    } = this.props;
    const { hh_executive = [], permission = {}, source } = positionDetail;
    let hh_executiveName =
      hh_executive instanceof Array &&
      hh_executive.map((item, index) => {
        return `${index > 0 ? "," : ""}${(item.org_user || {}).name ||
          (item.org_group || {}).name ||
          (item.org_team || {}).name ||
          (item.org_city || {}).name}`;
      });
    let isRecurit = source === 31; // 是否内招
    return (
      <React.Fragment>
        <div className="detail-position-source clear">
          <span>来源：</span>
          <span>{positionDetail.source_name}</span>
          <span className="ml10">负责顾问：</span>
          <Tooltip placement="bottom" title={positionDetail.hh_org_name}>
            <span>{positionDetail.hh_name}</span>
          </Tooltip>

          {hh_executiveName && hh_executiveName.length > 0 && (
            <span>
              <span className="ml10">协助顾问：</span>
              <Tooltip placement="bottom" title={hh_executiveName}>
                <span className="hh-width ellipsis">{hh_executiveName}</span>
              </Tooltip>
            </span>
          )}
          {isRecurit && (
            <React.Fragment>
              {positionDetail.line_manager && 
                <span>
                  <span className="ml10">直线经理：</span>
                  <Tooltip placement="bottom" title={positionDetail.line_manager}>
                    <span>{positionDetail.line_manager}</span>
                  </Tooltip>
                </span>
              }
              {positionDetail.dep_heads && 
                <span>
                  <span className="ml10">部门主管：</span>
                  <Tooltip placement="bottom" title={positionDetail.dep_heads}>
                    <span>{positionDetail.dep_heads}</span>
                  </Tooltip>
                </span>
              }
              {positionDetail.dep_structure && 
                <span>
                  <span className="ml10">部门架构：</span>
                  <Tooltip placement="bottom" title={positionDetail.dep_structure}>
                    <span>{positionDetail.dep_structure}</span>
                  </Tooltip>
                </span>
              }
            </React.Fragment>
          )}
          {/* 全部tab操作 */}
          {isAll && positionDetail.status === 2 && roles !== "consultant" && roles !== "consultant360" && roles !== "channel" && (
            <Icon
              type="edit"
              style={{ marginLeft: 5, cursor: "pointer" }}
              onClick={this.handleModifyOwnerOk.bind(this, true)}
            />
          )}
          {/* 我的tab操作 */}
          {!isAll &&
            (permission.edit_hh === 1 ||
              permission.edit_hh_executive === 1) && (
              <Icon
                type="edit"
                style={{ marginLeft: 5, cursor: "pointer" }}
                onClick={this.handleModifyOwnerOk.bind(this, true)}
              />
            )}
        </div>
        <SetPerson
          visible={this.state.setPersonVisible}
          onClose={this.handleClosePerson}
          ishh_executive={this.state.ishh_executive}
          isAll={isAll}
        />
        <style jsx global>
          {`
            .detail-position-source {
              color: #999999;
              line-height: 24px;
              text-align: left;
              margin-bottom: 10px;
              .hh-width {
                max-width: 137px;
                display: inline-block;
                vertical-align: bottom;
              }
              .ml10 {
                margin-left: 10px;
              }
              .title {
                line-height: 32px;
                span {
                  margin-right: 10px;
                }
              }
            }
          `}
        </style>
      </React.Fragment>
    );
  }
}
