import Model from "../Model";
import { history } from "root";
import { clearAuthority, setAuthority } from "utils/authority";
import { loginReq, isLoginReq, logoutReq, restPwd, manageTalentFolder, deleteTalentFolder, modifyTalentFolder } from "services/user";
import { reloadAuthorized } from "utils/Authorized";

export default Model.getInstance(
  class extends Model {
    namespace = "user";

    state = {
      org_info: { // 组织架构
        org_city: {},
        org_team: {},
        org_group: {},
        org_user: {},
      },
      talentFolders: []
    };

    actions = {
      async login(param) {
        const loginReqRes = await loginReq(param);
        if (loginReqRes && loginReqRes.code === 0) {
          this.dispatch({
            type: "user/setUser",
            payload: loginReqRes.data
          });
          if (loginReqRes.data.profile_libs && loginReqRes.data.profile_libs[0]) {
            this.dispatch({
              type: "user/setTalentFolders",
              payload: loginReqRes.data.profile_libs
            });
          }
          setAuthority(loginReqRes.data.roles);
          reloadAuthorized();

          /**
           * 当用户角色为迅致用户时，直接跳转迅致人才库
           */
          
          if(loginReqRes.data.roles && loginReqRes.data.roles === 'soonfit_role') {
            history.push("/soonfit");
          } else {
            history.push("/workBench");
          }
          
        }
        return loginReqRes;
      },

      async isLogin() {
        const isLoginReqRes = await isLoginReq({expand: "clue_remind"});
        if (isLoginReqRes && isLoginReqRes.code === 0) {
          this.dispatch({
            type: "user/setUser",
            payload: isLoginReqRes.data
          });
          if (isLoginReqRes.data.profile_libs && isLoginReqRes.data.profile_libs[0]) {
            this.dispatch({
              type: "user/setTalentFolders",
              payload: isLoginReqRes.data.profile_libs
            });
          } else {
            this.dispatch({
              type: "user/setTalentFolders",
              payload: []
            });
          }
        } else {
          clearAuthority();
          history.replace("/user/login");
        }
        return isLoginReqRes;
      },

      async logout() {
        const logoutReqRes = await logoutReq();
        if (logoutReqRes.code === 0) {
          this.dispatch({
            type: "user/setUser",
            payload: {}
          });
          clearAuthority();
          history.replace("/user/login");
        }
        return logoutReqRes
      },

      async restPwd(param) {
        const result = await restPwd({ ...param });
        return result;
      },

      async manageTalentFolder(param) {
        let res = await manageTalentFolder(param);
        if (res.code === 0) {
        }
        return res
      },
      async deleteTalentFolder(param) {
        let res = await deleteTalentFolder(param);
        if (res.code === 0) {
        }
        return res
      },
      async modifyTalentFolder(param) {
        let res = await modifyTalentFolder(param);
        if (res.code === 0) {
        }
        return res
      },
    };

    reducers = {
      setUser(state, { payload: loginReqRes }) {
        return { ...state, ...loginReqRes };
      },
      setUnread(state, { payload: data }) {
        if (data) {
          state.unread_message = state.unread_message ? state.unread_message - 1 : 0;
        } 
        return { ...state,  };
      },
      setTalentFolders(state, { payload: data }) {
        return { ...state, talentFolders: data };
      },
    };
  }
);
