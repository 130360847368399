import React, { Component } from "react";
import { Button } from "antd";

// 单选按钮(可取消)
export default class RadioBtn extends React.PureComponent {
  handleChange=(type)=>{
    const{onChange}=this.props;
    if(type===this.props.value)  type=undefined;
    onChange(type)
  }
  render() {
    const {
      value,
      option = [],
      className = "",
      fieldNames = {value:"value",label:"label"} ,
      onChange,
      ...props,
    } = this.props;
    return (
      <div >
        {option.map(item => (
          <Button
            {...props}
            className={`${className ? className : null}`}
            type={`${value === item[fieldNames.value] ? "primary" : null}`}
            key={item[fieldNames.value]}
            onClick={()=>this.handleChange(item[fieldNames.value])}
          >
            {item[fieldNames.label]}
          </Button>
        ))}
      </div>
    );
  }
}
