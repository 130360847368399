import Model from "../Model";
import * as positionS from "services/positionS";
import * as commonService from "services/commonService";
import dotProp from "dot-prop-immutable";
import * as InternService from "services/Intern";
import * as recomCandToPosService from "services/recomCandToPos";
import _ from "underscore";

export default Model.getInstance(
  class extends Model {
    namespace = "PositionVacantModal";
    state = {
      positionData: {},
      dataProgres: {}, //职位进展tab数据
      statusParam: { type: "1", "per-page": 5, current: 1 }, //职位列表参数
      statusParamCandidate: { status: "-1", "per-page": 10 }, //候选人列表参数
      candidateFilterParam: {},
      positionStack: false, // drawerstack
      positionRowInfo: {}, //职位信息
      positionDetail: {}, //职位信息
      positionChat: {},
      commonData: {}, //公共数据
      candidateData: {}, //候选人进展列表
      candidateDataLoading: false, //候选人进展列表加载态
      candidatePersonProgress: {}, //候选人进展
      candidateProgressLoading: false, //候选人进展加载态
      isLoadingList: false, //在候选人进展的地方操作后要查询职位列表
      profileNumUpdate: false,
      positionDetailsDrawer: false,
      recommendInfo: {
        selectedProfiles: [],
        detailInfo: {}, //推荐的职位详情
        detailProfile: [{}], //推荐人的详情
        active: 1, //步骤条
        emailDialogStatus: false,
        recommendParam: {}, //推荐参数
        recommendSelectKeys: [], // 分配key
        recommendSelectRows: {} // 所有选中的row
      }, //推荐页面信息
      recommendList: {},
      candidateParam: { "per-page": 5,sys_recommend:0 }, //推荐列表人选搜索参数
      loadingRecommendStatus: false, //推荐列表页面加载状态
      urlId: "", //打开职位详情的id
      detailTab: undefined, //打开职位详情默认选中的tab
      optimalInfo: {
        isOpen: false, //完善弹窗职位发布状态
        inappropriateStatus: false, //优职不合适弹窗转台
        optimalList: {}, //优职人选列表
        optimalDetail: {} //优职人选详情
      }, //优职tab信息
      khInfo: {
        isOpen: false, //完善弹窗职位发布状态
        // inappropriateStatus: false, //优职不合适弹窗转台
        khList: {}, //可汗人选列表
        khDetail: {} //可汗人选详情
      }, //可汗tab信息
      optimalListLoading: false, //优职人选列表加载状态
      khListLoading: false, //可汗人选列表加载状态
      optimalDetailLoading: {}, //优职人选详情标记不合适要刷新详情
      detailWidthStatus: false, //宽度状态
      detailHeight: undefined, //获取视口高度
      headStatus: {
        openStatus: "0", //打开改变弹窗的状态
        nowStatus: 0, //改变后当前的状态
        previousStatus: 0 //select改变之前的状态为了没有改变成功在返回之前的状态
      },
      positionEditDrawer: false, //职位编辑抽屉
      recommendModifyStatus: false, //推荐修改弹窗
      progressId:undefined,//人选进展的id（用来编辑后刷新当前进展）
    };

    actions = {
      async getCommonData(dataName) {
        let newCommonData = await commonService.commonData(dataName);
        const { commonData } = this.getState().PositionVacantModal;
        this.dispatch({
          type: "PositionVacantModal/setUpdateState",
          payload: {
            filed: "commonData",
            value: { ...commonData, ...newCommonData }
          }
        });
      },
      async getPositionList(param) {
        this.dispatch({
          type: "PositionVacantModal/setUpdateState",
          payload: { filed: "positionData", value: {} }
        });
        const expand =
          "position_name,company,contactor,province_name,city_name,region_name,status_name,start_time_str,progress_days,jd_profiles,source_name,created_by_name,created_by_org_name,hh_name,hh_org_name,already_reserved_num,publish_status,publish_info,hh_email,jd_address,ch_publish_info";
        const { statusParam } = this.getState().PositionVacantModal;
        // debugger
        let result = await positionS.positionList({
          ...statusParam,
          expand
        });
        if (result) {
          this.dispatch({
            type: "PositionVacantModal/setUpdateState",
            payload: { filed: "positionData", value: result.data }
          });
        }
      },
      async getPositionListAdvance(param) {
        this.dispatch({
          type: "PositionVacantModal/setUpdateState",
          payload: { filed: "positionData", value: {} }
        });
        const expand =
          "position_name,company,contactor,province_name,city_name,region_name,status_name,start_time_str,progress_days,jd_profiles,source_name,created_by_name,created_by_org_name,hh_name,hh_org_name,already_reserved_num,publish_status,publish_info,hh_email,jd_address,ch_publish_info";
        const { statusParam } = this.getState().PositionVacantModal;
        // debugger
        let result = await positionS.positionList({
          ...statusParam,
          ...param,
          expand
        });
        if (result) {
          this.dispatch({
            type: "PositionVacantModal/setUpdateState",
            payload: { filed: "positionData", value: result.data }
          });
        }
      },
      async getCandidateList(id, paramData = {}) {
        const expand =
          "profile_info,recommended_time,reserved_time,arrange_interview_time,offer_coordinate_time,onboard_time,obsolete_time,resigned_time,status_switch,status_name,performance,is_renew,hh_org_info,profile_sys_report,last_event_time,re_recommend_permission";
        let param = {
          ...this.getState().PositionVacantModal.statusParamCandidate,
          ...this.getState().PositionVacantModal.candidateFilterParam,
          ...paramData,
          jd_id: id
        };
        console.log(this.getState().PositionVacantModal.statusParamCandidate,this.getState().PositionVacantModal.candidateFilterParam,paramData, param)
        this.dispatch({
          type: "PositionVacantModal/setUpdateState",
          payload: { filed: "candidateDataLoading", value: true }
        });
        this.dispatch({
          type: "PositionVacantModal/setUpdateState",
          payload: { filed: "candidateProgressLoading", value: true }
        });
        let result = await positionS.candidateList({ ...param, expand });
        if (result) {
          this.dispatch({
            type: "PositionVacantModal/setUpdateState",
            payload: { filed: "candidateData", value: result.data }
          });
          this.dispatch({
            type: "PositionVacantModal/setUpdateState",
            payload: { filed: "candidateDataLoading", value: false }
          });
          this.dispatch({
            type: "PositionVacantModal/setUpdateState",
            payload: {
              filed: "candidateFilterParam",
              value: {
                ...this.getState().PositionVacantModal.candidateFilterParam,
                ...paramData
              }
            }
          });
        }
        return result;
      },
      async getCandidateProgress(param) {
        this.dispatch({
          type: "PositionVacantModal/setUpdateState",
          payload: {
            filed: "candidatePersonProgress",
            value: {}
          }
        });
        this.dispatch({
          type: "PositionVacantModal/setUpdateState",
          payload: { filed: "candidateProgressLoading", value: true }
        });
        const expand =
          "type_name,referrer,status_name,event_time_str,event_name,time_name";
        let result = await positionS.candidateProgress({ ...param, expand });
        if (result) {
          this.dispatch({
            type: "PositionVacantModal/setUpdateState",
            payload: {
              filed: "candidatePersonProgress",
              value: { ...result.data }
            }
          });
          this.dispatch({
            type: "PositionVacantModal/setUpdateState",
            payload: { filed: "candidateProgressLoading", value: false }
          });
          this.dispatch({
            type: "PositionVacantModal/setUpdateState",
            payload: { filed: "progressId", value: param.jd_profile_id }
          });
        }
      },
      async candidateHandle(param) {
        let result = await positionS.candidateStatusManage({ ...param });
        return result;
      },
      async getPositionProgress(param) {
        const expand =
          "status_name,create_time_str,create_by_name,invalid_reason_name,time_name";
        let result = await positionS.positionProgressManage({
          ...param,
          expand
        });
        if (result) {
          this.dispatch({
            type: "PositionVacantModal/setUpdateState",
            payload: { filed: "dataProgres", value: { ...result.data } }
          });
        }
      },
      async getDetail(id) {
        const expand =
          "position_pid,position_name,company,contactor,province_name,city_name,region_name,status_name,start_time_str,progress_days,source_name,created_by_name,created_by_org_name,hh_name,hh_org_name,service_type_name,salary_name,education_name,work_type_name,work_time_name,hh_email,benefits,manager_team,manager_city_id,hh_executive,is_collect,publish_info,jd_address,jd_profiles,screen_permission,hh_org_info,hh_executive,permission,ch_publish_info,is_gbox_public,jd_rmd_hh";
        let result = await positionS.positionDetail({ id, expand });
        this.dispatch({
          type: "PositionVacantModal/setUpdateState",
          payload: { filed: "positionDetail", value: { ...result.data } }
        });
        return result;
      },
      async openPosDrawer(id, show, callback) {
        this.dispatch({
          type: "PositionVacantModal/updatePosId",
          payload: id
        });
        this.dispatch({
          type: "PositionVacantModal/updatePosDetailDrawer",
          payload: show
        });
      },
      async positionAdd(param) {
        let result = await positionS.positionManageAdd({ ...param });
        return result;
      },
      async positionStatusRevamp(param) {
        let result = await positionS.positionManageRevamp({ ...param });
        return result;
      },
      //编辑职位
      async modPos(param) {
        let result = await positionS.modJds(param);
        return result;
      },
      async getChats(param) {
        const expand = "create_time_str,create_by_name,create_by_org_name";
        let result = await positionS.getChatList({ ...param, expand });
        if (result.code === 0) {
          this.dispatch({
            type: "PositionVacantModal/setUpdateState",
            payload: { filed: "positionChat", value: result.data }
          });
        }
        return result;
      },
      async addChat(param) {
        let result = await positionS.addChats(param);
        if (result.code === 0) {
          let chatresult = await positionS.getChatList({
            expand: "create_time_str,create_by_name",
            jd_id: param.jd_id,
            "per-page": 1000,
            page: param.currentPage
          });
          if (chatresult.code === 0) {
            this.dispatch({
              type: "PositionVacantModal/setUpdateState",
              payload: { filed: "positionChat", value: chatresult.data }
            });
          }
        }
        return result;
      },
      async changeManager(param) {
        let result = await positionS.changeManager(param);
        if (result.code === 0) {
          this.dispatch({
            type: "PositionVacantModal/updateManager",
            payload: param
          });
        }
        return result;
      },
      async markProfile(param) {
        let result = await positionS.markProfileSuit(param);
        if (result.code === 0) {
          let candidateData = this.getState().PositionVacantModal.candidateData;
          candidateData.list[param.index].is_suitable = 2;
          this.dispatch({
            type: "PositionVacantModal/setUpdateState",
            payload: { filed: "candidateData", value: { ...candidateData } }
          });
        }
        return result;
      },
      //获取推荐人选列表
      async getRecommendList(params) {
        //状态加载打开
        this.dispatch({
          type: "PositionVacantModal/setUpdateState",
          payload: {
            filed: "loadingRecommendStatus",
            value: true
          }
        });
        let result = await positionS.recommendList(params);
        let oldData = this.getState().PositionVacantModal.recommendInfo;
        let oldList = this.getState().PositionVacantModal.recommendList;
        if (result.code == 0) {
          // this.dispatch({
          //   type: "PositionVacantModal/setUpdateState",
          //   payload: {
          //     filed: "recommendInfo",
          //     value: { ...oldData, ...result.data }
          //   }
          // });
          this.dispatch({
            type: "PositionVacantModal/setUpdateState",
            payload: {
              filed: "recommendList",
              value: { ...oldList, ...result.data }
            }
          });
          //状态加载关闭
          this.dispatch({
            type: "PositionVacantModal/setUpdateState",
            payload: {
              filed: "loadingRecommendStatus",
              value: false
            }
          });
        } else {
          //状态加载关闭
          this.dispatch({
            type: "PositionVacantModal/setUpdateState",
            payload: {
              filed: "loadingRecommendStatus",
              value: false
            }
          });
        }
      },
      //获取推荐人选职位详情
      async getRecommendDetail(id) {
        let result = await positionS.recommendDetail(id);
        if (result.code == 0) {
          let recommendInfo = this.getState().PositionVacantModal.recommendInfo;
          this.dispatch({
            type: "PositionVacantModal/setUpdateState",
            payload: {
              filed: "recommendInfo",
              value: { ...recommendInfo, detailInfo: { ...result.data } }
            }
          });
        }

        return result;
      },
      //获取推荐人选详情
      async getRecommendProfileDetail(param) {
        let result = await positionS.recommendProfileDetail(param);
        if (result.code == 0) {
          let recommendInfo = this.getState().PositionVacantModal.recommendInfo;
          result.data.map(report => {
            report.report.map((item, index) => {
              item.selVal = item.type === 1 ? item.type : _.uniqueId(item.type);
            });
          });
          this.dispatch({
            type: "PositionVacantModal/setUpdateState",
            payload: {
              filed: "recommendInfo",
              value: { ...recommendInfo, detailProfile: result.data }
            }
          });
        }

        return result;
      },
      //推荐候选人到前台
      async recommandPosToFront(param) {
        const { recommendInfo } = this.getState().PositionVacantModal;
        let result = await recomCandToPosService.recommendProfileFront({
          ...param
        });
        if (result.code === 0) {
          this.dispatch({
            type: "PositionVacantModal/setUpdateState",
            payload: {
              filed: "recommendInfo",
              value: { ...recommendInfo, emailDialogStatus: false }
            }
          });
        }
        return result;
      },
      //推荐候选人
      async recommandToPosAndEmail(param) {
        const { recommendInfo } = this.getState().PositionVacantModal;
        let result = await recomCandToPosService.recommandToPos({ ...param });
        if (result.code === 0) {
          this.dispatch({
            type: "PositionVacantModal/setUpdateState",
            payload: {
              filed: "recommendInfo",
              value: { ...recommendInfo, emailDialogStatus: false }
            }
          });
        }
        return result;
      },
      //推荐职位
      async recommandToPos(param) {
        const { recommendInfo } = this.getState().PositionVacantModal;
        let result = await recomCandToPosService.recommandToPos({
          // jd_ids: recomPosIds,
          // profile_ids: selectedCandIds
          ...param
        });
        if (result.code === 0) {
          this.dispatch({
            type: "PositionVacantModal/setUpdateState",
            payload: {
              filed: "recommendInfo",
              value: { ...recommendInfo, emailDialogStatus: false }
            }
          });
        }
        return result;
      },
      //获取优职人选列表
      async getOptimalList(params) {
        //状态加载打开
        this.dispatch({
          type: "PositionVacantModal/setUpdateState",
          payload: {
            filed: "optimalListLoading",
            value: true
          }
        });

        let result = await positionS.optimalList(params);
        if (result.code == 0) {
          let optimalInfo = this.getState().PositionVacantModal.optimalInfo;
          this.dispatch({
            type: "PositionVacantModal/setUpdateState",
            payload: {
              filed: "optimalInfo",
              value: { ...optimalInfo, optimalList: { ...result.data } }
            }
          });
          //状态加载关闭
          this.dispatch({
            type: "PositionVacantModal/setUpdateState",
            payload: {
              filed: "optimalListLoading",
              value: false
            }
          });
        } else {
          //状态加载关闭
          this.dispatch({
            type: "PositionVacantModal/setUpdateState",
            payload: {
              filed: "optimalListLoading",
              value: false
            }
          });
        }
      },
      //获取可汗人选列表
      async getKhList(params) {
        //状态加载打开
        this.dispatch({
          type: "PositionVacantModal/setUpdateState",
          payload: {
            filed: "khListLoading",
            value: true
          }
        });

        let result = await positionS.khList(params);
        if (result.code == 0) {
          let khInfo = this.getState().PositionVacantModal.khInfo;
          this.dispatch({
            type: "PositionVacantModal/setUpdateState",
            payload: {
              filed: "khInfo",
              value: { ...khInfo, khList: { ...result.data } }
            }
          });
          //状态加载关闭
          this.dispatch({
            type: "PositionVacantModal/setUpdateState",
            payload: {
              filed: "khListLoading",
              value: false
            }
          });
        } else {
          //状态加载关闭
          this.dispatch({
            type: "PositionVacantModal/setUpdateState",
            payload: {
              filed: "khListLoading",
              value: false
            }
          });
        }
      },
      //获取优职人选详情
      async getOptimalDetail(param) {
        let result = await positionS.optimalDetail(param);
        if (result.code == 0) {
          let optimalInfo = this.getState().PositionVacantModal.optimalInfo;
          this.dispatch({
            type: "PositionVacantModal/setUpdateState",
            payload: {
              filed: "optimalInfo",
              value: { ...optimalInfo, optimalDetail: result.data }
            }
          });
        }
        return result;
      }
    };

    reducers = {
      setUpdateState(state, { payload }) {
        const { filed, value } = payload;
        state[filed] = value;
        return { ...state };
      },
      updatePosId(state, { payload: data }) {
        return {
          ...state,
          positionRowInfo: { ...state.positionRowInfo, positionId: data }
        };
      },
      updateState(state, { payload }) {
        const { filed, value } = payload;
        state[filed] = { ...value };
        state = dotProp.set(state, `${filed}.active`, value.active);
        return { ...state };
      },
      updatePosDetailDrawer(state, { payload: data }) {
        return { ...state, positionDetailsDrawer: data };
      },
      updateManager(state, { payload: data }) {
        state.positionDetail.hh_id = data.bd_ids[2];
        state.positionDetail.org_id = data.bd_ids[1];
        state.positionDetail.manager_city_id = data.bd_ids[0];
        state.positionDetail.hh_name = data.hh_id_name[2];
        state.positionDetail.manager_team =
          data.hh_id_name[0] + "-" + data.hh_id_name[1];
        return { ...state };
      },
      clearSearch(state, { payload }) {
        state.statusParam = { type: state.statusParam.type, "per-page": 5 };
        return state;
      },
      updateStatePublish(state, { payload }) {
        const { filed, value } = payload;
        state = dotProp.set(
          state,
          `${filed}.publish_info.base_info.publish_status`,
          value.publish_status
        );
        return { ...state };
      },
      updateStateKHPublish(state, { payload }) {
        const { filed, value } = payload;
        state = dotProp.set(
          state,
          `${filed}.ch_publish_info.base_info.publish_status`,
          value.publish_status
        );
        return { ...state };
      },
      updateStateOptimalList(state, { payload }) {
        const { filed, value } = payload;
        state = dotProp.set(
          state,
          `${filed}.optimalList.list.${value.listIndex}.is_read`,
          value.is_read
        );
        state = dotProp.set(
          state,
          `${filed}.optimalList.list.${value.listIndex}.jd_profile_status`,
          value.jd_profile_status
        );
        return { ...state };
      },
      updateRedux(state, { payload }) {
        return { ...state, ...payload };
      },
      updateReduxHead(state, { payload }) {
        return { ...state, headStatus: { ...state.headStatus, ...payload } };
      },
      updatePersonProgress(state, { payload }) {
        const { filed, value } = payload;
        state = dotProp.set(
          state,
          `${filed}.list.${value.listIndex}.event_time_str`,
          value.event_time_str
        );
        state = dotProp.set(
          state,
          `${filed}.list.${value.listIndex}.next_event_time`,
          value.next_event_time
        );
        return { ...state };
      },
      updateRecommendList(state, { payload }) {
        const { filed, value } = payload;
        //更新选择所改的时间
        let list = state.recommendList.list.map((item, index) => {
          if (item.id == value.idIndex) {
            item.default_event_time = value.default_event_time;
          }
          return item;
        });
        //如果在存储中有这个id则进行赋值
        if (
          ((state.recommendInfo.recommendSelectRows||{})[value.idIndex] || {})
            .default_event_time
        ) {
          state.recommendInfo.recommendSelectRows[
            value.idIndex
          ].default_event_time = value.default_event_time;
        }
        return {
          ...state,
          recommendList: { ...state.recommendList },
          recommendInfo: { ...state.recommendInfo, list }
        };
      },
      updateRecommendSelect(state, { payload: data }) {
        return {
          ...state,
          recommendInfo: {
            ...state.recommendInfo,
            recommendSelectKeys: data.selectedRowKeys,
            recommendSelectRows: data.selectedRows
          }
        };
      },
      // 人才库初始化跳转
      updateRecommendSelectProfiles(state, { payload: data }) {
        const {profileIds, detailInfo, emails} = data;
        state.recommendInfo.selectedProfiles = profileIds;
        state.recommendInfo.active = 1;
        state.recommendInfo.detailInfo = detailInfo;
        state.recommendInfo.recommendParam = {
          jd_ids: [detailInfo.id],
          emails: emails,
          profile_ids: profileIds
        };
        state.recommendInfo.profile_id = profileIds[0];
        return state;
      },
      setUpdateRow(state, { payload: data }) {
        const { filed, value } = data;
        state.recommendInfo[filed] = value;
        return state;
      }
    };
  }
);
