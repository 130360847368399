import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { Timeline } from "antd";
import PositionVacantModal from "store/reducers/positionVacantM";

/**
 * 职位进展
 */
@connect(
  ({ PositionVacantModal }) => {
    return { ...PositionVacantModal };
  },
  {
    ...PositionVacantModal.actions
  }
)
export default class PositionProgress extends PureComponent {
  state = {
    reverse: false
  };
  // componentDidMount() {
  //   /**
  //    * @description 初始化查询
  //    */
  //   const { getPositionProgress, positionRowInfo } = this.props;
  //   getPositionProgress({ jd_id: positionRowInfo.positionId });
  // }
  render() {
    const { dataProgres = {} } = this.props;
    return (
      <React.Fragment>
        <div className="position-progress-box collapse-handle-box">
          <Timeline reverse={this.state.reverse}>
            {dataProgres.list &&
              dataProgres.list.map((item, index) => {
                return (
                  <Timeline.Item key={index}>
                    <div className="progress">
                      <p>
                        <b>{item.status_name}</b>
                      </p>
                      <p className="mark">
                        {item.remark && <span>备注：{item.remark}</span>}
                      </p>
                      {item.time_name && (
                        <p className="time">
                          {item.time_name}：{item.create_time_str}
                        </p>
                      )}
                    </div>
                  </Timeline.Item>
                );
              })}
          </Timeline>
        </div>
        <style jsx global>
          {`
            .position-progress-box {
              padding: 30px 40px;
              height: calc(100% - 50px);
              .ant-timeline-item-content {
                top: -16px;
                .ant-timeline-item {
                  padding: 0 0 10px 0;
                }
              }
              .progress {
                p {
                  margin-bottom: 11px;
                  &.mark {
                    color: #666666;
                  }
                  &.time {
                    color: #999999;
                  }
                }
              }
            }
          `}
        </style>
      </React.Fragment>
    );
  }
}
