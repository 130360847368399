import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { Drawer, Icon, Button, Modal } from "antd";
import {
  createBaseInfoEditForm,
  fieldsToProps,
  BaseInfoEditForm as ImportBaseInfoEditForm
} from "../BaseInfoIntern";
import {
  createCertificateEditForm,
  fieldsToProps as certFieldsToProps,
  CertificateEditForm as ImportCertificateEditForm
} from "../Certificate";
import {
  createEducationEditForm,
  fieldsToProps as eduFieldsToProps,
  EducationEditForm as ImportEducationEditForm
} from "../Education";
import {
  createPracticeExperience,
  fieldsToProps as practiceFieldsToProps,
  PracticeExperienceEditForm as ImportPracticeExperienceEditForm
} from "../PracticeExperience";
import {
  createCampusPracticeEditForm,
  fieldsToProps as campusFieldsToProps,
  CampusPracticeEditForm as ImportCampusPracticeEditForm
} from "../CampusPractice";
import {
  createJobStatusEditForm,
  fieldsToProps as jobStatusFieldsToProps,
  JobStatusEditForm as ImportJobStatusEditForm
} from "../JobStatusIntern";
import {
  createPersonalWorkEditForm,
  fieldsToProps as personalFieldsToProps,
  PersonalWorkEditForm as ImportPersonalWorkEditForm
} from "../PersonalWork";
import internModel from "store/reducers/internModel";
const confirm = Modal.confirm;

const BaseInfoEditForm = createBaseInfoEditForm(undefined, fieldsToProps);
const EducationEditForm = createEducationEditForm(undefined, eduFieldsToProps);
const JobStatusEditForm = createJobStatusEditForm(
  undefined,
  jobStatusFieldsToProps
);
const PracticeExperienceEditForm = createPracticeExperience(
  undefined,
  practiceFieldsToProps
);
const CampusPracticeEditForm = createCampusPracticeEditForm(
  undefined,
  campusFieldsToProps
);
const PersonalWorkEditForm = createPersonalWorkEditForm(
  undefined,
  personalFieldsToProps
);
const CertificateEditForm = createCertificateEditForm(
  undefined,
  certFieldsToProps
);

function stateEditForm(EditForm, name, idMaps) {
  return class StateList extends PureComponent {
    crefs = [];
    state = {
      items: []
    };

    submit = () => {
      const promiseList = this.crefs
        .filter(ref => !!ref)
        .map(ref => ref.handleSubmit());
      return promiseList;
    };

    onAddItem = () => {
      const { items } = this.state;
      items.push({
        [idMaps[name].id]: `create-${new Date().getTime()}`,
        isEdit: true
      });
      this.setState({ items: [...items] });
    };

    deleteItem = index => {
      const { items } = this.state;
      items.splice(index, 1);
      this.setState({ items: [...items] });
    };

    render() {
      const { items } = this.state;
      return (
        <React.Fragment>
          {items.map((item, index) => {
            return (
              <div key={item[idMaps[name].id]}>
                <div style={{ marginBottom: 10 }} className="clear">
                  <span
                    className="right"
                    style={{ cursor: "pointer" }}
                    onClick={() => this.deleteItem(index)}
                  >
                    <Icon type="delete" style={{ marginRight: 5 }} />
                    删除此条编辑
                  </span>
                </div>
                <EditForm
                  item={item}
                  wrappedComponentRef={inst => (this.crefs[index] = inst)}
                />
              </div>
            );
          })}
        </React.Fragment>
      );
    }
  };
}

function importEditForm(ImportEditForm, name, idMaps, connectFunc) {
  class ImportList extends PureComponent {
    crefs = [];

    submit = () => {
      const promiseList = this.crefs
        .filter(ref => !!ref)
        .map(ref => ref.handleSubmit());
      return promiseList;
    };

    deleteImportItem = index => {
      this.props.removeImportItem(index);
    };

    onAddItem = () => {
      this.stateListRef.current.onAddItem();
    };

    render() {
      const { importItems } = this.props;
      return (
        <React.Fragment>
          {importItems.map((item, index) => (
            <div key={item[idMaps[name].id]}>
              <div style={{ marginBottom: 10 }} className="clear">
                <span
                  className="right"
                  style={{ cursor: "pointer" }}
                  onClick={() => this.deleteImportItem(index)}
                >
                  <Icon type="delete" style={{ marginRight: 5 }} />
                  删除此条编辑
                </span>
              </div>
              <ImportEditForm
                item={item}
                wrappedComponentRef={inst => (this.crefs[index] = inst)}
              />
            </div>
          ))}
        </React.Fragment>
      );
    }
  }

  return connectFunc(function(props) {
    const { cref, ...otherprops } = props;
    return <ImportList {...otherprops} ref={cref} />;
  });
}

function listEditForm(ImportEditForm, EditForm, name, connectFunc) {
  const idMaps = {
    education: {
      id: "education_id",
      title: "教育经历"
    },
    practice: {
      id: "job_id",
      title: "实习经历"
    },
    campus: {
      id: "campus_id",
      title: "校园实践"
    },
    certificate: {
      id: "cert_id",
      title: "证书&认证"
    },
    personalwork: {
      id: "works_id",
      title: "个人作品"
    }
  };

  const StateEditForm = stateEditForm(EditForm, name, idMaps);
  ImportEditForm = importEditForm(ImportEditForm, name, idMaps, connectFunc);
  return class extends PureComponent {
    onAddItem = () => {
      this.props.refc.current.onAddItem();
    };

    render() {
      const { importRefc, refc } = this.props;
      return (
        <div className="item education-form">
          <h2 className="clear">
            {idMaps[name].title}
            <Icon
              type="plus-square"
              theme="outlined"
              className="right"
              onClick={this.onAddItem}
            />
          </h2>
          <ImportEditForm cref={importRefc} />
          <StateEditForm ref={refc} />
        </div>
      );
    }
  };
}

const EducationForm = function(props) {
  const { importRefc, refc } = props;
  const EducationFormList = listEditForm(
    ImportEducationEditForm,
    EducationEditForm,
    "education",
    connect(
      ({ intern }) => ({
        importItems: intern.newCandidate.educations || []
      }),
      { removeImportItem: internModel.actions.removeNewEducation }
    )
  );
  return <EducationFormList importRefc={importRefc} refc={refc} />;
};

const PracticeExperienceForm = function(props) {
  const { importRefc, refc } = props;
  const PracticeExperienceFormList = listEditForm(
    ImportPracticeExperienceEditForm,
    PracticeExperienceEditForm,
    "practice",
    connect(
      ({ intern }) => ({
        importItems: intern.newCandidate.practiceExperiences || []
      }),
      { removeImportItem: internModel.actions.removeNewPracticeExpericence }
    )
  );
  return <PracticeExperienceFormList importRefc={importRefc} refc={refc} />;
};

const CampusForm = function(props) {
  const { importRefc, refc } = props;
  const CampusFormList = listEditForm(
    ImportCampusPracticeEditForm,
    CampusPracticeEditForm,
    "campus",
    connect(
      ({ intern }) => ({
        importItems: intern.newCandidate.campusPractices || []
      }),
      { removeImportItem: internModel.actions.removeNewCampusPractice }
    )
  );
  return <CampusFormList importRefc={importRefc} refc={refc} />;
};

const CertForm = function(props) {
  const { importRefc, refc } = props;
  const CertFormList = listEditForm(
    ImportCertificateEditForm,
    CertificateEditForm,
    "certificate",
    connect(
      ({ intern }) => ({
        importItems: intern.newCandidate.certificates || []
      }),
      { removeImportItem: internModel.actions.removeNewCertificate }
    )
  );
  return <CertFormList importRefc={importRefc} refc={refc} />;
};

const PersonalWorkFrom = function(props) {
  const { importRefc, refc } = props;
  const PersonalWorkFromList = listEditForm(
    ImportPersonalWorkEditForm,
    PersonalWorkEditForm,
    "personalwork",
    connect(
      ({ intern }) => ({
        importItems: intern.newCandidate.personalWorks || []
      }),
      { removeImportItem: internModel.actions.removeNewPersonalWork }
    )
  );
  return <PersonalWorkFromList importRefc={importRefc} refc={refc} />;
};

//手动创建简历
@connect(
  ({ intern }) => ({
    createResumeDrawer: intern.createResumeDrawer,
    baseInfo: intern.newCandidate.baseInfo || {},
    jobStatus: intern.newCandidate.jobStatus || {}
  }),
  { ...internModel.actions }
)
export default class AddResume extends PureComponent {
  componentDidUpdate(prevProps) {
    const { createResumeDrawer } = this.props;
    if (
      createResumeDrawer.visible !== prevProps.createResumeDrawer.visible &&
      createResumeDrawer.visible === true &&
      createResumeDrawer.openType === "create"
    ) {
      this.props.updateNewCandidate({
        baseInfo: {},
        jobStatus: {},
        educations: [],
        practiceExperiences: [], //实习经历
        campusPractices: [], //校园实践
        certificates: [],
        personalWorks: []
      });
      // this.props.addNewEducation({ limit: true });
      // this.props.addNewPracticeExperience({ limit: true });
      // this.props.addNewCampusPractice({ limit: true });
      // this.props.addNewCertificate({ limit: true });
      // this.props.addNewPersonalWork({ limit: true });
    }
  }

  educationRef = React.createRef();
  eduImportRef = React.createRef();
  campusRef = React.createRef();
  campusImportRef = React.createRef();
  practiceRef = React.createRef();
  practiceImportRef = React.createRef();
  certRef = React.createRef();
  certImportRef = React.createRef();
  personalWorkRef = React.createRef();
  personalImportRef = React.createRef();

  onClose = () => {
    confirm({
      title: "确定要放弃正在编辑的简历吗？",
      zIndex: 1005,
      cancelText: "取消",
      okText: "确定",
      onOk: () => {
        this.props.updateCreateResumeDrawer({ visible: false });
      },
      onCancel() {}
    });
  };

  onSave = () => {
    const { createCandidate } = this.props;
    const baseformPromise = this.baseformRef.handleSubmit(),
      jobStatusPromise = this.jobStatusRef.handleSubmit();
    const educationPromiseList = this.educationRef.current
      .submit()
      .concat(this.eduImportRef.current.submit());
    const campusPracticePromiseList = this.campusRef.current
      .submit()
      .concat(this.campusImportRef.current.submit());
    const practiceExpPromiseList = this.practiceRef.current
      .submit()
      .concat(this.practiceImportRef.current.submit());
    const certificatePromiseList = this.certRef.current
      .submit()
      .concat(this.certImportRef.current.submit());
    const personalWorkPromiseList = this.personalWorkRef.current
      .submit()
      .concat(this.personalImportRef.current.submit());

    const educationLen = educationPromiseList.length,
      practiceExpLen = practiceExpPromiseList.length,
      campusPracticeLen = campusPracticePromiseList.length,
      certificateLen = certificatePromiseList.length,
      personalWorkLen = personalWorkPromiseList.length;

    Promise.all([
      baseformPromise,
      jobStatusPromise,
      ...educationPromiseList,
      ...practiceExpPromiseList,
      ...campusPracticePromiseList,
      ...certificatePromiseList,
      ...personalWorkPromiseList
    ])
      .then(values => {
        const baseInfo = values[0],
          jobStatus = values[1],
          profile_school = collectToGroup(educationLen, values, 2),
          profile_job_intern = collectToGroup(
            practiceExpLen,
            values,
            null,
            item => {
              return {
                ...item,
                company: item.company_name
              };
            }
          ),
          profile_campus = collectToGroup(campusPracticeLen, values),
          profile_certificate = collectToGroup(certificateLen, values),
          profile_works = collectToGroup(personalWorkLen, values);

        const { city = {}, native = {}, tags = [] } = baseInfo;
        const baseInfoParam = {
          ...baseInfo,
          city: `${city.province_label ? city.province_label : ""}${
            city.city_label ? `-${city.city_label}` : ""
          }${city.region_label ? `-${city.region_label}` : ""}`,
          native: `${native.province_label ? native.province_label : ""}${
            native.city_label ? `-${native.city_label}` : ""
          }${native.region_label ? `-${native.region_label}` : ""}`,
          tags: tags.map(item => item.value)
        };
        const jobStatusParam = {
          ...jobStatus,
          expect_daily_salary: jobStatus.daily_salary_expect
        };
        const param = {
          ...baseInfoParam,
          ...jobStatusParam,
          profile_school,
          profile_job_intern,
          profile_campus,
          profile_certificate,
          profile_works
        };
        console.log("param", param);
        createCandidate(param);
      })
      .catch(err => {
        console.log(err);
      });
  };

  render() {
    const { baseInfo, jobStatus } = this.props;

    const { createResumeDrawer } = this.props;
    return (
      <Drawer
        width={900}
        placement="right"
        className="creat-drawer-box create-resume-drawer drawer-modal"
        title={createResumeDrawer.title}
        onClose={this.onClose}
        visible={createResumeDrawer.visible}
        style={{
          zIndex: 1002,
          paddingBottom: 53
        }}
      >
        <div className="item base-info-form">
          <h2>基本信息</h2>
          <ImportBaseInfoEditForm
            wrappedComponentRef={inst => (this.baseformRef = inst)}
            baseInfo={baseInfo}
          />
        </div>
        <div className="item job-status-form">
          <h2>求职状态</h2>
          <ImportJobStatusEditForm
            wrappedComponentRef={inst => (this.jobStatusRef = inst)}
            jobStatus={jobStatus}
          />
        </div>
        <EducationForm
          refc={this.educationRef}
          importRefc={this.eduImportRef}
        />
        <PracticeExperienceForm
          refc={this.practiceRef}
          importRefc={this.practiceImportRef}
        />
        <CampusForm refc={this.campusRef} importRefc={this.campusImportRef} />
        <CertForm refc={this.certRef} importRefc={this.certImportRef} />
        <PersonalWorkFrom
          refc={this.personalWorkRef}
          importRefc={this.personalImportRef}
        />
        <div
          style={{
            position: "absolute",
            bottom: 0,
            width: "100%",
            borderTop: "1px solid #e8e8e8",
            padding: "10px 16px",
            textAlign: "left",
            left: 0,
            background: "#fff",
            borderRadius: "0 0 4px 4px"
          }}
        >
          <Button
            onClick={this.onSave}
            type="primary"
            style={{
              marginRight: 20
            }}
          >
            保存
          </Button>
          <Button onClick={this.onClose}>取消</Button>
        </div>
        <style jsx global>
          {`
            h2 {
              font-weight: 600;
              font-size: 16px;
              margin: 38px 0;
            }
            .base-info-form h2 {
              margin-top: 10px;
            }
            .item {
              :global(.ant-form) {
                padding-left: 20px;
              }
              :global(.anticon) {
                cursor: pointer;
              }
            }
          `}
        </style>
      </Drawer>
    );
  }
}

/**
 * 收集数据到组中
 * @param {*} increment
 * @param {*} values
 * @param {*} start
 * @param {*} dataConvert 数据转换函数
 */
function collectToGroup(increment, values, start, dataConvert) {
  const group = [];
  start =
    collectToGroup.lastEnd && collectToGroup.lastEnd < values.length
      ? collectToGroup.lastEnd
      : start;
  const end = start + increment;
  for (let i = start; i < end; i++) {
    group.push(dataConvert ? dataConvert(values[i]) : values[i]);
  }
  collectToGroup.lastEnd = end;
  return group;
}
