import xhr from './index.js'

/**
 * 文章管理
 */
//文章列表
function articleManageList(param) {
  return xhr({
    url: 'admin/v1/articles',
    body:param
  })
}
//文章详情
function articleManageDetail(id) {
  return xhr({
    url: 'admin/v1/articles/'+id,
  })
}
//文章新增
function articleManageAdd(param) {
  return xhr({
    url: "admin/v1/articles",
    body: param,
    method: "POST"
  });
}
//文章编辑
function articleManageEdit(param) {
  return xhr({
    url: "admin/v1/articles/" + param.id,
    body: param,
    method: "put"
  });
}
//文章删除
function articleManageDelete(id) {
  return xhr({
    url: "admin/v1/articles/" + id,
    method: "DELETE"
  });
}
//文章推荐/取消推荐:type= 1.推荐 2.取消推荐
function articleManageRecommend(param) {
  return xhr({
    url: "admin/v1/article/recommend",
    body: param,
    method: "POST"
  });
}
//商机快报列表
function bulletinsList(param) {
  return xhr({
    url: "admin/v1/gb-bulletins",
    body: param,
    method: "get"
  });
}
//商机快报新增
function bulletinsAdd(param) {
  return xhr({
    url: "admin/v1/gb-bulletins",
    body: param,
    method: "post"
  });
}
//商机快报编辑
function bulletinsEdit(param) {
  return xhr({
    url: "admin/v1/gb-bulletins/" + param.id,
    body: param,
    method: "put"
  });
}
//商机快报删除
function bulletinsDel(id) {
  return xhr({
    url: "admin/v1/gb-bulletins/" + id,
    method: "DELETE"
  });
}
export {
  articleManageList,
  articleManageDetail,
  articleManageAdd,
  articleManageRecommend,
  articleManageEdit,
  articleManageDelete,
  bulletinsList,
  bulletinsAdd,
  bulletinsEdit,
  bulletinsDel
};