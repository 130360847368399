import React, { PureComponent } from "react";
import { Layout } from "antd";
import { Link } from "react-router-dom";
import { urlToList, getMenuMatches } from "../utils";
import BaseMenu from "./BaseMenu";
import menuBg from "assets/imgs/menu-background.png";
const { Sider } = Layout;

/**
 * 获得菜单子节点
 * @memberof SiderMenu
 */
const getDefaultCollapsedSubMenus = (props, flatMenuKeys) => {
  const {
    location: { pathname }
  } = props;
  return urlToList(pathname)
    .map(item => getMenuMatches(flatMenuKeys, item)[0])
    .filter(item => item);
};

/**
 * Recursively flatten the data
 * [{path:string},{path:string}] => {path,path2}
 * @param  menu
 */
export const getFlatMenuKeys = menu =>
  menu.reduce((keys, item) => {
    keys.push(item.path);
    if (item.children) {
      return keys.concat(getFlatMenuKeys(item.children));
    }
    return keys;
  }, []);

export default class SideMenu extends PureComponent {
  flatMenuKeys = getFlatMenuKeys(this.props.menuData);

  state = {
    openKeys: getDefaultCollapsedSubMenus(this.props, this.flatMenuKeys)
  };

  static getDerivedStateFromProps(props, state) {
    const { pathname } = state;
    if (props.location.pathname !== pathname) {
      return {
        pathname: props.location.pathname,
        openKeys: getDefaultCollapsedSubMenus(
          props,
          getFlatMenuKeys(props.menuData)
        )
      };
    }
    return null;
  }

  isMainMenu = key => {
    const { menuData } = this.props;
    return menuData.some(item => {
      if (key) {
        return item.key === key || item.path === key;
      }
      return false;
    });
  };

  handleOpenChange = openKeys => {
    const moreThanOne =
      openKeys.filter(openKey => this.isMainMenu(openKey)).length > 1;
    this.setState({
      openKeys: moreThanOne ? [openKeys.pop()] : [...openKeys]
    });
  };

  render() {
    const { collapsed, theme, logo, onCollapse, userAuth } = this.props;
    const { openKeys } = this.state;
    const siderToMenuProps = collapsed ? {} : { openKeys };
    console.log(openKeys, siderToMenuProps, "siderMenuOpenKeys");
    return (
      <Sider
        trigger={null}
        breakpoint="xxl"
        width={180}
        collapsible
        onCollapse={onCollapse}
        collapsedWidth={60}
        collapsed={collapsed}
        theme={theme}
        className="menu-sider"
        // style={{zIndex: "2000"}}
      >
        <div className="logo" id="logo">
          {logo}
        </div>
        <BaseMenu
          {...siderToMenuProps}
          handleOpenChange={this.handleOpenChange}
          onOpenChange={this.handleOpenChange}
          {...this.props}
          userAuth={userAuth}
          className="base-menu"
        />
        {/* <img className="menu-bg-img-sm" src={require("assets/imgs/menu-bg-sm.png")} alt="" />
        <img className="menu-bg-img" src={require("assets/imgs/menu-bg.png")} alt=""/> */}
        <style jsx global>
          {`
            .base-menu {
              background: transparent;
              .ant-menu-inline.ant-menu-sub {
                box-shadow: none;
                background: transparent;
              }
              .ant-menu-inline.ant-menu-sub {
                &:before,&:after {
                  display: none;
                }
              }
              &.ant-menu-inline-collapsed {
                width: 60px;
              }
              &.ant-menu-inline-collapsed > .ant-menu-item {
                padding: 0 21px !important;
              }
              &.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title {
                padding: 0 21px !important;
              }
              .ant-menu-item-selected {
                background-color: rgba(0,0,0,.2) !important;
                border-left: 2px solid #00dc94;
              }
            }
            .menu-sider {
              position: relative;
              background-image: linear-gradient(0deg, #0e1a4a 0%, #2a4280 100%), linear-gradient(	#2a4280, 	#2a4280);
            	background-blend-mode: normal, normal;
              .menu-bg-img {
                position: absolute;
                right: 0;
                bottom: 19px;
              }
              .menu-bg-img-sm {
                position: absolute;
                left: 9px;
                bottom: 210px;
              }
              .ant-layout-sider-children {
                background: url('${menuBg}') 9px 605px no-repeat;
              }
            }
          `}
        </style>
      </Sider>
    );
  }
}
