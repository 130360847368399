import Model from "../Model";
import * as InternService from "services/Intern";
import {
  educationFieldnames,
  certificateFieldnames
} from "pages/TalentPool/fieldsConfig";
import {
  cityNamejoint,
  convertPracticeExperience,
  convertEduExperience,
  convertCertExperience,
  convertCampusExperience,
  convertPersonExperience
} from "pages/TalentPool/interfaceDataConvert";
import { history } from "root";
import { Message } from "antd";

export default Model.getInstance(
  class extends Model {
    namespace = "intern";
    state = {
      internlist: {},
      internlistLoading: false,
      searchCondition: {},
      exportVisible: false,
      //简历创建
      createResumeDrawer: {
        visible: false,
        openType: "create", //create或import
        title: "创建实习生人选"
      },

      //简历导入
      resumeImport: {
        visible: false,
        finish: false // 解析完成
      },

      //候选人
      candidate: {
        candStatus: {
          statusModal: false,
          nowStatus: undefined,
          selectedStatusValue: {},
          statusReason: ""
        },
        id: "",
        // id: 24876,
        detail: {
          baseInfo: {},
          jobStatus: {},
          educations: [],
          practiceExperiences: [], //实习经历
          campusPractices: [], //校园实践
          certificates: [],
          personalWorks: [], //个人作品
          resumeAttachment: ""
        },

        positionProgresses: [],
        communicationRecords: {
          list: [],
          addModal: false,
          contactType: "",
          contactContext: ""
        }
      },

      //新增候选人(包括解析的候选人)
      newCandidate: {
        baseInfo: {},
        jobStatus: {},
        educations: [],
        practiceExperiences: [], //实习经历
        campusPractices: [], //校园实践
        certificates: [],
        personalWorks: [], //个人作品
        resumeAttachment: ""
      },

      profileDetail: {},
      recordJDs: {},
      isClear: false, //收藏的状态是否回显初始值
      folderFilter: false,
    };

    actions = {
      async getProfileDetail(param) {
        let result = await InternService.getProfileDetail(param);
        if (result.code == 0) {
          this.dispatch({
            type: "intern/updateDetail",
            payload: result.data
          });
        }
        return result;
      },

      async getInternList(param) {
        this.dispatch({
          type: "intern/updateInternLoading",
          payload: true
        });
        const { searchCondition } = this.getState().intern;
        let result = await InternService.internList({
          ...searchCondition,
          ...param
        });
        if (result.code == 0) {
          this.dispatch({
            type: "intern/updateInternList",
            payload: result.data
          });
        }
        this.dispatch({
          type: "intern/updateInternLoading",
          payload: false
        });
      },
      async getSocialCandDetail(id) {
        // const { candidate } = this.getState().intern;
        let result = await InternService.internDetail({ id: id });
        if (result.code === 0) {
          result = result.data;
          const { job, base_info } = result;
          result.job = {
            ...job,
            position: base_info.position,
            position_label: base_info.position_label,
            position_p: base_info.position_p,
            position_p_label: base_info.position_p_label,
            graduation_time: base_info.graduation_time,
            position_name: base_info.position_name
          };
          this.dispatch({
            type: "intern/updateCandidateDetail",
            payload: {
              baseInfo: result.base_info,
              jobStatus: result.job,
              educations: result.education.map(item => convertEduExperience(item)),
              practiceExperiences: result.work.map(item => convertPracticeExperience(item)),
              campusPractices: result.campu.map(item => convertCampusExperience(item)),
              certificates: result.certificate.map(item => convertCertExperience(item)),
              personalWorks: result.production.map(item => convertPersonExperience(item)),
              resumeAttachment: result.resume_attachment,
              is_collection: result.is_collection
            }
          });
          this.dispatch({
            type: "intern/updatePositionProgresses",
            payload: result.profile_jd
          });
          this.dispatch({
            type: "intern/updateCommunicationRecords",
            payload: { list: result.profile_communication }
          });
        }
        return result;
      },
      async commitAudit() {
        const { candStatus, id } = this.getState().intern.candidate;
        const { selectedStatusValue, statusReason } = candStatus;
        let result = await InternService.commitAudit({
          profile_id: id,
          apply_type: selectedStatusValue.key,
          apply_reson: statusReason
        });
        if (result.code === 0) {
          this.dispatch({
            type: "intern/updateCandStatus",
            payload: {
              statusModal: false,
              statusReason: "",
              nowStatus: selectedStatusValue
            }
          });
          this.dispatch({
            type: "intern/updateBaseInfo",
            payload: { status: 5, status_label: "审核中" }
          });
        }
      },
      async addCommunicate(values) {
        const { communicationRecords, id } = this.getState().intern.candidate;
        const { contactType, contactContext, list } = communicationRecords;
        let result = await InternService.addCommunicateRecord({
          // profile_id: id,
          // communication_type: contactType,
          // content: contactContext
          ...values
        });
        if (result.code === 0) {
          list.unshift(result.data);
          this.dispatch({
            type: "intern/updateCommunicationRecords",
            payload: {
              addModal: false
              // contactType: "",
              // contactContext: ""
            }
          });
        }
        return result;
      },
      async deleteCommunicate(params) {
        let result = await InternService.deleteCommunicateRecord(params);
        if (result.code === 0) {
        }
        return result;
      },
      async editBaseInfo(values) {
        const { candidate } = this.getState().intern;
        const {
          detail: { baseInfo },
          id
        } = candidate;
        const { city = {}, tags = [], native = {} } = values;
        let result = await InternService.editBaseInfo({
          ...values,
          profile_id: id,
          city: `${city.province_label ? city.province_label : ""}${
            city.city_label ? `-${city.city_label}` : ""
          }${city.region_label ? `-${city.region_label}` : ""}`,
          native: `${native.province_label ? native.province_label : ""}${
            native.city_label ? `-${native.city_label}` : ""
          }${native.region_label ? `-${native.region_label}` : ""}`,
          tags: tags.map(item => item.value)
        });
        //编辑成功
        if (result.code === 0) {
           //为了清空编辑后还原异常空
           if(!native.province_label)values.native=undefined;
           if(!city.city_label)values.city=undefined;
          this.dispatch({
            type: "intern/updateBaseInfo",
            payload: { ...values, isEdit: false }
          });
        } else if (result.code === 405) {
          if (values.existedCallBack) {
            values.existedCallBack(result);
          }
        } else {
          Message.error(result.transformError);
        }
        return result;
      },

      async editJobStatus(values) {
        const { candidate } = this.getState().intern;
        const {
          detail: { jobStatus },
          id
        } = candidate;
        const {
          forward_city,
          daily_salary_expect,
          begin_time,
          end_time
        } = values;
        let result = await InternService.editJobStatus({
          ...values,
          begin_time: /\d{4}\/\d{2}/.test(begin_time) ? begin_time : 0,
          end_time: /\d{4}\/\d{2}/.test(end_time) ? end_time : 0, //如果结束时间是至今，则提交0
          profile_id: id,
          forward_city: forward_city.map(item => cityNamejoint(item)).join(","),
          expect_daily_salary: daily_salary_expect
        });
        //编辑成功
        if (result.code === 0) {
          this.dispatch({
            type: "intern/updateJobStatus",
            payload: { ...values, isEdit: false }
          });
        }
        return result;
      },

      async removeEducation(index) {
        const { candidate } = this.getState().intern;
        const {
          detail: { educations },
          id
        } = candidate;
        const education = educations[index];
        const { education_id } = education;
        const param = { profile_id: id, type: "education", id: education_id };
        let result = await InternService.removeItem(param);
        if (result.code === 0) {
          this.dispatch({
            type: "intern/cleanEducation",
            payload: { index, lastData: result.data }
          });
        }
        return result;
      },
      //保存教育经历
      async saveEducation(index, values) {
        const { candidate } = this.getState().intern;
        const {
          detail: { educations },
          id
        } = candidate;
        const education = educations[index];
        const { education_id } = education;
        const isCreate = /create/.test(education_id);
        const { end_time } = values;
        let result = await InternService.editOrAddEducation({
          ...values,
          end_time: /\d{4}\/\d{2}/.test(end_time) ? end_time : 0, //如果结束时间是至今，则提交0
          profile_id: id,
          education_id: isCreate ? "" : education_id
        });
        if (result.code === 0) {
          const convertValue = convertEduExperience(values);
          const updateValues = isCreate
            ? {
                ...convertValue,
                education_id: result.data.education_id,
                isEdit: false
              }
            : {
                ...convertValue,
                last_education: result.data.education,
                education_id: education_id,
                isEdit: false
              };
          this.dispatch({
            type: "intern/updateEducation",
            payload: { index, values: updateValues }
          });
        }
        return result;
      },
      async saveCertificate(index, values) {
        const { candidate } = this.getState().intern;
        
        const {
          detail: { certificates },
          id
        } = candidate;
        const certificate = certificates[index];
        const { cert_id } = certificate;
        const isCreate = /create/.test(cert_id);
        
        let result = await InternService.editOrAddCertificate({
          ...values,
          profile_id: id,
          cert_id: isCreate ? "" : cert_id
        });
        if (result.code === 0) {
          const convertValue = convertCertExperience(values);
          const updateValues = isCreate
            ? {
                ...convertValue,
                cert_id: result.data.cert_id,
                isEdit: false
              }
            : { ...convertValue, isEdit: false, cert_id: cert_id };
          this.dispatch({
            type: "intern/updateCertificate",
            payload: { index, values: updateValues }
          });
        }
        return result;
      },

      async removeCertificate(index) {
        const { candidate } = this.getState().intern;
        const {
          detail: { certificates },
          id
        } = candidate;
        const certificate = certificates[index];
        const { cert_id } = certificate;
        const param = { profile_id: id, type: "certificate", id: cert_id };
        let result = await InternService.removeItem(param);
        if (result.code === 0) {
          this.dispatch({
            type: "intern/cleanCertificate",
            payload: index
          });
        }
        return result;
      },

      async removePersonalWork(index) {
        const { candidate } = this.getState().intern;
        const {
          detail: { personalWorks },
          id
        } = candidate;
        const personalWork = personalWorks[index];
        const { works_id } = personalWork;
        const param = { profile_id: id, type: "works", id: works_id };
        let result = await InternService.removeItem(param);
        if (result.code === 0) {
          this.dispatch({
            type: "intern/cleanPersonalWork",
            payload: index
          });
        }
        return result;
      },
      //保存个人作品
      async savePersonalWork(index, values) {
        const { candidate } = this.getState().intern;
        const {
          detail: { personalWorks },
          id
        } = candidate;
        const personalWork = personalWorks[index];
        const { works_id } = personalWork;
        const isCreate = /create/.test(works_id);
        let result = await InternService.editOrAddPersonalWork({
          ...values,
          profile_id: id,
          works_id: isCreate ? "" : works_id
        });
        if (result.code === 0) {
          const convertValue = convertPersonExperience(values);
          const updateValues = isCreate
            ? {
                ...convertValue,
                works_id: result.data.works_id,
                isEdit: false
              }
            : { ...convertValue, isEdit: false, works_id: works_id};
          this.dispatch({
            type: "intern/updatePersonalWork",
            payload: { index, values: updateValues }
          });
        }
        return result;
      },
      async removeCampusPractice(index) {
        const { candidate } = this.getState().intern;
        const {
          detail: { campusPractices },
          id
        } = candidate;
        const campusPractice = campusPractices[index];
        const { campus_id } = campusPractice;
        const param = { profile_id: id, type: "campus", id: campus_id };
        let result = await InternService.removeItem(param);
        if (result.code === 0) {
          this.dispatch({
            type: "intern/cleanCampusPractice",
            payload: index
          });
        }
        return result;
      },
      //保存个人作品
      async saveCampusPractice(index, values) {
        const { candidate } = this.getState().intern;
        const {
          detail: { campusPractices },
          id
        } = candidate;
        const campusPractice = campusPractices[index];
        const { campus_id } = campusPractice;
        const isCreate = /create/.test(campus_id);
        const { end_time } = values;
        let result = await InternService.editOrAddCampusPractice({
          ...values,
          end_time: /\d{4}\/\d{2}/.test(end_time) ? end_time : 0, //如果结束时间是至今，则提交0
          profile_id: id,
          campus_id: isCreate ? "" : campus_id
        });
        if (result.code === 0) {
          const convertValue = convertCampusExperience(values);
          const updateValues = isCreate
            ? {
                ...convertValue,
                campus_id: result.data.campus_id,
                isEdit: false
              }
            : { ...convertValue, isEdit: false, campus_id: campus_id };
          this.dispatch({
            type: "intern/updateCampusPractice",
            payload: { index, values: updateValues }
          });
        }
        return result;
      },

      async removePracticeExperience(index) {
        const { candidate } = this.getState().intern;
        const {
          detail: { practiceExperiences },
          id
        } = candidate;
        const practiceExperience = practiceExperiences[index];
        const { job_id } = practiceExperience;
        const param = { profile_id: id, type: "job_intern", id: job_id };
        let result = await InternService.removeItem(param);
        if (result.code === 0) {
          this.dispatch({
            type: "intern/cleanPracticeExperience",
            payload: index
          });
        }
        return result;
      },
      //保存实习经历
      async savePracticeExperience(index, values) {
        const { candidate } = this.getState().intern;
        const {
          detail: { practiceExperiences },
          id
        } = candidate;
        const practiceExperience = practiceExperiences[index];
        const { job_id } = practiceExperience;
        const isCreate = /create/.test(job_id);
        const { end_time } = values;
        let result = await InternService.editOrAddPracticeExperience({
          ...values,
          company: values.company_name,
          end_time: /\d{4}\/\d{2}/.test(end_time) ? end_time : 0, //如果结束时间是至今，则提交0
          profile_id: id,
          job_id: isCreate ? "" : job_id
        });
        if (result.code === 0) {
          const convertValue = convertPracticeExperience(values);
          const updateValues = isCreate
            ? {
                ...convertValue,
                job_id: result.data.intern_id,
                isEdit: false
              }
            : { ...convertValue, isEdit: false, job_id: job_id };
          this.dispatch({
            type: "intern/updatePracticeExperience",
            payload: { index, values: updateValues }
          });
        }
        return result;
      },

      //新增人选
      async createCandidate(param) {
        const {
          createResumeDrawer: { openType },
          newCandidate: { resumeAttachment }
        } = this.getState().intern;
        debugger
        //如果是导入简历，则提交附件参数
        if (openType === "import") {
          param.resume_attachment = resumeAttachment;
        }
        const {
          forward_city,
          profile_school,
          profile_job_intern,
          profile_campus
        } = param;
        param = {
          ...param,
          profile_school: profile_school.map(item => ({
            ...item,
            end_time: /\d{4}\/\d{2}/.test(item.end_time) ? item.end_time : 0
          })),
          profile_job_intern: profile_job_intern.map(item => ({
            ...item,
            end_time: /\d{4}\/\d{2}/.test(item.end_time) ? item.end_time : 0
          })),
          profile_campus: profile_campus.map(item => ({
            ...item,
            end_time: /\d{4}\/\d{2}/.test(item.end_time) ? item.end_time : 0
          })),
          forward_city: forward_city.map(item => cityNamejoint(item)).join(",")
        };
        let result = await InternService.candCreate(param);
        if (result.code === 0) {
          const candidate = result.data;
          const { internlist } = this.getState().intern;
          const newlist = [...(internlist.list || [])];
          newlist.unshift(candidate);
          newlist.pop();
          const newCandlist = { ...internlist, list: newlist };
          if (param.needJump) {
            this.dispatch({
              type: "intern/updateInternList",
              payload: newCandlist
            });
          }
          this.dispatch({
            type: "intern/updateCreateResumeDrawer",
            payload: { visible: false }
          });
          this.dispatch({
            type: "intern/updateResumeImport",
            payload: { finish: false }
          });
          this.dispatch({
            type: "intern/updateNewCandidate",
            payload: {
              baseInfo: {},
              jobStatus: {},
              educations: [],
              practiceExperiences: [], //实习经历
              campusPractices: [], //校园实践
              certificates: [],
              personalWorks: [], //个人作品
              resumeAttachment: ""
            }
          });
          if (param.needJump) {
            history.push({
              pathname: "/candidate/talent-pool",
              state: { open: result.data.id, is_intern: true }
            });
          }
        } else if (result.code === 405) {
          if (param.existedCallBack) {
            param.existedCallBack(result);
          }
        } else {
          Message.error(result.transformError);
        }
        return result;
      },

      async getJDList(param) {
        let result = await InternService.getJDs(param);
        if (result.code == 0) {
          this.dispatch({
            type: "intern/updateJDs",
            payload: result.data
          });
        }
        return result;
      },

      async coverResume(param) {
        let result = await InternService.coverProfiles(param);
        return result;
      },

      async exportRecords(params) {
        let result = await InternService.exportRecords(params);
        return result;
      }
    };

    reducers = {
      updateInternListView(state, { payload: data }) {
        state.internlist.list[data].is_view = 1;
        return { ...state };
      },
      updateInternList(state, { payload: data }) {
        return { ...state, internlist: data };
      },
      updateInternLoading(state, { payload: data }) {
        return { ...state, internlistLoading: data };
      },
      updateSearchCondition(state, { payload: data }) {
        const { searchCondition } = state;
        return { ...state, searchCondition: { ...searchCondition, ...data } };
      },
      clearSearchCondition(state) {
        return { ...state, searchCondition: {} };
      },
      selectCandidate(state, { payload: id }) {
        state.candidate.id = id;
        return { ...state };
      },
      updatePositionProgresses(state, { payload: postionProgresses }) {
        state.candidate.positionProgresses = postionProgresses;
        return { ...state };
      },
      updateCommunicationRecords(state, { payload: data }) {
        const { communicationRecords } = state.candidate;
        state.candidate.communicationRecords = {
          ...communicationRecords,
          ...data
        };
        return { ...state };
      },
      updateCandidateDetail(state, { payload: detail }) {
        state.candidate.detail = detail;
        // state.candidate.cloneDetail = JSON.parse(JSON.stringify(detail));
        return { ...state };
      },
      updateCandStatus(state, { payload: data }) {
        const { candStatus } = state.candidate;
        state.candidate.candStatus = { ...candStatus, ...data };
        return { ...state };
      },
      updateBaseInfo(state, { payload: data }) {
        const { baseInfo } = state.candidate.detail;
        state.candidate.detail.baseInfo = { ...baseInfo, ...data };
        return { ...state };
      },
      updateJobStatus(state, { payload: data }) {
        const { jobStatus } = state.candidate.detail;
        state.candidate.detail.jobStatus = { ...jobStatus, ...data };
        return { ...state };
      },
      updateEducation(state, { payload: data }) {
        const { educations } = state.candidate.detail;
        const { index, values } = data;
        const item = { ...educations[index], ...values };
        educations.splice(index, 1, item);
        state.candidate.detail.educations = [...educations];
        return { ...state };
      },
      cancelEducation(state, { payload: index }) {
        const { educations } = state.candidate.detail;
        const education = educations[index];
        //如果是新建
        if (/create/.test(education.education_id)) {
          educations.splice(index, 1);
        } else {
          //编辑
          const newEducation = { ...education, isEdit: false };
          educations.splice(index, 1, newEducation);
        }
        state.candidate.detail.educations = [...educations];
        return { ...state };
      },
      cleanEducation(state, { payload: data }) {
        const { index, lastData } = data;
        const { educations } = state.candidate.detail;
        educations.splice(index, 1);
        state.candidate.detail.educations = [...educations];
        return { ...state };
      },
      addEducation(
        state,
        {
          payload: { limit }
        }
      ) {
        const { educations } = state.candidate.detail;
        if (limit && educations.length) {
          const lastEducation = educations[educations.length - 1];
          //每次只能新建一个
          if (/create/.test(lastEducation.education_id)) return state;
        }
        const newEducation = {};
        for (const key of educationFieldnames) {
          newEducation[key] = undefined;
        }
        newEducation.education_id = `create-${new Date().getTime()}`;
        newEducation.isEdit = true;
        educations.push(newEducation);
        state.candidate.detail.educations = [...educations];
        return { ...state };
      },

      updateCertificate(state, { payload: data }) {
        const { certificates } = state.candidate.detail;
        const { index, values } = data;
        const item = { ...certificates[index], ...values };
        certificates.splice(index, 1, item);
        state.candidate.detail.certificates = [...certificates];
        return { ...state };
      },

      addCertificate(
        state,
        {
          payload: { limit }
        }
      ) {
        const { certificates } = state.candidate.detail;
        if (limit && certificates.length) {
          const lastCertificate = certificates[certificates.length - 1];
          //每次只能新建一个
          if (/create/.test(lastCertificate.cert_id)) return state;
        }
        const newCertificate = {};
        for (const key of certificateFieldnames) {
          newCertificate[key] = undefined;
        }
        newCertificate.cert_id = `create-${new Date().getTime()}`;
        newCertificate.isEdit = true;
        certificates.push(newCertificate);
        state.candidate.detail.certificates = [...certificates];
        return { ...state };
      },

      cancelCertificate(state, { payload: index }) {
        const { certificates } = state.candidate.detail;
        const certificate = certificates[index];
        //如果是新建
        if (/create/.test(certificate.cert_id)) {
          certificates.splice(index, 1);
        } else {
          //编辑
          const newCertificate = { ...certificate, isEdit: false };
          certificates.splice(index, 1, newCertificate);
        }
        state.candidate.detail.certificates = [...certificates];
        return { ...state };
      },
      cleanCertificate(state, { payload: index }) {
        const { certificates } = state.candidate.detail;
        certificates.splice(index, 1);
        state.candidate.detail.certificates = [...certificates];
        return { ...state };
      },

      updatePersonalWork(state, { payload: data }) {
        const { personalWorks } = state.candidate.detail;
        const { index, values } = data;
        const item = { ...personalWorks[index], ...values };
        personalWorks.splice(index, 1, item);
        state.candidate.detail.personalWorks = [...personalWorks];
        return { ...state };
      },

      addPersonalWork(
        state,
        {
          payload: { limit }
        }
      ) {
        const { personalWorks } = state.candidate.detail;
        if (limit && personalWorks.length) {
          const lastPersonalWork = personalWorks[personalWorks.length - 1];
          //每次只能新建一个
          if (/create/.test(lastPersonalWork.works_id)) return state;
        }
        const newPersonalWork = {};
        newPersonalWork.works_id = `create-${new Date().getTime()}`;
        newPersonalWork.isEdit = true;
        personalWorks.push(newPersonalWork);
        state.candidate.detail.personalWorks = [...personalWorks];
        return { ...state };
      },

      cancelPersonalWork(state, { payload: index }) {
        const { personalWorks } = state.candidate.detail;
        const personalWork = personalWorks[index];
        //如果是新建
        if (/create/.test(personalWork.works_id)) {
          personalWorks.splice(index, 1);
        } else {
          //编辑
          const newPersonalWork = { ...personalWork, isEdit: false };
          personalWorks.splice(index, 1, newPersonalWork);
        }
        state.candidate.detail.personalWorks = [...personalWorks];
        return { ...state };
      },
      cleanPersonalWork(state, { payload: index }) {
        const { personalWorks } = state.candidate.detail;
        personalWorks.splice(index, 1);
        state.candidate.detail.personalWorks = [...personalWorks];
        return { ...state };
      },

      updateCampusPractice(state, { payload: data }) {
        const { campusPractices } = state.candidate.detail;
        const { index, values } = data;
        const item = { ...campusPractices[index], ...values };
        campusPractices.splice(index, 1, item);
        state.candidate.detail.campusPractices = [...campusPractices];
        return { ...state };
      },

      addCampusPractice(
        state,
        {
          payload: { limit }
        }
      ) {
        const { campusPractices } = state.candidate.detail;
        if (limit && campusPractices.length) {
          const lastCampusPractice =
            campusPractices[campusPractices.length - 1];
          //每次只能新建一个
          if (/create/.test(lastCampusPractice.campus_id)) return state;
        }
        const newCampusPractice = {};
        newCampusPractice.campus_id = `create-${new Date().getTime()}`;
        newCampusPractice.isEdit = true;
        campusPractices.push(newCampusPractice);
        state.candidate.detail.campusPractices = [...campusPractices];
        return { ...state };
      },

      cancelCampusPractice(state, { payload: index }) {
        const { campusPractices } = state.candidate.detail;
        const campusPractice = campusPractices[index];
        //如果是新建
        if (/create/.test(campusPractice.campus_id)) {
          campusPractices.splice(index, 1);
        } else {
          //编辑
          const newCampusPractice = { ...campusPractice, isEdit: false };
          campusPractices.splice(index, 1, newCampusPractice);
        }
        state.candidate.detail.campusPractices = [...campusPractices];
        return { ...state };
      },
      cleanCampusPractice(state, { payload: index }) {
        const { campusPractices } = state.candidate.detail;
        campusPractices.splice(index, 1);
        state.candidate.detail.campusPractices = [...campusPractices];
        return { ...state };
      },

      updatePracticeExperience(state, { payload: data }) {
        const { practiceExperiences } = state.candidate.detail;
        const { index, values } = data;
        const item = { ...practiceExperiences[index], ...values };
        practiceExperiences.splice(index, 1, item);
        state.candidate.detail.practiceExperiences = [...practiceExperiences];
        return { ...state };
      },

      addPracticeExperience(
        state,
        {
          payload: { limit }
        }
      ) {
        const { practiceExperiences } = state.candidate.detail;
        if (limit && practiceExperiences.length) {
          const lastPracticeExperience =
            practiceExperiences[practiceExperiences.length - 1];
          //每次只能新建一个
          if (/create/.test(lastPracticeExperience.job_id)) return state;
        }
        const newPracticeExperience = {};
        newPracticeExperience.job_id = `create-${new Date().getTime()}`;
        newPracticeExperience.isEdit = true;
        practiceExperiences.push(newPracticeExperience);
        state.candidate.detail.practiceExperiences = [...practiceExperiences];
        return { ...state };
      },

      cancelPracticeExperience(state, { payload: index }) {
        const { practiceExperiences } = state.candidate.detail;
        const practiceExperience = practiceExperiences[index];
        //如果是新建
        if (/create/.test(practiceExperience.job_id)) {
          practiceExperiences.splice(index, 1);
        } else {
          //编辑
          const newPracticeExperience = {
            ...practiceExperience,
            isEdit: false
          };
          practiceExperiences.splice(index, 1, newPracticeExperience);
        }
        state.candidate.detail.practiceExperiences = [...practiceExperiences];
        return { ...state };
      },

      cleanPracticeExperience(state, { payload: index }) {
        const { practiceExperiences } = state.candidate.detail;
        practiceExperiences.splice(index, 1);
        state.candidate.detail.practiceExperiences = [...practiceExperiences];
        return { ...state };
      },

      updateCreateResumeDrawer(state, { payload: data }) {
        const { createResumeDrawer } = state;
        return {
          ...state,
          createResumeDrawer: { ...createResumeDrawer, ...data }
        };
      },

      updateResumeImport(state, { payload: data }) {
        const { resumeImport } = state;
        return {
          ...state,
          resumeImport: { ...resumeImport, ...data }
        };
      },

      addNewEducation(
        state,
        {
          payload: { limit }
        }
      ) {
        const { educations } = state.newCandidate;
        if (limit && educations.length) {
          const lastEducation = educations[educations.length - 1];
          //每次只能新建一个
          if (/create/.test(lastEducation.education_id)) return state;
        }
        const newEducation = {};
        for (const key of educationFieldnames) {
          newEducation[key] = undefined;
        }
        newEducation.education_id = `create-${new Date().getTime()}`;
        newEducation.isEdit = true;
        educations.push(newEducation);
        state.newCandidate.educations = [...educations];
        return { ...state };
      },

      removeNewEducation(state, { payload: index }) {
        const { educations } = state.newCandidate;
        educations.splice(index, 1);
        state.newCandidate.educations = [...educations];
        return { ...state };
      },

      updateNewEducation(state, { payload: data }) {
        const { educations } = state.newCandidate;
        const { index, values } = data;
        const item = { ...educations[index], ...values };
        educations.splice(index, 1, item);
        state.newCandidate.educations = [...educations];
        return { ...state };
      },

      addNewPracticeExperience(
        state,
        {
          payload: { limit }
        }
      ) {
        const { practiceExperiences } = state.newCandidate;
        if (limit && practiceExperiences.length) {
          const lastPracticeExperience =
            practiceExperiences[practiceExperiences.length - 1];
          //每次只能新建一个
          if (/create/.test(lastPracticeExperience.job_id)) return state;
        }
        const newPracticeExperience = {};
        newPracticeExperience.job_id = `create-${new Date().getTime()}`;
        newPracticeExperience.isEdit = true;
        practiceExperiences.push(newPracticeExperience);
        state.newCandidate.practiceExperiences = [...practiceExperiences];
        return { ...state };
      },

      removeNewPracticeExpericence(state, { payload: index }) {
        const { practiceExperiences } = state.newCandidate;
        practiceExperiences.splice(index, 1);
        state.newCandidate.practiceExperiences = [...practiceExperiences];
        return { ...state };
      },

      updateNewPracticeExperience(state, { payload: data }) {
        const { practiceExperiences } = state.newCandidate;
        const { index, values } = data;
        const item = { ...practiceExperiences[index], ...values };
        practiceExperiences.splice(index, 1, item);
        state.newCandidate.practiceExperiences = [...practiceExperiences];
        return { ...state };
      },

      addNewCampusPractice(
        state,
        {
          payload: { limit }
        }
      ) {
        const { campusPractices } = state.newCandidate;
        if (limit && campusPractices.length) {
          const lastCampusPractice =
            campusPractices[campusPractices.length - 1];
          //每次只能新建一个
          if (/create/.test(lastCampusPractice.campus_id)) return state;
        }
        const newCampusPractice = {};
        newCampusPractice.campus_id = `create-${new Date().getTime()}`;
        newCampusPractice.isEdit = true;
        campusPractices.push(newCampusPractice);
        state.newCandidate.campusPractices = [...campusPractices];
        return { ...state };
      },

      removeNewCampusPractice(state, { payload: index }) {
        const { campusPractices } = state.newCandidate;
        campusPractices.splice(index, 1);
        state.newCandidate.campusPractices = [...campusPractices];
        return { ...state };
      },

      updateNewCampusPractice(state, { payload: data }) {
        const { campusPractices } = state.newCandidate;
        const { index, values } = data;
        const item = { ...campusPractices[index], ...values };
        campusPractices.splice(index, 1, item);
        state.newCandidate.campusPractices = [...campusPractices];
        return { ...state };
      },

      addNewCertificate(
        state,
        {
          payload: { limit }
        }
      ) {
        const { certificates } = state.newCandidate;
        if (limit && certificates.length) {
          const lastCertificate = certificates[certificates.length - 1];
          //每次只能新建一个
          if (/create/.test(lastCertificate.cert_id)) return state;
        }
        const newCertificate = {};
        for (const key of certificateFieldnames) {
          newCertificate[key] = undefined;
        }
        newCertificate.cert_id = `create-${new Date().getTime()}`;
        newCertificate.isEdit = true;
        certificates.push(newCertificate);
        state.newCandidate.certificates = [...certificates];
        return { ...state };
      },

      removeNewCertificate(state, { payload: index }) {
        const { certificates } = state.newCandidate;
        certificates.splice(index, 1);
        state.newCandidate.certificates = [...certificates];
        return { ...state };
      },

      updateNewCertificate(state, { payload: data }) {
        const { certificates } = state.newCandidate;
        const { index, values } = data;
        const item = { ...certificates[index], ...values };
        certificates.splice(index, 1, item);
        state.newCandidate.certificates = [...certificates];
        return { ...state };
      },

      addNewPersonalWork(
        state,
        {
          payload: { limit }
        }
      ) {
        const { personalWorks } = state.newCandidate;
        if (limit && personalWorks.length) {
          const lastPersonalWork = personalWorks[personalWorks.length - 1];
          //每次只能新建一个
          if (/create/.test(lastPersonalWork.works_id)) return state;
        }
        const newPersonalWork = {};
        newPersonalWork.works_id = `create-${new Date().getTime()}`;
        newPersonalWork.isEdit = true;
        personalWorks.push(newPersonalWork);
        state.newCandidate.personalWorks = [...personalWorks];
        return { ...state };
      },

      removeNewPersonalWork(state, { payload: index }) {
        const { personalWorks } = state.newCandidate;
        personalWorks.splice(index, 1);
        state.newCandidate.personalWorks = [...personalWorks];
        return { ...state };
      },

      updateNewPersonalWork(state, { payload: data }) {
        const { personalWorks } = state.newCandidate;
        const { index, values } = data;
        const item = { ...personalWorks[index], ...values };
        personalWorks.splice(index, 1, item);
        state.newCandidate.personalWorks = [...personalWorks];
        return { ...state };
      },

      updateNewCandidate(state, { payload: detail }) {
        state.newCandidate = detail;
        return { ...state };
      },

      updateNewBaseInfo(state, { payload: data }) {
        const { baseInfo } = state.newCandidate;
        state.newCandidate.baseInfo = { ...baseInfo, ...data };
        return { ...state };
      },

      updateDetail(state, { payload: data }) {
        return { ...state, profileDetail: data };
      },
      updateJDs(state, { payload: data }) {
        return { ...state, recordJDs: data };
      },
      setUpdateState(state, { payload }) {
        const { filed, value } = payload;
        state[filed] = value;
        return { ...state };
      }
    };
  }
);
