import React from "react";
import ReactDOM from "react-dom";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  Row,
  Col,
  Select,
  Input,
  Form,
  Cascader,
  message
} from "antd";
import CityCascade from "components/CityCascade";
import LogoUpload from "components/UserAvatarUpload/logoUpload";
import * as commonService from "services/commonService";

const { TextArea } = Input;
const Option = Select.Option;
const FormItem = Form.Item;

export default class EditCompanyForm extends React.Component {
  state = {
    dataSource: []
  };
  onBlurHandle = (val) =>{
    if(!val.target.value) return;
    let params={
        name:val.target.value,
        type:3,
        obj_id:this.props.companyDetail.id
    }
    console.log('val',val.target.value,'param',params,'companyDetail',this.props.companyDetail)
    commonService.verifyCompany(params).then(res => {
      if (res.code === 0) {

      } else {
        message.warning(res.msg,4);
      }
    })  
  };
  render() {
    const {commonData, companyDetail} = this.props;
    const formItemLayout = {
      labelCol: { span: 5 },
      wrapperCol: { span: 19 }
    };
    const size = "large";
    const {
      getFieldDecorator,
    } = this.props.form;
    return (
      <Form className="edit-company-form">
        <Row gutter={35}>
          <Col span={12}>
            <FormItem label="公司Logo" {...formItemLayout}>
              {getFieldDecorator("logo", {
                initialValue: companyDetail.logo,
                normalize: (value) => value && value.data ? value.data.url : value
              })(
                <LogoUpload action="/admin/v1/common/upload" name="attachment"></LogoUpload>
              )}
            </FormItem>
          </Col>
        </Row>
        <Row gutter={35}>
          <Col span={12}>
            <FormItem
              className="add-company-item"
              label="公司名称"
              {...formItemLayout}>
              {getFieldDecorator("name", {
                initialValue: companyDetail.name,
                rules: [
                  { required: true, message: "请输入公司名称" },
                  { max: 20, message: "公司名称不能超过20个字" }
                ]
              })(<Input onBlur={this.onBlurHandle} placeholder="" size={size} />)}
            </FormItem>
          </Col>
          <Col span={12}>
            <FormItem
              className="add-company-item"
              label="公司简称"
              {...formItemLayout}
            >
              {getFieldDecorator("other_name", {
                initialValue: companyDetail.other_name,
                // rules: [{ max: 10, message: "公司简称不能超过10个字" }]
              })(<Input placeholder="" size={size} />)}
            </FormItem>
          </Col>
        </Row>
        <Row gutter={35}>
          <Col span={12}>
            <FormItem
              className="add-company-item"
              label="公司性质"
              {...formItemLayout}
            >
              {getFieldDecorator("company_type_labelValue", {
                initialValue: companyDetail.company_type ? {key: companyDetail.company_type, label: companyDetail.company_type_name} : undefined,
                rules: [{ required: true, message: "请选择公司性质" }]
              })(
                <Select placeholder="请选择公司性质" labelInValue size={size} allowClear>
                  {commonData.company_type &&
                      commonData.company_type.map(item => {
                        return (
                          <Option key={item.value} value={item.value}>
                            {item.label}
                          </Option>
                        );
                      })}
                </Select>
              )}
            </FormItem>
          </Col>
          <Col span={12}>
            <FormItem
              className="add-company-item"
              label="行业类别"
              {...formItemLayout}
            >
              {getFieldDecorator("industry_labelValue", {
                initialValue: companyDetail.industry ? {key: companyDetail.industry, label: companyDetail.industry_name} : undefined,
                rules: [{ required: true, message: "请选择行业类别" }]
              })(
                <Select labelInValue placeholder="请选择行业类别" size={size} allowClear>
                  {commonData.company_industry &&
                      commonData.company_industry.map(item => {
                        return (
                          <Option key={item.value} value={item.value}>
                            {item.label}
                          </Option>
                        );
                      })}
                </Select>
              )}
            </FormItem>
          </Col>
        </Row>
        <Row gutter={35}>
          <Col span={12}>
            <FormItem
              className="special-item"
              label="公司电话"
              {...formItemLayout}
            >
              {getFieldDecorator("contact_no", {
                initialValue: companyDetail.company_address.contact_no,
                rules: [
                  // { pattern: new RegExp(/^((0\d{2,3}-\d{7,8})|(1[3456789]\d{9}))$/), message: "号码格式不正确"}
                ]
              })(<Input rows={1} size={size} />)}
            </FormItem>
          </Col>
          <Col span={12}>
            <FormItem
              className="add-company-item"
              label="公司规模"
              {...formItemLayout}
            >
              {getFieldDecorator("scale_labelValue", {
                initialValue: companyDetail.scale ? {key: companyDetail.scale, label: companyDetail.scale_name} : undefined,
                rules: [{ required: true, message: "请选择公司规模" },]
              })(
                <Select placeholder="请选择公司规模" labelInValue size={size} allowClear>
                  {commonData.company_scale &&
                      commonData.company_scale.map(item => {
                        return (
                          <Option key={item.value} value={item.value}>
                            {item.label}
                          </Option>
                        );
                      })}
                </Select>
              )}
            </FormItem>
          </Col>
        </Row>
        <Row gutter={35}>
          <Col span={12}>
            <FormItem
              className="add-company-item"
              label="所属城市"
              {...formItemLayout}
            >
              {getFieldDecorator("city", {
                initialValue: [companyDetail.company_address.province_id,companyDetail.company_address.city_id,companyDetail.company_address.region_id,],
                rules: [
                ]
              })(<CityCascade className="gee-cascader" size={size}
                fieldNames={{ label: "name", value: "id", children: "list" }}
                options={commonData.cities}
                placeholder="请选择所属城市"/>)}
            </FormItem>
          </Col>
          <Col span={12}>
            <FormItem
              className="special-item"
              label="详细地址"
              {...formItemLayout}
            >
              {getFieldDecorator("address", {
                initialValue: companyDetail.company_address.address,
                rules: [
                  { max: 128, message: "自定义名称不能超过128个字符" }
                ]
              })(<Input size={size} />)}
            </FormItem>
          </Col>
        </Row>
        <Row gutter={35}>
          <Col span={24}>
            <FormItem
              className="company-intro"
              label="公司描述"
              labelCol={{ span: 2 }}
              wrapperCol={{ span: 22 }}
            >
              {getFieldDecorator("desc", {
                initialValue: companyDetail.desc,
                rules: [{ required: true, message: "请输入公司描述" }]
              })(<TextArea rows={4} size={size} />)}
            </FormItem>
          </Col>
        </Row>
      </Form>
    );
  }
}
