import xhr from './index.js'

/**
 * 组织架构管理
 */
//组织架构部门成员列表
function organizationManageList(param) {
  return xhr({
    url: 'admin/v1/org/org-list',
    body:param
  })
}
//组织架构列表
function organizationMemberManageList(param) {
  return xhr({
    url: 'admin/v1/orgs',
    body:param
  })
}
//组织架构详情
function organizationManageDetail(id) {
  return xhr({
    url: 'admin/v1/org/info?uid='+id,
  })
}
//组织架构新增
function organizationManageAdd(param) {
  return xhr({
    url: "admin/v1/org/create-member",
    body: param,
    method: "POST"
  });
}
//组织架构编辑
function organizationManageEdit(param) {
  return xhr({
    url: "admin/v1/org/edit",
    body: param,
    method: "post"
  });
}
// //文章删除
// function articleManageDelete(id) {
//   return xhr({
//     url: "admin/v1/articles/" + id,
//     method: "DELETE"
//   });
// }
//组织架构是否允许登录
function organizationManageLogin(param) {
  return xhr({
    url: "admin/v1/org/set-status",
    body:param,
    method: "POST"
  });
}
//组织架构重置密码
function organizationManagePassword(param) {
  return xhr({
    url: "admin/v1/org/rest-pwd",
    body: param,
    method: "POST"
  });
}
export {
  organizationManageList,
  organizationMemberManageList,
  organizationManageDetail,
  organizationManageAdd,
  organizationManageEdit,
  organizationManageLogin,
  organizationManagePassword
};