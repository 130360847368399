import _ from "underscore";
import moment from "moment";

export function cityNamejoint(city) {
  city = provincialCapitalShow(city);
  let { province_label, city_label, region_label } = city;
  province_label = city_label ? `${province_label}-` : province_label;
  city_label = region_label ? `${city_label}-` : city_label;
  return `${province_label || ""}${city_label || ""}${region_label || ""}`;
}

export function convertSubmitJobStatus(values) {
  const { salary_current, salary_expect, forward_city } = values;
  return {
    ...values,
    forward_city: forward_city.map(item => cityNamejoint(item)).join(","),
    salary_current: salary_current && String(salary_current),
    salary_expect: salary_expect && String(salary_expect)
  };
}

export function provincialCapitalShow(provincialCapital) {
  if (!provincialCapital) return {};
  const {
    city_id,
    province_id,
    city_label = "",
    province_label = "",
    region_label = ""
  } = provincialCapital;
  if (city_label === province_label) {
    return { province_label, city_label: "", region_label };
  }
  return { city_label, province_label, region_label };
}

/**
 * 转换教育经历
 */
export function convertEduExperience(edu) {
  const converEdu = _.mapObject(_.omit(edu, "education_id"), (val, key) => {
    if (key === "begin_time") {
      return { value: moment(val, "YYYY/MM") };
    }
    if (key === "end_time") {
      return {
        value: /\d{4}\/\d{2}/.test(val) ? moment(val, "YYYY/MM") : val
      };
    }
    return { value: val };
  });
  return { ...converEdu, education_id: edu.education_id };
}

/**
 * 转换项目经历
 */
export function convertProExperience(project) {
  const converPro = _.mapObject(_.omit(project, "project_id"), (val, key) => {
    if (key === "begin_time") {
      return { value: moment(val, "YYYY/MM") };
    }
    if (key === "end_time") {
      return {
        value: /\d{4}\/\d{2}/.test(val) ? moment(val, "YYYY/MM") : val
      };
    }
    return { value: val };
  });
  return { ...converPro, project_id: project.project_id };
}

/**
 * 转换工作经历
 */
export function convertWorkExp(work) {
  const converWork = _.mapObject(_.omit(work, "job_id"), (val, key) => {
    if (key === "begin_time") {
      return { value: moment(val, "YYYY/MM") };
    }
    if (key === "end_time") {
      return {
        value: /\d{4}\/\d{2}/.test(val) ? moment(val, "YYYY/MM") : val
      };
    }
    if (["salary_scope", "industry", "company_scale"].indexOf(key) !== -1) {
      return {
        value: work[`${key}_label`]
          ? { key: val, label: work[`${key}_label`] }
          : undefined
      };
    }
    return { value: val };
  });
  return { ...converWork, job_id: work.job_id };
}

/**
 * 转换证书
 */
export function convertCertExperience(cert) {
  const converCert = _.mapObject(_.omit(cert, "cert_id"), (val, key) => {
    if (key === "date") {
      return { value: val ? moment(val, "YYYY/MM") : ""};
    }
    return { value: val };
  });
  return { ...converCert, cert_id: cert.cert_id };
}

/**
 * 转换实习经历
 */
export function convertPracticeExperience(job) {
  const converCert = _.mapObject(_.omit(job, "job_id"), (val, key) => {
    if (key === "begin_time") {
      return { value: moment(val, "YYYY/MM") };
    }
    if (key === "end_time") {
      return {
        value: /\d{4}\/\d{2}/.test(val) ? moment(val, "YYYY/MM") : val
      };
    }
    if (key === "city") {
      return {
        value: [job.province, job.city]
      };
    }
    if (["company_scale"].indexOf(key) !== -1) {
      return {
        value: job[`${key}_label`]
          ? { key: val, label: job[`${key}_label`] }
          : undefined
      };
    }
    return { value: val };
  });
  return { ...converCert, job_id: job.job_id };
}

/**
 * 转换实习经历
 */
export function convertCampusExperience(job) {
  const converCert = _.mapObject(_.omit(job, "campus_id"), (val, key) => {
    if (key === "begin_time") {
      return { value: moment(val, "YYYY/MM") };
    }
    if (key === "end_time") {
      return {
        value: /\d{4}\/\d{2}/.test(val) ? moment(val, "YYYY/MM") : val
      };
    }
    if (key === "city") {
      return {
        value: [job.province, job.city]
      };
    }
    return { value: val };
  });
  return { ...converCert, campus_id: job.campus_id };
}

/**
 * 转换实习经历
 */
export function convertPersonExperience(job) {
  const converCert = _.mapObject(_.omit(job, "works_id"), (val, key) => {
    return { value: val };
  });
  return { ...converCert, works_id: job.works_id };
}
