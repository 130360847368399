import React from "react";
import { Modal, Form, Cascader, Input } from "antd";
import { connect } from "react-redux";
import PositionVacantModal from "store/reducers/positionVacantM";
import user from "store/reducers/user";
import MultiCascade from "components/OrgMultiCascade";

const getCityName = (cities, sel, count = 0) => {
  let result = [];
  cities.map(item => {
    if (sel[count] == item.id) {
      if (item.list) {
        result = getCityName(item.list, sel, count + 1);
      }
      result.unshift(item.name);
    }
  });
  return result;
};

class SetPersonForm extends React.PureComponent {
  state = {
    hasRepeat: false
  };

  bdChange = (value, selectedOptions) => {
    this.setState({
      hasRepeat: false
    });
    this.hasRepeat = false;
    const { getFieldValue } = this.props.form;
    let hhIds = getFieldValue("hh_executive_id");
    if (value.length === 0) return;
    hhIds &&
      hhIds.forEach(item => {
        if (item.lastUserId == value[value.length - 1]) {
          this.setState({
            hasRepeat: item
          });
          this.hasRepeat = item;
        }
      });
  };

  hhChange = (value, selectedOptions) => {
    this.setState({
      hasRepeat: false
    });
    this.hasRepeat = false;
    const { getFieldValue } = this.props.form;
    let bd_ids = getFieldValue("bd_ids");
    value &&
      value.forEach(item => {
        if (bd_ids && item.lastUserId == bd_ids[bd_ids.length - 1]) {
          this.setState({
            hasRepeat: item
          });
          this.hasRepeat = item;
        }
      });
  };

  render() {
    const { commonData, positionDetail, ishh_executive, isAll, roles } = this.props;
    const { hh_executive, hh_org_info = {}, permission = {}, hh_id, hh_name, source,line_manager,dep_heads,dep_structure } = positionDetail;

    const { hasRepeat } = this.state;
    const { getFieldDecorator } = this.props.form;
    let hh_executiveInit = hh_executive.map(item => ({
      org_city: item.org_city ? item.org_city.id : "",
      org_city_label: item.org_city ? item.org_city.name : "",
      org_team: item.org_team ? item.org_team.id : "",
      org_team_label: item.org_team ? item.org_team.name : "",
      org_group: item.org_group ? item.org_group.id : "",
      org_group_label: item.org_group ? item.org_group.name : "",
      org_user: item.org_user ? item.org_user.id : "",
      org_user_label: item.org_user ? item.org_user.name : "",
      lastName: item.last_org_name,
      lastUserId: item[item.last_org_name] && item[item.last_org_name].id
    }));
    let initResponsible = [hh_org_info.org_city.id,
    hh_org_info.org_team.id,
    hh_org_info.org_group.id,
    hh_org_info.org_user.id]
    return (
      <Form className="comm-detail person-form">
        <div className="person">负责顾问</div>
        {roles === "special_group_leader" &&
          <div style={{ marginBottom: "20px" }}>
            {hh_name}
          </div>}
        <Form.Item wrapperCol={{ span: 12 }} style={{ display: roles === "special_group_leader" ? "none" : "block" }}>
          {getFieldDecorator("bd_ids", {
            initialValue: initResponsible.length
              ? initResponsible.filter(item => item)
              : null,
            rules: [{ required: true, message: "请选择负责顾问" }]
          })(
            <Cascader
              changeOnSelect={false}
              expandTrigger={"click"}
              showSearch
              placeholder="请选择"
              size="large"
              disabled={!(permission.edit_hh === 1) && !isAll}
              className="gee-cascader"
              fieldNames={{
                label: "name",
                value: "id",
                children: "list"
              }}
              options={commonData.jd_manager}
              onChange={this.bdChange}
            />
          )}
        </Form.Item>
        <div>
          {ishh_executive && (
            <div>
              <div className="person">协助顾问</div>
              <Form.Item wrapperCol={{ span: 12 }}>
                {getFieldDecorator("hh_executive_id", {
                  initialValue: hh_executiveInit
                })(
                  <MultiCascade
                    showSearch={true}
                    changeOnSelect={false}
                    expandTrigger={"click"}
                    disabled={!(permission.edit_hh_executive === 1) && !isAll}
                    needUser={true}
                    options={commonData.jd_manager}
                    fieldNames={{
                      label: "name",
                      value: "id",
                      children: "list"
                    }}
                    placeholder="请选择协助顾问"
                    notFoundContent="没有选项"
                    onChange={this.hhChange}
                    size="large"
                  />
                )}
              </Form.Item>
            </div>
          )}
        </div>
        <Form.Item
          validateStatus={hasRepeat ? "error" : "success"}
          help={
            hasRepeat
              ? `*${
              hasRepeat[hasRepeat.lastName + "_label"]
              }已经为负责顾问，协助顾问不可选择`
              : ""
          }
        >
          {getFieldDecorator("hasRepeat", {})(<div />)}
        </Form.Item>
        {
          source === 31 && (
            <React.Fragment>
              <Form.Item  label="直线经理"
              >
                {getFieldDecorator("line_manager", {
                  initialValue: line_manager
                })(<Input placeholder="请输入" size="large"></Input>)}
              </Form.Item>
              <Form.Item  label="部门主管"
              >
                {getFieldDecorator("dep_heads", {
                  initialValue: dep_heads
                })(<Input placeholder="请输入" size="large"></Input>)}
              </Form.Item>
              <Form.Item  label="部门架构"
              >
                {getFieldDecorator("dep_structure", {
                  initialValue: dep_structure
                })(<Input placeholder="请输入" size="large"></Input>)}
              </Form.Item>
            </React.Fragment>
          )
        }
        {/* <div className="error-repeat">{hasRepeat && `*${hasRepeat.region_label}已经为负责顾问，协助顾问不可选择`}</div> */}
        <style jsx>{`
          :global(.person-form) {
            .person {
              margin-bottom: 20px;
              font-size: 16px;
            }
            .manage-team {
              font-size: 16px;
            }
            .error-repeat {
              color: #f5222d;
            }
          }
        `}</style>
      </Form>
    );
  }
}

// 修改责任人
@connect(
  ({ PositionVacantModal, user }) => {
    return { ...PositionVacantModal, roles: user.roles };
  },
  {
    ...PositionVacantModal.actions
  }
)
export default class SetCurrentPerson extends React.PureComponent {
  state = {};

  onCancel = () => {
    this.props.onClose();
  };

  addRecord = () => {
    const form = this.personForm.props.form;
    const { changeManager, positionDetail, commonData, getDetail } = this.props;

    if (this.personForm.hasRepeat) {
      return;
    }
    form.validateFields((err, values) => {
      console.log("Received values of form: ", values);
      if (err) {
        return;
      }
      values.hh_id_name = getCityName(commonData.jd_manager, values.bd_ids);
      values.jd_id = positionDetail.id;
      values.hh_id = values.bd_ids[values.bd_ids.length - 1];
      if (values.hh_executive_id) {
        values.hh_executive_id = values.hh_executive_id.map(item => {
          return item.lastUserId;
        });
      }

      console.log("Received values of form: ", values);
      changeManager(values).then(res => {
        if (res.code == 0) {
          this.props.onClose();
          form.resetFields();
          getDetail(positionDetail.id);
        }
      });
    });
  };

  personFormRef = formRef => {
    this.personForm = formRef;
  };

  render() {
    const SetPerson = Form.create()(SetPersonForm);
    const {
      commonData,
      positionDetail,
      visible,
      ishh_executive,
      isAll,
      roles,
    } = this.props;
    const { permission = {} } = positionDetail;
    return (
      <React.Fragment>
        <Modal
          zIndex={1001}
          title="修改责任人"
          visible={visible}
          onOk={this.addRecord}
          onCancel={this.onCancel}
        >
          <SetPerson
            wrappedComponentRef={this.personFormRef}
            commonData={commonData}
            positionDetail={positionDetail}
            ishh_executive={ishh_executive}
            permission={permission}
            isAll={isAll}
            roles={roles}
          />
        </Modal>
      </React.Fragment>
    );
  }
}
