import React from "react";
import {
  Row,
  Col,
  Select,
  Input,
  Form,
  Cascader,
  Modal,
  Drawer,
  Button,
  Icon,
  Checkbox,
  message
} from "antd";
import { connect } from "react-redux";
import CityCascade from "components/CityCascade";
import businessModel from "store/reducers/businessModel";
import InputComplete from "components/InputComplete.js";
import LogoUpload from "components/UserAvatarUpload/logoUpload";
import * as companyService from "services/companyService";
import * as commonService from "services/commonService";
import { history } from "root.js";
import { from } from "rxjs";
import {setDisabledOption} from "utils/util";

const { TextArea } = Input;
const Option = Select.Option;
const FormItem = Form.Item;
const formNumber = {
  labelCol: { span: 7 },
  wrapperCol: { span: 15 }
};

/**
 * @param commonData 公共数据对象
 */
class AddBusinessForm extends React.Component {
  state = {
    positionName: {
      value: undefined,
      options: [],
      fetching: false
    }
  };

  positionNameFetch = (value, callback) => {
    if (this.posNameTimeout) {
      clearTimeout(this.posNameTimeout);
      this.posNameTimeout = null;
    }
    this.posNameCurValue = value;

    const fake = () => {
      if (!value) {
        return callback([]);
      }

      this.setState({
        positionName: {
          ...this.state.positionName,
          fetching: true
        }
      });
      companyService.getJdsByName({ name: value }).then(res => {
        if (this.posNameCurValue === value && res.code === 0) {
          let result = res.data || [];
          result = result.filter(text => text !== value);
          result.unshift(value);
          callback(result);
        }
        this.setState({
          positionName: {
            ...this.state.positionName,
            fetching: false
          }
        });
      });
    };

    this.posNameTimeout = setTimeout(fake, 300);
  };

  leastOne = (rule, value, callback) => {
    const {getFieldValue, validateFields} = this.props.form;
    if (rule.field === "phone") {
      if (!value && !getFieldValue("office_number")) {
        callback("手机和电话号码必填一个");
      }
    } else if (rule.field === "office_number") {
      if (!value && !getFieldValue("phone")) {
        callback("手机和电话号码必填一个");
      }
    }
    
    callback();
  }

  validatePhone = (name, e) => {
    const {validateFields} = this.props.form;

    if (!e.target.value || e.target.value.length === 1) {
      setTimeout(() => {
        validateFields(["phone", "office_number"])
      }, 0);
    }    
  }

  handlePosNameSearch = value => {
    this.positionNameFetch(value, data =>
      this.setState({
        positionName: {
          ...this.state.positionName,
          options: data
        }
      })
    );
  };

  handlePosNameChange = (value, option) => {
    this.setState({
      positionName: {
        ...this.state.positionName,
        value
      }
    });
  };

  logoChange = res => {
    const { setFieldsValue } = this.props.form;
    // console.log("change", res)
    // if (res.code === 0) {
    //   // setFieldsValue({logo: res})
    // }
  };

  companyAddressChange = (param) => {
    const { getFieldValue } = this.props.form;
    let companyCity = getFieldValue(`company_city`);
    return new Promise((resolve, reject) => {
      commonService.mapTips({
        province_id: companyCity[0],
        city_id: companyCity[1],
        region_id: companyCity[2],
        address: param.name
      }).then(res => {
        let autoResult = {code: 0};
        if (res.code === 0) {
          autoResult.data = res.data && res.data.length !== 0 ? res.data.map(item => item.name) : [];
        } else {
          autoResult.data = [];
        }
        resolve(autoResult);
      })  
    })  
  }
  onBlurHandle = (val) =>{
    let params={
        name:val.target.value,
        type:2,
    }
    if(!val.target.value) return;
    console.log('val',val.target.value,'param',params,'companyDetail',this.props.companyDetail)
    commonService.verifyCompany(params).then(res => {
      if (res.code === 0) {

      } else {
        message.warning(res.msg,4);
      }
    })  
  };

  render() {
    const { getFieldDecorator,resetFields,getFieldValue } = this.props.form;
    const { commonData, fromMap } = this.props;
    const { positionName } = this.state;
    const formItemLayout = {
      labelCol: { span: 6 },
      wrapperCol: { span: 16 }
    };
    const formItemLayoutSingle = {
      labelCol: { span: 3 },
      wrapperCol: { span: 21 }
    };
    const colItemLayout = {
      span: 12
    };
    const size = "large";
    const logoUpload = {
      action: "/admin/v1/common/upload",
      name: "attachment",
      onChange: this.logoChange
    };
    let some = getFieldValue("some");
    const hasCity = getFieldValue(`company_city`) && getFieldValue(`company_city`).length !== 0;
    const isMap = getFieldValue('source_labelValue') && getFieldValue('source_labelValue')[1] === 32;
    let sourceOption=setDisabledOption(fromMap ? commonData.jd_northern_expedition : commonData.jd_source);
    return (
      <Form className="business-form" layout="horizontal">
        <div className="company-info">
          <h4>公司信息</h4>
          <Row>
            <Col {...colItemLayout}>
              <FormItem label="公司名称" {...formItemLayout}>
                {getFieldDecorator("company_name", {
                  rules: [
                    { required: true, message: "请输入公司名称" },
                    { max: 20, message: "公司名称不能超过20个字" }
                  ]
                })(<Input onBlur={this.onBlurHandle} placeholder="请输入公司名称" size={size} />)}
              </FormItem>
            </Col>
            <Col {...colItemLayout}>
              <FormItem label="公司Logo" {...formItemLayout}>
                {getFieldDecorator("logo", {
                  normalize: value =>
                    value && value.data ? value.data.url : value
                })(<LogoUpload {...logoUpload} />)}
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Col {...colItemLayout}>
              <FormItem label="联系人" {...formItemLayout}>
                {getFieldDecorator("contactor", {
                  rules: [{ required: true, message: "请输入联系人" }]
                })(<Input placeholder="请输入联系人" size={size} />)}
              </FormItem>
            </Col>
            <Col {...colItemLayout}>
              <FormItem label="公司性质" {...formItemLayout}>
                {getFieldDecorator("company_type", {
                  rules: [{ required: true, message: "请选择公司性质" }]
                })(
                  <Select size={size} placeholder="请选择公司性质" allowClear>
                    {commonData.company_type &&
                      commonData.company_type.map(item => {
                        return (
                          <Option key={item.value} value={item.value}>
                            {item.label}
                          </Option>
                        );
                      })}
                  </Select>
                )}
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Col {...colItemLayout}>
              <FormItem label="担任职位" {...formItemLayout}>
                {getFieldDecorator("contactor_position", {
                  rules: [
                    { required: true, message: "请输入担任职位" },
                    { max: 20, message: "担任职位不能超过20个字" }
                  ]
                })(<Input placeholder="请输入担任职位" size={size} />)}
              </FormItem>
            </Col>
            <Col {...colItemLayout}>
              <FormItem label="公司规模" {...formItemLayout}>
                {getFieldDecorator("scale", {
                  rules: [{ required: true, message: "请选择公司规模" }]
                })(
                  <Select size={size} placeholder="请选择公司规模" allowClear>
                    {commonData.company_scale &&
                      commonData.company_scale.map(item => {
                        return (
                          <Option key={item.value} value={item.value}>
                            {item.label}
                          </Option>
                        );
                      })}
                  </Select>
                )}
              </FormItem>
            </Col>
            
          </Row>
          <Row>
            <Col {...colItemLayout}>
              <FormItem label="联系电话" {...formItemLayout}>
                {getFieldDecorator("phone", {
                  rules: [
                    // { required: true, message: "请输入联系电话" },
                    // {
                    //   pattern: new RegExp(
                    //     /^((0\d{2,3}-\d{7,8})|(1[3456789]\d{9}))$/
                    //   ),
                    //   message: "联系电话格式不正确"
                    // },
                    { validator: this.leastOne }
                  ]
                })(<Input placeholder="请输入联系电话" size={size} onChange={(e) => this.validatePhone("phone", e)} />)}
              </FormItem>
            </Col>
            <Col {...colItemLayout}>
              <FormItem label="行业类别" {...formItemLayout}>
                {getFieldDecorator("industry", {
                  rules: [{ required: true, message: "请选择行业类别" }]
                })(
                  <Select size={size} placeholder="请选择行业类别" allowClear>
                    {commonData.company_industry &&
                      commonData.company_industry.map(item => {
                        return (
                          <Option key={item.value} value={item.value}>
                            {item.label}
                          </Option>
                        );
                      })}
                  </Select>
                )}
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Col {...colItemLayout}>
              <FormItem label="联系邮箱" {...formItemLayout}>
                {getFieldDecorator("email", {
                  rules: [
                    {
                      pattern: new RegExp(
                        /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/g
                      ),
                      message: "请输入正确的邮箱格式"
                    }
                  ]
                })(<Input placeholder="请输入联系邮箱" size={size} />)}
              </FormItem>
            </Col>
            <Col {...colItemLayout}>
                <FormItem label="所在城市" {...formItemLayout}>
                  {getFieldDecorator("company_city", {
                    rules: [
                      { required: fromMap || isMap, message: "请选择所在城市" }
                    ]
                  })(
                    <CityCascade
                      size={size} normalStatus={true}
                      className="gee-cascader"
                      fieldNames={{
                        label: "name",
                        value: "id",
                        children: "list"
                      }}
                      options={commonData.cities}
                      placeholder="请选择所在城市"
                    />
                  )}
                </FormItem>
            </Col>
          </Row>
          <Row>
            <Col {...colItemLayout}>
              <FormItem label="公司电话" {...formItemLayout}>
                {getFieldDecorator("office_number", {
                  rules: [
                    // {
                    //   pattern: new RegExp(
                    //     /^((0\d{2,3}-\d{7,8})|(1[3456789]\d{9}))$/
                    //   ),
                    //   message: "公司电话格式不正确"
                    // },
                    { validator: this.leastOne }
                  ]
                })(<Input placeholder="请输入公司电话" size={size} onChange={(e) => this.validatePhone("office_number", e)} />)}
              </FormItem>
            </Col>
            <Col {...colItemLayout}>
              <FormItem label="详细地址" {...formItemLayout}>
                {getFieldDecorator("c_address", {
                  rules: [
                    { max: 100, message: "详细地址不能超过100个字" },
                    { required: fromMap || isMap, message: "请输入详细地址" }
                  ]
                })(<InputComplete disabled={!hasCity} custom={true} size={size}
                    requestFunc={(param) => this.companyAddressChange(param)}
                    placeholder="请输入详细地址"
                    />)}
              </FormItem>
            </Col>
          </Row>
        </div>
        <div>
          <h4>需求信息</h4>
          <Row>
            <Row>
              <Col {...colItemLayout}>
                <FormItem label="职位名称" {...formItemLayout}>
                  {getFieldDecorator("post_name", {
                    rules: [{ required: true, message: "请输入职位名称" }]
                  })(
                    <InputComplete
                      requestFunc={companyService.getJdsByName}
                      size={size}
                      placeholder="请输入职位名称"
                    />
                    // <Select
                    //   allowClear
                    //   size={size}
                    //   showSearch
                    //   placeholder="请输入职位名称"
                    //   defaultActiveFirstOption={false}
                    //   showArrow={false}
                    //   filterOption={false}
                    //   onSearch={this.handlePosNameSearch}
                    //   onChange={this.handlePosNameChange}
                    //   notFoundContent={
                    //     positionName.fetching ? <Spin size="small" /> : null
                    //   }
                    // >
                    //   {positionName.options.map(text => (
                    //     <Option key={text} value={text}>
                    //       {text}
                    //     </Option>
                    //   ))}
                    // </Select>
                  )}
                </FormItem>
              </Col>
              <Col {...colItemLayout}>
                <FormItem label="职能" {...formItemLayout}>
                  {getFieldDecorator("position_label", {
                    rules: [{ required: true, message: "请选择职能" }]
                  })(
                    <Cascader
                      size={size}
                      className="gee-cascader"
                      fieldNames={{
                        label: "label",
                        value: "value",
                        children: "list"
                      }}
                      options={commonData.dispatch_position}
                      placeholder="请选择职能"
                    />
                  )}
                </FormItem>
              </Col>
            </Row>
            <Row>
              <Col {...colItemLayout}>
                <FormItem label="服务类型" {...formItemLayout}>
                  {getFieldDecorator("service_type", {
                    rules: [{ required: true, message: "请选择服务类型" }]
                  })(
                    <Select size={size} placeholder="请选择服务类型">
                      {commonData.service_type &&
                        commonData.service_type.map(item => {
                          return (
                            <Option key={item.value} value={item.value}>
                              {item.label}
                            </Option>
                          );
                        })}
                    </Select>
                  )}
                </FormItem>
              </Col>
              <Col {...colItemLayout}>
                <FormItem label="来源" {...formItemLayout}>
                  {getFieldDecorator("source_labelValue", {
                    rules: [{ required: true, message: "请选择来源" }],
                    initialValue: fromMap ? [32] : undefined
                  })(
                    // <Select size={size} placeholder="请选择来源">
                    //   {commonData.jd_source &&
                    //     commonData.jd_source.map(item => {
                    //       return (
                    //         <Option key={item.value} value={item.value}>
                    //           {item.label}
                    //         </Option>
                    //       );
                    //     })}
                    // </Select>
                    <Cascader
                      // disabled={fromMap}
                      className="gee-cascader custome-type"
                      expandTrigger="hover"
                      changeOnSelect
                      size={size}
                      fieldNames={{
                        label: "label",
                        value: "value",
                        children: "list"
                      }}
                      options={sourceOption}
                      placeholder="请选择来源"
                    />
                  )}
                </FormItem>
              </Col>
            </Row>
            <Row>
              <Col {...colItemLayout}>
                <FormItem label="工作城市" {...formItemLayout}>
                  {getFieldDecorator("city", {
                    rules: []
                  })(
                    <CityCascade
                      size={size}
                      className="gee-cascader"
                      changeOnSelect
                      fieldNames={{
                        label: "name",
                        value: "id",
                        children: "list"
                      }}
                      options={commonData.cities}
                      placeholder="请选择工作城市"
                    />
                  )}
                </FormItem>
              </Col>
              <Col {...colItemLayout}>
                <FormItem label="工作地址" {...formItemLayout}>
                  {getFieldDecorator("address", {
                    rules: [{ max: 100, message: "工作地址不能超过100个字" }]
                  })(<Input placeholder="" size={size} />)}
                </FormItem>
              </Col>
            </Row>
            <Row>
              <Col {...colItemLayout}>
                <Col span={19}>
                  <FormItem label="招聘人数" {...formNumber}>
                    {getFieldDecorator("require_num", {
                      rules: [
                        { required: !some, message: "请输入招聘人数" },
                        {
                          pattern: new RegExp(/^[0-9]*$/),
                          message: "招聘人数只能为数字"
                        }
                      ]
                    })(
                      <Input
                        style={{ marginLeft: 8 }}
                        disabled={some}
                        placeholder="请输入招聘人数"
                        size={size}
                      />
                    )}
                  </FormItem>
                </Col>
                <Col span={5}>
                  <FormItem label="">
                    {getFieldDecorator("some", {
                      rules: []
                    })(
                      <Checkbox
                        onChange={() => resetFields(["require_num"])}
                        style={{ width: "100%" }}
                      >
                        若干
                    </Checkbox>
                    )}
                  </FormItem>
                </Col>
              </Col>
              <Col {...colItemLayout}>
                <FormItem label="工作经验" {...formItemLayout}>
                  {getFieldDecorator("work_time", {
                    initialValue: 1,
                    rules: [{ required: true, message: "请选择工作经验" }]
                  })(
                    <Select size={size} placeholder="请选择工作经验">
                      {commonData.jd_work_time &&
                        commonData.jd_work_time.map(item => {
                          return (
                            <Option key={item.value} value={item.value}>
                              {item.label}
                            </Option>
                          );
                        })}
                    </Select>
                  )}
                </FormItem>
              </Col>
            </Row>
            <Row>
              <Col span="23">
                <FormItem label="职位描述" {...formItemLayoutSingle}>
                  {getFieldDecorator("jd_desc", {
                    rules: [{ required: true, message: "请输入职位描述" }]
                  })(<TextArea rows={2} placeholder="请详细填写职位描述" />)}
                </FormItem>
              </Col>
            </Row>
            <Row>
              <Col span="23">
                <FormItem label="任职要求" {...formItemLayoutSingle}>
                  {getFieldDecorator("requirements", {
                    // rules: [{ required: true, message: "请输入任职要求" }]
                  })(<TextArea rows={2} placeholder="请详细填写任职要求" />)}
                </FormItem>
              </Col>
            </Row>
            <Row>
              <Col span="23">
                <FormItem label="沟通记录" {...formItemLayoutSingle}>
                  {getFieldDecorator("chat_record", {})(
                    <TextArea rows={2} placeholder="请详细填写沟通记录" />
                  )}
                </FormItem>
              </Col>
            </Row>
          </Row>
        </div>


        <style jsx global>{`
          .business-form {
            padding-bottom: 50px;
            h4 {
              font-size: 16px;
              font-weight: 600;
              margin: 40px 0;
            }
            .company-info h4 {
              margin-top: 0;
            }
          }
          `}</style>
      </Form>
    );
  }
}

const AddBusiness = Form.create({})(AddBusinessForm);

@connect(
  ({ business }) => {
    return { ...business };
  },
  {
    addBusiness: businessModel.actions.addBusiness,
    getBusinessList: businessModel.actions.getBusinessList,
    getCommonData: businessModel.actions.getCommonData
  }
)
export default class AddBusinessWrapper extends React.Component {
  state = {};
  componentDidMount() {
    if (history.location.pathname.indexOf("custom/business") == -1) {
      this.props.getCommonData(
        "jd_work_time,team_leader,business_manager,business_invalid_reason,work_type,jd_benefit,jd_education,jd_salary,jd_source,jd_northern_expedition,business_status,service_type,company_type,company_industry,company_scale,dispatch_position,cities"
      );
    }
  }

  handleBusinessOk = () => {
    const form = this.businessForm.props.form;
    const { addBusiness, getBusinessList, pagination, condition, callBack } = this.props;
    form.validateFields((err, values) => {
      console.log("Received values of form: ", values);
      if (err) {
        return;
      }
      values.source =
        values.source_labelValue[values.source_labelValue.length - 1];
      values.province_id = values.city && values.city[0];
      values.city_id = values.city && values.city[1];
      values.region_id = values.city && values.city[2];
      values.c_province_id = values.company_city && values.company_city[0];
      values.c_city_id = values.company_city && values.company_city[1];
      values.c_region_id = values.company_city && values.company_city[2];
      values.position = values.position_label && values.position_label[1];
      if (values.some) values.require_num = 0;
      // if (values.logo && values.logo.code === 0) {
      //   values.logo = values.logo.data.url;
      // } else {
      //   values.logo = undefined;
      // }

      addBusiness(values).then(res => {
        if (res.code == 0) {
          if (pagination) {
            getBusinessList({
              "per-page": pagination.pageSize,
              page: pagination.current,
              ...condition
            });
          }
          form.resetFields();
          if (callBack) {
            callBack({
              // province_id: values.c_province_id,
              // city_id: values.c_province_id,
              // region_id: values.c_region_id,
              // position: values.c_address,
            });
          }
          this.props.onClose();
        }
      });
    });
  };

  handleBusinessCancel = () => {
    const form = this.businessForm.props.form;
    let that = this;
    let modal = Modal.confirm({
      title: "确定要放弃吗？",
      getContainer: false,
      onOk() {
        // setTimeout(() => {
        form.resetFields();
        that.props.onClose();
        // }, 300);
      },
      onCancel() {}
    });
  };

  businessFormRef = formRef => {
    this.businessForm = formRef;
  };

  render() {
    return (
      <Drawer
        // width={846}
        // destroyOnClose
        // className="business-modal"
        // title="新建商机"
        // visible={this.props.visible}
        // onOk={this.handleBusinessOk}
        // onCancel={this.handleBusinessCancel}
        // getContainer={false}
        width={900}
        title="新建商机"
        placement="right"
        className="creat-drawer-box resume-drawer"
        onClose={this.handleBusinessCancel}
        onOk={this.handleBusinessOk}
        visible={this.props.visible}
        // style={{ paddingBottom: 53, minHeight: 1100 }}
        destroyOnClose
      >
        <AddBusiness
          fromMap={this.props.fromMap}
          wrappedComponentRef={this.businessFormRef}
          commonData={this.props.commonData}
        />
        <div
          style={{
            position: "absolute",
            bottom: 0,
            width: "100%",
            borderTop: "1px solid #e8e8e8",
            padding: "10px 16px",
            textAlign: "left",
            left: 0,
            background: "#fff",
            borderRadius: "0 0 4px 4px",
            zIndex: 1,
          }}
        > 
          <Button style={{ marginRight: 8 }} type="primary" onClick={this.handleBusinessOk}>确定</Button>
          <Button onClick={this.handleBusinessCancel}>取消</Button>
        </div>
      </Drawer>
    );
  }
}
