import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { EditViewSingle } from "components/EditView";
import { Row, Col, Form, Select, Input, Cascader, DatePicker } from "antd";
import internModel from "store/reducers/internModel";
import { commonData } from "services/commonService";
import _ from "underscore";
import moment from "moment";
import { provincialCapitalShow } from "./interfaceDataConvert";
import MultiCascade from "components/MultiCascade";
import { disabledEndDate, disabledStartDate } from "utils/formVerify";
import { MonthPickerSofar } from "components/DatePickerSofarWrap";
import InputNumberUnit from "components/InputNumberUnit";
import updateCompletewrapper from "./update-complete-wrapper";

const FormItem = Form.Item;
const { TextArea } = Input;
const Option = Select.Option;
let { RangePicker, MonthPicker } = DatePicker;

function mapPropsToFields(props) {
  const { jobStatus = {} } = props;
  const {
    position,
    position_p,
    begin_time,
    end_time,
    position_name,
    graduation_time,
    forward_city = []
  } = jobStatus;
  const formFields = _.mapObject(jobStatus, function(val, key) {
    if (["available"].indexOf(key) !== -1) {
      return Form.createFormField({
        value: jobStatus[`${key}_label`]
          ? { key: val, label: jobStatus[`${key}_label`] }
          : undefined
      });
    }
    if (key == "graduation_time") {
      return Form.createFormField({
        value: val ? moment(val, "YYYY/MM") : undefined
      });
    }
    return Form.createFormField({
      value: val && val
    });
  });
  formFields.forward_city = Form.createFormField({
    value: forward_city.map(item => ({
      province: item.province_id,
      province_label: item.province_label,
      city: item.city_id,
      city_label: item.city_label,
      region: item.region_id,
      region_label: item.region_label
    }))
  });
  formFields.position = Form.createFormField({
    value: position ? [position_p, position] : undefined
  });
  formFields.begin_time = Form.createFormField({
    value: begin_time
      ? /\d{4}\/\d{2}/.test(begin_time)
        ? moment(begin_time, "YYYY/MM")
        : begin_time
      : undefined
  });
  formFields.end_time = Form.createFormField({
    value: end_time
      ? /\d{4}\/\d{2}/.test(end_time)
        ? moment(end_time, "YYYY/MM")
        : end_time
      : undefined
  });
  return formFields;
}

export function fieldsToProps(values, positionOptions) {
  const {
    position,
    practiceTime,
    graduation_time,
    forward_city,
    begin_time,
    end_time
  } = values;
  values.position_p = position && position[0];
  values.position = position && position[1];
  //从选择中查找position_label和position_p_label
  if (position) {
    positionOptions.map(item => {
      if (item.value == values.position_p) {
        values.position_p_label = item.label;
        item.list.map(childItem => {
          if (childItem.value == values.position) {
            values.position_label = childItem.label;
          }
        });
      }
    });
  }

  if (begin_time) {
    values.begin_time =
      typeof begin_time !== "string"
        ? begin_time.format("YYYY/MM")
        : begin_time;
  }

  if (end_time) {
    values.end_time =
      typeof end_time !== "string" ? end_time.format("YYYY/MM") : end_time;
  }

  values.graduation_time = graduation_time && graduation_time.format("YYYY/MM");

  for (const key of ["available"]) {
    const value = values[key] || {};
    values[key] = value.key;
    values[`${key}_label`] = value.label;
  }
  values.forward_city = forward_city.map(item => ({
    province_id: item.province,
    province_label: item.province_label,
    city_id: item.city,
    city_label: item.city_label,
    region_id: item.region,
    region_label: item.region_label
  }));

  return values;
}

export function createJobStatusEditForm(mapPropsToFields, fieldsToProps) {
  return Form.create({ mapPropsToFields })(
    class EditForm extends PureComponent {
      state = {
        positionOptions: [],
        cityOptions: [],
        availableOptions: [],
        beginTime: undefined,
        endTime: undefined
      };

      componentDidMount() {
        commonData("dispatch_position,cities,profile_available").then(
          options => {
            this.setState({
              cityOptions: options.cities || [],
              positionOptions: options.dispatch_position || [],
              availableOptions: options.profile_available || []
            });
          }
        );
      }

      onDateChange = (field, date) => {
        this.setState({ [field]: date });
      };

      handleSubmit = () => {
        const { positionOptions } = this.state;
        return new Promise((resolve, reject) => {
          this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
              resolve(fieldsToProps(values, positionOptions));
            } else {
              reject(err);
            }
          });
        });
      };

      render() {
        const formItemLayout = {
          labelCol: { span: 5 },
          wrapperCol: { span: 19 }
        };

        const {
          positionOptions,
          cityOptions,
          availableOptions,
          beginTime,
          endTime
        } = this.state;
        const { getFieldDecorator } = this.props.form;
        return (
          <div className="job-status-edit">
            <Form
              onSubmit={this.handleSubmit}
              className="edit-form"
              layout="horizontal"
            >
              <Row className="one" gutter={35}>
                <Col span={12}>
                  <FormItem label="期望职位" {...formItemLayout}>
                    {getFieldDecorator("position_name", {
                      rules: [
                        {
                          required: true,
                          message: "请输入期望职位，且最大60个字符",
                          max: 60
                        }
                      ]
                    })(<Input placeholder="请输入期望职位" size="large" />)}
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem
                    label="职能"
                    {...formItemLayout}
                  >
                    {getFieldDecorator("position", {
                      rules: [{ required: true, message: "请选择职能" }]
                    })(
                      <Cascader
                        options={positionOptions}
                        fieldNames={{ children: "list" }}
                        placeholder="请选择职能"
                        size="large"
                      />
                    )}
                  </FormItem>
                </Col>
              </Row>
              <Row className="one" gutter={35}>
                <Col span={12}>
                  <FormItem label="期望城市" {...formItemLayout}>
                    {getFieldDecorator("forward_city", {
                      rules: []
                    })(
                      <MultiCascade
                        options={cityOptions}
                        fieldNames={{
                          label: "name",
                          value: "id",
                          children: "list"
                        }}
                        showSearch={true}
                        notFoundContent="没有选项"
                        placeholder="请选择期望城市"
                        size="large"
                      />
                    )}
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem label="期望日薪" {...formItemLayout}>
                    {getFieldDecorator("daily_salary_expect", {
                      rules: []
                    })(
                      <InputNumberUnit
                        style={{ width: "100%", fontSize: "14px", paddingLeft: "9px" }}
                        unit="每日"
                        min={0}
                        size="large"
                        placeholder="请输入期望日薪"
                      />
                    )}
                  </FormItem>
                </Col>
              </Row>
              <Row className="one" gutter={35}>
                <Col span={12} style={{ height: 60 }}>
                  <FormItem label="实习天数" {...formItemLayout}>
                    {getFieldDecorator("weekly_working", {
                      rules: []
                    })(
                      <InputNumberUnit
                        style={{ width: "100%", fontSize: "14px", paddingLeft: "9px" }}
                        unit="每周"
                        min={0}
                        max={7}
                        size="large"
                        placeholder="请输入实习天数"
                      />
                    )}
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem label="到岗时间" {...formItemLayout}>
                    {getFieldDecorator("available", {
                      rules: []
                    })(
                      <Select
                        placeholder="请选择到岗时间"
                        size="large"
                        labelInValue
                      >
                        {availableOptions.map(item => (
                          <Option value={item.value} key={item.value}>
                            {item.label}
                          </Option>
                        ))}
                      </Select>
                    )}
                  </FormItem>
                </Col>
              </Row>
              <Row className="one" gutter={35}>
                <Col span={12}>
                  <FormItem label="毕业时间" {...formItemLayout}>
                    {getFieldDecorator("graduation_time", {
                      rules: [
                        {
                          required: true,
                          message: "请选择毕业时间"
                        }
                      ]
                    })(
                      <MonthPicker
                        size="large"
                        format="YYYY/MM"
                        style={{ width: "100%" }}
                      />
                    )}
                  </FormItem>
                </Col>
                <Col span={12}>
                  <div className="form-item-period clear">
                    <FormItem
                      label="实习时间"
                      labelCol={{ span: 8 }}
                      wrapperCol={{ span: 15 }}
                    >
                      {getFieldDecorator("begin_time", {
                        rules: []
                      })(
                        <MonthPickerSofar
                          size="large"
                          format="YYYY/MM"
                          disabledDate={disabledStartDate.bind(null, endTime)}
                          onChange={this.onDateChange.bind(this, "beginTime")}
                        />
                      )}
                    </FormItem>
                    &nbsp;&nbsp;~&nbsp;&nbsp;
                    <FormItem>
                      {getFieldDecorator("end_time", {
                        rules: []
                      })(
                        <MonthPickerSofar
                          size="large"
                          format="YYYY/MM"
                          placeholder="请选择日期"
                          disabledDate={disabledEndDate.bind(null, beginTime)}
                          onChange={this.onDateChange.bind(this, "endTime")}
                        />
                      )}
                    </FormItem>
                  </div>
                </Col>
              </Row>
            </Form>
            <style jsx>
              {`
                .job-status-edit {
                  :global(.four),
                  :global(.five) {
                    :global(.ant-form-item-label) {
                      width: 10%;
                    }
                    :global(.ant-form-item-control-wrapper) {
                      width: 90%;
                      :global(.ant-input) {
                        height: 100px;
                        resize: none;
                      }
                    }
                  }

                  :global(.ant-form-explain) {
                    position: absolute;
                    bottom: -15px;
                  }
                }
              `}
            </style>
            <style jsx global>{`
              .form-item-period {
                .ant-form-item {
                  display: inline-block;
                }
                .ant-form-item:first-child {
                  width: 58%;
                  .ant-form-item-label {
                    width: 35%;
                  }
                }
                .ant-form-item:last-child {
                  width: 34%;
                }
              }
            `}</style>
          </div>
        );
      }
    }
  );
}

export const JobStatusEditForm = createJobStatusEditForm(
  mapPropsToFields,
  fieldsToProps
);

export function JobStatusView(props) {
  const { jobStatus } = props;
  const { forward_city = [] } = jobStatus;
  const handledForwardCity = forward_city.map(item =>
    provincialCapitalShow(item)
  );
  return (
    <React.Fragment>
      <div className="expect">
        <h4>期望状态</h4>
        <Row className="row1">
          <Col span={12} className="col">
            <label>期望职位 : </label>
            <span
              className="ellipsis"
              style={{
                maxWidth: 300,
                display: "inline-block",
                verticalAlign: "bottom"
              }}
              title={jobStatus.position_name}
            >
              {jobStatus.position_name}
            </span>
          </Col>
          <Col span={12} className="col">
            <label>职能 : </label>
            <span>{`${jobStatus.position_p_label}/${
              jobStatus.position_label
            }`}</span>
          </Col>
          <Col span={12} className="col">
            <label>期望城市 : </label>
            <span>
              {handledForwardCity
                .map(
                  item =>
                    `${item.province_label}${item.city_label}${
                      item.region_label
                    }`
                )
                .join("，")}
            </span>
          </Col>
          <Col span={12} className="col">
            <label>期望日薪 : </label>
            <span>{jobStatus.daily_salary_expect}</span>
          </Col>
          <Col span={12} className="col">
            <label>实习天数/周 : </label>
            <span>{jobStatus.weekly_working}天</span>
          </Col>
          <Col span={12} className="col">
            <label>到岗时间 : </label>
            <span>{jobStatus.available_label}</span>
          </Col>
          <Col span={12} className="col">
            <label>毕业时间 : </label>
            <span>{jobStatus.graduation_time}</span>
          </Col>
          <Col span={12} className="col">
            <label>实习时间 : </label>
            <span>
              {jobStatus.begin_time}
              {jobStatus.end_time ? `至${jobStatus.end_time}` : ""}
            </span>
          </Col>
        </Row>
      </div>
      <style jsx>
        {`
          .status {
            margin-bottom: 30px;
          }
          .status,
          .expect {
            h4 {
              font-size: 16px;
              font-weight: 600;
              margin-bottom: 18px;
            }
            :global(.col) {
              color: #666;
              margin-bottom: 18px;
            }
          }
          :global(.ant-collapse-content > .ant-collapse-content-box) {
            padding-bottom: 0;
          }
        `}
      </style>
    </React.Fragment>
  );
}

class JobStatus extends PureComponent {
  onEdit = () => {
    this.props.updateJobStatus({ isEdit: true });
  };

  onCancel = () => {
    this.props.updateJobStatus({ isEdit: false });
  };

  onSave = () => {
    const { editJobStatus } = this.props;
    this.formRef.handleSubmit().then(values => {
      editJobStatus(values)
        .then(res => (!res.code ? res.data.compete : null))
        .then(this.props.toUpdateComplete);
    });
  };

  render() {
    const { jobStatus } = this.props;
    return (
      <div className="job-status entity">
        <EditViewSingle
          title="求职状态"
          isView={!jobStatus.isEdit}
          onEdit={this.onEdit}
          onCancel={this.onCancel}
          onSave={this.onSave}
        >
          <div role="view" className="view">
            <JobStatusView jobStatus={jobStatus} />
          </div>
          <div role="edit" className="edit">
            <JobStatusEditForm
              jobStatus={jobStatus}
              wrappedComponentRef={inst => (this.formRef = inst)}
            />
          </div>
        </EditViewSingle>
      </div>
    );
  }
}

export default connect(
  ({ intern }) => {
    return {
      jobStatus: intern.candidate.detail.jobStatus || {}
    };
  },
  { ...internModel.actions }
)(updateCompletewrapper(JobStatus));
