import xhr from './index.js'

// 列表
export function messageList(param) {
  return xhr({
    url: 'admin/v1/user/my-message',
    body: param
  })
}

// 已读
export function messageRead(param) {
  return xhr({
    url: 'admin/v1/user/read-message',
    body: param
  })
}
// 消息用户反馈列表
export function messageLog(param) {
  return xhr({
    url: 'admin/v1/user/suggestion-list',
    body: param
  })
}
// 新增消息反馈
export function messageAddLog(param) {
  return xhr({
    url: 'admin/v1/user/suggestion',
    body: param,
    method: "POST"
  })
}
