import Model from "../Model";
import * as bannerManageS from "services/bannerManageS";
import * as commonService from "services/commonService";
export default Model.getInstance(
  class extends Model {
    namespace = "BannerManageModal";
    state = {
      visibleBanner: false,
      visibleArea: false,
      data: {
        loadingBannner: false //banner加载状态
      },
      dataArea: {
        loadingArea: false //地区加载状态
      },
      setBannerData: {},
      getDetailBannerData: {},
      bannerTitle: {},
      areaTitle: {},
      getDetailAreaData: {},
      onlineData: {},
      rollData: {},
      commonData:{},//静态选项
    };

    actions = {
      async getCommonData(dataName) {
        let result = await commonService.commonData(dataName);
        this.dispatch({
          type: "BannerManageModal/setUpdateState",
          payload: {
            filed: "commonData",
            value: { ... result }
          }
        });
      },
      async getList(param = {}) {
        this.dispatch({
          type: "BannerManageModal/setUpdateState",
          payload: { filed: "data", value: { loadingBannner: true } }
        });
        const expand = "scene_name,status_name";
        let result = await bannerManageS.bannerManageList({ ...param, expand });
        if (result) {
          this.dispatch({
            type: "BannerManageModal/setUpdateState",
            payload: {
              filed: "data",
              value: { ...result.data, loadingBannner: false }
            }
          });
        }
      },
      async bannerAdd(param = {}) {
        let result = await bannerManageS.bannerManageAdd({ ...param });
        if (result.code === 0) {
          this.dispatch({
            type: "BannerManageModal/drawerOpen",
            payload: { type: "添加", page: "banner" }
          });
        }
        return result;
      },
      async bannerDetail(param) {
        let result = await bannerManageS.bannerManageDetail(param);
        if (result) {
          this.dispatch({
            type: "BannerManageModal/setbannerDetailState",
            payload: result.data
          });
        }
        return result;
      },
      async bannerDelete(id) {
        let result = await bannerManageS.bannerManageDelete(id);
        return result;
      },
      async bannerEdit(param) {
        let result = await bannerManageS.bannerManageEdit({ ...param });
        if (result.code === 0) {
          this.dispatch({
            type: "BannerManageModal/drawerOpen",
            payload: { type: "编辑", page: "banner" }
          });
        }
        return result;
      },
      //地区轮播start
      async getAreaList(param = {}) {
        this.dispatch({
          type: "BannerManageModal/setUpdateState",
          payload: { filed: "dataArea", value: { loadingArea: true } }
        });
        const expand = "city_name,profile_info";
        let result = await bannerManageS.areaManageList({ ...param, expand });
        if (result) {
          this.dispatch({
            type: "BannerManageModal/setUpdateState",
            payload: {
              filed: "dataArea",
              value: { ...result.data, loadingArea: false }
            }
          });
        }
      },
      async areaAdd(param = {}) {
        let result = await bannerManageS.areaManageAdd({ ...param });
        if (result.code === 0) {
          this.dispatch({
            type: "BannerManageModal/drawerOpen",
            payload: { type: "添加", page: "area" }
          });
        }
        return result;
      },
      async areaEdit(param) {
        let result = await bannerManageS.areaManageEdit({ ...param });
        if (result.code === 0) {
          this.dispatch({
            type: "BannerManageModal/drawerOpen",
            payload: { type: "编辑", page: "reae" }
          });
        }
        return result;
      },
      async areaDelete(id) {
        let result = await bannerManageS.areaManageDelete(id);
        return result;
      },
      async getCity() {
        let result = await bannerManageS.getCityData("index_content_cities");
        if (result) {
          this.dispatch({
            type: "BannerManageModal/setUpdateState",
            payload: { filed: "cityData", value: { ...result.data } }
          });
        }
      },
      async getAreaDetail(id) {
        const expand = "profile_num,province_id";
        let result = await bannerManageS.areaManageDetail({ id, expand });
        if (result) {
          this.dispatch({
            type: "BannerManageModal/setUpdateState",
            payload: { filed: "getDetailAreaData", value: { ...result.data } }
          });
        }
        return result;
      },
      async getOnlineData(param) {
        let result = await bannerManageS.getOnline(param);
        if (result) {
          this.dispatch({
            type: "BannerManageModal/setUpdateState",
            payload: {
              filed: "onlineData",
              value: { onlineNumber: result.data }
            }
          });
        }
      },
      async getRollData(param) {
        let result = await bannerManageS.getRoll(param);
        return result;
      }
    };

    reducers = {
      drawerOpen(state, { payload: type }) {
        if (type.page === "banner") {
          state.visibleBanner = !state.visibleBanner;
          state.getDetailBannerData = {};
          state.setBannerData = {};
        } else {
          state.visibleArea = !state.visibleArea;
          state.getDetailAreaData = {};
          state.onlineData = {};
        }
        return { ...state };
      },
      setUpdateState(state, { payload }) {
        const { filed, value } = payload;
        state[filed] = value;
        return { ...state };
      },
      setbannerDetailState(state, { payload: data }) {
        state.getDetailBannerData = data;
        state.setBannerData.img = data.img;
        if (data.type === 4) {
          state.getDetailBannerData.longImg = data.url;
          state.setBannerData.longImg = data.url;
          state.getDetailBannerData.url = "";
        }
        return { ...state };
      }
    };
  }
);
