import React from "react";
import ReactDOM from "react-dom";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  Row,
  Col,
  Button,
  Icon,
  Tabs,
  Collapse,
  Tag,
  Select,
  Input,
  Modal,
  DatePicker,
  Form,
  Drawer,
  Tooltip
} from "antd";
import { Provider } from "react-redux";
import {childRoot} from "../../root.js"
import Table from "components/GeeTable.js";
import PositionDetail from "pages/PositionVacant/detail.js";
import { EditViewSingle } from "components/EditView";
import EditCompanyForm from "./editCompanyForm.js";
import { CommunicateRecords, AddContractForm } from "./companyDetailForm.js";
import Contacter from "./contact.js";
import SetPerson from "./setPerson.js";
import global from "store/reducers/global";
import companyModel from "store/reducers/companyModel";
import PositionVacantModal from "store/reducers/positionVacantM";
import CustomIcon from "components/CustomIcon";
import styles from "./companyWrapper.scss";
const TabPane = Tabs.TabPane;
const Panel = Collapse.Panel;
const Option = Select.Option;
const TextArea = Input.TextArea;
const FormItem = Form.Item;
const { RangePicker } = DatePicker;

function createMarkup(text = "") {
  if (!text) text = "";
  return { __html: text.replace(/\n|\r|\r\n/g, "<br/>") };
}

const getCityName = (cities, sel, count = 0) => {
  let result = [];
  console.log("res", cities, sel, count);
  cities.map(item => {
    if (sel[count] == item.id) {
      if (item.list) {
        result = getCityName(item.list, sel, count + 1);
        console.log("res", result);
      }
      result.unshift(item.name);
    }
  });
  return result;
};

const contractColumn = [
  {
    title: "合同编号",
    dataIndex: "contract_no",
    render: (value, item, index) => {
      return <div>
        <sapn title={value} className="ellipsis" style={{maxWidth: "82px", display: "inline-block", verticalAlign: "top"}}>{value}</sapn>
        <Tooltip title={item.is_new === 1 ? "新增合同" : "续签合同"}>
          <CustomIcon style={{fontSize: "16px", color: "#999", marginLeft: "5px"}} type={item.is_new === 1 ? "icon-xinzeng" : "icon-xuqian"}/>
        </Tooltip>
      </div>
    }
  },
  {
    title: "签署日期",
    dataIndex: "sign_date"
  },
  {
    title: "合同类型",
    dataIndex: "type_name"
  },
  {
    title: "生效日期",
    dataIndex: "start_time"
  },
  {
    title: "负责商务",
    dataIndex: "bd_name"
  },
  {
    title: "备注",
    dataIndex: "remark",
    needEllipsis: true
  }
];

@connect(
  ({ company, user }) => {
    return { ...company, user };
  },
  {
    ...companyModel.actions,
    openPosDrawer: PositionVacantModal.actions.openPosDrawer,
    updateStack: global.actions.updateDrawerStack
  }
)
export default class CompanyDetail extends React.PureComponent {
  state = {
    baseInfoView: true,
    addContractModalVisible: false,
    setPersonVisible: false,
    jdPagination: {
      current: 1,
      pageSize: 10
    },
    companyDetailType: null
  };

  posColumn = [
    {
      title: "职位名称",
      dataIndex: "position_name",
      render: (text, record) => (
        <div>
          <div
            style={{
              fontWeight: 600,
              color: "#333",
              fontSize: 16,
              marginBottom: 15
            }}
          >
            <span
              onClick={() => {
                const { updateStack, user } = this.props;
                // this.props.openPosDrawer(record.id, true);
                if (user.roles === "business") return;
                updateStack({
                  type: "add",
                  componentRef: PositionDetail,
                  componentProps: { onClose: () => updateStack({ type: "pop" }), stackVisible: true, id: record.id },
                })
              }}
              title={record.post_name}
              style={{
                display: "inline-block",
                maxWidth: 120,
                verticalAlign: "bottom",
                cursor: "pointer" 
              }}
              className="ellipsis"
            >
              {record.post_name}
              {record.post_name && (record.require_num || record.require_num === 0)
                ? "/"
                : ""}
              {record.require_num}人
            </span>
          </div>
          <div style={{ color: "#999" }}>
            {record.service_type_name}
            {record.require_num}人
          </div>
        </div>
      )
    },
    {
      title: "招聘状态",
      dataIndex: "status_name",
      render: (text, record) => (
        <div>
          <div style={{ color: "#666", marginBottom: 15 }}>
            {text}(已进行{record.progress_days}天)
          </div>
          <div style={{ color: "#999" }}>已进行{record.progress_days}天</div>
        </div>
      )
    },
    {
      title: "负责人",
      dataIndex: "hh_name",
      render: (text, record) => <Tooltip title={record.hh_org_name}>{text}</Tooltip>
    }
  ];

  componentDidMount() {
    const {companyId, getCompanyDetail} = this.props;
    getCompanyDetail({ id: companyId });
  }
  
  componentDidUpdate(prevProps) {
    const {
      companyId, 
      getCompanyDetail,
      stackVisible,
      getJDList,
      getChatList,
      getContactList
    } = this.props;
    console.log("update", prevProps, this.props)
    // const { is } = require("immutable");
    if (prevProps.stackVisible != this.props.stackVisible && stackVisible) {
      getCompanyDetail({ id: companyId });
      getJDList({
        company_id: companyId,
        "per-page": 10
      }).then(res => {
        if (res.code == 0) {
          this.setState({
            jdPagination: {
              ...this.state.jdPagination,
              total: res.data._meta && res.data._meta.totalCount
            }
          });
        }
      });
      getChatList({
        company_id: companyId,
        "per-page": 6
      });
      getContactList({
        expand: "sex_name",
        company_id: companyId,
        "per-page": 10
      });
    }
  }

  // tab切换
  companyDetailChange = activeKey => {
    const {
      getJDList,
      getChatList,
      getContactList,
      companyDetail
    } = this.props;
    if (activeKey == "2") {
      getJDList({
        company_id: companyDetail.id,
        "per-page": 10
      }).then(res => {
        if (res.code == 0) {
          this.setState({
            jdPagination: {
              ...this.state.jdPagination,
              total: res.data._meta && res.data._meta.totalCount
            }
          });
        }
      });
    } else if (activeKey == "3") {
      getChatList({
        company_id: companyDetail.id,
        "per-page": 6
      }).then(res => {
        if (res.code == 0) {
          // this.setState({
          //   jdPagination: {...this.state.jdPagination, total: res.data._meta && res.data._meta.totalCount}
          // })
        }
      });
    } else if (activeKey == "4") {
      getContactList({
        expand: "sex_name",
        company_id: companyDetail.id,
        "per-page": 10
      });
    }
  };

  // 公司基本信息编辑
  onBaseInfoEdit = () => {
    this.setState({ baseInfoView: false });
  };

  onBaseInfoCancel = () => {
    const form = this.companyEditForm.props.form;
    form.resetFields();
    this.setState({ baseInfoView: true });
  };

  onBaseInfoSave = () => {
    const form = this.companyEditForm.props.form;
    const {
      modCompany,
      companyDetail,
      commonData,
      companyListPagination,
      companyListCondition,
      getCompanyList
    } = this.props;
    form.validateFields((err, values) => {
      if (err) {
        return;
      }
      values.cityName = getCityName(commonData.cities, values["city"]);
      values.company_type =
        values.company_type_labelValue && values.company_type_labelValue.key;
      values.industry =
        values.industry_labelValue && values.industry_labelValue.key;
      values.scale = values.scale_labelValue && values.scale_labelValue.key;
      values.province_id =
        values["city"] && values["city"][0] ? values["city"][0] : "";
      values.city_id =
        values["city"] && values["city"][1] ? values["city"][1] : "";
      values.region_id =
        values["city"] && values["city"][2] ? values["city"][2] : "";
      values.id = companyDetail.id;
      console.log("Received values of form: ", values);
      modCompany(values).then(res => {
        if (res.code == 0) {
          form.resetFields();
          this.setState({ baseInfoView: true });
          getCompanyList(
            {
              "per-page": companyListPagination.pageSize,
              page: companyListPagination.current,
              ...companyListCondition
            },
            companyListCondition
          );
        }
      });
    });
  };

  // drawer关闭
  onClose = () => {
    this.props.updateCompanyVisible(false);
    if (this.props.onClose) {
      this.props.onClose();
    }
  };

  // 添加合同
  onAddContract = e => {
    e.stopPropagation();
    this.setState({ addContractModalVisible: true });
  };

  handleContractOk = () => {
    const form = this.contractForm.props.form;
    const { addContract, companyDetail, commonData } = this.props;
    form.validateFields((err, values) => {
      if (err) {
        return;
      }

      values.company_id = companyDetail.id;
      values.bd_ids = values.bd_ids.filter(item => item);
      values.bd_id = values.bd_ids[values.bd_ids.length - 1];
      values.sign_date = values.sign_date_m.format("YYYY-MM-DD");
      values.start_time = values.rangeTime[0].format("YYYY-MM-DD");
      values.end_time = values.rangeTime[1].format("YYYY-MM-DD");
      console.log("Received values of form: ", values);
      addContract(values).then(res => {
        if (res.code == 0) {
          this.setState({ addContractModalVisible: false });
          form.resetFields();
          const { getCompanyDetail } = this.props;
          getCompanyDetail({ id: companyDetail.id }, true);
        }
      });
    });
  };

  handleContractCancel = () => {
    this.setState({ addContractModalVisible: false });
  };

  handleContractChange = (pagination, filters, sorter) => {
    const { getContractList, companyDetail, contractPagination } = this.props;
    contractPagination.current = pagination.current;
    contractPagination.pageSize = pagination.pageSize;
    getContractList({
      company_id: companyDetail.id,
      "per-page": pagination.pageSize,
      expand: "type_name,bd_name",
      page: pagination.current
    });
  };

  // 职位切换
  handleJDChange = (pagination, filters, sorter) => {
    const { getJDList, companyDetail } = this.props;
    const pager = { ...this.state.jdPagination };
    pager.current = pagination.current;
    pager.pageSize = pagination.pageSize;
    this.setState({
      jdPagination: pager
    });
    getJDList({
      company_id: companyDetail.id,
      "per-page": pagination.pageSize,
      page: pagination.current
    }).then(res => {
      if (res.code == 0) {
        this.setState({
          jdPagination: {
            ...this.state.jdPagination,
            total: res.data._meta && res.data._meta.totalCount
          }
        });
      }
    });
  };

  changeType = (value, option) => {
    const that = this;
    const {
      changeCompanyType,
      companyDetail,
      updateCompanyType,
      companyListPagination,
      companyListCondition,
      getCompanyList
    } = this.props;

    Modal.confirm({
      title: "提示",
      content: `确认将该客户标记成${option.props.children}？`,
      zIndex: 1001,
      okText: "确定",
      cancelText: "取消",
      onOk() {
        changeCompanyType({
          company_id: companyDetail.id,
          type: value
        }).then(res => {
          if (res.code == 0) {
            getCompanyList(
              {
                "per-page": companyListPagination.pageSize,
                page: companyListPagination.current,
                ...companyListCondition
              },
              companyListCondition
            );
            updateCompanyType(value);
          }
        });
      },
      onCancel() {}
    });
  };

  companyEditFormRef = formRef => {
    this.companyEditForm = formRef;
  };

  contractFormRef = formRef => {
    this.contractForm = formRef;
  };

  handleModifyOwnerOk = () => {
    this.setState({ setPersonVisible: true });
  };

  handleClosePerson = () => {
    this.setState({ setPersonVisible: false });
  };

  render() {
    const {
      commonData,
      companyVisible,
      contractPagination,
      getChatList,
      currentType,
      companyDetail,
      user,
      contract,
      jd,
      contact,
      getContactList,
      record,
      addChat,
      modContact
    } = this.props;
    let names = [];
    if (companyDetail.bd_name) names.push(<Tooltip title={companyDetail.bd_org_name}>{companyDetail.bd_name}</Tooltip>);
    if (companyDetail.hh_name) names.push(<Tooltip title={companyDetail.hh_org_name}>{companyDetail.hh_name}</Tooltip>);
    const titleLayout = (
      <div className="drawer-title clear company-detail-title">
        <span className="item ellipsis" style={{ maxWidth: "160px"}} title={companyDetail.source_name}>来源 : {companyDetail.source_name}</span>
        {/* <span className="item" style={{ marginLeft: 20 }}>客户类型 : {companyDetail.origin_type_name}</span> */}
        <span className="item ellipsis" style={{ marginLeft: 20, maxWidth: "270px"}}>
          负责人 : 
          {names.length == 1 && names}
          {names.length == 2 && names[0]}
          {names.length == 2 && " & "}
          {names.length == 2 && names[1]}
          
        </span>
        {currentType == "2" && (
            <Icon
              type="edit"
              style={{ marginLeft: 5}}
              onClick={this.handleModifyOwnerOk}
            />
          )}
        {/* <Tooltip title={companyDetail.add_by_org_name}>
          <span style={{marginLeft: "10px", marginRight: "5px", maxWidth: "130px"}} className="right ellipsis">{companyDetail.add_by_name}</span>
        </Tooltip>
        <span className="right">创建时间：{companyDetail.add_time_str}</span> */}
      </div>
    );
    const AddContract = Form.create({})(AddContractForm);
    const EditCompany = Form.create({})(EditCompanyForm);
    let areaNames = [];
    if (companyDetail.company_address) {
      areaNames.push(companyDetail.company_address.province_name);
      areaNames.push(companyDetail.company_address.city_name);
      areaNames.push(companyDetail.company_address.region_name);
    }
    areaNames = areaNames.filter(item => !!item);
    return (
      <React.Fragment>
        <Drawer
          className="info-drawer-box company-detail-drawer drawer-modal"
          title={titleLayout}
          placement="right"
          closable={true}
          width={900}
          onClose={this.onClose}
          visible={true}
        >
          <div className="main">
            <div className="second-header">
              <h2>
                {companyDetail.name}{" "}
                <span style={{ marginLeft: "16px", marginRight: "10px" }}>
                  No.{companyDetail.id}
                </span>
                {companyDetail.type == 3 && (
                  <Tooltip title="待归档">
                    <Icon
                      component={() => (
                        <i className="detail-icon icon-business" />
                      )}
                      theme="outlined"
                    />
                  </Tooltip>
                )}
               {companyDetail.type == 4 && (
                  <Tooltip title="合同">
                    <Icon
                      component={() => (
                        <i className="detail-icon icon-rebusiness" />
                      )}
                      theme="outlined"
                    />
                  </Tooltip>
                )}
                {companyDetail.type == 5 && (
                  <Tooltip title="合同取消"><CustomIcon type="icon-quxiao"/></Tooltip>
                )}
              </h2>
              <div className="tags">
                <span>
                  {companyDetail.company_address.province_name &&
                    `#${companyDetail.company_address.province_name}`}
                </span>
                &nbsp;
                <span style={{ marginLeft: "10px", marginRight: "10px" }}>
                  {companyDetail.industry_name &&
                    `#${companyDetail.industry_name}`}
                </span>
                &nbsp;
                <span>
                  {companyDetail.scale_name && `#${companyDetail.scale_name}`}
                </span>
              </div>
              <div>
                <Tag color="blue">
                  进行中的职位(
                  {companyDetail.jd_processing_num
                    ? companyDetail.jd_processing_num
                    : 0}
                  )
                </Tag>
                <Tag color="blue">
                  {companyDetail.origin_type_name}
                </Tag>
              </div>
              <Select style={{width: "150px"}}
                value={companyDetail.type + ""}
                onChange={this.changeType} >
                <Option value="3">待归档客户</Option>
                <Option value="4">合同客户</Option>
                <Option value="5">合同取消</Option>
              </Select>
            </div>
            <Tabs
              className="company-detail-tabs"
              type="card"
              defaultActiveKey="1"
              onChange={this.companyDetailChange}
            >
              <TabPane tab="详细信息" key="1">
                <EditViewSingle
                  title={
                    <div>
                      基本信息
                      <span className="now-status">
                        {companyDetail.status_name}
                      </span>
                    </div>
                  }
                  className="company-base-info"
                  isView={this.state.baseInfoView}
                  onEdit={this.onBaseInfoEdit}
                  onCancel={this.onBaseInfoCancel}
                  onSave={this.onBaseInfoSave}
                >
                  <div role="view" className="view">
                    <Row>
                      <Col span={14}>
                        <Col
                          span={4}
                          style={{ width: "65px", marginRight: "20px" }}
                        >
                          公司logo
                        </Col>
                        <Col span={19}>
                          <img className="logo" src={companyDetail.logo ? companyDetail.logo : require("assets/imgs/default-company.png")} alt="" />
                        </Col>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={14}>
                        <Col
                          span={4}
                          style={{ width: "65px", marginRight: "20px" }}
                        >
                          公司名称 :{" "}
                        </Col>
                        <Col span={19}>
                          <span>{companyDetail.name}</span>
                        </Col>
                      </Col>
                      <Col span={10}>
                        <Col
                          className="justify-content"
                          span={6}
                          style={{
                          }}
                        >
                          公司简称 :{" "}
                        </Col>
                        <Col span={17}>
                          <span>{companyDetail.other_name}</span>
                        </Col>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={14}>
                        <Col
                          span={4}
                          style={{ width: "65px", marginRight: "20px" }}
                        >
                          公司性质 :{" "}
                        </Col>
                        <Col span={19}>
                          <span>{companyDetail.company_type_name}</span>
                        </Col>
                      </Col>
                      <Col span={10}>
                        <Col
                          span={6}
                          style={{ width: "65px", marginRight: "20px" }}
                        >
                          行业类别 :{" "}
                        </Col>
                        <Col span={17}>
                          <span>{companyDetail.industry_name}</span>
                        </Col>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={14}>
                        <Col
                          span={4}
                          style={{ width: "65px", marginRight: "20px" }}
                        >
                          公司电话 :{" "}
                        </Col>
                        <Col span={19}>
                          <span>
                            {companyDetail.company_address.contact_no}
                          </span>
                        </Col>
                      </Col>
                      <Col span={10}>
                        <Col
                          span={6}
                          style={{ width: "65px", marginRight: "20px" }}
                        >
                          公司规模 :{" "}
                        </Col>
                        <Col span={17}>
                          <span>{companyDetail.scale_name}</span>
                        </Col>
                      </Col>
                    </Row>
                    <Row className="now-city-row">
                      <Col
                        span={3}
                        style={{ width: "65px", marginRight: "20px" }}
                      >
                        所属城市 :
                      </Col>
                      <Col span={21}>
                        <span>
                          {areaNames.join("/")}
                          {/* {`${companyDetail.company_address.province_name}${
                            companyDetail.company_address.city_name
                              ? `/${companyDetail.company_address.city_name}${
                                  companyDetail.company_address.region_name
                                    ? "/" +
                                      companyDetail.company_address.region_name
                                    : ""
                                }`
                              : ""
                          }`} */}
                        </span>
                      </Col>
                    </Row>
                    <Row className="address-row">
                      <Col
                        span={3}
                        style={{ width: "65px", marginRight: "20px" }}
                      >
                        详细地址 :
                      </Col>
                      <Col span={21}>
                        <span
                          dangerouslySetInnerHTML={createMarkup(
                            companyDetail.company_address.address
                          )}
                        />
                      </Col>
                    </Row>
                    <Row className="des-row">
                      <Col
                        span={3}
                        style={{ width: "65px", marginRight: "20px" }}
                      >
                        公司描述 :
                      </Col>
                      <Col span={21}>
                        <span
                          dangerouslySetInnerHTML={createMarkup(
                            companyDetail.desc
                          )}
                        />
                      </Col>
                    </Row>
                  </div>
                  <div role="edit" className="edit">
                    <EditCompany
                      wrappedComponentRef={this.companyEditFormRef}
                      companyDetail={companyDetail}
                      commonData={commonData}
                    />
                  </div>
                </EditViewSingle>
                <Collapse
                  defaultActiveKey={["1"]}
                  className="collapse-handle-box"
                >
                  <Panel
                    key="1"
                    header={
                      <React.Fragment>
                        <span>合同</span>
                        {(currentType != "2" || user.roles == "business" || user.roles == "admin") && (
                          <Icon
                            type="plus-square"
                            theme="outlined"
                            className="right"
                            style={{ fontSize: 18 }}
                            onClick={this.onAddContract}
                          />
                        )}
                      </React.Fragment>
                    }
                  >
                    <Table
                      columns={contractColumn}
                      dataSource={contract.list}
                      onChange={this.handleContractChange}
                      pagination={contractPagination}
                    />
                  </Panel>
                </Collapse>
              </TabPane>
              <TabPane tab="招聘职位" key="2">
                <Collapse
                  defaultActiveKey={["1"]}
                  className="collapse-handle-box"
                >
                  <Panel key="1" header="相关职位">
                    <Table
                      columns={this.posColumn}
                      dataSource={jd.list}
                      onChange={this.handleJDChange}
                      pagination={this.state.jdPagination}
                    />
                  </Panel>
                </Collapse>
              </TabPane>
              <TabPane tab="沟通记录" key="3">
                <CommunicateRecords
                  currentType={currentType}
                  record={record}
                  addChat={addChat}
                  getChatList={getChatList}
                  companyDetail={companyDetail}
                />
              </TabPane>
              <TabPane tab="联系人" key="4">
                <Contacter
                  currentType={currentType}
                  contact={contact}
                  getContactList={getContactList}
                  modContact={modContact}
                  companyDetail={companyDetail}
                />
              </TabPane>
            </Tabs>
          </div>
        </Drawer>
        <SetPerson
          visible={this.state.setPersonVisible}
          onClose={this.handleClosePerson}
        />
        <Modal
          width={845}
          zIndex={1001}
          wrapClassName="add-contract-modal"
          maskStyle={{ position: "absolute" }}
          title="添加合同"
          visible={this.state.addContractModalVisible}
          onOk={this.handleContractOk}
          onCancel={this.handleContractCancel}
        >
          <AddContract
            wrappedComponentRef={this.contractFormRef}
            commonData={commonData}
            userData={user}
          />
        </Modal>
        <style jsx>{styles}</style>
        <style jsx global>
          {`
            .company-detail-tabs {
              .collapse-handle-box .ant-collapse-header {
                color: #333333;
                font-size: 16px;
                font-weight: 600;
              }
            }
            .justify-content {
            }
          `}
        </style>
      </React.Fragment>
    );
  }
}
