import xhr from './index.js'

// 列表
export function businessList(param) {
  return xhr({
    url: 'admin/v1/business/list',
    body: param,
    method: "post"
  })
}
// 详情
export function businessDetail(param) {
  return xhr({
    url: 'admin/v1/businesses/' + param.id,
    body: param
  })
}
// 新增
export function insertBusiness(param) {
  return xhr({
    url: 'admin/v1/businesses',
    body: param,
    method: "post"
  })
}
// 编辑
export function modifyCompany(param) {
  return xhr({
    url: 'admin/v1/business/edit-company',
    body: param,
    method: "POST"
  })
}
// 编辑职位信息
export function modifyJD(id, param) {
  return xhr({
    url: 'admin/v1/jds/' + id,
    body: param,
    method: "PUT"
  })
}
// 商机操作
export function operateBusiness(param) {
  return xhr({
    url: 'admin/v1/business/operate',
    body: param,
    method: "POST"
  })
}
// 新增沟通记录
export function insertChat(param) {
  return xhr({
    url: 'admin/v1/company/chat-add',
    body: param,
    method: "post"
  })
}
// 沟通记录列表
export function chatList(param) {
  return xhr({
    url: 'admin/v1/company/chat-list',
    body: param,
  })
}

// 公司列表
export function companyList(param) {
  return xhr({
    url: 'admin/v1/company/get-companies-by-name',
    body: param,
  })
}

// 切换公司类型
export function changeManager(param) {
  return xhr({
    url: 'admin/v1/business/change-manager',
    body: param,
    method: "post"
  })
}

// 报价记录
export function getSendRecord(param) {
  return xhr({
    url: 'admin/v1/business/send-quote-record',
    body: param,
  })
}

// 重新发送
export function reSendOffer(param) {
  return xhr({
    url: 'admin/v1/business/resend-quote',
    body: param,
    method: "post"
  })
}
//导出记录
export function exportRecords(param) {
  return xhr({
    url: "admin/v1/profile-manage/export",
    body: param,
    method: "GET"
  });
}