import { Input } from 'antd';
import React from 'react';
import { useRef } from 'react';
import { useState } from 'react';
import styles from './styles.scss';

const CapchaInput = (props) => {
    const {  placeholder, onChange, onClick, onTimerClear } = props;

    const [second, setSecond] = useState(0);
    const lastSecond = useRef(0)
    const timer = useRef()
    const handleGetAuthCode = () => {
        try {
            onClick().then((res) => {
                lastSecond.current = 60;
                setSecond(lastSecond.current)
                timer.current = setInterval(() => {
                    if (lastSecond.current === 0) {
                        clearInterval(timer.current)
                        onTimerClear()
                    } else {
                        lastSecond.current = lastSecond.current - 1;
                        setSecond(lastSecond.current)
                    }
                }, 1000);
            })
        } catch (error) {
            console.log('error', error);
        }
        
        // GetAuthCode({ phone }).then((res) => {
        //   if (res.code !== 0) {
        //     if (res.code === 402 || res.code === 401) {
        //       if (res.data.phone) {
        //         message.error(res.data.phone);
        //       } else if (res.data.PHONE) {
        //         message.error(res.data.PHONE);
        //       }
        //     }
        //   } else {
        //     setAuthCodeStatus(true);
        //     timer.current = setInterval(() => {
        //       if (latestCount.current === 0) {
        //         clearInterval(timer.current);
        //         setSecond(60);
        //         setAuthCodeStatus(false);
        //         return;
        //       }
        //       setSecond((v) => v - 1);
        //     }, 1000);
        //   }
        // });
    };

    return (
        <div>
            <style jsx>{styles}</style>
            <div className={'sms-input'}>
                <Input size='large' placeholder={placeholder} onChange={onChange}></Input>
                <span className="get-code" onClick={handleGetAuthCode}>
                    {Number(second) !== 0 ? `${second}S` : '获取验证码'}
                </span>
            </div>
        </div>
    )
}

export default CapchaInput
