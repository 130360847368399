import Model from "../Model";
import * as organizationManageS from "services/organizationManageS";
import dotProp from "dot-prop-immutable"; 
export default Model.getInstance(
  class extends Model {
    namespace = "OrganizationManageModal";
    state = {
      visibleOrganization: false,
      dataMemberList: {},
      dataOrganizationList: {
        loadingStatus: false //加载状态
      },
      getDetailOrganizationData: {},
      openStatus: {},
      editPasswordInfo: {},
      statusParam: { "per-page": 10 ,status:1}
    };

    actions = {
      async getMemberList() {
        this.dispatch({
          type: "OrganizationManageModal/setUpdateState",
          payload: { filed: "dataMemberList", value: { loadingStatus: true } }
        });
        let result = await organizationManageS.organizationMemberManageList({
          ...this.getState().OrganizationManageModal.statusParam
        });
        if (result) {
          this.dispatch({
            type: "OrganizationManageModal/setUpdateState",
            payload: {
              filed: "dataMemberList",
              value: { ...result.data, loadingStatus: false }
            }
          });
        }
      },
      async getOrganizationList() {
        let result = await organizationManageS.organizationManageList();
        if (result) {
          this.dispatch({
            type: "OrganizationManageModal/setUpdateState",
            payload: {
              filed: "dataOrganizationList",
              value: { ...result.data }
            }
          });
        }
      },
      async organizationAdd(param) {
        let result = await organizationManageS.organizationManageAdd({
          ...param
        });
        if (result.code === 0) {
          this.dispatch({
            type: "OrganizationManageModal/drawerOpen",
            payload: { type: "添加", page: "article" }
          });
        }
        return result;
      },
      async organizationEdit(param) {
        let result = await organizationManageS.organizationManageEdit({
          ...param
        });
        if (result.code === 0) {
          this.dispatch({ type: "OrganizationManageModal/drawerOpen" });
          this.dispatch({ type: "OrganizationManageModal/editList", payload: param });
        }
        return result;
      },
      async getDetail(id) {
        let result = await organizationManageS.organizationManageDetail(id);
        this.dispatch({
          type: "OrganizationManageModal/updateDetail",
          payload: result.data
        });
      },
      async isAllowLogin(param) {
        let result = await organizationManageS.organizationManageLogin(param);
        return result;
      },
      async restPassword(param) {
        let result = await organizationManageS.organizationManagePassword(
          param
        );
        return result;
      }
    };

    reducers = {
      updateDetail(state, { payload: data }) {
        state.getDetailOrganizationData = data;
        return { ...state };
      },
      editList(state, { payload: data }) {
        const {uid, index} = state.openStatus;
        state.dataMemberList.list[index].name = data.name;
        state.dataMemberList.list[index].phone = data.phone;
        state.dataMemberList.list[index].email = data.email;
        return { ...state };
      },
      drawerOpen(state, { payload: type }) {
        state.openStatus.visible = !state.openStatus.visible;
        state.getDetailOrganizationData = {};
        return { ...state };
      },
      setUpdateState(state, { payload }) {
        const { filed, value } = payload;
        state[filed] = value;
        //tagtype="1"是菜单查询，"2"是搜索查询，是区分两边筛选条件互不影响
        if (value.tagtype === "1") {
          state.statusParam.keyword = undefined;
          state.statusParam.role = undefined;
          state.statusParam.tagtype = undefined;
        }
        if (value.tagtype === "2") {
          state.statusParam.tagtype = undefined;
          state.statusParam.org_city_id = undefined;
        }
        return { ...state };
      },
      updateState(state, { payload }) {
        const { filed, value } = payload;
        state= dotProp.set(state, `${filed}.list.${value.index}.status`, value.row.status===0?1:0)
        return { ...state };
      }
    };
  }
);
