import Model from "../Model";
import * as businessService from "services/businessService";
import * as commonService from "services/commonService";
import { message } from 'antd';

export default Model.getInstance(
  class extends Model {
    namespace = "business";
    state = {
      commonData: {},
      businessList: [],
      businessDetail: {
        company: {},
        contactor: {},
        jd_info: {},
        jd_profiles: {
          system_recommend: [],
          user_reserve: []
        },
      },
      companyList: {
        list: [],
      },
      record: {},
      sendRecordList: [],
      businessLoading: false,
      exportVisible: false, //导出弹窗
    };

    actions = {
      async getCommonData(dataName) {
        let commonData = await commonService.commonData(dataName);
        this.dispatch({
          type: "business/updateCommon",
          payload: commonData
        });
      },
      async getBusinessList(param) {
        this.dispatch({
          type: "business/updateLoading",
          payload: true    
        });
        // param["per-page"] = 1;
        param.expand = "company,jd_info,source_name,create_time_str,create_by_name,contactor,status_name,assign_to,assign_to_org_name,bd_name,bd_org_name,submit_num,last_contact,gb_business,source_p,communication"
        let result = await businessService.businessList(param);
        if (result.code == 0) {
          this.dispatch({
            type: "business/updateList",
            payload: result.data     
          });
          // // 北伐的时候，在另外存一下
          // if (param.source === 32) {

          // }
        }
        this.dispatch({
          type: "business/updateLoading",
          payload: false
        });
        return result;
      },
      async addBusiness(param) {
        let result = await businessService.insertBusiness(param);
        if (result.code == 0) {
          message.info("创建成功！")
        }
        return result;
      },
      async getBusinessDetail(param) {
        param.expand =
          "company,jd_info,source_name,create_time_str,create_by_name,contactor,status_name,assign_to,assign_to_org_name," + 
          "bd_name,bd_org_name,submit_num,jd_profiles,quote_confirm_time,quote_confirm_user,send_quote_content,quote_files," + 
          "manager_team,bd_org_info,hh_org_info,send_quote_time,source_p";
        let result = await businessService.businessDetail(param);
        if (result.code == 0) {
          this.dispatch({
            type: "business/updateBusinessDetail",
            payload: result.data     
          });
          let chatParam = {
            expand: "create_time_str,create_by_name,create_by_org_name,status_name,invalid_reason_name",
            company_id: result.data.company_id,
            business_id: result.data.id,
            "per-page": 6,
          }
          let chatResult = await businessService.chatList(chatParam);
          if (chatResult.code == 0) {
            this.dispatch({
              type: "business/updateChat",
              payload: chatResult.data
            });
          }
        }
        return result;
      },
      async chatList(param) {
        param.expand = "create_time_str,create_by_name,create_by_org_name,status_name,invalid_reason_name";
        let chatResult = await businessService.chatList(param);
        if (chatResult.code == 0) {
          this.dispatch({
            type: "business/updateChat",
            payload: chatResult.data
          });
        }
      },
      async modCompany(param) {
        let result = await businessService.modifyCompany(param);
        if (result.code == 0) {
          this.dispatch({
            type: "business/editCompanyDetail",
            payload: param   
          });
          message.info("修改成功！");
        }
        return result;
      },
      async modPos(id, param) {
        let result = await businessService.modifyJD(id, param);
        if (result.code == 0) {
          this.dispatch({
            type: "business/editPosDetail",
            payload: param   
          });
          message.info("修改成功！");
        }
        return result;
      },
      async addRecord(param) {
        let result = await businessService.insertChat(param);
        let businessDetail = this.getState().business.businessDetail;
        if (result.code == 0) {
          message.info("添加成功！");
          let chatParam = {
            expand: "create_time_str,create_by_name,create_by_org_name,status_name,invalid_reason_name",
            company_id: businessDetail.company_id,
            business_id: businessDetail.id,
            "per-page": 6,
            page: param.currentPage
          }
          let chatResult = await businessService.chatList(chatParam);
          if (chatResult.code == 0) {
            this.dispatch({
              type: "business/updateChat",
              payload: chatResult.data
            });
          }
        }
        return result;
      },
      async addChat(param) {
        let result = await businessService.insertChat(param);
        if (result.code == 0) {
        }
        return result;
      },
      async operateBusiness(param) {
        let result = await businessService.operateBusiness(param);
        if (result.code == 0) {
          param.username = this.getState().user.name;
          this.dispatch({
            type: "business/updateBusinessStatus",
            payload: param
          });
          if (param.operate_type == 3) {
            message.info("发送成功！");
          } else {
            message.info("修改成功！");
          }
          let chatParam = {
            expand: "create_time_str,create_by_name,create_by_org_name,status_name,invalid_reason_name",
            company_id: this.getState().business.businessDetail.company.id,
            business_id: param.business_id,
            "per-page": 6,
          }
          let chatResult = await businessService.chatList(chatParam);
          if (chatResult.code == 0) {
            this.dispatch({
              type: "business/updateChat",
              payload: chatResult.data
            });
          }
        }
        return result;
      },
      async getCompanyList(param) {
        let result = await businessService.companyList(param);
        if (result.code == 0) {
          this.dispatch({
            type: "business/updateCompanyList",
            payload: result.data     
          });
        }
      },
      async changeManager(param) {
        let result = await businessService.changeManager(param);
        if (result.code == 0) {
          message.info("操作成功！");
          this.dispatch({
            type: "business/updateManager",
            payload: param
          });
        }
        return result;
      },
      async getSendRecord(param) {
        let result = await businessService.getSendRecord(param);
        if (result.code == 0) {
          result.data.list.map(item => {
            item.collapse = false;
          })
          this.dispatch({
            type: "business/updateSendRecord",
            payload: result.data.list
          });
        }
        return result;
      },
      async reSendOffer(param) {
        let result = await businessService.reSendOffer(param);
        if (result.code == 0) {
          message.info("操作成功！");
        }
        return result;
      },
      async exportRecords(params) {
        let result = await businessService.exportRecords(params);
        return result;
      }
    };

    reducers = {
      updateCommon(state, { payload: data }) {
        return { ...state, commonData: data };
      },
      updateList(state, { payload: data }) {
        return { ...state, businessList: data };
      },
      updateCompanyList(state, { payload: data }) {
        return { ...state, companyList: data };
      },
      updateBusinessDetail(state, { payload: data }) {
        return { ...state, businessDetail: data };
      },
      updateChat(state, { payload: data }) {
        return { ...state, record: data };
      },
      updateSendRecord(state, { payload: data }) {
        
        return { ...state, sendRecordList: data };
      },
      editCompanyDetail(state, { payload: data }) {
        state.businessDetail.company.name = data.company_name;
        state.businessDetail.company.logo = data.logo;
        state.businessDetail.company.company_type_name = data.company_type_labelValue && data.company_type_labelValue.label;
        state.businessDetail.company.industry_name = data.industry_labelValue && data.industry_labelValue.label;
        state.businessDetail.company.scale_name = data.scale_labelValue && data.scale_labelValue.label;
        state.businessDetail.company.company_type = data.company_type_labelValue && data.company_type_labelValue.key;
        state.businessDetail.company.industry = data.industry_labelValue && data.industry_labelValue.key;
        state.businessDetail.company.scale = data.scale_labelValue && data.scale_labelValue.key;
        state.businessDetail.company.province_name = data.cityName[0] ? data.cityName[0] : "";
        if (data.cityName[1]) {
          data.cityName[1] = "天津北京重庆上海".indexOf(data.cityName[1]) != -1 ? "" : data.cityName[1]
        } else {
          data.cityName[1] = "";
        }
        state.businessDetail.company.city_name = data.cityName[1];
        state.businessDetail.company.region_name = data.cityName[2] ? data.cityName[2] : "";
        state.businessDetail.company.province_id = data.city[0] ? data.city[0] : 0;
        state.businessDetail.company.city_id = data.city[1] ? data.city[1] : 0;
        state.businessDetail.company.region_id = data.city[2] ? data.city[2] : 0;
        state.businessDetail.company.address = data.address;
        return { ...state};
      },
      editPosDetail(state, { payload: data }) {
        // state.businessDetail.jd_info.salary_name = data.salary_labelValue && data.salary_labelValue.label;
        // state.businessDetail.jd_info.salary = data.salary_labelValue && data.salary_labelValue.key;
        // state.businessDetail.salary_name = data.salary_ind ? "面议" : (data.salary_type_labelValue || {}).label;
        // state.businessDetail.salary_type = (data.salary_type_labelValue || {}).key;
        // state.businessDetail.salary_from = data.salary_from;
        // state.businessDetail.salary_to = data.salary_to;
        // state.businessDetail.salary_ind = data.salary_ind;

        state.businessDetail.jd_info.require_num = data.require_num;
        state.businessDetail.jd_info.education_name = data.education_labelValue && data.education_labelValue.label;
        state.businessDetail.jd_info.service_type_name = data.service_type_labelValue.label;
        state.businessDetail.jd_info.work_type_name = data.work_type_labelValue && data.work_type_labelValue.label;
        state.businessDetail.jd_info.education = data.education_labelValue && data.education_labelValue.key;
        state.businessDetail.jd_info.service_type = data.service_type_labelValue.key;
        state.businessDetail.jd_info.work_type = data.work_type_labelValue && data.work_type_labelValue.key;
        state.businessDetail.jd_info.work_time = data.work_time_labelValue && data.work_time_labelValue.key;
        state.businessDetail.jd_info.work_time_name = data.work_time_labelValue && data.work_time_labelValue.label;
        state.businessDetail.jd_info.province_id = data.city[0] ? data.city[0] : 0;
        state.businessDetail.jd_info.city_id = data.city[1] ? data.city[1] : 0;
        state.businessDetail.jd_info.region_id = data.city[2] ? data.city[2] : 0;
        state.businessDetail.jd_info.province = data.cityName[0] ? data.cityName[0] : "";
        if (data.cityName[1]) {
          data.cityName[1] = "天津北京重庆上海".indexOf(data.cityName[1]) != -1 ? "" : data.cityName[1]
        } else {
          data.cityName[1] = "";
        }
        state.businessDetail.jd_info.city = data.cityName[1];
        // state.businessDetail.jd_info.city = data.cityName[1] ? data.cityName[1] : "";
        state.businessDetail.jd_info.region = data.cityName[2] ? data.cityName[2] : "";
        state.businessDetail.jd_info.position_name = data.posName ? data.posName.join("/") : "";
        state.businessDetail.jd_info.position_pid = data.position_label[0] ? data.position_label[0] : "";
        state.businessDetail.jd_info.position = data.position_label[1] ? data.position_label[1] : "";
        state.businessDetail.jd_info.benefits = data.benefits_labelValue.map((item)=>({id: item.key, name: item.label}));
        state.businessDetail.jd_info.address = data.address;
        state.businessDetail.jd_info.jd_desc = data.jd_desc;
        return { ...state};
      },
      updateLoading(state, { payload: data }) {
        return { ...state, businessLoading: data };
      },
      updateBusinessStatus(state, { payload: data }) {
        state.businessDetail.status = data.operate_type;
        if(data.operate_type == 3) {
          state.businessDetail.send_quote_content = {};
          state.businessDetail.send_quote_content.message_to = data.message_to;
          state.businessDetail.send_quote_content.title = data.title;
          state.businessDetail.send_quote_content.content = data.content;
          let moment = require("moment");
          state.businessDetail.send_quote_time = moment().format('YYYY/MM/DD HH:mm')
        }
        if(data.operate_type == 4) {
          state.businessDetail.quote_confirm_user = data.username + "-后台确认";
          let moment = require("moment");
          state.businessDetail.quote_confirm_time = moment().format('MM/DD HH:mm')
        }
        return { ...state, businessDetail: {...state.businessDetail}};
      },
      updateManager(state, { payload: data }) {
        // state.businessDetail.bd_name = data.bd_id_name[2];
        // state.businessDetail.bd_id = data.bd_ids[2];
        // state.businessDetail.org_id = data.bd_ids[1];
        // state.businessDetail.city_id = data.bd_ids[0];
        // state.businessDetail.bd_org_name = data.bd_id_name[1];
        // state.businessDetail.manager_team = data.bd_id_name[0] + "-" + data.bd_id_name[1];
        // if (data.source_labelValue.length > 1) {
        //   state.businessDetail.source = data.source_labelValue[1];
        //   state.businessDetail.source_p = data.source_labelValue[0];
        // } else {
        //   state.businessDetail.source = data.source_labelValue[0];
        //   state.businessDetail.source_p = "";
        // }
        // state.businessDetail.source_name = data.source_name.join("-");
        return { ...state};
      },
      setUpdateState(state, { payload }) {
        const { filed, value } = payload;
        state[filed] = value;
        return { ...state };
      },
    };
  }
);
