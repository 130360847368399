import Model from "../Model";
import * as UserFeedbackS from "services/UserFeedbackS";
export default Model.getInstance(
  class extends Model {
    namespace = "UserFeedbackModal";
    state = {
      opinion: {
        loadingOpinion: false //意见反馈加载状态
      },
      person: {
        loadingPerson: false //人选反馈加载状态
      }
    };

    actions = {
      async getOpinionList(param = {}) {
        this.dispatch({
          type: "UserFeedbackModal/setUpdateState",
          payload: { filed: "opinion", value: { loadingOpinion: true } }
        });
        const expand = "user_name,status_name";
        let result = await UserFeedbackS.opinionManageList({
          ...param,
          expand
        });
        if (result) {
          this.dispatch({
            type: "UserFeedbackModal/setUpdateState",
            payload: {
              filed: "opinion",
              value: { ...result.data, loadingOpinion: false }
            }
          });
        }
      },
      async getPersonList(param = {}) {
        this.dispatch({
          type: "UserFeedbackModal/setUpdateState",
          payload: { filed: "person", value: { loadingPerson: true } }
        });
        const expand = "city_name,sex_name,work_time_name,phone,user_name";
        let result = await UserFeedbackS.personManageList({ ...param, expand });
        if (result) {
          this.dispatch({
            type: "UserFeedbackModal/setUpdateState",
            payload: {
              filed: "person",
              value: { ...result.data, loadingPerson: false }
            }
          });
        }
      },
      async feedbackDispose(param) {
        let result = await UserFeedbackS.feedbackDispose(param);
       return result;
      }
    };

    reducers = {
      setUpdateState(state, { payload }) {
        const { filed, value } = payload;
        state[filed] = value;
        return { ...state };
      }
    };
  }
);
