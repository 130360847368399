import Model from "../Model";
import dotProp from "dot-prop-immutable";
import * as workBenchS from "services/workBenchS";
export default Model.getInstance(
  class extends Model {
    namespace = "workBenchM";
    state = {
      positionData: [],
      remindInfo: [], //提醒列表
      articleInfo: [], //优职说列表
      recommendInfo: {}, //实时推荐列表
      personInfo: {}, //人选列表
      isRemindOpen: false, //提醒弹窗
      articleDetail: {} //优职说详情
    };

    actions = {
      async getPositionList() {
        let result = await workBenchS.newPositionList();
        if (result) {
          this.dispatch({
            type: "workBenchM/updateRedux",
            payload: { positionData: result.data }
          });
        }
      },
      async getReminderList(param) {
        let result = await workBenchS.reminderList(param);
        if (result) {
          this.dispatch({
            type: "workBenchM/updateRedux",
            payload: { remindInfo: (result.data || {}).list }
          });
        }
      },
      async getPersonList(param) {
        let result = await workBenchS.personList(param);
        if (result) {
          this.dispatch({
            type: "workBenchM/updateRedux",
            payload: { personInfo: result.data }
          });
        }
      },
      async getRecommendList(param) {
        let result = await workBenchS.recommendList(param);
        if (result) {
          this.dispatch({
            type: "workBenchM/updateRedux",
            payload: { recommendInfo: result.data }
          });
        }
      },
      async getArticleList(param) {
        let result = await workBenchS.articleList(param);
        if (result) {
          this.dispatch({
            type: "workBenchM/updateRedux",
            payload: { articleInfo: result.data }
          });
        }
      },
      async getArticleDetail(id) {
        let expand = "useful_num,useless_num,mark,type_name,update_time_str";
        let result = await workBenchS.articleDetail({ id,scene:2, expand });
        if (result) {
          this.dispatch({
            type: "workBenchM/updateRedux",
            payload: { articleDetail: result.data }
          });
        }
      }
    };

    reducers = {
      updateRedux(state, { payload }) {
        return { ...state, ...payload };
      },
      updateArticleDetail(state, { payload }) {
        state = dotProp.set(state, 'articleDetail.mark', payload);
        state = dotProp.set(
          state,
          `articleDetail.${payload === 2 ? "useless_num" : "useful_num"}`,
          state.articleDetail[`${payload === 2 ? "useless_num" : "useful_num"}`]+1
        );
        return { ...state };
      },
      updateList(state, { payload }) {
        const { index, is_collect } = payload;
        state = dotProp.set(
          state,
          `positionData.${index}.is_collect`,
          is_collect === 1 ? 0 : 1
        );
        return { ...state };
      }
    };
  }
);
