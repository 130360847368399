import React, { PureComponent } from "react";
import { connect } from "react-redux";
import dotProp from "dot-prop-immutable";
import {
  Select,
  Input,
  Form,
  Message,
  Pagination,
  Modal,
  Collapse,
  DatePicker,
  Checkbox,
  Radio,
  Row,
  Col,
  Tooltip,
  Icon,
  Cascader,
  Button
} from "antd";
import moment from "moment";
import PositionVacantModal from "store/reducers/positionVacantM";
import locale from "antd/lib/date-picker/locale/zh_CN";
import { withRouter } from "react-router";
import global from "store/reducers/global";
import InternDetail from "pages/TalentPool/Intern/Detail";
import SocialDetail from "pages/TalentPool/SocialCandidate/Detail";
import InputNumberUnit from "components/InputNumberUnit";
import CustomIcon from "components/CustomIcon";
// import { debounce } from "utils/util";
import { getPerformance } from "services/positionS";
import fileDownload from "utils/fileDownload.js";
import { isLoginReq } from "services/user";
import _ from "underscore";

const Option = Select.Option;
const FormItem = Form.Item;
const Panel = Collapse.Panel;
const CheckboxGroup = Checkbox.Group;
const RadioGroup = Radio.Group;
const labelName = [
  "",
  "",
  "面试地点",
  "面试反馈",
  "备注",
  "备注",
  "备注",
  "离职原因"
];
const leaveName = {
  1: "保证期",
  2: "",
  3: "项目结束",
  4: ""
};
const formItemLayout = {
  labelCol: {
    span: 6
  },
  wrapperCol: {
    span: 14
  }
};
const formItemServe = {
  labelCol: {
    span: 6
  },
  wrapperCol: {
    span: 18
  }
};
const formItemCol = {
  labelCol: {
    span: 8
  },
  wrapperCol: {
    span: 16
  }
};
const formItemSingle = {
  labelCol: {
    span: 12
  },
  wrapperCol: {
    span: 12
  }
};
const plainOptions = [
  { value: 2, label: "邮件通知候选人" },
  { value: 4, label: "邮件通知客户" }
];

/**
 * @param openStatus 弹窗类型 2-安排面试,3-面试通过,4-offer协调,5-onboard-入职到岗-续签,6-淘汰,7-离职
 * @param statusTab 人选状态 -1待筛选，1-已推荐，2-安排面试，3-面试通过，4-offer协调，5-onboard，-10-已淘汰
 */
const RecommendDialog = Form.create()(
  @connect(
    ({ PositionVacantModal }) => {
      return { ...PositionVacantModal };
    },
    {
      ...PositionVacantModal.actions
    }
  )
  class RecommendDialogForm extends React.PureComponent {
    state = {
      size: "default",
      initValue: {},
      isEditService: false,
      titleData: [
        "",
        "",
        "安排面试",
        "面试通过",
        "offer协调",
        "入职到岗",
        "淘汰人选",
        "离职"
      ],
      labelData: ["", "", "面试", "通过", "offer", "Onboard", "淘汰", "离职"],
      service_type: 3, //选择的服务类型
      charge_method: 1, //收费类型
      performanceParam: {}, //获取成绩参数
      showPerformance: {}, //展示所计算数的值
      charge_amount: "", //人才招聘类型是的收费金额=总利润
      assign_schemeForm: [{ hh_id: [], ratio: "" }],
      loginInfo: {},
      initGetStatus: false //初始得到取岗位外包已有的值
    };

    componentDidMount() {
      const { openStatus } = this.props;

      this.props.getCommonData("all_manager");
      const { performance = [], hh_org_info = {} } = this.props.personInfo;
      if (performance.length) {
        this.setState({
          assign_schemeForm: (performance[0] || {}).assign_scheme.map(item => {
            const { hh_org_info = {} } = item;
            const {
              org_city = {},
              org_team = {},
              org_group = {},
              org_user = {}
            } = hh_org_info;
            return {
              hh_id: [org_city.id, org_team.id, org_group.id, org_user.id],
              ratio: item.ratio
            };
          }),
          service_type: (performance[0] || {}).service_type,
          loginInfo: {
            loginId: (
              ((performance[0] || {}).assign_scheme[0] || {}).hh_org_info || {}
            ).login_uid
          }
        });
      } else {
        const {
          org_city = {},
          org_team = {},
          org_group = {},
          org_user = {},
          login_uid
        } = hh_org_info;
        this.setState(
          {
            loginInfo: { loginId: login_uid },
            assign_schemeForm: [
              {
                hh_id: [org_city.id, org_team.id, org_group.id, org_user.id],
                ratio: "100"
              }
            ]
          },
          () => {
            console.log(this.state, "rrrr");
          }
        );
      }

      //初始化设置现有计算值
      this.setState({ initGetStatus: true }); //
      const {
        gp_month,
        event_time,
        end_date,
        bill_month,
        onboard_date,
        service_type
      } = performance[0] || {};
      if (
        openStatus !== 7 &&
        (gp_month || event_time || end_date || bill_month || onboard_date) &&
        !(this.props.personInfo || {}).is_renew
      ) {
        this.setState(
          {
            performanceParam: {
              gp_month,
              event_time: onboard_date,
              end_date,
              bill_month
            }
          },
          () => {
            console.log(this.state, "ppppp");
          }
        );
      }
      //在续签服务类型为岗位外包（3）进行赋值
      if (
        openStatus === 5 &&
        ((this.props.personInfo || {}).is_renew && service_type === 3)
      ) {
        this.setState(
          {
            performanceParam: {
              event_time: this.formatTime(
                moment((performance[0] || {}).end_date).add(1, "day"),
                "YYYY-MM-DD HH:mm"
              )
            }
          },
          () => {
            console.log(this.state, "iiiooo");
          }
        );
      }
    }

    handleSubmit = (e, type) => {
      const { validateFieldsAndScroll } = this.props.form;
      const { showModal } = this.props.parentMethod;
      const {
        candidateHandle,
        getCandidateList,
        needProgress,
        getCandidateProgress,
        personInfo,
        openStatus,
        setUpdateState,
        getDetail,
        needLoading
      } = this.props;
      const { is_renew } = personInfo;
      e.preventDefault();
      validateFieldsAndScroll((err, values) => {
        if (!err) {
          const dataParam = { ...values };
          dataParam.operate_type = openStatus;
          dataParam.jd_profile_id = personInfo.id;
          dataParam.event_time = this.formatTime(values.time);
          delete dataParam.time;
          console.log(values.assign_scheme, "values.assign_scheme");
          //onboard操作入职或离职的参数
          if (openStatus === 5) {
            dataParam.end_date = this.formatTime(values.end);
            dataParam.warranty_date = this.formatTime(values.warrantyTime);
            if (values.assign_scheme && values.assign_scheme.length) {
              dataParam.assign_scheme = values.assign_scheme.map(item => {
                item["hh_id"] = _.filter(item.hh_id); //去除多余的undefined
                return {
                  ...item,
                  hh_id: item.hh_id[item.hh_id.length - 1]
                };
              });
            }
            dataParam.is_renew = is_renew;
            delete dataParam.end;
            delete dataParam.warrantyTime;
          }

          candidateHandle(dataParam).then(res => {
            if (res.code === 0) {
              //数据中心列表查询
              if (needLoading) {
                needLoading();
              } else {
                getCandidateList(this.props.positionDetail.id).then(res => {
                  // 需要候选人记录也查询
                  if (needProgress && res.code === 0) {
                    needProgress();
                  }
                });

                setUpdateState({
                  filed: "isLoadingList",
                  value: true
                });
                setUpdateState({
                  filed: "profileNumUpdate",
                  value: true
                });
                //需要查询一下职位详情更新推荐的状态数
                getDetail(this.props.positionDetail.id);
              }
              showModal(0);//关闭弹窗
            }
          });
        }
      });
    };

    formatTime = (val, format) => {
      return val ? val.format(format ? format : "YYYY-MM-DD HH:mm") : null;
    };

    handleCancel = e => {
      this.props.parentMethod.showModal(0);
      this.setState({ isEditService: false });
    };
    /**
     * @description 限制输入个数
     * @param {string} value   当前输入框返回数据
     */
    limitSize = (rule, value, callback) => {
      if (value && value.length > 100) {
        callback("最大输入不要超过100字符!");
      }
      callback();
    };
    /**
     * @description 改变服务类型
     * @param {string} name  改变的名字
     * @param {number} val   改变的值
     */
    changeValue = (name, val) => {
      const { setFieldsValue } = this.props.form;
      this.setState({
        [name]: (val.target || {}).value ? val.target.value : val
      });
      // 切换服务类型清空之前计算的值和收费率值
      console.log(name, this.state);
      if (name === "service_type") {
        this.setState({
          showPerformance: {},
          charge_amount: "" //人才招聘金额清空
        });
        //如果是续签除了onboard时间其余清空
        if ((this.props.personInfo || {}).is_renew) {
          this.setState({
            showPerformance: {},
            performanceParam: {
              //保留onborad原有的时间
              event_time: moment(
                this.state.performanceParam.event_time || val
              ).format("YYYY-MM-DD")
            }
          });
        }
        setFieldsValue({ charge_amount: "" });
        this.clearVal("charge_value");
      }
      //切换收费率类型清空输入的值
      if (name === "charge_method") this.clearVal("charge_value");
    };
    /**
     * @description 选择月薪进行收费率右边输入框赋值
     * @param {string} name  改变的名字
     * @param {number} val   改变的值
     */
    changeSalaryValue = val => {
      //收费率在选择月薪类型时进行赋值
      if (this.state.charge_method === 1) this.setVal("charge_value", val);
    };
    /**
     * @description 清空val值
     * @param {string} name  改变的名字
     */
    clearVal = name => {
      const { setFieldsValue } = this.props.form;
      //由于要取选中的公司下大联系人和电话所写
      setFieldsValue({ [name]: "" });
    };
    /**
     * @description 设置收费率右边输入框值
     * @param {string} name  改变的名字
     */
    setVal = (name, val) => {
      const { setFieldsValue } = this.props.form;
      //由于要取选中的公司下大联系人和电话所写
      setFieldsValue({ [name]: val });
    };
    /**
     * @description 得到计算的值
     * @param {string} name  改变的名字
     * @param {number} val   改变的值
     */
    onGetVal = (name, val) => {
      let convert = {};
      if (name === "time") {
        convert.event_time = this.formatTime(val, "YYYY-MM-DD HH:mm");
      }

      if (name === "end")
        convert.end_date = this.formatTime(val, "YYYY-MM-DD HH:mm");
      convert[name] = val;
      delete convert.time;
      delete convert.end;
      this.setState(
        {
          performanceParam: {
            ...this.state.performanceParam,
            ...convert,
            jd_profile_id: this.props.personInfo.id
          },
          initGetStatus: false
        },
        () => {
          const {
            gp_month,
            event_time,
            end_date,
            bill_month
          } = this.state.performanceParam;
          if (gp_month && event_time && end_date && bill_month) {
            // if (gp_month && bill_month && gp_month > bill_month) {
            //   Message.error("月开票必须大于月GP");
            //   return;
            // }
            this.getComputeVal(this.state.performanceParam);
          }
        }
      );
    };
    //获取计算几口值
    getComputeVal = param => {
      getPerformance(param).then(res => {
        this.setState({ showPerformance: { ...res.data } });
      });
    };
    //添加业绩切分
    addAddressClick = () => {
      let { assign_schemeForm } = this.state;
      assign_schemeForm.push({ hh_id: [], ratio: undefined });
      this.setState({
        assign_schemeForm: [].concat(assign_schemeForm)
      });
    };
    //删除业绩切分
    delAddressClick = index => {
      const { getFieldValue, resetFields } = this.props.form;
      const assign_scheme = getFieldValue("assign_scheme");
      // let { assign_schemeForm } = this.state;
      assign_scheme.splice(index, 1);
      resetFields(["assign_scheme"]);
      this.setState({
        assign_schemeForm: [].concat(assign_scheme)
      });
    };
    //是否可以切分
    isSplitter = () => {
      // const { loginInfo = {} } = this.state;
      // const { positionDetail = {} } = this.props;
      // console.log(
      //   positionDetail.hh_id,
      //   "positionDetail.hh_id",
      //   loginInfo.loginId,
      //   positionDetail.hh_id === loginInfo.loginId
      // );
      // if (positionDetail.hh_id === loginInfo.loginId) return true;
      // return false;
      return true;
    };

    optionStatus = [
      {
        label: "月薪",
        value: 1
      },
      {
        label: "百分比",
        value: 2
      }
    ];

    editService = () => {
      this.setState({
        isEditService: true
      });
    };
    handleEditCancel = e => {
      this.setState({
        isEditService: false
      });
    };

    // onboard历史
    renderPerformance = performance => {
      const { isEditService } = this.state;
      const { openStatus = 0, personInfo = {}, statusTab } = this.props;
      const { is_renew } = personInfo;
      const performances = performance.map((item, index) => {
        return (
          <div className="item-box" key={index}>
            <div className="title">
              <img src={require("assets/imgs/icon-time.png")} />
              {item.create_time_str} &nbsp;&nbsp;
              {item.create_by_name}
              {/**非续签 */
              !isEditService && !is_renew && openStatus !== 7 && index === 0 && (
                <div className="edit-area" onClick={this.editService}>
                  <CustomIcon type="icon-edit" />
                  编辑
                </div>
              )}
            </div>
            {isEditService && index === 0 ? (
              <Form onSubmit={this.handleSubmit}>
                {this.renderOnboard()}
                <div className="edit-footer">
                  <Button onClick={this.handleEditCancel}>取消</Button>
                  <Button
                    disabled={statusTab == 6}
                    type="primary"
                    onClick={e => this.handleSubmit(e, "edit")}
                  >
                    确定
                  </Button>
                </div>
                <div className="padding-div" />
              </Form>
            ) : (
              <React.Fragment>
                {item.service_type && (
                  <div className="row-info ">
                    <span className="row-title">服务类型：</span>
                    <span>{item.service_type_name}</span>
                  </div>
                )}
                {item.onboard_date && (
                  <div className="row-info ">
                    <span className="row-title">Onboard时间 ：</span>
                    <span>{item.onboard_date}</span>
                  </div>
                )}
                {item.warranty_date && (
                  <div className="row-info ">
                    <span className="row-title">保证期 ：</span>
                    {item.warranty_date}
                  </div>
                )}
                {item.resigned_date && (
                  <div className="row-info ">
                    <span className="row-title">离职时间 ：</span>
                    {item.resigned_date}
                  </div>
                )}
                {item.end_date && (
                  <div className="row-info ">
                    <span className="row-title">项目结束时间：</span>
                    {item.end_date}
                  </div>
                )}
                {item.salary && (
                  <div className="row-info ">
                    <span className="row-title">月薪：</span>
                    {item.salary}元
                  </div>
                )}
                {item.charge_amount && (
                  <div className="row-info ">
                    <span className="row-title">收费金额 ：</span>
                    {item.charge_amount}元
                  </div>
                )}
                {item.charge_value && (
                  <div className="row-info ">
                    <span className="row-title">
                      收费率(
                      {`${item.charge_method === 1 ? "月薪" : "百分比"}`}
                      )：
                    </span>
                    {item.charge_value}
                    {`${item.charge_method === 1 ? "元" : "%"}`}
                  </div>
                )}
                {item.gp_month && (
                  <div className="row-info ">
                    <span className="row-title">月GP：</span>
                    {item.gp_month}元
                  </div>
                )}
                {item.bill_month && (
                  <div className="row-info ">
                    <span className="row-title">月开票：</span>
                    {item.bill_month}元
                  </div>
                )}
                {item.gp_total && (
                  <div className="row-info ">
                    <span className="row-title">总利润：</span>
                    {item.gp_total}元
                  </div>
                )}
                {item.bill_total && (
                  <div className="row-info ">
                    <span className="row-title">总开票：</span>
                    {item.bill_total}元
                  </div>
                )}
                {item.bill_expected && (
                  <div className="row-info ">
                    <span className="row-title">预计总开票：</span>
                    {item.bill_expected}元
                  </div>
                )}
                {item.gp_expected && (
                  <div className="row-info ">
                    <span className="row-title">预计总利润：</span>
                    {item.gp_expected}元
                  </div>
                )}
                {(item.assign_scheme || []).length > 0 && (
                  <div className="row-info ">
                    <span className="row-title">业绩切分：</span>
                    {item.assign_scheme.map((schemeItem, schemeIndex) => {
                      return (
                        <span key={schemeIndex}>
                          {`${schemeItem.hh_name}-${schemeItem.ratio}`}
                          {schemeItem.ratio && "%"}
                          &nbsp;
                        </span>
                      );
                    })}
                  </div>
                )}
                {item.remark && (
                  <div className="row-info ">
                    <span className="row-title">备注：</span>
                    {item.remark}
                  </div>
                )}
              </React.Fragment>
            )}
          </div>
        );
      });
      return performances;
    };

    // 公共弹窗
    renderCommon = () => {
      const { labelData, size } = this.state;
      const { openStatus = 0, personInfo = {}, statusTab } = this.props;
      const { getFieldDecorator } = this.props.form;
      const { performance = [], is_renew, last_event_time } = personInfo;
      return (
        <React.Fragment>
          <FormItem {...formItemLayout} label={`${labelData[openStatus]}时间`}>
            {getFieldDecorator("time", {
              initialValue: undefined,
              rules: [{ required: true, message: "请选择" }]
            })(
              <DatePicker
                disabledDate={currentDate => {
                  return (
                    currentDate &&
                    currentDate.isBefore(moment.unix(last_event_time), "day")
                  );
                }}
                onChange={this.onGetVal.bind(this, "time")}
                showTime={{ defaultValue: moment() }}
                size={size}
                locale={locale}
                style={{ width: "100%" }}
                format="YYYY-MM-DD HH:mm"
                placeholder={`请选择${labelData[openStatus]}时间`}
              />
            )}
          </FormItem>
          <FormItem {...formItemLayout} label={`${labelName[openStatus]}`}>
            {getFieldDecorator("remark", {
              initialValue:
                !is_renew && openStatus !== 7
                  ? (performance[0] || {}).remark
                  : undefined,
              rules: [
                { required: false, message: "请填写" },
                {
                  validator: this.limitSize
                }
              ]
            })(
              <Input
                size={size}
                placeholder={`请填写${labelName[openStatus]}`}
              />
            )}
          </FormItem>
        </React.Fragment>
      );
    };
    // 安排面试
    renderInterview = () => {
      const { size } = this.state;
      const { getFieldDecorator } = this.props.form;
      return (
        <React.Fragment>
          {this.renderCommon()}
          <FormItem {...formItemLayout} label="通知">
            {getFieldDecorator("interview_notification", {
              initValue: [1],
              rules: [{ required: false, message: "请填写" }]
            })(<CheckboxGroup options={plainOptions} size={size} />)}
          </FormItem>
        </React.Fragment>
      );
    };
    // 面试通过
    renderInterviewPass = () => {
      return <React.Fragment>{this.renderCommon()}</React.Fragment>;
    };
    // offer协调
    renderOffer = () => {
      return <React.Fragment>{this.renderCommon()}</React.Fragment>;
    };
    validateCharge = (rule, value, callback) => {
      console.log(value);
      // const form = this.props.form;
      if (value > 100) {
        callback("收费率不能大于100");
      }
      callback();
    };
    // 入职到岗
    renderOnboard = () => {
      const {
        labelData,
        size,
        service_type,
        showPerformance = {},
        charge_amount,
        charge_method,
        assign_schemeForm = []
      } = this.state;
      const {
        openStatus = 0,
        personInfo = {},
        statusTab,
        commonData = {}
      } = this.props;
      const { getFieldDecorator, getFieldValue } = this.props.form;
      const { performance = [], is_renew, last_event_time } = personInfo;
      let chargeValueValidate = [{ required: true, message: "请输入" }];
      if (charge_method !== 1) {
        chargeValueValidate.push({ validator: this.validateCharge });
      }
      let disableDate =
        is_renew && openStatus === 5 && (performance[0] || {}).end_date
          ? {
              disabledDate: currentDate => {
                return (
                  currentDate &&
                  currentDate.isBefore(
                    moment((performance[0] || {}).end_date),
                    "day"
                  )
                );
              }
            }
          : {};
      return (
        <React.Fragment>
          <FormItem {...formItemServe} label="服务类型">
            {getFieldDecorator("service_type", {
              initialValue: is_renew
                ? (performance[0] || {}).service_type
                : this.state.service_type,
              rules: [{ required: true, message: "请选择" }]
            })(
              <RadioGroup
                onChange={this.changeValue.bind(this, "service_type")}
              >
                <Radio value={3}>岗位外包</Radio>
                <Radio value={1}>人才招聘</Radio>
                <Radio value={4}>onsite</Radio>
                <Radio value={2}>离岸招聘</Radio>
              </RadioGroup>
            )}
          </FormItem>
          <FormItem {...formItemLayout} label={`${labelData[openStatus]}时间`}>
            {getFieldDecorator("time", {
              initialValue:
                is_renew && openStatus === 5 && (performance[0] || {}).end_date
                  ? moment((performance[0] || {}).end_date).add(1, "day")
                  : (performance[0] || {}).onboard_date && openStatus !== 7
                  ? moment((performance[0] || {}).onboard_date)
                  : undefined,
              rules: [{ required: true, message: "请填选择" }]
            })(
              <DatePicker
                {...disableDate}
                onChange={this.onGetVal.bind(this, "time")}
                showTime={{ defaultValue: moment("HH:mm") }}
                size={size}
                locale={locale}
                style={{ width: "100%" }}
                format="YYYY-MM-DD HH:mm"
                placeholder={`请选择${labelData[openStatus]}时间`}
              />
            )}
          </FormItem>
          {/**岗位外包 */ service_type === 3 && (
            <div>
              <FormItem {...formItemLayout} label="项目结束时间">
                {getFieldDecorator("end", {
                  initialValue:
                    !is_renew && (performance[0] || {}).end_date
                      ? moment((performance[0] || {}).end_date)
                      : undefined,
                  rules: [{ required: true, message: "请填选择" }]
                })(
                  <DatePicker
                    onChange={this.onGetVal.bind(this, "end")}
                    showTime={{ defaultValue: moment("HH:mm") }}
                    size={size}
                    locale={locale}
                    style={{ width: "100%" }}
                    format="YYYY-MM-DD HH:mm"
                    placeholder="请选择时间"
                  />
                )}
              </FormItem>
              <FormItem {...formItemLayout} label="月薪">
                {getFieldDecorator("salary", {
                  initialValue: !is_renew
                    ? (performance[0] || {}).salary
                    : undefined,
                  rules: [{ required: true, message: "请填选择" }]
                })(
                  <InputNumberUnit
                    onChange={this.changeSalaryValue}
                    precision={2}
                    style={{ width: "100%" }}
                    size={size}
                    min={0}
                    placeholder="请输入月薪"
                    unit="元"
                  />
                )}
              </FormItem>
              <Row>
                <Col span={12}>
                  <FormItem {...formItemSingle} label="收费率">
                    {getFieldDecorator("charge_method", {
                      initialValue:
                        !is_renew && (performance[0] || {}).charge_method
                          ? (performance[0] || {}).charge_method
                          : 1,
                      rules: [{ required: true, message: "请填选择" }]
                    })(
                      <Select
                        onChange={this.changeValue.bind(this, "charge_method")}
                        size={size}
                        placeholder="请输选择"
                      >
                        {this.optionStatus.map((item, index) => {
                          return (
                            <Option key={index} value={item.value}>
                              {item.label}
                            </Option>
                          );
                        })}
                      </Select>
                    )}
                  </FormItem>
                </Col>
                <Col span={7} className="money">
                  <FormItem>
                    {getFieldDecorator("charge_value", {
                      initialValue: !is_renew
                        ? (performance[0] || {}).charge_value
                        : undefined,
                      rules: chargeValueValidate
                    })(
                      <InputNumberUnit
                        style={{ width: "100%" }}
                        precision={2}
                        size={size}
                        min={0}
                        placeholder="请输入"
                        unit={`${
                          getFieldValue("charge_method") === 1 ? "元" : "%"
                        }`}
                      />
                    )}
                  </FormItem>
                </Col>
              </Row>
              <FormItem {...formItemLayout} label="月GP">
                {getFieldDecorator("gp_month", {
                  initialValue: !is_renew
                    ? (performance[0] || {}).gp_month
                    : undefined,
                  rules: [{ required: true, message: "请输入" }]
                })(
                  <InputNumberUnit
                    precision={2}
                    onChange={this.onGetVal.bind(this, "gp_month")}
                    style={{ width: "100%" }}
                    size={size}
                    min={0}
                    placeholder="请输入月GP"
                    unit="元"
                  />
                )}
              </FormItem>
              <FormItem {...formItemLayout} label="月开票">
                {getFieldDecorator("bill_month", {
                  initialValue: !is_renew
                    ? (performance[0] || {}).bill_month
                    : undefined,
                  rules: [{ required: true, message: "请输入" }]
                })(
                  <InputNumberUnit
                    precision={2}
                    onChange={this.onGetVal.bind(this, "bill_month")}
                    style={{ width: "100%" }}
                    size={size}
                    min={0}
                    placeholder="请输入月开票"
                    unit="元"
                  />
                )}
              </FormItem>
              <Row>
                <Col style={{ paddingLeft: "30px" }} span={12}>
                  <FormItem {...formItemCol} label="总利润">
                    {is_renew ? (
                      <span>
                        {showPerformance.gp_total}
                        {showPerformance.gp_total && "元"}
                      </span>
                    ) : (
                      <span>
                        {!is_renew && this.state.initGetStatus
                          ? (performance[0] || {}).gp_total
                          : showPerformance.gp_total}
                        {showPerformance.gp_total ||
                        (performance[0] || {}).gp_total
                          ? "元"
                          : undefined}
                      </span>
                    )}
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem {...formItemCol} label="总开票">
                    {is_renew ? (
                      <span>
                        {showPerformance.bill_total}
                        {showPerformance.bill_total && "元"}
                      </span>
                    ) : (
                      <span>
                        {!is_renew && this.state.initGetStatus
                          ? (performance[0] || {}).bill_total
                          : showPerformance.bill_total}
                        {showPerformance.bill_total ||
                        (performance[0] || {}).bill_total
                          ? "元"
                          : undefined}
                      </span>
                    )}
                  </FormItem>
                </Col>
              </Row>
              <Row>
                <Col style={{ paddingLeft: "25px" }} span={12}>
                  <FormItem {...formItemCol} label="预计总利润">
                    {is_renew ? (
                      <span>
                        {showPerformance.gp_expected}
                        {showPerformance.gp_expected && "元"}
                      </span>
                    ) : (
                      <span>
                        {!is_renew && this.state.initGetStatus
                          ? (performance[0] || {}).gp_expected
                          : showPerformance.gp_expected}
                        {showPerformance.gp_expected ||
                        (performance[0] || {}).gp_expected
                          ? "元"
                          : undefined}
                      </span>
                    )}
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem {...formItemCol} label="预计总开票">
                    {is_renew ? (
                      <span>
                        {showPerformance.bill_expected}
                        {showPerformance.bill_expected && "元"}
                      </span>
                    ) : (
                      <span>
                        {!is_renew && this.state.initGetStatus
                          ? (performance[0] || {}).bill_expected
                          : showPerformance.bill_expected}
                        {showPerformance.bill_expected ||
                        (performance[0] || {}).bill_expected
                          ? "元"
                          : undefined}
                      </span>
                    )}
                  </FormItem>
                </Col>
              </Row>
            </div>
          )}
          {/* 人才招聘，onsite，离岸招聘 */ service_type !== 3 && (
            <div>
              {/* 人才招聘 */ service_type === 1 && (
                <FormItem {...formItemLayout} label="保证期">
                  {getFieldDecorator("warrantyTime", {
                    initialValue:
                      !is_renew &&
                      performance &&
                      performance[0] &&
                      performance[0].warranty_date
                        ? moment(performance[0].warranty_date)
                        : undefined,
                    rules: [{ required: true, message: "请填选择" }]
                  })(
                    <DatePicker
                      showTime={{
                        defaultValue: moment("HH:mm")
                      }}
                      size={size}
                      locale={locale}
                      style={{ width: "100%" }}
                      format="YYYY-MM-DD HH:mm"
                      placeholder="请选择时间"
                    />
                  )}
                </FormItem>
              )}

              <FormItem {...formItemLayout} label="收费金额">
                {getFieldDecorator("charge_amount", {
                  initialValue: !is_renew
                    ? (performance[0] || {}).charge_amount
                    : undefined,
                  rules: [{ required: true, message: "请输入" }]
                })(
                  <InputNumberUnit
                    disabled={statusTab === 6}
                    precision={2}
                    onChange={this.changeValue.bind(this, "charge_amount")}
                    style={{ width: "100%" }}
                    size={size}
                    min={0}
                    placeholder="请输入"
                    unit="元"
                  />
                )}
              </FormItem>
              <Row>
                <Col span={12}>
                  <FormItem {...formItemSingle} label="收费率">
                    {getFieldDecorator("charge_method", {
                      initialValue:
                        !is_renew && (performance[0] || {}).charge_method
                          ? (performance[0] || {}).charge_method
                          : 1,
                      rules: [{ required: true, message: "请填选择" }]
                    })(
                      <Select
                        disabled={statusTab === 6}
                        onChange={this.changeValue.bind(this, "charge_method")}
                        style={{ width: "135" }}
                        size={size}
                        placeholder="请输选择"
                      >
                        {this.optionStatus.map((item, index) => {
                          return (
                            <Option key={index} value={item.value}>
                              {item.label}
                            </Option>
                          );
                        })}
                      </Select>
                    )}
                  </FormItem>
                </Col>
                <Col span={7} className="money">
                  <FormItem>
                    {getFieldDecorator("charge_value", {
                      initialValue: !is_renew
                        ? (performance[0] || {}).charge_value
                        : undefined,
                      rules: [...chargeValueValidate]
                    })(
                      <InputNumberUnit
                        disabled={statusTab === 6}
                        precision={2}
                        style={{ width: "100%" }}
                        size={size}
                        min={0}
                        placeholder="请输入"
                        unit={`${
                          getFieldValue("charge_method") === 1 ? "元" : "%"
                        }`}
                      />
                    )}
                  </FormItem>
                </Col>
              </Row>

              <FormItem {...formItemLayout} label="总利润">
                {charge_amount
                  ? charge_amount
                  : (performance[0] || {}).charge_amount}
                {charge_amount || (performance[0] || {}).charge_amount
                  ? "元"
                  : undefined}
              </FormItem>
            </div>
          )}
          {/* 业绩切分 */}
          {console.log(assign_schemeForm, "assign_schemeForm")}
          <div>
            {assign_schemeForm &&
              assign_schemeForm.map((item, index) => {
                return (
                  <Row
                    key={index}
                    className={index === 0 ? "" : "add-split-row"}
                  >
                    <Col span={12}>
                      <FormItem
                        label={index === 0 ? "业绩切分:" : " "}
                        {...formItemSingle}
                      >
                        {console.log(item.hh_id, "item.hh_id")}
                        {getFieldDecorator(`assign_scheme[${index}].hh_id`, {
                          initialValue: item.hh_id,
                          rules: [
                            {
                              required: true,
                              message: " "
                            }
                          ]
                        })(
                          <Cascader
                            disabled={
                              assign_schemeForm.length == 1 ||
                              !this.isSplitter()
                            }
                            options={commonData.all_manager}
                            fieldNames={{
                              label: "name",
                              value: "id",
                              children: "list"
                            }}
                            placeholder="请选择"
                            size={size}
                          />
                        )}
                      </FormItem>
                    </Col>
                    <Col span={7} className="money">
                      <FormItem>
                        {getFieldDecorator(`assign_scheme[${index}].ratio`, {
                          initialValue: item.ratio,
                          rules: [{ required: true, message: "请输入" }]
                        })(
                          <InputNumberUnit
                            disabled={
                              assign_schemeForm.length == 1 ||
                              !this.isSplitter()
                            }
                            precision={2}
                            style={{
                              width: "100%"
                            }}
                            size={size}
                            min={0.01}
                            placeholder="请输入"
                            unit="%"
                          />
                        )}
                      </FormItem>
                    </Col>
                    {assign_schemeForm.length > 1 &&
                    index > 0 &&
                    this.isSplitter() ? (
                      <Col span={2} className="delete-col">
                        <Icon
                          className="dynamic-delete-button"
                          type="minus-circle-o"
                          onClick={this.delAddressClick.bind(this, index)}
                        />
                      </Col>
                    ) : null}
                  </Row>
                );
              })}
            {assign_schemeForm.length < 3 && this.isSplitter() && (
              <Row className="add">
                <Col span={14} offset={6}>
                  <Button
                    size={size}
                    className="add-split-btn"
                    onClick={this.addAddressClick.bind(this)}
                  >
                    <Icon type="plus" />
                    添加切分人
                  </Button>
                </Col>
              </Row>
            )}
          </div>
          <FormItem {...formItemLayout} label={`${labelName[openStatus]}`}>
            {getFieldDecorator("remark", {
              initialValue:
                !is_renew && openStatus !== 7
                  ? (performance[0] || {}).remark
                  : undefined,
              rules: [
                { required: false, message: "请填写" },
                {
                  validator: this.limitSize
                }
              ]
            })(
              <Input
                size={size}
                placeholder={`请填写${labelName[openStatus]}`}
              />
            )}
          </FormItem>
        </React.Fragment>
      );
    };
    // 淘汰
    renderDeselect = () => {
      const { labelData, size } = this.state;
      const { openStatus = 0, personInfo = {}, statusTab } = this.props;
      const { getFieldDecorator } = this.props.form;
      const { performance = [], is_renew, last_event_time } = personInfo;
      return (
        <React.Fragment>
          <FormItem {...formItemLayout} label={`${labelData[openStatus]}时间`}>
            {getFieldDecorator("time", {
              initialValue: undefined,
              rules: [{ required: true, message: "请选择" }]
            })(
              <DatePicker
                onChange={this.onGetVal.bind(this, "time")}
                showTime={{ defaultValue: moment("HH:mm") }}
                size={size}
                locale={locale}
                style={{ width: "100%" }}
                format="YYYY-MM-DD HH:mm"
                placeholder={`请选择${labelData[openStatus]}时间`}
              />
            )}
          </FormItem>
          <FormItem {...formItemLayout} label={`${labelName[openStatus]}`}>
            {getFieldDecorator("remark", {
              initialValue:
                !is_renew && openStatus !== 7
                  ? (performance[0] || {}).remark
                  : undefined,
              rules: [
                { required: false, message: "请填写" },
                {
                  validator: this.limitSize
                }
              ]
            })(
              <Input
                size={size}
                placeholder={`请填写${labelName[openStatus]}`}
              />
            )}
          </FormItem>
        </React.Fragment>
      );
    };
    // 离职
    renderLeave = () => {
      const { labelData, size, service_type } = this.state;
      const { openStatus = 0, personInfo = {}, statusTab } = this.props;
      const { getFieldDecorator } = this.props.form;
      const { performance = [], is_renew } = personInfo;
      return (
        <React.Fragment>
          <div>
            {(performance[0] || {}).onboard_date && (
              <FormItem {...formItemLayout} label="Onboard时间">
                {(performance[0] || {}).onboard_date}
              </FormItem>
            )}
            {(performance[0] || {}).end_date && (
              <FormItem {...formItemLayout} label="项目结束时间">
                {(performance[0] || {}).end_date}
              </FormItem>
            )}
            {(performance[0] || {}).resigned_date && (
              <FormItem {...formItemLayout} label="离职时间">
                {(performance[0] || {}).resigned_date}
              </FormItem>
            )}
            {(performance[0] || {}).warranty_date && (
              <FormItem {...formItemLayout} label="保证期时间">
                {(performance[0] || {}).warranty_date}
              </FormItem>
            )}
            {statusTab === -10 && (
              <FormItem {...formItemLayout} label={`${labelName[openStatus]}`}>
                {(performance[0] || {}).resigned_reason ||
                  (performance[0] || {}).remark}
              </FormItem>
            )}
          </div>
          {console.log(statusTab, "ttttttttttt")}
          {statusTab !== -10 && this.renderCommon()}
        </React.Fragment>
      );
    };
    render() {
      const { assign_schemeForm = [], loginInfo = {} } = this.state;
      const { getFieldDecorator } = this.props.form;
      const {
        openStatus = 0,
        personInfo = {},
        commonData = {},
        statusTab
      } = this.props;
      const { performance = [], is_renew } = personInfo;
      const {
        size,
        titleData,
        labelData,
        service_type,
        charge_method,
        showPerformance = {},
        charge_amount
      } = this.state;
      // 是否可以续签
      const isRenew = is_renew && openStatus === 5;
      return (
        <Modal
          width={isRenew || openStatus === 7 ? 1000 : 500}
          title={isRenew && service_type === 3 ? "续签" : titleData[openStatus]}
          visible={openStatus > 0 ? true : false}
          onOk={this.handleSubmit}
          onCancel={this.handleCancel}
          okText="确认"
          cancelText="取消"
          footer={
            openStatus === 5 && performance.length > 0 && !isRenew ? null : (
              <div>
                <Button onClick={this.handleCancel}>取消</Button>
                <Button
                  disabled={statusTab == -10}
                  type="primary"
                  onClick={this.handleSubmit}
                >
                  确定
                </Button>
              </div>
            )
          }
        >
          {isRenew || openStatus === 7 ? (
            <div className="recommend-dialog clear">
              <div className="left">
                <Form onSubmit={this.handleSubmit}>
                  <div className="name clear">
                    <div className="left-label">
                      候选人： {personInfo.name}（ID:{personInfo.profile_no}）
                    </div>
                  </div>
                  {/** 续签*/ openStatus === 5 && this.renderOnboard()}
                  {/** 离职*/ openStatus === 7 && this.renderLeave()}
                </Form>
              </div>
              {performance.length > 0 && (
                <div className="right">
                  {this.renderPerformance(performance)}
                </div>
              )}
            </div>
          ) : (
            <div className="recommend-dialog clear">
              <div className="recommend-container">
                <Form onSubmit={this.handleSubmit}>
                  <div className="name clear">
                    <div className="left-label">
                      候选人： {personInfo.name}（ID:{personInfo.profile_no}）
                    </div>
                  </div>
                  {/* <FormItem
                      {...formItemLayout}
                      label={`${labelData[openStatus]}时间`}
                    >
                    </FormItem> */}
                  {/** 安排面试*/ openStatus === 2 && this.renderInterview()}
                  {/** 面试通过*/ openStatus === 3 &&
                    this.renderInterviewPass()}
                  {/** offer协调*/ openStatus === 4 && this.renderOffer()}
                  {/** 入职到岗*/ openStatus === 5 &&
                    (performance.length > 0
                      ? this.renderPerformance(performance)
                      : this.renderOnboard())}
                  {/** 淘汰*/ openStatus === 6 && this.renderDeselect()}
                </Form>
              </div>
            </div>
          )}

          <style jsx global>
            {`
              .ant-modal-body {
                padding: 30px 20px 22px 10px;
                .recommend-dialog {
                  .left {
                    width: 500px;
                    .money {
                      .gee-input-number-unit .unit {
                        right: -16px;
                      }
                    }
                    .add {
                      margin-bottom: 14px;
                    }
                  }
                  .right {
                    text-algin: left;
                    max-height: 700px;
                    overflow-y: scroll;
                    border-left: 1px solid #e5e5e5;
                    width: 460px;
                    padding: 0 20px;
                  }
                  .recommend-container,
                  .left {
                    .money {
                      margin-left: 20px;
                    }
                    .delete-col {
                      margin: 13px 10px 10px 10px;
                    }
                    .add-split-row {
                      .ant-form-item-label label {
                        display: none;
                      }
                    }
                    .add-split-btn {
                      width: 100%;
                      margin-bottom: 10px;
                      color: #1890ff;
                    }
                  }
                  .item-box {
                    position: relative;
                    padding: 26px 7px 20px 10px;
                    background: #f9f9f9;
                    margin-bottom: 10px;
                    .title {
                      position: relative;
                      padding: 0 0 20px;
                      font-weight: 600;
                      font-size: 14px;
                      line-height: 18px;
                      color: #333333;
                      .edit-area {
                        position: absolute;
                        right: 18px;
                        top: 1px;
                        color: #1890ff;
                        font-size: 12px;
                        cursor: pointer;
                        .anticon {
                          margin-right: 8px;
                          font-size: 14px;
                          vertical-align: -0.18em;
                        }
                      }
                      img {
                        width: 20px;
                        height: 20px;
                        margin-right: 10px;
                      }
                    }
                    .row-info {
                      font-size: 12px;
                      line-height: 24px;
                      color: #333333;
                      margin-bottom: 8px;
                      span.row-title {
                        display: inline-block;
                        width: 100px;
                        text-align: right;
                        margin-right: 10px;
                      }
                    }
                    .edit-footer {
                      padding: 10px 0;
                      position: absolute;
                      left: 0;
                      right: 0;
                      border-top: 1px solid #e5e5e5;
                      text-align: right;
                      .ant-btn {
                        margin-right: 20px;
                      }
                    }
                    .padding-div {
                      padding-bottom: 35px;
                    }
                  }
                  .name {
                    font-size: 16px;
                    margin-bottom: 11px;
                    .left-label {
                      margin-left: 62px;
                      float: left;
                    }
                  }
                  .ant-checkbox-group-item {
                    margin-right: 20px;
                  }
                  .ant-form-item {
                    margin-bottom: 5px;
                  }
                  .left-label,
                  .ant-form-item,
                  .ant-form-item label,
                  .ant-form-item input {
                    font-size: 12px;
                  }
                }
              }
            `}
          </style>
        </Modal>
      );
    }
  }
);

export default RecommendDialog;
