import React, { PureComponent } from "react";
import { connect } from "react-redux";
import {
  Select,
  Input,
  Icon,
  Tooltip,
  Popconfirm,
  Popover,
  message
} from "antd";
import CityCascade from "components/CityCascade";
import * as companyService from "services/companyService";
import AlterationDialog from "./AlterationDialog";
import CustomIcon from "components/CustomIcon";
import PositionVacantModal from "store/reducers/positionVacantM";
import CompanyDetail from "pages/company/companyDetail.js";
import companyModel from "store/reducers/companyModel";
import global from "store/reducers/global";
import { isCollect } from "services/positionS";

const Option = Select.Option;

const statusColor = ["", "#fa9965", "#38a4e9", "#ff4b54", "#999999", "#999999"];
//1.待分配 2.进行中 3.已暂停 4.成功关闭 5.客户取消
const optionPositionStatus = [
  {
    label: "进行中",
    value: 2
  },
  {
    label: "已暂停",
    value: 3
  },
  {
    label: "成功关闭",
    value: 4
  },
  {
    label: "客户取消",
    value: 5
  }
];
const statusIcon = {
  0: "icon-icon_zt",
  1: "icon-icon_jxz",
  2: "icon-icon_zt",
  3: "icon-icon_wc"
};

/**
 * 职位详情头
 */
@connect(
  ({ PositionVacantModal, user }) => {
    return { ...PositionVacantModal, roles: user.roles };
  },
  {
    ...PositionVacantModal.actions,
    updateStack: global.actions.updateDrawerStack,
    updateField: companyModel.actions.updateField
  }
)
export default class DetailHead extends PureComponent {
  onChangePositionStatus = (val, option) => {
    const { positionDetail = {} } = this.props;
    if (positionDetail.status === val) return;
    this.props.updateReduxHead({ openStatus: val });
  };
  confirm = () => {
    const { getDetail, positionDetail = {} } = this.props;
    let params = {
      jd_id: positionDetail.id
    };
    isCollect(params).then(res => {
      if (res.code === 0) getDetail(positionDetail.id);
    });
  };
  //操作职位发布
  handleSend = record => {
    if (record.status !== 2) {
      message.warning("必须让职位在进行中才可操作！");
      return;
    }
    this.props.setUpdateState({
      filed: "optimalInfo",
      value: {
        ...this.props.optimalInfo,
        isOpen: !this.props.optimalInfo.isOpen
      }
    });
  };
  openCompany = () => {
    const { updateStack, positionDetail } = this.props;
    const { is_gbox_public } = positionDetail;
    if (is_gbox_public) return;
    updateStack({
      type: "add",
      componentRef: CompanyDetail,
      componentProps: {
        currentType: "1",
        isType: "1",
        onClose: () => updateStack({ type: "pop" }),
        stackVisible: true,
        companyId: positionDetail.company.id
      }
    });
  }
  render() {
    const { positionDetail = {}, statusParam, headStatus, roles } = this.props;
    const { publish_info = {}, id, is_gbox_public } = positionDetail;
    const { openStatus, nowStatus } = headStatus;
    const { base_info = {}, qr_code } = publish_info;
    return (
      <React.Fragment>
        <div className="detail-head">
          <div className="position-show-head base-info single-drawer-head">
            <div className="sel-position">
              {(statusParam.type === "1" ||
                (statusParam.type === "0" && roles === "admin")) && (
                <Select
                  style={{ width: "200px" }}
                  placeholder="切换状态"
                  onSelect={this.onChangePositionStatus}
                  value={nowStatus}
                >
                  {optionPositionStatus.map((item, index) => {
                    if (positionDetail.status === 4 && item.value === 5)
                      return null;
                    if (positionDetail.status === 5 && item.value === 4)
                      return null;
                    return (
                      <Option key={index} value={item.value}>
                        {item.label}
                      </Option>
                    );
                  })}
                </Select>
              )}
              {statusParam.type === "0" && roles !== "admin" && (
                <span>
                  <span
                    className="dot"
                    style={{
                      background: statusColor[positionDetail.status]
                    }}
                  />
                  {positionDetail.status_name}
                </span>
              )}
              <div />
              <div className="icon-handle">
                <Popconfirm
                  placement="left"
                  title={`${
                    positionDetail.is_collect === 1
                      ? "取消后，已收藏的职位不在显示"
                      : "确认收藏吗"
                  }`}
                  onConfirm={this.confirm}
                  okText="确定"
                  cancelText="取消"
                >
                  <span
                    className="collect-poisition"
                    style={{ cursor: "pointer", marginRight: "10px" }}
                    title="收藏职位"
                  >
                    {positionDetail.is_collect === 1 ? (
                      <Icon
                        type="heart"
                        theme="filled"
                        twoToneColor="#f03a50"
                        style={{
                          color: "#f03a50",
                          fontSize: "20px",
                          verticalAlign: "middle"
                        }}
                      />
                    ) : (
                      <Icon
                        type="heart"
                        style={{ fontSize: "20px", verticalAlign: "middle" }}
                      />
                    )}
                  </span>
                </Popconfirm>
                {positionDetail.origin !== 3 && (
                  <span>
                    <span className="line" />
                    {base_info.publish_status === 2 ||
                    !base_info.publish_status ? (
                      <Popconfirm
                        placement="left"
                        title="该职位尚未发布到即派优职，是否发布"
                        onConfirm={() => this.handleSend(positionDetail)}
                        okText="确定"
                        cancelText="取消"
                      >
                        <CustomIcon
                          className="youzhi-sync"
                          type={`${statusIcon[base_info.publish_status || 0]}`}
                          style={{ fontSize: 20, verticalAlign: "bottom" }}
                        />
                      </Popconfirm>
                    ) : (
                      <Popover
                        placement="left"
                        content={
                          <div>
                            <p
                              style={{
                                textAlign: "center",
                                margin: "0  auto 10px"
                              }}
                            >
                              <img
                                style={{ width: "138px", height: "138px" }}
                                src={
                                  qr_code
                                    ? qr_code
                                    : require("assets/imgs/icon-reviewing.png")
                                }
                              />
                            </p>
                            {base_info.publish_status === 1 && (
                              <p
                                style={{
                                  textAlign: "center"
                                }}
                              >
                                发布审核中
                              </p>
                            )}
                            {base_info.publish_status === 3 && (
                              <p
                                style={{
                                  textAlign: "center"
                                }}
                              >
                                扫码分享职位
                              </p>
                            )}
                          </div>
                        }
                      >
                        <CustomIcon
                          className="youzhi-sync"
                          type={`${statusIcon[base_info.publish_status || 0]}`}
                          style={{ fontSize: 20, verticalAlign: "bottom" }}
                        />
                      </Popover>
                    )}
                  </span>
                )}
              </div>
            </div>
            <div>
              <b>
                {positionDetail.post_name}
                {positionDetail.post_name &&
                (positionDetail.require_num || positionDetail.require_num === 0)
                  ? "/"
                  : ""}
                {positionDetail.require_num && (
                  <span>{positionDetail.require_num}人</span>
                )}
              </b>
              <span className="number" style={{ verticalAlign: "inherit" }}>
                No. {positionDetail.jd_no}
              </span>
            </div>
            <div>
              <span
                style={{ color: "#1890ff", cursor: "pointer" }}
                onClick={this.openCompany}
              >
                <CustomIcon
                  type="icon-gongsixinxi"
                  style={{
                    fontSize: 20,
                    verticalAlign: "bottom",
                    color: "#cccccc",
                    margin: "0 5px 0 0"
                  }}
                />
                {(positionDetail.company || {}).name}
              </span>
              <Tooltip
                title={
                  (positionDetail.company || {}).type === 4
                    ? "合同"
                    : (positionDetail.company || {}).type === 3
                    ? "待归档"
                    : "合同取消"
                }
              >
                <svg className="icon" aria-hidden="true">
                  {(positionDetail.company || {}).type === 4 && (
                    <use xlinkHref="#icon-attestation" />
                  )}
                  {(positionDetail.company || {}).type === 3 && (
                    <use xlinkHref="#icon-no-attestation" />
                  )}
                  {(positionDetail.company || {}).type === 5 && (
                    <use xlinkHref="#icon-quxiao" />
                  )}
                </svg>
              </Tooltip>
              {!!is_gbox_public && <span className="is-gbox-public">
                <CustomIcon type="icon-gongkaiwangzhi"
                  style={{
                    fontSize: 18,
                    verticalAlign: "bottom",
                    color: "#cccccc",
                    margin: "0 8px 0 0"
                  }}
                  />
                已向客户公开职位进展
              </span>}
            </div>
            {!!openStatus && <AlterationDialog />}
          </div>
        </div>

        <style jsx global>
          {`
            .detail-head {
              .position-show-head {
                position: relative;
                .icon {
                  width: 1em;
                  height: 1em;
                  vertical-align: -0.15em;
                  fill: currentColor;
                  overflow: hidden;
                  margin-left: 15px;
                }
                .sel-position {
                  position: absolute;
                  right: 24px;
                  top: 20px;
                  height: 80px;
                  .dot {
                    width: 6px;
                    height: 6px;
                    border-radius: 50%;
                    display: inline-block;
                    margin-right: 10px;
                    vertical-align: middle;
                  }
                  :global(.ant-select-lg) {
                    font-size: 14px;
                  }
                }
                .icon-handle {
                  min-width: 80px;
                  position: absolute;
                  right: 0;
                  bottom: 15px;
                  text-align: right;
                }
                .line {
                  width: 1px;
                  height: 20px;
                  background: #cccccc;
                  display: inline-block;
                  vertical-align: bottom;
                  margin-right: 3px;
                }
              }
              .is-gbox-public {
                margin-left: 25px;
              }
            }
          `}
        </style>
      </React.Fragment>
    );
  }
}
