import React, { PureComponent } from "react";
import PropTypes from 'prop-types';
import { Steps, Hints } from "components/intro.js-react";
import "intro.js/introjs.css";
/**
 * 因为intro.js有一个，steps只有一条会报错的bug，暂时先把代码移到内部
 */
export default class StepIntro extends PureComponent {
  state = {
  };

  exitStep = () => {
    const {name} = this.props;
    let stepintro = localStorage.getItem("stepintro");
    stepintro = stepintro ? JSON.parse(stepintro) : {};
    stepintro[name] = 1;
    localStorage.setItem("stepintro", JSON.stringify(stepintro));
  }

  render() {
    const { steps, stepEnable, name, options } = this.props;
    let stepintro = localStorage.getItem("stepintro");
    stepintro = stepintro ? JSON.parse(stepintro) : {};
    return (
      <React.Fragment>
        <Steps
          enabled={stepEnable && stepintro[name] !== 1}
          steps={steps}
          initialStep={0}
          options={{
            nextLabel: "下一个",
            prevLabel: "上一个",
            doneLabel: "完成",
            skipLabel: "跳过",
            showStepNumbers: false,
            exitOnOverlayClick: false,
            showBullets: false,
            ...options
          }}
          ref={steps => (this.steps = steps)}
          onExit={this.exitStep}
          />
        <style jsx>{`
        `}</style>
      </React.Fragment>
    );
  }
}

export class HintsIntro extends PureComponent {
  state = {
  };

  exitStep = () => {
    const {name} = this.props;
    let stepintro = localStorage.getItem("stepintro");
    stepintro = stepintro ? JSON.parse(stepintro) : {};
    stepintro[name] = 1;
    localStorage.setItem("stepintro", JSON.stringify(stepintro));
  }

  render() {
    const { steps, stepEnable, name, options } = this.props;
    let stepintro = localStorage.getItem("stepintro");
    stepintro = stepintro ? JSON.parse(stepintro) : {};
    return (
      <React.Fragment>
        <Hints
          enabled={stepEnable && stepintro[name] !== 1}
          hints={steps}
          initialStep={0}
          options={{
            nextLabel: "下一个",
            prevLabel: "上一个",
            doneLabel: "完成",
            skipLabel: "跳过",
            showStepNumbers: false,
            exitOnOverlayClick: false,
            wrapper: ".detail-position-source",
            showBullets: false,
            ...options
          }}
          ref={hints => (this.hints = hints)}
          onExit={this.exitStep}
          />
        <style jsx>{`
        `}</style>
      </React.Fragment>
    );
  }
}

StepIntro.propTypes = {
  name: PropTypes.string,
  steps: PropTypes.array,
  stepEnable: PropTypes.bool,
};