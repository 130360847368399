import _ from "underscore";
import _difference from "lodash/difference";
// 数组多项移除
export function removeItems(indexs, array) {
  if (indexs.length == 0) {
    return array;
  }
  array.splice(indexs[0], 1);
  indexs.splice(0, 1);
  return removeItems(indexs, array);
}

export function getScrollbarWidth() {
  var scrollP = document.createElement("p"),
    styles = {
      width: "100px",
      height: "100px",
      overflowY: "scroll",
    },
    i,
    scrollbarWidth;
  scrollP.setAttribute("id", "scrollP_frame");
  for (i in styles) scrollP.style[i] = styles[i];
  document.body.appendChild(scrollP);
  scrollbarWidth = scrollP.offsetWidth - scrollP.clientWidth;
  if (scrollP) {
    scrollP.parentNode.removeChild(scrollP);
  }
  return scrollbarWidth;
}

export var moment = require("moment");
moment.locale("zh-cn", {
  months:
    "一月_二月_三月_四月_五月_六月_七月_八月_九月_十月_十一月_十二月".split(
      "_"
    ),
  monthsShort: "1月_2月_3月_4月_5月_6月_7月_8月_9月_10月_11月_12月".split("_"),
  weekdays: "星期日_星期一_星期二_星期三_星期四_星期五_星期六".split("_"),
  weekdaysShort: "周日_周一_周二_周三_周四_周五_周六".split("_"),
  weekdaysMin: "日_一_二_三_四_五_六".split("_"),
  longDateFormat: {
    LT: "HH:mm",
    LTS: "HH:mm:ss",
    L: "YYYY年MMMD日",
    LL: "YYYY年MMMD日",
    LLL: "YYYY年MMMD日Ah点mm分",
    LLLL: "YYYY年MMMD日ddddAh点mm分",
    l: "YYYY年MMMD日",
    ll: "YYYY年MMMD日",
    lll: "YYYY年MMMD日 HH:mm",
    llll: "YYYY年MMMD日dddd HH:mm",
  },
  meridiemParse: /凌晨|早上|上午|中午|下午|晚上/,
  meridiemHour: function (hour, meridiem) {
    if (hour === 12) {
      hour = 0;
    }
    if (meridiem === "凌晨" || meridiem === "早上" || meridiem === "上午") {
      return hour;
    } else if (meridiem === "下午" || meridiem === "晚上") {
      return hour + 12;
    } else {
      // '中午'
      return hour >= 11 ? hour : hour + 12;
    }
  },
  meridiem: function (hour, minute, isLower) {
    var hm = hour * 100 + minute;
    if (hm < 600) {
      return "凌晨";
    } else if (hm < 900) {
      return "早上";
    } else if (hm < 1130) {
      return "上午";
    } else if (hm < 1230) {
      return "中午";
    } else if (hm < 1800) {
      return "下午";
    } else {
      return "晚上";
    }
  },
  calendar: {
    sameDay: "[今天]LT",
    nextDay: "[明天]LT",
    nextWeek: "[下]ddddLT",
    lastDay: "[昨天]LT",
    lastWeek: "[上]ddddLT",
    sameElse: "L",
  },
  dayOfMonthOrdinalParse: /\d{1,2}(日|月|周)/,
  ordinal: function (number, period) {
    switch (period) {
      case "d":
      case "D":
      case "DDD":
        return number + "日";
      case "M":
        return number + "月";
      case "w":
      case "W":
        return number + "周";
      default:
        return number;
    }
  },
  relativeTime: {
    future: "%s内",
    past: "%s前",
    s: "几秒",
    m: "1 分钟",
    mm: "%d 分钟",
    h: "1 小时",
    hh: "%d 小时",
    d: "1 天",
    dd: "%d 天",
    M: "1 个月",
    MM: "%d 个月",
    y: "1 年",
    yy: "%d 年",
  },
  week: {
    // GB/T 7408-1994《数据元和交换格式·信息交换·日期和时间表示法》与ISO 8601:1988等效
    dow: 1, // Monday is the first day of the week.
    doy: 4, // The week that contains Jan 4th is the first week of the year.
  },
});

//过滤对象，用户向后台传参转换
export function filterObject(keyMaps, sourceObj) {
  let targetObj = {};
  for (const sourceKey of Object.keys(keyMaps)) {
    const targetFieldConfig = keyMaps[sourceKey];
    const targetKey = targetFieldConfig[0];
    const targetValueType = targetFieldConfig[1];
    if (!targetValueType) {
      //默认为String
      targetObj[targetKey] = String(sourceObj[sourceKey]);
    } else if (targetValueType === "int") {
      targetObj[targetKey] = parseInt(sourceObj[sourceKey]);
    }
  }
  return targetObj;
}

// a download url but not support IE9
export function downloadFile(data, filename, mime) {
  var blob = new Blob([data], { type: mime || "application/octet-stream" });
  if (typeof window.navigator.msSaveBlob !== "undefined") {
    // IE workaround for "HTML7007: One or more blob URLs were
    // revoked by closing the blob for which they were created.
    // These URLs will no longer resolve as the data backing
    // the URL has been freed."
    window.navigator.msSaveBlob(blob, filename);
  } else {
    var blobURL = window.URL.createObjectURL(blob);
    var tempLink = document.createElement("a");
    tempLink.style.display = "none";
    tempLink.href = blobURL;
    tempLink.setAttribute("download", filename);

    // Safari thinks _blank anchor are pop ups. We only want to set _blank
    // target if the browser does not support the HTML5 download attribute.
    // This allows you to download files in desktop safari if pop up blocking
    // is enabled.
    if (typeof tempLink.download === "undefined") {
      tempLink.setAttribute("target", "_blank");
    }

    document.body.appendChild(tempLink);
    tempLink.click();
    document.body.removeChild(tempLink);
    window.URL.revokeObjectURL(blobURL);
  }
}

export function fixedZero(val) {
  return val * 1 < 10 ? `0${val}` : val;
}

export function getTimeDistance(type) {
  const now = new Date();
  const oneDay = 1000 * 60 * 60 * 24;

  if (type === "today") {
    now.setHours(0);
    now.setMinutes(0);
    now.setSeconds(0);
    return [moment(now), moment(now.getTime() + (oneDay - 1000))];
  }

  if (type === "week") {
    let day = now.getDay();
    now.setHours(0);
    now.setMinutes(0);
    now.setSeconds(0);

    if (day === 0) {
      day = 6;
    } else {
      day -= 1;
    }

    const beginTime = now.getTime() - day * oneDay;

    return [moment(beginTime), moment(beginTime + (7 * oneDay - 1000))];
  }

  if (type === "month") {
    const year = now.getFullYear();
    const month = now.getMonth();
    const nextDate = moment(now).add(1, "months");
    const nextYear = nextDate.year();
    const nextMonth = nextDate.month();
    return [
      moment(`${year}-${fixedZero(month + 1)}-01 00:00:00`),
      // moment(moment(`${nextYear}-${fixedZero(nextMonth + 1)}-01 00:00:00`).valueOf() - 1000),//这个是截止当月
      moment(now), //截止当前的时间
    ];
  }
  if (type === "custom") {
    return [moment().day(-2), moment().day(4)];
  }

  const year = now.getFullYear();
  return [moment(`${year}-01-01 00:00:00`), moment(`${year}-12-31 23:59:59`)];
}
export function debounce(func, wait, immediate) {
  let timeout;

  return () => {
    let context = this,
      args = arguments;
    let later = () => {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    let callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

/**
 * @description 递归选项进行查找选中的type
 * @param {array} array  所有操作的数组
 * @param {number} id  所有选中的id
 */

export function getTypeName(array = [], id) {
  if (!array || !array.length) return;
  let typeConvert = null;
  array.some((item) => {
    const child = item.list;
    if (item.id === id) {
      typeConvert = { key: item.type, id: id };
      return true;
    }
    // 如果还有子节点，执行递归
    if (child && child.length > 0) {
      typeConvert = getTypeName(child, id);
      return typeConvert;
    }
  });
  return typeConvert;
}

// function getPath(valueMap, valueField) {
//   const path = [];
//   let current = valueMap[valueField];
//   while (current) {
//     path.unshift(current[valueField]);
//     current = current.parent;
//   }
//   return path;
// }

/**
 * @description 转换树节点
 */
export function transformTree(
  array = [],
  field = ["id", "name", "list"],
  parent
) {
  if (!array || !array.length) return [];
  return array.map((item) => {
    let typeConvert = null;
    const child = item[field[2]];
    // 如果还有子节点，执行递归
    if (child && child.length > 0) {
      let parentItem = {};
      parentItem[field[0]] = item[field[0]];
      parentItem[field[1]] = item[field[1]];
      parentItem.parent = parent;
      parentItem.parentLabel =
        (parent && parent[field[1]] ? parent[field[1]] : "") + item[field[1]];
      typeConvert = transformTree(child, field, parentItem);
    }
    return {
      value: item[field[0]],
      title: item[field[1]],
      parent: parent,
      parentLabel: parent ? parent.parentLabel + item[field[1]] : "",
      a_id: item.a_id ? item.a_id : item[field[0]],
      a_name: item.a_name ? item.a_name : item[field[1]],
      // parentValue: parent,
      children: typeConvert,
    };
  });
}

export function transformTreeOptions(
  array = [],
  options,
  field = ["id", "name", "list"]
) {
  if (!array || !array.length) return [];
  return array
    .filter((item) => {
      if (options.removeType !== undefined) {
        if (options.removeType === item.type) {
          return false;
        }
      }
      return true;
    })
    .map((item) => {
      let typeConvert = null;
      const child = item[field[2]];
      // 如果还有子节点，执行递归
      if (child && child.length > 0) {
        typeConvert = transformTreeOptions(child, options, field);
      }

      return {
        value: item[field[0]],
        title: item[field[1]],
        children: typeConvert,
        selectable: options.selectType
          ? options.selectType === item.type
          : true,
      };
    });
}

export function transformTreeOptionsOnlyChild(
  array = [],
  options,
  field = ["id", "name", "list"]
) {
  if (!array || !array.length) return [];
  return array.map((item) => {
    let typeConvert = null;
    const child = item[field[2]];
    // 如果还有子节点，执行递归
    if (child && child.length > 0) {
      typeConvert = transformTreeOptions(child, options, field);
    }

    return {
      value: item[field[0]],
      title: item[field[1]],
      children: typeConvert,
      disabled: child ? true : false,
    };
  });
}

export function transformHasAllTreeOptions(
  array = [],
  options,
  field = ["id", "name", "list"],
  selectedOptions = []
) {
  if (!array || !array.length) return [];
  return array.map((item) => {
    let typeConvert = null;
    const child = item[field[2]];
    // 将标有全部节点的值过滤出来
    const totalOptions = child.filter((item) => item.label === "全部");
    let isAllSelected = false;
    totalOptions.forEach((item) => {
      if (selectedOptions.indexOf(item.value) > -1) {
        isAllSelected = true;
      }
    });
    // 如果当前选择的选项中刚好有全部节点中对应的值 则将除全部选项之外的选项禁用
    // 如果还有子节点，执行递归
    if (child && child.length > 0) {
      typeConvert = transformTreeOptions(child, options, field);
      typeConvert = typeConvert.map((item) => {
        if (isAllSelected) {
          if (item.title !== "全部") {
            item.disableCheckbox = true;
          }
        }
        return item;
      });
    }
    return {
      value: `p${item[field[0]]}`,
      title: item[field[1]],
      children: typeConvert,
      disabled: child ? true : false,
      disableCheckbox: true,
      checkable: false,
    };
  });
}

/**
 * 如果options中包含某一项全部的值 则在treeData中获取该类别的全部子项 并将options中的除全部项删除
 * @param {*} treeData
 * @param {*} options
 */
export function filterExceptAllOption(treeData, options = []) {
  let result = options;
  treeData.forEach((item) => {
    // 如果选项中包含有某一个领域的全部对象
    if (options.indexOf(item.value) > -1) {
      const { list } = item;
      const listValue = list.map((i) => i.value);
      result = _difference(options, listValue);
      result.push(item.value);
    }
  });
  return result;
}

//转换参数，用户向后台传参转换
export function convertParams(sourceObj) {
  let alls = {};
  _.keys(sourceObj).map((name) => {
    if (_.isArray(sourceObj[name])) {
      let arrays = sourceObj[name].map((item) => {
        let array = [];
        array.push(item ? item.value : undefined);
        alls[item.name] = array;
      });
    } else {
      alls[name] = sourceObj[name] ? sourceObj[name].value : undefined;
    }
  });
  return alls;
}
export function setDisabledOption(data = [], name = "官网") {
  return data.map((item) => {
    if (item.label === name) {
      item.disabled = true;
      item.list &&
        item.list.forEach((itemChild) => {
          itemChild.disabled = true;
        });
    }
    return item;
  });
}
// 判断是否有中文
export function isChinese(temp) {
  return /.*[\u4e00-\u9fa5]+.*$/.test(temp);
}

export function isChineseSymbol(temp) {
  return /[\u3002\uff1b\uff0c\uff1a\u201c\u201d\uff08\uff09\u3001\uff1f\u300a\u300b]/.test(
    temp
  );
}

export function splitContentToLine(item, lineWordsNumber, maxline) {
  let currentLineWordsNumber = 0,
    currentLine = "";
  let lines = [];
  for (let index = 0; index < item.length; index++) {
    let word = item[index];
    if (currentLineWordsNumber >= lineWordsNumber) {
      lines.push(currentLine);
      currentLineWordsNumber = 0;
      currentLine = "";
    }
    currentLine += word + "";
    if (isChinese(word) || isChineseSymbol(word)) {
      currentLineWordsNumber++;
    } else {
      currentLineWordsNumber += 0.5;
    }
  }
  if (currentLine) {
    lines.push(currentLine);
    currentLineWordsNumber = 0;
    currentLine = "";
  }
  let lineLength = lines.length;
  if (maxline && lineLength > maxline) {
    lines.splice(maxline, lineLength - maxline);
    lines[maxline - 1] = lines[maxline - 1].replace(/.{2}$/, "...");
  }
  return lines;
}

export function transformCommon(data) {
  return data.map((item, index) => {
    const result = item.list
      .filter((v) => v.type !== 1)
      .map((v) => {
        return {
          title: v.label,
          value: v.value,
        };
      });
    return {
      title: item.label,
      value: item.value,
      children: result,
    };
  });
}
