import React from "react";
import { Switch, Route } from "react-router-dom";
import Loadable from 'react-loadable';
import nprogress from "nprogress";
import "nprogress/nprogress.css";
// import BannerManage from "pages/bannerManage/bannerManage";
// import ArticleManage from "pages/articleManage/articleManage";
// import UserFeedback from "pages/UserFeedback/UserFeedback";
// import MessagePush from "pages/messagePush/messagePush";
// import UserManage from "pages/userManage/userManage";
// import OrganizationManage from "pages/OrganizationManage/OrganizationManage";
// import OrganizationManageEdit from "pages/OrganizationManage/OrganizationManageEdit";
import Company from "pages/company/company";
// import Business from "pages/business/business";
// import Clue from "pages/clue/clue";
// import Center from "pages/user/Center";
import UserLogin from "pages/user/login";
import ForgetPassword from 'pages/user/ForgetPwd'
// import Exception404 from "pages/exception/404";

// import PositionVacant from "pages/PositionVacant";
// import TalentPool from "pages/TalentPool";
// import TalentCreateImport from "pages/TalentPool/CreateImport/CreateImport";
// import CandidateAudit from "pages/CandidateAudit";
// import PositionAllot from "pages/PositionAllot";
// import DataCenterPage from "pages/DataCenter/Index";
// import Recommend from "pages/PositionVacant/recommendCandidate";
// import OptimalResumeDetail from "pages/PositionVacant/optimalResumeDetail";
// import SysMessage from "pages/sysmessage/sysmessage";
// import WorkBench from "pages/workBench";
// import Channel from "pages/channel/channel";
// import GBox from "pages/gbox/gbox"
import CustomIcon from "components/CustomIcon";
import GeeLayout from "layout/GeeLayout";
import UserLayout from "layout/UserLayout";
import { getAuthority } from "./utils/authority";

// 使用component, render渲染子路由需要单独写renderroute
// config---name侧边菜单显示用，不配置不会显示，submenu会使path无效
// config---hideInMenu可以隐藏侧边菜单
// config---hideChildrenInMenu可以隐藏整个子级侧边菜单
// "admin",
// "business",
// "city_manager",
// "consultant",//顾问
// "channel", //渠道
// "operator", //运营
// "team_leader"
// "group_leader"
// consultant360 360顾问
// special_group_leader 特殊leader
const authorities = ["admin"];
const positionAuth = [
  "admin",
  "consultant",
  "consultant360",
  "channel",
  "city_manager",
  "team_leader",
  "group_leader",
  "special_group_leader"
];
const positionAllotAuth = [
  "admin",
  "city_manager",
  "team_leader",
  "group_leader"
];
const centerDataAuth = [
  "admin",
  "consultant",
  "consultant360",
  "city_manager",
  "team_leader",
  "group_leader",
  "special_group_leader"
];
const candidateAuth = [
  "admin",
  "business",
  "city_manager",
  "consultant",
  "consultant360",
  "channel",
  "operator",
  "team_leader",
  "group_leader",
  "special_group_leader"
];
const candidateAuditAuth = ["admin", "team_leader", "group_leader"];
const customAuth = [
  "admin",
  "business",
  "city_manager",
  "consultant",
  "consultant360",
  "channel",
  "operator",
  "team_leader",
  "group_leader"
];
const customClueAuth = [
  "admin",
  "business",
  "city_manager",
  "operator",
  "team_leader",
  "group_leader",
  "consultant",
  "consultant360",
  "channel"
];
const customBusinessAuth = [
  "admin",
  "business",
  "city_manager",
  "consultant360",
  "operator",
  "team_leader",
  "group_leader"
];
const customCompanyAuth = [
  "admin",
  "business",
  "city_manager",
  "consultant",
  "consultant360",
  "channel",
  "team_leader",
  "group_leader"
];
const systemAuth = [
  "admin",
  "business",
  "city_manager",
  "consultant",
  "consultant360",
  "channel",
  "operator",
  "team_leader",
  "group_leader"
];
const gboxAuth = [
  "admin",
  "operator",
  // "business",
  // "channel",
  // "consultant360",
  // "city_manager",
  // "team_leader",
  // "group_leader"
];
const operateAuth = ["admin", "city_manager", "operator"];
const centerAuth = [
  "admin",
  "business",
  "city_manager",
  "consultant",
  "consultant360",
  "channel",
  "operator",
  "team_leader",
  "group_leader",
  "special_group_leader",
  "soonfit_role"
];
const messageAuth = [
  "admin",
  "business",
  "city_manager",
  "consultant",
  "consultant360",
  "channel",
  "operator",
  "team_leader",
  "group_leader",
  "special_group_leader"
];
const workBenchAuth = [
  "admin",
  "business",
  "city_manager",
  "consultant",
  "consultant360",
  "channel",
  "operator",
  "team_leader",
  "group_leader",
  "special_group_leader"
];
const talentPoolCopyAuth = [
  "soonfit_role",
];

const userAuth = getAuthority().join()

const loadings = () => {
  nprogress.start();
  nprogress.set(0.4);
  return null;
};
const render = (loaded, props) => {
  console.log("loaded", loaded)
  let Component = loaded.default;
  nprogress.done();
  return <Component {...props} />;
}
// const WorkBenchLazy = lazy(() => import('pages/workBench'));
const WorkBench = Loadable({
  loader: () => import(/* webpackChunkName: "work_bench" */ 'pages/workBench'),
  loading: loadings, render: render
});
const BannerManage = Loadable({
  loader: () => import(/* webpackChunkName: "banner_manage" */ 'pages/bannerManage/bannerManage'),
  loading: loadings, render: render
});
const ArticleManage = Loadable({
  loader: () => import(/* webpackChunkName: "article" */ 'pages/articleManage/article'),
  loading: loadings, render: render
});
const UserFeedback = Loadable({
  loader: () => import(/* webpackChunkName: "UserFeedback" */ 'pages/UserFeedback/UserFeedback'),
  loading: loadings, render: render
});
const MessagePush = Loadable({
  loader: () => import(/* webpackChunkName: "messagePush" */ 'pages/messagePush/messagePush'),
  loading: loadings, render: render
});
const UserManage = Loadable({
  loader: () => import(/* webpackChunkName: "userManage" */ 'pages/userManage/userManage'),
  loading: loadings, render: render
});
const OrganizationManage = Loadable({
  loader: () => import(/* webpackChunkName: "OrganizationManage" */ 'pages/OrganizationManage/OrganizationManage'),
  loading: loadings, render: render
});
const OrganizationManageEdit = Loadable({
  loader: () => import(/* webpackChunkName: "OrganizationManageEdit" */ 'pages/OrganizationManage/OrganizationManageEdit'),
  loading: loadings, render: render
});
// const Company = Loadable({
//   loader: () => import(/* webpackChunkName: "company" */ 'pages/company/company'),
//   loading: loadings,   render: render
// });
const Business = Loadable({
  loader: () => import(/* webpackChunkName: "business" */ 'pages/business/business'),
  loading: loadings, render: render
});
const Clue = Loadable({
  loader: () => import(/* webpackChunkName: "clue" */ 'pages/clue/clue'),
  loading: loadings, render: render
});
const Center = Loadable({
  loader: () => import(/* webpackChunkName: "Center" */ 'pages/user/Center'),
  loading: loadings, render: render
});
// const UserLogin = Loadable({
//   loader: () => import(/* webpackChunkName: "login" */ 'pages/user/login'),
//   loading: loadings,   render: render
// });
const Exception404 = Loadable({
  loader: () => import(/* webpackChunkName: "404" */ 'pages/exception/404'),
  loading: loadings, render: render
});

const TalentPool = Loadable({
  loader: () => import(/* webpackChunkName: "TalentPool" */ 'pages/TalentPool'),
  loading: loadings, render: render
});
const SoonfitTalentPool = Loadable({
  loader: () => import(/* webpackChunkName: "TalentPool" */ 'pages/SoonfitTalentPool'),
  loading: loadings, render: render
});
const PositionVacant = Loadable({
  loader: () => import(/* webpackChunkName: "PositionVacant" */ 'pages/PositionVacant'),
  loading: loadings, render: render
});
const TalentCreateImport = Loadable({
  loader: () => import(/* webpackChunkName: "CreateImport" */ 'pages/TalentPool/CreateImport/CreateImport'),
  loading: loadings, render: render
});
const CandidateAudit = Loadable({
  loader: () => import(/* webpackChunkName: "CandidateAudit" */ 'pages/CandidateAudit'),
  loading: loadings, render: render
});
const PositionAllot = Loadable({
  loader: () => import(/* webpackChunkName: "PositionAllot" */ 'pages/PositionAllot'),
  loading: loadings, render: render
});
const DataCenterPage = Loadable({
  loader: () => import(/* webpackChunkName: "DataCenter" */ 'pages/DataCenter/Index'),
  loading: loadings, render: render
});
const Recommend = Loadable({
  loader: () => import(/* webpackChunkName: "recommendCandidate" */ 'pages/PositionVacant/recommendCandidate'),
  loading: loadings, render: render
});
const OptimalResumeDetail = Loadable({
  loader: () => import(/* webpackChunkName: "optimalResumeDetail" */ 'pages/PositionVacant/optimalResumeDetail'),
  loading: loadings, render: render
});
const SysMessage = Loadable({
  loader: () => import(/* webpackChunkName: "sysmessage" */ 'pages/sysmessage/sysmessage'),
  loading: loadings, render: render
});
const Channel = Loadable({
  loader: () => import(/* webpackChunkName: "channel" */ 'pages/channel/channel'),
  loading: loadings, render: render
});
const GBox = Loadable({
  loader: () => import(/* webpackChunkName: "gee" */ 'pages/gbox/gbox'),
  loading: loadings, render: render
});
// const GeeLayout = Loadable({
//   loader: () => import(/* webpackChunkName: "gee" */ 'layout/GeeLayout'),
//   loading: loadings,   render: render
// });
// const UserLayout = Loadable({
//   loader: () => import(/* webpackChunkName: "user" */ 'layout/UserLayout'),
//   loading: loadings,   render: render
// });


const Routes = () => (
  <Switch>
    <Route path="/user" component={UserLayout}>
      <Route path="/user" redirect="/user/login" exact={true} />
      <Route path="/user/login" component={UserLogin} />
      <Route path="/user/forget" component={ForgetPassword} />
    </Route>

    <Route path="/" component={GeeLayout}>
      {/* <Route path="/" authority={authorities}> */}
      <Route path="/" redirect={userAuth === 'soonfit_role' ? '/soonfit' : '/workBench'} exact={true} />
      <Route
        path="/workBench"
        name="首页"
        authority={workBenchAuth}
        component={WorkBench}
        icon={<CustomIcon type="icon-desktop-copy" style={{ fontSize: 15 }} />}
      />

      <Route
        path="/position"
        name="职位管理"
        authority={positionAuth}
        icon={<CustomIcon type="icon-tasklist" style={{ fontSize: 16 }} />}
      >
        <Route
          path="/position/vacant"
          exact={true}
          name="招聘职位"
          component={PositionVacant}
        />
        {/** type为1是前台，0或没有为客户 */}
        <Route
          path="/position/vacant/recommend/:jd_id?/:type?"
          component={Recommend}
        />
        <Route
          path="/position/vacant/optimaldetail/:jd_profile_id?/:jd_id?/:profile_id?/:kh?"
          component={OptimalResumeDetail}
        />
        <Route
          path="/position/allot"
          name="职位待分配"
          authority={positionAllotAuth}
          component={PositionAllot}
        />
      </Route>
      <Route
        path="/candidate"
        name="人选管理"
        authority={candidateAuth}
        icon="team"
      >
        <Route
          path="/candidate"
          redirect="/candidate/talent-pool"
          exact={true}
        />
        <Route
          path="/candidate/talent-pool/create/:type?"
          component={TalentCreateImport}
          exact={true}
        />
        <Route
          path="/candidate/talent-pool"
          component={TalentPool}
          name="人才库"
        />

        <Route
          path="/candidate/audit"
          authority={candidateAuditAuth}
          component={CandidateAudit}
          name="状态审核"
        />
      </Route>
      <Route
        path="/custom"
        name="客户管理"
        authority={customAuth}
        icon="solution"
      >
        <Route path="/custom" redirect="/custom/clue" exact={true} />
        <Route
          authority={customClueAuth}
          path="/custom/clue"
          name="线索池"
          component={Clue}
        />
        <Route
          authority={customBusinessAuth}
          path="/custom/business"
          name="商机"
          component={Business}
        />
        <Route
          authority={customCompanyAuth}
          path="/custom/company"
          name="企业客户"
          component={Company}
        />
      </Route>
      <Route
        path="/system"
        name="系统管理"
        authority={systemAuth}
        icon="setting"
      >
        <Route
          path="/system"
          redirect="/system/operate/usermanage"
          exact={true}
        />
        <Route path="/system/operate" name="运营管理" authority={operateAuth}>
          <Route
            path="/system/operate/usermanage"
            component={UserManage}
            name="用户管理"
          />
          <Route
            path="/system/operate/bannermanage"
            exact
            component={BannerManage}
            name="轮播管理"
          />
          <Route
            path="/system/operate/articlemanage"
            exact
            component={ArticleManage}
            name="文章管理"
          />
          <Route
            path="/system/operate/messagepush"
            exact
            component={MessagePush}
            name="消息推送"
          />
          {/* <Route path="/system/operate/6" name="活动管理" /> */}
          <Route
            path="/system/operate/userfeedback"
            exact
            component={UserFeedback}
            name="用户反馈"
          />
        </Route>
        <Route
          path="/system/organizationmanage"
          exact
          component={OrganizationManage}
          name="顾问管理"
        />
        <Route
          path="/system/channel"
          exact
          component={Channel}
          name="渠道管理"
        />
        <Route
          path="/system/counselor/counselor/edit"
          component={OrganizationManageEdit}
        />
      </Route>
      <Route
        path="/center"
        // name="个人中心"
        authority={centerAuth}
        // icon="user"
        component={Center}
      />

      <Route
        path="/gbox"
        name="禾蛙盒子"
        authority={gboxAuth}
        icon={<CustomIcon type="icon-gee-box-white-copy" style={{ fontSize: 15}} />}
      >
        <Route
          path="/gbox/partyB"
          exact
          component={GBox}
          authority={gboxAuth}
          name="乙方版"
        />
        <Route
          path="/gbox/partyA"
          exact
          component={GBox}
          authority={gboxAuth}
          name="甲方版"
        />
      </Route>
      <Route
        path="/datacenter"
        name="数据中心"
        authority={centerDataAuth}
        icon="database"
        component={DataCenterPage}
      />

      <Route
        path="/soonfit"
        component={SoonfitTalentPool}
        authority={talentPoolCopyAuth}
        icon="team"
        name="人才库"
      />

      <Route path="/message" authority={messageAuth} component={SysMessage} />
      <Route component={Exception404} />
      {/* </Route> */}
    </Route>
  </Switch>
);

export default Routes;
