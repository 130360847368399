import React from "react";
import { Table } from "antd";
import _ from "underscore";
/**
 * @param needRowSel 单行选中样式 true 开启
 */
export default class GeeTable extends React.PureComponent {
  state = { current: -1, columns: [] };

  onRow = (record, index) => {
    const { onRow } = this.props;
    if (!onRow) return;
    const rowObj = onRow(record, index);
    const { onClick, ...rowEvent } = rowObj;

    return {
      ...rowEvent,
      onClick: e => {
        this.setState({ current: index });
        onClick(e);
      }
    };
  };

  /**
   * @description 声明周期事件
   */
  componentWillMount() {
    let columns = this.props.columns.map(item => {
      if (item.needEllipsis) {
        item.render = (value, row, index) => {
          return (
            <div
              className={item.needEllipsis ? "ellipsis-style" : ""}
              style={{ maxWidth: item.maxWidth }}
              title={value}
              onMouseOver={e => {
                this.mounseOverHandle(e);
              }}
            >
              {value}
            </div>
          );
        };
      }
      return item;
    });
    this.setState({ columns });
  }

  componentDidUpdate(prevProps) {
    const { is } = require("immutable");
    if (!is(prevProps.columns, this.props.columns)) {
      let columns = this.props.columns.map(item => {
        if (item.needEllipsis) {
          item.render = (value, row, index) => {
            return (
              <div
                className={item.needEllipsis ? "ellipsis-style" : ""}
                style={{ maxWidth: item.maxWidth }}
                title={value}
                onMouseOver={e => {
                  this.mounseOverHandle(e);
                }}
              >
                {value}
              </div>
            );
          };
        }
        return item;
      });
      this.setState({ columns: columns });
    }
  }

  /**
   * @description 鼠标移上事件
   * @param {Object} e 鼠标移上事件对象
   */
  mounseOverHandle(e) {
    const node = e.target;
    if (node.scrollWidth <= node.clientWidth) {
      // 如果不需要鼠标以上显示所有，则把title属性去掉
      node.removeAttribute("title");
    }
  }

  //
  render() {
    const { onRow, rowClassName = "", needRowSel, ...props } = this.props;
    return (
      <React.Fragment>
        <Table
          {...props}
          rowClassName={(row, index) => {
            if (_.isFunction(rowClassName)) {
              
              return rowClassName(row);
            } else {
              if (index == this.state.current && needRowSel)
                return `${rowClassName} gee-row-sel`;
            }
          }}
          columns={this.state.columns}
          pagination={
            this.props.pagination && {
              ...this.props.pagination,
              pageSizeOptions: ["5", "10", "20", "30"],
              defaultPageSize: 5
            }
          }
          onRow={this.onRow}
        />
      </React.Fragment>
    );
  }
}
