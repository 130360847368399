import xhr from './index.js'

/**
 * 消息推送管理
 */
//消息列表
function messageManageList(param) {
  return xhr({
    url: 'admin/v1/messages',
    body:param
  })
}
//消息新增
function messageManageAdd(param) {
  return xhr({
    url: 'admin/v1/messages',
    body:param,
    method: "post"
  })
}
export {
  messageManageList,messageManageAdd
}