import React, { PureComponent } from "react";
import { InputNumber } from "antd";

export default class InputNumberUnit extends PureComponent {
  render() {
    const { unit, ...props } = this.props;
    return (
      <div className="gee-input-number-unit">
        <InputNumber {...props} />
        <span className="unit">{unit}</span>
      </div>
    );
  }
}
