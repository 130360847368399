import React, { PureComponent } from "react";
import ReactDOM from "react-dom";
import { connect } from "react-redux";
import {
  Tabs,
  Select,
  Input,
  Button,
  Message,
  Drawer,
  Form,
  Icon,
  Collapse,
  Spin,
  Tooltip,
  Popconfirm,
  Popover,
  Badge,
  Radio
} from "antd";
import Table from "components/GeeTable.js";
import { EditViewSingle } from "components/EditView";
import SetPerson from "./setPositionPerson.js";
import PositionCommunicateRecords from "./PositionCommunicateRecords";
import ContactPerson from "./ContactPerson";
import PositionProgress from "./PositionProgress";
import CreatePosition from "./CreatePosition";
import AlterationDialog from "./AlterationDialog";
import { PositionInfoShow, PositionInfoEdit } from "./PositionInfo";
import PositionVacantModal from "store/reducers/positionVacantM";
import user from "store/reducers/user";
import { history } from "root.js";
import styles from "./PositionManage.scss";
import global from "store/reducers/global";
import companyModel from "store/reducers/companyModel";
import CompanyDetail from "pages/company/companyDetail.js";
import { isCollect } from "services/positionS";
import { OptimalPosition } from "./optimalPosition";
import { KhPosition } from "./khPosition";
import CustomIcon from "components/CustomIcon";
import { withRouter } from "react-router";
import _ from "underscore";
import DetailPositionStatus from "./detailPositionStatus";
import ProgressModule from "./progress-module";
import DetailHead from "./detailHead";
import DetailPositionSource from "./detailPositionSource";
import StepIntro, { HintsIntro } from "components/step-intro";
import ic_close from "assets/imgs/ic_close.png";

const TabPane = Tabs.TabPane;
const Option = Select.Option;
const FormItem = Form.Item;
const Panel = Collapse.Panel;
const Search = Input.Search;

/**
 * 职位详情
 */
export default withRouter(
  @connect(
    ({ PositionVacantModal, user, global }) => {
      return {
        ...PositionVacantModal,
        roles: user.roles,
        positionDrawerContainer: global.positionDrawerContainer
      };
    },
    {
      ...PositionVacantModal.actions,
      updateStack: global.actions.updateDrawerStack,
      updateField: companyModel.actions.updateField
    }
  )
  class PositionDetails extends PureComponent {
    modelRef = React.createRef();
    state = {
      loading: false,
      isDot: true,
      channelStatus: 1
    };
    componentDidMount() {
      window.addEventListener("resize", this.handleResize);
      const {
        getDetail,
        getCandidateList,
        getPositionProgress,
        getChats,
        id,
        getCommonData,
        updateReduxHead
      } = this.props;
      /**
       * @description 初始获取窗口的宽度进行设置状态
       */
      this.initSetStatus();
      this.setState({ loading: true });
      setTimeout(() => {
        getCommonData(
          "jd_benefit,cities,jd_salary,jd_salary_type,jd_education,service_type,work_type,jd_source,business_invalid_reason,jd_work_time,dispatch_position,publish_end_option,publish_profile_receive_setting,publish_jd_type,publish_is_open"
        );
        /**
         * @description 初始化查询列表
         */
        if (id) {
          setTimeout(() => {
            getDetail(id).then(res => {
              if (res.code === 0)
                updateReduxHead({ nowStatus: res.data.status });
              getPositionProgress({ jd_id: id });
              getChats({
                expand: "create_time_str,create_by_name",
                jd_id: id,
                "per-page": 10000
              });
              this.setState({ 
                loading: false,
                channelStatus: res.data.origin !== 3 ? 1 : 2
              });
            });
          }, 0);
        }
      }, 0);
    }

    componentDidUpdate(prevProps) {
      const {
        getDetail,
        getCandidateList,
        getPositionProgress,
        getChats,
        stackVisible,
        id,
        updateReduxHead
      } = this.props;
      if (prevProps.stackVisible !== stackVisible && stackVisible) {
        getDetail(id).then(res => {
          if (res.code === 0) updateReduxHead({ nowStatus: res.data.status, channelStatus: res.data.origin !== 3 ? 1 : 2 });
        });
        getPositionProgress({ jd_id: id });
        getChats({
          expand: "create_time_str,create_by_name",
          jd_id: id,
          "per-page": 6
        });
        return;
      }
    }
    /**
     * @description 初始获取窗口的宽度进行设置状态
     */
    initSetStatus = () => {
      if (window.innerWidth > 1580) {
        this.props.updateRedux({ detailWidthStatus: true });
      }
      this.props.updateRedux({ detailHeight: window.innerHeight - 176 });
    };
    /**
     * @description 浏览器窗口变化动态改变状态
     */
    handleResize = e => {
      if (e.target.innerWidth > 1580) {
        this.props.updateRedux({ detailWidthStatus: true });
      } else {
        this.props.updateRedux({ detailWidthStatus: false });
      }
      this.props.updateRedux({ detailHeight: window.innerHeight - 176 });
    };

    /**
     * @description 关闭职位详情抽屉
     */
    onDrawerClose = () => {
      const { updatePosDetailDrawer, onClose } = this.props;
      if (onClose) {
        onClose();
      }
      updatePosDetailDrawer(false);
    };

    componentWillUnmount() {
      this.props.setUpdateState({
        filed: "positionDetail",
        value: {}
      });
      this.props.setUpdateState({
        filed: "detailTab",
        value: undefined
      });
      this.props.setUpdateState({
        filed: "statusParamCandidate",
        value: { status: "-1", "per-page": 10 }
      });
      this.props.setUpdateState({
        filed: "candidateFilterParam",
        value: {}
      });
    }
    /**
     * @description 点击优职如果is_read=1则改成已读
     */
    handleTab = key => {
      const { positionDetail } = this.props;
      let chNewRead = ((positionDetail.ch_publish_info || {}).profile_count || {}).is_new_read || 0;
      let youzhiNewRead = ((positionDetail.publish_info || {}).profile_count || {}).is_new_read || 0;
      // if (
      //   key === "4" && (chNewRead === 1 || youzhiNewRead === 1)
      // ) {
      //   this.setState({ isDot: false });
      // }
    };
    /**
     * @description 切换渠道
     * @param {array} id  所有选中的数组
     */
    typeChange = e => {
      this.setState({ channelStatus: e.target.value });
    };
    /**
     * @description 职位折叠面板头
     */
    positionEditCollapse = () => {
      const { positionDetail = {}, roles, isAll } = this.props;
      return (
        <React.Fragment>
          <div className="position-show-box clear">
            <div className="left">
              <CustomIcon
                type="icon-zhiwei-copy"
                style={{
                  fontSize: 20,
                  verticalAlign: "bottom",
                  paddingRight: "7px"
                }}
              />
              <b> 职位信息</b>
            </div>
            {positionDetail.status === 2 &&
              !(
                (roles === "consultant" || roles === "consultant360" || roles === "channel") &&
                isAll === true
              ) && (
                <div
                  className="right"
                  onClick={e => {
                    e.stopPropagation();
                    this.props.updateRedux({
                      positionEditDrawer: !this.props.positionEditDrawer
                    });
                  }}
                >
                  编辑
                </div>
              )}
          </div>
        </React.Fragment>
      );
    };
    render() {
      const {
        detailWidthStatus,
        positionDetailsDrawer,
        profileNumUpdate,
        positionDetail = {},
        getDetail,
        commonData,
        modPos,
        isAll,
        statusParam,
        roles,
        container,
        positionChat,
        id,
        setUpdateState,
        positionEditDrawer,
        detailHeight = 400,
        fromWorkBench
      } = this.props;
      const { loading } = this.state;
      const { publish_info = {} } = positionDetail;
      const { base_info = {}, qr_code } = publish_info;
      const size = "large";
      const steps = [
        {
          element: ".collect-poisition",
          highlightClass: "position-detail-helper",
          intro:
            "这个小爱心，是收藏的功能，你给收藏了，在你职位就展示了，就方便你下次查看了"
        },
        {
          element: ".left-tabs .ant-tabs-tab:nth-child(2)",
          highlightClass: "position-detail-helper",
          intro: "点击优职投递查看来自即派优职投递的简历"
        },
        {
          element: ".detail-position-source",
          highlightClass: "position-detail-helper",
          intro:
            "我想增加协助顾问怎么增加？打开左上角全部职位，找到这个职位，还在现在这个位置，有个小笔，你给他轻轻的点开"
        }
      ];

      let youzhiCount = 0; // 优职计数
      let chCount = 0;
      let youzhiNewRead = 0; // 优职计数
      let chNewRead = 0;

      if (positionDetail.origin !== 3) {
        steps.splice(1, 0, {
          element: ".youzhi-sync",
          highlightClass: "position-detail-helper",
          intro: "点击“G”可将该职位同步发布到即派优职"
        });
      }
      youzhiCount = ((positionDetail.publish_info || {}).profile_count || {}).count || 0;
      chCount = ((positionDetail.ch_publish_info || {}).profile_count || {}).count || 0;
      youzhiNewRead = ((positionDetail.publish_info || {}).profile_count || {}).is_new_read || 0;
      chNewRead = ((positionDetail.ch_publish_info || {}).profile_count || {}).is_new_read || 0;
      return (
        <Drawer
          width={detailWidthStatus ? 1328 : 1184}
          placement="right"
          className="info-drawer-box single-drawer-box position-details-drawer"
          title={null}
          onClose={this.onDrawerClose}
          visible={true}
          afterVisibleChange={this.visibleChange}
          style={{
            paddingBottom: 53
          }}
          closable={false}
        >
          {/* { positionDetail.id && <StepIntro stepEnable={true} name="positionDetail" steps={steps}/> } */}
          <div className="drawer-body">
            <Spin spinning={loading}>
              <div className="close-drawer-btn" onClick={this.onDrawerClose} />
              <div className="head">
                <DetailHead statusParam={statusParam} />
              </div>

              <div className="content clear">
                <div className="left-show" style={{ width: "774px" }}>
                  <Tabs
                    type="card"
                    className="left-tabs"
                    defaultActiveKey={
                      positionDetail.origin !== 3 && fromWorkBench
                        ? "4"
                        : this.props.detailTab || "1"
                    }
                    onChange={this.handleTab}
                  >
                    <TabPane
                      tab="人选进展"
                      key="1"
                      forceRender={true}
                      className="profile-progress-tabpane"
                    >
                      <div style={{ height: `${detailHeight}px` }}>
                        <ProgressModule />
                      </div>
                    </TabPane>
                    
                    <TabPane
                      forceRender={false}
                      tab={
                        <Badge
                          dot={
                            (youzhiNewRead === 1 || chNewRead === 1) && this.state.isDot
                          }
                        >
                          渠道投递
                            {!!(youzhiCount || chCount) && (
                            <span>
                              （{youzhiCount + chCount}）
                              </span>
                          )}
                        </Badge>
                      }
                      key="4"
                    >
                      <Radio.Group
                        value={this.state.channelStatus}
                        buttonStyle="solid"
                        onChange={this.typeChange}
                        style={{ margin: "0 0 20px 20px" }}
                      >
                        {positionDetail.origin !== 3 &&
                          <Badge dot={youzhiNewRead === 1 && this.state.isDot}>
                            <Radio.Button style={{borderRadius: "4px 0 0 4px"}} value={1}>即派优职({youzhiCount})</Radio.Button>
                          </Badge>
                        }
                        <Badge dot={chNewRead === 1 && this.state.isDot}>
                          <Radio.Button style={{borderRadius: positionDetail.origin !== 3 ? "0 4px 4px 0" : ""}} value={2}>可汗招聘({chCount})</Radio.Button>
                        </Badge>
                      </Radio.Group>
                      {this.state.channelStatus === 1 ?
                        <OptimalPosition
                          positionDetail={positionDetail}
                          history={this.props.history}
                        /> : <KhPosition
                          positionDetail={positionDetail}
                          history={this.props.history}
                        />
                      }

                    </TabPane>
                    
                  </Tabs>
                </div>
                <div
                  className="right-show"
                  style={{ width: detailWidthStatus ? "537px" : "386px" }}
                >
                  <Tabs
                    type="card"
                    defaultActiveKey={this.props.detailTab || "1"}
                  >
                    <TabPane tab="详细信息" key="1">
                      <div
                        className="detail-content-box"
                        style={{ height: `${detailHeight}px` }}
                      >
                        <div ref={this.modelRef} />
                        {positionDetail.id && (
                          <DetailPositionStatus
                            setUpdateState={setUpdateState}
                            profileNumUpdate={profileNumUpdate}
                            commonData={commonData}
                            positionDetail={positionDetail}
                            detailWidthStatus={detailWidthStatus}
                          />
                        )}

                        <div className="position-handel-box">
                          <DetailPositionSource isAll={isAll} />
                          <Collapse
                            defaultActiveKey={["12"]}
                            className="collapse-handle-box"
                          >
                            <Panel
                              header={this.positionEditCollapse()}
                              key="12"
                            >
                              <PositionInfoShow
                                positionDetail={positionDetail}
                                detailWidthStatus={detailWidthStatus}
                              />
                            </Panel>
                          </Collapse>
                          <ContactPerson isAll={isAll} />
                          <PositionCommunicateRecords
                            key={"职位沟通记录"}
                            modelRef={this.modelRef}
                          />
                        </div>
                      </div>
                    </TabPane>
                    <TabPane tab="职位进展" key="2">
                      <div
                        style={{
                          height: `${detailHeight}px`,
                          overflowY: "auto"
                        }}
                      >
                        <PositionProgress />
                      </div>
                    </TabPane>
                  </Tabs>
                  {positionEditDrawer && <PositionInfoEdit />}
                </div>
              </div>
            </Spin>
          </div>
          {/*  */}
          <style jsx global>{`
            .position-detail-helper {
              background-color: rgba(255,255,255,.5);
            }
            .position-details-drawer {
              .ant-drawer-content {
                overflow: visible;
              }
              .collect-poisition {
                transform: translate3d(0px, 0px, 0px);
              }
              .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab-active{
                color:#1890ff;
                font-weight:600;
              }
              .close-drawer-btn{
                width:15px;
                height:44px;
                left:0;
                top:450px;
                position: absolute;
                z-index: 1;
                background: url('${ic_close}') no-repeat;
              } 
              .ant-drawer-close{
                width:15px;
                height:44px;
                left:0;
                top:50%;
                .ant-drawer-close-x{
                  width:15px;
                height:44px;
                svg{
                  display: none;
                }
                }
              }
            
              .drawer-body {
                .head {
                  min-height: 94px;
                  border-bottom:1px solid #e8e8e8;
                  // margin-bottom:10px;
                }
                .content {
                  height: calc(100% - 94px);
                  .ant-tabs-nav {
                    padding-left: 16px;
                  }
                  .left-show {
                    display: inline-block;
                    height: 100%;
                    padding-top: 10px;
                  }
                  .right-show{
                    border-left: 1px solid #e5e5e5;
                    display: inline-block;
                    vertical-align: top;
                    padding-top: 10px;
                    .detail-content-box{
                      overflow:auto;
                      overflow-x:hidden;
                      .position-handel-box{
                      padding: 10px;
                      background-color: #f1f2f6;
                    }
                    }
                  }
                }
            .position-show-head {
                  position: relative;
                  .icon {
                    width: 1em;
                    height: 1em;
                    vertical-align: -0.15em;
                    fill: currentColor;
                    overflow: hidden;
                    margin-left: 15px;
                  }
                  .sel-position {
                    position: absolute;
                    right: 24px;
                    top: 20px;
                    height: 80px;
                    .dot {
                      width: 6px;
                      height: 6px;
                      border-radius: 50%;
                      display: inline-block;
                      margin-right: 10px;
                      vertical-align: middle;
                    }
                    .ant-select-lg {
                      font-size: 14px;
                    }
                  }
                  .icon-handle {
                    min-width: 80px;
                    position: absolute;
                    right: 0;
                    bottom: 15px;
                    text-align: right;
                  }
                  .line {
                    width: 1px;
                    height: 20px;
                    background: #cccccc;
                    display: inline-block;
                    vertical-align: bottom;
                    margin-right: 3px;
                  }
                }
              }
            }
            .position-show-box{
              .ant-collapse-content-box{
                padding: 16px 8px;
              }
              .right{
                color: #1890ff;
                text-align:center;
                padding-top:2px;
              }
              b{
                vertical-align: text-top;
              }
            }
          `}</style>
        </Drawer>
      );
    }
  }
);
