import css from 'styled-jsx/css';

export default css.global`.capcha-input {
    // background-color: pink;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .capcha-code {
        height: 36px;
        width: 70px;
        border: 1px solid #f0f1f5;
        margin-left: 10px;
    }
}`