import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { Drawer, Input, Row, Col, Upload, Icon, message } from "antd";
import { Modal } from "antd";
import internModel from "store/reducers/internModel";
import socialCandModel from "store/reducers/socialCandModel";
import {
  convertProExperience,
  convertWorkExp
} from "pages/TalentPool/interfaceDataConvert";

const Dragger = Upload.Dragger;
/**
 * 简历导入
 */
export default class ResumeImport extends PureComponent {
  state = {
    loading: false
  };

  onClose = () => {
    this.props.updateResumeImport({ visible: false });
  };

  render() {
    const { resumeImport, type } = this.props;
    const uploadAffixProps = {
      name: "attachment",
      showUploadList: false,
      action: "admin/v1/profile-manage/upload",
      data: { is_intern: type },
      beforeUpload: file => {
        // const isJPG = file.type === "image/jpeg";
        // if (!isJPG) {
        //   message.error("You can only upload JPG file!");
        // }
        const isLt5M = file.size / 1024 / 1024 < 5;
        if (!isLt5M) {
          message.error("上传简历最大不超过5M");
        }
        if (isLt5M) this.setState({ loading: true });
        return isLt5M;
      },
      onChange: info => {
        const status = info.file.status;
        if (status === "uploading") {
          return;
        }
        this.setState({ loading: false });
        if (status === "done") {
          const res = info.file.response;
          if (res.code === 0) {
            const data = res.data;
            if (!type) {
              this.props.updateNewCandidate({
                baseInfo: data.base_info,
                jobStatus: data.job,
                educations: data.education || [],
                jobExperiences: (data.work || []).map(item =>
                  convertWorkExp(item)
                ),
                proExperiences: (data.project || []).map(item =>
                  convertProExperience(item)
                ),
                certificates: data.certificate || [],
                resumeAttachment: data.resume_attachment || ""
              });
            } else {
              this.props.updateNewCandidate({
                baseInfo: data.base_info,
                jobStatus: {
                  ...data.job,
                  position_name: data.base_info.position_name
                },
                educations: data.education || [],
                practiceExperiences: data.profile_job_intern || [],
                campusPractices: data.campu || [],
                certificates: data.certificate || [],
                personalWorks: data.production || [],
                resumeAttachment: data.resume_attachment || ""
              });
            }
            this.props.updateResumeImport({ visible: false });
            //打开创建简历
            this.props.updateCreateResumeDrawer({
              openType: "import",
              title: "简历解析完成，请检查以下信息",
              visible: true
            });
          } else {
            message.error(res.msg);
          }
        }
      }
    };
    return (
      <Drawer
        width={900}
        placement="right"
        className="creat-drawer-box drawer-modal upload-resume-drawer"
        title={`导入${type === 1 ? "实习生" : "社会人选"}简历`}
        onClose={this.onClose}
        visible={resumeImport.visible}
        style={{
          paddingBottom: 53
        }}
      >
        <Dragger {...uploadAffixProps} className="resume-affix-upload">
          <p className="ant-upload-drag-icon">
            <Icon type={this.state.loading ? "loading" : "inbox"} />
          </p>
          <p className="ant-upload-text">点击上传简历附件</p>
          <p className="ant-upload-hint">
            请选择html、docx、doc、pdf类型的文件，大小不超过5M
          </p>
        </Dragger>
        <style jsx>
          {`
            :global(.upload-resume-drawer) {
              z-index: 1001;
              :global(.ant-drawer-body) {
                padding: 100px;
                :global(.ant-upload-btn) {
                  padding: 50px 0 40px;
                }
                .ant-upload-text {
                  margin-bottom: 10px;
                }
              }
            }
          `}
        </style>
      </Drawer>
    );
  }
}

export const InternResumeImport = connect(
  ({ intern }) => ({
    resumeImport: intern.resumeImport,
    type: 1
  }),
  { ...internModel.actions }
)(ResumeImport);

export const SocialResumeImport = connect(
  ({ socialCandidate }) => ({
    resumeImport: socialCandidate.resumeImport,
    type: 0
  }),
  { ...socialCandModel.actions }
)(ResumeImport);
