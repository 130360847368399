import React from "react";
import ReactDOM from "react-dom";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Row, Col, Pagination, Input, Button, Icon, Tabs, Popover, Select, Cascader, Tooltip, TreeSelect, Badge, Form, Modal, DatePicker, Checkbox } from "antd";
import Table from "components/GeeTable.js";
import companyModel from "store/reducers/companyModel";
import AddCompany from "./addCompanyForm.js";
import global from "store/reducers/global";
import CompanyDetail from "./companyDetail.js";
import styles from "./company.scss";
import iconBusiness from "assets/imgs/business/icon-business.png";
import iconReBusiness from "assets/imgs/business/icon-rebusiness.png";
import wrapperStyles from "./companyWrapper.scss";
import CustomIcon from "components/CustomIcon";
import { OptimalDialog } from "../PositionVacant/optimalPosition";
import {transformTree} from "utils/util";
import StepIntro from "components/step-intro";
import IconTeam from "assets/imgs/icon-team.png";
import { CommunicateRecords, AddContractForm } from "./companyDetailForm.js";
const { RangePicker } = DatePicker;
const TabPane = Tabs.TabPane;
const Option = Select.Option;
const Search = Input.Search;
const FormItem = Form.Item;

@connect(
  ({ company, user ,}) => {
    return { ...company, user };
  },
  {
    ...companyModel.actions,
    updateStack: global.actions.updateDrawerStack
  }
)
class CompanyTable extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      conditionPopVisible: false,
      addContractModalVisible: false,
      editCompanyModalVisible: false,
      addLinkManModalVisible: false,
      modifyOwnerVisible: false,
      expand:
        "hh_name,bd_name,city_name,industry_name,contacts,jds,jd_profiles,add_time_str,last_follow_time",
      currentAll: false
    };
  }

  componentDidMount() {
    const {
      companyListPagination,
      companyListCondition,
      user,
      getCompanyList,
      isType,
      location = {}
    } = this.props;
    let {state} = location
    if (isType === "2" && location.state && location.state.needRefresh) {
      delete companyListCondition.user_id;
      companyListCondition.keyword = location.state.keyword;
      setTimeout(() => {
        getCompanyList(
          {
            page: 1,
            "per-page": companyListPagination.pageSize,
            ...companyListCondition
          }, companyListCondition
        );
      }, 0);
    } else if (user.id) {
      companyListCondition.user_id = user.id;
      if (isType == "2") {
        delete companyListCondition.user_id;
      }
      console.log("usermount");
      getCompanyList(
        {
          page: 1,
          "per-page": companyListPagination.pageSize,
          ...companyListCondition
        },
        companyListCondition
      );
    }
  }

  componentWillUnmount() {
    this.props.updateField({
      field: "companyListCondition",
      value: {}
    });
    this.props.updateField({
      field: "companyListPagination",
      value: {
        current: 1,
        pageSize: 5,
        showSizeChanger: true,
        showQuickJumper: true,
        showTotal: total => `总计${total}个`
      }
    });
  }

  componentDidUpdate(prevProps) {
    const {
      companyListPagination,
      currentType,
      user,
      getCompanyList,
      isType,
      companyListCondition,
      updatePagination,
      location
    } = this.props;
    const { is } = require("immutable");
    // 查询好用户信息，查询列表
    if (
      !is(user, prevProps.user) ||
      (!is(currentType, prevProps.currentType) && isType == currentType)
    ) {
      console.log("来了")
      // 第一次查用户，和tab切换
      companyListCondition.user_id = user.id;
      if (isType == "2") {
        // 全部
        delete companyListCondition.user_id;
      }
      getCompanyList(
        {
          page: 1,
          "per-page": companyListPagination.pageSize,
          ...companyListCondition
        },
        companyListCondition
      );
      updatePagination({
        current: 1
      });
    }
    if (isType === "2") {    
      let needRefresh = location.state && location.state.needRefresh;
      let prevNeedRefresh = prevProps.location.state && prevProps.location.state.needRefresh;
      if (location.state && !is(needRefresh, prevNeedRefresh)) {
        console.log("changerefresh123")
        setTimeout(() => {
          delete companyListCondition.user_id;
          companyListCondition.keyword = location.state.keyword;
          getCompanyList(
            {
              page: 1,
              "per-page": companyListPagination.pageSize,
              ...companyListCondition
            }, companyListCondition
          );
        }, 0);
      }
    }
  }

  handleTableChange = (page, size) => {
    const {
      getCompanyList,
      companyListCondition,
      updatePagination
    } = this.props;
    const { expand } = this.state;
    getCompanyList(
      {
        "per-page": size,
        page: page,
        ...companyListCondition
      },
      companyListCondition
    );
    updatePagination({
      pageSize: size,
      current: page
    });
  };

  handleSizeChange = (current, size) => {
    const {
      getCompanyList,
      companyListCondition,
      updatePagination
    } = this.props;
    const { expand } = this.state;
    getCompanyList(
      {
        "per-page": size,
        page: current,
        ...companyListCondition
      },
      companyListCondition
    );
    updatePagination({
      pageSize: size,
      current: current
    });
  };

  typeChange = (value, option) => {
    const {
      getCompanyList,
      companyListCondition,
      companyListPagination,
      updatePagination
    } = this.props;
    const { expand } = this.state;
    const condition = {
      ...companyListCondition,
      type: value
    };
    getCompanyList(
      {
        "per-page": companyListPagination.pageSize,
        page: 1,
        ...condition
      },
      condition
    );
    updatePagination({
      current: 1
    });
  };

  managerChange = (value, option) => {
    const {
      getCompanyList,
      companyListCondition,
      companyListPagination,
      updatePagination
    } = this.props;
    const { expand } = this.state;
    const condition = {
      ...companyListCondition,
      bd_id: value,
      bd_ids: value,
    };
    getCompanyList(
      {
        "per-page": companyListPagination.pageSize,
        page: companyListPagination.current,
        ...condition
      },
      condition
    );
    updatePagination({
      current: 1
    });
  };

  sourceChange = (value, option) => {
    const {
      getCompanyList,
      companyListCondition,
      companyListPagination,
      updatePagination
    } = this.props;
    const { expand } = this.state;
    const condition = {
      ...companyListCondition,
      source: value[value.length - 1],
      sources: value,
    };
    getCompanyList(
      {
        "per-page": companyListPagination.pageSize,
        page: companyListPagination.current,
        ...condition
      },
      condition
    );
    updatePagination({
      current: 1
    });
  };

  searchValueChange = (e) => {
    const {
      companyListCondition,
      companyListPagination,
      updatePagination,
      updateCondition
    } = this.props;
    updateCondition({
      ...companyListCondition,
      keyword: e.target.value
    })
  }

  serachChange = (value) => {
    const {
      getCompanyList,
      companyListCondition,
      companyListPagination,
      updatePagination
    } = this.props;
    const { expand } = this.state;
    const condition = {
      ...companyListCondition,
      keyword: value
    };
    getCompanyList(
      {
        "per-page": companyListPagination.pageSize,
        page: companyListPagination.current,
        ...condition
      },
      condition
    );
    updatePagination({
      current: 1
    });
  };

  originTypeChange = (value, option) => {
    this.filterSearch({origin_type: value})
  };

  contractTypeChange = (value, option) => {
    this.filterSearch({contract_type: value})
  };

  effectiveChange = (e) => {
    console.log("efffval", e)
    this.setState({
      is_effective_contract: e.target.checked ? 1 : 0
    })
    this.filterSearch({is_effective_contract: e.target.checked ? 1 : 0 })
  };

  rangeChange = (dates, dateStrings) => {
    this.filterSearch({
      rangeValue: dates,
      contract_create_start_time: dateStrings[0],
      contract_create_end_time: dateStrings[1]
    })
  };

  filterSearch = (param) => {
    const {
      getCompanyList,
      companyListCondition,
      companyListPagination,
      updatePagination
    } = this.props;
    const condition = {
      ...companyListCondition,
      ...param
    };
    getCompanyList(
      {
        "per-page": companyListPagination.pageSize,
        page: 1,
        ...condition
      },
      condition
    );
    updatePagination({
      current: 1
    });
  }

  addCompany = () => {
    this.props.updateAddCompanyVisible(true);
  };

  // 
  visibleChange = (visible, index) => {
    const { companyList, updateCompanyList } = this.props;
    companyList.list[index].popupVisible = visible;
    updateCompanyList({...companyList});
  };

  // 列表合同添加
  addRecordClick = (e, index, row) => {
    e.stopPropagation();
    this.setState({ 
      addContractModalVisible: true, 
      currentRow: row, 
      currentRowIndex: index 
    });
    this.visibleChange(false, index);
  };

  handleContractOk = () => {
    const form = this.contractForm.props.form;
    const { companyList, updateCompanyList, addContract} = this.props;
    const { currentRow, currentRowIndex } = this.state;
    console.log(this, "sd")
    form.validateFields((err, values) => {
      if (err) {
        return;
      }
      values.norefresh = true;
      values.company_id = currentRow.id;
      values.bd_ids = values.bd_ids.filter(item => item);
      values.bd_id = values.bd_ids[values.bd_ids.length - 1];
      values.sign_date = values.sign_date_m.format("YYYY-MM-DD");
      values.start_time = values.rangeTime[0].format("YYYY-MM-DD");
      values.end_time = values.rangeTime[1].format("YYYY-MM-DD");
      console.log("Received values of form: ", values);

      addContract(values).then(res => {
        if (res.code == 0) {
          this.setState({ addContractModalVisible: false });
          form.resetFields();
          companyList.list[currentRowIndex].contract_record.unshift(res.data);
          updateCompanyList({...companyList});
        }
      });
    });
  };

  handleContractCancel = () => {
    this.setState({ addContractModalVisible: false });
  };

  contractFormRef = formRef => {
    this.contractForm = formRef;
  };

  onRow = record => {
    let that = this;
    const {updateStack,  updateField, currentType, isType } = this.props;
              
    return {
      onClick: e => {
        // updateField({field: "companyId", value: record.id})
        updateStack({
          type: "add", 
          componentRef: CompanyDetail,
          componentProps: {currentType: currentType, isType: isType, onClose: () => updateStack({type: "pop"}), stackVisible: true, companyId: record.id},
        })
      } // 点击行
    };
  };

  changeVisible = visible => {
    this.setState({
      conditionPopVisible: visible
    });
  };

  goSearch = () => {
    this.filterSearch({});
    this.changeVisible(false);
  }

  goReset = () => {
    const {
      getCompanyList,
      companyListPagination,
      updatePagination,
      user,
      isType
    } = this.props;
    let defaultCondition = {
      "per-page": companyListPagination.pageSize,
      page: 1,
    };
    defaultCondition.user_id = user.id;
    if (isType == "2") {
      delete defaultCondition.user_id;
    }
    getCompanyList(defaultCondition, {
        ...defaultCondition
      }
    );
    updatePagination({
      current: 1
    });
    this.setState()
  }

  render() {
    const {
      commonData,
      companyList,
      companyLoading,
      companyListCondition,
      companyListPagination,
      user: {roles},
      user
    } = this.props;
    const {conditionPopVisible} = this.state;
    const columns = [
      {
        title: "基本信息",
        key: "1",
        width: 450,
        render: (text, record) => {
          let names = [];
          if (record.bd_name) names.push(<Tooltip title={record.bd_org_name}>{record.bd_name}</Tooltip>);
          if (record.hh_name) names.push(<Tooltip title={record.hh_org_name}>{record.hh_name}</Tooltip>);
          return (
            <div className="base-column">
              <div className="base-column-logo"><img src={record.logo ? record.logo : require("assets/imgs/default-company.png")} alt="" /></div>
              <div className="base-column-info">
                <div className="column-info" style={{ color: "#333" }}>
                  <span className="ellipsis names">{record.name}</span>&nbsp;
                  {record.type == 3 && (
                    <Tooltip title="待归档">
                      <Icon
                        component={() => <i className="icon-business" />}
                        theme="outlined"
                      />
                    </Tooltip>
                  )}
                  {record.type == 4 &&  (
                    <Tooltip title="合同">
                      <Icon
                        component={() => <i className="icon-rebusiness" />}
                        theme="outlined"
                      />
                    </Tooltip>
                  )}
                  {record.type == 5 && (
                    <Tooltip title="合同取消"><CustomIcon type="icon-quxiao"/></Tooltip>
                  )}
                </div>
                <div style={{ color: "#999"}}>
                  NO.{record.id}
                  {names.length != 0 &&
                    <span className="ellipsis bd-names" style={{ marginLeft: "10px" }}>
                      由
                      {names.length == 1 && names}
                      {names.length == 2 && names[0]}
                      {names.length == 2 && " & "}
                      {names.length == 2 && names[1]}
                      负责
                    </span>
                  }
                </div>
              </div>
            </div>
          )

        }
      },
      {
        title: "行业及地区",
        key: "age",
        render: (text, record) => (
          <div>
            <div style={{ color: "#666", margin: "6px 0" }}>
              {record.industry_name}
            </div>
            <div style={{ color: "#999" }}>{record.city_name}</div>
          </div>
        )
      },
      {
        title: "招聘职位",
        key: "jds",
        dataIndex: "jds",
        width: 260,
        render: (jds, record) => (
          <span className="position-num">
            {jds.processing_num ?
              <Badge className="ing-num" color="blue" text={`进行中（${jds.processing_num}）`} /> : ""
            }
            {jds.success_closed_num ?
              <Badge color="#ccc" text={`已关闭（${jds.success_closed_num}）`} /> : ""
            }
          </span>
        )
      },
      {
        title: "合同记录",
        key: "contract_record",
        dataIndex: "contract_record",
        width: 100,
        render: (value, row, index) => {
          const {isType, user} = this.props;
          const content = (
            <div onClick={e => e.stopPropagation()}>
              {value &&
                value.map(item => {
                  return (
                    <div className="contract-item">
                      <div className="clear tags">
                        <div className="right type">{item.type_name}</div>
                        <div className="right new">{item.is_new_name}</div>
                      </div>
                      <div className="item-info"><div className="label">合同编号：</div><div className="content ellipsis">{item.contract_no}</div></div>
                      <div className="item-info"><div className="label">签署日期：</div><div className="content ellipsis">{item.sign_date}</div></div>
                      <div className="item-info"><div className="label">生效日期：</div><div className="content ellipsis">{item.start_time}~{item.end_time}</div></div>
                      <div className="item-info"><div className="label">负责人：</div><div className="content ellipsis">{item.bd_name}</div></div>
                      <div className="item-info"><div className="label">备注：</div><div className="content">{item.remark}</div></div>
                    </div>
                   );
                })}
            </div>
          );
          return (
            <Popover
              content={content}
              title={
                <div
                  onClick={e => {
                    e.stopPropagation();
                  }}
                >
                  <span>合同记录</span>
                  {
                    (isType === "1" || (isType === "2" && (user.roles === "business" || user.roles === "admin"))) &&
                      <span
                        className="right"
                        style={{ color: "#1890ff", paddingRight: "16px" }}
                        onClick={e => this.addRecordClick(e, index, row)}
                      >
                        <CustomIcon type="icon-plus1" />
                        添加
                      </span>
                  }
                  
                </div>
              }
              visible={row.popupVisible}
              onVisibleChange={visible => this.visibleChange(visible, index)}
              placement="left"
              trigger="hover"
              overlayClassName="add-contract-popup"
            >
              <div
                className="ellipsis communication-table-value"
              >
                {value && value.length !== 0 ? value[0].sign_date : "-"}
              </div>
            </Popover>
          );
        }
      },
      {
        title: "候选人",
        key: "jd_profiles",
        dataIndex: "jd_profiles",
        width: 350,
        render: (jd_profiles, record) => (
          <React.Fragment>
            <div style={{ margin: "6px 0" }}>
              {
                jd_profiles.recommended_num ?
                  <Tooltip className="profile-num" title="已推荐"><CustomIcon className="recommended_num" type="icon-ic_5"/>{jd_profiles.recommended_num}</Tooltip> : ""
              }
              {
                jd_profiles.arrange_interview_num ?
                  <Tooltip className="profile-num" title="安排面试"><CustomIcon className="arrange_interview_num" type="icon-ic_"/>{jd_profiles.arrange_interview_num}
                  </Tooltip> : ""
              }
              {
                jd_profiles.interview_through_num ?
                  <Tooltip className="profile-num" title="面试通过"><CustomIcon className="interview_through_num" type="icon-ic_1"/>{jd_profiles.interview_through_num}
                  </Tooltip>: ""
              }
              {
                jd_profiles.offer_coordinate_num ?
                  <Tooltip className="profile-num" title="offer协调"><CustomIcon className="offer_coordinate_num" type="icon-ic_2"/>{jd_profiles.offer_coordinate_num}
                  </Tooltip> : ""
              }
              {
                jd_profiles.onboard_num ?
                  <Tooltip className="profile-num" title="onboard"><CustomIcon className="onboard_num" type="icon-ic_3"/>{jd_profiles.onboard_num}
                  </Tooltip>: ""
              }
              {
                jd_profiles.obsolete_num ?
                  <Tooltip className="profile-num" title="淘汰人数"><CustomIcon className="obsolete_num" type="icon-ic_4"/>{jd_profiles.obsolete_num}
                  </Tooltip>: ""
              }
            </div>
          </React.Fragment>
        )
      },
      {
        title: "最后跟进时间",
        dataIndex: "last_follow_time",
        width: 160,
        render: (text, record) => {
            return  <span className="last_follow_time" style={{ color: "#666" }}>{text}
            {
              record.new_follow_record === 0 &&
              <Tooltip title="该客户已超过20天没有跟进记录，请及时跟进">
                <Icon type="info-circle" className="info-icon" style={{ color: "#f5222d" }}/>
              </Tooltip>
            }
          </span>
        }
      }
    ];
    console.log("render", this);
    const steps = [
      {
        element: ".table-area",
        position: "top",
        intro:
          "客户模块由sherry同学那边统一维护，创建客户时候先看系统有没有，不要建重复了，比如科锐现在系统有13个"
      },
    ];
    const formItemLayout = {
      labelCol: { span: 6 },
      wrapperCol: { span: 18 }
    };
    const searchContent = (
      <React.Fragment>
        <Row className="base-area">
          <FormItem label="客户进展" colon={false} {...formItemLayout}>
            <Select
              className="custome-type"
              placeholder="客户进展"
              allowClear style={{marginRight: "20px"}}
              value={companyListCondition.type}
              onChange={this.typeChange}>
              <Option value="3">待归档客户</Option>
              <Option value="4">合同客户</Option>
              <Option value="5">合同取消</Option>
            </Select>
          </FormItem>
          <FormItem label="负责人" colon={false} {...formItemLayout}>
            <TreeSelect treeNodeFilterProp="title" maxTagCount={0} maxTagPlaceholder={(omittedValues) => `您选择了${omittedValues.length}位顾问`} dropdownClassName="org-tree-select"
              value={companyListCondition.bd_ids} 
              treeCheckable={true} treeIcon={true} placeholder="请选择负责人"
              dropdownStyle={{maxHeight: "260px"}} className="custome-type" onChange={this.managerChange}
              treeData={transformTree(commonData.company_manager)}
              />
          </FormItem>
          <FormItem label="线索来源" colon={false} {...formItemLayout}>
            <Cascader className="gee-cascader custome-type" expandTrigger="hover" changeOnSelect
              fieldNames={{ label: "label", value: "value", children: "list" }}
              value={companyListCondition.sources}
              options={commonData.jd_source_search} onChange={this.sourceChange}
              placeholder="请选择来源"/>
          </FormItem>
          <FormItem label="信息搜索" colon={false} {...formItemLayout}>
            <Search
              className="company-search"
              placeholder="按公司名搜索"
              value={companyListCondition.keyword}
              onChange={this.searchValueChange}
              onSearch={this.serachChange} />
          </FormItem>

          <FormItem label="客户类型" colon={false} {...formItemLayout}>
            <Select
              className="custome-type"
              placeholder="客户类型"
              allowClear
              value={companyListCondition.origin_type}
              onChange={this.originTypeChange}>
              <Option value={1}>即派客户</Option>
              <Option value={2}>非即派客户</Option>
            </Select>
          </FormItem>
          <FormItem label="合同类型" colon={false} {...formItemLayout}>
            <Select
              className="custome-type"
              placeholder="合同类型"
              allowClear style={{marginRight: "20px"}}
              value={companyListCondition.contract_type}
              onChange={this.contractTypeChange}>
              {commonData.contract_type &&
                commonData.contract_type.map(item => {
                  return (
                        <Option key={item.value} value={item.value}>
                          {item.label}
                        </Option>
                  );
              })}
            </Select>
          </FormItem>
          <FormItem label="合同创建" colon={false} {...formItemLayout}>
            <RangePicker
              className="custome-datepicker"
              format="YYYY-MM-DD" value={companyListCondition.rangeValue}
              onChange={this.rangeChange}
              placeholder={["开始日期", "结束日期"]}
            />
          </FormItem>
          <FormItem label="" colon={false} {...formItemLayout}>
            <Checkbox checked={companyListCondition.is_effective_contract} onChange={this.effectiveChange}>有效合同</Checkbox>
          </FormItem>
        </Row>
        <Row className="submit-area">
          <Button className="search right" type="primary" onClick={this.goSearch} size="small">
            确定
          </Button>
          <Button className="reset right" onClick={this.goReset} size="small">重置</Button>
        </Row>
      </React.Fragment>
    )
    const AddContract = Form.create({})(AddContractForm);
    let contract_num_part = "";
    if (companyList.extra && companyList.extra.contract_num_part) {
      contract_num_part = companyList.extra.contract_num_part.map(item => `${item.label}:${item.num}`).join(" ");
    }
    return (
      <div className="company-detail-table">
        <div className="outer-box">
          <div className="handle-list-box">
            <div className="base-area">
              <Popover
                content={searchContent}
                title={null}
                trigger="click"
                overlayClassName="all-search-popup"
                placement="bottomLeft"
                visible={conditionPopVisible}
                onVisibleChange={this.changeVisible}
                >
                <Button>筛选/排序</Button>
              </Popover>
              
              {
                roles !== "consultant" && roles !== "consultant360" && roles !== "group_leader" &&roles !== "channel" &&
                <Button
                  onClick={this.addCompany}
                  type="primary"
                  className="right">
                  创建客户
                </Button>
              }
            </div>
            {
              companyList.list && companyList.list.length >= 0 && <StepIntro stepEnable={true} name="company" steps={steps}/>
            }
            <div className="table-area">
              <Table
                rowKey="id"
                className="company-table"
                columns={columns}
                dataSource={companyList.list}
                pagination={false}
                loading={companyLoading}
                needRowSel={this.props.companyVisible}
                onRow={this.onRow}
                scroll={{x: 1466}}
              />
              <Row className="tool-area">
                <Col>
                  {!companyLoading && (
                    <div className="number-area">
                      <span className="now-custome">
                        <span className="dot" />
                        <span className="text">
                          待归档客户（
                      {companyList.extra &&
                            companyList.extra.to_be_archived_client_num}
                          ）
                    </span>
                      </span>
                      <span className="now-custome">
                        <span className="dot" />
                        <span className="text">
                          合同客户（
                      {companyList.extra &&
                            companyList.extra.contract_client_num}
                          ）
                    </span>
                      </span>
                      <Tooltip title={contract_num_part} overlayStyle={{maxWidth: 600}}>
                        <span className="now-custome">
                          <span className="dot" />
                          <span className="text">
                            合同（{companyList.extra && companyList.extra.contract_num}）
                          </span>
                        </span>
                      </Tooltip>
                    </div>
                  )}
                </Col>
                <Col className="right">
                  <Pagination
                    {...companyListPagination}
                    className="company-pagination"
                    total={companyListPagination.total}
                    current={companyListPagination.current}
                    pageSizeOptions={["5", "10", "20", "30"]}
                    defaultPageSize={5}
                    pageSize={companyListPagination.pageSize}
                    onChange={this.handleTableChange}
                    onShowSizeChange={this.handleSizeChange}
                    size="small"
                  />
                </Col>
              </Row>
            </div>
          </div>
        </div>
        {/* <CompanyDetail currentType={currentType} isType={isType} /> */}
        <AddCompany />
        <OptimalDialog listDetail={{}} />
        <Modal
          width={845}
          zIndex={1001}
          wrapClassName="add-contract-modal"
          maskStyle={{ position: "absolute" }}
          title="添加合同"
          visible={this.state.addContractModalVisible}
          onOk={this.handleContractOk}
          onCancel={this.handleContractCancel}
        >
          <AddContract
            wrappedComponentRef={this.contractFormRef}
            commonData={commonData}
            userData={user}
          />
        </Modal>
        <style jsx global>{`
          .org-tree-select {
              .ant-select-tree-icon__close {
                width: 18px !important;
                background: url('${IconTeam}') 0 4px no-repeat;
                background-size: 14px 14px;
              }
              .ant-select-tree-icon__open {
                width: 18px !important;
                background: url('${IconTeam}') 0 4px no-repeat;
                background-size: 14px 14px;
              }
            }
        `}</style>
      </div>
    );
  }
}

@connect(
  ({ company, user }) => {
    return { ...company, userAuth: user.roles };
  },
  {
    ...companyModel.actions
  }
)
export default class Company extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      currentType: "1",
    };
  }

  componentDidMount() {
    const { getCommonData, location } = this.props;
    getCommonData(
      "company_type,company_industry,company_scale,jd_source,cities,service_type,company_manager,jd_source_search,contract_type"
    );
    if (location.state && location.state.needRefresh) {
      this.setState({
        currentType: "2"
      })
    }
  }

  componentDidUpdate(prevProps) {
    const { location, getCompanyList, companyListPagination, companyListCondition,} = this.props;
    let needRefresh = location.state && location.state.needRefresh;
    let prevNeedRefresh = prevProps.location.state && prevProps.location.state.needRefresh;
    const { is } = require("immutable");
    if (location.state && !is(needRefresh, prevNeedRefresh)) {
      console.log("changerefresh")      
      this.setState({
        currentType: "2"
      })
    }
  }

  changeType = (activeKey) => {
    this.setState({ currentType: activeKey });
    this.props.updateField({
      field: "companyListCondition",
      value: {}
    });
  }

  render() {
    const {userAuth, location} = this.props;
    const {currentType} = this.state;
    return (
      <div className="company-container">
        <div className="company-detail">
            <Tabs className="page-title-tabs"
              activeKey={currentType}
              onChange={this.changeType}
            >
              <TabPane tab="我的客户" key="1">
                {currentType === "1" &&
                  <CompanyTable
                    isType="1"
                    currentType={this.state.currentType}
                  />
                }
              </TabPane>
              {
                (userAuth == "team_leader" || userAuth == "city_manager" || userAuth == "admin" || userAuth == "business"||userAuth == "group_leader")
                  && 
                  <TabPane tab="全部客户" key="2">
                    {currentType === "2" &&
                      <CompanyTable
                        isType="2" location={location}
                        currentType={this.state.currentType}
                      />
                    }
                  </TabPane>
              }
            </Tabs>
        </div>
        <style jsx>{styles}</style>
        <style jsx>{wrapperStyles}</style>
        <style jsx global>{`
          .icon-business {
            width: 14px;
            height: 14px;
            display: inline-block;
            background: url('${iconBusiness}') no-repeat;
            background-size: 14px;
          }
          .icon-rebusiness {
            width: 14px;
            height: 14px;
            display: inline-block;
            background: url('${iconReBusiness}') no-repeat;
            background-size: 14px;
          }
        `}</style>
      </div>
    );
  }
}
