import React from "react";
import { Select, Spin } from "antd";
const Option = Select.Option;

export default class SelectComplete extends React.PureComponent {
  state = {
    // fetching:false
    positionOptions: []
  };
  componentDidMount() {
    if (this.props.initLoad) this.fetch();
  }
  componentDidUpdate(prevProps) {
    const { setSearchParams = {} } = this.props;
    const { is } = require("immutable");
    if (
      !is(
        prevProps.setSearchParams &&
          (prevProps.setSearchParams || {}).reminder_hh_id,
        setSearchParams && setSearchParams.reminder_hh_id
      )
    ) {
      this.fetch();
      return;
    }
  }
  handleSearch = value => {
    const { setSearchParams = {} } = this.props;
    let expand = "company,position_name";
    this.lastFetchId += 1;
    const fetchId = this.lastFetchId;
    this.setState({
      positionOptions: [],
      fetching: true
    });
    // type=3是与我相关的职位
    this.props
      .requestFunc({
        expand,
        post_name: value,
        ...setSearchParams,
        "per-page": 9999
      })
      .then(res => {
        // if (fetchId !== this.lastFetchId) {
        //   // for fetch callback order
        //   return;
        // }
        if (res.code === 0) {
          let data = [];
          res.data.list.forEach(item => {
            data.push({
              value: item.id,
              label:
                (item.post_name ? item.post_name : "") +
                "---" +
                (item.company ? item.company.name : "")
            });
          });
          this.setState(
            {
              fetching: false,
              positionOptions: data
            },
            () => {
              console.log("dd1", this.state);
            }
          );
        } else {
          this.setState({
            fetching: false
          });
        }
      });
  };

  fetch = value => {
    if (this.timeout) {
      clearTimeout(this.timeout);
      this.timeout = null;
    }
    this.timeout = setTimeout(() => this.handleSearch(value), 300);
  };

  handleChange = value => {
    this.setState({
      fetching: false
    });
  };

  render() {
    const { ...props } = this.props;
    const { positionOptions = [] } = this.state;
    return (
      <Select
        allowClear
        showSearch
        placeholder={this.props.placeholder || "请输入"}
        style={this.props.style}
        defaultActiveFirstOption={false}
        showArrow={false}
        filterOption={false}
        onSearch={this.fetch}
        onChange={this.handleChange}
        notFoundContent={
          this.state.fetching ? <Spin size="small" /> : "暂无数据"
        }
        loading={this.state.fetching}
        {...props}
      >
        {positionOptions.map(item => (
          <Option value={item.value} key={item.value} title={item.label}>
            {item.label}
          </Option>
        ))}
      </Select>
    );
  }
}
