import React, { PureComponent } from "react";
import { Upload, Icon, message, Avatar } from "antd";
import classnames from "classnames";

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
}

/**
 * 头像提交前默认处理
 */
function defaultBeforeUpload(file) {
  const isJPG = file.type === "image/jpeg";
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isJPG || !isLt2M) {
    message.error("请上传jpg格式图片，且不超过2M");
  }
  return isJPG && isLt2M;
}

/**
 * 用户头像上传
 */
export default class UserAvatarUpload extends PureComponent {
  state = {
    loading: false,
    uploadVisible: false
  };

  handleChange = info => {
    const { onChange } = this.props;
    if (info.file.status === "uploading") {
      this.setState({ loading: true });
      return;
    }
    if (info.file.status === "done") {
      const res = info.file.response;
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, imageUrl => onChange(res, imageUrl));
    } else {
      message.error("上传头像失败");
    }
    this.setState({ uploadVisible: false, loading: false });
  };

  deleteAvatar = e => {
    e.stopPropagation();

    const { deletefunc, data, onDelete } = this.props;
    deletefunc(data).then(res => {
      if (onDelete) {
        onDelete(res);
      }
    });
  };

  onMouseEnter = () => {
    this.setState({ uploadVisible: true });
  };

  onMouseLeave = () => {
    this.setState({ uploadVisible: false });
  };

  render() {
    let {
      action,
      alt = "用户头像",
      size = 100,
      width = 100,
      height = 120,
      className,
      avatar,
      shape = "square",
      beforeUpload,
      deletefunc,
      defaultAvatar,
      ...props
    } = this.props;
    const { uploadVisible } = this.state;
    // beforeUpload = beforeUpload || defaultBeforeUpload;
    return (
      <div
        className={classnames("user-avarar-upload", className)}
        style={{ width, height }}
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
      >
        <Avatar
          shape={shape}
          alt={alt}
          src={avatar || defaultAvatar}
          style={{ width, height }}
          icon="user"
          size={size}
        />
        {deletefunc && avatar && (
          <Icon
            type="delete"
            className="delete-icon"
            onClick={this.deleteAvatar}
          />
        )}
        <Upload
          {...props}
          listType="picture-card"
          className="avatar-uploader"
          showUploadList={false}
          action={action}
          beforeUpload={beforeUpload}
          style={{
            width,
            height,
            display: uploadVisible ? "table-cell" : "none"
          }}
          onChange={this.handleChange}
        >
          <Icon
            type={this.state.loading ? "loading" : "upload"}
            style={{ fontSize: 23, color: "#fff" }}
          />
        </Upload>
        <style jsx>{`
          .user-avarar-upload {
            position: relative;
            :global(.avatar-uploader) {
              position: absolute;
              top: 0;
              left: 0;
              background: rgba(0, 0, 0, 0.3);
            }
            :global(.ant-upload.ant-upload-select-picture-card) {
              background-color: transparent;
              border: none;
              margin: 0;
              width: auto;
              height: auto;
            }
            :global(.delete-icon) {
              position: absolute;
              top: 0;
              right: 0;
              width: 20px;
              height: 20px;
              line-height: 23px;
              color: #fff;
              background-color: rgba(0, 0, 0, 0.5);
              z-index: 2;
            }
          }
        `}</style>
      </div>
    );
  }
}
