import React, { PureComponent } from "react";
import { connect } from "react-redux";
import {
  Tabs,
  Select,
  Input,
  Row,
  Col,
  Button,
  Menu,
  Dropdown,
  Icon,
  Collapse
} from "antd";
import PositionVacantModal from "store/reducers/positionVacantM";
import { history } from "root.js";
import styles from "./progress-module.scss";

const TabPane = Tabs.TabPane;
const Option = Select.Option;
const TextArea = Input.TextArea;
const InputGroup = Input.Group;
const Panel = Collapse.Panel;

const createMenu = (id, hh_id, user_id) => (
  <Menu>
    {hh_id != user_id && (
      <Menu.Item>
        <a
          target="_blank"
          rel="noopener noreferrer"
          onClick={() =>
            history.push("/position/vacant/recommend/" + id + "/1")
          }
        >
          推荐给前台
        </a>
      </Menu.Item>
    )}
    <Menu.Item>
      <a
        target="_blank"
        rel="noopener noreferrer"
        onClick={() => history.push("/position/vacant/recommend/" + id)}
      >
        推荐给客户
      </a>
    </Menu.Item>
  </Menu>
);

const flowType = [
  { value: -1, label: "待筛选" },
  { value: 1, label: "已推荐" },
  { value: 2, label: "安排面试" },
  { value: 3, label: "面试通过" },
  { value: 4, label: "offer协调" },
  { value: 5, label: "onboard" },
  { value: -10, label: "已淘汰" }
];

/**
 * 人选进展操作流
 */
@connect(
  ({ PositionVacantModal, user }) => {
    return { ...PositionVacantModal, user_id: user.id };
  },
  {
    ...PositionVacantModal.actions
  }
)
export default class ProgressFlow extends PureComponent {
  state = {
    currentType: -1 // 人选进展当前选择状态
  };

  componentDidUpdate(prevProps) {
    const { statusParamCandidate } = this.props;
    // 当前人选进展更新
    if (statusParamCandidate.status != prevProps.statusParamCandidate.status) {
      this.setState({
        currentType: statusParamCandidate.status
      });
    }
  }

  // 切换显示的人选进展
  changeType = key => {
    const { setUpdateState } = this.props;
    setUpdateState({
      filed: "statusParamCandidate",
      value: { status: key, page: 1, "per-page": 10 }
    });
  };

  render() {
    const { currentType } = this.state;
    const { positionDetail = {}, candidateData, user_id } = this.props;
    const { extra = {} } = candidateData;
    return (
      <div className="flow-container">
        <div className="step-box">
          <ul className="clear">
            {flowType.map(item => {
              return (
                <li
                  key={item.value}
                  className={`${item.value === currentType ? "active" : ""}`}
                  onClick={() => this.changeType(item.value)}
                >
                  <span className="step-title">
                    {item.label}&nbsp;
                    {item.value === -1 && extra.screened_num}
                    {item.value === 1 && extra.recommended_num}
                    {item.value === 2 && extra.arrange_interview_num}
                    {item.value === 3 && extra.interview_through_num}
                    {item.value === 4 && extra.offer_coordinate_num}
                    {item.value === 5 && extra.onboard_num}
                    {item.value === -10 && extra.obsolete_num}
                  </span>
                </li>
              );
            })}
          </ul>
        </div>
        {positionDetail.status === 2 && (
          <Dropdown
            overlay={createMenu(
              positionDetail.id,
              positionDetail.hh_id,
              user_id
            )}
            placement="bottomLeft"
          >
            <Button
              style={{
                fontSize: "12px",
                background: "#1890ff",
                color: "#ffffff"
              }}
            >
              推荐报告
            </Button>
          </Dropdown>
        )}
        <style jsx>{styles}</style>
      </div>
    );
  }
}
