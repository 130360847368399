
import React from "react";
import {
  Row,
  Col,
  Radio,
  Collapse,
  Select,
  Input,
  Modal,
  DatePicker,
  Form,
  Cascader
} from "antd";
import { connect } from "react-redux";
import companyModel from "store/reducers/companyModel";
import {setDisabledOption} from "utils/util";

const Panel = Collapse.Panel;
const Option = Select.Option;
const TextArea = Input.TextArea;
const FormItem = Form.Item;
const { RangePicker } = DatePicker;

const getCityName = (cities, sel = [], count = 0) => {
  let result = [];
  console.log("res", cities, sel, count)
  cities.map((item) => {
    if (sel[count] == item.id) {
      if (item.list) {
        result = getCityName(item.list, sel, count + 1)
        console.log("res", result)
      }
      result.unshift(item.name)
    }
  })
  return result;
}

const getLabelValue = (cities, sel, count = 0) => {
  let result = [];
  console.log("res", cities, sel, count);
  cities.map(item => {
    if (sel[count] == item.value) {
      if (item.list) {
        result = getLabelValue(item.list, sel, count + 1);
        console.log("res", result);
      }
      result.unshift(item.label);
    }
  });
  return result;
};

class SetPersonForm extends React.PureComponent {

  render() {
    const {commonData, companyDetail} = this.props;
    const {hh_org_info = {},bd_org_info = {}} = companyDetail;
    const {getFieldDecorator} = this.props.form;
    const formItemLayout = {
      labelCol: { span: 5 },
      wrapperCol: { span: 12 }
    };
    let sourceOption=setDisabledOption(commonData.jd_source);
    return (
      <Form className="comm-detail person-form">
        <Form.Item {...formItemLayout} label="负责商务">
          {getFieldDecorator("bd_ids", {
            initialValue: bd_org_info.org_city ? 
            [bd_org_info.org_city.id, bd_org_info.org_team.id, bd_org_info.org_group.id, bd_org_info.org_user.id].filter(item => item) : undefined,
            // rules: [{ required: true, message: "请选择负责商务" }]
          })(
            <Cascader
              showSearch
              allowClear={false}
              placeholder="请选择"
              size="large"
              className="gee-cascader"
              fieldNames={{
                label: "name",
                value: "id",
                children: "list"
              }}
              options={commonData.company_manager} />
          )}
        </Form.Item>
        <Form.Item {...formItemLayout} label="负责顾问">
          {getFieldDecorator("hh_ids", {
            initialValue: hh_org_info.org_city ? 
            [hh_org_info.org_city.id, hh_org_info.org_team.id, hh_org_info.org_group.id, hh_org_info.org_user.id].filter(item => item) : undefined,
            // rules: [{ required: true, message: "请选择负责顾问" }]
          })(
            <Cascader
              showSearch
              allowClear={false}
              placeholder="请选择"
              size="large"
              className="gee-cascader"
              fieldNames={{
                label: "name",
                value: "id",
                children: "list"
              }}
              options={commonData.company_manager} />
          )}
        </Form.Item>
        <Form.Item {...formItemLayout} label="客户来源">
          {getFieldDecorator("source_labelValue", {
            initialValue: companyDetail.source && (companyDetail.source_p ? [companyDetail.source_p, companyDetail.source] : [companyDetail.source]),
            rules: [{ required: true, message: "请选择客户来源" }]
          })(
            // <Select placeholder="请选择客户来源" size="large" labelInValue>
            //   {commonData.jd_source &&
            //     commonData.jd_source.map(item => {
            //       return (
            //         <Option key={item.value} value={item.value}>
            //           {item.label}
            //         </Option>
            //       );
            //     })}
            // </Select>
            <Cascader 
            disabled={companyDetail.source===9||companyDetail.source_p===9}
            className="gee-cascader custome-type" expandTrigger="hover" changeOnSelect size="large"
              fieldNames={{ label: "label", value: "value", children: "list" }}
              options={sourceOption} placeholder="请选择客户来源"/>
          )}
        </Form.Item>
        <Form.Item {...formItemLayout} label="客户类型">
          {getFieldDecorator("origin_type", {
            initialValue: companyDetail.origin_type,
            rules: [{ required: true, message: "请选择客户类型" }]
          })(
            <Radio.Group>
              <Radio value={1}>即派客户</Radio>
              <Radio value={2}>非即派客户</Radio>
            </Radio.Group>
          )}
        </Form.Item>
        <Form.Item {...formItemLayout} label="创建人" style={{marginBottom:8}}>
           <div>{companyDetail.add_by_name}</div>
        </Form.Item>
        <Form.Item {...formItemLayout} label="创建时间">
        {companyDetail.add_time_str}
        </Form.Item>
        <style jsx>{`
            :global(.person-form) {
              .person {
                margin-bottom: 20px;
                font-size: 16px;
              }
              .manage-team {
                font-size: 16px;
              }
            }
          `}</style>
      </Form>
    );
  }
}


// 修改责任人
@connect(
  ({ company, user }) => {
    return { ...company, user };
  },
  {
    ...companyModel.actions
  }
)
export default class SetCurrentPerson extends React.PureComponent {

  state = {
  };

  onCancel = () => {
    this.props.onClose();
  };

  addRecord = () => {
    const form = this.personForm.props.form;
    const {changeManager, companyDetail, getCompanyDetail, commonData, getCompanyList, companyListCondition, companyListPagination} = this.props;
    form.validateFields((err, values) => {
      if (err) {
        return;
      }
      // values.bd_id_name = getCityName(commonData.company_manager, values.bd_ids);
      // values.hh_id_name = getCityName(commonData.company_manager, values.hh_ids);
      values.company_id = companyDetail.id;
      values.bd_id = values.bd_ids && values.bd_ids[values.bd_ids.length - 1];
      values.hh_id = values.hh_ids && values.hh_ids[values.hh_ids.length - 1];
      values.source = values.source_labelValue[values.source_labelValue.length - 1];
      values.source_name = getLabelValue(
        commonData.jd_source,
        values.source_labelValue
      );
      console.log('Received values of form: ', values);
      changeManager(values).then(res => {
        if (res.code == 0) {
          this.props.onClose();
          getCompanyList({
            "per-page": companyListPagination.pageSize,
            ...companyListCondition
          }, companyListCondition);
          getCompanyDetail({ id: companyDetail.id });
          form.resetFields();
        }
      })
    });
  }

  personFormRef = (formRef) => {
    this.personForm = formRef;
  }
  
  render() {
    const SetPerson = Form.create()(SetPersonForm);
    const {commonData, companyDetail, visible} = this.props;
    console.log(this.state)
    return (
      <React.Fragment>
        <Modal
          zIndex={1001}
          title="修改"
          visible={visible}
          onOk={this.addRecord}
          onCancel={this.onCancel} >
          <SetPerson  wrappedComponentRef={this.personFormRef} commonData={commonData} companyDetail={companyDetail}></SetPerson>
        </Modal>
      </React.Fragment>
    );
  }
}