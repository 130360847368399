import React, { Component } from "react";
import { Select, Cascader } from "antd";

/**
 * needUser 只要组织架构的user
 */
class MultiCascade extends Component {
  cascadeOnMouse = false; // 级联是否聚焦
  selectOnFocus = false; // select是否聚焦
  cascaderRef = React.createRef();

  names = ["org_city", "org_team", "org_group",  "org_user"];

  state = {
    popupVisible: false // 级联浮层显隐
  };

  onSelectFocus = () => {
    this.selectOnFocus = true;
    this.setState({ popupVisible: true });
  };

  onSelectBlur = () => {
    this.selectOnFocus = false;
    this.setState({ popupVisible: this.cascadeOnMouse });
  };

  onCascadeMouseEnter = () => {
    this.cascadeOnMouse = true;
  };

  onCascadeMouseLeave = () => {
    this.cascadeOnMouse = false;
    this.setState({
      popupVisible: this.selectOnFocus
    });
  };

  convertValues = value => {
    const names = this.names;
    value = value || [];
    console.log("ddddd",value)
    return value.map(item => {
      const keys = [];
      const labels = [];
      for (const name of names) {
        const key = item[name];
        const label = item[`${name}_label`];
        key ? keys.push(key) : !1;
        label ? labels.push(label) : !1;
      }
      return {
        label: labels.join("/"),
        key: keys.join("/"),
      };
    });
  };

  same = (res, currentValue) => {
    const names = this.names;
    let index = 0;
    for (const item of currentValue) {
      console.log("index", index++);
      const same = [];
      names.map(key => {
        const curKeyValue = item[key] ? parseInt(item[key]) : undefined;
        console.log("values", res[key], curKeyValue);
        same.push(res[key] === curKeyValue);
      });
      console.log("same", same);
      if (!same.includes(false)) return true;
    }
    return false;
  };

  onCascaderChange = (value, selectedOptions) => {
    const { fieldNames = {}, onChange, selsize, needUser } = this.props;
    const currentValue = this.props.value || [];
    const res = {},
      names = this.names;
    console.log("valuevaluevaluevalue", value, this.props.value)
    selectedOptions.map((item, index) => {
      // if (item.type !== "user") return false;
      console.log("selectedOptions", item, needUser)
      res[names[index]] = item[fieldNames["value"] || "value"];
      res[`${names[index]}_label`] = item[fieldNames["label"] || "label"];
      res.isUser = item.type === "user"; // 最后一个值是否为user
      res.lastName = names[index]; // 最后一个值是哪个name
      res.lastUserId = item.type === "user" ? item[fieldNames["value"] || "value"] : ""; // 最后一个值为user,这里单独存个id,方便取值
    });
    console.log(res, "resrteeee")
    if (!res.isUser) return;  // 不是用户不让选
    if (!this.same(res, currentValue) && value.length > (selsize || 1)) {
      onChange([...currentValue, res]);
    }
  };

  onSelectChange = value => {
    const { onChange } = this.props;
    value = value.map(item => {
      let keys = item.key.split("/");
      let labels = item.label.split("/");
      const [org_city, org_team, org_group, org_user] = keys,
        [org_city_label, org_team_label, org_group_label, org_user_label] = labels;
      let keyFilters = keys.filter(item => item);
      let labelFilters = labels.filter(item => item);
      let lastName = "org_city"
      if (labelFilters.length === 2) {
        lastName = "org_team"
      } else if (labelFilters.length === 3) {
        lastName = "org_group"
      } else if (labelFilters.length === 4) {
        lastName = "org_user"
      }
      return {
        org_city,
        org_city_label,
        org_team,
        org_team_label,
        org_group,
        org_group_label,
        org_user,
        org_user_label,
        lastName: lastName,
        lastUserId: keyFilters[keyFilters.length - 1],
      };
    });
    console.log("selectchangeover", value)
    onChange(value);
  };

  onSelectSearch = value => {
    if (this.props.showSearch) {
      this.cascaderRef.current.setState({ inputValue: value });
    }
  };

  render() {
    const { popupVisible } = this.state;
    const {
      allowClear,
      value,
      placeholder,
      style,
      size,
      onChange,
      ...props
    } = this.props;
    const convertValue = this.convertValues(value);
    return (
      <div className="cascadeMultiple">
        <Select
          mode="multiple"
          open={false}
          labelInValue
          placeholder={placeholder}
          onFocus={this.onSelectFocus}
          onBlur={this.onSelectBlur}
          value={convertValue}
          onSearch={this.onSelectSearch}
          onChange={this.onSelectChange}
          style={style}
          size={size}
        />
        <div
          onMouseEnter={this.onCascadeMouseEnter}
          onMouseLeave={this.onCascadeMouseLeave}
        >
          <Cascader
            ref={this.cascaderRef}
            className="cascade"
            changeOnSelect
            expandTrigger="hover"
            size={size}
            popupVisible={popupVisible}
            onChange={this.onCascaderChange}
            {...props}
          />
        </div>
        <style jsx>{`
          .cascadeMultiple {
            position: relative;
            :global(.cascade) {
              top: 0;
              height: 100%;
              left: 0;
              position: absolute;
              z-index: -1;
            }
          }
        `}</style>
      </div>
    );
  }
}

export default MultiCascade;
