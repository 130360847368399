import Model from "../Model";
import * as recomCandToPosService from "services/recomCandToPos";

export default Model.getInstance(
  class extends Model {
    namespace = "recomCandToPos";
    state = {
      selectedCandIds: [], //选择的候选人Id
      selectedCands: [], //选择的候选人
      selectedInternCandIds: [], //选择的候选人Id
      selectedInternCands: [], //选择的候选人
      //推荐职位
      recommandPos: {
        type: 0, //0:from candidate, 1:from position
        visible: false,
        isIntern: false,
        list: {},
        listLoading: false,
        recomPosIds: [],
        emails: [],
        rowSelectItem: [],
      },
      //简历邮件
      resumeEmail: {
        visible: false
      },
      recommendSearchParam: {},
    };

    actions = {
      async getRecomPosList(param) {
        this.dispatch({
          type: "recomCandToPos/updateRecommandPos",
          payload: { listLoading: true }
        });
        param = {status: 2, type: 1, ...param}
        let result = await recomCandToPosService.getRecomPosList(param);
        if (result.code == 0) {
          this.dispatch({
            type: "recomCandToPos/updateRecommandPos",
            payload: { list: result.data }
          });
        }
        this.dispatch({
          type: "recomCandToPos/updateRecommandPos",
          payload: { listLoading: false }
        });
      },

      async recommandToPos(param) {
        const {
          selectedCandIds,
          recommandPos: { recomPosIds }
        } = this.getState().recomCandToPos;
        let result = await recomCandToPosService.recommandToPos({
          jd_ids: recomPosIds,
          profile_ids: selectedCandIds
        });
        if (result.code === 0) {
          this.dispatch({
            type: "recomCandToPos/updateRecommandPos",
            payload: { visible: false }
          });
        }
        return result;
      },

      async recommandToPosAndEmail(param) {
        const {
          selectedCandIds,
          recommandPos: { recomPosIds }
        } = this.getState().recomCandToPos;
        let result = await recomCandToPosService.recommandToPos({
          jd_ids: recomPosIds,
          profile_ids: selectedCandIds,
          ...param
        });
        if (result.code === 0) {
          this.dispatch({
            type: "recomCandToPos/updateResumeEmail",
            payload: { visible: false }
          });
        }
        return result;
      }
    };

    reducers = {
      updateSelectedCandIds(state, { payload: data }) {
        return { ...state, selectedCandIds: data };
      },
      updateSelectedCands(state, { payload: data }) {
        return { ...state, selectedCands: data };
      },
      updateSelectedInternCandIds(state, { payload: data }) {
        return { ...state, selectedInternCandIds: data };
      },
      updateSelectedInternCands(state, { payload: data }) {
        return { ...state, selectedInternCands: data };
      },

      updateRecommandPos(state, { payload: data }) {
        const { recommandPos } = state;
        return {
          ...state,
          recommandPos: { ...recommandPos, ...data }
        };
      },

      updateResumeEmail(state, { payload: data }) {
        const { resumeEmail } = state;
        return {
          ...state,
          resumeEmail: { ...resumeEmail, ...data }
        };
      },

      // empty recommand status
      cleanRecomStatus(state) {
        const { recommandPos } = state;
        return {
          ...state,
          selectedCandIds: [],
          selectedInternCandIds: [],
          selectedCands: [],
          selectedInternCands: [],
          recommandPos: {
            ...recommandPos,
            type: 0,
            recomPosIds: [],
            emails: []
          }
        };
      },

      updateParams(state, { payload: data }) {
        return { ...state, recommendSearchParam: data };
      },
    };
  }
);
