import Model from "../Model";
import * as dataCenterS from "services/dataCenterS";
export default Model.getInstance(
  class extends Model {
    namespace = "DataCenterModal";
    state = {
      briefInfo: {},
      operateInfo: {},
      businessInfo: {}, //商务数据
      counselorInfo: [], //顾问数据
      selPositionStatus: 0, //0：共计，1：新增
      performanceInfo: {
        loadingStatus: false
      }, //业绩数据
      weeklyInfo: {
        loadingStatus: false
      }, //weekly数据
      weeklyParam: { "per-page": 10 }, //weekly列表参数
      performanceParam: { "per-page": 10, type: 1 }, //业绩列表参数
      followUpDialogStatus: false, //跟进弹窗是否打开
      inputDialogStatus: false, //跟录入弹窗是否打开
      positionDialogStatus: false, //performance下三个职位类型弹窗是否打开
      flowDialogStatus: false, //performance下四个面试流程弹窗是否打开和weekly两个
      predictDialogStatus: false, //performance下两个预计总利润弹窗是否打开
      positionDialogInfo: {}, //业绩简报职位弹窗信息
      predictDialogInfo: {}, //预计利润弹窗信息
      flowDialogInfo: {}, //流程弹窗信息
      followUpDialogInfo: {} //更近弹窗信息
    };

    actions = {
      async getDataBrief(param) {
        this.dispatch({
          type: "DataCenterModal/setUpdateState",
          payload: {
            filed: "briefInfo",
            value: {}
          }
        });
        let result = await dataCenterS.dataBrief(param);
        if (result) {
          this.dispatch({
            type: "DataCenterModal/setUpdateState",
            payload: {
              filed: "briefInfo",
              value: { ...result.data }
            }
          });
        }
      },
      async getDataOperate(param) {
        let result = await dataCenterS.dataOperate(param);
        if (result) {
          this.dispatch({
            type: "DataCenterModal/setUpdateState",
            payload: {
              filed: "operateInfo",
              value: { ...result.data }
            }
          });
        }
      },
      async getDataBusiness(param) {
        let result = await dataCenterS.dataBusiness(param);
        if (result) {
          this.dispatch({
            type: "DataCenterModal/setUpdateBusinessState",
            payload: {
              filed: "businessInfo",
              value: { ...result.data }
            }
          });
        }
      },
      async getCounselorInfo(param) {
        let result = await dataCenterS.dataCounselor(param);
        if (result) {
          this.dispatch({
            type: "DataCenterModal/setUpdateCounselorState",
            payload: {
              filed: "counselorInfo",
              value: { ...result }
            }
          });
        }
      },
      async getPerformanceList(param) {
        this.dispatch({
          type: "DataCenterModal/setUpdateState",
          payload: { filed: "performanceInfo", value: { loadingStatus: true } }
        });
        let result = await dataCenterS.dataperformance(param);
        if (result) {
          this.dispatch({
            type: "DataCenterModal/setUpdateState",
            payload: {
              filed: "performanceInfo",
              value: { ...result.data, loadingStatus: false }
            }
          });
        }
      },
      async getWeeklyList(param) {
        this.dispatch({
          type: "DataCenterModal/setUpdateState",
          payload: { filed: "weeklyInfo", value: { loadingStatus: true } }
        });
        let result = await dataCenterS.weeklyList(param);
        if (result) {
          this.dispatch({
            type: "DataCenterModal/setUpdateState",
            payload: {
              filed: "weeklyInfo",
              value: { ...result.data, loadingStatus: false }
            }
          });
        }
      },
      async getPositionProfile(param, filedName) {
        let result = await dataCenterS.positionProfile(param);
        if (result) {
          this.dispatch({
            type: "DataCenterModal/setUpdateState",
            payload: {
              filed: [filedName],
              value: { ...result.data }
            }
          });
        }
      },
      async getPerformancePosition(param) {
        let result = await dataCenterS.performancePosition(param);
        if (result) {
          this.dispatch({
            type: "DataCenterModal/setUpdateState",
            payload: {
              filed: "positionDialogInfo",
              value: { ...result.data }
            }
          });
        }
      },
      async getPerformanceDetails(param) {
        let result = await dataCenterS.performanceDetails(param);
        if (result) {
          this.dispatch({
            type: "DataCenterModal/setUpdateState",
            payload: {
              filed: "predictDialogInfo",
              value: { ...result.data }
            }
          });
        }
      },
      async getPerformanceProfile(param) {
        let result = await dataCenterS.performanceProfile(param);
        if (result) {
          this.dispatch({
            type: "DataCenterModal/setUpdateState",
            payload: {
              filed: "followUpDialogInfo",
              value: { ...result.data }
            }
          });
        }
      }
    };

    reducers = {
      setUpdateState(state, { payload }) {
        const { filed, value } = payload;
        state[filed] = value;
        return { ...state };
      },
      setUpdateBusinessState(state, { payload }) {
        const { filed, value } = payload;
        let trenDataConvert =
          value.trend_data &&
          value.trend_data.map(item => {
            return {
              商机: item.business_num,
              线索: item.clue_num,
              客户: item.company_num,
              ...item
            };
          });
        state[filed] = { ...value, trend_data: trenDataConvert };
        return { ...state };
      },
      setUpdateCounselorState(state, { payload }) {
        const { filed, value = {} } = payload;
        state[filed] = value.data;
        return { ...state };
      },
      setPositionDialog(state, { payload }) {
        return { ...state, positionDialogStatus: payload };
      },
      setFollowUpDialog(state, { payload }) {
        return { ...state, followUpDialogStatus: payload };
      },
      setInputDialog(state, { payload }) {
        return { ...state, inputDialogStatus: payload };
      },
      setFlowDialog(state, { payload }) {
        return { ...state, flowDialogStatus: payload };
      },
      setPredictDialog(state, { payload }) {
        return { ...state, predictDialogStatus: payload };
      },
      updateList(state, { payload: data }) {
        return { ...state, followUpDialogInfo: data };
      }
    };
  }
);
