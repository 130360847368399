import xhr from './index.js'

/**
 * 用户反馈管理
 */
//意见列表
function opinionManageList(param) {
  return xhr({
    url: 'admin/v1/feedbacks',
    body:param
  })
}
//反馈列表
function personManageList(param) {
  return xhr({
    url: 'admin/v1/feedback/profile-list',
    body:param
  })
}
//反馈处理
function feedbackDispose(param) {
  return xhr({
    url: 'admin/v1/feedback/process',
    body:param,
    method: "POST"
  })
}

export {
  opinionManageList,personManageList,feedbackDispose
}