import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  Icon,
  Dropdown,
  Avatar,
  Menu,
  Popover,
  Spin,
  Badge,
  notification,
  Table,
} from "antd";
import BasicLayout from "./BasicLayout";
import Exception403 from "pages/exception/403";
import Authorized from "utils/Authorized";
import userModel from "store/reducers/user";
import companyModel from "store/reducers/companyModel";
import socialCandModel from "store/reducers/socialCandModel";
import PositionVacantModal from "store/reducers/positionVacantM";
import global from "store/reducers/global";
import internModel from "store/reducers/internModel";
import { Redirect } from "react-router-dom";
import { clearAuthority } from "utils/authority";
import AddCompany from "pages/company/addCompanyForm.js";
import AddBusiness from "pages/business/AddBusiness";
import AddSocialResume from "pages/TalentPool/SocialCandidate/AddResume";
import AddInternResume from "pages/TalentPool/Intern/AddResume";
import RecommandToPos from "pages/TalentPool/RecommandToPos";
import ResumeEmailSend from "pages/TalentPool/ResumeEmailSend";
import AdvanceSearch from "components/AdvanceSearch";
import messageModel from "store/reducers/sysMessageModel";
import {
  SocialResumeImport,
  InternResumeImport,
} from "pages/TalentPool/ResumeImport.js";

import { history } from "root.js";

@connect(
  ({ global }) => {
    return { ...global };
  },
  {
    isLogin: userModel.actions.isLogin,
  }
)
export default class GeeLayout extends PureComponent {
  componentDidMount() {
    this.props.isLogin().then((res) => {});
  }

  render() {
    const { collapsed } = this.props;
    const logo = (
      <React.Fragment>
        <div className="left-logo">
          <img
            className={`${collapsed ? "logo-sm" : ""}`}
            src={require("assets/imgs/layout-logo.png")}
          />
          {!collapsed && <div className="logo-title">即派后台管理系统</div>}
        </div>
        <style jsx>
          {`
            .left-logo {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              height: ${collapsed ? 69 : 150}px;
              margin: 0;
              font-size: 12px;
              .logo-title {
                color: #fff;
              }
              img {
                margin-bottom: 20px;
                width: 46px;
                height: 47px;
              }
              img.logo-sm {
                width: 26px;
                height: 26px;
              }
            }
          `}
        </style>
      </React.Fragment>
    );
    return (
      <React.Fragment>
        <Authorized
          authority={[
            "admin",
            "business",
            "city_manager",
            "consultant",
            "consultant360",
            "channel",
            "operator",
            "team_leader",
            "group_leader",
            "special_group_leader",
            "soonfit_role",
          ]}
          noMatch={<Redirect to="/user/login" />}
        >
          <BasicLayout
            logo={logo}
            className="gee-layout"
            customHeader={<CustomHeader />}
            {...this.props}
          />
        </Authorized>
        <style jsx>
          {`
            :global(.gee-layout) :global(.layout-header) {
              position: relative;
              height: 50px;
              line-height: 48px;
            }
          `}
        </style>
      </React.Fragment>
    );
  }
}

@connect(
  ({ user, global, sysmessage }) => {
    return {
      unread: user.unread_message,
      userAuth: user.roles,
      username: user.name,
      avatar: user.avatar,
      drawerStack: global.drawerStack,
      globalLoading: global.globalLoading,
      topMessageList: sysmessage.topMessageList,
      topMessageLoading: sysmessage.topMessageLoading,
    };
  },
  {
    logout: userModel.actions.logout,
    updateAddCompanyVisible: companyModel.actions.updateAddCompanyVisible,
    updateCreateResumeDrawer: socialCandModel.actions.updateCreateResumeDrawer,
    updateSocialResumeImport: socialCandModel.actions.updateResumeImport,
    updateInternResumeDrawer: internModel.actions.updateCreateResumeDrawer,
    updateInternResumeImport: internModel.actions.updateResumeImport,
    updateStack: global.actions.updateStack,
    globalSearch: global.actions.getGlobalSearch,
    globalDispatch: global.actions.globalDispatch,
    getTopMessageList: messageModel.actions.getTopMessageList,
  }
)
class CustomHeader extends PureComponent {
  state = {
    currentLocation: history.location.pathname,
    busniseeModalVisible: false,
  };

  resumeHandleOptions = (
    <Menu>
      <Menu.Item>
        <a
          onClick={() => {
            history.push("/candidate/talent-pool/create/social");
          }}
        >
          社会人选
        </a>
      </Menu.Item>
      <Menu.Item>
        <a
          onClick={() => {
            history.push("/candidate/talent-pool/create/intern");
          }}
        >
          实习生
        </a>
      </Menu.Item>
    </Menu>
  );

  componentDidMount() {
    const { updateStack } = this.props;
    this.unlisten = history.listen((location, action) => {
      updateStack([]); // 路由切换的时候关掉所有的抽屉
      // 监听路由变化
      this.setState({
        currentLocation: location.pathname,
      });
    });
  }

  componentWillUnmount() {
    this.unlisten();
  }

  addCompany = () => {
    this.props.updateAddCompanyVisible(true);
  };

  addBusiness = () => {
    this.setState({
      busniseeModalVisible: true,
    });
  };

  handleBusinessCancel = () => {
    this.setState({
      busniseeModalVisible: false,
    });
  };

  noticeClick = (visible) => {
    if (visible) {
      this.props.getTopMessageList();
    }
    this.setState({
      noticeVisible: visible,
    });
  };

  moreClick = () => {
    if (
      this.state.currentLocation.indexOf("message") == -1 ||
      this.state.currentLocation.indexOf("message") > 5
    ) {
      history.push("/message");
    }
    this.setState({ noticeVisible: false });
  };

  logoutHandle = () => {
    this.props.logout().then((res) => {
      if (res.code === 0 && this.props.userAuth === "special_group_leader") {
        window.location.reload();
      }
    });
  };

  render() {
    const {
      username,
      avatar,
      userAuth,
      drawerStack,
      topMessageLoading,
      topMessageList,
      unread,
      globalSearch,
      globalDispatch,
      globalLoading,
    } = this.props;
    const { currentLocation, noticeVisible } = this.state;

    const menu = (
      <Menu className="user-down-menu" selectedKeys={[]}>
        <Menu.Item key="userCenter">
          <Link to="/center">
            <Icon type="user" style={{ marginRight: 5 }} />
            个人中心
          </Link>
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item key="logout" onClick={this.logoutHandle}>
          <span>
            <Icon type="logout" style={{ marginRight: 5 }} />
            退出登录
          </span>
        </Menu.Item>
      </Menu>
    );

    const noticeTitle = (
      <div className="notice-title">
        <span>消息</span>
        {topMessageList && topMessageList.length > 0 && (
          <span className="right more" onClick={this.moreClick}>
            查看更多
          </span>
        )}
      </div>
    );

    const noticeContent = (
      <Spin spinning={topMessageLoading}>
        {topMessageList && topMessageList.length > 0 ? (
          topMessageList.map((item) => (
            <div className="notice-item ellipsis">
              [{item.type_label}]{item.title}
            </div>
          ))
        ) : (
          <p>暂无更多消息</p>
        )}
      </Spin>
    );

    return (
      <React.Fragment>
        {userAuth &&
          userAuth !== "special_group_leader" &&
          userAuth !== "soonfit_role" && (
            <AdvanceSearch
              userAuth={userAuth}
              globalLoading={globalLoading}
              globalSearch={globalSearch}
              history={history}
              globalDispatch={globalDispatch}
            />
          )}
        <div className="user right">
          {userAuth !== "soonfit_role" && (
            <div className="quick-actions">
              <Dropdown
                overlay={this.resumeHandleOptions}
                overlayStyle={{ paddingTop: 10 }}
                className="action create-candidate"
              >
                <span className="action create-candidate">
                  <i className="ope-icon">
                    <Icon type="plus" theme="outlined" />
                  </i>
                  新增候选人
                </span>
              </Dropdown>
              {userAuth != "consultant" && userAuth != "channel" && (
                <span
                  className="action add-business"
                  onClick={this.addBusiness}
                >
                  <i className="ope-icon">
                    <Icon type="bulb" theme="outlined" />
                  </i>
                  新增商机
                </span>
              )}
              {userAuth != "operator" &&
                userAuth != "group_leader" &&
                userAuth != "consultant" &&
                userAuth != "consultant360" &&
                userAuth != "channel" && (
                  <span className="action add-client" onClick={this.addCompany}>
                    <i className="ope-icon">
                      <Icon type="user" theme="outlined" />
                    </i>
                    新增客户
                  </span>
                )}
            </div>
          )}
          {userAuth !== "soonfit_role" && (
            <Popover
              title={noticeTitle}
              content={noticeContent}
              placement="top"
              onVisibleChange={this.noticeClick}
              visible={noticeVisible}
              className="message-icon"
              trigger="click"
              overlayClassName="notice-popover"
            >
              <Badge count={unread}>
                <Icon type="bell" className="unread-badge" />
              </Badge>
            </Popover>
          )}
          <Dropdown overlay={menu}>
            <span className="account">
              <Avatar
                className="avatar"
                src={avatar ? avatar : require("assets/imgs/icon-toux.png")}
                alt="avatar"
              />
              <span className="name">{username}</span>
            </span>
          </Dropdown>
        </div>
        {currentLocation.indexOf("custom/company") != -1 ? null : (
          userAuth !== undefined && userAuth !== "soonfit_role" && <React.Fragment>
            <AddCompany />
          </React.Fragment>
        )}
        {/* 防止在迅致角色的时候加载组件调用静态接口泄露组织架构信息 */}
        {userAuth !== undefined && userAuth !== "soonfit_role" && <AddBusiness
          visible={this.state.busniseeModalVisible}
          onClose={this.handleBusinessCancel}
        />}
        {/* {currentLocation.indexOf("candidate/talent-pool") != -1 ? null : (
          <React.Fragment>
            <AddSocialResume />
            <AddInternResume />
            <SocialResumeImport />
            <InternResumeImport />
          </React.Fragment>
        )} */}
        <div className="drawer-stack-wrapper">
          {drawerStack &&
            drawerStack.map((item, index) => {
              console.log("okok", item);
              let ComponentRef = item.componentRef;
              return (
                <div key={index}>
                  <ComponentRef {...item.componentProps} />
                </div>
              );
            })}
        </div>
        <RecommandToPos />
        <ResumeEmailSend />
        <style jsx>
          {`
            .quick-actions {
              margin-left: 76px;
              margin-right: 30px;
              display: inline-block;
              vertical-align: top;
              .action {
                cursor: pointer;
                margin-right: 30px;
                i {
                  display: inline-block;
                  vertical-align: middle;
                  margin-bottom: 1px;
                  width: 24px;
                  height: 24px;
                  background-color: #00dc94;
                  border-radius: 3px;
                  text-align: center;
                  margin-right: 10px;
                  :global(.anticon) {
                    color: #fff;
                    vertical-align: top;
                    margin-top: 5px;
                    font-size: 13px;
                  }
                }
              }
              .add-business i {
                background-color: #38a4e9;
              }
              .add-client i {
                background-color: #fa9965;
              }
            }
            .user {
              padding-right: 26px;
              color: #666666;
              :global(.avatar) {
                margin-right: 10px;
              }
              :global(.message-icon) {
                display: inline-block;
                margin-right: 20px;
                font-size: 18px;
                vertical-align: middle;
              }
            }
            .custom-header {
              display: inline-block;
              vertical-align: top;
              width: 100%;
              padding-left: 68px;
            }
          `}
        </style>
        <style jsx global>{`
          .message-icon {
            cursor: pointer;
          }
          .notice-popover {
            width: 300px;
            .ant-popover-arrow {
              display: none;
            }
            .notice-title {
              height: 45px;
              line-height: 45px;
              .more {
                cursor: pointer;
                color: #999;
              }
            }
            .notice-item {
              height: 30px;
              line-height: 30px;
            }
          }
          .message-icon {
            .ant-badge-count {
              z-index: 0;
            }
          }
        `}</style>
      </React.Fragment>
    );
  }
}
