import Model from "../Model";
import * as messageManageS from "services/messageManageS";
export default Model.getInstance(
  class extends Model {
    namespace = "MessageManageModal";
    state = {
      visibleMessage: false,
      dataList: {
        loadingStatus: false //加载状态
      },
      messageTitle: {}
    };

    actions = {
      async getList(param = {}) {
        this.dispatch({
          type: "MessageManageModal/setUpdateState",
          payload: { filed: "dataList", value: { loadingStatus: true } }
        });
        const expand = "send_mode_name,send_obj_type_name,type_name,send_num";
        let result = await messageManageS.messageManageList({
          ...param,
          expand
        });
        if (result) {
          this.dispatch({
            type: "MessageManageModal/setUpdateState",
            payload: {
              filed: "dataList",
              value: { ...result.data, loadingStatus: false }
            }
          });
        }
      },
      async messageAdd(param) {
        let result = await messageManageS.messageManageAdd({ ...param });
        if (result.code === 0) {
          this.dispatch({
            type: "MessageManageModal/drawerOpen"
          });
        }
        return result;
      },
      async setStateA(param) {
        this.dispatch({
          type: "MessageManageModal/setUpdateState",
          payload: param
        });
      }
    };

    reducers = {
      drawerOpen(state) {
        state.visibleMessage = !state.visibleMessage;
        return { ...state };
      },
      setUpdateState(state, { payload }) {
        const { filed, value } = payload;
        state[filed] = value;
        return { ...state };
      }
    };
  }
);
