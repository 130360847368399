import Model from "../Model";
import * as businessService from "services/businessService";
import * as companyService from "services/companyService";
import * as commonService from "services/commonService";
import { message } from "antd";

export default Model.getInstance(
  class extends Model {
    namespace = "company";
    state = {
      commonData: {},
      companyList: [],
      companyListCondition: {},
      companyListPagination: {
        current: 1,
        pageSize: 5,
        showSizeChanger: true,
        showQuickJumper: true,
        showTotal: total => `总计${total}个`
      },
      companyLoading: false,
      companyVisible: false,
      addCompanyVisible: false,
      companyDetail: {
        company_address: {}
      },
      companyNameList: {},
      contract: {},
      contractPagination: {
        current: 1,
        pageSize: 10
      },
      jd: {},
      record: {},
      contact: {}
    };

    actions = {
      async getCommonData(dataName) {
        let commonData = await commonService.commonData(dataName);
        this.dispatch({
          type: "company/updateCommon",
          payload: commonData
        });
      },
      async getCompanyList(param, companyListCondition = {}) {
        param.expand = "bd_name,bd_org_name,hh_name,hh_org_name,city_name,industry_name,contacts,jds,jd_profiles,add_time_str,last_follow_time,new_follow_record,contract_record";
        this.dispatch({
          type: "company/updateLoading",
          payload: true
        });
        this.dispatch({
          type: "company/updateCondition",
          payload: companyListCondition,
        });
        let result = await companyService.companyList(param);
        if (result.code == 0) {
          // result.data.list && result.data.list.forEach(item => item.popupVisible = false)
          this.dispatch({
            type: "company/updateList",
            payload: result.data
          });
          this.dispatch({
            type: "company/updatePagination",
            payload: {
              total: result.data._meta && result.data._meta.totalCount
            }
          });
        }
        this.dispatch({
          type: "company/updateLoading",
          payload: false
        });
      },
      async addCompany(param) {
        let result = await companyService.insertCompany(param);
        if (result.code == 0) {
          message.info("创建成功！");
        }
        return result;
      },
      async getCompanyDetail(param, isShow, callback) {
        param.expand = "source_p,source_name,bd_name,bd_org_name,hh_name,hh_org_name,add_time_str,jd_processing_num,company_type_name,industry_name,scale_name,company_address,manager_team,org_id,city_id,hh_org_id,hh_city_id,status_name,hh_org_info,bd_org_info,origin_type_name,add_by_name,add_by_org_name";
        let result = await companyService.companyDetail(param);
        if (result.code == 0) {
          this.dispatch({
            type: "company/updateCompanyDetail",
            payload: result.data,
            isShow: isShow
          });
          if (callback) callback();
          let contractResult = await companyService.getContractList({
            expand: "type_name,bd_name",
            company_id: result.data.id,
            "per-page": 10
          });
          if (contractResult.code == 0) {
            this.dispatch({
              type: "company/updateContract",
              payload: contractResult.data
            });
          }
        }
        return result;
      },
      async modCompany(param) {
        let result = await companyService.modifyCompany(param);
        if (result.code == 0) {
          this.dispatch({
            type: "company/editCompanyDetail",
            payload: param
          });
          message.info("修改成功！");
        }
        return result;
      },
      async modPos(id, param) {
        let result = await businessService.modifyJD(id, param);
        if (result.code == 0) {
          this.dispatch({
            type: "business/editPosDetail",
            payload: param
          });
          message.info("修改成功！");
        }
        return result;
      },
      async addContract(param) {
        let result = await companyService.insertCompanyContract(param);
        let companyDetail = this.getState().company.companyDetail;
        if (result.code == 0) {
          message.info("添加成功！");
          let contractParam = {
            expand: "type_name,bd_name",
            company_id: companyDetail.id,
            "per-page": 10
          };
          if (param.norefresh) return result;
          let contractResult = await companyService.getContractList(
            contractParam
          );
          if (contractResult.code == 0) {
            this.dispatch({
              type: "company/updateContract",
              payload: contractResult.data
            });
          }
        }
        return result;
      },
      async getContractList(param) {
        let contractResult = await companyService.getContractList(param);
        if (contractResult.code == 0) {
          this.dispatch({
            type: "company/updateContract",
            payload: contractResult.data
          });
        }
        return contractResult;
      },
      async getJDList(param) {
        param.expand =
          "position_name,service_type_name,status_name,progress_days,hh_name,hh_org_name";
        let result = await companyService.getJDList(param);
        if (result.code == 0) {
          this.dispatch({
            type: "company/updateJd",
            payload: result.data
          });
        }
        return result;
      },
      async getContactList(param) {
        let result = await companyService.getContactList(param);
        if (result.code == 0) {
          this.dispatch({
            type: "company/updateContact",
            payload: result.data
          });
        }
        return result;
      },
      async getChatList(param) {
        param.expand = "create_time_str,create_by_name,status_name,create_by_org_name";
        let result = await companyService.chatList(param);
        if (result.code == 0) {
          this.dispatch({
            type: "company/updateChat",
            payload: result.data
          });
        }
        return result;
      },
      async addChat(param) {
        let result = await companyService.insertChat(param);
        if (result.code == 0) {
          message.info("添加成功！");
          let chatresult = await companyService.chatList({
            expand: "create_time_str,create_by_name,status_name,create_by_org_name",
            company_id: param.company_id,
            "per-page": 6,
            page: param.currentPage
          });
          if (chatresult.code == 0) {
            this.dispatch({
              type: "company/updateChat",
              payload: chatresult.data
            });
          }
        }
        return result;
      },
      async modContact(param) {
        let result = await companyService.modContact(param);
        if (result.code == 0) {
          // message.info("联系人操作成功！");
          if (!param.fromBusiness) {  // 从商机来的不查询
            let chatresult = await companyService.getContactList({
              expand: "sex_name",
              company_id: param.company_id,
              "per-page": 10
            });
            if (chatresult.code == 0) {
              this.dispatch({
                type: "company/updateContact",
                payload: chatresult.data
              });
            }
          }
        }
        return result;
      },
      async getCompanyByNameList(param) {
        // 重名列表
        let result = await companyService.companyByNameList(param);
        return result;
      },
      async changeCompanyType(param) {
        let result = await companyService.changeCompanyType(param);
        if (result.code == 0) {
          message.info("操作成功！");
        }
        return result;
      },
      async changeManager(param) {
        let result = await companyService.changeManager(param);
        if (result.code == 0) {
          message.info("操作成功！");
          // this.dispatch({
          //   type: "company/updateManager",
          //   payload: param
          // });
        }
        return result;
      }
    };

    reducers = {
      updateCommon(state, { payload: data }) {
        return { ...state, commonData: data };
      },
      updateField(
        state,
        {
          payload: { field, value }
        }
      ) {
        state[field] = value;
        return { ...state };
      },
      updateList(state, { payload: data }) {
        return { ...state, companyList: data };
      },
      updateCondition(state, { payload: data }) {
        return {
          ...state,
          companyListCondition: data
        };
      },
      updatePagination(state, { payload: data }) {
        return {
          ...state,
          companyListPagination: { ...state.companyListPagination, ...data }
        };
      },
      updateCompanyDetail(state, { payload: data, isShow }) {
        return { ...state, companyDetail: data, companyVisible: isShow };
      },
      updateCompanyType(state, { payload: data }) {
        state.companyDetail.type = data;
        return { ...state};
      },
      updateCompanyVisible(state, { payload: data }) {
        return { ...state, companyVisible: data };
      },
      updateAddCompanyVisible(state, { payload: data }) {
        return { ...state, addCompanyVisible: data };
      },
      updateCompanyList(state, { payload: data }) {
        // 重名列表
        return { ...state, companyNameList: data };
      },
      updateContract(state, { payload: data }) {
        return {
          ...state,
          contract: data,
          contractPagination: {
            ...state.contractPagination,
            total: data._meta && data._meta.totalCount
          }
        };
      },
      updateJd(state, { payload: data }) {
        return { ...state, jd: data };
      },
      updateChat(state, { payload: data }) {
        return { ...state, record: data };
      },
      updateContact(state, { payload: data }) {
        return { ...state, contact: data };
      },
      editCompanyDetail(state, { payload: data }) {
        state.companyDetail.name = data.name;
        state.companyDetail.logo = data.logo;
        state.companyDetail.other_name = data.other_name;
        state.companyDetail.desc = data.desc;
        state.companyDetail.company_type = data.company_type_labelValue &&
          data.company_type_labelValue.key;
        state.companyDetail.company_type_name = data.company_type_labelValue &&
          data.company_type_labelValue.label;
        state.companyDetail.industry = data.industry_labelValue && 
          data.industry_labelValue.key;
        state.companyDetail.industry_name = data.industry_labelValue && 
          data.industry_labelValue.label;
        state.companyDetail.scale = data.scale_labelValue && data.scale_labelValue.key;
        state.companyDetail.scale_name = data.scale_labelValue && data.scale_labelValue.label;
        state.companyDetail.company_address.province_name = data.cityName[0] ? data.cityName[0] : "";
        if (data.cityName[1]) {
          data.cityName[1] = "天津北京重庆上海".indexOf(data.cityName[1]) != -1 ? "" : data.cityName[1]
        } else {
          data.cityName[1] = "";
        }
        state.companyDetail.company_address.city_name = data.cityName[1];
        state.companyDetail.company_address.region_name = data.cityName[2] ? data.cityName[2] : "";
        state.companyDetail.company_address.province_id = data.city[0] ? data.city[0] : 0;
        state.companyDetail.company_address.city_id = data.city[1] ? data.city[1] : 0;
        state.companyDetail.company_address.region_id = data.city[2] ? data.city[2] : 0;
        state.companyDetail.company_address.address = data.address;
        state.companyDetail.company_address.contact_no = data.contact_no;
        return { ...state };
      },
      editPosDetail(state, { payload: data }) {
        // state.businessDetail.company.name = data.company_name;
        // state.businessDetail.company.company_type_name = data.company_type_labelValue.label;
        // state.businessDetail.company.industry_name = data.industry_labelValue.label;
        // state.businessDetail.company.scale_name = data.scale_labelValue.label;
        // state.businessDetail.company.province_name = data.cityName[0];
        // state.businessDetail.company.city_name = data.cityName[1];
        // state.businessDetail.company.region_name = data.cityName[2];
        // state.businessDetail.company.province_id = data.city[0];
        // state.businessDetail.company.city_id = data.city[1];
        // state.businessDetail.company.region_id = data.city[2];
        // state.businessDetail.company.address = data.address;
        return { ...state };
      },
      updateIndexData(state, { payload: index }) {
        // state.clueList.list[index].
        return { ...state };
      },
      updateLoading(state, { payload: data }) {
        return { ...state, companyLoading: data };
      },
      updateManager(state, { payload: data }) {
        state.companyDetail.bd_id = data.bd_ids && data.bd_ids[2];
        state.companyDetail.org_id = data.bd_ids && data.bd_ids[1];
        state.companyDetail.city_id = data.bd_ids && data.bd_ids[0];
        state.companyDetail.bd_name = data.bd_id_name[2];
        state.companyDetail.bd_org_name = data.bd_id_name[1];
        state.companyDetail.hh_id = data.hh_ids && data.hh_ids[2];
        state.companyDetail.hh_org_id = data.hh_ids && data.hh_ids[1];
        state.companyDetail.hh_city_id = data.hh_ids && data.hh_ids[0];
        state.companyDetail.hh_name = data.hh_id_name[2];
        state.companyDetail.hh_org_name = data.hh_id_name[1];
        // state.companyDetail.source = data.source_labelValue && data.source_labelValue.key;
        // state.companyDetail.source_name = data.source_labelValue && data.source_labelValue.label;
        if (data.source_labelValue.length > 1) {
          state.companyDetail.source = data.source_labelValue[1];
          state.companyDetail.source_p = data.source_labelValue[0];
        } else {
          state.companyDetail.source = data.source_labelValue[0];
          state.companyDetail.source_p = "";
        }
        state.companyDetail.source_name = data.source_name.join("-");
        // state.companyDetail.manager_team =
        //   data.bd_id_name[0] + "-" + data.bd_id_name[1];
        return { ...state };
      }
    };
  }
);
