import React, { PureComponent } from "react";
import classnames from "classnames";
import { DatePicker } from "antd";

export default function datePickerSofarWrap(DatePicker) {
  return class extends PureComponent {
    extraFooter = () => (
      <a
        className="ant-calendar-month-panel-month"
        onClick={this.onSelectSofar}
      >
        至今
      </a>
    );

    defaultPlaceholder = "请选择时间";

    state = {
      open: false,
      sofarVisible: false,
      placeholder: this.defaultPlaceholder,
      value: undefined
    };

    static getDerivedStateFromProps(props, state) {
      if (props.value !== state.propValue) {
        if (props.value === "至今") {
          return {
            sofarVisible: true
          };
        }
        return {
          value: props.value,
          propValue: props.value
        };
      }
      if (props.placeholder !== state.propPlaceholder) {
        return {
          placeholder: props.placeholder,
          propPlaceholder: props.placeholder
        };
      }
      return null;
    }

    onSelectSofar = () => {
      this.selectSofar();
    };

    selectSofar = () => {
      const { onChange } = this.props;
      this.setState({
        sofarVisible: true,
        open: false,
        value: undefined
      });
      onChange("至今", "至今");
    };

    clearSofar = () => {
      this.setState({
        sofarVisible: false,
        open: false
      });
    };

    onSofarClick = () => {
      this.setState({ open: true });
    };

    onDatePickerFocus = () => {
      this.setState({
        open: true
      });
    };

    onDatePickerBlur = () => {
      this.setState({
        open: false
      });
    };

    onDatePickerChange = (date, dateString) => {
      const { onChange } = this.props;
      this.clearSofar();
      onChange(date, dateString);
    };

    onOpenChange = status => {
      this.setState({
        open: status
      });
    };

    render() {
      let { open, placeholder, sofarVisible, value } = this.state;
      placeholder = sofarVisible ? "" : placeholder;
      const { className, style, ...props } = this.props;
      return (
        <span
          className={classnames("data-picker-sofar-wrap", className)}
          style={style}
        >
          {sofarVisible ? (
            <div className="sofar" onClick={this.onSofarClick}>
              至今
            </div>
          ) : null}
          <DatePicker
            {...props}
            renderExtraFooter={this.extraFooter}
            onChange={this.onDatePickerChange}
            open={open}
            onOpenChange={this.onOpenChange}
            value={value}
            placeholder={placeholder}
          />
          <style jsx>{`
            .data-picker-sofar-wrap {
              position: relative;
              display: inline-block;
              .sofar {
                position: absolute;
                z-index: 1;
                padding-left: 20px;
              }
            }
          `}</style>
        </span>
      );
    }
  };
}

export const MonthPickerSofar = datePickerSofarWrap(DatePicker.MonthPicker);
