import React, { PureComponent } from "react";
import ProgressFlow from "./progress-flow";
import ProgressProfile from "./progress-profile";
import ProgressProfileRecord from "./progress-profile-record";

const progress = () => (
  <React.Fragment>
    <ProgressFlow/>
    <ProgressProfile/>
    <ProgressProfileRecord/>
  </React.Fragment>
)

export default progress;