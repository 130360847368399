import xhr from './index.js'

// 列表
export function commentBusinessList(param) {
  return xhr({
    url: 'admin/v1/gbox-user/reviews',
    body: param,
    method: "get"
  })
}
export function commentPoint(param) {
  return xhr({
    url: 'admin/v1/gbox-user/reviews-op',
    body: param,
    method: "post"
  })
}
// 列表
export function shareBusinessList(param) {
  return xhr({
    url: 'admin/v1/gb-businesses',
    body: param,
    method: "get"
  })
}
// 新增
export function insertShareBusiness(param) {
  return xhr({
    url: 'admin/v1/gb-businesses',
    body: param,
    method: "post"
  })
}
// 编辑
export function modifyShareBusiness(param) {
  return xhr({
    url: 'admin/v1/gb-businesses/' + param.id,
    body: param,
    method: "put"
  })
}
// 审核未通过
export function reviewBusiness(param) {
  return xhr({
    url: 'admin/v1/gb-business/review',
    body: param,
    method: "POST"
  })
}
// 详情
export function shareBusinessDetail(param) {
  return xhr({
    url: 'admin/v1/gb-businesses/' + param.id,
    body: param,
    method: "get"
  })
}

// 列表
export function getBusinessData(param) {
  return xhr({
    url: 'admin/v1/gb-business/data',
    body: param,
    method: "get"
  })
}
// gbox用户列表
export function gboxList(param) {
  return xhr({
    url: 'admin/v1/gbox-users',
    body: param,
    method: "get"
  })
}
// gbox用户详情
export function gboxDetail(param) {
  return xhr({
    url: 'admin/v1/gbox-users/' + param.id,
    body: param,
    method: "get"
  })
}
// gbox用户详情合作审核
export function gboxCooperationReview(param) {
  return xhr({
    url: 'admin/v1/gbox-user/cooperation-review',
    body: param,
    method: "post"
  })
}
// gbox用户详情服务商审核
export function gboxCompanyReview(param) {
  return xhr({
    url: 'admin/v1/gbox-company/review',
    body: param,
    method: "post"
  })
}
// gbox用户详情服务商入驻、取消入驻
export function gboxServiceProvider(param) {
  return xhr({
    url: 'admin/v1/gbox-company/service-provider',
    body: param,
    method: "post"
  })
}
// gbox用户详情服务商顾问展示、取消展示
export function gboxServiceUser(param) {
  return xhr({
    url: 'admin/v1/gbox-user/service-user',
    body: param,
    method: "post"
  })
}
// gbox服务商详情
export function gboxCompanyDetail(param) {
  return xhr({
    url: 'admin/v1/gbox-companies/' + param.id,
    body: param,
    method: "get"
  })
}
// gbox服务商详情合作信息
export function gboxCompanyDetailCooperations(param) {
  return xhr({
    url: 'admin/v1/gbox-company/cooperations',
    body: param,
    method: "get"
  })
}
// gbox服务商详情关联用户列表
export function gboxCompanyDetailUser(param) {
  return xhr({
    url: 'admin/v1/gbox-company/users',
    body: param,
    method: "get"
  })
}
// gbox服务商编辑
export function gboxCompanyEdit(param) {
  return xhr({
    url: 'admin/v1/gbox-companies/' + param.id,
    body: param,
    method: "put"
  })
}
// gbox用户修改积分
export function gboxChangeScore(param) {
  return xhr({
    url: 'admin/v1/point-logs',
    body: param,
    method: "post"
  })
}
// gbox是否允许登录
export function loginSet(param) {
  return xhr({
    url: 'admin/v1/gbox-user/set-status',
    body: param,
    method: "post"
  })
}
// gbox积分详情
export function scoreLog(param) {
  return xhr({
    url: 'admin/v1/point-logs',
    body: param,
    method: "get"
  })
}
// 共享商机数据
export function businessData(param) {
  return xhr({
    url: 'admin/v1/gb-business/data',
    body: param,
    method: "get"
  })
}
// 获取已认证用户公司列表
export function attestationCompany(param) {
  return xhr({
    url: 'admin/v1/gbox-companies',
    body: param,
    method: "get"
  })
}
// 获取认证审核历史记录
export function reviewLog(param) {
  return xhr({
    url: 'admin/v1/gbox-user/review-log',
    body: param,
    method: "get"
  })
}
// 用户认证审核
export function review(param) {
  return xhr({
    url: 'admin/v1/gbox-user/review',
    body: param,
    method: "post"
  })
}
// 合作信息删除
export function gboxCooperationDel(param) {
  return xhr({
    url: 'admin/v1/gbox-user/cooperation-del',
    body: param,
    method: "post"
  })
}

// 线索商机不合适
export function gboxNotSuit(param) {
  return xhr({
    url: 'admin/v1/gb-business/not-geebox',
    body: param,
    method: "post"
  })
}
// 合作留言、回复列表
export function reviewsList(param) {
  return xhr({
    url: 'admin/v1/gbox-user/cooperation-review-list',
    body: param,
    method: "get"
  })
}
// 删除合作留言、回复列表
export function reviewsDel(param) {
  return xhr({
    url: 'admin/v1/gbox-user/cooperation-review-del',
    body: param,
    method: "get"
  })
}

export function conversationList(param) {
  return xhr({
    url: 'admin/v1/gbox-user/conversation-list',
    body: param,
    method: "get"
  })
}

export function complaintList(param) {
  return xhr({
    url: 'admin/v1/gb-business/complaint-record',
    body: param,
    method: "get"
  })
}

export function complaintOffiline(param) {
  return xhr({
    url: 'admin/v1/gb-business/complaint-offline',
    body: param,
    method: "POST"
  })
}

export function cooperationEdit(param) {
  return xhr({
    url: 'admin/v1/gbox-user/cooperation-edit',
    body: {
      ...param,
    },
    method: "POST"
  })
}
//发放红包
export function sendPackage(param) {
  return xhr({
    url: 'admin/v1/gbox-user/grant-red-envelope',
    body: param,
    method: "POST"
  })
}

//乙方合作列表
export function getCooperationList(param) {
  const {city, cooperation_type, page, talents_type, function_type, type, pageSize, local_city, business_type, review_time_start,review_time_end } = param;
  let talents_type_str = '';
  let cooperation_type_str = '';
  let city_str = '';
  let local_city_str = '';
  let business_type_str = '';
  let function_type_str = ''
  let review_time_start_str = review_time_start?`&review_time_start=${review_time_start}`:''
  let review_time_end_str = review_time_end?`&review_time_end=${review_time_end}`:''
  talents_type && talents_type.forEach(v=>{
    talents_type_str += `&talents_type[]=${v}`;
  })
  city && city.forEach(v=>{
    city_str += `&city[]=${v}`;
  })
  cooperation_type && cooperation_type.forEach(v=>{
    cooperation_type_str += `&cooperation_type[]=${v}`;
  })
  local_city && local_city.forEach(v=>{
    local_city_str += `&local_city[]=${v}`;
  })
  business_type && business_type.forEach(v=>{
    business_type_str += `&business_type[]=${v}`;
  })
  function_type && function_type.forEach(v=>{
    function_type_str += `&function_type[]=${v}`;
  })
  
  return xhr({
    url: `admin/v1/gb-cooperations?&page=${page}&per-page=${pageSize}&type=${type}${talents_type_str}${function_type_str}${cooperation_type_str}${city_str}${local_city_str}${business_type_str}${review_time_start_str}${review_time_end_str}`,
    method: "get"
  })
}

//乙方合作详情
export function getCooperationdetail(param) {
  return xhr({
    url: 'admin/v1/gb-cooperations/' + param.id,
    body: param,
    method: "get"
  })
}

//在线沟通列表
export function getConversation(param) {
  return xhr({
    url: 'admin/v1/gbox-user/conversation-cooperation-list',
    body: param,
    method: "get"
  })
}

//乙方用户-详情-行为信息
export function getCooperationReviewList(param) {
  return xhr({
    url: 'admin/v1/gbox-user/cooperation-review-list',
    body: param,
    method: "get"
  })
}

//乙方用户-详情-行为信息
export function deleteCooperationReview(param) {
  return xhr({
    url: 'admin/v1/gbox-user/cooperation-review-del',
    body: param,
    method: "get"
  })
}

//乙方用户-详情-客服备注-列表
export function getCooperationRemarkList(param) {
  return xhr({
    url: 'admin/v1/gbox-user/remark-list',
    body: param,
    method: "get"
  })
}

//乙方用户-详情-客服备注-添加
export function addCooperationRemark(param) {
  return xhr({
    url: 'admin/v1/gbox-user/remark-add',
    body: param,
    method: "post"
  })
}

//乙方用户-详情-客服备注-添加
export function delCooperationRemark(param) {
  return xhr({
    url: 'admin/v1/gbox-user/remark-del',
    body: param,
    method: "post"
  })
}


//更改用户权限
export function changeUserAuth(param) {
  return xhr({
    url: 'admin/v1/gbox-user/auth-set',
    body: param,
    method: "POST"
  })
}

//合作下线
export function cooperationOffline(param) {
  return xhr({
    url: 'admin/v1/gbox-user/cooperation-offline',
    body: param,
    method: "POST"
  })
}

//修改用户身份信息
export function editUserInfo(param) {
  return xhr({
    url: `admin/v1/gbox-users/${param.id}`,
    body: param,
    method: "put"
  })
}
//修改甲方用户身份信息
export function editHrUserInfo(param) {
  return xhr({
    url: `admin/v1/gbox-user/update-hr-user`,
    body: param,
    method: "post"
  })
}

// 下载合作图表数据
export function downloadCooperation() {
  return xhr({
    url: `admin/v1/gb-cooperation/download`,
    method: 'GET'
  })
}

// 获取状态变更记录&联系次数变更记录
export function getBusinessStatusLog(id) {
  return xhr({
    url: `admin/v1/gb-business/status-log?business_id=${id}`,
    method: 'GET'
  })
}

export function setBusinessOffline(params) {
  return xhr({
    url: `admin/v1/gb-business/offline`,
    method: 'POST',
    body: params
  })
}

/**
 * 获取分销业务列表
 * @param {*} params 
 * @returns 
 */
export function getSaleList(params) {
  return xhr({
    url: `admin/v1/gbox-company/sales-list`,
    method: 'GET',
    body: params
  })
}

export function editRmdStatus(params) {
  return xhr({
    url: `admin/v1/gbox-company/sales-rmd-status-change`,
    method: 'POST',
    body: params
  })
}

export function editSale(params) {
  return xhr({
    url: `admin/v1/gbox-company/sales-edit`,
    method: 'POST',
    body: params
  })
}
