import xhr from "./index.js";

// 列表
export function candList(param) {
  return xhr({
    url: "admin/v1/profile-manage/list",
    body: param,
    method: "POST",
  });
}

//详情
export function candDetail(id) {
  return xhr({
    url: "admin/v1/profile-manage/info",
    body: { id }
  });
}

//编辑基本信息
export function editBaseInfo(param) {
  param.edit_type = "basic";
  return xhr({
    url: "admin/v1/profile-manage/edit-info",
    body: param,
    method: "POST",
    needMessage: false
  });
}

//编辑求职状态
export function editJobStatus(param) {
  param.edit_type = "status";
  return xhr({
    url: "admin/v1/profile-manage/edit-info",
    body: param,
    method: "POST"
  });
}

//添加或新增教育经历
export function editOrAddEducation(param) {
  return xhr({
    url: "admin/v1/profile-manage/add-education",
    body: param,
    method: "POST"
  });
}

//添加或新增工作经历
export function editOrAddJobExperience(param) {
  return xhr({
    url: "admin/v1/profile-manage/add-job",
    body: param,
    method: "POST"
  });
}

//添加或新增证书
export function editOrAddCertificate(param) {
  return xhr({
    url: "admin/v1/profile-manage/add-cert",
    body: param,
    method: "POST"
  });
}

//删除各项
export function removeItem(param) {
  return xhr({
    url: "admin/v1/profile-manage/remove-record",
    body: param,
    method: "POST"
  });
}

//新增社会人选
export function candCreate(param) {
  return xhr({
    url: "admin/v1/profile-manage/creat-profile",
    body: param,
    method: "POST",
    needMessage: false
  });
}

//提交审核
export function commitAudit(param) {
  return xhr({
    url: "admin/v1/profile-audit/to-verify",
    body: param,
    method: "POST"
  });
}

//添加沟通记录
export function addCommunicateRecord(param) {
  return xhr({
    url: "admin/v1/profile-manage/create-record",
    body: param,
    method: "POST"
  });
}

//删除沟通记录
export function deleteCommunicateRecord(param) {
  return xhr({
    url: "admin/v1/profile-manage/del-communication",
    body: param,
    method: "POST"
  });
}

//获取推荐职位列表
export function getRecomPosList(param) {
  param.status = 2;
  param.type = 1;
  return xhr({
    url: "admin/v1/jd/list",
    body: param,
    method: "POST"
  });
}

//推荐到职位
export function recommandToPos(param) {
  return xhr({
    url: "/admin/v1/jd-profiles",
    body: param,
    method: "POST"
  });
}

//人选修改历史
export function candModifyRecords(param) {
  return xhr({
    url: "admin/v1/profile-manage/log",
    body: param,
    method: "GET"
  });
}

//编辑或新增项目经历
export function editOrAddProExperience(param){
  return xhr({
    url: "admin/v1/profile-manage/add-project",
    body: param,
    method: "POST"
  });
}

//获取预览简历详情
export function getProfileDetail(param){
  return xhr({
    url: "admin/v1/profile-manage/preview",
    body: param,
    method: "GET"
  });
}

// 获取职位列表
export function getJDs(param){
  return xhr({
    url: "admin/v1/jd/list",
    body: param,
    method: "POST"
  });
}

// 简历覆盖
export function coverProfiles(param){
  return xhr({
    url: "admin/v1/profile-manage/cover-profile",
    body: param,
    method: "POST"
  });
}

// 收藏/取消收藏人选
export function isCollect(param){
  return xhr({
    url: "admin/v1/profile-manage/collect",
    body: param,
    method: "POST"
  });
}
//每日都有新简历
export function newResumeList(param) {
  return xhr({
    url: "admin/v1/profile-manage/date-list",
    body: param,
    method: "GET"
  });
}
//导出沟通记录
export function exportRecords(param) {
  return xhr({
    url: "admin/v1/profile-manage/export",
    body: param,
    method: "GET"
  });
}
//导入人选新增北伐
export function northern(param) {
  return xhr({
    url: "admin/v1/profile-manage/northern-expedition",
    body: param,
    method: "POST"
  });
}
//导入人选新增北伐
export function socialExport(param) {
  return xhr({
    url: "admin/v1/profile-manage/export",
    body: param,
    method: "POST"
  });
}

//迅致人选列表
export function soonfitCandList(param) {
  return xhr({
    url: "admin/v1/soonfit-profile/list",
    body: param,
    method: "POST",
  });
}

//迅致人选详情
export function soonfitCandDetail(id) {
  return xhr({
    url: "admin/v1/soonfit-profile/info",
    body: { id }
  });
}

//获取迅致预览简历详情
export function getSoonfitProfileDetail(param){
  return xhr({
    url: "admin/v1/soonfit-profile/preview",
    body: param,
    method: "GET"
  });
}

//获取迅致简历联系方式
export function getSoonfitResumeContact(id){
  return xhr({
    url: "admin/v1/soonfit-profile/view-contact",
    body: { id }
  });
}