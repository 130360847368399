import React from "react";
import { Button } from "antd";

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    this.setState({ hasError: true });
  }

  render() {
    if (this.state.hasError) {
      return <ClientErrorFallback />;
    }
    return this.props.children;
  }
}

class ClientErrorFallback extends React.Component {
  render() {
    const { history } = this.props;
    return (
      <div className="abnormal">
        <div className="abnormal500-box bg">
          <div className="container">
            <div className="abnormal-show-box clear">
              <div className="left-show mg">
                <div className="img-box">
                  <div className="img500" />
                </div>
              </div>
              <div className="right-show">
                <div className="title">错误</div>
                <div className="des">抱歉，客户端发送未知错误</div>
                <div className="btn">
                  <Button type="primary" onClick={() => history.push("/")}>
                    返回首页
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <style jsx global>
            {`
              .abnormal {
                .bg {
                  background: #f0f5f8;
                  height: 100vh;
                }
                .abnormal-show-box {
                  padding: 231px 0;
                  .left-show {
                    width: 500px;
                    float: left;
                    margin: 0 67px 0 140px;
                  }
                  .right-show {
                    width: 300px;
                    float: left;
                    margin-top: 86px;
                    .title {
                      font-size: 72px;
                      color: #666666;
                      margin-bottom: 15px;
                    }
                    .des {
                      font-size: 24px;
                      line-height: 24px;
                      color: #999999;
                      margin-bottom: 19px;
                    }
                  }
                }
                .abnormal500-box {
                  .img-box {
                    width: 471px;
                    height: 216px;
                    margin-top: 87px;
                    .img500 {
                      width: 100%;
                      height: 100%;
                      background: url("https://gw.alipayobjects.com/zos/rmsportal/RVRUAYdCGeYNBWoKiIwB.svg");
                      background-size: cover;
                    }
                  }
                  .mg {
                    margin: 0 94px 0 140px;
                  }
                }
              }
            `}
          </style>
        </div>
      </div>
    );
  }
}
