import xhr from './index.js'

/**
 * 渠道管理
 */
//渠道列表
function channelList(param) {
  return xhr({
    url: 'admin/v1/channels',
    body:param
  })
}
//渠道申请列表
function applyList(param) {
  return xhr({
    url: 'admin/v1/channel/apply-list',
    body:param
  })
}
//渠道详情
function detail(params) {
  return xhr({
    url: 'admin/v1/channels/'+params.id,
    body:params
  })
}

//渠道新增
function add(param) {
  return xhr({
    url: 'admin/v1/channels',
    body:param,
    method: "post"
  })
}
//渠道编辑
function edit(params) {
  return xhr({
    url: '/admin/v1/channels/'+params.id,
    body:params,
    method: "PUT"
  })
}
//审核操作
function operate(params) {
  return xhr({
    url: "admin/v1/channel/apply-operate",
    body:params,
    method: "POST"
  })
}
//审核操作
function look(params) {
  return xhr({
    url: "admin/v1/channel/apply",
    body:params,
    method: "POST"
  })
}
//校园列表
function schoolList(param) {
  return xhr({
    url: 'admin/v1/campuses',
    body:param
  })
}
//校园详情
function schoolDetail(params) {
  return xhr({
    url: 'admin/v1/campuses/'+params.id,
    body:params
  })
}

//校园新增
function schoolAdd(param) {
  return xhr({
    url: 'admin/v1/campuses',
    body:param,
    method: "post"
  })
}
//校园编辑
function  schoolEdit(params) {
  return xhr({
    url: '/admin/v1/campuses/'+params.id,
    body:params,
    method: "PUT"
  })
}
export {
  channelList,add,detail,edit,applyList,operate,look,schoolList,schoolDetail
,schoolAdd,schoolEdit}