import _get from 'lodash/get';
import _set from 'lodash/set'
import Model from "../Model";
import * as clueService from "services/clueService";
import * as gboxService from "services/gboxService";
import * as commonService from "services/commonService";
import { message } from 'antd';
import moment from "moment";

export default Model.getInstance(
  class extends Model {
    namespace = "gboxModel";
    state = {
      commonData: {},
      clueList: [],
      clueLoading: false,
      condition: {},
      clueRecordVisible: false, // 线索跟进记录显示
      currentRecord: {}, // 线索跟进记录
      clueSelectKeys: [], // 分配key
      clueSelectRows: {}, // 分配row
      assignModalVisible: false, // 线索分配显示
      clueRecords: {
        list: [],
        _meta: {}
      }, // 线索跟进记录列表
      gboxUser: {},//gbox乙方用户信息
      gboxUserFirst: {},//gbox甲方用户信息
      loadingUserStatus: false,//加载状态
      selectedGboxIds: [],//选中的gbox id
      gboxBusiness: {
        gboxBusinessDrawer: false,//用户下编辑商机
        gboxBusinessDrawerId: 0,//用户下编辑商机的id
      },
      shareList: [],
      shareTotalInfo: [], // 商机获取统计数据
      shareDetail: {}, // 共享商机详情
      shareLoading: false,
      businessData: {},
      businessDetailLoading: false,
      // openDetailId: undefined, //打开详情的id
      openDetailId: "", //打开详情的id
      openDetailIdFirst: "", //打开详情的id
      openCompanyId: '',
      gboxUserInfo:{},//乙方用户详情
      gboxUserInfoFirst:{},//甲方用户详情
      cooperationData: [], //乙方合作列表
      cooperationLoading: false, //方合作列表表格loading
      cooperationDetailsStatus: false, //合作详情开关状态
      cooperationDetail: {}, //乙方合作详情
      conversation: {}, //获取会话列表接口数据
      cooperationId: "", //合作id
    };

    actions = {
      async getCommonData(dataName) {
        let commonData = await commonService.commonData(dataName);
        this.dispatch({
          type: "gboxModel/updateCommon",
          payload: commonData
        });
        return commonData;
      },
      async getShareList(param = {}) {
        param.expand =
          "complaint_info,company_industry,create_time_str,real_status,online_time_str,create_by_name,company,feedback_num,city_name,status_name,service_type_name,share_num,origin_name,publisher_role_name,password,renew_time_str,mark_name,create_by";
        this.dispatch({
          type: "gboxModel/updateLoading",
          payload: true
        });
        let result = await gboxService.shareBusinessList(param);
        if (result.code == 0) {
          if (param.total_statistics) {
            this.dispatch({
              type: "gboxModel/updateTotalInfo",
              payload: result.data
            });
          } else {
            this.dispatch({
              type: "gboxModel/updateList",
              payload: result.data
            });
          }
        }
        this.dispatch({
          type: "gboxModel/updateLoading",
          payload: false
        });
      },
      async addShare(param) {
        let result = await gboxService.insertShareBusiness(param);
        if (result.code == 0) {
          message.info("创建成功！")
        }
        return result;
      },
      async modShare(param) {
        let result = await gboxService.modifyShareBusiness(param);
        if (result.code == 0) {
          message.info("修改成功！");
          this.dispatch({
            type: "gboxModel/updateShareDetail",
            payload: {}
          })
        }
        return result;
      },
      async reviewBusiness(param) {
        let result = await gboxService.reviewBusiness(param);
        if (result.code == 0) {
          message.info("修改成功！");
          // this.dispatch({
          //   type: "gboxModel/updateShareDetail",
          //   payload: {}
          // })
        }
        return result;
      },
      async getShareDetail(id) {
        let param = { id };
        param.expand = "create_by_from,publisher_complaint_num,complaint_confirm,real_status,create_by_name,origin_name,city_info,online_time_str,publisher_role_name,unpass_time_str,offline_reason_str,origin_create_time,password,activity_begin_str,activity_end_str,service_type_p,publisher_business";
        this.dispatch({
          type: "gboxModel/updateDetailLoading",
          payload: true
        });
        let result = await gboxService.shareBusinessDetail(param);
        if (result.code == 0) {
          let record = result.data;
          let online_time = record.online_time ? moment.unix(record.online_time) : undefined;
          this.dispatch({
            type: "gboxModel/updateShareDetail",
            payload: {
              ...record,
              company_name: record.company,
              company_city: record.city_info,

              contactor: record.contactor,

              phone: record.phone,

              post_name: record.post_name,
              assure_period: record.assure_period,
              service_budget: record.service_budget,
              service_type: record.service_type ? record.service_type : undefined,
              customer_auth: record.customer_auth,

              require_num: record.require_num === "若干" ? undefined : parseInt(record.require_num),
              some: record.require_num === "若干" ? 1 : 0,

              jd_desc: record.jd_desc,
              requirements: record.requirements,
              status: record.real_status,

              business_desc: record.business_desc,
              label: record.label,
              date: online_time,
              online_time:online_time,
              time: online_time ? online_time.format("YYYY-MM-DD HH:mm").split(" ")[1] : "",
              max_get: record.max_get,
              has_get: record.has_get,
              origin: record.origin,
              puber: record.create_by_name ? record.create_by_name : record.phone,
              need_auth: record.need_auth,
              details: record.details,

            }
          })
          this.dispatch({
            type: "gboxModel/updateDetailLoading",
            payload: false
          });
        }
        return result;
      },
      async getBusinessData(param = {}) {
        param.expand =
          "create_time_str,business,title,detail,feedback_name";
          param["per-page"] = 5;
        this.dispatch({
          type: "gboxModel/updateBusinessLoading",
          payload: true
        });
        let result = await gboxService.getBusinessData(param);
        if (result.code == 0) {
          this.dispatch({
            type: "gboxModel/updateBusinessData",
            payload: result.data
          });
        }
        this.dispatch({
          type: "gboxModel/updateBusinessLoading",
          payload: false
        });
      },
      //获取b-box用户列表
      async getList(param = {}) {
        const expand = "cooperation_status,user_info,check_status_name,main_business_name,industry_name,function_name,area_name,business_log_count,company_status,invite_count,invited_time_str,invite_origin,from_channel_name";
        this.dispatch({
          type: "gboxModel/setUpdateState",
          payload: { filed: "loadingUserStatus", value: true }
        });
        let result = await gboxService.gboxList({ ...param, expand });
        if (result.code === 0) {
          this.dispatch({
            type: "gboxModel/setUpdateState",
            payload: {
              filed: "gboxUser",
              value: { ...result.data }
            }
          });
        }
        this.dispatch({
          type: "gboxModel/setUpdateState",
          payload: { filed: "loadingUserStatus", value: false }
        });
      },
      //获取b-box甲方用户列表
      async getListFirst(param = {}) {
        const expand = "cooperation_status,user_info,check_status_name,main_business_name,industry_name,function_name,area_name,business_log_count,company_status,invite_count,invited_time_str,invite_origin,business_count,company_count";
        this.dispatch({
          type: "gboxModel/setUpdateState",
          payload: { filed: "loadingUserStatus", value: true }
        });
        let result = await gboxService.gboxList({ ...param, expand });
        if (result.code === 0) {
          this.dispatch({
            type: "gboxModel/setUpdateState",
            payload: {
              filed: "gboxUserFirst",
              value: { ...result.data }
            }
          });
        }
        this.dispatch({
          type: "gboxModel/setUpdateState",
          payload: { filed: "loadingUserStatus", value: false }
        });
      },
      //获取乙方b-box详情
      async getGboxDetail(param = {}) {
        const expand = "user_info,company_info,cooperation_info,check_status_name,last_company_apply_info,cooperation_info,position_from_str,position_to_str,city_name,service_area_name,service_range_name,refund_type_name,cost_type_name,cost_type_price_name,vip_expiry_str,recharge_point,adept_industry_name,adept_post_name,exist_same_user";
        let result = await gboxService.gboxDetail({ ...param, expand });
        if (result.code === 0) {
          this.dispatch({
            type: "gboxModel/setUpdateState",
            payload: {
              filed: "gboxUserInfo",
              value: { ...result.data }
            }
          });
        }
      },
      //获取甲方b-box详情
      async getGboxDetailFirst(param = {}) {
        const expand = "user_info,company_info,cooperation_info,check_status_name,last_company_apply_info,cooperation_info";
        let result = await gboxService.gboxDetail({ ...param, expand });
        if (result.code === 0) {
          this.dispatch({
            type: "gboxModel/setUpdateState",
            payload: {
              filed: "gboxUserInfoFirst",
              value: { ...result.data }
            }
          });
        }
      },
      //获取b-box详情
      async gboxNotSuit(param = {}) {
        let result = await gboxService.gboxNotSuit(param);
        if (result.code === 0) {
        }
      },
      //获取乙方合作列表
      async getCooperationList(param = {}) {
        this.dispatch({
          type: "gboxModel/setUpdateState",
          payload: { filed: "cooperationLoading", value: true }
        });
        let result = await gboxService.getCooperationList(param);
        if (result.code === 0) {
          this.dispatch({
            type: "gboxModel/setUpdateState",
            payload: {
              filed: "cooperationData",
              value: result.data
            }
          });
        }
        this.dispatch({
          type: "gboxModel/setUpdateState",
          payload: { filed: "cooperationLoading", value: false }
        });
      },

      //获取乙方合作详情
      async getCooperationdetail(param = {}) {
        let result = await gboxService.getCooperationdetail(param);
        if (result.code === 0) {
          this.dispatch({
            type: "gboxModel/setUpdateState",
            payload: {
              filed: "cooperationDetail",
              value: result.data
            }
          });
        } else {
          this.dispatch({
            type: "gboxModel/setUpdateState",
            payload: { filed: "cooperationId", value: '' }
          });
        }
      },

      //获取在线沟通会话列表
      async getConversation(param = {}) {
        let result = await gboxService.getConversation(param);
        if (result.code === 0) {
          this.dispatch({
            type: "gboxModel/setUpdateState",
            payload: {
              filed: "conversation",
              value: result.data
            }
          });
        }
      },

      //更改用户权限
      async changeUserAuth(param = {}) {
        let result = await gboxService.changeUserAuth(param);
        if (result.code === 0) {
          message.info("修改成功！");
        }
      },

      //合作下线
      async cooperationOffline(param = {}) {
        let result = await gboxService.cooperationOffline(param);
        if (result.code === 0) {
          message.info("修改成功！");
        }
      }
    };

    reducers = {
      updateCommon(state, { payload: data }) {
        return { ...state, commonData: {...state.commonData, ...data} };
      },
      updateList(state, { payload: data }) {
        return { ...state, shareList: data };
      },
      updateTotalInfo(state, { payload: data }) {
        return { ...state, shareTotalInfo: data };
      },
      updateShareDetail(state, { payload: data }) {
        return { ...state, shareDetail: data };
      },
      updateLoading(state, { payload: data }) {
        return { ...state, shareLoading: data };
      },
      updateBusinessData(state, { payload: data }) {
        return { ...state, businessData: data };
      },
      updateBusinessLoading(state, { payload: data }) {
        return { ...state, businessDataLoading: data };
      },
      updateDetailLoading(state, { payload: data }) {
        return { ...state, businessDetailLoading: data };
      },
      setUpdateState(state, { payload }) {
        const { filed, value } = payload;
        state[filed] = value;
        return { ...state };
      },
      setUpdate(state, { payload }) {
        return { ...state, ...payload };
      },
    };
  }
);
