import React, { PureComponent } from "react";
import { connect } from "react-redux";
import {
  Tabs,
  Select,
  Input,
  Row,
  Col,
  Button,
  Form,
  Icon,
  Collapse,
  Modal,
  Radio,
  Table,
  Popconfirm,
} from "antd";
import RadioBtn from "components/radioBtn/radioBtn";
import CustomIcon from "components/CustomIcon";
import Empty from "components/empty";
import PositionVacantModal from "store/reducers/positionVacantM";
import * as positionS from "services/positionS";

const Option = Select.Option;
const FormItem = Form.Item;
const TextArea = Input.TextArea;
const Panel = Collapse.Panel;

/**
 * 优职下tab
 */
@connect(
  ({ PositionVacantModal }) => {
    return { ...PositionVacantModal };
  },
  {
    ...PositionVacantModal.actions
  }
)
class KhPosition extends PureComponent {
  state = {
    statusTitle: {
      0: "职位尚未发布",
      1: "正在发布，等待审核",
      2: "已停止发布到可汗招聘",
      3: "已成功发布到可汗招聘"
    },
    statusIcon: {
      0: "icon-frown-copy",
      1: "icon-hourglass-copy",
      2: "icon-close-circle-copy",
      3: "icon-check-circle-copy"
    },
    emailForm: []
  };
  editHandle = () => {
    const { setUpdateState, khInfo } = this.props;
    setUpdateState({
      filed: "khInfo",
      value: { ...khInfo, isOpen: !khInfo.isOpen }
    });
  };
  confirm = publish_status => {
    const { positionDetail = {}, updateStateKHPublish } = this.props;
    let dataParam = {};
    if (publish_status === 2) {
      dataParam = { jd_id: positionDetail.id };
    } else {
      dataParam.jd_id = positionDetail.id;
    }
    let fetchName = publish_status === 2 ? `khAdd` : `khStopPublish`;
    positionS[fetchName](dataParam).then(res => {
      if (res.code === 0) {
        
        //下面为了不调用接口直接改redux状态
        if (publish_status === 2) {
          publish_status = 3;
        } else if (publish_status === 3) {
          publish_status = 2;
        }
        updateStateKHPublish({
          filed: "positionDetail",
          value: { publish_status }
        });
      }
    });
  };
  headShow = publish_status => (
    <span className="head clear">
      <span className="left">
        <CustomIcon
          type={`${this.state.statusIcon[publish_status || 0]}`}
          style={{ fontSize: 20 }}
        />
        {this.state.statusTitle[publish_status || 0]}
      </span>
      {(publish_status === 2 || publish_status === 3) && (
        <span className="right">
          <Popconfirm
            title={`你确认${publish_status === 2 ? "重新" : "停止"}发布`}
            onConfirm={e => {
              e.stopPropagation();
              this.confirm(publish_status);
            }}
            onCancel={this.cancel}
            okText="确定"
            cancelText="取消"
          >
            <Button
              type="primary"
              onClick={e => {
                e.stopPropagation();
              }}
            >
              <CustomIcon
                type={
                  publish_status === 2 ? "icon-sync-copy" : "icon-stop-copy"
                }
                style={{ fontSize: 20 }}
              />
              {publish_status === 2 ? "重新" : "停止"}发布
            </Button>
          </Popconfirm>
        </span>
      )}
    </span>
  );
  render() {
    const { positionDetail = {}, khInfo = {}, history } = this.props;
    const { ch_publish_info = {} } = positionDetail;
    const { base_info = {}, profiles } = ch_publish_info;

    return (
      <React.Fragment>
        <div className="optimal-position-show-box collapse-handle-box">
          {/* 简历信息 */}
          <Collapse defaultActiveKey={["1"]}>
            <Panel header={this.headShow(base_info.publish_status)} key="1">
              {!base_info.publish_status && (
                <div className="without">
                  <Empty description="职位尚未发布到可汗招聘" />
                  <Button type="primary" onClick={this.editHandle}>
                    立即发布
                  </Button>
                </div>
              )}
              {(base_info.publish_status === 1 ||
                base_info.publish_status === 3) && (
                <div className="position-info">
                  {/* <p>发布时长：{base_info.publish_end_option_lable}</p> */}
                  <p>
                    公司展示：
                    {base_info.is_open === 4
                      ? base_info.secret_name
                      : base_info.is_open_lable}
                  </p>
                  <p>职位性质：{base_info.type_lable}</p>
                  {/* <p>职位亮点：{base_info.advantage}</p> */}
                  <p>简历接收：{base_info.profile_receive_setting_label}</p>
                  {base_info.secret_desc && (
                    <p style={{ lineHeight: "18px" }}>
                      公司描述：{base_info.secret_desc}
                    </p>
                  )}
                  <div className="edit-btn" onClick={this.editHandle}>
                    <CustomIcon type="icon-edit" style={{ fontSize: 20 }} />
                    编辑
                  </div>
                </div>
              )}
              {base_info.publish_status === 2 && (
                <div className="without">
                  <Empty
                    image={require("assets/imgs/publish-fail.png")}
                    description={
                      base_info.unpass_reason && (
                        <span>未通过原因：{base_info.unpass_reason}</span>
                      )
                    }
                  />
                  <Button type="primary" onClick={this.editHandle}>
                    立即发布
                  </Button>
                </div>
              )}
            </Panel>
          </Collapse>
          {/* 简历处理 */}
          <div className="resume-box">
            {positionDetail.id && (
              <KhListC
                jd_id={positionDetail.id}
                profiles={profiles}
                history={this.props.history}
              />
            )}
          </div>
        </div>
        {history && history.location.pathname.indexOf("position/vacant") === -1 &&  <KhDialog />}
        <style jsx global>
          {`
            .optimal-position-show-box {
              padding: 0 15px;
              .head {
                font-size: 16px;
                font-weight: 600;
                svg {
                  margin-right: 5px;
                  vertical-align: inherit;
                }
                .right {
                  position: relative;
                  button {
                    position: absolute;
                    top: -5px;
                    right: -10px;
                    background: #1890ff;
                    border: 1px;
                    span {
                      margin-left: 0;
                    }
                  }
                }
              }
              .without {
                .gee-empty-status {
                  min-height: 300px;
                  img {
                    margin-top: 58px;
                  }
                }
                position: relative;
                button {
                  position: absolute;
                  top: 218px;
                  left: 317px;
                  background: #1890ff;
                }
              }
              .position-info {
                background-color: #fafafa;
                border-radius: 4px;
                padding: 20px 10px;
                position: relative;
                p {
                  font-size: 14px;
                  color: #666666;
                  margin-bottom: 15px;
                  &:last-of-type {
                    margin-bottom: 0;
                  }
                }
                .edit-btn {
                  position: absolute;
                  top: 73px;
                  right: 60px;
                  cursor: pointer;
                  svg {
                    margin-right: 5px;
                    vertical-align: inherit;
                  }
                }
              }
              .resume-box {
                margin-top: 20px;
              }
            }
          `}
        </style>
      </React.Fragment>
    );
  }
}
/**
 * 完善职位发布信息
 */

const KhDialog = Form.create()(
  @connect(
    ({ PositionVacantModal }) => {
      return { ...PositionVacantModal };
    },
    {
      ...PositionVacantModal.actions
    }
  )
  class OptimalDialogForm extends React.PureComponent {
    state = {
      size: "large",
      emailForm: [""],
      companyStatus: undefined,
      setType: undefined,
      subStatus: false
    };
    componentDidMount() {
      //设置公司子自定义状态
      const { base_info = {} } =
        this.props.positionDetail.ch_publish_info ||
        this.props.listDetail.ch_publish_info ||
        {};

      this.setState({
        companyStatus: base_info.is_open,
        setType: base_info.profile_receive_setting || 2,
        emailForm:
          (base_info.notify_emails || []).length > 0
            ? base_info.notify_emails
            : [""]
      });
    }

    handleSubmit = e => {
      const { validateFieldsAndScroll } = this.props.form;
      const {
        positionDetail = {},
        getDetail,
        listDetail = {},
        getPositionList,
        statusParam
      } = this.props;
      const { ch_publish_info = {} } = positionDetail;
      const { base_info = {} } = ch_publish_info;
      e.preventDefault();
      validateFieldsAndScroll((err, values) => {
        if (!err) {
          this.setState({ subStatus: !this.state.subStatus }); //防止确定按钮重复提交
          const dataParam = {
            ...values,
            jd_id: positionDetail.id || listDetail.jd_id
          };
          let fetchName =
            (JSON.stringify(listDetail) !== "{}" &&
              JSON.stringify(listDetail.ch_publish_info) !== "{}") ||
            (positionDetail.id && base_info.publish_status)
              ? `khEdit`
              : `khAdd`;
          positionS[fetchName](dataParam)
            .then(res => {
              if (res.code === 0) {
                if (listDetail.jd_id) {
                  getPositionList(statusParam);
                } else {
                  getDetail(this.props.positionDetail.id);
                }
                this.handleCancel();
              }
            })
            .finally(() => this.setState({ subStatus: !this.state.subStatus }));
        }
      });
    };
    /**
     * @description 关闭或打开弹窗
     */
    handleCancel = e => {
      const { setUpdateState, khInfo } = this.props;
      setUpdateState({
        filed: "khInfo",
        value: { ...khInfo, isOpen: !khInfo.isOpen }
      });
    };
    /**
     * @description 更新state
     * @param {string} name  改变的名字
     * @param {number} val   改变的值
     */
    updataData = (name, val) => {
      this.setState({ [name]: val });
    };
    /**
     * @description 限制输入个数
     * @param {string} value   当前输入框返回数据
     */
    limitSize = (rule, value, callback) => {
      if (value && value.length > 20) {
        callback("最大输入不要超过20个字!");
      }
      callback();
    };
    //添加邮箱
    addEmailClick = () => {
      let { emailForm } = this.state;
      emailForm.push("");
      this.setState({
        emailForm: [].concat(emailForm)
      });
    };
    //删除邮箱
    delEmailClick = index => {
      const { getFieldValue, resetFields } = this.props.form;
      const notify_emails = getFieldValue("notify_emails");
      notify_emails.splice(index, 1);
      resetFields(["notify_emails"]);
      this.setState({
        emailForm: [].concat(notify_emails)
      });
    };
    handleType = info => {
      debugger;
    };
    render() {
      const { emailForm = [], companyStatus } = this.state;
      const { getFieldDecorator } = this.props.form;
      const {
        commonData = {},
        khInfo = {},
        positionDetail = {},
        listDetail = {}
      } = this.props;
      console.log(listDetail, positionDetail, "detail")
      const { ch_publish_info = {}, hh_email, work_type } =
        JSON.stringify(listDetail) !== "{}" ? listDetail : positionDetail;
      const { base_info = {} } = ch_publish_info;
      const { size } = this.state;
      const formItemLayout = {
        labelCol: {
          span: 6
        },
        wrapperCol: {
          span: 14
        }
      };
      const formEmailLayout = {
        labelCol: {
          span: 6
        },
        wrapperCol: {
          span: 18
        }
      };
      console.log("完善可汗职位发布信息", khInfo)
      return (
        <Modal
          width={520}
          title="完善可汗职位发布信息"
          visible={khInfo.isOpen}
          onCancel={this.handleCancel}
          footer={[
            <Button onClick={this.handleCancel}>取消</Button>,
            <Button
              type="primary"
              disabled={this.state.subStatus}
              onClick={this.handleSubmit}
            >
              确定
            </Button>
          ]}
        >
          <div className="optimal-dialog">
            <Form onSubmit={this.handleSubmit}>
              {/* <FormItem {...formItemLayout} label="发布时长">
                {getFieldDecorator("publish_end_option", {
                  initialValue: base_info.publish_end_option
                    ? base_info.publish_end_option
                    : undefined,
                  rules: [{ required: true, message: "请选择" }]
                })(
                  <Select size={size} placeholder="请输选择">
                    {commonData.publish_end_option &&
                      commonData.publish_end_option.map((item, index) => {
                        return (
                          <Option key={index} value={item.value}>
                            {item.label}
                          </Option>
                        );
                      })}
                  </Select>
                )}
              </FormItem> */}
              <FormItem {...formItemLayout} label="简历接收设置">
                {getFieldDecorator("profile_receive_setting", {
                  initialValue: base_info.profile_receive_setting
                    ? base_info.profile_receive_setting
                    : 2,
                  rules: [{ required: true, message: "请选择" }]
                })(
                  <Select
                    size={size}
                    placeholder="请输选择"
                    onChange={this.updataData.bind(this, "setType")}
                  >
                    {commonData.publish_profile_receive_setting &&
                      commonData.publish_profile_receive_setting.map(
                        (item, index) => {
                          return (
                            <Option key={index} value={item.value}>
                              {item.label}
                            </Option>
                          );
                        }
                      )}
                  </Select>
                )}
              </FormItem>
              {this.state.setType === 2 && (
                <div>
                  {emailForm &&
                    emailForm.map((item, index) => {
                      return (
                        <Row key={index}>
                          <Col span={20}>
                            <FormItem
                              style={{
                                paddingLeft: 116
                              }}
                              {...formEmailLayout}
                            >
                              {getFieldDecorator(`notify_emails[${index}]`, {
                                initialValue: !item && !index ? hh_email : item,
                                rules: [
                                  {
                                    pattern: new RegExp(
                                      /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/g
                                    ),
                                    message: "请输入正确的邮箱格式"
                                  }
                                ]
                              })(
                                <Input
                                  size={size}
                                  style={{ width: "275px" }}
                                  placeholder="请输入邮箱"
                                />
                              )}
                            </FormItem>
                          </Col>
                          <Col span={1} className="delete-col">
                            {emailForm.length > 1 && index > 0 && (
                              <span>
                                <Icon
                                  type="minus-circle-o"
                                  onClick={this.delEmailClick.bind(this, index)}
                                />
                              </span>
                            )}
                          </Col>
                        </Row>
                      );
                    })}
                  {emailForm.length < 5 && (
                    <Row className="add">
                      <Col span={10} offset={6}>
                        <Button
                          style={{ width: "275px" }}
                          onClick={this.addEmailClick.bind(this)}
                        >
                          <Icon type="plus" />
                          新增邮箱
                        </Button>
                      </Col>
                    </Row>
                  )}
                </div>
              )}
              {commonData.publish_jd_type && (
                <FormItem {...formItemLayout} label="职位性质">
                  {getFieldDecorator("type", {
                    initialValue: base_info.type ? base_info.type : (work_type ? work_type : undefined),
                    rules: [{ required: true, message: "请选择职位性质" }]
                  })(
                    <RadioBtn
                      className="radio-btn"
                      option={commonData.work_type}
                    />
                  )}
                </FormItem>
              )}
              {/* <FormItem {...formItemLayout} label="职位亮点">
                {getFieldDecorator("advantage", {
                  initialValue: base_info.advantage
                    ? base_info.advantage
                    : undefined,
                  rules: [
                    { required: true, message: "请填写" },
                    {
                      validator: this.limitSize
                    }
                  ]
                })(
                  <Input size={size} placeholder="请输入职位亮点，20个字以内" />
                )}
              </FormItem> */}
              <FormItem {...formItemLayout} label="公司展示">
                {getFieldDecorator("is_open", {
                  initialValue: base_info.is_open
                    ? base_info.is_open
                    : undefined,
                  rules: [{ required: true, message: "请选择" }]
                })(
                  <Select
                    onChange={this.updataData.bind(this, "companyStatus")}
                    size={size}
                    placeholder="请输选择"
                  >
                    {commonData.publish_is_open &&
                      commonData.publish_is_open.map((item, index) => {
                        return (
                          <Option key={index} value={item.value}>
                            {item.label}
                          </Option>
                        );
                      })}
                  </Select>
                )}
              </FormItem>
              {companyStatus === 4 && (
                <div className="no">
                  <FormItem {...formItemLayout} label=" " className="noR">
                    {getFieldDecorator("secret_name", {
                      initialValue: base_info.secret_name
                        ? base_info.secret_name
                        : undefined,
                      rules: [{ required: true, message: "请填写" }]
                    })(
                      <TextArea
                        // style={{ width: "275px" }}
                        placeholder="公司性质+行业（如国内A股上市人力资源公司）"
                        rows={3}
                      />
                    )}
                  </FormItem>
                  {/* <FormItem label="公司描述" {...formItemLayout}>
                    {getFieldDecorator("secret_desc", {
                      initialValue: base_info.secret_desc
                        ? base_info.secret_desc
                        : undefined,
                      rules: [{ required: true, message: "请输入公司描述" }]
                    })(
                      <TextArea
                        rows={5}
                        placeholder="该公司若不能对外公开，公司描述需重新编辑"
                      />
                    )}
                  </FormItem> */}
                </div>
              )}
            </Form>
          </div>
          <style jsx global>
            {`
              .optimal-dialog {
                padding: 16px 0;
                .radio-btn {
                  margin-right: 13px;
                  padding: 0 12px;
                  &:last-child {
                    margin-right: 0;
                  }
                }
                .add {
                  margin-bottom: 20px;
                  button {
                    height: 40px;
                  }
                }
                .delete-col {
                  margin: 13px 10px 10px 10px;
                }
                .no {
                  .noR {
                    .ant-form-item-required {
                      &:before {
                        opacity: 0;
                      }
                    }
                  }
                }
              }
            `}
          </style>
        </Modal>
      );
    }
  }
);

/**
 * 可汗人选列表
 */

@connect(
  ({ PositionVacantModal }) => {
    return { ...PositionVacantModal };
  },
  {
    ...PositionVacantModal.actions
  }
)
class KhListC extends PureComponent {
  state = {
    statusParam: { "per-page": 5 }
  };
  componentDidMount() {
    this.searchList();
  }

  /**
   * @description 列表选项
   */
  columns = [
    {
      title: "简历ID",
      dataIndex: "profile_id",
      key: "profile_id",
      render: (text, record) => {
        return (
          <div className="ellipsis" style={{ width: "75px" }} title={text}>
            {!record.is_read && <span className="dot" />} {record.profile_id}
          </div>
        );
      }
    },
    {
      title: "姓名",
      key: "name",
      dataIndex: "name",
      render: text => {
        return (
          <div className="ellipsis" style={{ width: "75px" }} title={text}>
            {text}
          </div>
        );
      }
    },
    {
      title: "联系方式",
      key: "phone",
      dataIndex: "phone",
      width: 100
    },
    {
      title: "所在城市",
      key: "city_name",
      dataIndex: "city_name"
    },
    {
      title: "工作经验",
      key: "work_time",
      dataIndex: "work_time",
      render: (text, record, index) => text + "年"
    },
    {
      title: "最近公司",
      key: "last_company",
      dataIndex: "last_company",
      render: text => {
        return (
          <div className="ellipsis" style={{ width: "88px" }} title={text}>
            {text}
          </div>
        );
      }
    },
    {
      title: "投递时间",
      key: "update_time",
      dataIndex: "update_time",
      width: 100
    }
  ];
  /**
   * @description 搜索列表
   * @param {number} page  当前页
   * @param {number} pageSize  展示条数多少
   */
  searchList = (page = 1, pageSize = 5) => {
    this.props.getKhList({
      jd_id: this.props.jd_id,
      page,
      "per-page": pageSize
    });
  };

  /**
   * @description 点击行展开抽屉，查询详情
   * @param {object} record   当前行信息
   */
  onRow = record => {
    const { history } = this.props;
    return {
      onClick: e => {
        history.push(`/position/vacant/optimaldetail/${record.jd_profile_id}/${
          this.props.positionDetail.id
        }/${record.profile_id}/-1`);
      }
    };
  };

  render() {
    const { khList = {} } = this.props.khInfo;
    const { list = [], _meta = {} } = khList;
    const paginationProps = {
      size: "small",
      showSizeChanger: true,
      showQuickJumper: true,
      total: _meta.totalCount,
      pageSize: _meta.perPage || 5,
      onShowSizeChange: this.searchList.bind(this),
      onChange: this.searchList.bind(this),
      showTotal: total => `总计${total}个`,
      pageSizeOptions: ["5", "10", "20", "30", "40"]
    };
    return (
      <div className="optimal-list">
        {list.length > 0 && (
          <Collapse defaultActiveKey={["1"]}>
            <Panel
              header={
                <span className="head">{`简历处理(${(khList._meta || {})
                  .handleCount || 0}/${(khList._meta || {}).totalCount ||
                  0})`}</span>
              }
              key="1"
            >
              <Table
                // loading={dataList.loadingStatus}
                rowKey={record => record.id}
                columns={this.columns}
                dataSource={list}
                pagination={paginationProps}
                onRow={this.onRow}
              />
            </Panel>
          </Collapse>
        )}

        <style jsx global>
          {`
            .optimal-list {
              .dot {
                background: #ff4b54;
                width: 5px;
                height: 5px;
                border-radius: 50%;
                display: inline-block;
                vertical-align: middle;
              }
            }
          `}
        </style>
      </div>
    );
  }
}

export { KhPosition, KhDialog };
