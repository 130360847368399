import css from 'styled-jsx/css';

export default css.global`/* @styled-jsx=global */
.profile-detail-layout {
  position: fixed;
  right: 0;
  top: 0;
  overflow-y: auto;
  // top: -310px;
  z-index: 1001;
  // left: -140px;
  height: 100%;
  background: #fff;
  margin: 0 auto;
  min-width: 900px;
  width: 900px;
  .profile-detail {
    position: absolute;
    min-width: 1180px;
    width: 1180px;
    transform: scale(0.7627);
    height: 100%;
    top: -12%;
    left: -140px;
    border-radius: 4px;
    .detail-header {
      padding: 0 30px;
      height: 65px;
      line-height: 65px;
      font-size: 21px;
      background-color: #eaeaea;
      border-bottom: 1px solid #e5e5e5;

      .function {
        margin-right: 40px;
        color: #333333;
        font-weight: bold;
      }

      .anticon-close {
        margin-top: 23px;
        font-size: 21px;
        color: #999999;
        cursor: pointer;
      }
    }
    .detail-content {
      padding: 30px 40px 94px 30px;
      background-color: #fff;
      .base-info {
        margin-bottom: 40px;
        height: 120px;
        img {
          width: 100px;
          height: 120px;
          margin-right: 20px;
        }
        .profile {
          display: inline-block;
          vertical-align: top;
          .profile-name {
            // margin-top: 10px;
            height: 18px;
            .name {
              font-size: 18px;
              font-weight: bold;
            }
            .no {
              color: #666666;
            }
          }
          .profile-base {
            margin-top: 29px;
            color: #666666;
          }
          .profile-other {
             // margin-top: 20px;
             line-height: 20px;
            color: #666666;
          }
        }
        .operate {
          vertical-align: top;
          text-align: center;
          margin-right: 34px;
          .operate-num {
            margin-top: 42px;
            color: #333333;
          }
          .operate-reserve {
            margin-top: 10px;
            .reserve-btn {
              width: 150px;
              height: 36px;
              line-height: 34px;
            }
          }
          .operate-nouse {
            margin-top: 10px;
            width: 150px;
            height: 36px;
            line-height: 34px;
            background-color: #f5f5f5;
            border-radius: 4px;
            border: solid 1px #d9d9d9;
            color: #666666;
            font-size: 16px;
          }
        }
      }
      .profile-section {
        .section-title {
          height: 28px;
          span {
            display: inline-block;
            vertical-align: middle;
          }
          .icon {
            margin-right: 13px;
            width: 28px;
            height: 100%;
          }
          .icon-experience {
            background: url("~assets/imgs/profile/icon-experience.png")
              no-repeat;
          }
          .icon-project {
            background: url("~assets/imgs/profile/icon-project.png") no-repeat;
          }
          .icon-practice {
            background: url("~assets/imgs/profile/icon-practice.png") no-repeat;
          }
          .icon-edu {
            background: url("~assets/imgs/profile/icon-edu.png") no-repeat;
          }
          .icon-evaluate {
            background: url("~assets/imgs/profile/icon-evaluate.png") no-repeat;
          }
          .icon-intention {
            background: url("~assets/imgs/profile/icon-intention.png") no-repeat;
          }
          .icon-certify {
            background: url("~assets/imgs/profile/icon-certify.png") no-repeat;
          }
          .title {
            margin-right: 10px;
            font-size: 18px;
            font-weight: bold;
          }
          .line {
            width: 397px;
            height: 6px;
            background-color: #f5f5f5;
            &.short {
              width: 380px;
            }
          }
        }
        .work-timeline {
          margin-top: 12px;
          padding-left: 10px;
          .work-line {
            margin-bottom: 40px;
            .icon-company {
              margin-right: 10px;
              display: inline-block;
              width: 20px;
              height: 18px;
              vertical-align: top;
              background: url("~assets/imgs/profile/icon-company.png") no-repeat;
            }
            .icon-folder {
              margin-right: 10px;
              display: inline-block;
              width: 20px;
              height: 18px;
              vertical-align: top;
              background: url("~assets/imgs/profile/icon-folder.png") no-repeat;
            }
            .icon-campus {
              margin-right: 10px;
              display: inline-block;
              width: 20px;
              height: 20px;
              vertical-align: top;
              background: url("~assets/imgs/profile/icon-campus.png") no-repeat;
            }
            &.campus {
              &:first-child {
                margin-top: 30px;
              }
            }
            &.school-work {
              &:first-child {
                margin-top: 33px;
              }
            }
          }
          .work-company {
            font-size: 16px;
            font-weight: bold;
          }
          .work-time {
            margin-top: 13px;
            margin-bottom: 16px;
            line-height: 1;
            color: #999999;
          }
          .work-info {
            color: #666666;
            line-height: 1;
          }
          .work-title {
            margin-top: 10px;
            line-height: 30px;
            font-weight: bold;
          }
          .work-duty {
            color: #666666;
            line-height: 30px;
          }
        }
        .record-timeline {
          margin-top: 54px;
          .record {
            width: 200px;
            position: relative;
            background-color: #ffffff;
            border-radius: 2px 4px 2px 2px;
            .record-arrow {
              position: absolute;
              top: 23px;
              width: 0;
              height: 0;
            }
            .record-info {
              padding-top: 13px;
              padding-left: 14px;
              height: 60px;
              background-color: #fff3d2;
              text-align: left;
              .record-school {
                margin-top: 6px;
                font-size: 16px;
              }
            }
            .record-type {
              padding-top: 10px;
              padding-left: 14px;
              height: 45px;
              border-top: 0;
              text-align: left;
              color: #666666;
              .record-major {
                margin-top: 3px;
              }
            }
          }
          .ant-timeline-item-left {
            .record-arrow {
              left: -8px;
              border-top: 7px solid transparent;
              border-right: 8px solid #fff3d2;
              border-bottom: 7px solid transparent;
            }
            .ant-timeline-item-content {
              margin-left: 26px;
            }
          }
          .ant-timeline-item-right {
            .record-arrow {
              right: -8px;
              border-top: 7px solid transparent;
              border-left: 8px solid #fff3d2;
              border-bottom: 7px solid transparent;
            }
            .ant-timeline-item-content {
              margin-left: 36px;
              left: 0;
            }
          }
          .ant-timeline-item-content {
            top: -25px;
          }
        }
        .work-record-timeline {
          .record .record-info {
            background-color: #dbf4ff;
          }
          .ant-timeline-item-left {
            .record-arrow {
              border-right: 8px solid #dbf4ff;
            }
          }
          .ant-timeline-item-right {
            .record-arrow {
              border-left: 8px solid #dbf4ff;
            }
          }
        }
        .certi-block {
          padding-top: 25px;
          padding-left: 41px;
          padding-bottom: 46px;
          color: #666666;
          line-height: 24px;
          .right {
            color: #999999;
          }
        }
        &.help-section {
          .section-title {
            margin-bottom: 25px;
          }
        }
        .find-to {
          padding-top: 0;
          padding-left: 41px;
          color: #666666;
          line-height: 24px;

          &:last-child {
            padding-bottom: 46px;
          }
        }
        .help-ev {
          padding-top: 25px;
          padding-left: 41px;
          padding-bottom: 55px;
          .help-item {
            .helper {
              height: 50px;
              line-height: 50px;
              img {
                margin-right: 11px;
                width: 50px;
                height: 50px;
                border-radius: 50%;
              }
              span {
                font-size: 16px;
              }
              .right {
                font-size: 14px;
                color: #999999;
              }
            }
            .ev-word {
              position: relative;
              margin-top: 15px;
              padding: 20px 12px 20px 9px;
              width: 479px;
              background-color: #f5f5f5;
              border-radius: 4px;
              color: #666666;
              line-height: 24px;
              .ev-arrow {
                position: absolute;
                top: -6px;
                left: 19px;
                width: 0;
                height: 0;
                border-bottom: 6px solid #f5f5f5;
                border-right: 6.5px solid transparent;
                border-left: 6.5px solid transparent;
              }
            }
          }
        }
      }
    }
  }
}
`