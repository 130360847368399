import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { Select, Modal, message, Button, Input, Checkbox, Icon } from "antd";
import moment from "moment";
import Table from "components/GeeTable.js";
import recomCandToPosModel from "store/reducers/recomCandToPosModel";
import { history } from "root.js";
import underscore from "underscore";

const Option = Select.Option;
const Search = Input.Search;
const CheckboxGroup = Checkbox.Group;
/**
 * 推荐到职位
 */
@connect(
  ({ recomCandToPos, user }) => ({
    recommandPos: recomCandToPos.recommandPos,
    recommendSearchParam: recomCandToPos.recommendSearchParam,
    selectedCands: recomCandToPos.selectedCands,
    selectedInternCands: recomCandToPos.selectedInternCands,
    user_id: user.id 
  }),
  { ...recomCandToPosModel.actions }
)
export default class RecommandToPos extends PureComponent {
  expand =
    "contactor,status_name,city_name,region_name,source_name,position_name,company,hh_email,jd_address";

  state = {
    sendClick: false,
    selectedCands: [],
  }

  componentDidUpdate(prevProps) {
    const { recommandPos, selectedCands, selectedInternCands } = this.props;
    if (
      recommandPos.visible !== prevProps.recommandPos.visible &&
      recommandPos.visible === true
    ) {
      this.positionListSearch();
      if (recommandPos.isIntern) {
        this.setState({
          selectedCands: underscore.values(selectedInternCands)
        })
      } else {
        this.setState({
          selectedCands: underscore.values(selectedCands)
        })
      }
    }
  }
  componentWillUnmount() {
    this.props.cleanRecomStatus();
  }

  onOK = () => {
    // this.props.updateResumeEmail({ visible: true }); //打开发送简历邮箱Modal
    const { recommandPos, updateRecommandPos } = this.props;
    const { selectedCands } = this.state;
    const { recomPosIds } = recommandPos;
    updateRecommandPos({ visible: false, recomPosIds: [] }); //关闭推荐职位modal
    history.push("/position/vacant/recommend/" + recomPosIds[0] + "?to=talent&ids=" + selectedCands.map(item => item.key).join("-"));
  };

  onCancel = () => {
    // empty recommand status
    // this.props.cleanRecomStatus();
    this.props.updateRecommandPos({ visible: false });
    let recommendSearchParam={}
    this.props.updateParams({...recommendSearchParam})
  };

  onRecomToPos = () => {
 
    // this.setState({sendClick: true})
    // this.props.recommandToPos().then(res => {
    //   if (res.code === 0) {
    //     // empty recommand status
    //     this.props.cleanRecomStatus();
    //     message.success("人选推荐成功！");
    //   }
    //   this.setState({sendClick: false})
    // });
    const { recommandPos, updateRecommandPos } = this.props;
    const { selectedCands } = this.state;
    const { recomPosIds } = recommandPos;
    updateRecommandPos({ visible: false, recomPosIds: [] }); //关闭推荐职位modal
    history.push("/position/vacant/recommend/" + recomPosIds[0] + "/1?to=talent&ids=" + selectedCands.map(item => item.key).join("-"));
  };

  //人选列表搜索
  positionListSearch = (page, pageSize) => {
    const { getRecomPosList, recommendSearchParam } = this.props;
    getRecomPosList({ ...recommendSearchParam, page, "per-page": pageSize, expand: this.expand });
  };
  
  positionFilter = (name, val) => {
    const { getRecomPosList, updateParams, recommendSearchParam, recommandPos} = this.props;
    const { list} = recommandPos;
    const _meta = list._meta || {};
    if (name === "type") {
      recommendSearchParam[name] = val[0];
    } else {
      recommendSearchParam[name] = val;
    }
    console.log("param", { page: 1, "per-page": _meta.perPage || 5, expand: this.expand, ...recommendSearchParam })
    getRecomPosList({ page: 1, "per-page": _meta.perPage || 5, expand: this.expand, ...recommendSearchParam });
    updateParams({...recommendSearchParam})
  }

  columns = [
    {
      title: "职位名称",
      dataIndex: "name",
      key: "name",
      fixed: "left",
      width: 250,
      render: (value, row, index) => {
        const { position } = value;
        return (
          <div>
            <div
              style={{
                fontSize: 16,
                color: "#333",
                fontWeight: "bolder",
                width: 242
              }}
            >
              <span
                style={{
                  display: "inline-block",
                  maxWidth: 120,
                  verticalAlign: "bottom"
                }}
                title={position}
                className="ellipsis"
              >
                {position}
              </span>
              {position && (value.needNum || value.needNum === 0) ? "/" : ""}
              {value.needNum}
            </div>
            <div
              style={{ color: "#999", marginTop: 13, width: 242 }}
              className="ellipsis-style"
              title={value.company}
            >
              {value.company}
            </div>
          </div>
        );
      }
    },
    {
      title: "联系人",
      dataIndex: "contact",
      key: "contact",
      width: 100,
      needEllipsis: true
    },
    {
      title: "手机号码",
      dataIndex: "phone",
      key: "phone",
      width: 100
    },
    {
      title: "邮箱",
      dataIndex: "email",
      key: "email",
      needEllipsis: true,
      width: 150
    },
    {
      title: "职位状态",
      dataIndex: "status",
      key: "status",
      needEllipsis: true,
      width: 100
    },
    {
      title: "地区",
      dataIndex: "area",
      key: "area",
      needEllipsis: true,
      width: 120,
    },
    {
      title: "职位来源",
      dataIndex: "from",
      key: "from",
      needEllipsis: true,
      width: 100
    },
    {
      title: "创建时间",
      width: 150,
      dataIndex: "createTime",
      key: "createTime",
      fixed: "right"
    }
  ];

  render() {
    const { recommandPos, user_id } = this.props;
    const { selectedCands } = this.state;
    const { visible, list, recomPosIds, listLoading, rowSelectItem, isIntern } = recommandPos;
    const dataSource = convertList(list.list);
    const _meta = list._meta || {};
    const rowSelection = {
      selectedRowKeys: recomPosIds,
      onChange: (selectedRowKeys, selectedRows) => {
        let selectedRowsObjs = {};
        selectedRows.forEach(item => {
          selectedRowsObjs[item.key] = item;
        })
        let currentKeys = selectedRowKeys[selectedRowKeys.length-1];
        selectedRows = [selectedRowsObjs[currentKeys]];
        let emails = selectedRows
          .map(item => item.email)
          .filter(item => !!item);
        emails = emails.concat(
          selectedRows.map(item => item.hh_email).filter(item => !!item)
        );
        this.props.updateRecommandPos({
          rowSelectItem: selectedRows,
          recomPosIds: [currentKeys],
          emails
        });
      }
    };
    console.log(recomPosIds, rowSelectItem, isIntern )
    return (
      <Modal
        title="推荐到职位"
        width="60%"
        visible={visible}
        onCancel={this.onCancel}
        zIndex={1001}
        destroyOnClose
        footer={[
          <Button
            key="back"
            onClick={this.onRecomToPos}
            disabled={!recomPosIds.length || this.state.sendClick || rowSelectItem[0].hh_id == user_id }
          >
            推荐给前台
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={this.onOK}
            disabled={!recomPosIds.length}
          >
            推荐给客户
          </Button>
        ]}
      >
        <div className="position-profile">
          <div className="select-text">
            <Icon type="info-circle" theme="filled" style={{color: "#fa9964", marginRight: "5px"}}/>已选择：
          </div>
          <div className="select-profile">
            {
              selectedCands && selectedCands.map(item => {
                return (
                  <div className="profile"><span className="name">{item.name}</span><span className="no">(ID:{item.no})</span></div>
                )
              })
            }
          </div>
          <div className="select-num">共<span>{selectedCands.length}位</span>候选人</div>
        </div>
        <div className="position-dialog-filter">
          <CheckboxGroup defaultValue={["1"]} onChange={this.positionFilter.bind(this, "type")}><Checkbox value="1">我的职位</Checkbox></CheckboxGroup>
          <Search onSearch={this.positionFilter.bind(this, "keyword")} className="filter-search" placeholder="编号/公司名/职位名"/>
        </div>
        <div className="position-list">
          <Table
            className="gt-table"
            loading={listLoading}
            columns={this.columns}
            dataSource={dataSource}
            pagination={{
              current:  _meta.currentPage,
              total: _meta.totalCount,
              pageSize: _meta.perPage || 5,
              onChange: this.positionListSearch.bind(this)
            }}
            scroll={{ x: 1000 }}
            rowSelection={rowSelection}
          />
        </div>
        <style jsx>
          {`
            .gt-search-bar span {
              margin-right: 5px;
            }
            .position-profile {
              display: flex;
              padding: 10px 136px 0 16px;
              margin-bottom: 20px;
              background-color: #fef5ef;
	            border-radius: 4px;
              .select-text {
                width: 76px;
                min-width: 76px;
                color: #666666;
              }
              .select-profile {

                display: flex;
                flex-wrap: wrap;
                .profile {
                  margin-right: 10px;
                  margin-bottom: 10px;
                  .name {
                    font-weight: bold;
                    color: #333333;
                  }
                  .no {
                    color: #666666;
                  }
                }
              }
              .select-num {
                width: 90px;
                min-width: 90px;
                align-self: flex-end;
                padding-bottom: 10px;
                span {
                  font-weight: bold;
                  color: #333333;
                }
              }
            }
            .position-dialog-filter {
              padding-left: 20px;
              :global(.filter-search) {
                width: 300px;
              }
            }
            .position-list {
              margin-top: 10px;
            }
          `}
        </style>
      </Modal>
    );
  }
}

function createWork(item) {
  let result = [];

  if (item.city_name === item.province_name) {
    item.province_name && result.push(item.province_name);
  } else {
    item.province_name && result.push(item.province_name);
    item.city_name && result.push(item.city_name);
  }
  item.region_name && result.push(item.region_name);
  return result.join("/");
};

function convertList(posList) {
  return (
    posList &&
    posList.map(item => {
      return {
        key: item.id,
        name: {
          NO: `NO.${item.id}`,
          position: item.post_name,
          needNum: `${item.require_num}人`,
          company: (item.company || {}).name
        },
        contact: (item.contactor || {}).contactor,
        phone: (item.contactor || {}).phone,
        email: (item.contactor || {}).email,
        hh_email: item.hh_email,
        hh_id: item.hh_id,
        status: item.status_name,
        area: item.jd_address.map(item => {
          return createWork(item)
        }).join("|"),
        from: item.source_name,
        createTime: moment(item.create_time * 1000).format(
          "YYYY-MM-DD HH:mm:ss"
        )
      };
    })
  );
}
