import React from "react";
import { connect } from "react-redux";
import { Select, Form, Row, Col, Button, Modal, DatePicker } from "antd";
import Table from "components/GeeTable.js";
import DataCenterModal from "store/reducers/dataCenterM";
import moment from "moment";
import CustomIcon from "components/CustomIcon";
import PositionDetail from "pages/PositionVacant/detail.js";
import InternDetail from "pages/TalentPool/Intern/Detail";
import SocialDetail from "pages/TalentPool/SocialCandidate/Detail";
import global from "store/reducers/global";
import user from "store/reducers/user";
import ProgressDialog from "pages/PositionVacant/progress-module/progress-dialog";

import * as positionS from "services/positionS";

const Option = Select.Option;
const FormItem = Form.Item;
const confirm = Modal.confirm;
const { RangePicker } = DatePicker;

class FlowDialogComponent extends React.PureComponent {
  state = {
    searchParam: {},
    profileData: {},
  };

  title = {
    "-1": "已推荐给前台",
    "1": "已推荐给客户",
    "2": "安排面试",
    "3": "面试通过",
    "4": "offer协调",
    "5": "onboard",
    "6": "已淘汰",
  }

  timeStr = {
    "-1": "推荐时间",
    "1": "推荐时间",
    "2": "面试时间",
    "3": "面试反馈时间",
    "4": "offer时间",
    "5": "onboard时间",
    "6": "出局时间",
  }

  componentDidUpdate(prevProps) {
    const {visible} = this.props;
    if (prevProps.visible !== visible && visible) {
      this.searchList({"per-page": 5});
    }
  }

  /**
   * @description 搜索列表
   * @param {string} name  改变的名字
   * @param {number} val   改变的值
   */
  searchList = (params) => {
    const {profileParams, dialogType} = this.props;    
    let param = {...this.state.searchParam, ...profileParams, type: dialogType, ...params};
    this.setState({
      searchParam: param
    })
    positionS.positionProfileData(param).then(res => {
      if (res.code === 0) {
        this.setState({
          profileData: res.data
        })
      }
    })
  };

  onShowSizeChange = (page, pageSize) => {
    this.searchList({
      page: page,
      "per-page": pageSize,
    })
  };

  pageChange = (page) => {
    this.searchList({
      page: page,
    })
  }


  /**
   * @description 关闭或打开弹窗
   */
  handleCancel = e => {
    this.props.onDialogClose();
    this.setState({
      searchParam: {},
      profileData: {},
    })
  };

  /**
   * @description tables样式
   */
  rowStyle = {
    color: "#1890ff",
    cursor: "pointer",
    width: "125px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap"
  };
  /**
   * @description tables样式
   */
  rowDisabledStyle = {
    color: "#cccccc",
    width: "125px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap"
  };

  /**
   * @description 打开onboard弹窗
   * @param {object} record
   */
  openModal = record => {
    if (record.status !== 4) return;
    this.setState({
      openStatus: 5,
      personInfo: {
        ...record,
        id: record.jd_profile_id
      }
    });
  };
  /**
   * @description 打开onboard弹窗
   * @param {object} record
   */
  showModal = () => {
    this.setState({
      openStatus: 0,
      personInfo: {}
    });
  };

  render() {
    const { profileData } = this.state;
    const {
      visible,
      dialogType
    } = this.props;
    const { _meta = {}, extra = {} } = profileData;
    const paginationProps = {
      size: "small",
      showSizeChanger: true,
      showQuickJumper: true,
      total: _meta.totalCount,
      pageSize: _meta.perPage || 5,
      onShowSizeChange: this.onShowSizeChange,
      onChange: this.pageChange,
      showTotal: total => `总计${total}个`
    };
    const { total = {} } = extra;
    /**
     * @description 列表选项
     */
    let columns = [
      {
        title: "候选人",
        dataIndex: "name",
        key: "name",
        align: "left",
        width: 75,
        render: (text, record) => (
          <div
            style={this.rowStyle}
            title={text}
            onClick={() => {
              const { updateStack } = this.props;
              updateStack({
                type: "add",
                componentRef: record.is_intern ? InternDetail : SocialDetail,
                componentProps: {
                  onClose: () => updateStack({ type: "pop" }),
                  stackVisible: true,
                  candidateId: record.profile_id
                }
              });
              this.handleCancel(); //关闭弹窗
            }}
          >
            <CustomIcon
              type={`${record.sex === 1 ? "icon-nan" : "icon-nv1"}`}
              style={{
                fontSize: 15,
                verticalAlign: "bottom",
                paddingRight: "7px"
              }}
            />
            {text}
          </div>
        )
      },
      {
        title: "年龄",
        dataIndex: "age",
        key: "age",
        align: "left",
        needEllipsis: true,
        width: "4%",
        render: text => <div>{text ? text : "--"}</div>
      },
      {
        title: "职位",
        key: "position_name",
        dataIndex: "position_name",
        align: "left",
        needEllipsis: true,
        width: "7%"
      },
      {
        title: "最近工作单位",
        key: "last_job_company_name",
        dataIndex: "last_job_company_name",
        align: "left",
        needEllipsis: true,
        width: "7%",
        render: text => <div>{text ? text : "--"}</div>
      },
      {
        title: "学历",
        key: "education",
        dataIndex: "education",
        align: "left",
        needEllipsis: true,
        width: "5%",
        render: text => <div>{text ? text : "--"}</div>
      },
      {
        title: "推荐人",
        key: "rmd_hh_name",
        dataIndex: "rmd_hh_name",
        align: "left",
        needEllipsis: true,
        width: 60,
      },
      {
        title: this.timeStr[dialogType],
        key: "event_time_str",
        dataIndex: "event_time_str",
        align: "left",
        width: 90,
      },
    ];
    if (dialogType !== 6) {
      columns.push({
        title: "当前状态",
        key: "status_name",
        dataIndex: "status_name",
        align: "left",
        width: 65,
      })
    }

    return (
      <Modal
        width={1180}
        title={this.title[dialogType]}
        visible={visible}
        onOk={this.handleSubmit}
        onCancel={this.handleCancel}
        footer={false}
        className="flowDialog-dialog"
        destroyOnClose
      >
        <Table
          rowKey={record => record.jd_profile_id}
          columns={columns}
          dataSource={profileData.list}
          pagination={paginationProps}
        />
        <style jsx global>
          {`
            .flowDialog-dialog {
              .ant-modal-body {
                padding: 10px 20px;
              }
            }
          `}
        </style>
      </Modal>
    );
  }
}
const FlowDialog = Form.create()(FlowDialogComponent);
export default connect(
  ({ }) => {
    return { };
  },
  {
    updateStack: global.actions.updateDrawerStack
  }
)(FlowDialog);
