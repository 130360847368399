import React, { PureComponent } from "react";
import { connect } from "react-redux";
import {
  Collapse,
  Icon,
  Modal,
  Select,
  Input,
  DatePicker,
  TimePicker,
  Spin,
  Form,
  Radio
} from "antd";
import moment from "moment";
import { commonData } from "services/commonService";
import socialCandModel from "store/reducers/socialCandModel";
import PropTypes from "prop-types";
import Empty from "components/empty";

const Panel = Collapse.Panel;
const Option = Select.Option;
const { TextArea } = Input;
const RadioGroup = Radio.Group;

const labelCol = { span: 4 };
const wrapperCol = { span: 20 };

class CommunicateForm extends PureComponent {
  state = {
    positionOptions: []
  };

  lastFetchId = 0;
  timeout;
  componentDidMount() {
    this.fetch();
  }
  handleSearch = value => {
    let expand = "company,position_name";
    this.lastFetchId += 1;
    const fetchId = this.lastFetchId;
    this.setState({
      positionOptions: [],
      fetching: true
    });
    // 添加type=3是与我相关的职位
    this.props.getJDList({ expand, post_name: value, type: 3,"per-page":9999 }).then(res => {
      if (fetchId !== this.lastFetchId) {
        // for fetch callback order
        return;
      }
      if (res.code === 0) {
        let data = [];
        res.data.list.forEach(item => {
          data.push({
            value: item.id,
            label:
              (item.post_name ? item.post_name : "") +
              "---" +
              (item.company ? item.company.name : "")
          });
        });
        this.setState({
          fetching: false,
          positionOptions: data
        });
      } else {
        this.setState({
          fetching: false
        });
      }
    });
  };

  fetch = value => {
    if (this.timeout) {
      clearTimeout(this.timeout);
      this.timeout = null;
    }
    this.timeout = setTimeout(() => this.handleSearch(value), 300);
  };

  handleChange = value => {
    this.setState({
      fetching: false
    });
  };
  /**
   * @description 选择选项设置沟通记录
   * @param {object} e
   */
  changeRadio = e => {
    this.props.form.setFieldsValue({
      content: e.target.value
    });
  };

  render() {
    const {
      communicateTypeOptions,
      profile_communicate_length,
      profile_communicate_content,
      position
    } = this.props;
    const { getFieldDecorator } = this.props.form;
    return (
      <Form>
        <Form.Item
          className="comm-type comm-time-pick comm-date-pick"
          label="沟通时间"
          labelCol={{ span: 7 }}
          wrapperCol={{ span: 14 }}
        >
          {getFieldDecorator("date", {
            initialValue: moment(),
            rules: [{ required: true, message: "请选择沟通时间" }]
          })(
            <DatePicker  onChange={this.onDate} className="communicate-date" />
          )}
        </Form.Item>
        <Form.Item className="comm-type comm-time-pick">
          {getFieldDecorator("time", {
            initialValue: moment(),
            rules: [{ required: true, message: "请选择时间" }]
          })(
            <TimePicker
              className="communicate-time"
              format="HH:mm"
              defaultOpenValue={moment("00:00", "HH:mm")}
            />
          )}
        </Form.Item>
        <Form.Item className="comm-type comm-time-pick">
          {getFieldDecorator("communication_length", {
            initialValue: 10,
            rules: []
          })(
            <Select className="time-duration" placeholder="时长" allowClear>
              {profile_communicate_length.map(item => (
                <Option value={item.value} key={item.value}>
                  {item.label}
                </Option>
              ))}
            </Select>
          )}
        </Form.Item>
        <Form.Item
          className="comm-type"
          label="沟通形式"
          labelCol={labelCol}
          wrapperCol={wrapperCol}
        >
          {getFieldDecorator("communication_type", {
            initialValue: 0,
            rules: [{ required: true, message: "请选择沟通形式" }]
          })(
            <Select
              placeholder="请选择沟通形式"
              onChange={this.onContactTypeSel}
            >
              {communicateTypeOptions.map(item => (
                <Option value={item.value} key={item.value}>
                  {item.label}
                </Option>
              ))}
            </Select>
          )}
        </Form.Item>
        <Form.Item
          className="comm-type"
          label="关联职位"
          labelCol={labelCol}
          wrapperCol={wrapperCol}
        >
          {getFieldDecorator("jd_id", {
            initialValue: position ? position.name : undefined,
            rules: []
          })(
            <Select
              allowClear
              showSearch
              disabled={position}
              placeholder={this.props.placeholder}
              style={this.props.style}
              defaultActiveFirstOption={false}
              showArrow={false}
              filterOption={false}
              onSearch={this.fetch}
              onChange={this.handleChange}
              notFoundContent={
                this.state.fetching ? <Spin size="small" /> : "暂无数据"
              }
              loading={this.state.fetching}
            >
              {this.state.positionOptions.map(item => (
                <Option value={item.value} key={item.value} title={item.label}>
                  {item.label}
                </Option>
              ))}
            </Select>
          )}
        </Form.Item>
        <Form.Item
          className="comm-detail"
          labelCol={labelCol}
          label="沟通记录"
          wrapperCol={wrapperCol}
        >
          {getFieldDecorator("content", {
            rules: [{ required: true, message: "请选择沟通记录" }]
          })(
            <TextArea
              placeholder="您可以更详细的备注本次沟通记录"
              onChange={this.onContactContext}
            />
          )}
        </Form.Item>
        <Form.Item
          className="comm-btn"
          labelCol={labelCol}
          label={<span>&nbsp;</span>}
          wrapperCol={wrapperCol}
        >
          {getFieldDecorator("radioVal", {})(
            <RadioGroup
              className="radio-btn"
              onChange={this.changeRadio.bind(this)}
            >
              {profile_communicate_content.map(item => {
                return (
                  <Radio.Button value={item.label} key={item.value}>
                    {item.label}
                  </Radio.Button>
                );
              })}
            </RadioGroup>
          )}
        </Form.Item>
      </Form>
    );
  }
}
const WrappedCommunicateForm = Form.create()(CommunicateForm);
export default class CommunicateModal extends PureComponent {
  static propTypes = {
    communicationRecords: PropTypes.any.isRequired,
    addCommunicate: PropTypes.func.isRequired,
    updateCommunicationRecords: PropTypes.func.isRequired
  };

  state = {
    communicateTypeOptions: [],
    profile_communicate_length: []
  };

  componentDidMount() {
    commonData(
      "profile_record,profile_communicate_length,profile_communicate_content"
    ).then(options => {
      this.setState({
        communicateTypeOptions: options.profile_record || [],
        profile_communicate_length: options.profile_communicate_length || [],
        profile_communicate_content: options.profile_communicate_content || []
      });
    });
  }

  onSure = () => {
    const { callBack } = this.props;
    const form = this.communicateForm.props.form;
    form.validateFields((err, values) => {
      if (this.props.position) {
        values.jd_id = this.props.position.id;
      }
      console.log("Received values of form: ", values);
      if (err) {
        return;
      }

      values.profile_id = this.props.candidateId;
      values.communication_time =
        values.date.format("YYYY-MM-DD") + " " + values.time.format("HH:mm");
      if (this.props.position) {
        values.jd_id = this.props.position.id;
      }
      delete values.radioVal
      console.log(values);
      this.props.addCommunicate(values).then(res => {
        if (res.code === 0) {
          form.resetFields();
          if (callBack) {
            callBack({...values, ...res.data});
          }
        }
      });
    });
  };

  onCancel = () => {
    this.props.updateCommunicationRecords({
      addModal: false
    });
    this.communicateForm.props.form.resetFields();
  };

  communicateFormRef = formRef => {
    this.communicateForm = formRef;
  };

  render() {
    const {
      communicateTypeOptions,
      profile_communicate_length,
      profile_communicate_content
    } = this.state;
    const {
      modelRef,
      updateCommunicationRecords,
      getJDList,
      position,
      communicationRecords: { addModal, contactType, contactContext }
    } = this.props;

    return (
      <React.Fragment>
        <Modal
          title="添加沟通记录"
          visible={addModal}
          width={600}
          onOk={this.onSure}
          maskClosable={false}
          // getContainer={() => modelRef.current}
          onCancel={this.onCancel}
        >
          <WrappedCommunicateForm
            wrappedComponentRef={this.communicateFormRef}
            profile_communicate_length={profile_communicate_length}
            position={position}
            updateCommunicationRecords={updateCommunicationRecords}
            getJDList={getJDList}
            communicateTypeOptions={communicateTypeOptions}
            profile_communicate_content={profile_communicate_content}
          />
          <style jsx>{`
              :global(.comm-type) {
                position: relative;
                margin-bottom: 10px;
                :global(.ant-select) {
                  width: 450px;
                  height: 32px;
                }
                :global(.time-duration) {
                  width: 120px;
                  margin-left: 7px;
                }
                :global(.communicate-date) {
                }
              }
              :global(.comm-time-pick) {
                display: inline-block;
                vertical-align: top;
              }
              :global(.comm-date-pick) {
                :global(.ant-col-7) {
                  width: 92px;
                }
                :global(.ant-col-15) {
                  width: 64.5%;
                }
              }
              .comm-detail {
                :global(.ant-input) {
                  padding: 20px;
                  width: 450px;
                  height: 140px;
                  resize: none;
                }
              }
              :global(.comm-btn) {
                :global(.radio-btn) {
                  :global(label) {
                    margin-right: 10px;
                    border-radius: 4px;
                    border: 1px solid #d9d9d9;
                    padding: 0 10px;
                    height: 34px;
                    line-height: 34px;
                    :global(span) {
                      font-size: 14px;
                      padding-right: 0;
                    }
                  }
                  :global(.ant-radio-button-wrapper-checked) {
                    background: #38a4e9;
                    color: #ffffff;
                    box-shadow: none;
                  }
                }
              }
            `}
          </style>
        </Modal>
      </React.Fragment>
    );
  }
}
