import { Input } from 'antd';
import React from 'react';
import styles from './styles.scss';

const CapchaInput = (props) => {
    const { url, placeholder, onCapchaClick, onChange } = props;

    return (
        <div>
            <style jsx>{styles}</style>
            <div className={'capcha-input'}>
                <Input size='large' placeholder={placeholder} onChange={onChange}></Input>
                <img src={url} className="capcha-code" alt='capcha-code' onClick={onCapchaClick} />
            </div>
        </div>
    )
}

export default CapchaInput
