import css from 'styled-jsx/css';

export default css.global`/* @styled-jsx=global */

@import "normalize.scss";
@import "variable.scss";
@import "icons.scss";

body {
  font-family: "Microsoft Yahei", "微软雅黑", Arial, sans-serif !important;
  font-size: 12px;
  line-height: 1.1;
  color: #333;
  height: 100%;
}

.clear:after {
  content: "";
  display: block;
  clear: both;
}

.clear {
  zoom: 1;
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.db{
  display: block;
}

.left {
  float: left;
}

.right {
  float: right;
}

.hide {
  display: none;
}

.gt-search-bar {
  margin-bottom: 20px;
}

.ant-drawer {
  z-index: 999;
}

.scroll-bar {
  &::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 6px; /*高宽分别对应横竖滚动条的尺寸*/
    height: 1px;
  }
  &::-webkit-scrollbar-thumb {
    /*滚动条里面小方块*/
    border-radius: 6px;
    // -webkit-box-shadow: inset 0 0 5px #cdcdcd;
    background: #cdcdcd;
  }
  &::-webkit-scrollbar-track {
    /*滚动条里面轨道*/
    // -webkit-box-shadow: inset 0 0 5px #cdcdcd;
    border-radius: 10px;
    background: unset;
  }
}

div:focus {
  outline: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}

textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
textarea:-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}

//form
.ant-form {
  .ant-form-item-label label:after {
    content: "";
  }
}

.ant-form-item {
  margin-bottom: 16px;
}

.ant-select-lg,
.ant-input-lg {
  font-size: 14px;
}
.ant-input {
  padding-left: 10px;
  &.ant-calendar-picker-input {
    padding-left: 5px;
  }
}
.ant-form-explain {
  margin-top: 0;
}

//列表上面名字
.title-area {
  background-color: #f8fbff;
  .page-name {
    padding-left: 24px;
    height: 50px;
    line-height: 50px;
    font-size: 16px;
    font-weight: bold;
  }
}

//tabs 覆盖 start
.ant-tabs-nav-wrap {
  padding-left: 24px;
  .ant-tabs-nav .ant-tabs-tab {
    padding: 0 16px 12px 16px;
    line-height: 1;
    color: #666666;
  }
}

.ant-tabs-bar {
  margin: 0 0 20px 0;
}
.page-title-tabs {
  position: static;
  .ant-tabs-bar {
    border-bottom: 0;
    background-color: #f8fbff;
  }
  .ant-tabs-nav-wrap {
    .ant-tabs-tab {
      margin-right: 27px;
      padding: 0 5px 12px 5px;
      height: 50px;
      line-height: 50px;
      font-size: 14px;
      color: #999999;
    }
    .ant-tabs-tab-active {
      font-size: 16px;
      font-weight: bold;
      color: #333333;
    }
  }
}
//tab 覆盖 end
.ant-select-selection__rendered {
  margin-left: 10px;
  margin-right: 10px;
}
.ant-select-arrow .ant-select-arrow-icon svg {
  width: 12px;
  height: 9px;
  background-color: initial;
  color: #666666;
}
.ant-select-arrow,
.ant-select-selection__clear {
  right: 20px;
}
// 搜索条件+列表  start
.outer-box {
  background: #fff;
  padding: 0 26px;
  .handle-list-box {
    background: #ffffff;
    border-radius: 2px;
    .table-area {
      margin-top: 20px;
    }
  }
}

// 搜索条件+列表  end

//抽屉创建表单start
.ant-drawer-close {
  right: 23px;
  top: -4px;
}
.creat-drawer-box {
  .ant-drawer-header {
    padding: 13px 24px;
    background: #1890ff;
    border-radius: 0;
    .ant-drawer-title {
      font-size: 16px;
      font-weight: bolder;
      color: #ffffff;
    }
  }
  .ant-drawer-close .anticon-close {
    color: #ffffff;
  }
  .footer-suspend {
    position: absolute;
    bottom: 0;
    width: 100%;
    border-top: 1px solid #e8e8e8;
    padding: 30px 24px 28px 24px;
    text-align: left;
    left: 0;
    border-radius: 0 0 4px 4px;
    background: #ffffff;
    button {
      margin-right: 20px;
    }
  }
}
//抽屉创建表单end

//抽屉信息展示start
.ant-drawer > *{
  transition: unset;
}
.info-drawer-box {

  .ant-drawer-header {
    background: #1890ff;
    border-radius: 0;
    .ant-drawer-title {
      color: #ffffff;
      font-size: 16px;
      padding-right: 36px;
      line-height: 14px;
    }
  }
  .ant-drawer-close {
    right: 24px;
    color: #ffffff;
  }
  .ant-drawer-close-x {
    height: 47px;
    line-height: 47px;
  }
  // Tabs 覆盖
  .ant-tabs-nav-wrap {
    padding-left: 0;
  }
}
//带展示基础信息灰色页面
.single-drawer-box {
  .ant-drawer-body {
    padding: 0;
    line-height: 1.1;
  }
  .single-drawer-head {
    background: #f9f9f9;
    padding: 22px 24px 20px 24px;
    color: #666666;
    div {
      margin-bottom: 14px;
      i {
        margin-left: 5px;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
    .number {
      display: inline-block;
      vertical-align: sub;
    }
    b {
      font-size: 20px;
      padding-right: 20px;
      color: #333333;
      max-width: 530px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      display: inline-block;
    }
  }
  .single-drawer-body {
    padding: 18px 24px 24px 24px;
  }
}
//抽屉信息展示end

//抽屉折叠表单 start
.collapse-handle-box {
  margin-bottom: 20px;
  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    padding: 10px 54px 10px 20px;
    line-height: 16px;
    background: #fafafa;
    border-radius: 4px;
  }
  .ant-collapse > .ant-collapse-item > .ant-collapse-header .arrow {
    right: 14px;
    left: auto;
  }
  .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
    right: 14px;
    left: auto;
  }
  &.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
    right: 14px;
    left: auto;
  }
}

.collapse-handle-box.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  padding: 10px 54px 10px 20px;
  line-height: 16px;
  background: #fafafa;
  border-radius: 4px;
}
.collapse-handle-box.ant-collapse
  > .ant-collapse-item
  > .ant-collapse-header
  .arrow {
  right: 14px;
  left: auto;
}
//抽屉折叠表单 end

//抽屉弹窗 start
.drawer-modal {
  .ant-modal-wrap {
    position: absolute;
    .ant-modal {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  .ant-modal-mask {
    position: absolute;
  }
}
//抽屉弹窗 end

//编辑 ant-form-item 覆盖
.ant-form-item {
  margin-bottom: 16px;
  .ant-form-item-control {
    // line-height: 34px;
  }
}
//table start
.ant-table {
  font-size: 14px;
}
.ant-table .ant-table-scroll .ant-table-body{
  overflow-x: auto !important;
}
.ant-table-thead > tr {
  background-color: #ebf4ff;
}

.ant-table-thead > tr > th {
  border-bottom: 0;
}

.ant-table-thead > tr:first-child > th:first-child {
  padding-left: 20px;
  &.ant-table-selection-column {
    padding-left: 0;
  }
}

.ant-table-thead > tr .ant-table-column-title {
  color: #666;
  font-weight: bold;
}

.ant-table-tbody > tr > td:first-child {
  padding-left: 20px;
  &.ant-table-selection-column {
    padding-left: 0;
  }
}

.ant-table-tbody > tr > td {
  padding: 13px 4px;
  border-bottom: 0;
}
.ant-table-thead > tr > th {
  padding: 14px 4px;
}
.ant-table-tbody > tr > td {
  color: #666;
}
.ant-table-tbody > tr:nth-child(2n) {
  background-color: #f8fbff;
}
.ant-table-tbody > tr:last-child {
  border-bottom: 1px solid #e5e5e5;
  transition:none;
}
.gee-row-sel {
  background-color: #f0fcff;
}
//ant-pagination
.ant-pagination.mini {
  font-size: 12px;
  color: #666;
  input, .ant-select {
    font-size: 12px;
  }
}
//modal start
.ant-modal-title {
  font-size: 17px;
  color: #333333;
  font-weight: 600;
}

//textarea
textarea.ant-input {
  padding: 10px;
}

// 弹窗样式覆盖
// 右下角的按钮
.ant-modal-footer button + button {
  margin-left: 20px;
}

.gee-input-number-unit {
  position: relative;
  .unit {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    line-height: 40px;
    padding-right: 25px;
  }
}

// cascader
.ant-cascader-picker {
  .ant-cascader-picker-arrow svg {
    width: 12px;
    height: 9px;
    background-color: initial;
    color: #666666;
  }
  .ant-cascader-picker-arrow,
  .ant-cascader-picker-clear {
    right: 20px;
  }
}

.ant-cascader-picker-label {
  padding: 0 20px;
}

.ellipsis-style {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

table {
  table-layout: fixed !important;
}

.minwidth150 {
  min-width: 150px;
}

.minwidth200 {
  width: 30%;
}

pre {
  font-family: "Microsoft YaHei";
  font-size: 1em;
  white-space: pre-wrap;
  line-height: 1.5;
  overflow-y: hidden;
}

.org-tree-select {
  .ant-select-tree-icon__docu {
    display: none !important;
  }
  // .ant-select-tree-icon__close {
  //   background: url("/assets/imgs/icon-team.png") no-repeat;

  // }
  // .ant-select-tree-icon__open {
  //   background: url("/assets/imgs/icon-team.png") no-repeat;
  // }
}

.introjs-hint {
  z-index: 999;
}
//由于打开弹窗后在点击抽屉显示，会出现抽屉在下面
// .ant-modal-mask,.ant-modal-wrap{
//   z-index: 1;
// }

.all-search-popup {

  width: 300px;
  .ant-form-item {
    margin-bottom: 0;
  }
  .ant-popover-inner-content {
    padding: 0;
    overflow-y: auto;
  }
  .base-area {
    padding: 20px;
    label, input {
      font-size: 12px;
    }
    .custome-type {
      width: 100%;
    }
  }
  .submit-area {
    padding-top: 10px;
    height: 44px;
    border-top: 1px solid #e5e5e5;
    .ant-btn {
      margin-right: 10px;
      font-size: 12px;
    }
  }
}
.show-img-dialog-box{
  .ant-modal-body{
    padding: 0;
  }
  .ant-modal-close  {
    top: -50px;
    right: -40px;
  }
}


.add-record-popup {
  width: 300px;
  .ant-popover-inner-content {
    padding-top: 0;
    padding-right: 6px;
    max-height: 300px;
    overflow-y: auto;
  }
  .ant-popover-title {
    padding: 0 0 0 14px;
    height: 40px;
    line-height: 40px;
    .anticon {
      margin-right: 5px;
      vertical-align: -0.225em;
      font-size: 16px;
    }
  }
  .communication-item {
    display: flex;
    margin-top: 20px;
    .info {
      flex: 1;
      .communication-base {
        display: flex;
        justify-content: space-between;
        font-size: 12px;
        color: #999999;
        span.time {
          max-width: 135px;
        }
        span.name {
          max-width: 110px;
        }
      }
      .communication-content {
        margin-top: 10px;
        padding: 10px;
        color: #666666;
        background-color: #f5f5f5;
        word-break: break-all;
      }
    }

    .delete {
      align-self: flex-end;
      padding-left: 5px;
      padding-bottom: 10px;
      width: 20px;
      text-align: center;
      color: #999999;

      .anticon {
        font-size: 16px;
      }
    }
  }
}`