import React, { PureComponent } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import {
  Input,
  Row,
  Col,
  Select,
  message,
  Form,
  Button,
  Upload,
  Icon
} from "antd";
import { Modal } from "antd";
import recomCandToPosModel from "store/reducers/recomCandToPosModel";
import ReactQuill from "react-quill";
import {emailReg} from "utils/constant.js";
/**
 * 简历邮件发送
 */
export default withRouter(
  connect(
    ({ recomCandToPos, user }) => ({
      resumeEmail: recomCandToPos.resumeEmail,
      recommandPos: recomCandToPos.recommandPos,
      selectedCandIds: recomCandToPos.selectedCandIds,
      user
    }),
    { ...recomCandToPosModel.actions }
  )(
    class ResumeEmailSend extends PureComponent {
      emailFormRef = React.createRef();

      state = {
        text: "",
        title: {
          validateStatus: "",
          help: "",
          value: ""
        },
        emails: {
          validateStatus: "",
          help: "",
          value: []
        },
        sendClick: false,
      };

      modules = {
        toolbar: [
          [{ header: [1, 2, false] }],
          ["bold", "italic", "underline", "strike", "blockquote"],
          [
            { list: "ordered" },
            { list: "bullet" },
            { indent: "-1" },
            { indent: "+1" }
          ],
          ["link", "image"],
          ["clean"]
        ]
      };

      formats = [
        "header",
        "bold",
        "italic",
        "underline",
        "strike",
        "blockquote",
        "list",
        "bullet",
        "indent",
        "link",
        "image"
      ];

      componentDidUpdate(prevProps) {
        if (prevProps.recommandPos.emails !== this.props.recommandPos.emails) {
          this.setState({
            emails: {
              validateStatus: "",
              help: "",
              value: this.props.recommandPos.emails
            }
          });
        }
      }

      handleChange = value => {
        this.setState({ text: value });
      };

      onTitleChange = value => {
        this.setState({ title: value });
      };

      onOk = () => {
        const validateResult = this.emailFormRef.current.submitValidate();
        const { text, title, emails } = this.state;
        if (!validateResult) {
          return;
        }
        this.setState({
          sendClick: true
        })
        const { recommandPos } = this.props;
        this.props
          .recommandToPosAndEmail({
            title: title.value,
            emails: emails.value,
            content: text,
            files: this.files
          })
          .then(res => {
            if (res.code === 0) {
              message.success("人选推荐成功！");
              if (recommandPos.type) {
                this.props.history.replace({
                  pathname: "/position/vacant",
                  state: { jd: recommandPos.recomPosIds[0] }
                });
              }
              this.onCancel();
            } else {
              message.error(res.msg);
            }
            this.setState({
              sendClick: false
            })
          });
      };

      onSomeThing = () => {
        const { recommandPos } = this.props;
        this.setState({
          sendClick: true
        })
        if (recommandPos.type) {
          this.props.recommandToPos().then(res => {
            if (res.code === 0) {
              message.success("人选推荐成功！");
              this.props.history.replace({
                pathname: "/position/vacant",
                state: { jd: recommandPos.recomPosIds[0] }
              });
              this.onCancel();
            } else {
              message.error(res.msg);
            }
            this.setState({
              sendClick: false
            })
          });
        } else {
          this.onCancel();
          this.setState({
            sendClick: false
          })
        }
      };

      onCancel = () => {
        this.setState({
          text: "",
          title: {
            validateStatus: "",
            help: "",
            value: ""
          }
        });
        // empty recommand status
        this.props.cleanRecomStatus();
        // close send email modal
        this.props.updateResumeEmail({ visible: false });
      };

      onEmailChange = value => {
        let emailReg = /([a-z0-9]*[-_.]?[a-z0-9]+)+@([a-z0-9]*[-_]?[a-z0-9]+)+[.][a-z]{2,3}([.][a-z]{2})?/i;
        if(value.value.length != 0 && !emailReg.test(value.value[value.value.length-1])) {
          message.error("输入的邮箱格式有误");
          return;
        }
        this.setState(
          {
            emails: value
          },
          () => {
            console.log(this, this.state.emails, value);
          }
        );

        // this.props.updateRecommandPos({ emails: value });
      };

      onAffixChange = value => {
        this.files = value;
      };

      render() {
        const { title, emails } = this.state;
        const { resumeEmail, selectedCandIds, recommandPos } = this.props;
        const { visible } = resumeEmail;
        const { type } = recommandPos;
        return (
          <Modal
            title="推荐候选人"
            width="600px"
            className="recommend-email-modal"
            footer={
              <div>
                <Button disabled={this.state.sendClick} onClick={this.onSomeThing}>
                  {type ? "推荐到职位" : "取消"}
                </Button>
                <Button disabled={this.state.sendClick} type="primary" onClick={this.onOk}>
                  {type ? "推荐并发送邮件" : "发送邮件"}
                </Button>
              </div>
            }
            onCancel={this.onCancel}
            visible={visible}
          >
            <div className="header">
              <EmailForm
                ref={this.emailFormRef}
                emails={emails}
                title={title}
                onAffixChange={this.onAffixChange}
                onTitleChange={this.onTitleChange}
                onEmailChange={this.onEmailChange}
              />
            </div>
            <div className="main">
              <ReactQuill
                value={this.state.text}
                onChange={this.handleChange}
                modules={this.modules}
                formats={this.formats}
              />
            </div>
            <style jsx>{`
              :global(.recommend-email-modal) {
                :global(.ant-modal-body) {
                  .header {
                    :global(.ant-row) {
                      margin-bottom: 10px;
                    }
                  }
                  .main {
                    margin-top: 20px;
                    :global(.ql-editor) {
                      min-height: 300px;
                    }
                  }
                }
              }
            `}</style>
          </Modal>
        );
      }
    }
  )
);

const FormItem = Form.Item;
class EmailForm extends PureComponent {
  state = { fileList: [] };
  onEmailChange = value => {
    const { onEmailChange } = this.props;
    onEmailChange({
      validateStatus: !value || !value.length ? "error" : "success",
      help: !value || !value.length ? "请填写邮箱地址" : "",
      value
    });
  };

  onTitleChange = e => {
    const { onTitleChange } = this.props;
    const value = e.target ? e.target.value : e;
    onTitleChange({
      validateStatus: !value ? "error" : "success",
      help: !value ? "请填写邮件标题" : "",
      value
    });
  };

  submitValidate = () => {
    const { emails, title } = this.props;
    this.onEmailChange(emails.value);
    this.onTitleChange(title.value);
    return emails.value && emails.value.length && title.value;
  };

  uploadChange = info => {
    let { file, fileList } = info;
    if (file.status === "done") {
      if (file.response.code !== 0) {
        message.error(file.response.data.files);
        fileList = fileList.filter(item => item.uid !== file.uid);
      }
      const fileUrl = fileList.map(file => file.response.data.url[0]);
      this.setState({ fileUrl });
      this.props.onAffixChange(fileUrl);
    }
    this.setState({ fileList });
  };

  render() {
    const formItemLayout = {
      labelCol: { span: 3 },
      wrapperCol: { span: 21 }
    };
    const { emails, title } = this.props;
    console.log("props", emails);
    return (
      <Form className="edit-form" layout="horizontal">
        <FormItem
          label="收件人"
          {...formItemLayout}
          validateStatus={emails.validateStatus}
          help={emails.help}
        >
          <Select
            mode="tags"
            value={emails.value}
            placeholder="请输入收件人"
            style={{ width: "100%" }}
            onChange={this.onEmailChange}
            tokenSeparators={[","]}
          />
        </FormItem>
        <FormItem
          label="邮件标题"
          {...formItemLayout}
          validateStatus={title.validateStatus}
          help={title.help}
        >
          <Input
            placeholder="请输入邮件标题"
            value={title.value}
            onChange={this.onTitleChange}
          />
        </FormItem>
        <FormItem label="附件" {...formItemLayout}>
          <Upload
            listType="text"
            action="admin/v1/common/upload-files"
            name="files"
            fileList={this.state.fileList}
            onChange={this.uploadChange}
            className={`upload-list-inline offer-uploader`}
          >
            <Button>
              <Icon type="upload" /> 上传附件
            </Button>
          </Upload>
        </FormItem>
        <style jsx global>{`
          .offer-uploader {
            .ant-upload-list {
              display: inline-block;
              margin-top: 5px;
              max-width: 750px;
              vertical-align: top;
              .ant-upload-list-item {
                float: left;
                max-width: 200px;
                margin-left: 8px;
                line-height: 22px;
              }
              .anticon-close {
                right: 0;
              }
            }

            &.disabled {
              i.anticon-close {
                display: none;
              }
            }
          }
        `}</style>
      </Form>
    );
  }
}
