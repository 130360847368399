import React from "react";
import { connect } from "react-redux";
import {
  Row,
  Col,
  Button,
  Select,
  Input,
  Form,
  Cascader,
  Drawer,
  Popover,
  Modal,
  Tooltip,
  Radio,
  AutoComplete,
  message
} from "antd";
import CityCascade from "components/CityCascade";
import Table from "components/GeeTable.js";
import companyModel from "store/reducers/companyModel";
import LogoUpload from "components/UserAvatarUpload/logoUpload";
import { history } from "root.js";
import _ from "underscore";
import InputComplete from "components/InputComplete";
import * as companyService from "services/companyService";
import {setDisabledOption} from "utils/util";
import * as commonService from "services/commonService";



const { TextArea } = Input;
const Option = Select.Option;
const FormItem = Form.Item;

@connect(
  ({ company, user }) => {
    return { ...company, user };
  },
  {
    ...companyModel.actions
  }
)
class AddCompanyForm extends React.Component {
  state = {
    companyNameList: {},
    hasRename: false,
    selData: [] //选项
  };

  showCompany = record => {
    const { getCompanyDetail } = this.props;
    getCompanyDetail(
      {
        id: record.id
      },
      true
    ).then(res => {});
  };

  leastOne = (rule, value, callback) => {
    const { getFieldValue, validateFields } = this.props.form;
    if (rule.field === "phone") {
      if (!value && !getFieldValue("office_number")) {
        callback("手机和电话号码必填一个");
      }
    } else if (rule.field === "office_number") {
      if (!value && !getFieldValue("phone")) {
        callback("手机和电话号码必填一个");
      }
    }

    callback();
  };

  validatePhone = (name, e) => {
    const { validateFields } = this.props.form;

    if (!e.target.value || e.target.value.length === 1) {
      setTimeout(() => {
        validateFields(["phone", "office_number"]);
      }, 0);
    }
  };

  createRenameLayout = () => {
    const columns = [
      {
        title: "编号",
        width: 88,
        dataIndex: "id",
        render: (text, record) => {
          return (
            <span className="rename-no" style={{ color: "#333333" }}>
              {text}
            </span>
          );
        }
      },
      {
        title: "公司名称",
        width: 242,
        dataIndex: "name",
        render: (text, record) => {
          return (
            <span
              style={{
                cursor: "pointer",
                color: "#38a4e9",
                fontWeight: "bold"
              }}
              className="rename-clicker"
              onClick={() => this.showCompany(record)}
            >
              {text}
            </span>
          );
        }
      }
    ];
    return (
      <Table
        className="repeat-company-table"
        pagination={false}
        columns={columns}
        dataSource={this.state.companyNameList.list}
        size="middle"
      />
    );
  };
  getCompanyInfo = name => {
    const { getCompanyByNameList } = this.props;
    getCompanyByNameList({
      expand: "contacts",
      type: 1,
      name: name
    }).then(res => {
      if (res.code == 0) {
        this.setState(
          {
            companyNameList: res.data,
            hasRename: res.data && res.data.list && res.data.list.length != 0
          },
          () => {
            console.log(this.state, "ppp");
          }
        );
      }
    });
  };
  onSelect = name => {
    this.getCompanyInfo(name);
  };
  onChangeCompany = name => {
    this.setState(
      {
        hasRename: false
      },
      () => {
        console.log(this.state, "pp11p");
      }
    );
  };
  onBlurHandle = (val) =>{
    if(!val) return;
    let params={
        name:val,
        type:3,
    }
    console.log('val',val,'param',params)
    commonService.verifyCompany(params).then(res => {
      if (res.code === 0) {

      } else {
        message.warning(res.msg,4);
      }
    })  
  };
  searchName = name => {
    if (name) _.debounce(this.getCompanyInfo("companyList", name), 1000);
  };

  onSave = () => {
    const form = this.props.form;
    const {
      addCompany,
      onClose,
      getCompanyList,
      companyListCondition,
      companyListPagination
    } = this.props;
    if (this.state.hasRename) return;

    form.validateFields((err, values) => {
      if (err) {
        return;
      }

      values.bd_id = values.bd_ids && values.bd_ids[values.bd_ids.length - 1];
      values.hh_id = values.hh_ids && values.hh_ids[values.hh_ids.length - 1];
      values.province_id = values.city && values.city[0];
      values.city_id = values.city && values.city[1];
      values.region_id = values.city && values.city[2];
      values.source =
        values.source_labelValue[values.source_labelValue.length - 1];
      console.log("Received values of form: ", values);
      addCompany(values).then(res => {
        if (res.code == 0) {
          if (history.location.pathname.indexOf("/custom/company") != -1) {
            getCompanyList(
              {
                "per-page": companyListPagination.pageSize,
                // page: companyListPagination.current,
                ...companyListCondition
              },
              companyListCondition
            );
          }
          form.resetFields();
          onClose("save");
        }
      });
    });
  };

  onClose = () => {
    const form = this.props.form;
    form.resetFields();
    this.props.onClose();
  };

  render() {
    const { commonData, user: userData } = this.props;
    const { companyNameList, hasRename } = this.state;
    const formItemLayout = {
      labelCol: { span: 5 },
      wrapperCol: { span: 19 }
    };
    const size = "large";
    const { getFieldDecorator } = this.props.form;
    const children = this.state.selData.map(name => (
      <Option key={name}>{name}</Option>
    ));
    let sourceOption=setDisabledOption(commonData.jd_source);
    

    return (
      <Form className="add-company-form">
        <div className="company-info">
          <h4>公司信息</h4>
          <Row gutter={35}>
            <Col span={12}>
              <FormItem
                className="add-company-item"
                {...formItemLayout}
                label="公司名称"
              >
                {getFieldDecorator("name", {
                  // getValueFromEvent: this.searchName,
                  rules: [
                    { required: true, message: "请输入公司名称" },
                    { max: 20, message: "公司名称不能超过20个字" }
                  ]
                })(
                  // <AutoComplete
                  //   onSelect={this.onSelect}
                  //   // dataSource={this.state.selData}
                  //   onSearch={this.searchName}
                  //   size={size}
                  //   placeholder="请输入公司名称"
                  // >
                  //   {children}
                  // </AutoComplete>
                  <InputComplete
                    size={size}
                    onSelect={this.onSelect}
                    onChange={this.onChangeCompany}
                    setSearchExpand={{ expand: "contacts", type: 1 }}
                    requestFunc={companyService.companyByNameList}
                    placeholder="请输入"
                    onBlur={this.onBlurHandle}
                  />
                )}
              </FormItem>
            </Col>
            <Col span={12}>
              {hasRename && (
                <Popover content={this.createRenameLayout()} title="">
                  <div className="rename-company">
                    重名公司（{companyNameList.list.length}）
                  </div>
                </Popover>
              )}
            </Col>
          </Row>
          <Row gutter={35}>
            <Col span={12}>
              <FormItem
                label="公司Logo"
                className="add-company-item"
                {...formItemLayout}
              >
                {getFieldDecorator("logo", {
                  normalize: value =>
                    value && value.data ? value.data.url : value
                })(
                  <LogoUpload
                    action="/admin/v1/common/upload"
                    name="attachment"
                  />
                )}
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem
                className="add-company-item"
                {...formItemLayout}
                label="公司简称"
              >
                {getFieldDecorator("other_name", {
                  // rules: [{ max: 10, message: "公司简称不能超过10个字" }]
                })(<Input size={size} placeholder="请输入公司简称" />)}
              </FormItem>
            </Col>
          </Row>
          <Row gutter={35}>
            <Col span={12}>
              <FormItem
                className="add-company-item"
                {...formItemLayout}
                label="公司规模"
              >
                {getFieldDecorator("scale", {
                  rules: [{ required: true, message: "请选择公司规模" }]
                })(
                  <Select placeholder="请选择公司规模" size={size} allowClear>
                    {commonData.company_scale &&
                      commonData.company_scale.map(item => {
                        return (
                          <Option key={item.value} value={item.value}>
                            {item.label}
                          </Option>
                        );
                      })}
                  </Select>
                )}
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem
                className="add-company-item"
                {...formItemLayout}
                label="公司性质"
              >
                {getFieldDecorator("company_type", {
                  rules: [{ required: true, message: "请选择公司性质" }]
                })(
                  <Select placeholder="请选择公司性质" size={size} allowClear>
                    {commonData.company_type &&
                      commonData.company_type.map(item => {
                        return (
                          <Option key={item.value} value={item.value}>
                            {item.label}
                          </Option>
                        );
                      })}
                  </Select>
                )}
              </FormItem>
            </Col>
          </Row>
          <Row gutter={35}>
            <Col span={12}>
              <FormItem
                className="add-company-item"
                {...formItemLayout}
                label="行业类别"
              >
                {getFieldDecorator("industry", {
                  rules: [{ required: true, message: "请选择行业类别" }]
                })(
                  <Select placeholder="请选择行业类别" size={size} allowClear>
                    {commonData.company_industry &&
                      commonData.company_industry.map(item => {
                        return (
                          <Option key={item.value} value={item.value}>
                            {item.label}
                          </Option>
                        );
                      })}
                  </Select>
                )}
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem
                className="add-company-item"
                {...formItemLayout}
                label="所属城市"
              >
                {getFieldDecorator("city", {
                  rules: []
                })(
                  <CityCascade
                    size={size}
                    expandTrigger="hover"
                    changeOnSelect
                    className="gee-cascader"
                    fieldNames={{
                      label: "name",
                      value: "id",
                      children: "list"
                    }}
                    options={commonData.cities}
                    placeholder="请选择所属城市"
                  />
                )}
              </FormItem>
            </Col>
          </Row>
          <Row gutter={35}>
            <Col span={12}>
              <FormItem
                className="add-company-item"
                {...formItemLayout}
                label="详细地址"
              >
                {getFieldDecorator("address", {
                  rules: [{ max: 128, message: "自定义名称不能超过128个字符" }]
                })(<Input size={size} placeholder="请输入详细地址" />)}
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem
                className="add-company-item"
                {...formItemLayout}
                label="负责商务"
              >
                {getFieldDecorator("bd_ids", {
                  rules: [],
                  initialValue:
                    userData.roles == "business"
                      ? [
                          userData.org_info.org_city.id,
                          userData.org_info.org_team.id,
                          userData.org_info.org_group.id,
                          userData.org_info.org_user.id
                        ].filter(item => item)
                      : undefined
                })(
                  <Cascader
                    placeholder="请选择负责商务"
                    size={size}
                    showSearch
                    className="gee-cascader"
                    fieldNames={{
                      label: "name",
                      value: "id",
                      children: "list"
                    }}
                    options={commonData.company_manager}
                  />
                )}
              </FormItem>
            </Col>
          </Row>
          <Row gutter={35}>
            <Col span={12}>
              <FormItem
                className="add-company-item"
                {...formItemLayout}
                label="来源"
              >
                {getFieldDecorator("source_labelValue", {
                  initialValue: [16, 26],
                  rules: [{ required: true, message: "请选择线索来源" }]
                })(
                  // <Select placeholder="请选择线索来源" size={size}>
                  //   {commonData.jd_source &&
                  //     commonData.jd_source.map(item => {
                  //       return (
                  //         <Option key={item.value} value={item.value}>
                  //           {item.label}
                  //         </Option>
                  //       );
                  //     })}
                  // </Select>
                  <Cascader
                    className="gee-cascader custome-type"
                    expandTrigger="hover"
                    changeOnSelect
                    size="large"
                    fieldNames={{
                      label: "label",
                      value: "value",
                      children: "list"
                    }}
                    options={sourceOption}
                    placeholder="请选择线索来源"
                  />
                )}
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem
                className="add-company-item"
                {...formItemLayout}
                label="负责顾问"
              >
                {getFieldDecorator("hh_ids", {
                  rules: [],
                  initialValue:
                    userData.roles != "business"
                      ? [
                          userData.org_info.org_city.id,
                          userData.org_info.org_team.id,
                          userData.org_info.org_group.id,
                          userData.org_info.org_user.id
                        ].filter(item => item)
                      : undefined
                })(
                  <Cascader
                    placeholder="请选择负责顾问"
                    size={size}
                    showSearch
                    className="gee-cascader"
                    fieldNames={{
                      label: "name",
                      value: "id",
                      children: "list"
                    }}
                    options={commonData.company_manager}
                  />
                )}
              </FormItem>
            </Col>
          </Row>
          <Row gutter={35}>
            <Col span={24}>
              <FormItem
                className="add-company-item fromitem-company-intro"
                labelCol={{ span: 2 }}
                wrapperCol={{ span: 22 }}
                label="公司描述"
              >
                {getFieldDecorator("desc", {
                  rules: [
                    { required: true, message: "请输入公司描述" },
                    { max: 200, message: "公司描述不能超过200个字" }
                  ]
                })(<TextArea size={size} placeholder="请输入公司描述" />)}
              </FormItem>
            </Col>
          </Row>
        </div>
        <div className="contacter">
          <h4>联系人</h4>
          <Row gutter={35}>
            <Col span={12}>
              <FormItem
                className="add-business-item"
                {...formItemLayout}
                label="联系人"
              >
                {getFieldDecorator("contactor", {
                  rules: [
                    { required: true, message: "请输入联系人" },
                    { max: 10, message: "联系人不能超过10个字" }
                  ]
                })(<Input size={size} placeholder="请输入联系人" />)}
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem
                className="add-business-item"
                {...formItemLayout}
                label="性别"
              >
                {getFieldDecorator("sex", {
                  rules: []
                })(
                  <Select placeholder="请选择性别" size={size}>
                    <Option value="1">男</Option>
                    <Option value="2">女</Option>
                  </Select>
                )}
              </FormItem>
            </Col>
          </Row>
          <Row gutter={35}>
            <Col span={12}>
              <FormItem
                className="add-business-item"
                {...formItemLayout}
                label="担任职位"
              >
                {getFieldDecorator("position", {
                  rules: [{ max: 20, message: "担任职位不能超过20个字" }]
                })(<Input size={size} placeholder="请输入担任职位" />)}
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem
                className="add-business-item"
                {...formItemLayout}
                label="手机"
              >
                {getFieldDecorator("phone", {
                  rules: [
                    // {
                    //   pattern: new RegExp(/^[0-9]*$/),
                    //   message: "手机号码只能为数字"
                    // },
                    {
                      validator: this.leastOne
                    }
                  ]
                })(
                  <Input
                    size={size}
                    placeholder="请输入手机"
                    onChange={e => this.validatePhone("phone", e)}
                  />
                )}
              </FormItem>
            </Col>
          </Row>
          <Row gutter={35}>
            <Col span={12}>
              <FormItem
                className="add-business-item"
                {...formItemLayout}
                label="公司电话"
              >
                {getFieldDecorator("office_number", {
                  rules: [
                    // {
                    //   pattern: new RegExp(
                    //     /^((0\d{2,3}-\d{7,8})|(1[3456789]\d{9}))$/
                    //   ),
                    //   message: "号码格式不正确"
                    // },
                    {
                      validator: this.leastOne
                    }
                  ]
                })(
                  <Input
                    size={size}
                    placeholder="请输入工作电话"
                    onChange={e => this.validatePhone("office_number", e)}
                  />
                )}
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem
                className="add-business-item"
                {...formItemLayout}
                label="工作邮箱"
              >
                {getFieldDecorator("email", {
                  rules: [
                    {
                      pattern: new RegExp(
                        /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/g
                      ),
                      message: "请输入正确的邮箱格式"
                    }
                  ]
                })(<Input size={size} placeholder="请输入工作邮箱" />)}
              </FormItem>
            </Col>
          </Row>
          <Row gutter={35}>
            <Col span={12}>
              <FormItem
                className="add-business-item"
                {...formItemLayout}
                label="客户类型"
              >
                {getFieldDecorator("origin_type", {
                  initialValue: 1,
                  rules: [{ required: true, message: "请选择客户类型" }]
                })(
                  <Radio.Group>
                    <Radio value={1}>即派客户</Radio>
                    <Radio value={2}>非即派客户</Radio>
                  </Radio.Group>
                )}
              </FormItem>
            </Col>
          </Row>
        </div>
        <div
          style={{
            position: "absolute",
            bottom: 0,
            width: "100%",
            borderTop: "1px solid #e8e8e8",
            padding: "10px 16px",
            textAlign: "left",
            left: 0,
            background: "#fff",
            borderRadius: "0 0 4px 4px"
          }}
        >
          {hasRename ? (
            <Tooltip
              defaultVisible={true}
              title="由于存在相同名称的公司，不能创建/保存公司"
            >
              <Button
                onClick={this.onSave}
                type="primary"
                style={{ marginRight: 20 }}
              >
                保存
              </Button>
            </Tooltip>
          ) : (
            <Button
              onClick={this.onSave}
              type="primary"
              style={{ marginRight: 20 }}
            >
              保存
            </Button>
          )}
          <Button
            style={{
              marginRight: 8
            }}
            onClick={this.onClose}
          >
            取消
          </Button>
        </div>
        <style jsx>{`
          .rename-company {
            display: inline-block;
            margin-top: 13px;
            font-weight: bold;
            color: #38a4e9;
          }
        `}</style>
        <style jsx global>{`
          .add-company-form {
            padding-bottom: 53px;
            h4 {
              font-size: 16px;
              font-weight: 600;
              margin: 40px 0;
            }
            .company-info h4 {
              margin-top: 0;
            }
            .ant-row {
              padding-left: 10px;
            }
            .fromitem-company-intro {
              .ant-form-item-label {
                width: 10%;
              }
              .ant-form-item-control-wrapper {
                width: 90%;
              }
            }
          }
          .ant-tooltip {
            max-width: 350px;
          }
          .repeat-company-table {
            table {
              width: 376px;
            }
          }
        `}</style>
      </Form>
    );
  }
}

const AddCompany = Form.create({})(AddCompanyForm);

@connect(
  ({ company, user }) => {
    return { ...company, user };
  },
  {
    ...companyModel.actions
  }
)
export default class AddCompanyDrawer extends React.Component {
  componentDidMount() {
    const { getCommonData } = this.props;
    getCommonData(
      "company_type,company_industry,company_scale,jd_source,cities,service_type,company_manager,org_id,city_id,jd_source_search,contract_type"
    );
  }

  onClose = type => {
    const { updateAddCompanyVisible } = this.props;
    if (type != "save") {
      Modal.confirm({
        title: "确定要放弃正在编辑的客户吗？",
        getContainer: false,
        onOk() {
          // setTimeout(() => {
            updateAddCompanyVisible(false);
          // }, 300);
        },
        onCancel() {}
      });
    } else {
      updateAddCompanyVisible(false);
    }
  };

  render() {
    const { addCompanyVisible, commonData } = this.props;
    return (
      <Drawer
        className="creat-drawer-box add-company-drawer"
        title="创建客户"
        placement="right"
        closable={true}
        width={900}
        onClose={this.onClose}
        destroyOnClose={true}
        zIndex={999}
        visible={addCompanyVisible}
        // style={{ minHeight: 1100 }}
      >
        <AddCompany onClose={this.onClose} commonData={commonData} />
      </Drawer>
    );
  }
}
