import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { Row, Col, Icon, Form, DatePicker, Input } from "antd";
import { EditViewSingle, EditViewMulti } from "components/EditView";
import socialCandModel from "store/reducers/socialCandModel";
import moment from "moment";
import _ from "underscore";
import updateCompletewrapper from "./update-complete-wrapper";

const FormItem = Form.Item;
const { TextArea } = Input;

const { MonthPicker } = DatePicker;

function mapPropsToFields(props) {
  const { item } = props;
  const formFields = _.mapObject(item, function(val, key) {
    return Form.createFormField({
      // value: val && val
      ...val,
      value: val && val.value
    });
  });
  // formFields.date = Form.createFormField({
  //   value:
  //     item.date && item.date != "0000-00-00"
  //       ? moment(item.date, "YYYY/MM")
  //       : undefined
  // });
  return formFields;
}

export function fieldsToProps(values) {
  const { date } = values;
  values.date = date && date.format("YYYY/MM");
  return values;
}

export function onCertFieldsChange(props, changedFields) {
  props.onChange(changedFields);
}

export function createCertificateEditForm(mapPropsToFields, fieldsToProps, onFieldsChange) {
  return Form.create({
    mapPropsToFields,
    onFieldsChange
  })(
    class EditForm extends PureComponent {
      handleSubmit = () => {
        return new Promise((resolve, reject) => {
          this.props.form.validateFieldsAndScroll({scroll: {offsetBottom: 60}},(err, values) => {
            if (!err) {
              resolve(fieldsToProps(values));
            } else {
              reject(err);
            }
          });
        });
      };

      render() {
        const formItemLayout = {
          labelCol: { span: 5 },
          wrapperCol: { span: 19 }
        };

        const { getFieldDecorator } = this.props.form;
        return (
          <div className="certificate-edit">
            <Form className="edit-form" layout="horizontal">
              <Row className="one" gutter={35}>
                <Col span={12}>
                  <FormItem label="证书名称" {...formItemLayout}>
                    {getFieldDecorator("name", {
                      rules: [
                        {
                          required: true,
                          max: 60,
                          message: "请输入证书名称，且最大60个字符"
                        }
                      ]
                    })(<Input placeholder="请输入证书名称" size="large" />)}
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem label="获得日期" {...formItemLayout}>
                    {getFieldDecorator("date", {
                      rules: [
                        {
                          required: false,
                          message: "请选择获得日期"
                        }
                      ]
                    })(
                      <MonthPicker
                        format="YYYY/MM"
                        placeholder="请选择获得日期"
                        style={{ width: "100%" }}
                        size="large"
                      />
                    )}
                  </FormItem>
                </Col>
              </Row>
              <Row className="two" gutter={35}>
                <Col span={24}>
                  <FormItem
                    label="具体描述"
                    labelCol={{ span: 2 }}
                    wrapperCol={{ span: 22 }}
                  >
                    {getFieldDecorator("desc", {
                      rules: []
                    })(<TextArea placeholder="具体描述" />)}
                  </FormItem>
                </Col>
              </Row>
            </Form>
            <style jsx>
              {`
                .certificate-edit {
                  :global(.two) {
                    :global(.ant-form-item-label) {
                      width: 10%;
                    }
                    :global(.ant-form-item-control-wrapper) {
                      width: 90%;
                      :global(.ant-input) {
                        height: 100px;
                        resize: none;
                      }
                    }
                  }
                }
              `}
            </style>
          </div>
        );
      }
    }
  );
}

export const CertificateEditForm = createCertificateEditForm(
  mapPropsToFields,
  fieldsToProps
);

export const newCertificateEditForm = createCertificateEditForm(
  mapPropsToFields,
  fieldsToProps,
  onCertFieldsChange
);

export function CertificateView(props) {
  let { item } = props;
  item = _.mapObject(item, (val, key) => {
    return val && val.value;
  });
  return (
    <div className="certificate-view">
      <Row gutter={10}>
        <Col span={4}>
          {item.date && item.date != "0000-00-00"
            ? moment(item.date).format("YYYY/MM")
            : ""}
        </Col>
        <Col span={18} className="certificate-name">
          <div style={{ fontWeight: "bolder" }}>{item.name}</div>
          <pre style={{ marginTop: 10 }}>{item.desc}</pre>
        </Col>
      </Row>
      <style jsx>
        {`
          .certificate-view {
            :global(.ant-row) {
              color: #666;
            }
            :global(.certificate-name) {
              color: #333;
            }
          }
        `}
      </style>
    </div>
  );
}

class Certificate extends PureComponent {
  state = {
    topPlaceholderBg: "#ffffff",
    bottomPlaceholderBg: "#ffffff"
  };

  setPlaceholderColor = (index, color) => {
    const { certificates } = this.props;
    index === certificates.length - 1
      ? this.setState({ bottomPlaceholderBg: color })
      : !1;
    index === 0 ? this.setState({ topPlaceholderBg: color }) : !1;
  };

  formRef = [];

  onEdit = index => {
    this.setPlaceholderColor(index, "#f0f0f0");
    this.props.updateCertificate({ index, values: { isEdit: true } });
  };

  onCancel = index => {
    this.setPlaceholderColor(index, "#ffffff");
    this.props.cancelCertificate(index);
  };

  onSave = index => {
    const { saveCertificate } = this.props;
    this.formRef[index].handleSubmit().then(values => {
      saveCertificate(index, values)
        .then(res => {
          if (res.code === 0) this.setPlaceholderColor(index, "#ffffff");
          return res;
        })
        .then(res => (!res.code ? res.data.compete : null))
        .then(this.props.toUpdateComplete);
    });
  };

  onDelete = index => {
    this.props
      .removeCertificate(index)
      .then(res => (!res.code ? res.data.compete : null))
      .then(this.props.toUpdateComplete);
  };

  onAdd = () => {
    const { certificates } = this.props;
    if (!certificates.length) {
      this.setPlaceholderColor(-1, "#f0f0f0");
      this.setPlaceholderColor(0, "#f0f0f0");
    } else {
      this.setState({ bottomPlaceholderBg: "#f0f0f0" });
    }
    this.props.addCertificate({ limit: true });
  };

  render() {
    const { topPlaceholderBg, bottomPlaceholderBg } = this.state;
    const { certificates } = this.props;
    return (
      <EditViewMulti className="certificate" title="证书" onAdd={this.onAdd}>
        <div className="placeholder" style={{ background: topPlaceholderBg }} />
        {certificates.map((item, index) => (
          <EditViewSingle
            isView={!item.isEdit}
            className={`${index === certificates.length - 1 ? "last" : ""}`}
            onEdit={() => this.onEdit(index)}
            onCancel={() => this.onCancel(index)}
            onSave={() => this.onSave(index)}
            onDelete={() => this.onDelete(index)}
            key={item.cert_id}
          >
            <div role="view" className="view">
              <CertificateView item={item} />
            </div>
            <div role="edit" className="edit">
              <CertificateEditForm
                item={item}
                wrappedComponentRef={inst => (this.formRef[index] = inst)}
              />
            </div>
          </EditViewSingle>
        ))}
        <div
          className="placeholder"
          style={{ background: bottomPlaceholderBg }}
        />
        <style jsx>
          {`
            .placeholder {
              height: 30px;
            }
          `}
        </style>
      </EditViewMulti>
    );
  }
}

export default updateCompletewrapper(Certificate);
