import css from 'styled-jsx/css';

export default css.global`/* @styled-jsx=global */
.company-detail-drawer{
  .company-detail-title {
    .item {
      display: inline-block;
      height: 20px;
      vertical-align: top;
      line-height: 18px;
    }
  }
  .second-header{
    position: relative;
    margin:-20px -20px 18px;
    background-color: #f9f9f9;
    height: 130px;
    padding:  20px;
    h2 {
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 14px;
      span{
        font-size: 14px;
        color: #666;
      }
    }
    .tags{
      color: #666;
      margin-bottom: 14px;
    }
    .ant-select{
      position: absolute;
      right: 24px;
      top: 49px;
    }

    .detail-icon {
      margin-left: 10px;
      margin-bottom: 2px;
    }
  }

  .company-base-info{
    .logo {
      width: 60px;
      height: 60px;
      border: solid 1px #dadada;
    }
    .ant-row{
      color:#999;
      margin-bottom: 16px;
      span,p{
        color:#666;
      }
      .logo-uploader span {
        color: #fff;
      }
    }
    .now-city-row {
      margin-top: 30px;
      margin-bottom: 10px;
    }
    .address-row {
      line-height: 1.5;
    }
    .des-row {
      line-height: 1.5;
    }
    .edit .ant-row{ 
      margin-bottom: 10px;
    }
  }    

  .edit-company-form {
    .company-intro{
      .ant-form-item-label{
        width: 10%;
      }
      .ant-form-item-control-wrapper{
        width: 90%;
      }
    }
  }
  
  .add-contacter-modal {
    .ant-modal-body {
      padding: 40px 38px 20px;
    }
  }
  .add-contract-modal {
    .ant-modal-body {
      padding: 40px 38px 20px;
      .add-business-item.remark{
        .ant-form-item-label{
          width: 10%;
        }
        .ant-form-item-control-wrapper{
          width: 90%;
        }
      }
    }
  }
  .company-detail-tabs {
    .now-status {
      margin-left: 10px;
      background-color: #f0f0f0;
      border-radius: 3px;
      border: solid 1px #999999;
      color: #999;
      font-size: 12px;
      padding: 1px 8px;
      line-height: 1;
    }
  }
}

.company-detail {
  background: #ffffff;;
  .company-detail-table {
    .base-area {
      .custome-type{
        width: 200px;
      }
    }
    .base-area .now-custome {
      margin-left: 15px;
      .text {
        display: inline-block;
        vertical-align: middle;
      }
      .dot {
        margin-right: 5px;
        display: inline-block;
        vertical-align: middle;
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background-color: #333333;
      }
      .special-dot {
        background-color: #FF9966;
      }
    }
  }
  
}

.add-contract-modal {
  position: absolute;
}

.add-record-modal {
  position: absolute;
}

.add-linkman-modal {
  position: absolute;
}

.add-contract-form {
  .single-col {
    .ant-col-3 {
      width: 10%;
    }
    .ant-col-21 {
      width: 90%;
    }
  }
}

.repeat-company-form {
  width: 376px;
}

.add-contract-popup {
  
  width: 320px;
  .ant-popover-inner-content {
    padding: 0 10px 10px;
    max-height: 400px;
    overflow-y: auto; 
    overflow-x: hidden;
  }
  .ant-popover-title {
    padding: 0 0 0 14px;
    height: 40px;
    line-height: 40px;
    .anticon {
      margin-right: 5px;
      vertical-align: -0.225em;
      font-size: 16px;
      cursor: pointer;
    }
  }
  .contract-item {
    padding: 0 10px 10px;
    margin-top: 10px;
    background-color: #f5f5f5;
    .tags {
      margin-right: -10px;
      .right {
        width: 63px;
	      height: 22px;
        color: #fff;
        font-size: 12px;
        line-height: 22px;
        text-align: center;
      }
      .type {
        background-color: #fa9965;
      }
      .new {
        background-color: #999999;
      }
    }
    .item-info {
      margin-top: 8px;
      & > div {
        display: inline-block;
        vertical-align: top;
        color: #666666;
      }
      .label {
        width: 70px;
        text-align: right;
      }
      .content {
        margin-left: 5px;
        max-width: 170px;
        word-break: break-all;
      }
    }
  }
}`