import Model from "../Model";
import * as articleManageS from "services/articleManageS";
export default Model.getInstance(
  class extends Model {
    namespace = "ArticleManageModal";
    state = {
      visibleArticle: false,
      visibleBulletins: false,
      dataList: {
        loadingStatus: false //加载状态
      },
      bulletinsList: {
        loadingStatus: false //加载状态
      },
      setArticleData: {},
      getDetailAritcleData: {},
      articletitle: {},
      articleBusinessTitle: {},
      statusParam: { "per-page": 10 },
      businessSearch: { "per-page": 10 },
    };

    actions = {
      async getList() {
        this.dispatch({
          type: "ArticleManageModal/setUpdateState",
          payload: { filed: "dataList", value: { loadingStatus: true } }
        });
        const expand = "type_name,source_name,update_time_str,online_time_str,status_name,push_site_name";
        let result = await articleManageS.articleManageList({
          ...this.getState().ArticleManageModal.statusParam,
          expand
        });
        if (result) {
          this.dispatch({
            type: "ArticleManageModal/setUpdateState",
            payload: {
              filed: "dataList",
              value: { ...result.data, loadingStatus: false }
            }
          });
        }
      },
      async getBusinessList() {
        this.dispatch({
          type: "ArticleManageModal/setUpdateState",
          payload: { filed: "bulletinsList", value: { loadingStatus: true } }
        });
        const expand = "create_time_str,industry_name,category_name,type_name,reviews";
        let result = await articleManageS.bulletinsList({
          ...this.getState().ArticleManageModal.businessSearch,
          expand
        });
        if (result) {
          this.dispatch({
            type: "ArticleManageModal/setUpdateState",
            payload: {
              filed: "bulletinsList",
              value: { ...result.data, loadingStatus: false }
            }
          });
        }
      },
      async articleAdd(param) {
        let result = await articleManageS.articleManageAdd({ ...param });
        if (result.code === 0) {
          this.dispatch({
            type: "ArticleManageModal/drawerOpen",
            payload: { type: "添加", page: "article" }
          });
        }
        return result;
      },
      async bulletinsAdd(param) {
        let result = await articleManageS.bulletinsAdd({ ...param });
        if (result.code === 0) {
          this.dispatch({
            type: "ArticleManageModal/drawerOpenBusiness",
            payload: { type: "添加", page: "article" }
          });
        }
        return result;
      },
      async articleEdit(param) {
        let result = await articleManageS.articleManageEdit({ ...param });
        if (result.code === 0) {
          this.dispatch({ type: "ArticleManageModal/drawerOpen" });
        }
        return result;
      },
      async bulletinsEdit(param) {
        let result = await articleManageS.bulletinsEdit({ ...param });
        if (result.code === 0) {
          this.dispatch({ type: "ArticleManageModal/drawerOpenBusiness" });
        }
        return result;
      },
      async getDetail(id) {
        let result = await articleManageS.articleManageDetail(id);
        this.dispatch({
          type: "ArticleManageModal/updateDetail",
          payload: result.data
        });
        return result;
      },
      async addState(param) {
        this.dispatch({
          type: "ArticleManageModal/setUpdateState",
          payload: param
        });
      },
      async articleDelete(id) {
        let result = await articleManageS.articleManageDelete(id);
        return result;
      },
      async bulletinsDel(id) {
        let result = await articleManageS.bulletinsDel(id);
        return result;
      },
      async articleRecommend(param = {}) {
        let result = await articleManageS.articleManageRecommend({ ...param });
        return result;
      }
    };

    reducers = {
      updateDetail(state, { payload: data }) {
        state.getDetailAritcleData = data;
        state.setArticleData.cover = (data||{}).cover; //详情获取的封面赋值redux，编辑提交时用
        return { ...state };
      },
      drawerOpen(state, { payload: type }) {
        state.visibleArticle = !state.visibleArticle;
        state.setArticleData = {};
        state.getDetailAritcleData = {};
        return { ...state };
      },
      drawerOpenBusiness(state, { payload: type }) {
        state.visibleBulletins = !state.visibleBulletins;
        return { ...state };
      },
      setUpdateState(state, { payload }) {
        const { filed, value } = payload;
        state[filed] = value;
        return { ...state };
      }
    };
  }
);
