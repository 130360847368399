import React, { PureComponent } from "react";
import {
  Tabs,
  Select,
  Input,
  Row,
  Col,
  Button,
  Tag,
  Drawer,
  Form,
  Card,
  Icon,
  Divider,
  DatePicker,
  List,
  Avatar,
  Tooltip,
  Collapse,
  Carousel,
  TreeSelect,
  Checkbox
} from "antd";

import CustomIcon from "../../components/CustomIcon";
import DetailDialog from "./detail-dialog/detailDialog";

import * as positionS from "services/positionS";
import {transformTree} from "utils/util";
import { omit } from "underscore";

const TabPane = Tabs.TabPane;
const Option = Select.Option;
const FormItem = Form.Item;
const TextArea = Input.TextArea;
const InputGroup = Input.Group;
const Panel = Collapse.Panel;
const { RangePicker } = DatePicker;

/**
 * 职位详情状态显示
 */
export default class DetailPositionStatus extends PureComponent {
  statusCarouselRef = React.createRef();

  state = {
    profileNum: {},
    profileParams: {},
    dialogVisible: false,
    dialogType: -1,
  }

  componentDidMount() {
    const {positionDetail} = this.props;
    if (positionDetail) {
      this.getProfileNum({jd_id: positionDetail.id});
    }
  }

  componentDidUpdate(prevProps) {
    const {profileNumUpdate, positionDetail, setUpdateState} = this.props;
    if (prevProps.profileNumUpdate !== profileNumUpdate && profileNumUpdate) {
      this.getProfileNum({jd_id: positionDetail.id});
      setUpdateState({
        filed: "profileNumUpdate",
        value: false
      })
    }
  }

  getProfileNum = (params) => {
    const {profileParams} = this.state;
    let param = {...profileParams, ...params};
    this.setState({
      profileParams: param
    })
    positionS.positionProfileNum({
      ...param,
    }).then(res => {
      if (res.code === 0) {
        this.setState({
          profileNum: res.data
        })
      }
    })
  }

  switchLeft = () => {
    let currentRef = this.statusCarouselRef.current
    currentRef && currentRef.prev()
  }
  switchRight = () => {
    let currentRef = this.statusCarouselRef.current
    currentRef &&  currentRef.next()
  }
  showDialog = (type, num) => {
    if (num <= 0) return;
    this.setState({
      dialogVisible: true,
      dialogType: type
    })
  }
  maxTagShow = (omitValues) => {
    return `已选择${omitValues.length}位顾问`
  }
  hhChange = (value) => {
    this.getProfileNum({user_id: value})
  }
  timeChange = (value) => {
    if (!value || value.length === 0) {
      this.getProfileNum({start_time: undefined, end_time: undefined})
    } else {
      this.getProfileNum({start_time: value[0].format("YYYY-MM-DD"), end_time: value[1].format("YYYY-MM-DD")})
    }
  }
  onDialogClose = () => {
    this.setState({
      dialogVisible: false,
    })
  }
  render() {
    const { positionDetail = {}, detailWidthStatus, commonData } = this.props;
    const { profileNum, profileParams, dialogVisible, dialogType } = this.state;
    return (
      <React.Fragment>
        <div className="detail-position-status">
          {positionDetail.start_time_str && (
            <div className="create line-row">
              此职位创建：{positionDetail.start_time_str}，已进行
              {positionDetail.progress_days}天
            </div>
          )}
          <Row className="line-row" type="flex" align="middle">
            <Col span={5} xxl={{ span: 3 }}>选择时间</Col>
            <Col span={18} xxl={{ span: 20 }}><RangePicker onChange={this.timeChange}/></Col>
          </Row>
          <Row className="line-row" type="flex" align="middle">
            <Col span={5} xxl={{ span: 3 }}>选择顾问</Col>
            <Col span={18} xxl={{ span: 20 }}>
              <Select placeholder="请选择顾问" maxTagCount={0} maxTagPlaceholder={this.maxTagShow} mode="multiple" onChange={this.hhChange}>
                  {positionDetail.jd_rmd_hh &&
                    positionDetail.jd_rmd_hh.map(item => {
                      return (
                        <Option key={item.id} value={item.id}>
                          {item.name}
                        </Option>
                      );
                    })}
              </Select>
            </Col>
          </Row>
          <div className={`item-box clear ${!detailWidthStatus && "small-width"}`}>
            <div className="left switch-btn" onClick={this.switchLeft}><CustomIcon type="icon-left"/></div>
            <Carousel slidesToShow={4} dots={false} variableWidth infinite={false}
              ref={this.statusCarouselRef}  className="status-carousel">
              <div className="item" onClick={() => this.showDialog(-1, profileNum.screened_num)} style={{ width: 80 }}>
                <div className="number">{profileNum.screened_num}</div>
                <div className="name">推荐给前台</div>
              </div>
              <div className="item" onClick={() => this.showDialog(1, profileNum.recommended_num)} style={{ width: 80 }}>
                <div className="number">{profileNum.recommended_num}</div>
                <div className="name">推荐给客户</div>
              </div>
              <div className="item" onClick={() => this.showDialog(2, profileNum.arrange_interview_num)} style={{ width: 80 }}>
                <div className="number">{profileNum.arrange_interview_num}</div>
                <div className="name">安排面试</div>
              </div>
              <div className="item" onClick={() => this.showDialog(3, profileNum.interview_through_num)} style={{ width: 80 }}>
                <div className="number">{profileNum.interview_through_num}</div>
                <div className="name">面试通过</div>
              </div>
              <div className="item" onClick={() => this.showDialog(4, profileNum.offer_coordinate_num)} style={{ width: 80 }}>
                <div className="number">{profileNum.offer_coordinate_num}</div>
                <div className="name">offer协调</div>
              </div>
              <div className="item" onClick={() => this.showDialog(5, profileNum.onboard_num)} style={{ width: 80 }}>
                <div className="number">{profileNum.onboard_num}</div>
                <div className="name">onboard</div>
              </div>
              <div className="item" onClick={() => this.showDialog(6, profileNum.obsolete_num)} style={{ width: 80 }}>
                <div className="number">{profileNum.obsolete_num}</div>
                <div className="name">已淘汰</div>
              </div>
            </Carousel>
            <div className="left switch-btn" onClick={this.switchRight}><CustomIcon type="icon-right"/></div>
          </div>
        </div>
        <DetailDialog profileParams={profileParams} visible={dialogVisible} dialogType={dialogType} onDialogClose={this.onDialogClose}/>
        <style jsx global>
          {`
            .detail-position-status {
              padding: 0 0 20px;
              .create {
                margin-bottom: 20px;
              }
              .small-width {
                width: 270px;
                margin: 0 auto;
              }
              
              .line-row {
                margin-bottom: 10px;
                padding-left: 20px;
                .ant-select, .ant-calendar-picker {
                  width: 100%;
                }
              }
              .item-box {
                display: flex;
                align-items: center;
                justify-content: center;
                .switch-btn {
                  width: 27px;
                  height: 56px;
                  line-height: 56px;
                  text-align: center;
                  cursor: pointer;
                }
                .ant-carousel {
                  width: 324px;
                  .slick-initialized .slick-slide {
                    padding-left: 4px;
                    padding-right: 4px;
                  }
                }
                .item {
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  width: 80px;
                  padding: 3px 0 10px;
                  margin: 10px 4px;
                  text-align: center;
                  background-color: #e7f4ff;
                  border-radius: 4px;
                  font-size: 12px;
                  color: #999999;
                  .number {
                    font-size: 16px;
                    line-height: 30px;
                    font-weight: 600;
                    color: #1890ff;
                  }
                }
              }
            }
          `}
        </style>
      </React.Fragment>
    );
  }
}
