import css from 'styled-jsx/css';

export default css.global`.sms-input {
    position: relative;

    .get-code {
        position: absolute;
        right: 12px;
        top: 50%;
        transform: translateY(-50%);
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        color: #5d61e5;
        cursor: pointer;
    }
}`