import xhr from "./index.js";

/**
 * 用户管理
 */
//用户列表
function userManageList(param) {
  return xhr({
    url: "admin/v1/user-manages",
    body: param
  });
}
//用户详情
function userManageDetail(id) {
  return xhr({
    url: "admin/v1/user-manage/info",
    body: id
  });
}
//统计状态人数
function userStatistics(id) {
  return xhr({
    url: "admin/v1/user-manage/list-count",
    body: id
  });
}
//认证审核
function userAttestation(param) {
  return xhr({
    url: "admin/v1/user-manage/check-status",
    body: param,
    method: "POST"
  });
}
//编辑认证用户公司信息
function userEditCompany(param) {
  return xhr({
    url: "admin/v1/user-manage/edit-company",
    body: param,
    method: "POST"
  });
}
export { userManageList, userManageDetail, userStatistics, userAttestation, userEditCompany };
