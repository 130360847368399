import css from 'styled-jsx/css';

export default css.global`.position-manage {
  height: 100%;
  background: #ffffff;
 
  .position-table {
  }
}

.position-list {
  .ant-form-inline .ant-form-item {
    margin-right: 10px;
  }
}`