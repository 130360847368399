import xhr from "./index.js";

/**
 * 职位管理
 */
//职位列表
function positionList(param) {
  return xhr({
    url: "admin/v1/jd/list",
    body: param,
    method: "POST",
  });
}
//职位进展人选数据
function positionProfileNum(param) {
  return xhr({
    url: "admin/v1/jd/jd-profile-num",
    body: param,
    method: "get",
  });
}
//职位进展数据
function positionProfileData(param) {
  return xhr({
    url: "admin/v1/jd/jd-profile-data",
    body: param,
    method: "get",
  });
}
//候选人列表
function candidateList(param) {
  return xhr({
    url: "admin/v1/jd-profiles",
    body: param
  });
}
//候选人进展
function candidateProgress(param) {
  return xhr({
    url: "admin/v1/jd-profile/logs",
    body: param
  });
}
//候选人操作
function candidateStatusManage(param) {
  return xhr({
    url: "admin/v1/jd-profile/operate",
    method: "POST",
    body: param
  });
}
//职位分配
function positionAllotManage(param) {
  return xhr({
    url: "admin/v1/jd/assign",
    method: "POST",
    body: param
  });
}
//职位进展
function positionProgressManage(param) {
  return xhr({
    url: "admin/v1/jd/logs",
    body: param
  });
}

//职位详情
function positionDetail(param) {
  return xhr({
    url: "admin/v1/jds/" + param.id,
    body: param
  });
}
//职位新增
function positionManageAdd(param) {
  return xhr({
    url: "admin/v1/jds",
    body: param,
    method: "POST"
  });
}
//职位状态修改
function positionManageRevamp(param) {
  return xhr({
    url: "admin/v1/jd/operate",
    body: param,
    method: "POST"
  });
}

function modJds(param) {
  return xhr({
    url: "admin/v1/jds/" + param.id,
    body: param,
    method: "PUT"
  });
}

function getChatList(param) {
  return xhr({
    url: "admin/v1/jd/chat-list",
    body: param
  });
}

function addChats(param) {
  return xhr({
    url: "admin/v1/jd/chat-add",
    body: param,
    method: "POST"
  });
}

function changeManager(param) {
  return xhr({
    url: "admin/v1/jd/change-manager",
    body: param,
    method: "POST"
  });
}
//推荐人选页面职位详情
function recommendDetail(param) {
  return xhr({
    url: "admin/v1/jd/info",
    body: param,
    method: "GET"
  });
}
//推荐人选列表
function recommendList(param) {
  return xhr({
    url: "admin/v1/jd/profile-list",
    body: param,
    method: "POST"
  });
}
//推荐人选详情
function recommendProfileDetail(param) {
  return xhr({
    url: "admin/v1/jd/profile-info",
    body: param,
    method: "POST"
  });
}
//标记不合适
function markProfileSuit(param) {
  return xhr({
    url: "admin/v1/jd-profile/mark-suitable",
    body: param,
    method: "POST"
  });
}
//获得业绩
function getPerformance(param) {
  return xhr({
    url: "admin/v1/jd-profile/get-performance",
    body: param,
    method: "GET"
  });
}
//收藏、取消收藏 类型 1.收藏 2.取消收藏
function isCollect(param) {
  return xhr({
    url: "admin/v1/jd/collect",
    body: param,
    method: "POST"
  });
}
//可汗发布职位编辑
function khEdit(param) {
  return xhr({
    url: "admin/v1/jd/edit-to-ch",
    body: param,
    method: "POST"
  });
}
//停止发布到可汗
function khStopPublish(param) {
  return xhr({
    url: "admin/v1/jd/stop-publish-ch",
    body: param,
    method: "POST"
  });
}
//可汗发布职位新增（重新发布只需传jd_id）
function khAdd(param) {
  return xhr({
    url: "admin/v1/jd/publish-to-ch",
    body: param,
    method: "POST"
  });
}
//优职发布职位编辑
function optimalEdit(param) {
  return xhr({
    url: "admin/v1/jd/edit-to-gbox",
    body: param,
    method: "POST"
  });
}
//优职发布职位新增（重新发布只需传jd_id）
function optimalAdd(param) {
  return xhr({
    url: "admin/v1/jd/publish-to-gbox",
    body: param,
    method: "POST"
  });
}
//优职简历标记合适不合适
function isOptimalProfile(param) {
  return xhr({
    url: "admin/v1/jd/set-gbox-profile-status",
    body: param,
    method: "POST"
  });
}
//即派职位同步到优职
function isSynchronization(param) {
  return xhr({
    url: "admin/v1/jd-gbox/set-publish-status",
    body: param,
    method: "POST"
  });
}
//即派职位同步到优职停止发布
function stopPublish(param) {
  return xhr({
    url: "admin/v1/jd/stop-publish",
    body: param,
    method: "POST"
  });
}
//优职人选列表
function optimalList(param) {
  return xhr({
    url: "admin/v1/jd/gbox-profile-list",
    body: param,
    method: "GET"
  });
}
//可汗人选列表
function khList(param) {
  return xhr({
    url: "admin/v1/jd/ch-profile-list",
    body: param,
    method: "GET"
  });
}
//优职人选详情
function optimalDetail(param) {
  return xhr({
    url: "admin/v1/jd/gbox-profile-info",
    body: param,
    method: "POST"
  });
}
//可汗人选详情
function khDetail(param) {
  return xhr({
    url: "admin/v1/jd/ch-profile-info",
    body: param,
    method: "POST"
  });
}
//修改候选人进展事件时间
function modifyEvent(param) {
  return xhr({
    url: "admin/v1/jd-profile/modify-event-time",
    body: param,
    method: "POST"
  });
}
//标记系统推荐人选不匹配
function mismatching(param) {
  return xhr({
    url: "admin/v1/jd/set-sys-recommend-mismatch",
    body: param,
    method: "POST"
  });
}
//下载官网生成的简历报告
// function downloadReportFile(param) {
//   return xhr({
//     url: "admin/v1/profile-manage/download-jd",
//     body: param,
//     method: "GET"
//   });
// }
export {
  changeManager,
  getChatList,
  addChats,
  modJds,
  positionList,
  positionAllotManage,
  positionProgressManage,
  positionDetail,
  positionManageAdd,
  positionManageRevamp,
  candidateList,
  candidateProgress,
  candidateStatusManage,
  recommendDetail,
  recommendList,
  recommendProfileDetail,
  getPerformance,
  isCollect,
  optimalEdit,
  isSynchronization,
  isOptimalProfile,
  stopPublish,
  optimalList,
  khList,
  optimalDetail,
  optimalAdd,
  markProfileSuit,
  modifyEvent,
  mismatching,
  khDetail,
  khAdd,
  khEdit,
  khStopPublish,
  positionProfileNum,
  positionProfileData
};
