import React, { PureComponent } from "react";
import { Collapse, Upload, Row, Col, Empty, Message } from "antd";
import PreviewTool from "components/PreviewTool";
import CustomIcon from "components/CustomIcon";
import fileDownload from "utils/fileDownload.js";

const Dragger = Upload.Dragger;
const Panel = Collapse.Panel;

export default class OriginResume extends PureComponent {

  state = {
    currentResume: "",
  }
  componentDidMount() {
    const { resumeAttachment } = this.props;
    this.setState({
      currentResume: resumeAttachment[0] ? resumeAttachment[0].patch : ""
    })
  }
  componentDidUpdate(prevProps) {
    const { resumeAttachment } = this.props;
    if (resumeAttachment != prevProps.resumeAttachment) {
      this.setState({
        currentResume: resumeAttachment[0] ? resumeAttachment[0].patch : ""
      })
    }
  }

  changeResume = (src) => {
    this.setState({
      currentResume: src
    })
  }

  downResume = (resume) => {
    const {candidateId} = this.props;
    fileDownload(
      `admin/v1/profile-manage/download?&profile_id=${candidateId}&resume_id=${resume.resume_id}`
    ).then(res => {
      console.log(res, "ddd")
    }).catch(res => {
      let startIndex = res.indexOf('{"code"');
      let endIndex = res.indexOf('"}') + 2;
      if (startIndex == -1 || endIndex == -1) {
        Message.error("异常错误发生");
        // hide();
        this.setState({ nowDownload: false });
        return;
      }
      let resObj = JSON.parse(res.substring(startIndex, endIndex));
      Message.error(resObj.msg);
      // hide();
      // this.setState({ nowDownload: false });
    });
  }

  render() {
    const { resumeAttachment } = this.props;
    const { currentResume } = this.state;
    return (
      <React.Fragment>
        <div className="origin-resume">
          <Row className="resume-list-row" justify="space-between" type="flex">
            {
              resumeAttachment && resumeAttachment.map((item,index) => {
                return <Col className="resume-item" span={12} key={index} onClick={() => this.changeResume(item.patch)}>
                  <CustomIcon className="attachment-icon" type="icon-attachment"/>
                  <span className="name ellipsis">{item.name}</span>
                  <span className="time">{item.created_time}</span>
                  <span className="updater ellipsis">{item.created_by}</span>更新
                  <a target="_blank" onClick={() => this.downResume(item)}><CustomIcon className="down-icon" type="icon-download"/></a>
                </Col>
              })
            }
          </Row>
          {
            currentResume ? <PreviewTool iframeSrc={currentResume} className="origin-preview"/>
            : <Empty description="暂无附件" className="origin-empty"/>
          }
        </div>
        <style jsx global>{`
          .origin-resume {
            .resume-list-row {
              .resume-item {
                display: flex;
                align-items: center;
                justify-content: start;
                padding: 0 10px;
                margin-bottom: 4px;
                width: 419px;
                height: 39px;
                background-color: #fdf4ea;
                color: #666666;
                font-size: 14px;
                .name {
                  margin-left: 5px;
                  margin-right: 6px;
                  max-width: 130px;
                }
                .time {
                  margin-right: 5px;
                  width: 120px;
                }
                .updater {
                  max-width: 60px;
                }
                .attachment-icon {
                  color: #999999;
                  font-size: 18px;
                }
                .down-icon {
                  margin-left: 6px;
                  font-size: 18px;
                  color: #1890ff;
                  cursor: pointer;
                }
              }
            }
            .origin-preview {
              margin-top: 10px;
              height: 800px !important;
            }
            .origin-empty {
              padding-top: 100px;
              height: 600px;
              background-color: #f8fbff;
              color: #999999;
            }
          }
        `}</style>
      </React.Fragment>
    );
  }
}
