import React, { PureComponent } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import {
  Input,
  Row,
  Col,
  Select,
  message,
  Form,
  Button,
  Upload,
  Icon
} from "antd";
import { Modal } from "antd";
import PositionVacantModal from "../../../store/reducers/positionVacantM";
import ReactQuill from "react-quill";

/**
 * 简历邮件发送
 */
export default withRouter(
  connect(
    ({ PositionVacantModal, user }) => ({
      emailDialogStatus: PositionVacantModal.recommendInfo.emailDialogStatus,
      recommendParam: PositionVacantModal.recommendInfo.recommendParam,
      selectedCandIds: PositionVacantModal.recommendInfo.selectedCandIds,
      recommendInfo: PositionVacantModal.recommendInfo,
      user
    }),
    { ...PositionVacantModal.actions }
  )(
    class RecommendEmailSend extends PureComponent {
      emailFormRef = React.createRef();

      state = {
        text: "",
        title: {
          validateStatus: "",
          help: "",
          value: ""
        },
        emails: {
          validateStatus: "",
          help: "",
          value: []
        },
        ccemails: {
          validateStatus: "",
          help: "",
          value: []
        },
        sendClick: false,
      };

      modules = {
        toolbar: [
          [{ header: [1, 2, false] }],
          ["bold", "italic", "underline", "strike", "blockquote"],
          [
            { list: "ordered" },
            { list: "bullet" },
            { indent: "-1" },
            { indent: "+1" }
          ],
          ["link", "image"],
          ["clean"]
        ]
      };

      formats = [
        "header",
        "bold",
        "italic",
        "underline",
        "strike",
        "blockquote",
        "list",
        "bullet",
        "indent",
        "link",
        "image"
      ];
      componentDidMount() {
        this.setState({
          emails: {
            validateStatus: "",
            help: "",
            value: (this.props.recommendParam || {}).emails
          }
        });
      }

      componentDidUpdate(prevProps) {
        // if (prevProps.recommandPos.emails !== this.props.recommandPos.emails) {
        //   console.log("ssss", this.props);
        //   this.setState({
        //     emails: {
        //       validateStatus: "",
        //       help: "",
        //       value: this.props.recommandPos.emails
        //     }
        //   });
        // }
      }

      handleChange = value => {
        this.setState({ text: value });
      };

      onTitleChange = value => {
        this.setState({ title: value });
      };

      onOk = () => {
        const validateResult = this.emailFormRef.current.submitValidate();
        const { text, title, emails, ccemails } = this.state;
        const { sendCallBack } = this.props;
        // if (!validateResult) {
        //   return;
        // }
        if (!title.value|!emails.value) {
          return;
        }
        this.setState({
          sendClick: true
        })
        //isTypeStatus是单独推荐状态
        const { recommendParam = {} ,isTypeStatus=false,searchJdDetail} = this.props;
        const { jd_ids, profile_ids, report ,event_time} = recommendParam;
        let param = {
          title: title.value,
          emails: emails.value,
          cc_emails: ccemails.value,
          content: text,
          files: this.files,
          jd_ids,
          profile_ids,
          event_time,
          report: report.map(item => item.patch)
        };
        // report.type = report.type > 3 ? 3 : report.type; // 上传的unix值要转为3
        console.log(param, "推荐参数");

        this.props.recommandToPosAndEmail(param).then(res => {
          if (res.code === 0) {
            const { recommendInfo } = this.props;
            this.props.setUpdateState({
              filed: "recommendInfo",
              value: isTypeStatus?{
                active: 1,
                ...recommendInfo,
                emailDialogStatus: false, //关闭推荐弹窗
              }:{
                ...recommendInfo,
                active: isTypeStatus?0:3,
                emailDialogStatus: false, //关闭推荐弹窗
                recommendSuccess: { ...res.data } //推荐成功在步骤三显示的信息
              }
            });
            if (sendCallBack) {
              sendCallBack();
            }
            if (searchJdDetail) {
              searchJdDetail();
            }
          } else {
            message.error(res.msg);
          }
          this.setState({
            sendClick: false
          })
        });
      };

      onSomeThing = () => {
        const { text, title, emails } = this.state;
        //isTypeStatus是单独推荐状态
        const { recommendParam = {}, sendCallBack,searchJdDetail,isTypeStatus=false } = this.props;
        const { jd_ids, profile_ids, report,event_time } = recommendParam;
        let param = {
          title: title.value,
          // emails: emails.value,
          content: text,
          files: this.files,
          jd_ids,
          profile_ids,
          event_time,
          report: report.map(item => item.patch)
        };
        // report.type = report.type > 3 ? 3 : report.type; // 上传的unix值要转为3
        this.setState({
          sendClick: true
        })
        this.props.recommandToPos(param).then(res => {
          if (res.code === 0) {
            const { recommendInfo } = this.props;
            this.props.setUpdateState({
              filed: "recommendInfo",
              value: isTypeStatus?{
                active: 1,
                ...recommendInfo,
                emailDialogStatus: false, //关闭推荐弹窗
              }:{
                ...recommendInfo,
                active: 3,
                emailDialogStatus: false, //关闭推荐弹窗
                recommendSuccess: { ...res.data } //推荐成功在步骤三显示的信息
              }
            });
            if (sendCallBack) {
              sendCallBack();
            }
            if (searchJdDetail) {
              searchJdDetail();
            }
          } else {
            message.error(res.msg);
          }
          this.setState({
            sendClick: false
          })
        });
      };

      onCancel = (e) => {
        const { setUpdateState, recommendInfo } = this.props;
        setUpdateState({
          filed: "recommendInfo",
          value: { ...recommendInfo, emailDialogStatus: false }
        });
        this.setState({
          text: "",
          title: {
            validateStatus: "",
            help: "",
            value: ""
          }
        });
      };

      onEmailChange = value => {
        let emailReg = /([a-z0-9]*[-_.]?[a-z0-9]+)*@([a-z0-9]*[-_]?[a-z0-9]+)+[.][a-z]{2,3}([.][a-z]{2})?/g;
        if(value.value.length != 0 && !emailReg.test(value.value[value.value.length-1])) {
          message.error("输入的邮箱格式有误");
          return;
        }
        this.setState(
          {
            emails: value
          },
          () => {
            console.log(this, this.state.emails, value);
          }
        );
      };

      onCCEmailChange = value => {
        let emailReg = /([a-z0-9]*[-_.]?[a-z0-9]+)*@([a-z0-9]*[-_]?[a-z0-9]+)+[.][a-z]{2,3}([.][a-z]{2})?/g;
        if(value.value.length != 0 && !emailReg.test(value.value[value.value.length-1])) {
          message.error("输入的邮箱格式有误");
          return;
        }
        this.setState(
          {
            ccemails: value
          },
          () => {
            console.log(this, this.state.ccemails, value);
          }
        );
      };

      onAffixChange = value => {
        this.files = value;
      };

      render() {
        const { title, emails, ccemails } = this.state;
        const {
          selectedCandIds,
          recommendParam = {},
          emailDialogStatus
        } = this.props;
        const { report } = recommendParam;

        return (
          <Modal
            title="推荐候选人---至客户"
            width="600px"
            className="recommend-email-send-modal"
            footer={
              <div>
                <Button disabled={this.state.sendClick} onClick={this.onSomeThing}>推荐到职位</Button>
                <Button disabled={this.state.sendClick} type="primary" onClick={this.onOk}>
                  推荐并发送邮件
                </Button>
              </div>
            }
            onCancel={this.onCancel}
            visible={emailDialogStatus}
          >
            <div className="header">
              <EmailForm
                ref={this.emailFormRef}
                emails={emails}
                ccemails={ccemails}
                title={title}
                report={report}
                onAffixChange={this.onAffixChange}
                onTitleChange={this.onTitleChange}
                onEmailChange={this.onEmailChange}
                onCCEmailChange={this.onCCEmailChange}
              />
            </div>
            <div className="main">
              <ReactQuill
                value={this.state.text}
                onChange={this.handleChange}
                modules={this.modules}
                formats={this.formats}
              />
            </div>
            <style jsx>{`
              :global(.recommend-email-send-modal) {
                :global(.ant-modal-body) {
                  .header {
                    :global(.ant-row) {
                      margin-bottom: 10px;
                    }
                  }
                  .main {
                    margin-top: 20px;
                    :global(.ql-editor) {
                      min-height: 300px;
                    }
                  }
                }
              }
            `}</style>
          </Modal>
        );
      }
    }
  )
);

const FormItem = Form.Item;
class EmailForm extends PureComponent {
  state = { fileList: [] };
  onEmailChange = value => {
    const { onEmailChange } = this.props;
    onEmailChange({
      // validateStatus: !value || !value.length ? "error" : "success",
      // help: !value || !value.length ? "请填写邮箱地址" : "",
      value
    });
  };

  onCCEmailChange = value => {
    const { onCCEmailChange } = this.props;
    onCCEmailChange({
      // validateStatus: !value || !value.length ? "error" : "success",
      // help: !value || !value.length ? "请填写邮箱地址" : "",
      value
    });
  };  

  onTitleChange = e => {
    const { onTitleChange } = this.props;
    const value = e.target ? e.target.value : e;
    onTitleChange({
      validateStatus: !value ? "error" : "success",
      help: !value ? "请填写邮件标题" : "",
      value
    });
  };

  submitValidate = () => {
    const { emails, title } = this.props;
    this.onEmailChange(emails.value);
    this.onTitleChange(title.value);
    return emails.value && emails.value.length && title.value;
  };

  uploadChange = info => {
    let { file, fileList } = info;
    if (file.status === "done") {
      if (file.response.code !== 0) {
        message.error(file.response.data.files);
        fileList = fileList.filter(item => item.uid !== file.uid);
      }
      const fileUrl = fileList.map(file => file.response.data.url[0]);
      this.setState({ fileUrl });
      this.props.onAffixChange(fileUrl);
    }
    this.setState({ fileList });
  };

  render() {
    const formItemLayout = {
      labelCol: { span: 3 },
      wrapperCol: { span: 21 }
    };
    const { emails, ccemails, title, report = [] } = this.props;
    console.log(this.props,'uuu')
    return (
      <Form className="edit-form" layout="horizontal">
        <FormItem
          label="收件人"
          {...formItemLayout}
          validateStatus={emails.validateStatus}
          help={emails.help}
        >
          <Select
            mode="tags"
            value={emails.value}
            placeholder="请输入收件人"
            style={{ width: "100%" }}
            onChange={this.onEmailChange}
            tokenSeparators={[","]}
          />
        </FormItem>
        <FormItem
          label="抄送人"
          {...formItemLayout}
          validateStatus={emails.validateStatus}
          help={emails.help}
        >
          <Select
            mode="tags"
            value={ccemails.value}
            placeholder="请输入抄送人"
            style={{ width: "100%" }}
            onChange={this.onCCEmailChange}
            tokenSeparators={[","]}
          />
        </FormItem>
        <FormItem
          label="邮件标题"
          {...formItemLayout}
          validateStatus={title.validateStatus}
          help={title.help}
        >
          <Input
            placeholder="请输入邮件标题"
            value={title.value}
            onChange={this.onTitleChange}
          />
        </FormItem>
        <FormItem label="附件" {...formItemLayout}>
          <Upload
            listType="text"
            action="admin/v1/common/upload-files"
            name="files"
            fileList={this.state.fileList}
            onChange={this.uploadChange}
            className={`upload-list-inline offer-uploader`}
          >
            <Button>
              <Icon type="upload" /> 上传附件
            </Button>
          </Upload>
        </FormItem>
        <FormItem label="所选报告" {...formItemLayout} className="report">
          {
            report && report.map((item,index) => {
              return (
                <span key={index} className="report-item ellipsis" title={item.text}>
                  <Icon type="paper-clip" style={{ color: "rgba(0, 0, 0, 0.45)" }} />
                  {item.text}
                </span>
              )
            })
          }
          
        </FormItem>
        <style jsx global>{`
          .offer-uploader {
            .ant-upload-list {
              display: inline-block;
              margin-top: 5px;
              max-width: 750px;
              vertical-align: top;
              .ant-upload-list-item {
                float: left;
                max-width: 200px;
                margin-left: 8px;
                line-height: 22px;
              }
              .anticon-close {
                right: 0;
              }
            }

            &.disabled {
              i.anticon-close {
                display: none;
              }
            }
          }
          .report {
            .report-item {
              margin-right: 10px;
              max-width: 200px;
              display: inline-block;
              line-height: 1;
            }
          }
        `}</style>
      </Form>
    );
  }
}
