import Model from "../Model";
import { convertChannelEdit ,convertSchoolEdit} from "../../pages/channel/fieldsConfig";
import * as channelS from "services/channelS";
import * as areaMappingS from "services/areaMappingService";
import * as businessService from "services/businessService";

export default Model.getInstance(
  class extends Model {
    namespace = "ChannelModel";
    state = {
      visibleMessage: false,
      statusParam: { "per-page": 10,page:1 },
      formParam: {}, //编辑表单参数
      dataList: {
        loadingStatus: false //加载状态
      },
      auditInfo: {
        loadingStatus: false //加载状态
      },
      detailInfo: {}, //渠道详情
      openDetailId: undefined, //打开详情的id
      formDrawer: false, // 渠道表单抽屉是否打开状态
      areaPositionList: {}, //区域职位列表
      areaMarkerList: [], //区域坐标点列表
      areaTalentList: [], //区域人选列表
      popMarkerList: [],
      areaCondition: {
        typeStatus: "1", //人选还是商机默认人选
      }, //查询参数
      schoolList: {
        loadingStatus: false //加载状态
      },
      schoolStatusParam: { "per-page": 10,page:1 },
      schoolFormParam: {}, //编辑表单参数
      schoolFormDrawer: false, // 渠道表单抽屉是否打开状态
      schoolDetailInfo: {}, //校园信息详情
      
    };

    actions = {
      async getChannelList(param = {}) {
        this.dispatch({
          type: "ChannelModel/setUpdateState",
          payload: { filed: "dataList", value: { loadingStatus: true } }
        });
        const expand = "channel_cover,channel_contact";
        let result = await channelS.channelList({
          ...param,
          expand
        });
        if (result) {
          this.dispatch({
            type: "ChannelModel/setUpdateState",
            payload: {
              filed: "dataList",
              value: { ...result.data, loadingStatus: false }
            }
          });
        }
      },
      async getApplyList(param = {}) {
        this.dispatch({
          type: "ChannelModel/setUpdateState",
          payload: { filed: "auditInfo", value: { loadingStatus: true } }
        });
        let result = await channelS.applyList({ ...param });
        if (result) {
          this.dispatch({
            type: "ChannelModel/setUpdateState",
            payload: {
              filed: "auditInfo",
              value: {
                ...result.data,
                loadingStatus: false
              }
            }
          });
        }
      },
      async getDetail(id) {
        const expand = "channel_cover,channel_contact,charge_user_org_info";
        let result = await channelS.detail({ id, expand });
        if (result.code === 0) {
          this.dispatch({
            type: "ChannelModel/setUpdateState",
            payload: {
              filed: "detailInfo",
              value: { ...result.data }
            }
          });
        }
        return result;
      },
      async channelAdd(params) {
        let result = await channelS.add(params);
        if (result.code === 0) {
        }
        return result;
      },
      async channelEdit(params) {
        let result = await channelS.edit(params);
        return result;
      },
      async areaPositionAdd(param) {
        let result = await areaMappingS.areaPositionAdd({ ...param });
        return result;
      },
      async areaPositionEdit(param) {
        let result = await areaMappingS.areaPositionEdit(param);
        return result;
      },
      async areaPositionDelete(param) {
        let result = await areaMappingS.areaPositionDelete(param);
        return result;
      },
      async getAreaMarkerList(param) {
        // param.expand = "position_pid,jd_address,create_time_str,effective_time,created_by_name,created_by_org_name,benefits";
        let result = await areaMappingS.areaMarkers({ ...param });
        if (result) {
          this.dispatch({
            type: "ChannelModel/setUpdateState",
            payload: { filed: "areaMarkerList", value: result.data }
          });
        }
        return result;
      },
      async getAreaPositionList(param) {
        param.expand = "position_pid,jd_address,create_time_str,effective_time,created_by_name,created_by_org_name,benefits,hh_org_info";
        param["per-page"] = 10;
        let result = await areaMappingS.areaPositions({ ...param });
        if (result) {
          this.dispatch({
            type: "ChannelModel/setUpdateState",
            payload: { filed: "areaPositionList", value: result.data }
          });
        }
        return result;
      },
      async getAreaTalentList(param) {
        // param.expand = "position_pid,jd_address,create_time_str,effective_time,created_by_name,created_by_org_name,benefits,hh_org_info";
        param["per-page"] = 20;
        this.dispatch({
          type: "business/updateLoading",
          payload: true    
        });
        let result = await areaMappingS.areaTalents({ ...param });
        if (result) {
          this.dispatch({
            type: "ChannelModel/setUpdateState",
            payload: { filed: "areaTalentList", value: result.data }
          });
        }
        this.dispatch({
          type: "business/updateLoading",
          payload: false
        });
        return result;
      },
      async getPopMarkerList(param, popDot) {
        param = {
          source: 32,
          ...param,
          longitude: popDot.longitude,
          latitude: popDot.latitude,
        }
        let result = {};
        if (popDot.type === "business") {
          param.expand = "company,jd_info,source_name,create_time_str,create_by_name,contactor,status_name,assign_to,assign_to_org_name,bd_name,bd_org_name,submit_num,last_contact,gb_business,source_p,communication"
          result = await businessService.businessList(param);
        } else {
          result = await areaMappingS.areaTalents(param);
        }
        if (result.code == 0) {
          this.dispatch({
            type: "ChannelModel/setUpdateState",
            payload: { filed: "popMarkerList", value: result.data.list }
          });
        }
        return result;
      },
      //校园渠道开始
      async getSchoolList(param = {}) {
        this.dispatch({
          type: "ChannelModel/setUpdateState",
          payload: { filed: "schoolList", value: { loadingStatus: true } }
        });
        const expand = "area_name,city_name,nature_name";
        let result = await channelS.schoolList({
          ...param,
          expand
        });
        if (result) {
          this.dispatch({
            type: "ChannelModel/setUpdateState",
            payload: {
              filed: "schoolList",
              value: { ...result.data, loadingStatus: false }
            }
          });
        }
      },
      async schoolEditHandle(params) {
        let result = await channelS.schoolEdit(params);
        return result;
      },
      async schoolAddHandle(params) {
        let result = await channelS.schoolAdd(params);
        if (result.code === 0) {
        }
        return result;
      },
      async getSchoolDetail(id) {
        const expand = "city_p_id";
        let result = await channelS.schoolDetail({ id, expand });
        if (result.code === 0) {
          this.dispatch({
            type: "ChannelModel/setUpdateState",
            payload: {
              filed: "schoolDetailInfo",
              value: { ...result.data }
            }
          });
        }
        return result;
      },
    };

    reducers = {
      drawerOpen(state) {
        state.visibleMessage = !state.visibleMessage;
        return { ...state };
      },
      setUpdateState(state, { payload }) {
        const { filed, value } = payload;
        state[filed] = value;
        return { ...state };
      },
      setUpdate(state, { payload }) {
        return { ...state, ...payload };
      },
      setUpdateEdit(state, { payload }) {
        let formParam = {
          ...convertChannelEdit(state.detailInfo)
        };
        return { ...state, formParam };
      },
      setUpdateSchoolEdit(state, { payload }) {
        let schoolFormParam = {
          ...convertSchoolEdit(state.schoolDetailInfo)
        };
        return { ...state, schoolFormParam };
      }
    };
  }
);
