import React, { PureComponent } from "react";

export default class PreviewTool extends PureComponent {
  state = {
  };

  /**
   * @description 是否是word||xlsx
   * @param {object} report   预览的值
   */
  isWordXlsx = patch => {
    return patch && patch.indexOf(".doc") !== -1;
  };

  isPdf = patch => {
    return patch && patch.indexOf(".pdf") !== -1;
  };

  render() {
    const { iframeSrc, className } = this.props;
    const { protocol, host } = window.location;
    
    let hasHttp = /^(http|https)/g.test(iframeSrc);
    let showSrc = this.isWordXlsx(iframeSrc)
      ? "https://officex.careerintlinc.com/wv/wordviewerframe.aspx?ui=zh-cn&WOPISrc=" +
        `${hasHttp ? "" : `${protocol}//${host}`}${iframeSrc}`
      : `${hasHttp ? "" : `${protocol}//${host}`}${iframeSrc}`;
    showSrc =  this.isPdf(showSrc) ? (showSrc + "#view=FitH,top"): showSrc;
    console.log("showSrc", showSrc)
      // showSrc = "https://www.scout.or.jp/_userdata/training/kadai/03_wb_a_kadai.pdf"
    return (
      <div className={`preview-tool-container ${className}`}>
        <iframe frameBorder="0" src={showSrc} />
        <style jsx>{`
          .preview-tool-container {
            height: 100%;
            iframe {
              width: 100%;
              height: 100%;
              overflow: scroll;
            }
          }
        `}</style>
      </div>
    );
  }
}
