import _ from "underscore";
import moment from "moment";

//渠道新增或编辑字段
export const channelFieldNames = [
  "company_name",
  "short_name",
  "channel_source",
  "chat_status",
  "is_free",
  "fee_desc",
  "address",
  "source_type",
  "source_type_other",
  "cover_nums",
  "contact_phone",
  "contact_user",
  "remark",
  "desc",
  "charge_user",
  "contactsArray",
  "addressArray",
  "time",
  "position",
  "cooperation_time_from",
  "cooperation_time_to",
  //下面回显
  "channel_cover",
  "channel_contact",
  "charge_user_org_info"
];
//校园新增或编辑字段
export const schoolFieldNames = [
  "area",
  "city_id",
  "nature",
  "name",
  "faculty_center",
  "pic",
  "office_phone",
  "email",
  "office_location",
  "job_url",
  "is_211",
  "is_985",
  "is_top",

  "city_p_id"
];
//字段判断处理
function doVal(data, filedName) {
  let val = {};
  val[filedName] = val[filedName] ? val[filedName] : [];
  data[filedName].forEach((item, index) => {
    val[filedName][index] = val[filedName][index] ? val[filedName][index] : {};
    val[filedName][index] = item.value;
  });
  return val;
}
//转换成number
function covertNumber(val){
  return val?1:0
}
//转换成boolean
function covertBoolean(val){
  return val?true:false
}
// 转为职位map 映射到表单
export function convertChannelEdit(data) {
  const initVal = _.pick(data, channelFieldNames);
  let convertVal = {};
  _.keys(initVal).map(key => {
    let val = initVal[key];
    convertVal[key] = {
      value: val
    };
    if (key === "channel_cover") {
      convertVal["addressArray"] = { value: [""] };
      convertVal["address"] = [];
      convertVal["remark"] = [];

      val &&
        val.forEach((currentItem, index) => {
          convertVal["addressArray"].value[index] = "";
          convertVal["address"][index] = {
            value: [currentItem.province_id, currentItem.city_id],
            name: "address[" + index + "]"
          };
          convertVal["remark"][index] = {
            value: currentItem.remark,
            name: "remark[" + index + "]"
          };
        });
    }
    if (key === "channel_contact") {
      convertVal["contactsArray"] = { value: [""] };
      convertVal["contact_user"] = [];
      convertVal["contact_phone"] = [];
      convertVal["position"] = [];
      val.data &&
        val.data.forEach((currentItem, index) => {
          convertVal["contactsArray"].value[index] = "";
          convertVal["contact_user"][index] = {
            value: currentItem.contact_user,
            name: "contact_user[" + index + "]"
          };
          convertVal["contact_phone"][index] = {
            value: currentItem.contact_phone,
            name: "contact_phone[" + index + "]"
          };
          convertVal["position"][index] = {
            value: currentItem.position,
            name: "position[" + index + "]"
          };
        });
    }
    if (key === "charge_user_org_info") {
      let id1 = initVal["charge_user_org_info"]
        ? (initVal["charge_user_org_info"].org_city || {}).id
        : undefined;
      let id2 = initVal["charge_user_org_info"]
        ? (initVal["charge_user_org_info"].org_team || {}).id
        : undefined;
      let id3 = initVal["charge_user_org_info"]
        ? (initVal["charge_user_org_info"].org_group || {}).id
        : undefined;
      let id4 = initVal["charge_user_org_info"]
        ? (initVal["charge_user_org_info"].org_user || {}).id
        : undefined;
      let idArray = [id1, id2, id3, id4];
      convertVal["charge_user"] = { value: _.filter(idArray) };
    }
    if (key === "cooperation_time_to"&&initVal.cooperation_time_from) {
      convertVal["time"] = {
        value: [
          moment(initVal.cooperation_time_from),
          moment(initVal.cooperation_time_to)
        ]
      };
    }
  });
  console.log("convertVal", convertVal);
  return convertVal;
}

// 表单转为渠道提交
export function transformChannelForm(data) {
  let alls = {};
  _.keys(data).map(name => {
    alls[name] = data[name].value;
    if (name === "charge_user") {
      alls[name] = _.last(alls[name]);
    }
    if (name === "address") {
      alls["address"] = alls["address"] ? alls["address"] : [];
      data[name].map((item, index) => {
        alls["address"][index] = alls["address"][index]
          ? alls["address"][index]
          : {};
        if (item.value) {
          const [firstVal, secondVal] = item.value;
          alls["address"][index].province_id = firstVal;
          alls["address"][index].city_id = secondVal;
        }
      });
    }
    if (name === "remark") alls = { ...alls, ...doVal(data, name) };
    if (name === "contact_user") alls = { ...alls, ...doVal(data, name) };
    if (name === "contact_phone") alls = { ...alls, ...doVal(data, name) };
    if (name === "position") alls = { ...alls, ...doVal(data, name) };
  });
  alls["address"] =
    alls["address"] && alls["address"].filter(item => item.province_id); //去除垃圾数据
  alls["contact_phone"] =
    alls["contact_phone"] && alls["contact_phone"].filter(item => item); //去除垃圾数据
  alls["contact_user"] =
    alls["contact_user"] && alls["contact_user"].filter(item => item); //去除垃圾数据
  alls["position"] = alls["position"] && alls["position"].filter(item => item); //去除垃圾数据
  alls["remark"] = alls["remark"] && alls["remark"].filter(item => item); //去除垃圾数据
  return alls;
}
// 转为校园 映射到表单
export function convertSchoolEdit(data) {
  const initVal = _.pick(data, schoolFieldNames);
  let convertVal = {};
  _.keys(initVal).map(key => {
    let val = initVal[key];
    convertVal[key] = {
      value: val
    };
    if (key === "city_p_id"||key === "city_id") {
      let idArray = [initVal["city_p_id"], initVal["city_id"]];
      convertVal["city_id"] = { value: _.filter(idArray) };
    }
  });
  console.log("convertVal", convertVal);
  return convertVal;
}

// 表单转为校园信息提交
export function transformSchoolForm(data) {
  let alls = {};
  _.keys(data).map(name => {
    alls[name] = data[name].value;
    if (name === "city_id") {
      alls[name] = _.last(alls[name]);
    }
    if (name === "is_211"||name === "is_985"||name === "is_top") {
      alls[name] = covertNumber(alls[name]);
    }
  });
  return alls;
}
