import React, { PureComponent } from "react";
import { Tooltip, Icon, Collapse } from "antd";
import classNames from "classnames";
import { filter } from "utils/validComponentChildren";
const Panel = Collapse.Panel;

/**
 * 展示和编辑切换组件
 */
class EditView extends PureComponent {
  render() {
    const { isView, className, children } = this.props;

    return (
      <div className={classNames("edit-view", className)}>
        {isView ? (
          <div className="view">{filterChildren(children, "view")}</div>
        ) : (
          <div className="edit">{filterChildren(children, "edit")}</div>
        )}
        <style jsx>
          {`
            .edit-view {
              padding: 10px;
            }
          `}
        </style>
      </div>
    );
  }
}

class EditViewSingle extends PureComponent {
  state = {
    currentKey: ["1"]
  };

  componentDidUpdate(prevProps) {
    if (prevProps.isView !== this.props.isView && !this.props.isView) {
      this.setState({
        currentKey: ["1"]
      });
    }
  }

  clickCollapse = () => {
    this.setState({
      currentKey: this.state.currentKey.length !== 0 ? [] : ["1"]
    });
  };
  render() {
    const {
      isView = true,
      isExpand = true,
      editable,
      disabledEdit,
      deletable,
      className,
      children,
      title,
      onSave,
      onDelete,
      onEdit,
      onCancel,
      ...props
    } = this.props;
    const { currentKey } = this.state;
    let singleHeadInfo = title ? (
      <div className="header clear">
        <div className="title left">{title}</div>
        {!disabledEdit && (
          <div className="right">
            {onEdit && <div
              className="func"
              style={{ display: isView ? "block" : "none" }}
            >
              <div
                onClick={e => {
                  onEdit && onEdit(false);
                  e.stopPropagation();
                }}
                className={classNames("edit-btn", {
                  disable: editable === false
                })}
              >
                <Tooltip title="编辑">
                  <Icon type="edit" theme="outlined" />
                </Tooltip>
              </div>
            </div>}

            <div
              className="func clear"
              style={{ display: !isView ? "block" : "none" }}
            >
              {onSave && <div
                className="save-btn right"
                onClick={e => {
                  onSave && onSave(true);
                  e.stopPropagation();
                }}
              >
                <Tooltip title="保存">
                  <Icon type="check" theme="outlined" />
                </Tooltip>
              </div>}
              {onCancel && <div
                className="cancel-btn right"
                onClick={e => {
                  onCancel && onCancel(true);
                  e.stopPropagation();
                }}
              >
                <Tooltip title="取消">
                  <Icon type="close" theme="outlined" />
                </Tooltip>
              </div>}
            </div>
          </div>
        )}

        <style jsx>
          {`
            .header .title {
              color: #333333;
              font-size: 16px;
              font-weight: 600;
            }
            .header {
              .save-btn {
                margin-left: 10px;
              }
            }
          `}
        </style>
      </div>
    ) : (
      <div className="func-group">
        <div
          className="func clear"
          style={{ display: isView ? "block" : "none" }}
        >
          {onDelete && <div
            className={classNames("delete-btn right", {
              disable: deletable === false
            })}
            onClick={e => {
              onDelete && onDelete();
              e.stopPropagation();
            }}
          >
            <i className="icon icon-circle-close-gray" />
            <Tooltip title="删除">
              <Icon type="delete" theme="outlined" />
            </Tooltip>
          </div>}
          {onEdit && <div
            className={classNames("edit-btn right", {
              disable: editable === false
            })}
            onClick={e => {
              onEdit && onEdit(false);
              e.stopPropagation();
            }}
          >
            <Tooltip title="编辑">
              <Icon type="edit" theme="outlined" />
            </Tooltip>
          </div>}
        </div>
        <div
          className="func clear"
          style={{ display: !isView ? "block" : "none" }}
        >
          {onSave && <div
            className="save-btn right"
            onClick={e => {
              onSave && onSave(true);
              e.stopPropagation();
            }}
          >
            <Tooltip title="保存">
              <Icon type="check" theme="outlined" />
            </Tooltip>
          </div>}
          {onCancel && <div
            className="cancel-btn right"
            onClick={e => {
              onCancel && onCancel(true);
              e.stopPropagation();
            }}
          >
            <Tooltip title="取消">
              <Icon type="close" theme="outlined" />
            </Tooltip>
          </div>}
        </div>
        <style jsx>{`
          .edit-btn,
          .cancel-btn,
          .save-btn,
          .delete-btn {
            cursor: pointer;
            :global(.anticon) {
              font-size: 14px;
            }
          }
          .cancel-btn {
            margin-right: 5px;
          }
          .func-group {
            padding: 0 38px 0 10px;
            .edit-btn {
              margin-right: 5px;
            }
          }
        `}</style>
      </div>
    );
    return (
      <div
        className={classNames(
          "edit-view-single collapse-handle-box",
          { "editview-single-editabled": !isView },
          className
        )}
        {...props}
      >
        {title ? (
          <Collapse
            defaultActiveKey={isExpand ? ["1"] : []}
            activeKey={currentKey}
            onChange={this.clickCollapse}
          >
            <Panel
              header={singleHeadInfo}
              key="1"
              forceRender={true}
              // className={isView ? "" : "editview-single-editabled"}
            >
              <EditView isView={isView} children={children} />
            </Panel>
          </Collapse>
        ) : (
          <div>
            {singleHeadInfo}
            <EditView isView={isView} children={children} />
          </div>
        )}
        <style jsx global>
          {`
            .editview-single-editabled {
              background: #f0f0f0;
              .ant-collapse-content {
                background: #f0f0f0;
              }
            }
          `}
        </style>
      </div>
    );
  }
}

class EditViewMulti extends PureComponent {
  state = {
    currentKey: ["1"]
  };

  clickCollapse = () => {
    this.setState({
      currentKey: this.state.currentKey.length !== 0 ? [] : ["1"]
    });
  };

  render() {
    const { title, className, children, onAdd, addable } = this.props;
    const { currentKey } = this.state;
    let multiHeadInfo = (
      <div className="header clear">
        <div className="title left">{title}</div>
        {onAdd && <div className="right func">
          <div
            className={classNames("btn-add", {
              disable: addable === false
            })}
            onClick={e => {
              onAdd && onAdd();
              this.setState({ currentKey: ["1"] });
              e.stopPropagation();
            }}
          >
            <Tooltip title="新增">
              <Icon type="plus-square" theme="outlined" />
            </Tooltip>
          </div>
        </div>}
        <style jsx>
          {`
            .header {
              .title {
                font-weight: 600;
              }
            }
          `}
        </style>
      </div>
    );
    return (
      <div
        className={classNames("edit-view-multi collapse-handle-box", className)}
      >
        <Collapse
          defaultActiveKey={["1"]}
          activeKey={currentKey}
          onChange={this.clickCollapse}
        >
          <Panel header={multiHeadInfo} key="1">
            <div className="body">{children}</div>
          </Panel>
        </Collapse>
        <style jsx>
          {`
            .btn-add {
              :global(.anticon-plus-square) {
                font-size: 14px;
                cursor: pointer;
              }
            }
            .edit-view-multi {
              .header {
                background: #eee;
                padding: 5px 10px;
              }

              :global(.ant-collapse-content-box) {
                padding: 0;
              }

              :global(.edit-view) {
                padding: 0 26px;
              }

              :global(.collapse-handle-box) {
                margin-bottom: 0;
              }

              :global(.edit-view-single) {
                position: relative;
                padding-top: 8px;
                padding-bottom: 8px;
                :global(.func-group) {
                  position: absolute;
                  top: 8px;
                  right: 0;
                  z-index: 100;
                }
              }

              :global(.editview-single-editabled) {
                margin-bottom: 10px;
                padding-top: 30px;
              }

              :global(.editview-single-editabled.last) {
                margin-bottom: 0;
              }

              h3.left {
                margin: 0;
              }
            }
          `}
        </style>
      </div>
    );
  }
}

function filterChildren(children, role) {
  return filter(children, child => {
    return child.props.role && child.props.role === role;
  });
}

export { EditView, EditViewSingle, EditViewMulti };
