import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { Row, Col, Form, Input, DatePicker, Select, InputNumber } from "antd";
import { EditViewSingle, EditViewMulti } from "components/EditView";
import socialCandModel from "store/reducers/socialCandModel";
import { commonData } from "services/commonService";
import moment from "moment";
import _ from "underscore";
import InputNumberUnit from "components/InputNumberUnit";
import { disabledEndDate, disabledStartDate } from "utils/formVerify";
import { MonthPickerSofar } from "components/DatePickerSofarWrap";
import updateCompletewrapper from "./update-complete-wrapper";

const FormItem = Form.Item;
const Option = Select.Option;
const { MonthPicker } = DatePicker;
const { TextArea } = Input;

export function mapPropsToFields(props) {
  const { item } = props;
  const formFileds = _.mapObject(item, function(val, key) {
    return Form.createFormField({
      ...val,
      value: val && val.value
    });
  });
  return formFileds;
}

export function fieldsToProps(values) {
  const { begin_time, end_time } = values;
  values.begin_time = begin_time && begin_time.format("YYYY/MM");
  values.end_time =
    typeof end_time !== "string" ? end_time.format("YYYY/MM") : end_time;
  for (const key of ["salary_scope", "industry", "company_scale"]) {
    const value = values[key] || {};
    values[key] = value.key;
    values[`${key}_label`] = value.label;
  }
  return values;
}

export function onFieldsChange(props, changedFields) {
  // console.log("changer", props, changedFields)
  props.onChange(changedFields);
}

export function createJobExperienceEditForm(
  mapPropsToFields,
  fieldsToProps,
  onFieldsChange
) {
  return Form.create({
    mapPropsToFields,
    onFieldsChange
  })(
    class EditForm extends PureComponent {
      state = {
        salaryScopeOptions: [],
        companyScaleOptions: [],
        companyIndustryOptions: [],

        beginTime: null,
        endTime: null
      };

      componentDidMount() {
        commonData("salary_scope,company_scale,company_industry").then(
          options => {
            this.setState({
              salaryScopeOptions: options.salary_scope || [],
              companyScaleOptions: options.company_scale || [],
              companyIndustryOptions: options.company_industry || []
            });
          }
        );
      }

      handleSubmit = () => {
        return new Promise((resolve, reject) => {
          this.props.form.validateFieldsAndScroll({scroll: {offsetBottom: 60}},(err, values) => {
            if (!err) {
              resolve(fieldsToProps ? fieldsToProps(values) : values);
            } else {
              reject(err);
            }
          });
        });
      };

      onDateChange = (field, date) => {
        this.setState({ [field]: date });
      };

      render() {
        const formItemLayout = {
          labelCol: { span: 5 },
          wrapperCol: { span: 19 }
        };

        const { getFieldDecorator } = this.props.form;
        const {
          salaryScopeOptions,
          companyScaleOptions,
          companyIndustryOptions,
          endTime,
          beginTime
        } = this.state;
        return (
          <div className="job-experience-edit">
            <Form className="edit-form" layout="horizontal">
              <Row className="one" gutter={35}>
                <Col span={12}>
                  <FormItem label="公司名称" {...formItemLayout}>
                    {getFieldDecorator("company_name", {
                      rules: [
                        {
                          required: true,
                          message: "请输入公司名称，且最大60个字符",
                          max: 60
                        }
                      ]
                    })(<Input placeholder="请输入公司名称" size="large" />)}
                  </FormItem>
                </Col>
                <Col span={12}>
                  <div className="form-item-period clear">
                    <FormItem
                      label="起止时间"
                      labelCol={{ span: 8 }}
                      wrapperCol={{ span: 15 }}
                    >
                      {getFieldDecorator("begin_time", {
                        rules: [
                          {
                            required: true,
                            message: "请选择起止时间"
                          }
                        ]
                      })(
                        <MonthPicker
                          size="large"
                          format="YYYY/MM"
                          disabledDate={disabledStartDate.bind(null, endTime)}
                          onChange={this.onDateChange.bind(this, "beginTime")}
                        />
                      )}
                    </FormItem>
                    &nbsp;&nbsp;~&nbsp;&nbsp;
                    <FormItem>
                      {getFieldDecorator("end_time", {
                        rules: [
                          {
                            required: true,
                            message: "请选择起止时间"
                          }
                        ]
                      })(
                        <MonthPickerSofar
                          size="large"
                          format="YYYY/MM"
                          disabledDate={disabledEndDate.bind(null, beginTime)}
                          onChange={this.onDateChange.bind(this, "endTime")}
                        />
                      )}
                    </FormItem>
                  </div>
                </Col>
              </Row>
              <Row className="two" gutter={35}>
                <Col span={12}>
                  <FormItem
                    label="部门"
                    {...formItemLayout}
                  >
                    {getFieldDecorator("department", {
                      rules: [{ message: "部门最大60个字符", max: 60 }]
                    })(<Input placeholder="请输入部门" size="large" />)}
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem
                    label="职位"
                    {...formItemLayout}
                  >
                    {getFieldDecorator("position", {
                      rules: [
                        {
                          max: 60,
                          message: "职位最大60个字符"
                        }
                      ]
                    })(<Input placeholder="请输入职位" size="large" />)}
                  </FormItem>
                </Col>
              </Row>
              <Row className="three" gutter={35}>
                <Col span={12}>
                  <FormItem label="薪资范围" {...formItemLayout}>
                    {getFieldDecorator("salary_scope", {
                      rules: []
                    })(
                      <Select
                        placeholder="请选择薪资范围"
                        size="large"
                        labelInValue
                      >
                        {salaryScopeOptions.map(item => (
                          <Option value={item.value} key={item.value}>
                            {item.label}
                          </Option>
                        ))}
                      </Select>
                    )}
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem label="汇报对象" {...formItemLayout}>
                    {getFieldDecorator("supervisor", {
                      rules: [
                        {
                          max: 60,
                          message: "汇报对象最大60个字符"
                        }
                      ]
                    })(<Input placeholder="请输入汇报对象" size="large" />)}
                  </FormItem>
                </Col>
              </Row>
              <Row className="four" gutter={35}>
                <Col span={12}>
                  <FormItem label="所属行业" {...formItemLayout}>
                    {getFieldDecorator("industry", {
                      rules: []
                    })(
                      <Select
                        placeholder="请选择所属行业"
                        size="large"
                        labelInValue
                      >
                        {companyIndustryOptions.map(item => (
                          <Option value={item.value} key={item.value}>
                            {item.label}
                          </Option>
                        ))}
                      </Select>
                    )}
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem label="下属人数" {...formItemLayout}>
                    {getFieldDecorator("underling", {
                      rules: []
                    })(
                      <InputNumberUnit
                        precision={0}
                        style={{ width: "100%", fontSize: "14px", paddingLeft: "9px" }}
                        size="large"
                        min={0}
                        max={10000}
                        unit="人"
                        placeholder="请输入下属人数"
                      />
                    )}
                  </FormItem>
                </Col>
              </Row>
              <Row className="six" gutter={35}>
                <Col span={12}>
                  <FormItem label="公司规模" {...formItemLayout}>
                    {getFieldDecorator("company_scale", {
                      rules: []
                    })(
                      <Select
                        placeholder="请选择公司规模"
                        size="large"
                        labelInValue
                      >
                        {companyScaleOptions.map(item => (
                          <Option value={item.value} key={item.value}>
                            {item.label}
                          </Option>
                        ))}
                      </Select>
                    )}
                  </FormItem>
                </Col>
              </Row>

              <Row className="five" gutter={35}>
                <Col span={24}>
                  <FormItem
                    label="工作内容"
                    labelCol={{ span: 2 }}
                    wrapperCol={{ span: 22 }}
                  >
                    {getFieldDecorator("duty", {
                      rules: []
                    })(
                      <TextArea
                        placeholder="工作内容"
                        autosize={{ minRows: 2, maxRows: 6 }}
                      />
                    )}
                  </FormItem>
                </Col>
              </Row>
            </Form>
            <style jsx global>
              {`
                .form-item-period {
                  .ant-form-item {
                    display: inline-block;
                  }
                  .ant-form-item:first-child {
                    width: 58%;
                    .ant-form-item-label {
                      width: 35%;
                    }
                  }
                  .ant-form-item:last-child {
                    width: 34%;
                  }
                }
                .job-experience-edit {
                  :global(.five) {
                    :global(.ant-form-item-label) {
                      width: 10%;
                    }
                    :global(.ant-form-item-control-wrapper) {
                      width: 90%;
                      :global(.ant-input) {
                        height: 100px;
                        resize: none;
                      }
                    }
                  }
                }
              `}
            </style>
          </div>
        );
      }
    }
  );
}

export const JobExperienceEditForm = createJobExperienceEditForm(
  mapPropsToFields,
  fieldsToProps
);

export const AddJobExpEditForm = createJobExperienceEditForm(
  mapPropsToFields,
  fieldsToProps,
  onFieldsChange
);

export function JobExperienceView(props) {
  let { item } = props;
  item = _.mapObject(item, (val, key) => {
    return val && val.value;
  });
  return (
    <div className="job-experience-view">
      <Row gutter={10} className="job-experience-item">
        <Col span={4}>
          {item.begin_time.format("YYYY/MM")}-
          {typeof item.end_time === "string"
            ? item.end_time
            : item.end_time.format("YYYY/MM")}
        </Col>
        <Col span={18}>
          <Row gutter={5} className="job-experience-title">
            <Col span={24}>
              {item.company_name} | {item.industry_label} |
              {item.company_scale_label}
            </Col>
          </Row>
          <Row gutter={5}>
            <Col span={24}>
              部门 : {item.department} | 职位 : {item.position}
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              汇报对象 : {item.supervisor} | 下属人数 : {item.underling}
            </Col>
          </Row>
          <div className="job-content">
            <div>工作内容 :</div>
            <pre>{item.duty}</pre>
          </div>
        </Col>
      </Row>
      <style jsx>
        {`
          .job-experience-view {
            :global(.job-experience-item) {
              margin-bottom: 28px;
              &:last-child {
                margin-bottom: 0;
              }
              .job-content {
                > div {
                  font-weight: 600;
                }
                p {
                  line-height: 28px;
                  margin-bottom: 0;
                }
              }
            }
            :global(.ant-row) {
              margin-bottom: 18px;
              color: #666;
            }
            :global(.job-experience-title) {
              font-weight: 600;
            }
          }
        `}
      </style>
    </div>
  );
}

class JobExperience extends PureComponent {
  state = {
    topPlaceholderBg: "#ffffff",
    bottomPlaceholderBg: "#ffffff"
  };

  setPlaceholderColor = (index, color) => {
    const { jobExperiences } = this.props;
    index === jobExperiences.length - 1
      ? this.setState({ bottomPlaceholderBg: color })
      : !1;
    index === 0 ? this.setState({ topPlaceholderBg: color }) : !1;
  };

  onEdit = index => {
    this.setPlaceholderColor(index, "#f0f0f0");
    this.props.updateJobExperience({ index, values: { isEdit: true } });
  };

  onCancel = index => {
    this.setPlaceholderColor(index, "#ffffff");
    this.props.cancelJobExperience(index);
  };

  onSave = index => {
    const { saveJobExperience } = this.props;
    this.formRef.handleSubmit().then(values => {
      saveJobExperience(index, values)
        .then(res => {
          if (res.code === 0) this.setPlaceholderColor(index, "#ffffff");
          return res;
        })
        .then(res => (!res.code ? res.data.compete : null))
        .then(this.props.toUpdateComplete);
    });
  };

  onDelete = index => {
    this.props
      .removeJobExperience(index)
      .then(res => (!res.code ? res.data.compete : null))
      .then(this.props.toUpdateComplete);
  };

  onAdd = () => {
    const { jobExperiences } = this.props;
    if (!jobExperiences.length) {
      this.setPlaceholderColor(-1, "#f0f0f0");
      this.setPlaceholderColor(0, "#f0f0f0");
    } else {
      this.setState({ bottomPlaceholderBg: "#f0f0f0" });
    }
    this.props.addJobExperience({ limit: true });
  };

  render() {
    const { topPlaceholderBg, bottomPlaceholderBg } = this.state;
    const { jobExperiences, updateJobExperience } = this.props;
    return (
      <EditViewMulti
        className="job-experience"
        title="工作经历"
        onAdd={this.onAdd}
      >
        <div className="placeholder" style={{ background: topPlaceholderBg }} />
        {jobExperiences.map((item, index) => (
          <EditViewSingle
            isView={!item.isEdit}
            onEdit={() => this.onEdit(index)}
            onCancel={() => this.onCancel(index)}
            onSave={() => this.onSave(index)}
            onDelete={() => this.onDelete(index)}
            className={`${index === jobExperiences.length - 1 ? "last" : ""}`}
            key={item.job_id}
          >
            <div role="view" className="view">
              <JobExperienceView item={item} />
            </div>
            <div role="edit" className="edit">
              <JobExperienceEditForm
                item={item}
                wrappedComponentRef={inst => (this.formRef = inst)}
              />
            </div>
          </EditViewSingle>
        ))}
        <div
          className="placeholder"
          style={{ background: bottomPlaceholderBg }}
        />
        <style jsx>{`
          .placeholder {
            height: 30px;
          }
        `}</style>
      </EditViewMulti>
    );
  }
}

export default connect(
  ({ socialCandidate }) => {
    return {
      jobExperiences: socialCandidate.candidate.detail.jobExperiences || []
    };
  },
  { ...socialCandModel.actions }
)(updateCompletewrapper(JobExperience));
