//实习生
export const internBaseInfoEditFieldnames= [
    "name",
    "education",
    "education_label",
    "sex",
    "sex_label",
    "job_intention",
    "city",
    "native",
    "birth",
    "phone",
    "email",
    "tags",
    "profile_source",
    "profile_source_label",
    'profile_source_data',
    "self_introduction",
    "appraisal",
    "content",
    "address",
];

//社会人选
export const BaseInfoEditFieldnames = [
  "name",
  "job_intention",
  "job_intention_label",
  'position_name',
  'profile_source_data',
  "position_p",
  "position",
  "birth",
  "date",
  "phone",
  "email",
  'city',
  "native",
  "communicate",
  "communicate_label",
  "education",
  "education_label",
  "office",
  "office_label",
  "english",
  "english_label",
  "marry",
  "marry_label",
  "tags",
  "profile_source",
  "profile_source_label",
  "worktime",
  "sex",
  "sex_label",
  "appraisal",
  "content",
  "address",
];

export const jobStatusEditFieldnames = [
  "is_leave",
  "available",
  "salary_current",
  "salary_list",
  "forward_city",
  "salary_expect",
  "answer1",
  "answer2"
];

export const educationFieldnames=[
    'education_id',
    'begin_time',
    'education',
    'end_time',
    'major_name',
    'school_name'
];

export const jobExperienceFieldnames=[
    'company_name',
    'begin_time',
    'end_time',
    'department',
    'position',
    'salary_scope',
    'supervisor',
    'company_scale',
    'company_industry',
    'underling',
    'duty'
]

export const certificateFieldnames=[
    'name',
    'date'
];