import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { EditViewSingle } from "components/EditView";
import { Row, Col, Form, Select, Input, DatePicker } from "antd";
import socialCandModel from "store/reducers/socialCandModel";
import MultiCascade from "components/MultiCascade";
import { commonData } from "services/commonService";
import _ from "underscore";
import moment from "moment";
import { jobStatusEditFieldnames } from "./fieldsConfig";
import InputNumberUnit from "components/InputNumberUnit";
import { cityNamejoint } from "./interfaceDataConvert";
import updateCompletewrapper from "./update-complete-wrapper";

const FormItem = Form.Item;
const Option = Select.Option;
const { TextArea } = Input;

function mapPropsToFields(props) {
  const { jobStatus } = props;
  const filtedEdit = _.pick(jobStatus, jobStatusEditFieldnames);
  const { forward_city = [] } = filtedEdit;
  const fields = _.mapObject(filtedEdit, function(val, key) {
    if (["is_leave", "available"].indexOf(key) !== -1) {
      return Form.createFormField({
        value: val
          ? { key: parseInt(val), label: jobStatus[`${key}_label`] }
          : undefined
      });
    }
    return Form.createFormField({
      value: val && val
    });
  });
  fields.forward_city = Form.createFormField({
    value: forward_city.map(item => ({
      province: item.province_id,
      province_label: item.province_label,
      city: item.city_id,
      city_label: item.city_label,
      region: item.region_id,
      region_label: item.region_label
    }))
  });
  return fields;
}

function fieldsToProps(values) {
  for (const key of ["is_leave", "available"]) {
    const value = values[key] || {};
    values[key] = value.key;
    values[`${key}_label`] = value.label;
  }
  const { forward_city } = values;
  values.forward_city = forward_city.map(item => ({
    province_id: item.province,
    province_label: item.province_label,
    city_id: item.city,
    city_label: item.city_label,
    region_id: item.region,
    region_label: item.region_label
  }));
  return values;
}

export function createJobStatusEditForm(mapPropsToFields, fieldsToProps) {
  return Form.create({
    mapPropsToFields
  })(
    class EditForm extends PureComponent {
      state = {
        citiesOptions: [],
        profileStatusOptions: [],
        profileAvailableOptions: []
      };
      componentDidMount() {
        commonData("cities,profile_is_leave,profile_available").then(
          options => {
            this.setState({
              citiesOptions: options.cities || [],
              profileStatusOptions: options.profile_is_leave || [],
              profileAvailableOptions: options.profile_available || []
            });
          }
        );
      }

      handleSubmit = () => {
        return new Promise((resolve, reject) => {
          this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
              resolve(fieldsToProps(values));
            } else {
              reject(err);
            }
          });
        });
      };

      render() {
        const formItemLayout = {
          labelCol: { span: 5 },
          wrapperCol: { span: 19 }
        };

        const { getFieldDecorator } = this.props.form;
        const {
          citiesOptions,
          profileStatusOptions,
          profileAvailableOptions
        } = this.state;
        return (
          <div className="job-status-edit">
            <Form
              onSubmit={this.handleSubmit}
              className="edit-form"
              layout="horizontal"
            >
              <Row className="one" gutter={35}>
                <Col span={12}>
                  <FormItem
                    label="状态"
                    {...formItemLayout}
                  >
                    {getFieldDecorator("is_leave", {
                      initialValue: {
                        key: (profileStatusOptions[1] || {}).value,
                        label: (profileStatusOptions[1] || {}).label
                      },
                      rules: [
                        {
                          required: true,
                          message: "请选择状态"
                        }
                      ]
                    })(
                      <Select
                        placeholder="请选择状态"
                        size="large"
                        labelInValue
                      >
                        {profileStatusOptions.map(item => (
                          <Option value={item.value} key={item.value}>
                            {item.label}
                          </Option>
                        ))}
                      </Select>
                    )}
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem label="到岗时间" {...formItemLayout}>
                    {getFieldDecorator("available", {
                      rules: []
                    })(
                      <Select
                        placeholder="请选择到岗时间"
                        size="large"
                        labelInValue
                      >
                        {profileAvailableOptions.map(item => (
                          <Option value={item.value} key={item.value}>
                            {item.label}
                          </Option>
                        ))}
                      </Select>
                    )}
                  </FormItem>
                </Col>
              </Row>
              <Row className="two" gutter={35}>
                <Col span={12}>
                  <FormItem label="当前薪资" {...formItemLayout}>
                    {getFieldDecorator("salary_current", {
                      rules: []
                    })(
                      <InputNumberUnit
                        precision={1}
                        style={{ width: "100%", fontSize: "14px", paddingLeft: "9px" }}
                        size="large"
                        min={0}
                        placeholder="请输入当前薪资"
                        unit="k/月"
                      />
                    )}
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem label="薪资结构" {...formItemLayout}>
                    {getFieldDecorator("salary_list", {
                      rules: []
                    })(<Input size="large" placeholder="请输入薪资结构" />)}
                  </FormItem>
                </Col>
              </Row>
              <Row className="three" gutter={35}>
                <Col span={12}>
                  <FormItem label="期望城市" {...formItemLayout}>
                    {getFieldDecorator("forward_city", {
                      rules: []
                      // getValueFromEvent: arg => {
                      //   const value =
                      //     this.props.form.getFieldValue("forward_city") || [];
                      //   value.push(arg);
                      //   return value;
                      // }
                    })(
                      <MultiCascade
                        options={citiesOptions}
                        fieldNames={{
                          label: "name",
                          value: "id",
                          children: "list"
                        }}
                        showSearch={true}
                        notFoundContent="没有选项"
                        placeholder="请选择期望城市"
                        size="large"
                      />
                    )}
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem label="期望薪资" {...formItemLayout}>
                    {getFieldDecorator("salary_expect", {
                      rules: []
                    })(
                      <InputNumberUnit
                        precision={1}
                        style={{ width: "100%", fontSize: "14px", paddingLeft: "9px" }}
                        size="large"
                        min={0}
                        placeholder="请输入期望薪资"
                        unit="k/月"
                      />
                    )}
                  </FormItem>
                </Col>
              </Row>
              <Row className="four">
                <Col span={24}>
                  <FormItem
                    label="离职原因"
                    labelCol={{ span: 2 }}
                    wrapperCol={{ span: 22 }}
                  >
                    {getFieldDecorator("answer1", {
                      rules: []
                    })(<TextArea placeholder="离职原因" />)}
                  </FormItem>
                </Col>
              </Row>
              <Row className="five">
                <Col span={24}>
                  <FormItem
                    label="期望新公司"
                    labelCol={{ span: 2 }}
                    wrapperCol={{ span: 22 }}
                  >
                    {getFieldDecorator("answer2", {
                      rules: []
                    })(<TextArea placeholder="您对新公司的期望" />)}
                  </FormItem>
                </Col>
              </Row>
            </Form>
            <style jsx>
              {`
                .job-status-edit {
                  :global(.four),
                  :global(.five) {
                    :global(.ant-form-item-label) {
                      width: 10%;
                    }
                    :global(.ant-form-item-control-wrapper) {
                      width: 90%;
                      :global(.ant-input) {
                        height: 100px;
                        resize: none;
                      }
                    }
                  }
                }
              `}
            </style>
          </div>
        );
      }
    }
  );
}

export const JobStatusEditForm = createJobStatusEditForm(
  mapPropsToFields,
  fieldsToProps
);

export function JobStatusView(props) {
  const { jobStatus } = props;
  return (
    <React.Fragment>
      <div className="status">
        <h4>当前状态</h4>
        <Row className="row1">
          <Col span={13}>
            <label>状态 : </label>
            <span>{jobStatus.is_leave_label}</span>
          </Col>
          <Col span={11}>
            <label>到岗时间 : </label>
            <span>{jobStatus.available_label}</span>
          </Col>
        </Row>
        <Row className="row2">
          <Col span={13}>
            <label>当前薪资 : </label>
            <span>{jobStatus.salary_current}K/月</span>
          </Col>
          <Col span={11}>
            <label>当前薪资结构 : </label>
            <span>{jobStatus.salary_list}</span>
          </Col>
        </Row>
        <Row className="row3">
          <Col>
            <label className="left" style={{ width: 67 }}>
              离职原因 :
            </label>
            <div className="left" style={{ width: "90%" }}>
              <pre>{jobStatus.answer1}</pre>
            </div>
          </Col>
        </Row>
      </div>
      <div className="expect">
        <h4>期望状态</h4>
        <Row className="row1">
          <Col span={13}>
            <label>期望城市 : </label>
            <span>
              {(jobStatus.forward_city || [])
                .map(item => cityNamejoint(item))
                .join(",")}
            </span>
          </Col>
          <Col span={11}>
            <label>期望薪资 : </label>
            <span>{jobStatus.salary_expect}k/月</span>
          </Col>
        </Row>
        <Row className="row2">
          <Col span={24}>
            <label className="left" style={{ width: 80 }}>
              期望新公司 :
            </label>
            <div className="left" style={{ width: "88%" }}>
              <pre>{jobStatus.answer2}</pre>
            </div>
          </Col>
        </Row>
      </div>
      <style jsx>
        {`
          .status {
            margin-bottom: 30px;
          }
          .status,
          .expect {
            h4 {
              font-size: 16px;
              font-weight: 600;
              margin-bottom: 18px;
            }
            :global(.ant-row) {
              color: #666;
              margin-bottom: 18px;
              &:last-child {
                margin-bottom: 0;
              }
            }
          }
        `}
      </style>
    </React.Fragment>
  );
}

class JobStatus extends PureComponent {
  onEdit = () => {
    this.props.updateJobStatus({ isEdit: true });
  };

  onCancel = () => {
    this.props.updateJobStatus({ isEdit: false });
  };

  onSave = () => {
    const { editJobStatus, updateJobStatus } = this.props;
    this.formRef.handleSubmit().then(values => {
      editJobStatus(values)
        .then(res => (!res.code ? res.data.compete : null))
        .then(this.props.toUpdateComplete);
    });
  };

  render() {
    const { jobStatus, updateJobStatus } = this.props;
    return (
      <div className="job-status entity">
        <EditViewSingle
          title="求职状态"
          isView={!jobStatus.isEdit}
          onEdit={this.onEdit}
          onCancel={this.onCancel}
          onSave={this.onSave}
        >
          <div role="view" className="view">
            <JobStatusView jobStatus={jobStatus} />
          </div>
          <div role="edit" className="edit">
            <JobStatusEditForm
              jobStatus={jobStatus}
              updateJobStatus={updateJobStatus}
              wrappedComponentRef={inst => (this.formRef = inst)}
            />
          </div>
        </EditViewSingle>
      </div>
    );
  }
}

export default connect(
  ({ socialCandidate }) => {
    return {
      jobStatus: socialCandidate.candidate.detail.jobStatus || {}
    };
  },
  { ...socialCandModel.actions }
)(updateCompletewrapper(JobStatus));
