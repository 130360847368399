import css from 'styled-jsx/css';

export default css.global`/* @styled-jsx=global */
// .company-container {
//   height: 100%;
  
// }

// .base-area {
//   .gee-cascader {
//     margin-left: 20px;
//     width: 200px;
//   }
//   .company-search {
//     margin-left: 20px;
//     width: 250px;
//   }
// }

.company-table {
  .ant-table-tbody>tr>td {
    padding: 9px 4px;
  }
  .base-column {
    .base-column-logo {
      display: inline-block;
      margin-right: 10px;
      img {
        width: 40px;
        height: 40px;
      }
    }
    .base-column-info {
      display: inline-block;
      vertical-align: top;
      .column-info {
        margin: 6px 0;
        font-size: 16px;
      }
      .names {
        display: inline-block;
        max-width: 300px;
        vertical-align: top;
        font-weight: bold;
        font-size: 14px;
      }
      .bd-names {
        display: inline-block;
        max-width: 230px;
        vertical-align: top;
      }
    }
  }
  .position-num {
    .ing-num {
      margin-right: 5px;
    }
    .ant-badge-status-text {
      color: #666666;
      font-size: 14px;
    }
    
  }
  .profile-num {
    margin-right: 10px;
    color: #666666;
    .anticon {
      font-size: 16px;
      color: #1890ff;
      vertical-align: -0.225em;
    }
    .recommended_num {
      font-size: 23px;
      vertical-align: -0.255em;
    }
    .arrange_interview_num {
      margin-right: 3px;
      font-size: 18px;
    }
    .interview_through_num {
      margin-right: 3px;
      font-size: 17px;
    }
    .offer_coordinate_num {
      margin-right: 3px;
      font-size: 16px;
    }
    .onboard_num {
      margin-right: 3px;
      font-size: 18px;
    }
    .obsolete_num {
      margin-right: 3px;
      font-size: 17px;
    }
  }
  .names {
    display: inline-block;
    max-width: 160px;
    vertical-align: top;
  }
  .last_follow_time {
    position: relative;
    .info-icon {
      position: absolute;
      top: 1px;
      font-size: 14px;
      right: -24px;
    }
  }
  
  .profile-tag {
    width: 80px;
    height: 22px;
    margin-right: 10px;
    &:last-child {
      margin-right: 0;
    }
    &.coordinate {
      background-color: #fffbe6;
      color: #faad14;
      border-color: #faad14;
    }
    &.obsolete {
      background-color: #f5f5f5;
      color: #999999;
      border-color: #999999;
    }
  }
  .contact-tag {
    width: 80px;
    max-width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    line-height: normal;
    color: #38a4e9;
  }
}

.tool-area {
  margin-top: 24px;
}

.number-area {
  position: relative;
  float: left;
}

.company-pagination {
  font-size: 12px;
}

.number-area .now-custome {
  margin-left: 15px;
  .text {
    display: inline-block;
    vertical-align: middle;
    color: #666666;
    font-size: 14px;
  }
  .dot {
    position: relative;
    top: -2px;
    display: inline-block;
    margin-right: 10px;
    width: 8px;
    height: 2px;
    background-color: #1890ff;
    border-radius: 2px;
  }
  .special-dot {
    background-color: #ff9966;
  }
}`