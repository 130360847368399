import React, { PureComponent } from "react";
import { connect } from "react-redux";
import {
  Tabs,
  Select,
  Input,
  Modal,
  Button,
  Tooltip,
  Table,
  Message,
  Icon,
  Collapse
} from "antd";
import underscore from "underscore";
import global from "store/reducers/global";
import PositionVacantModal from "store/reducers/positionVacantM";
import socialCandModel from "store/reducers/socialCandModel";
import intern from "store/reducers/internModel";
import InternDetail from "pages/TalentPool/Intern/Detail";
import SocialDetail from "pages/TalentPool/SocialCandidate/Detail";
import fileDownload from "utils/fileDownload.js";
import CustomIcon from "components/CustomIcon";
import ProgressDialog from "./progress-dialog";
import CommunicateModal from "pages/TalentPool/CommunicateModal";
import { history } from "root.js";
import PreviewTool from "components/PreviewTool";
import RecommendEmailSend from "../recommendCandidate/RecommendEmailSend";
import styles from "./progress-module.scss";

const confirm = Modal.confirm;
const TabPane = Tabs.TabPane;
const Option = Select.Option;
const TextArea = Input.TextArea;
const InputGroup = Input.Group;
const Panel = Collapse.Panel;

/**
 * 人选进展操作流
 */
@connect(
  ({ PositionVacantModal, user, socialCandidate, intern }) => {
    return {
      ...PositionVacantModal,
      roles: user.roles,
      socialRecords: socialCandidate.candidate.communicationRecords,
      internRecords: intern.candidate.communicationRecords
    };
  },
  {
    ...PositionVacantModal.actions,
    updateSocialRecords: socialCandModel.actions.updateCommunicationRecords,
    updateInternRecords: intern.actions.updateCommunicationRecords,
    addSocialCommunicate: socialCandModel.actions.addCommunicate,
    addInternCommunicate: intern.actions.addCommunicate,
    getSocialJDList: socialCandModel.actions.getJDList,
    getInternJDList: intern.actions.getJDList,
    updateStack: global.actions.updateDrawerStack
  }
)
export default class ProgressProfile extends PureComponent {
  state = {
    currentPage: 1,
    currentType: -1, // 人选进展当前选择状态
    showCommunicateModal: 0, // 展示实习生还是社会人选的沟通记录
    candidateId: 0, // 沟通记录。当前人选id
    candidate: {}, // 沟通记录。当前人选
    suitId: 0, // -标记不合适 jdprofileid
    openStatus: 0, // 1,2,3,4,5,6共六种状态
    personInfo: {}, // 操作人信息
    jdProfileId: 0, // table选中人选id
    communicateJDPId: 0, // 沟通记录的 jdprofileid
    selectRows: [], // 选中的人选
    previewVisible: false, // 查看报告显示
    statusColor: [
      "#38a4e9",
      "#38a4e9",
      "#fa9965",
      "#52c41a",
      "#5fccc3",
      "#ff4b54",
      "#999999",
      "#999999"
    ], //0.已推荐 1.已预约 2.安排面试 3.面试通过 4.offer时间 5.onboard 6.已淘汰
    outSelVal: -10,
    nameVal: "",
  };

  componentDidUpdate(prevProps) {
    const { statusParamCandidate, positionDetail } = this.props;
    // 当前人选进展更新
    if (positionDetail.id != prevProps.positionDetail.id) {
      this.getCandidateList();
    }
    if (statusParamCandidate.status != prevProps.statusParamCandidate.status) {
      this.getCandidateList({page: 1, keyword: "", status: statusParamCandidate.status});
      this.setState({
        currentType: statusParamCandidate.status,
        selectRows: [],
        outSelVal: -10,
        nameVal: "",
      });
    }
  }

  getCandidateList = param => {
    const {
      getCandidateList,
      positionDetail,
      getCandidateProgress,
      setUpdateState
    } = this.props;
    if (!positionDetail.id) return;

    getCandidateList(positionDetail.id, param).then(res => {
      if (res.code === 0) {
        // 人选有，默认取第一个
        if (res.data.list && res.data.list.length > 0) {
          getCandidateProgress({ jd_profile_id: res.data.list[0].id });
          this.setState({
            jdProfileId: res.data.list[0].id
          });
        } else {
          setUpdateState({
            filed: "candidatePersonProgress",
            value: {}
          });
          setUpdateState({
            filed: "candidateProgressLoading",
            value: false
          });
        }
      }
    });
  };

  /**
   * @description 获取候选人详细进展
   * @param {number} jd_profile_id   简历的id
   */
  getCandidate = jd_profile_id => {
    if (jd_profile_id === this.state.jdProfileId) return;
    const { getCandidateProgress } = this.props;
    if (jd_profile_id) {
      getCandidateProgress({ jd_profile_id });
      this.setState({ jdProfileId: jd_profile_id });
    }
  };

  /**
   * @description 去简历详情
   * @param {number} id   当前候选人状态
   */
  onGoDetail = (id, intern, e) => {
    e.stopPropagation();
    const { updateStack } = this.props;
    updateStack({
      type: "add",
      componentRef: intern ? InternDetail : SocialDetail,
      componentProps: {
        onClose: () => updateStack({ type: "pop" }),
        stackVisible: true,
        candidateId: id
      }
    });
  };

  onRecommend = e => {
    e.stopPropagation();
  };

  /**
   * @description 展示编辑候选人操作
   * @param {number} type
   */
  showModal = type => {
    this.setState({ openStatus: type });
  };

  onChangeRecommend = (
    name,
    id,
    profile_no,
    performance,
    is_renew,
    hh_org_info,
    item,
    val
  ) => {
    console.log("valvalval", val);
    this.showModal(val);
    this.setState({
      personInfo: {
        name,
        id,
        profile_no,
        performance,
        is_renew,
        hh_org_info,
        last_event_time: item.last_event_time
      }
    });
  };

  /**
   * @description 判断参数（由于status和改变状态id不对应）
   * @param {number} status   当前候选人状态， 看是否是onboard状态，若是把status改为7选中已离职
   */
  onRewirting = status => {
    console.log("status", status);
    if (status === 0 || status === 1) {
      return 2;
    }
    return status === 5 ? 7 : status + 1;
  };

  /**
   * @description 下载报告
   * @param {number} profile_id
   */
  downloadReport = (profile_id, e) => {
    if (e) e.stopPropagation();
    const { id } = this.props.positionDetail;
    fileDownload(
      `admin/v1/profile-manage/download-jd?&profile_id=${profile_id}&jd_id=${id}`
    );
  };

  /**
   * @description 展示候选人操作状态
   * @param {number} type //此操作是在onboard和离职下的一个特殊情况，
   */
  showHandleModal = (
    name,
    id,
    profile_no,
    performance,
    is_renew,
    type,
    hh_org_info,
    item,
    e
  ) => {
    if (e) e.stopPropagation();
    this.setState({
      personInfo: {
        name,
        id,
        profile_no,
        performance,
        is_renew,
        hh_org_info,
        last_event_time: item.last_event_time
      }
    });
    this.showModal(type);
  };

  /**
   * 添加沟通记录展示
   */
  showCommunicateModal = item => {
    const { profile_info } = item;
    const {
      updateInternRecords,
      updateSocialRecords,
      positionDetail
    } = this.props;
    this.setState(
      {
        showCommunicateModal: profile_info.is_intern,
        candidateId: profile_info.id,
        communicateJDPId: item.id,
        currentPosition: {
          id: positionDetail.id,
          name: positionDetail.post_name
        }
      },
      () => {
        if (profile_info.is_intern) {
          updateInternRecords({ addModal: true });
        } else {
          updateSocialRecords({ addModal: true });
        }
      }
    );
  };

  /** 推荐记录查询 */
  getCandidateProgress = () => {
    const { getCandidateProgress } = this.props;
    const { communicateJDPId, jdProfileId } = this.state;
    if (communicateJDPId != jdProfileId) return;
    getCandidateProgress({ jd_profile_id: communicateJDPId });
  };

  totalRecommend = () => {
    const { selectRows } = this.state;
    const { positionDetail = {} } = this.props;
    if (selectRows.length === 0) {
      // Message.error("至少选择一位人选");
      return;
    }

    history.push(
      "/position/vacant/recommend/" +
        positionDetail.id +
        "?ids=" +
        selectRows.join("-")
    );
  };

  // 单个推荐
  recommendSingle = (e, item) => {
    e.stopPropagation();
    const { positionDetail = {} } = this.props;
    history.push(
      "/position/vacant/recommend/" +
        positionDetail.id +
        "?ids=" +
        item.profile_id
    );
  };

  changeOutVal = (value, option) => {
    this.setState({
      outSelVal: value
    });
    this.getCandidateList({ page: 1, status: value });
  };

  nameChange = (e) => {
    this.setState({
      nameVal: e.target.value
    });
  }
  nameSearch = (value) => {
    
    this.getCandidateList({page: 1, keyword: value})
  }

  createColumns = () => {
    const { positionDetail } = this.props;
    let operateTitle = null;
    // 待筛选
    if (
      this.state.currentType === -1 &&
      positionDetail.screen_permission &&
      positionDetail.status === 2
    ) {
      operateTitle = (
        <div
          className={`total-header ${
            this.state.selectRows.length !== 0 ? "can-use" : ""
          }`}
          onClick={this.totalRecommend}
        >
          批量推荐给客户
        </div>
      );
    }
    if (this.state.currentType === -10) {
      operateTitle = (
        <Select
          placeholder="切换状态"
          size="small"
          onChange={this.changeOutVal}
          value={this.state.outSelVal}
          style={{ width: "110px" }}
        >
          <Option value={-10}>全部</Option>
          <Option value={6}>已出局</Option>
          <Option value={-2}>不合适</Option>
          <Option value={7}>已离职</Option>
        </Select>
      );
    }
    const searchTitle = (
      <React.Fragment>
        <span>人选</span>
        <Input.Search onSearch={this.nameSearch} onChange={this.nameChange} value={this.state.nameVal} 
          className="inner-search" placeholder="输入人选姓名搜索" size="small"/>
      </React.Fragment>
    )
    return [
      {
        title: searchTitle,
        align: "left",

        width: 232,
        render: (text, item) => {
          return (
            <div className="profile-info">
              <div
                className="name"
                onClick={this.onGoDetail.bind(
                  this,
                  item.profile_id,
                  item.profile_info.is_intern
                )}
              >
                <span className="bold">{item.profile_info.name}</span>
                <span title={item.profile_info.profile_no}>
                  【 ID：
                  {item.profile_info.profile_no}】
                </span>
                {/**系统推荐 */ item.type === 2 && (
                  <img
                    className="system-icon"
                    src={require("assets/imgs/ic_xttj.png")}
                  />
                )}
                {/**客户预约 */ item.type === 1 && (
                  <img
                    className="reserve-icon"
                    src={require("assets/imgs/ic_khyy.png")}
                  />
                )}
              </div>
              {item.profile_info.is_intern === 1 ? (
                <div className="base">
                  {item.profile_info.education}
                  {item.profile_info.education && item.profile_info.major
                    ? " · "
                    : ""}
                  {item.profile_info.major}
                </div>
              ) : (
                <div className="base">
                  {item.profile_info.education}
                  {item.profile_info.education && item.profile_info.worktime
                    ? " · "
                    : ""}
                  {item.profile_info.worktime &&
                    `${item.profile_info.worktime}年经验`}
                </div>
              )}
              {item.profile_info.is_intern === 1 ? (
                <div
                  className="base"
                  title={`${item.profile_info.graduation_time}`}
                >
                  {`${item.profile_info.graduation_time}${
                    item.profile_info.graduation_time ? "月毕业" : ""
                  }`}
                </div>
              ) : (
                <div
                  className="base"
                  title={`${item.profile_info.salary_expect}`}
                >
                  {item.profile_info.marry}
                  {item.profile_info.marry && item.profile_info.salary_expect
                    ? " · "
                    : ""}
                  {item.profile_info.salary_expect
                    ? `期望薪资${item.profile_info.salary_expect}`
                    : ""}
                </div>
              )}
            </div>
          );
        }
      },
      {
        title: operateTitle,
        align: "right",

        render: (text, item, index) => {
          const { status } = this.props.statusParamCandidate;
          const { positionDetail } = this.props;
          /** 人选状态不同 操作不同 */
          return (
            <div className="operate-td">
              <div className="handle-area">
                {/**onboard状态 */ item.status === 5 && (
                  <span
                    onClick={this.showHandleModal.bind(
                      this,
                      item.profile_info.name,
                      item.id,
                      item.profile_info.profile_no,
                      item.performance,
                      item.is_renew,
                      5,
                      item.hh_org_info,
                      item
                    )}
                    className="text-right"
                  >
                    <Tooltip
                      title={item.is_renew ? "续签" : "入职到岗"}
                      placement="top"
                    >
                      <svg className="icon" aria-hidden="true">
                        <use xlinkHref="#icon-ic_1" />
                      </svg>
                    </Tooltip>
                  </span>
                )}
                {/**不合适、已出局状态 */ (item.status === -2 ||
                  item.status === 6) &&
                item.re_recommend_permission ? (
                  <span
                    onClick={e => this.recommendLeave(e, item, index)}
                    className="text-right"
                  >
                    <Tooltip title="推荐候选人至客户" placement="top">
                      <svg className="icon" aria-hidden="true">
                        <use xlinkHref="#icon-nav-meidusha-tuisong" />
                      </svg>
                    </Tooltip>
                  </span>
                ) : null}
                {/**已离职状态 */ item.status === 7 && (
                  <span
                    onClick={this.showHandleModal.bind(
                      this,
                      item.profile_info.name,
                      item.id,
                      item.profile_info.profile_no,
                      item.performance,
                      item.is_renew,
                      7,
                      item.hh_org_info,
                      item
                    )}
                    className="text-right"
                  >
                    <Tooltip title="离职" placement="top">
                      <svg className="icon" aria-hidden="true">
                        <use xlinkHref="#icon-ic_4" />
                      </svg>
                    </Tooltip>
                  </span>
                )}
                <Tooltip title="下载报告" placement="top">
                  <Icon
                    className="icon"
                    onClick={this.downloadReport.bind(this, item.profile_id)}
                    type="arrow-down"
                  />
                </Tooltip>
                <Tooltip title="添加沟通记录" placement="top">
                  <CustomIcon
                    className="icon"
                    type="icon-menu"
                    onClick={() => this.showCommunicateModal(item)}
                  />
                </Tooltip>
              </div>

              {/** 待筛选以外 */
              item.status < 6 &&
                item.status >= 0 &&
                (this.props.positionDetail.status !== 3 &&
                this.props.positionDetail.status !== 4 &&
                this.props.positionDetail.status !== 5 ? (
                  <div onClick={this.onRecommend.bind(this)}>
                    <Select
                      placeholder="切换状态"
                      size="small"
                      onSelect={this.onChangeRecommend.bind(
                        this,
                        item.profile_info.name,
                        item.id,
                        item.profile_info.profile_no,
                        item.performance,
                        item.is_renew,
                        item.hh_org_info,
                        item
                      )}
                      value={this.onRewirting(item.status)}
                      style={{ width: "110px" }}
                    >
                      {item.status_switch.map((itemOption, index) => {
                        return (
                          <Option key={index} value={itemOption.id}>
                            {itemOption.name}
                          </Option>
                        );
                      })}
                    </Select>
                  </div>
                ) : (
                  <div style={{ marginTop: "10px" }}>
                    <span
                      className="dot"
                      style={{
                        background: this.state.statusColor[item.status]
                          ? this.state.statusColor[item.status]
                          : "#5080ef"
                      }}
                    />
                    {item.status_name}
                  </div>
                ))}
              {/** 待筛选 */
              item.status === -1 && (
                <div className="filter-operate">
                  {item.is_suitable === 1 ? (
                    <React.Fragment>
                      {/** 是否有权限操作 */
                      positionDetail.screen_permission &&
                      positionDetail.status === 2 ? (
                        <span onClick={e => this.lookReport(e, item, index)}>
                          查看报告
                        </span>
                      ) : null}
                      {/* <span onClick={e => this.recommendSingle(e, item)}>推荐</span> */}
                    </React.Fragment>
                  ) : (
                    <span style={{ cursor: "default", color: "#999" }}>
                      不合适
                    </span>
                  )}
                </div>
              )}
              {/** 已淘汰页签 */
              status === -10 && (
                <div className="filter-operate">
                  {item.status === -2 && (
                    <span style={{ cursor: "default", color: "#999" }}>
                      【不合适】
                    </span>
                  )}
                  {item.status === 6 && (
                    <span style={{ cursor: "default", color: "#999" }}>
                      【已出局】
                    </span>
                  )}
                  {item.status === 7 && (
                    <span style={{ cursor: "default", color: "#999" }}>
                      【已离职】
                    </span>
                  )}
                </div>
              )}
            </div>
          );
        }
      }
    ];
  };

  recommendLeave = (e, item, index) => {
    e.stopPropagation();
    this.setState(
      {
        candidate: item
      },
      () => {
        this.nowRecommend();
      }
    );
  };

  // 查看报告
  lookReport = (e, item, index) => {
    e.stopPropagation();
    this.setState({
      previewVisible: true,
      suitId: item.id,
      candidateId: item.profile_id,
      candidateIndex: index,
      candidate: item
    });
  };
  // 查看报告页推荐
  nowRecommend = () => {
    const { setUpdateState, recommendInfo, positionDetail } = this.props;
    const {
      jd_id,
      profile_id,
      report = {},
      profile_sys_report = {},
      status
    } = this.state.candidate;
    console.log(this.state.candidate, "this.state.candidate");
    let emails = [];
    if (positionDetail.contactor && positionDetail.contactor.email) {
      emails.push(positionDetail.contactor.email);
    }
    if (positionDetail.hh_email !== "") emails.push(positionDetail.hh_email);
    setUpdateState({
      filed: "recommendInfo",
      value: {
        ...recommendInfo,
        recommendParam: {
          jd_ids: [jd_id],
          emails: emails,
          profile_ids: [profile_id],
          report: [status === 6 ? profile_sys_report : report]
        },
        emailDialogStatus: true
      }
    });
  };
  /** 标记不合适 */
  markSuit = () => {
    let that = this;
    const { markProfile, setUpdateState } = this.props;
    const { suitId, candidateIndex, currentPage } = this.state;
    confirm({
      title: "确定将该人选标记为不合适?",
      content: "标记不合适后将无法进行下一步操作",
      okText: "确定",
      cancelText: "取消",
      onOk() {
        markProfile({ jd_profile_id: suitId, index: candidateIndex }).then(
          res => {
            if (res.code === 0) {
              that.setState({
                previewVisible: false
              });
              that.getCandidateList({ page: currentPage });
              that.searchJdDetail();
              setUpdateState({
                filed: "profileNumUpdate",
                value: true
              });
            }
          }
        );
      },
      onCancel() {}
    });
  };

  pageChange = (page, pageSize) => {
    this.getCandidateList({ page: page });
    this.setState({
      currentPage: page
    });
  };

  /** 推荐回调 */
  sendCallBack = () => {
    // 切换状态为已推荐
    const { setUpdateState } = this.props;
    setUpdateState({ filed: "statusParamCandidate", value: { status: 1 } });
    this.setState({
      previewVisible: false
    });
    setUpdateState({
      filed: "profileNumUpdate",
      value: true
    });
  };
  searchJdDetail = () => {
    this.props.getDetail(this.props.positionDetail.id);
  };

  render() {
    const {
      personInfo,
      openStatus,
      showCommunicateModal,
      candidateId,
      jdProfileId,
      selectRows,
      previewVisible,
      currentPosition,
      candidate
    } = this.state;
    const {
      positionDetail = {},
      recommendInfo = {},
      candidateData,
      className = "",
      getInternJDList,
      internRecords,
      addInternCommunicate,
      updateInternRecords,
      getSocialJDList,
      socialRecords,
      addSocialCommunicate,
      updateSocialRecords,
      candidateDataLoading,
      detailHeight
    } = this.props;
    const { status } = this.props.statusParamCandidate;
    const rowSelection = {
      selectedRowKeys: selectRows,
      columnWidth: 48,
      onChange: (selectedRowKeys, selectedRows) => {
        let nosuits = selectedRows
          .filter(item => item.is_suitable !== 1)
          .map(item => item.profile_id);
        let sels = underscore.difference(selectedRowKeys, nosuits); //排除不合适
        if (sels.length > 10) {
          Message.error("一次最多可选择10位候选人");
          return;
        }
        this.setState({
          selectRows: sels
        });
      }
      // onSelect: (record, selected, selectedRows, nativeEvent) => {
      //   if (record.is_suitable === 2) {
      //     // Message.error("标记为不合适的候选人不可以操作");
      //   }
      // }
    };
    const paginationProps = {
      total: candidateData._meta && candidateData._meta.totalCount,
      current: candidateData._meta && candidateData._meta.currentPage,
      size: "small",
      showTotal: total => `共${total}条`,
      showQuickJumper: true,
      onChange: this.pageChange
    };
    return (
      <div className={`${className} profile-container `}>
        <Table
          rowKey={(record,index )=> record.profile_id}
          className="progress-profile-table"
          rowClassName={(row, index) => {
            if (row.id == jdProfileId) return ` row-sel`;
          }}
          columns={this.createColumns()}
          dataSource={candidateData.list || []}
          needRowSel={true}
          pagination={paginationProps}
          rowSelection={rowSelection}
          scroll={{ y: detailHeight - 176 }}
          loading={candidateDataLoading}
          onRow={record => {
            return {
              onClick: () => {
                this.getCandidate(record.id);
              }
            };
          }}
        />
        {/** 人选流程 */ openStatus > 0 && (
          <ProgressDialog
            parentMethod={this}
            openStatus={openStatus}
            personInfo={personInfo}
            hh_id={positionDetail.hh_id}
            statusTab={status}
            needProgress={this.getCandidateList}
          />
        )}
        {/** 沟通记录 */ showCommunicateModal ? (
          <CommunicateModal
            getJDList={getInternJDList}
            communicationRecords={internRecords}
            addCommunicate={addInternCommunicate}
            position={currentPosition}
            candidateId={candidateId}
            updateCommunicationRecords={updateInternRecords}
            callBack={this.getCandidateProgress}
          />
        ) : (
          <CommunicateModal
            getJDList={getSocialJDList}
            communicationRecords={socialRecords}
            position={currentPosition}
            addCommunicate={addSocialCommunicate}
            candidateId={candidateId}
            updateCommunicationRecords={updateSocialRecords}
            callBack={this.getCandidateProgress}
          />
        )}
        {/** 查看报告 */ previewVisible && (
          <div className="report-view">
            <div className="report-view-mask" />
            <div className="report-view-wrapper">
              <PreviewTool
                onClick={e => e.stopPropagation()}
                iframeSrc={candidate.report ? candidate.report.patch : ""}
                className={`preview`}
              />
              <div
                className="bottom-operate"
                onClick={e => e.stopPropagation()}
              >
                <span className="ellipsis remark" title={candidate.remark}>
                  前台备注({candidate.create_by_user}): {candidate.remark}
                </span>
                <span>
                  <Button type="primary" onClick={this.markSuit}>
                    标记不合适
                  </Button>
                  <Button type="primary" onClick={this.nowRecommend}>
                    推荐给客户
                  </Button>
                </span>
              </div>
              <Icon
                type="close-circle"
                className="close-icon"
                onClick={() => this.setState({ previewVisible: false })}
              />
            </div>
          </div>
        )}
        {/* isTypeStatus  这个状态为了在单独推荐时候不要去改变推荐页面active */}
        {recommendInfo.emailDialogStatus && (
          <RecommendEmailSend
            sendCallBack={this.sendCallBack}
            isTypeStatus={true}
            searchJdDetail={this.searchJdDetail}
          />
        )}
        <style jsx>{styles}</style>
      </div>
    );
  }
}
