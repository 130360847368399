
import axios from "axios";
import xhr from "./index";

function loginReq(param) {
  return xhr({ url: "/admin/v1/user/login", body: param, method: "POST" });
}

function isLoginReq(param) {
  return xhr({ url: "/admin/v1/user/is-login", body: param, method: "GET" });
}

function logoutReq() {
  return xhr({ url: "/admin/v1/user/logout", method: "GET" });
}

function restPwd(param) {
  return xhr({ url: "admin/v1/user/rest-pwd", body: param, method: "POST" });
}

function manageTalentFolder(param) {
  return xhr({ url: "admin/v1/profile-manage/lib", body: param, method: "POST" });
}

function deleteTalentFolder(param) {
  return xhr({ url: "admin/v1/profile-manage/lib-del", body: param, method: "POST" });
}

function modifyTalentFolder(param) {
  return xhr({ url: "admin/v1/profile-manage/lib-profile", body: param, method: "POST" });
}


function sendMsg(param) {
 return xhr({ url: 'admin/v1/common/send-msg', body: param, method: 'POST' })
}

function resetPassword(param) {
  return xhr({ url: 'admin/v1/user/forgot-password', body: param, method: 'POST' })
}

export { loginReq, isLoginReq, logoutReq, restPwd, manageTalentFolder, deleteTalentFolder, modifyTalentFolder, sendMsg, resetPassword };
