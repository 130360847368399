import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { Row, Col, Form, Input, DatePicker, Select } from "antd";
import { EditViewSingle, EditViewMulti } from "components/EditView";

import internModel from "store/reducers/internModel";
import { commonData } from "services/commonService";
import _ from "underscore";
import moment from "moment";
import updateCompletewrapper from "./update-complete-wrapper";

const FormItem = Form.Item;
const { RangePicker } = DatePicker;
const { TextArea } = Input;

function mapPropsToFields(props) {
  const { item = {} } = props;
  const formFields = _.mapObject(item, function(val, key) {
    return Form.createFormField({
      // value: val && val
      ...val,
      value: val && val.value
    });
  });
  return formFields;
}

export function fieldsToProps(values) {
  return values;
}

export function onFieldsChange(props, changedFields) {
  props.onChange(changedFields);
}

export function createPersonalWorkEditForm(mapPropsToFields, fieldsToProps, onFieldsChange) {
  return Form.create({
    mapPropsToFields, onFieldsChange
  })(
    class EditForm extends PureComponent {
      handleSubmit = e => {
        return new Promise((resolve, reject) => {
          this.props.form.validateFieldsAndScroll({scroll: {offsetBottom: 60}},(err, values) => {
            if (!err) {
              resolve(fieldsToProps(values));
            } else {
              reject(err);
            }
          });
        });
      };

      render() {
        const formItemLayout = {
          labelCol: { span: 5 },
          wrapperCol: { span: 19 }
        };

        const { getFieldDecorator } = this.props.form;
        return (
          <div className="personal-work-edit">
            <Form
              onSubmit={this.handleSubmit}
              className="edit-form"
              layout="horizontal"
            >
              <Row className="one" gutter={35}>
                <Col span={12}>
                  <FormItem label="作品名称" {...formItemLayout}>
                    {getFieldDecorator("works_name", {
                      rules: [
                        {
                          required: true,
                          max: 20,
                          message: "请输入作品名称，且最大20个字符"
                        }
                      ]
                    })(<Input placeholder="请输入作品名称" size="large" />)}
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem label="作品链接" {...formItemLayout}>
                    {getFieldDecorator("works_link", {
                      rules: [{ required: true, message: "请输入作品链接" }]
                    })(<Input placeholder="请输入作品链接" size="large" />)}
                  </FormItem>
                </Col>
              </Row>
              <Row className="five" gutter={35}>
                <Col span={24}>
                  <FormItem
                    label="作品简介"
                    labelCol={{ span: 2 }}
                    wrapperCol={{ span: 22 }}
                  >
                    {getFieldDecorator("works_introduction", {
                      rules: []
                    })(
                      <TextArea
                        placeholder="作品简介"
                        autosize={{ minRows: 2, maxRows: 6 }}
                      />
                    )}
                  </FormItem>
                </Col>
              </Row>
            </Form>
            <style jsx>
              {`
                .personal-work-edit {
                  :global(.five) {
                    :global(.ant-form-item-label) {
                      width: 10%;
                    }
                    :global(.ant-form-item-control-wrapper) {
                      width: 90%;
                      :global(.ant-input) {
                        height: 100px;
                        resize: none;
                      }
                    }
                  }
                }
              `}
            </style>
          </div>
        );
      }
    }
  );
}

export const PersonalWorkEditForm = createPersonalWorkEditForm(
  mapPropsToFields,
  fieldsToProps
);

export const newPersonalWorkEditForm = createPersonalWorkEditForm(
  mapPropsToFields,
  fieldsToProps,
  onFieldsChange
);

export function PersonalWorkView(props) {
  let { item } = props;
  item = _.mapObject(item, (val, key) => {
    return val && val.value;
  });
  return (
    <div className="personal-work-view" style={{ width: "91%" }}>
      <Row gutter={10} className="personal-work-item">
        <div className="name">作品名称 : {item.works_name}</div>
        <div className="link">
          作品链接 : <a href={item.works_link}>{item.works_link}</a>
        </div>
        <div className="job-content">
          <div>作品简介 : </div>
          <pre>{item.works_introduction}</pre>
        </div>
      </Row>
      <style jsx>
        {`
          .personal-work-view {
            :global(.personal-work-item) {
              margin-bottom: 28px;
              &:last-child {
                margin-bottom: 0;
              }
              .name,
              .link {
                font-weight: 600;
                margin-bottom: 20px;
              }
              .job-content {
                > div {
                  font-weight: 600;
                }
                p {
                  line-height: 28px;
                  margin-bottom: 0;
                }
              }
            }
            :global(.ant-row) {
              margin-bottom: 18px;
              color: #666;
            }
            :global(.personal-work-title) {
              font-weight: 600;
            }
          }
        `}
      </style>
    </div>
  );
}

class PersonalWork extends PureComponent {
  state = {
    topPlaceholderBg: "#ffffff",
    bottomPlaceholderBg: "#ffffff"
  };

  formRef = [];

  setPlaceholderColor = (index, color) => {
    const { personalWorks } = this.props;
    index === personalWorks.length - 1
      ? this.setState({ bottomPlaceholderBg: color })
      : !1;
    index === 0 ? this.setState({ topPlaceholderBg: color }) : !1;
  };

  onEdit = index => {
    this.setPlaceholderColor(index, "#f0f0f0");
    this.props.updatePersonalWork({ index, values: { isEdit: true } });
  };

  onCancel = index => {
    this.setPlaceholderColor(index, "#ffffff");
    this.props.cancelPersonalWork(index);
  };

  onSave = index => {
    const { savePersonalWork } = this.props;
    this.formRef[index].handleSubmit().then(values => {
      savePersonalWork(index, values)
        .then(res => {
          if (res.code === 0) this.setPlaceholderColor(index, "#ffffff");
          return res;
        })
        .then(res => (!res.code ? res.data.compete : null))
        .then(this.props.toUpdateComplete);
    });
  };

  onDelete = index => {
    this.props
      .removePersonalWork(index)
      .then(res => (!res.code ? res.data.compete : null))
      .then(this.props.toUpdateComplete);
  };

  onAdd = () => {
    const { personalWorks } = this.props;
    if (!personalWorks.length) {
      this.setPlaceholderColor(-1, "#f0f0f0");
      this.setPlaceholderColor(0, "#f0f0f0");
    } else {
      this.setState({ bottomPlaceholderBg: "#f0f0f0" });
    }
    this.props.addPersonalWork({ limit: true });
  };

  render() {
    const { topPlaceholderBg, bottomPlaceholderBg } = this.state;
    const { personalWorks } = this.props;
    return (
      <EditViewMulti
        className="job-experience"
        title="个人作品"
        onAdd={this.onAdd}
      >
        <div
          className="placeholder"
          style={{ background: topPlaceholderBg, height: 20 }}
        />
        {personalWorks.map((item, index) => (
          <EditViewSingle
            className={`${index === personalWorks.length - 1 ? "last" : ""}`}
            isView={!item.isEdit}
            onEdit={() => this.onEdit(index)}
            onCancel={() => this.onCancel(index)}
            onSave={() => this.onSave(index)}
            onDelete={() => this.onDelete(index)}
            key={item.works_id}
          >
            <div role="view" className="view">
              <PersonalWorkView item={item} />
            </div>
            <div role="edit" className="edit">
              <PersonalWorkEditForm
                item={item}
                wrappedComponentRef={inst => (this.formRef[index] = inst)}
              />
            </div>
          </EditViewSingle>
        ))}
        <div
          className="placeholder"
          style={{ background: bottomPlaceholderBg, height: 20 }}
        />
      </EditViewMulti>
    );
  }
}

export default connect(
  ({ intern }) => {
    return {
      personalWorks: intern.candidate.detail.personalWorks || []
    };
  },
  { ...internModel.actions }
)(updateCompletewrapper(PersonalWork));
