import Model from "../Model";
import * as userManageS from "services/userManageS";
export default Model.getInstance(
  class extends Model {
    namespace = "UserManageModal";
    state = {
      visible: false,
      dataList: {},
      detail: {},
      statisticsData: {},
      loadingStatus: false //加载状态
    };
    actions = {
      async getList(param = {}) {
        const expand = "extra_fields";
        this.dispatch({
          type: "UserManageModal/setUpdateState",
          payload: { filed: "loadingStatus", value: true }
        });
        let result = await userManageS.userManageList({ ...param, expand });
        if (result.code === 0) {
          this.dispatch({
            type: "UserManageModal/setUpdateState",
            payload: {
              filed: "dataList",
              value: { ...result.data }
            }
          });
        }
        this.dispatch({
          type: "UserManageModal/setUpdateState",
          payload: { filed: "loadingStatus", value: false }
        });
      },
      async getDetail(param) {
        let result = await userManageS.userManageDetail({ ...param });
        this.dispatch({
          type: "UserManageModal/setUpdateState",
          payload: {
            filed: "detail",
            value: { ...this.getState().UserManageModal.detail, ...result.data }
          }
        });
        return result;
      },
      async handleUserAttestation(param = {}) {
        let result = await userManageS.userAttestation({ ...param });
        return result;
      },
      async editCompany(param = {}) {
        let result = await userManageS.userEditCompany({ ...param });
        return result;
      },
      async addState(param) {
        this.dispatch({
          type: "UserManageModal/setUpdateState",
          payload: param
        });
      }
    };

    reducers = {
      /**
       * @description 设置更新列表、详情、抽屉等状态
       */
      setUpdateState(state, { payload }) {
        const { filed, value } = payload;
        state[filed] = value;
        return { ...state };
      }
    };
  }
);
