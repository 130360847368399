import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { Modal, Radio, Input } from "antd";
import candidateAudit from "store/reducers/candidateAudit";

const RadioGroup = Radio.Group;
const { TextArea } = Input;

@connect(
  ({ candidateAudit }) => {
    return {
      ...candidateAudit
    };
  },
  {
    ...candidateAudit.actions,
  }
)
export default class AuditModal extends PureComponent { 

  onChange = e => {
    this.props.updateAuditResult({ auditResult: e.target.value });
  };

  onRejectReasonChange = e => {
    this.props.updateAuditResult({ rejectReason: e.target.value });
  };

  handleCancel = () => {
    this.props.updateAuditModel(false);
  };

  handleOk = () => {
    const {auditTarget, auditCallBack} = this.props;
    this.props.audit(auditTarget).then(res => {
      if (auditCallBack) {
        auditCallBack(res);
      } else {
        if (res.code === 0) {
          this.props.getAuditList({
            "per-page": this.pageSize || 10,
            page: this.currentPage || 1
          });
        }
      }
    });
  };

  render() {
    const {
      auditModel,
      auditResult,
      rejectReason,
    } = this.props;
    return (
      <Modal
        title="状态审核"
        visible={auditModel}
        onOk={this.handleOk}
        onCancel={this.handleCancel}
        className="cadidate-audit-modal"
      >
        <p style={{ marginBottom: 30 }}>是否通过该人选状态审核？</p>
        <RadioGroup onChange={this.onChange} value={auditResult}>
          <Radio value={1} style={{ marginRight: 50 }}>
            通过
          </Radio>
          <Radio value={-1}>拒绝</Radio>
        </RadioGroup>
        {auditResult === -1 && (
          <div className="reject-reason">
            <p>请填写拒绝理由</p>
            <TextArea
              value={rejectReason}
              onChange={this.onRejectReasonChange}
            />
          </div>
        )}
        <style jsx global>
          {`
            .cadidate-audit-modal {
              .reject-reason {
                margin-top: 10px;
                p {
                  margin-bottom: 5px;
                }
              }
            }
          `}
        </style>
      </Modal>
    )
  }
}