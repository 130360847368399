import React, { PureComponent } from "react";
import { Upload, Icon, message, Avatar, Button } from "antd";
import classnames from "classnames";

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
}

/**
 * 头像提交前默认处理
 */
function defaultBeforeUpload(file) {
  const isJPG = file.type === "image/jpeg" || file.type === "image/png";
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isJPG || !isLt2M) {
    message.error("请上传jpg、png格式图片，且不超过2M");
  }
  return isJPG && isLt2M;
}

/**
 * 用户头像上传
 */
export default class LogoUpload extends PureComponent {
  state = {
    fileList: [],
    fileUrl: "",
    loading: false,
    uploadVisible: false
  };

  componentWillUnmount() {
    this.setState({
      fileList: [],
      fileUrl: "",
    })
  }

  handleChange = info => {
    const { onChange } = this.props;
    console.log("info", info)
    if (info.file.status === "uploading") {
      this.setState({ loading: true, fileList: info.fileList });
      return;
    }
    if (info.file.status === "done") {
      const res = info.file.response;
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, imageUrl => onChange(res, imageUrl));
    } else {
      // message.error("上传头像失败");
    }
    console.log("info", info)
    this.setState({ uploadVisible: false, loading: false, fileList: info.fileList });
  };

  // deleteAvatar = e => {
  //   e.stopPropagation();
  //   const { deletefunc, data, onDelete } = this.props;
  //   deletefunc(data).then(res => {
  //     if (onDelete) {
  //       onDelete(res);
  //     }
  //   }); 
  // };

  deleteAvatar = e => {
    e.stopPropagation();
    const { onChange } = this.props;
    onChange(null);
    this.setState({ fileList: [] });
  };

  onMouseEnter = () => {
    this.setState({ uploadVisible: true });
  };

  onMouseLeave = () => {
    this.setState({ uploadVisible: false });
  };

  render() {
    let {
      action,
      alt = "用户头像",
      size = 100,
      width = 100,
      height = 120,
      className,
      value,
      shape = "square",
      beforeUpload,
      deletefunc,
      defaultAvatar,
      ...props
    } = this.props;
    const { uploadVisible, fileList, fileUrl } = this.state;
    beforeUpload = beforeUpload || defaultBeforeUpload;
    console.log("typetype", fileList, fileUrl, value)
    return (
      <div
        className={classnames("user-logo-upload", className)}
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
      >
        <Upload
          {...props}
          listType="picture-card"
          className="logo-uploader"
          showUploadList={false}
          action={action}
          beforeUpload={beforeUpload}
          fileList={fileList}
          onChange={this.handleChange}
        >
          {
            value ?
              <React.Fragment>
                <Avatar shape="square" size={60} src={value ? value : require("assets/imgs/default-company.png")}/>
                {uploadVisible && <Icon type="upload" className="upload-icon"></Icon>}
                <span className="del-link" onClick={this.deleteAvatar}>删除</span>
              </React.Fragment>
              :
              <Button type="primary">点击上传</Button>
          }
        </Upload>
        <style jsx>{`
          .user-logo-upload {
            position: relative;
            :global(.logo-uploader) {
              position: relative;
              :global(.upload-icon) {
                position: absolute;
                left: 0;
                width: 60px;
                height: 60px;
                line-height: 60px;
                background-color: rgba(0,0,0,.5);
                color: #fff;
                font-size: 20px;
              }
            }
            :global(.ant-upload) {
              padding: 4px 4px 0 0;
            }
            :global(.ant-upload.ant-upload-select-picture-card) {
              background-color: transparent;
              border: none;
              margin: 0;
              width: auto;
              height: auto;
            }
            :global(.del-link) {
              display: inline-block;
              padding-left: 10px;
              padding-bottom: 10px;
              vertical-align: bottom;
              color: #999999 !important;
              cursor: pointer;
            }
          }
        `}</style>
      </div>
    );
  }
}
