
import React from "react";
import {
  Row,
  Col,
  Icon,
  Collapse,
  Select,
  Input,
  Modal,
  DatePicker,
  Pagination,
  Form,
  Tooltip,
  Cascader,
  Radio
} from "antd";
import moment from "moment";

const Panel = Collapse.Panel;
const Option = Select.Option;
const TextArea = Input.TextArea;
const FormItem = Form.Item;
const { RangePicker } = DatePicker;

//沟通记录
export class CommunicateRecords extends React.PureComponent {
  state = {
    visible: false,
    remarkValue: "",
    remarkValidate: "success",
    currentPage: 1,
  };

  onCancel = () => {
    this.setState({
      visible: false
    });
  };

  onAdd = e => {
    e.stopPropagation();
    this.setState({
      visible: true
    });
  };

  addRecord = () => {
    if (!this.state.remarkValue) {
      this.setState({
        remarkValidate: "error"
      })
      return;
    }
    this.props.addChat({
      company_id: this.props.companyDetail.id,
      remark: this.state.remarkValue,
      currentPage: this.state.currentPage
    }).then(res=> {
      if (res.code == 0) {
        this.setState({
          remarkValue: "",
          remarkValidate: "success",
          visible: false
        })
      }
    })
  }

  handleRemarkChange(e) {
    let remark = e.target.value;
    if (remark) {
      this.setState({
        remarkValue: e.target.value,
        remarkValidate: "success"
      })
    } else {
      this.setState({
        remarkValue: e.target.value,
        remarkValidate: "error"
      })
    }
  }

  handlePageChange = (page, pageSize) => {
    const {getChatList, companyDetail} = this.props;
    getChatList({
      company_id: companyDetail.id,
      "per-page": 6,
      page: page
    })
    this.setState({
      currentPage: page
    })
  }
  
  render() {
    const { visible } = this.state;
    const { record, currentType } = this.props;
    return (
      <React.Fragment>
        <Modal
          zIndex={1001}
          title="添加沟通记录"
          visible={visible}
          onOk={this.addRecord}
          onCancel={this.onCancel} >
          <Form className="comm-detail">
            <div style={{marginBottom: "20px", fontSize: "16px"}}>请详细备注本次沟通记录</div>
            <Form.Item help={this.state.remarkValidate == "success" ? "" : "备注不能为空" } validateStatus={this.state.remarkValidate}>
              <TextArea placeholder="您可以更详细的备注本次沟通记录" rows={7}
                value={this.state.remarkValue}
                onChange={(e) => this.handleRemarkChange(e)}/>
            </Form.Item> 
          </Form>
          <style jsx>
            {`
              .comm-type {
                padding-left: 30px;
                margin-bottom: 20px;
                :global(.ant-select) {
                  width: 200px;
                  margin-left: 5px;
                  height: 32px;
                }
              }
              .comm-detail {
                padding-left: 30px;
                :global(.ant-input) {
                  padding: 20px;
                  width: 450px;
                  height: 140px;
                  resize: none;
                }
              }
            `}
          </style>
        </Modal>
        <Collapse defaultActiveKey={["2"]} className="collapse-handle-box">
          <Panel
            header={
              <React.Fragment>
                <span>沟通记录</span>
                {
                  currentType != "2" && 
                    <Icon type="plus-square" theme="outlined" className="right" style={{ fontSize: 18 }} onClick={this.onAdd}/>
                }
              </React.Fragment>
            }
            key="2"
          >
            {record.list && record.list.map((item) => {
              return (
                <div className="process record" key={item.id}>
                  <div className="record-status">{item.create_time_str}<Tooltip title={item.create_by_org_name}><span>{item.create_by_name}</span></Tooltip><span className="right">{item.status_name}</span></div>
                  <div className="record-remark">备注 : {item.remark}</div>
                </div>
              )
            })
            }
            {record.list && record.list.length == 0 ? "暂无沟通记录" :
              <Pagination className="right" style={{marginBottom: "20px"}} defaultCurrent={1} current={record._meta && record._meta.currentPage}
                pageSize={6} total={record._meta && record._meta.totalCount} onChange={this.handlePageChange}/>
            }
          </Panel>
          <style jsx>
            {`
              .process {
                position: relative;
                padding: 20px 10px;
                margin-left: 4px;
                margin-bottom: 20px;
                background-color: #fafafa;
                border-radius: 4px;
                border: solid 1px #d9d9d9;
                .record-status {
                  color: #999999;
                  margin-bottom: 14px;
                  span {
                    margin-left: 20px;
                  }
                }
                .record-remark {
                  color: #666666;
                }
                .process-status {
                  position: absolute;
                  top: 0;
                  right: 0;
                  color: #666666;
                  padding: 7px 10px 7px 30px;
                  border-bottom-left-radius: 35px;
                }
              }
            `}
          </style>
        </Collapse>
      </React.Fragment>
    );
  }
}

// 添加合同
export class AddContractForm extends React.PureComponent {
  state = {
    dataSource: []
  };

  render() {
    const {commonData, userData} = this.props;
    const formItemLayout = {
      labelCol: { span: 5 },
      wrapperCol: { span: 19 }
    };
    const size = "large";
    const {
      getFieldDecorator,
    } = this.props.form;
    return (
      <Form className="add-contract-form">
        <Row gutter={35}>
          <Col span={12}>
            <FormItem
              className="add-contract-item"
              {...formItemLayout}
              label="合同编号"
            >
              {getFieldDecorator("contract_no", {
                rules: [
                  { required: true, message: "请输入合同编号" }
                ]
              })(<Input size={size} placeholder="请输入合同编号"/>)}
            </FormItem>
          </Col>
          <Col span={12}>
            <FormItem
              className="add-business-item"
              {...formItemLayout}
              label="签署日期"
            >
              {getFieldDecorator("sign_date_m", {
                rules: [
                  { required: true, message: "请选择签署日期" }
                ]
              })(<DatePicker style={{width: "100%"}} size={size}/>)}
            </FormItem>
          </Col>
        </Row>
        <Row gutter={35}>
          <Col span={12}>
            <FormItem
              className="add-business-item"
              {...formItemLayout}
              label="合同类型"
            >
              {getFieldDecorator("type", {
                rules: [
                  { required: true, message: "请选择合同类型" }
                ]
              })(
                <Select placeholder="请选择合同类型" size={size}>
                  {commonData.contract_type &&
                    commonData.contract_type.map(item => {
                      return (
                        <Option key={item.value} value={item.value}>
                          {item.label}
                        </Option>
                      );
                    })}
                </Select>
              )}
            </FormItem>
          </Col>
          <Col span={12}>
            <FormItem
              className="add-business-item"
              {...formItemLayout}
              label="生效日期"
            >
              {getFieldDecorator("rangeTime", {
                initialValue: [moment(), moment().add(1, 'y')],
                rules: [
                  { required: true, message: "请选择生效时间" }
                ]
              })(
                <RangePicker size={size}/>
              )}
            </FormItem>
          </Col>
        </Row>

        <Row gutter={35}>
          <Col span={12}>
            <FormItem
              className="add-business-item"
              {...formItemLayout}
              label="负责人"
            >
              {getFieldDecorator("bd_ids", {
                initialValue: [userData.org_info.org_city.id, userData.org_info.org_team.id, userData.org_info.org_group.id, userData.org_info.org_user.id],
                rules: [
                  { required: true, message: "请选择负责人" }
                ]
              })(
                <Cascader
                  fieldNames={{
                    label: "name",
                    value: "id",
                    children: "list"
                  }}
                  options={commonData.company_manager}
                  className="gee-cascader" size={size} placeholder="请选择负责人"/>
              )}
            </FormItem>
          </Col>
          <Col span={12}>
            <FormItem
              className="add-business-item"
              {...formItemLayout}
              label=""
            >
              {getFieldDecorator("is_new", {
                initialValue: 1,
                rules: [
                ]
              })(
                <Radio.Group >
                  <Radio value={1}>新增合同</Radio>
                  <Radio value={2}>续签合同</Radio>
                </Radio.Group>
              )}
            </FormItem>
          </Col>
        </Row>
        <Row gutter={35}>
          <Col className="single-col" span={24}>
            <FormItem
              className="add-business-item remark"
              labelCol={{ span: 3 }}
              wrapperCol={{ span: 21 }}
              label="备注"
            >
              {getFieldDecorator("remark", {
                rules: []
              })(<TextArea placeholder="请输入备注" size={size}/>)}
            </FormItem>
          </Col>
        </Row>
      </Form>
    );
  }
}
