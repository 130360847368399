
import xhr from "./index.js";

//区域职位新增
export function areaPositionAdd(param) {
  return xhr({
    url: "admin/v1/area-jds",
    body: param,
    method: "POST"
  });
}

//区域职位编辑
export function areaPositionEdit(param) {
  return xhr({
    url: "admin/v1/area-jds/" + param.id,
    body: param,
    method: "PUT"
  });
}

//区域职位删除
export function areaPositionDelete(param) {
  return xhr({
    url: "admin/v1/area-jds/" + param.id,
    body: param,
    method: "DELETE"
  });
}

//区域 Marker列表
export function areaMarkers(param) {
  return xhr({
    // url: "admin/v1/area-jd/location",
    url: "admin/v1/business/location",
    body: param,
    method: "POST"
  });
}

//区域职位列表
export function areaPositions(param) {
  return xhr({
    url: "admin/v1/area-jds",
    body: param,
    method: "GET"
  });
}

// //区域商机列表
// export function areaBusinesses(param) {
//   return xhr({
//     url: "admin/v1/business/location",
//     body: param,
//     method: "POST"
//   });
// }

//区域人选列表
export function areaTalents(param) {
  return xhr({
    url: "admin/v1/profile-manage/northern-expedition-list",
    body: param,
    method: "POST"
  });
}