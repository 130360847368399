import xhr from "./index.js";

//获取推荐职位列表
export function getRecomPosList(param) {

  return xhr({
    url: "admin/v1/jd/list",
    body: param,
    method: "POST"
  });
}

//推荐到职位
export function recommandToPos(param) {
  return xhr({
    url: "/admin/v1/jd-profiles",
    body: param,
    method: "POST"
  });
}

//推荐人选到前台
export function recommendProfileFront(param) {
  return xhr({
    url: "/admin/v1/jd-profile/recmd-front",
    body: param,
    method: "POST"
  });
}