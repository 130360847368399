import css from 'styled-jsx/css';

export default css.global`/* @styled-jsx=global */
.flow-container {
  display: flex;
  padding-left: 16px;
  padding-right: 21px;
  width: 773px;
  justify-content: space-between;
  align-content: center;
  .step-box {
    ul {
      padding: 0;
      display: flex;
      margin-bottom: 20px;
      li {
        position: relative;
        min-width: 80px;
        font-size: 12px;
        margin-right: 3px;
        padding-left: 12px;
        list-style: none;
        line-height: 30px;
        background: #e5e5e5;
        cursor: pointer;
        .step-title {
          color: #333333;
        }
        &:after {
          content: "";
          display: block;
          border-top: 15px solid transparent;
          border-bottom: 15px solid transparent;
          border-left: 5px solid #e5e5e5;
          position: absolute;
          right: -5px;
          top: 0;
          z-index: 1;
        }
        &:before {
          content: "";
          display: block;
          border-top: 15px solid transparent;
          border-bottom: 15px solid transparent;
          border-left: 5px solid #ffffff;
          position: absolute;
          left: 0px;
          top: 0;
        }
        &:first-child:before {
          display: none;
        }
        &:last-child:after {
          display: none;
        }
      }
      .active {
        background: #1890ff;
        .step-title {
          color: #ffffff;
        }

        &:after {
          border-left-color: #1890ff;
        }
      }
    }
  }
  .ant-dropdown-menu-item > a, .ant-dropdown-menu-submenu-title > a{
    font-size: 12px;
  }
}

/**  */
.profile-container {
  width: 423px;
  // min-height: 1150px;
  height: calc(100% - 50px);
  display: inline-block;
  border: 1px solid #e5e5e5;
  border-bottom: 0;
  vertical-align: top;
  .progress-profile-table {
    .row-sel {
      background-color: #ddeeff;
    }
    .profile-info {
      .name {
        position: relative;
        line-height: 22px;
        font-size: 12px;
        color: #666666;
        .bold {
          font-size: 12px;
          font-weight: bold;
          color: #333333;
        }
      }
      .base {
        font-size: 12px;
        color: #999999;
        line-height: 22px;
      }
      .system-icon {
        position: absolute;
        top: 2px;
        display: inline-block;
        width: 66px;
        height: 18px;
      }
      .reserve-icon {
        position: absolute;
        top: 2px;
        display: inline-block;
        width: 66px;
        height: 18px;
      }
    }
    .ant-table-scroll {
      .ant-table-header {
        margin-bottom: 0 !important;
        overflow: visible !important;
        .ant-table-thead>tr>th, .ant-table-tbody>tr>td {
          padding: 10px 4px;
          border-bottom: 1px solid #e5e5e5;
        }
        .ant-table-thead .ant-table-selection {
          display: none;
        }
      }
      .ant-table-body {
        overflow-y: auto !important;
      }
      .ant-table-placeholder {
        border-bottom: 0;
      }
    }
    .total-header {
      padding-right: 30px;
      font-size: 12px;
      color: #999999;
      cursor: pointer;
      &.can-use {
        color: rgb(56, 164, 233);
      }
    }
    .operate-td {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding-right: 15px;
      height: 63px;

      .filter-operate {
        span {
          margin-left: 10px;
          color: #1890ff;
          cursor: pointer;
        }
      }

      .handle-area {
        .icon {
          width: 1em;
	        height: 1em;
          vertical-align: -0.15em;
          fill: currentColor;
          overflow: hidden;
          margin-right: 10px;
          font-size: 16px;
          cursor: pointer;
        }
      }

      .dot {
        width: 6px;
        height: 6px;
        border-radius: 50%;
        display: inline-block;
        margin-right: 10px;
        vertical-align: middle;
      }
    }
    .ant-table-pagination.ant-pagination {
      margin-right: 10px;
    }
    .ant-table-thead > tr {
      background-color: inherit;
    }
  }

  .report-view {
    .report-view-mask {
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: rgba(0,0,0,.7);
      z-index: 1;
    }
    .report-view-wrapper {
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 1;
    }
    .preview {
      width: 800px;
      height: calc(100% - 80px);
      margin: 0 auto;
    }
    .bottom-operate {
      padding: 0 80px;
      display: flex;
      justify-content: space-between;
      height: 80px;
      line-height: 80px;
      background: #ffffff;
      border-top: 1px solid #e5e5e5;
      font-size: 14px;
      .ant-btn {
        margin-left: 15px;
      }
      .remark {
        display: inline-block;
        width: 800px;
      }
    }
    .close-icon {
      position: absolute;
      top: 20px;
      right: 60px;
      color: #fff;  
      font-size: 40px;
    }
  }

  .inner-search {
    margin-left: 10px;
    width: 160px;
    input {
      padding-left: 10px;
    }
  }
}

.profile-record-container {
  width: 350px;
  // min-height: 1150px;
  height: calc(100% - 50px);
  display: inline-block;
  vertical-align: top;
  border-top: 1px solid #e5e5e5;
  overflow-y: auto;
  .profile-record-header {
    padding-left: 16px;
    width: 350px;
    height: 45px;
    line-height: 45px;
    border-bottom: 1px solid #e5e5e5;
    background-color: #fafafa;
  }
  .profile-record-content {
    padding: 35px 20px 20px;
  }

  .ant-timeline-item-content {
    top: -15px;
  }
  .ant-timeline-item {
    padding: 0 0 10px 0;
  }
  .progress {
    position: relative;
    .color-status {
      width: 40px;
      height: 40px;
      padding: 5px 2px 5px 4px;
      color: #ffffff;
      border-radius: 3px;
      margin-right: 10px;
      span {
        display: inline-block;
      }
    }
    .right {
      width: 81%;
    }
    p {
      margin-bottom: 11px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      &.mark {
        color: #666666;
        span {
          line-height: 20px;
        }
      }
      &.time {
        color: #999999;
      }
    }
  }
}
`