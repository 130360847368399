import React, { PureComponent, useState, useEffect }  from "react";
import { notification, Button, Tag, Tooltip } from "antd";
import { history } from "root";
import Table from "components/GeeTable.js";
import * as clueService from "services/clueService";
import * as businessService from "services/businessService";

export async function clueNotification(props) {
  // const {clue_remind} = props;
  let columns = [
    {
      title: "线索日期",
      dataIndex: "create_time_str",
      width: 130,
    },
    {
      title: "公司名称",
      width: 100,
      needEllipsis: true,
      dataIndex: "company_name"
    },
    {
      title: "联系人",
      width: 100,
      needEllipsis: true,
      dataIndex: "contactor"
    },
    {
      title: "手机号码",
      width: 100,
      dataIndex: "contactor_phone",
      render: (text, record) => record.contactor_phone || record.contactor_office_number
    },
    {
      title: "线索来源",
      width: 100,
      needEllipsis: true,
      dataIndex: "source_name"
    },
  ];
  const goClue = () => {
    notification.close("clue");
    history.replace({
      pathname: "/custom/clue",
      state: {notification: true}
    });
  }
  if (true) {
    let param = {};
    param.expand =
          "follow_up_name,create_time_str,source_name,company_name,company_scale_id,company_scale,company_type," + 
          "company_industry,contactor,contactor_phone,contactor_office_number,contactor_email," + 
          "contactor_position,create_by_name,create_by_org_name,org_id,city_id,operator,operate_time,follow_records,source_p,create_by_org_info,gb_business";
    param.status = 4;
    param["per-page"] = 5;
    let result = await clueService.clueList(param);
    if (result.code == 0) {
      notification.open({
        key: "clue",
        message: <div style={{fontWeight: "bold", fontSize: "16px"}}>线索未处理提醒！</div>,
        description: (
          <React.Fragment>
            <div style={{marginBottom: "20px"}}>由您跟进的如下线索已超过3个工作日未处理，系统即将转入Geebox，请及时确认！</div>
            <Table
              rowKey="id"
              className="share-business-table"
              columns={columns}
              dataSource={result.data && result.data.list}
              // onChange={this.handleTableChange}
              // loading={this.props.shareLoading}
              pagination={false}
              />  
            <div style={{textAlign: "center"}}>
              <Button size="large" onClick={goClue} shape="round" type="primary" style={{margin: "20px auto", width: "300px" }}>查看详情</Button>
            </div>
          </React.Fragment>
        ),
        onClick: () => {
          console.log('Notification Clicked!');
          
        },
        duration: null,
        style: {
          width: 650,
          marginLeft: 335 - 650,
        },
      });
    }
  }
}

export async function businessNotification() {
  // const {clue_remind} = props;
  let columns = [
    {
      title: "商机日期",
      dataIndex: "create_time_str",
      width: 130,
    },
    {
      title: "公司名称",
      width: 200,
      needEllipsis: true,
      dataIndex: "company.name"
    },
    {
      title: "招聘需求",
      width: 180,
      dataIndex: "contactor.contactor",
      render: (text, record) => (
        <div className="tip-div ellipsis" style={{maxWidth: "160px", display: "inline-block"}}>
          <span
            className="tip-post-name ellipsis"
            title={record.jd_info && record.jd_info.post_name}
          >
            {record.jd_info && record.jd_info.post_name}/
          </span>
          <span className="tip-num ellipsis">
            {record.jd_info &&
              record.jd_info.require_num &&
              "  " + record.jd_info.require_num + "人"}
          </span>
        </div>
      )
    },
    {
      title: "商机进展",
      dataIndex: "status_name",
      width: 170,
      render: (text, record) => (
        <div className="content">
          {
            text === "无效商机" && record.is_potential === 1 ?
              <Tag
                color={"volcano"}
                className="status-not"
              >
                潜在商机
              </Tag>
              :
              <Tag
                color={text === "已分配" ? "blue" : "volcano"}
                className={text === "无效商机" || text === "潜在商机" ? "status-not" : ""}
              >
                {text}
              </Tag>
          }
          {/* {text === "已分配" && (
            <div className="content-tip">
              分配给:
                <Tooltip title={record.assign_to_org_name}>
                {record.assign_to}
              </Tooltip>
            </div>
          )} */}
        </div>
      )
    },
  ];
  const goBusiness = () => {
    notification.close("business");
    history.replace({
      pathname: "/custom/business",
      state: {notification: true}
    });
  }
  if (true) {
    let param = {};
    param.expand = "company,jd_info,source_name,create_time_str,create_by_name,contactor,status_name,assign_to,assign_to_org_name,bd_name,bd_org_name,submit_num,last_contact,gb_business,source_p"
    param.status = 8;
    param["per-page"] = 5;
    let result = await businessService.businessList(param);
    if (result.code == 0) {
      notification.open({
        key: "business",
        message: <div style={{fontWeight: "bold", fontSize: "16px"}}>商机未处理提醒！</div>,
        description: (
          <React.Fragment>
            <div style={{marginBottom: "20px"}}>以下商机超过3个工作日未进行有效核实，系统即将转入Geebox请及时确认。</div>
            <Table
              rowKey="id"
              className="share-business-table"
              columns={columns}
              dataSource={result.data && result.data.list}
              // onChange={this.handleTableChange}
              // loading={this.props.shareLoading}
              pagination={false}
              />  
            <div style={{textAlign: "center"}}>
              <Button size="large" onClick={goBusiness} shape="round" type="primary" style={{margin: "20px auto", width: "300px" }}>查看详情</Button>
            </div>
          </React.Fragment>
        ),
        onClick: () => {
          console.log('Notification Clicked!');
          
        },
        duration: null,
        style: {
          width: 650,
          marginLeft: 335 - 650,
        },
      });
    }
  }
}