import xhr from './index.js'

// 最新职位列表
export function newPositionList(param) {
  return xhr({
    url: 'admin/v1/workbench/new-jds',
    body: param
  })
}
// 提醒事项列表
export function reminderList(param) {
  return xhr({
    url: 'admin/v1/workbench/reminder',
    body: param
  })
}
// 新增提醒
export function addRemind(param) {
  return xhr({
    url: 'admin/v1/workbench/add-reminder',
    body: param,
    method: "POST"
  })
}
// 操作提醒状态
export function isRemindDo(param) {
  return xhr({
    url: 'admin/v1/workbench/operate-reminder',
    body: param,
    method: "POST"
  })
}
// 优职说列表
export function articleList(param) {
  return xhr({
    url: 'admin/v1/workbench/article',
    body: param,
    method: "get"
  })
}
// 优职详情
export function articleDetail(param) {
  return xhr({
    url: 'admin/v1/articles/'+param.id,
    body: param,
  })
}
// 优职详情
export function isRemark(param) {
  return xhr({
    url: 'admin/v1/article/mark',
    body: param,
    method: "POST"
  })
}
// 人选更新榜
export function personList(param) {
  return xhr({
    url: 'admin/v1/workbench/profile-update-list',
    body: param,
  })
}
// 实时推荐榜
export function recommendList(param) {
  return xhr({
    url: 'admin/v1/workbench/rmd-list',
    body: param,
  })
}
