import xhr from "./index.js";

/**
 * 轮播管理
 */
//轮播列表
function bannerManageList(param) {
  return xhr({
    url: "admin/v1/index-imgs",
    body: param
  });
}
//轮播新增
function bannerManageAdd(param) {
  return xhr({
    url: "admin/v1/index-imgs",
    body: param,
    method: "POST"
  });
}
//轮播编辑
function bannerManageEdit(param) {
  return xhr({
    url: "admin/v1/index-imgs/" + param.id,
    body: param,
    method: "put"
  });
}
//轮播详情
function bannerManageDetail(id) {
  return xhr({
    url: "admin/v1/index-imgs/" + id
  });
}
//轮播删除
function bannerManageDelete(id) {
  return xhr({
    url: "admin/v1/index-imgs/" + id,
    method: "DELETE"
  });
}
//内容轮播列表
function areaManageList(param) {
  return xhr({
    url: "admin/v1/index-contents",
    body: param
  });
}
//内容新增
function areaManageAdd(param) {
  return xhr({
    url: "admin/v1/index-contents",
    body: param,
    method: "POST"
  });
}
//内容编辑
function areaManageEdit(param) {
  return xhr({
    url: "admin/v1/index-contents/" + param.id,
    body: param,
    method: "put"
  });
}
//内容详情
function areaManageDetail(param) {
  return xhr({
    url: "admin/v1/index-contents/" + param.id,
    body: { expand: param.expand }
  });
}
//内容删除
function areaManageDelete(id) {
  return xhr({
    url: "admin/v1/index-contents/" + id,
    method: "DELETE"
  });
}
// 选项列表
function getCityData(param) {
  return xhr({
    url: "admin/v1/common/static",
    body: { data_name: param }
  });
}
// 查询某城市在线人选数
function getOnline(param) {
  return xhr({
    url: "admin/v1/index-content/online-profile",
    body: param
  });
}
// 查询全国下的某滚动信息
function getRoll(param) {
  return xhr({
    url: "admin/v1/index-content/national-info",
    body: param
  });
}

export {
  bannerManageList,
  bannerManageAdd,
  bannerManageDetail,
  bannerManageEdit,
  bannerManageDelete,
  areaManageList,
  areaManageAdd,
  areaManageDetail,
  areaManageEdit,
  areaManageDelete,
  getCityData,
  getOnline,
  getRoll
};
