import Model from "../Model";
import * as messageService from "services/sysMessageService";
import * as commonService from "services/commonService";
import { message } from "antd";

export default Model.getInstance(
  class extends Model {
    namespace = "sysmessage";
    state = {
      commonData: {},
      topMessageList: [],
      topMessageLoading: false,
      messageList: [],
      messagePagination: {},
      messageLoading: false,
      messageLogList: [],
      messageLogPagination: {},
      messageLogLoading: false,
      detailDialogStatus: false, //详情弹窗状态
      detailDialogInfo: {}, //详情弹窗
      detailDialogdes: "", //详情描述
      detailDialogParam: {} //详情弹窗参数
    };

    actions = {
      async getCommonData(dataName) {
        let commonData = await commonService.commonData(dataName);
        this.dispatch({
          type: "sysmessage/updateCommon",
          payload: commonData
        });
      },
      async getMessageList(param = {}) {
        this.dispatch({
          type: "sysmessage/updateLoading",
          payload: true
        });
        param["per-page"] = param["per-page"] || 5;
        let result = await messageService.messageList(param);
        if (result.code == 0) {
          this.dispatch({
            type: "sysmessage/updateList",
            payload: { ...result.data, type: param.type }
          });
        }
        this.dispatch({
          type: "sysmessage/updateLoading",
          payload: false
        });
        return result;
      },
      async getTopMessageList(param = {}) {
        this.dispatch({
          type: "sysmessage/updateTopLoading",
          payload: true
        });
        param["per-page"] = param["per-page"] || 5;
        let result = await messageService.messageList(param);
        if (result.code == 0) {
          this.dispatch({
            type: "sysmessage/updateTopList",
            payload: result.data
          });
        }
        this.dispatch({
          type: "sysmessage/updateTopLoading",
          payload: false
        });
        return result;
      },
      async setMessageRead(param = {}) {
        let result = await messageService.messageRead(param);
        if (result.code == 0) {
          this.dispatch({
            type: "sysmessage/updateRead",
            payload: param.id ? param : param
          });
          if (param.id) {
            this.dispatch({
              type: "user/setUnread",
              payload: true
            });
          }
        }
        return result;
      },
      //获取消息弹窗数据
      async getMessageLog(param = {}) {
        let result = await messageService.messageLog(param);
        if (result.code == 0) {
          this.dispatch({
            type: "sysmessage/setUpdateState",
            payload: { filed: "detailDialogInfo", value: result.data }
          });
        }
        return result;
      }
    };

    reducers = {
      updateCommon(state, { payload: data }) {
        return { ...state, commonData: data };
      },
      updateList(state, { payload: data }) {
        return {
          ...state,
          [data.type === 1 ? "messageList" : "messageLogList"]: data.list || [],
          [data.type === 1 ? "messagePagination" : "messageLogPagination"]:
            data._meta || {}
        };
      },
      updateLoading(state, { payload: data }) {
        return { ...state, messageLoading: data };
      },
      updateTopList(state, { payload: data }) {
        return { ...state, topMessageList: data.list || [] };
      },
      updateTopLoading(state, { payload: data }) {
        return { ...state, topMessageLoading: data };
      },
      updateRead(state, { payload: data }) {
        let show = { 1: "messageList", 2: "messageLogList" };
        if (data && data.id) {
          state[show[data.type]][data.index].status = 2;
        } else {
          state[show[data.type]].forEach(item => {
            item.status = 2;
          });
        }
        return { ...state, [show[data.type]]: [...state[show[data.type]]] };
      },
      setUpdateState(state, { payload }) {
        const { filed, value } = payload;
        state[filed] = value;
        return { ...state };
      }
    };
  }
);
