import React from "react";
import ReactDOM from "react-dom";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  Menu,
  Row,
  Col,
  Icon,
  Tabs,
  Card,
  Collapse,
  Select,
  Input,
  Modal,
  Pagination,
  Form,
} from "antd";

const Panel = Collapse.Panel;
const Option = Select.Option;
const FormItem = Form.Item;

class AddLinkManForm extends React.Component {
  state = {
    dataSource: []
  };

  
  leastOne = (rule, value, callback) => {
    const {getFieldValue} = this.props.form;
    if (rule.field === "phone") {
      if (!value && !getFieldValue("office_number")) {
        callback("手机和电话号码必填一个");
      }
    } else if (rule.field === "office_number") {
      if (!value && !getFieldValue("phone")) {
        callback("手机和电话号码必填一个");
      }
    }
    
    callback();
  }

  validatePhone = (name, e) => {
    const {validateFields} = this.props.form;

    if (!e.target.value || e.target.value.length === 1) {
      setTimeout(() => {
        validateFields(["phone", "office_number"])
      }, 0);
    }    
  }

  render() {
    const {editContacter} = this.props;
    const formItemLayout = {
      labelCol: { span: 5 },
      wrapperCol: { span: 19 }
    };
    const size = "large";
    const {
      getFieldDecorator,
    } = this.props.form;
    return (
      <Form className="add-contract-form">
        <Row gutter={35}>
          <Col span={12}>
            <FormItem
              className="add-contract-item"
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 16 }}
              label="姓名"
              {...formItemLayout}
            >
              {getFieldDecorator("contactor", {
                initialValue: editContacter.contactor,
                rules: [
                  { required: true, message: "请输入姓名" }
                ]
              })(<Input size={size} placeholder="请输入姓名"/>)}
            </FormItem>
          </Col>
          <Col span={12}>
            <FormItem
              className="add-business-item"
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 16 }}
              label="性别"
              {...formItemLayout}
            >
              {getFieldDecorator("sex", {
                initialValue: editContacter.sex ? editContacter.sex + "" : "",
                rules: []
              })(
                <Select placeholder="请选择性别" size={size}>
                  <Option value="1">男</Option>
                  <Option value="2">女</Option>
                </Select>
              )}
            </FormItem>
          </Col>
        </Row>
        <Row gutter={35}>
          <Col span={12}>
            <FormItem
              className="add-business-item"
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 16 }}
              label="职位"
              {...formItemLayout}
            >
              {getFieldDecorator("position", {
                initialValue: editContacter.position,
                rules: []
              })(<Input size={size}  placeholder="请输入职位"/>)}
            </FormItem>
          </Col>
          <Col span={12}>
            <FormItem
              className="add-business-item"
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 16 }}
              label="手机"
              {...formItemLayout}
            >
              {getFieldDecorator("phone", {
                initialValue: editContacter.phone,
                rules: [
                  {
                    validator: this.leastOne
                  }
                ]
              })(<Input size={size} placeholder="请输入手机" onChange={(e) => this.validatePhone("phone", e)}/>)}
            </FormItem>
          </Col>
        </Row>

        <Row gutter={35}>
          <Col span={12}>
            <FormItem
              className="add-business-item"
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 16 }}
              label="工作电话"
              {...formItemLayout}
            >
              {getFieldDecorator("office_number", {
                initialValue: editContacter.office_number,
                rules: [
                  {
                    validator: this.leastOne
                  }
                ]
              })(<Input type="text" size={size}  placeholder="请输入工作电话" onChange={(e) => this.validatePhone("office_number", e)}/>)}
            </FormItem>
          </Col>
          <Col span={12}>
            <FormItem
              className="add-business-item"
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 16 }}
              label="工作邮箱"
              {...formItemLayout}
            >
              {getFieldDecorator("email", {
                initialValue: editContacter.email,
                rules: []
              })(<Input type="text" size={size}  placeholder="请输入工作邮箱"/>)}
            </FormItem>
          </Col>
        </Row>
      </Form>
    );
  }
}


//联系人
export default class Contact extends React.PureComponent {
  state = {
    visible: false, 
    modalTitle: "新增联系人",
    editContacter: {}
  };    

  addLinkMan = () => {
    const form = this.addLinkForm.props.form;
    const {modContact, companyDetail} = this.props;
    form.validateFields((err, values) => {
      if (err) {
        return;
      }
      if (this.state.editContacter.company_id) {
        values.company_contact_id = this.state.editContacter.id;
      }
      values.company_id = companyDetail.id;

      console.log('Received values of form: ', values);
      modContact(values).then(res => {
        if (res.code == 0) {
          this.setState({ visible: false });
          form.resetFields();
        }
      })
      
    });
  }

  editContact = (item) => {
    console.log(item)
    this.setState({
      visible: true,
      modalTitle: "编辑联系人", 
      editContacter: item
    })
  }

  onCancel = () => {
    this.setState({
      visible: false
    });
  };

  onAdd = e => {
    e.stopPropagation();
    this.setState({
      editContacter: {},
      visible: true
    });
  };

  addLinkFormRef = (formRef) => {
    this.addLinkForm = formRef;
  }

  pageSizeChange = (page, pageSize) => {
    const {getContactList, companyDetail} = this.props;
    getContactList({
      expand: "sex_name",
      company_id: companyDetail.id,
      page: page,
      "per-page": 10
    });
  }
  render() {
    const { visible } = this.state;
    const { contact, companyDetail, currentType } = this.props;
    const AddLinkMan = Form.create({})(AddLinkManForm);
    return (
      <React.Fragment>
        <Modal
          zIndex={1001}
          title={this.state.modalTitle}
          width={845}
          className="add-contacter-modal"
          visible={visible}
          onOk={this.addLinkMan}
          onCancel={this.onCancel} >
          <AddLinkMan wrappedComponentRef={this.addLinkFormRef} editContacter={this.state.editContacter}/>
        </Modal>
        <Collapse defaultActiveKey={["3"]} className="collapse-handle-box">
          <Panel
            key="3"
            header={
              <React.Fragment>
                <span>联系人</span>
                {
                  currentType != "2" && 
                    <Icon type="plus-square" theme="outlined" className="right" style={{ fontSize: 18 }} onClick={this.onAdd}/>
                }
              </React.Fragment>
            }
          >
            <div className="linkman-tabpane">
              <Row className="linkman-row" gutter={60}>
                {
                  contact.list && contact.list.map(item => {
                    let baseInfo = [];
                    item.sex_name && baseInfo.push(item.sex_name);
                    item.position && baseInfo.push(item.position);
                    return (
                      <Col span={12} key={item.id}>
                        <Card bordered={false}>
                          <div className="linkman-info name">
                            <span title={item.contactor} className="contact-name">{item.contactor}</span> 
                            <span title={baseInfo.join("·")} className="contact-sex">{baseInfo.join("·")}</span>
                            { currentType != "2" && <Icon type="edit" className="right" onClick={() => this.editContact(item)}/> }
                          </div>
                          <div className="linkman-info phone">
                            <Icon
                              className="info-icon"
                              type="mobile"
                              theme="outlined"
                            />
                            <span>{item.phone}</span>
                          </div>
                          <div className="linkman-info tell">
                            <Icon
                              className="info-icon"
                              type="phone"
                              theme="outlined"
                            />
                            <span>{item.office_number}</span>
                          </div>
                          <div className="linkman-info email">
                            <Icon
                              className="info-icon"
                              type="mail"
                              theme="outlined"
                            />
                            <span>{item.email}</span>
                          </div>
                        </Card>
                      </Col>
                    )
                  })
                }
              </Row>
              {
                contact._meta && 
                  <div className="clear">
                    <Pagination className="right" 
                      onChange={this.pageSizeChange} current={contact._meta.currentPage} total={contact._meta.totalCount}/>
                  </div>
              }
              
            </div>
          </Panel>
          <style jsx>{`
            .linkman-tabpane {
              padding: 0 72px 20px 20px;
              :global(.linkman-row) {
                margin-left: -40px !important;
              }
              :global(.ant-card) {
                margin-bottom: 10px;
                width: 350px;
                background-color: #fafafa;
                border-radius: 3px;
                :global(.ant-card-body) {
                  padding: 20px;
                }
              }
              .linkman-info {
                color: #666666;
                margin-bottom: 17px;
                :global(.anticon) {
                  margin-right: 12px;
                }
              }
              .name {
                font-weight: 600;
                font-size: 16px;
                margin-bottom: 30px;
                color: #333333;
                .contact-name {
                  margin-right: 20px;
                  max-width: 65px;
                  display: inline-block;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  line-height: normal;
                }
                .contact-sex {
                  max-width: 195px;
                  display: inline-block;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  line-height: normal;
                }
              }
            }
          `}</style>
        </Collapse>
      </React.Fragment>
    );
  }
}