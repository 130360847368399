import Model from "../Model";
import * as clueService from "services/clueService";
import * as commonService from "services/commonService";
import { message } from 'antd';

export default Model.getInstance(
  class extends Model {
    namespace = "clue";
    state = {
      commonData: {},
      clueList: [],
      clueLoading: false,
      condition: {},
      clueRecordVisible: false, // 线索跟进记录显示
      currentRecord: {}, // 线索跟进记录
      clueSelectKeys: [], // 分配key
      clueSelectRows: {}, // 分配row
      assignModalVisible: false, // 线索分配显示
      clueRecords: {
        list: [],
        _meta: {}
      }, // 线索跟进记录列表
    };

    actions = {
      async getCommonData(dataName) {
        let commonData = await commonService.commonData(dataName);
        this.dispatch({
          type: "clue/updateCommon",
          payload: commonData
        });
      },
      async getClueList(param) {
        param.expand =
          "follow_up_name,create_time_str,source_name,company_name,company_scale_id,company_scale,company_type," + 
          "company_industry,contactor,contactor_phone,contactor_office_number,contactor_email," + 
          "contactor_position,create_by_name,create_by_org_name,org_id,city_id,operator,operate_time,follow_records,source_p,create_by_org_info,gb_business";
        this.dispatch({
          type: "clue/updateLoading",
          payload: true    
        });
        let result = await clueService.clueList(param);
        if (result.code == 0) {
          this.dispatch({
            type: "clue/updateList",
            payload: result.data     
          });
        }
        this.dispatch({
          type: "clue/updateLoading",
          payload: false
        });
      },
      async addClue(param) {
        let result = await clueService.insertClue(param);
        if (result.code == 0) {
          message.info("创建成功！")
        }
        return result;
      },
      async modClue(editRecord, param) {
        let result = await clueService.modifyClue(editRecord.id, param);
        if (result.code == 0) {
          message.info("修改成功！");
          param.index = editRecord.index;
          this.dispatch({
            type: "clue/updateEditData",
            payload: param
          })
        }
        return result;
      },
      async delClue(id) {
        let result = await clueService.deleteClue(id);
        if (result.code == 0) {
          message.info("删除成功！")
        }
        return result;
      },
      async setInvalidClue(param, index) {
        let result = await clueService.setInvalidClue(param);
        if (result.code == 0) {
          message.info("成功设置为无效线索！");
          
        }
        this.dispatch({
          type: "clue/updateIndexData",
          payload: {index, param}
        })
        return result;
      },
      async transformClue(param) {
        let result = await clueService.transformBusiness(param);
        if (result.code == 0) {
          message.info("转化商机成功！")
        }
        return result;
      },
      async chatList(param) {
        param.expand = "create_time_str,create_by_name,create_by_org_name,status_name,invalid_reason_name";
        param["per-page"] = 8;
        let chatResult = await clueService.chatList(param);
        if (chatResult.code == 0) {
          this.dispatch({
            type: "clue/updateChat",
            payload: chatResult.data
          });
        }
      },
      async addRecord(param) {
        let result = await clueService.insertChat(param);
        if (result.code == 0) {
          message.info("添加成功！");
          // let chatParam = {
          //   expand: "create_time_str,create_by_name,create_by_org_name,status_name,invalid_reason_name",
          //   company_id: param.company_id,
          //   clue_id: param.clue_id,
          //   "per-page": 8,
          //   page: 1
          // }
          // let chatResult = await clueService.chatList(chatParam);
          // if (chatResult.code == 0) {
            this.dispatch({
              type: "clue/updateAllChat",
              payload: result.data
            });
          // }
        }
        return result;
      },
      async delRecord(param) {
        let result = await clueService.delChat(param);
        if (result.code == 0) {
          message.info("删除成功！");
          this.dispatch({
            type: "clue/deleteChat",
            payload: param
          });
        }
        return result;
      },
      async assignClue(param) {
        let result = await clueService.assignClue(param);
        if (result.code == 0) {
        }
        return result;
      },
    };

    reducers = {
      updateCommon(state, { payload: data }) {
        return { ...state, commonData: data };
      },
      updateList(state, { payload: data }) {
        return { ...state, clueList: data };
      },
      updateIndexData(state, { payload }) {
        let cluelist = state.clueList.list.concat([]);
        const { index, param} = payload;
        cluelist[index].status = 2;
        cluelist[index].remark = param.remark;
        cluelist[index].operator = param.name;
        cluelist[index].operate_time = param.time;
        return { ...state, clueList: {
          ...state.clueList,
          list: cluelist,
        }};
      },
      updateEditData(state, { payload: editRecord }) {
        let cluelist = state.clueList.list.concat([]);
        if (editRecord.source_labelValue.length > 1) {
          cluelist[editRecord.index].source = editRecord.source_labelValue[1];
          cluelist[editRecord.index].source_p = editRecord.source_labelValue[0];
        } else {
          cluelist[editRecord.index].source = editRecord.source_labelValue[0];
          cluelist[editRecord.index].source_p = "";
        }
        cluelist[editRecord.index].source_name = editRecord.source_name.join("-");
        if (Array.isArray(editRecord.create_by_labelValue)) {
          cluelist[editRecord.index].city_id = editRecord.create_by_labelValue[0];
          cluelist[editRecord.index].org_id = editRecord.create_by_labelValue[1];
          cluelist[editRecord.index].create_by = editRecord.create_by_labelValue[2];
          cluelist[editRecord.index].create_by_name = editRecord.create_by_name[2];
          cluelist[editRecord.index].create_by_org_name = editRecord.create_by_name[1];
        }
        cluelist[editRecord.index].company_name = editRecord.company_name;
        cluelist[editRecord.index].company_scale = editRecord.company_scale_labelValue ? editRecord.company_scale_labelValue.label : undefined;
        cluelist[editRecord.index].company_scale_id = editRecord.company_scale_labelValue ? editRecord.company_scale_labelValue.key : undefined;
        cluelist[editRecord.index].company_type_name = editRecord.company_type_labelValue ? editRecord.company_type_labelValue.label : undefined;
        cluelist[editRecord.index].company_type = editRecord.company_type_labelValue ? editRecord.company_type_labelValue.key : undefined;
        cluelist[editRecord.index].contactor = editRecord.contactor;
        cluelist[editRecord.index].phone = editRecord.phone;
        cluelist[editRecord.index].email = editRecord.email;
        cluelist[editRecord.index].demand = editRecord.demand;
        cluelist[editRecord.index].jd_desc = editRecord.jd_desc;
        return { ...state, clueList: {
          ...state.clueList,
          list: cluelist,
        }};
      },
      updateLoading(state, { payload: data }) {
        return { ...state, clueLoading: data };
      },
      updateClueSelect(state, { payload: data }) {
        return { ...state, clueSelectKeys: data.selectedRowKeys, clueSelectRows: data.selectedRows };
      },
      updateRecord(state, { payload: data }) {
        const {record, clueRecordVisible} = data;
        return { ...state, 
          clueRecordVisible: clueRecordVisible, 
          currentRecord: record, 
          clueRecords: {
            list: [],
            _meta: {}
          } 
        };
      },
      updateAssignVisible(state, { payload: data }) {
        return { ...state, 
          assignModalVisible: data, 
        };
      },
      updateChat(state, { payload: data }) {
        let clueRecords = {
          ...data,
          list: state.clueRecords.list.concat(data.list),
        }
        return { ...state, clueRecords: clueRecords };
      },
      updateAllChat(state, { payload: data }) {
        state.clueRecords.list.unshift({
          ...data
        })
        let clueRecords = {
          ...state.clueRecords,
          list: [...state.clueRecords.list],
        }
        state.currentRecord.follow_records += 1;
        return { ...state, clueRecords: clueRecords };
      },
      deleteChat(state, { payload: data }) {
        state.currentRecord.follow_records -= 1;
        state.clueRecords.list.splice(data.index, 1)
        return { ...state, clueRecords: {...state.clueRecords} };
      },
    };
  }
);
