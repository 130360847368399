import React from "react";

export default function Empty({ description, image }) {

  return (
    <div className="gee-empty-status">
      <img src={image ? image: require("assets/imgs/empty.png")} />
      <p>{description}</p>
      <style jsx>{`
        .gee-empty-status {
          text-align: center;
          min-height: 520px;
        }
        img {
          margin-top: 108px;
        }
        p {
          margin-top: 20px;
          color: #999;
        }
      `}</style>
    </div>
  );
}
