import React, { PureComponent } from "react";
import { connect } from "react-redux";
import {
  Tabs,
  Select,
  Input,
  Row,
  Col,
  Button,
  Drawer,
  Form,
  Icon,
  InputNumber,
  Collapse,
  Checkbox,
  Message,
  Cascader
} from "antd";
import CityCascade from "components/CityCascade";
import InputComplete from "components/InputComplete";
import * as companyService from "services/companyService";
import PositionVacantModal from "store/reducers/positionVacantM";

const Option = Select.Option;
const FormItem = Form.Item;
const TextArea = Input.TextArea;
const InputGroup = Input.Group;

function createMarkup(text = "") {
  if (!text) text = "";
  return { __html: text.replace(/\n|\r|\r\n/g, "<br/>") };
}

/**
 * 职位信息展示
 */
class PositionInfoShow extends PureComponent {
  createWork = item => {
    let result = [];

    if (item.city_name === item.province_name) {
      item.province_name && result.push(item.province_name);
    } else {
      item.province_name && result.push(item.province_name);
      item.city_name && result.push(item.city_name);
    }
    item.region_name && result.push(item.region_name);
    return result.join("/");
  };

  render() {
    const { positionDetail = {}, detailWidthStatus } = this.props;
    return (
      <React.Fragment>
        <div className="position-show-box">
          <div className="info clear">
            <div
              className={`info-item ${!detailWidthStatus && "single-row-show"}`}
            >
              <span className="label">职位名称：</span>
              <span className="show" title={positionDetail.post_name}>
                {positionDetail.post_name}
              </span>
            </div>
            <div
              className={`info-item ${!detailWidthStatus && "single-row-show"}`}
            >
              <span className="label">职能：</span>
              <span className="show">{positionDetail.position_name}</span>
            </div>
            <div
              className={`info-item ${!detailWidthStatus && "single-row-show"}`}
            >
              <span className="label">需求人数：</span>
              <span className="show">{positionDetail.require_num}</span>
            </div>
            <div
              className={`info-item ${!detailWidthStatus && "single-row-show"}`}
            >
              <span className="label">学历：</span>
              <span className="show">{positionDetail.education_name}</span>
            </div>
            <div
              className={`info-item ${!detailWidthStatus && "single-row-show"}`}
            >
              <span className="label">服务类型：</span>
              <span className="show">{positionDetail.service_type_name}</span>
            </div>
            <div
              className={`info-item ${!detailWidthStatus && "single-row-show"}`}
            >
              <span className="label">职位性质：</span>
              <span className="show">{positionDetail.work_type_name}</span>
            </div>
            <div
              className={`info-item ${!detailWidthStatus && "single-row-show"}`}
            >
              <span className="label">工作经验：</span>
              <span className="show">{positionDetail.work_time_name}</span>
            </div>
            <div
              className={`info-item ${!detailWidthStatus && "single-row-show"}`}
            >
              <span className="label">薪酬结构：</span>
              <span className="show" title={positionDetail.salary_structure}>
                {positionDetail.salary_structure}
              </span>
            </div>
            <div
              className={`info-item ${!detailWidthStatus && "single-row-show"}`}
            >
              <span className="label">薪资(元)：</span>
              <span className="show">{positionDetail.salary_name}</span>
            </div>
            {/* {positionDetail.salary_ind ? (
              <div className={`info-item ${!detailWidthStatus&&"single-row-show"}`}>
                <span className="label">薪资：</span>
                <span className="show">{positionDetail.salary_name}</span>
              </div>
            ) : (
              <React.Fragment>
                <div className={`info-item ${!detailWidthStatus&&"single-row-show"}`}>
                  <span className="label">薪资类型：</span>
                  <span className="show">{positionDetail.salary_name}</span>
                </div>
                <div className={`info-item ${!detailWidthStatus&&"single-row-show"}`}>
                  <span className="label">薪资：</span>
                  <span className="show">
                    {positionDetail.salary_from} - {positionDetail.salary_to}
                  </span>
                </div>
              </React.Fragment>
            )} */}
            <div>
              {positionDetail.jd_address &&
                positionDetail.jd_address.map((item, index) => {
                  return (
                    <React.Fragment key={index}>
                      <div
                        className={`info-item ${!detailWidthStatus &&
                          "single-row-show"}`}
                      >
                        <span className="label">
                          工作地点{index + 1 > 1 ? index + 1 : ""}：
                        </span>
                        <span className="show">{this.createWork(item)}</span>
                      </div>
                      <div
                        className={`info-item ${!detailWidthStatus &&
                          "single-row-show"}`}
                      >
                        <span className="label">
                          详细地址{index + 1 > 1 ? index + 1 : ""}：
                        </span>
                        <span className="show" title={item.address}>
                          {item.address}
                        </span>
                      </div>
                    </React.Fragment>
                  );
                })}
            </div>
            <div className="info-item" style={{ width: "99%" }}>
              <span className="label">福利：</span>
              <span className="show">
                {positionDetail.benefits &&
                  positionDetail.benefits.map(item => item.name).join("、")}
              </span>
            </div>
          </div>

          <div className="info mt13">
            {/* <div className="info-item2 clear">
              <div className="left">工作地点：</div>
              <div className="right">
                
              </div>
            </div>
            <div className="info-item2 mb23 clear">
              <div className="left">详细地址：</div>
              <div className="right">{positionDetail.address}</div>
            </div> */}
            <div className="info-item2 des clear">
              <div className="left-label">职位描述：</div>
              <div className="show right-des">
                <span
                  dangerouslySetInnerHTML={createMarkup(positionDetail.jd_desc)}
                />
              </div>
            </div>
            <div className="info-item2 des clear">
              <div className="left-label">任职要求：</div>
              <div className="show right-des">
                <span
                  dangerouslySetInnerHTML={createMarkup(positionDetail.requirements)}
                />
              </div>
            </div>
          </div>
        </div>

        <style jsx global>
          {`
            .position-show-box {
              .info {
                padding: 0 12px;
                .show {
                  color: #333333;
                }
                .info-item {
                  float: left;
                  width: 47%;
                  margin-bottom: 10px;
                  margin-right: 21px;
                  line-height: 1.1;
                  color: #666666;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  .label {
                    padding-right: 5px;
                    color: #999999;
                    text-align: right;
                    display: inline-block;
                    width: 75px;
                  }
                  &:nth-child(even) {
                    margin-right: 0;
                  }
                }
                .single-row-show {
                  width: 98%;
                }
              }
              .mt13 {
                margin-top: 13px;
              }
              .mb23 {
                margin-bottom: 23px;
              }
              .info-item2 {
                margin-bottom: 10px;
                line-height: 1.1;
                display: flex;
                .left-label {
                  width: 70px;
                  color: #999999;
                  text-align: right;
                }
                .right-des {
                  flex: 1;
                  color: #666666;
                }
                &:last-child {
                  margin-bottom: 0;
                }
              }
              .des {
                line-height: 22px;
              }
            }
          `}
        </style>
      </React.Fragment>
    );
  }
}
/**
 * 职位信息编辑
 */

const PositionInfoEdit = Form.create()(
  @connect(
  ({ PositionVacantModal }) => {
    return { ...PositionVacantModal };
  },
  {
    ...PositionVacantModal.actions
  }
)
class PositionInfoForm extends PureComponent {
  state = {
    optionCompany: [],
    value: undefined,
    fetching: false,
    contactsoption: [],
    contactId: undefined, //默认的联系人id
    keys: []
  };
  componentDidMount() {
    const { jd_address = [] } = this.props.positionDetail;
    this.setState({
      keys: jd_address.length > 0 ? jd_address.map((item, index) => index) : [0]
    });
    console.log(this.props.positionDetail.jd_address.map((item, index) => index), 'this.props.positionDetail.jd_address.map((item, index) => index)')
  }
  handleSubmit = e => {
    const { validateFieldsAndScroll } = this.props.form;
    const { modPos, getDetail, positionDetail } = this.props;
    e.preventDefault();
    validateFieldsAndScroll((err, values) => {
      if (!err) {
        if (parseFloat(values.salary_from) > parseFloat(values.salary_to)) {
          Message.error("起始薪资不能大于终止薪资");
          return;
        }
        const dataParam = {
          ...values,
          id: positionDetail.id,
          address: values.address && values.address.map((item, index) => {
            let currentCity = values.city[index] ? values.city[index] : [];
            return {
              address: item,
              province_id: currentCity[0] ? currentCity[0] : "",
              city_id: currentCity[1] ? currentCity[1] : "",
              region_id: currentCity[2] ? currentCity[2] : ""
            };
          }),
          salary_ind: values.salary_ind ? 1 : 0,
          position:
            values.positionArray &&
            values.positionArray[values.positionArray.length - 1]
        };
        if (dataParam.some) dataParam.require_num = 0;
        delete dataParam.city;
        delete dataParam.positionArray;
        modPos({ ...dataParam }).then(res => {
          if (res.code === 0) {
            getDetail(positionDetail.id);
            this.close();
          }
        });
      }
    });
  };

  addWorkRow = () => {
    const { keys } = this.state;
    let currentKey = keys[keys.length - 1] ? keys[keys.length - 1] : 0;
    this.setState({
      keys: keys.concat(currentKey + 1)
    });
  };

  removeWorkRow = key => {
    const { keys } = this.state;
    this.setState({
      keys: keys.filter(item => item !== key)
    });
  };
  close = () => {
    this.props.updateRedux({
      positionEditDrawer: !this.props.positionEditDrawer
    });
  };

  render() {
    const formItemLayout = {
      labelCol: { span: 5 },
      wrapperCol: { span: 19 }
    };
    const formNumber = {
      labelCol: { span: 6 },
      wrapperCol: { span: 17 }
    };
    const formItemLayoutSingle = {
      labelCol: { span: 2 },
      wrapperCol: { span: 20 }
    };
    const colItemLayout = {
      span: 11
    };
    const size = "large";
    const {
      commonData = {},
      positionDetail = {},
    } = this.props;
    const {
      getFieldDecorator,
      getFieldValue,
      resetFields
    } = this.props.form;
    let salaryInd =
      getFieldValue("salary_ind") === undefined
        ? positionDetail.salary_ind
        : getFieldValue("salary_ind");
    let some =
      getFieldValue("some") === undefined
        ? positionDetail.require_num !== "若干"
          ? false
          : true
        : getFieldValue("some");
    const {
      keys
    } = this.state;
    return (
      <div>
        <Drawer
          width={900}
          placement="right"
          className="info-drawer-box position-info-edit"
          title="编辑职位"
          onClose={this.close}
          visible={true}
          style={{
            paddingBottom: 53
            // height:`${detailHeight+68}px`
          }}
        >
          <Form
            // style={{height:`${detailHeight+68}px`}}
            onSubmit={this.handleSubmit}
            className="edit-form"
            layout="horizontal"
          >
            <Row gutter={35}>
              <Row>
                <Col {...colItemLayout}>
                  <FormItem label="职位名称" {...formItemLayout}>
                    {getFieldDecorator("post_name", {
                      initialValue: positionDetail.post_name,
                      rules: [{ required: true, message: "请输入职位名称" }]
                    })(
                      <InputComplete
                        size={size}
                        requestFunc={companyService.getJdsByName}
                        placeholder="请输入"
                      />
                    )}
                  </FormItem>
                </Col>
                <Col {...colItemLayout}>
                  <FormItem
                    label="职&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;能"
                    {...formItemLayout}
                  >
                    {getFieldDecorator("positionArray", {
                      initialValue: positionDetail.position_pid
                        ? [
                          positionDetail.position_pid,
                          positionDetail.position
                        ]
                        : undefined,
                      rules: [{ required: true, message: "请选择职能" }]
                    })(
                      <Cascader
                        size={size}
                        options={commonData.dispatch_position}
                        fieldNames={{
                          label: "label",
                          value: "value",
                          children: "list"
                        }}
                        placeholder="请选择"
                      />
                    )}
                  </FormItem>
                </Col>
              </Row>
              <Row>
                <Col {...colItemLayout}>
                  <Col span={19}>
                    <FormItem label="需求人数" {...formNumber}>
                      {getFieldDecorator("require_num", {
                        initialValue:
                          positionDetail.require_num !== "若干" && !some
                            ? positionDetail.require_num
                            : undefined,
                        rules: [
                          { required: !some, message: "请输入需求人数" }
                        ]
                      })(
                        <Input
                          disabled={some}
                          style={{ marginLeft: 4 }}
                          placeholder="请输入"
                          size={size}
                        />
                      )}
                    </FormItem>
                  </Col>
                  <Col span={5}>
                    <FormItem label="">
                      {getFieldDecorator("some", {
                        initialValue:
                          positionDetail.require_num == "若干" ? 1 : 0,
                        rules: [],
                        valuePropName: "checked"
                      })(
                        <Checkbox
                          onChange={() => resetFields(["require_num"])}
                          style={{ width: "100%" }}
                        >
                          若干
                          </Checkbox>
                      )}
                    </FormItem>
                  </Col>
                </Col>
                <Col {...colItemLayout}>
                  <FormItem
                    label="学&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;历"
                    {...formItemLayout}
                  >
                    {getFieldDecorator("education", {
                      initialValue: positionDetail.education
                        ? positionDetail.education
                        : undefined,
                      rules: [{ required: false }]
                    })(
                      <Select size={size} allowClear placeholder="请输选择">
                        {commonData.jd_education &&
                          commonData.jd_education.map(item => {
                            return (
                              <Option key={item.value} value={item.value}>
                                {item.label}
                              </Option>
                            );
                          })}
                      </Select>
                    )}
                  </FormItem>
                </Col>
              </Row>
              <Row>
                <Col {...colItemLayout}>
                  <FormItem label="服务类型" {...formItemLayout}>
                    {getFieldDecorator("service_type", {
                      initialValue: positionDetail.service_type
                        ? positionDetail.service_type
                        : undefined,
                      rules: [{ required: true, message: "请选择服务类型" }]
                    })(
                      <Select size={size} allowClear placeholder="请输选择">
                        {commonData.service_type &&
                          commonData.service_type.map((item, index) => {
                            return (
                              <Option key={index} value={item.value}>
                                {item.label}
                              </Option>
                            );
                          })}
                      </Select>
                    )}
                  </FormItem>
                </Col>
                <Col {...colItemLayout}>
                  <FormItem label="职位性质" {...formItemLayout}>
                    {getFieldDecorator("work_type", {
                      initialValue: positionDetail.work_type
                        ? positionDetail.work_type
                        : undefined,
                      rules: [{ required: false }]
                    })(
                      <Select
                        size={size}
                        allowClear
                        placeholder="请选择职位性质"
                      >
                        {commonData.work_type &&
                          commonData.work_type.map((item, index) => {
                            return (
                              <Option key={index} value={item.value}>
                                {item.label}
                              </Option>
                            );
                          })}
                      </Select>
                    )}
                  </FormItem>
                </Col>
              </Row>

              <Row>
                <Col {...colItemLayout}>
                  <FormItem label="工作经验" {...formItemLayout}>
                    {getFieldDecorator("work_time", {
                      initialValue: positionDetail.work_time
                        ? positionDetail.work_time
                        : undefined,
                      rules: [{ required: true, message: "请选择工作经验" }]
                    })(
                      <Select
                        size={size}
                        allowClear
                        placeholder="请选择工作经验"
                      >
                        {commonData.jd_work_time &&
                          commonData.jd_work_time.map((item, index) => {
                            return (
                              <Option key={index} value={item.value}>
                                {item.label}
                              </Option>
                            );
                          })}
                      </Select>
                    )}
                  </FormItem>
                </Col>
                <Col {...colItemLayout}>
                  <FormItem label="薪酬结构" {...formItemLayout}>
                    {getFieldDecorator("salary_structure", {
                      initialValue: positionDetail.salary_structure,
                      rules: [{ required: false }]
                    })(<Input placeholder="请输入" size={size} />)}
                  </FormItem>
                </Col>
              </Row>

              <Row>
                <Col {...colItemLayout}>
                  <FormItem label="薪资类型" {...formItemLayout}>
                    {getFieldDecorator("salary_type", {
                      initialValue: salaryInd
                        ? undefined
                        : positionDetail.salary_type
                          ? positionDetail.salary_type
                          : undefined,
                      rules: [
                        { required: !salaryInd, message: "请选择薪资类型" }
                      ]
                    })(
                      <Select
                        size={size}
                        disabled={salaryInd}
                        allowClear
                        placeholder="请选择薪资类型"
                      >
                        {commonData.jd_salary_type &&
                          commonData.jd_salary_type.map((item, index) => {
                            return (
                              <Option key={index} value={item.value}>
                                {item.label}
                              </Option>
                            );
                          })}
                      </Select>
                    )}
                  </FormItem>
                </Col>
                <Col {...colItemLayout}>
                  <Row gutter={8} className="input-group-row ">
                    <InputGroup>
                      <Col
                        span={3}
                        className={`col-label col-label-3 ${!salaryInd &&
                          "ant-form-item-required"}`}
                      >
                        薪资(元)
                        </Col>
                      <Col span={6}>
                        <FormItem>
                          {getFieldDecorator("salary_from", {
                            initialValue: salaryInd
                              ? undefined
                              : positionDetail.salary_from
                                ? positionDetail.salary_from
                                : "",
                            rules: [
                              { required: !salaryInd, message: "最低薪资" }
                            ]
                          })(
                            <InputNumber
                              disabled={salaryInd}
                              min={1}
                              precision={0}
                              placeholder="最低"
                              size={size}
                              style={{ width: "100%" }}
                            />
                          )}
                        </FormItem>
                      </Col>
                      <Col span={1} className="col-label">
                        -
                        </Col>
                      <Col span={6}>
                        <FormItem label="">
                          {getFieldDecorator("salary_to", {
                            initialValue: salaryInd
                              ? undefined
                              : positionDetail.salary_to
                                ? positionDetail.salary_to
                                : "",
                            rules: [
                              { required: !salaryInd, message: "最高薪资" }
                            ]
                          })(
                            <InputNumber
                              disabled={salaryInd}
                              min={1}
                              precision={0}
                              placeholder="最高"
                              size={size}
                              style={{ width: "100%" }}
                            />
                          )}
                        </FormItem>
                      </Col>
                      <Col span={6}>
                        <FormItem label="">
                          {getFieldDecorator("salary_ind", {
                            initialValue: positionDetail.salary_ind,
                            rules: [],
                            valuePropName: "checked"
                          })(
                            <Checkbox
                              onChange={() =>
                                resetFields([
                                  "salary_type",
                                  "salary_from",
                                  "salary_to"
                                ])
                              }
                              style={{ width: "100%" }}
                            >
                              面议
                              </Checkbox>
                          )}
                        </FormItem>
                      </Col>
                    </InputGroup>
                  </Row>
                </Col>
              </Row>

              {keys &&
                keys.map(key => {
                  return (
                    <Row key={key}>
                      <Col {...colItemLayout}>
                        <FormItem label="工作地点" {...formItemLayout}>
                          {getFieldDecorator(`city[${key}]`, {
                            initialValue:
                              positionDetail.jd_address &&
                                positionDetail.jd_address[key]
                                ? [
                                  positionDetail.jd_address[key]
                                    .province_id,
                                  positionDetail.jd_address[key].city_id,
                                  positionDetail.jd_address[key].region_id
                                ]
                                : undefined,
                            rules: [
                              { required: true, message: "请选择工作地点" }
                            ]
                          })(
                            <CityCascade
                              changeOnSelect
                              size={size}
                              options={commonData.cities}
                              fieldNames={{
                                label: "name",
                                value: "id",
                                children: "list"
                              }}
                              placeholder="请选择"
                            />
                          )}
                        </FormItem>
                      </Col>
                      <Col {...colItemLayout}>
                        <FormItem label="详细地址" {...formItemLayout}>
                          {getFieldDecorator(`address[${key}]`, {
                            initialValue: positionDetail.jd_address[key]
                              ? positionDetail.jd_address[key].address
                              : "",
                            rules: [{ required: false }]
                          })(<Input placeholder="请输入" size={size} />)}
                        </FormItem>
                      </Col>
                      <Col span={1}>
                        <span
                          className="ant-row ant-form-item"
                          style={{ paddingTop: "12px" }}
                        >
                          {keys.length > 1 && (
                            <Icon
                              type="minus-circle-o"
                              onClick={() => this.removeWorkRow(key)}
                            />
                          )}
                        </span>
                      </Col>
                    </Row>
                  );
                })}

              <Row>
                <Col
                  {...colItemLayout}
                  style={{ paddingLeft: "83px", marginBottom: "20px" }}
                >
                  <Button
                    icon="plus"
                    size={size}
                    onClick={() => this.addWorkRow()}
                  >
                    新增工作地点
                    </Button>
                </Col>
              </Row>

              <Row className="single-row">
                <FormItem label="福利" {...formItemLayoutSingle}>
                  {getFieldDecorator("benefits", {
                    initialValue: positionDetail.benefits.map(
                      item => item.id
                    ),
                    rules: [{ required: false }]
                  })(
                    <Select
                      mode="multiple"
                      allowClear showSearch optionFilterProp="children"
                      size={size}
                      style={{ width: "100%" }}
                      placeholder="请选择"
                    >
                      {commonData.jd_benefit &&
                        commonData.jd_benefit.map((item, index) => {
                          return (
                            <Option key={index} value={item.value}>
                              {item.label}
                            </Option>
                          );
                        })}
                    </Select>
                  )}
                </FormItem>
              </Row>
              <Row className="single-row">
                <FormItem label="职位描述" {...formItemLayoutSingle}>
                  {getFieldDecorator("jd_desc", {
                    initialValue: positionDetail.jd_desc,
                    rules: [{ required: true, message: "请输入职位描述" }]
                  })(<TextArea rows={7} placeholder="请输入职位描述" />)}
                </FormItem>
              </Row>
              <Row className="single-row">
                <FormItem label="任职要求" {...formItemLayoutSingle}>
                  {getFieldDecorator("requirements", {
                    initialValue: positionDetail.requirements,
                    // rules: [{ required: true, message: "请输入任职要求" }]
                  })(<TextArea rows={7} placeholder="请输入任职要求" />)}
                </FormItem>
              </Row>
            </Row>
            <div
              style={{
                position: "absolute",
                bottom: 0,
                width: "100%",
                borderTop: "1px solid #e8e8e8",
                padding: "10px 16px",
                textAlign: "left",
                left: 0,
                background: "#fff",
                borderRadius: "0 0 4px 4px"
              }}
            >
              <Button
                style={{
                  marginRight: 8,
                  background: "#1890ff",
                  border: "1px solid"
                }}
                type="primary"
                onClick={this.handleSubmit}
              >
                保存
                </Button>
              <Button onClick={this.close}>取消</Button>
            </div>
          </Form>
        </Drawer>
        <style jsx global>{`
            .position-info-edit {
              .ant-drawer-header {
                background: #1890ff;
              }
              .ant-row {
                padding-left: 10px;
              }
              .input-group-row .ant-row {
                padding-left: 0;
              }
              .col-label-3 {
                width: 20.33%;
                text-align: right;
              }
              .col-label {
                display: inline-block;
                padding-top: 10px;
              }
              .single-row .ant-form-item-label {
                width: 9.333333%;
              }
            }
          `}</style>
      </div>
    );
  }
}
);
export { PositionInfoShow, PositionInfoEdit };
