import React, { PureComponent } from "react";
import { Form, Icon, Input, Button, Checkbox } from "antd";
import { history } from "root";
import { connect } from "react-redux";
import { v4 as uuid } from 'uuid'
import userModel from "store/reducers/user";
import CaptchaInput from 'components/CaptchaInput'
import { reloadAuthorized } from "utils/Authorized";
import { clueNotification, businessNotification } from "components/notification";
import containerBg from 'assets/imgs/login-container-bg.png';
import { getCaptchaImage } from "../../services/user";

const FormItem = Form.Item;
const LoginForm = Form.create()(
  class Login extends PureComponent {
    state = {
      captchaImg: '',
      captchaSalt: ''
    }
    handleSubmit = e => {
      const { login, history } = this.props;
      const { captchaSalt } = this.state;
      e.preventDefault();
      this.props.form.validateFields((err, values) => {
        if (!err) {
          values['captcha_salt'] = captchaSalt
          login(values).then(res => {
            if (res.code === 0) {
              if (res.data.clue_remind) {
                clueNotification();
              }
              if (res.data.business_remind) {
                businessNotification();
              }
            } else {
              this.generateCapchaImage();
            }
          })
        }
      });
    };
    componentDidMount() {
      this.generateCapchaImage()
    }

    generateCapchaImage = () => {
      const uid = uuid()
      let captchaImg = `admin/v1/common/captcha?captcha_salt=${uid}`
      if (process.env.NODE_ENV === 'development') {
        captchaImg = 'http://test.new.geetemp.cn/' + captchaImg
      }
      this.setState({
        captchaImg,
        captchaSalt: uid
      })
    }

    handleForgetPasswd = () => {
      history.replace("/user/forget");
    }

    render() {
      const { getFieldDecorator } = this.props.form;
      const { captchaImg } = this.state;
      return (
        <div className="login-container">
          <div className="left-content"></div>
          <Form onSubmit={this.handleSubmit} className="login-form">
            <div className="title">Login</div>
            <div className="title-tip">高效的青年白领灵活用工平台</div>
            <FormItem>
              {getFieldDecorator("username", {
                rules: [{ required: true, message: "请输入您的账号" }]
              })(
                <Input size="large"
                  prefix={
                    <Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />
                  }
                  placeholder="请输入您的账号"
                />
              )}
            </FormItem>
            <FormItem>
              {getFieldDecorator("password", {
                rules: [{ required: true, message: "请输入您的密码" }]
              })(
                <Input size="large"
                  prefix={
                    <Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />
                  }
                  type="password"
                  placeholder="请输入您的密码"
                />
              )}
            </FormItem>

            <FormItem>
              {getFieldDecorator("verify_code", {
                rules: [{ required: true, message: "请输入验证码" }]
              })(
                <CaptchaInput url={captchaImg} placeholder="请输入右侧验证码" onCapchaClick={this.generateCapchaImage} />
              )}
            </FormItem>

            <FormItem className="checkbox-item">
              {getFieldDecorator("remember_me", {
                valuePropName: "checked",
                initialValue: true
              })(<Checkbox>自动登录</Checkbox>)}
            </FormItem>

            <FormItem>
              <Button
                type="primary" shape="round" block size="large"
                htmlType="submit"
                className="login-form-button"
              >
                登录
              </Button>
            </FormItem>
            <a onClick={this.handleForgetPasswd}>忘记密码？</a>
          </Form>
          <style jsx global>
            {`
                .login-container {
                  display: flex;
                  width: 860px;
                  height: 520px;
                  background-color: #ffffff;
                  box-shadow: 0px 6px 20px 0px rgba(0, 0, 0, 0.04);
                  border-radius: 4px;
                  margin: 0 auto;
                  .left-content {
                    width: 500px;
                    height: 100%;
                    background: url('${containerBg}') center no-repeat;
                  }
                  .login-form {
                    padding-right: 70px;
                    width: 360px;
                    .title {
                      padding-top: 117px;
                      padding-bottom: 17px;
                      font-size: 24px;
                      font-weight: bold;
                    }
                    .title-tip {
                      padding-bottom: 34px;
                      font-size: 15px;
                      font-weight: bold;
                    }

                    input {
                      border-radius: 21px;
                      background-color: #f0f1f5;
                      border: 0;
                      &:-webkit-autofill {
                        background-color: #f0f1f5 !important;
                      }
                    }
                    .ant-form-item-control {
                      line-height: 1;
                    }
                    .ant-form-explain {
                      padding-left: 20px;
                      padding-top: 3px;
                    }
                    .checkbox-item {
                      padding-left: 20px;
                      .ant-checkbox-wrapper {
                        color: #bbbbbf;
                      }
                      .ant-checkbox-inner {
                        border-color: #f0f1f5;
                        background-color: #f0f1f5;
                      }
                      .ant-checkbox-checked .ant-checkbox-inner {
                        border-color: #00DD95;
                        background-color: #00DD95;
                      }
                    }
                    .login-form-button {
                      font-size: 15px;
                      background-color: #00dc94;
                      border-color: #00dc94;
                    }
                  }
                }
              `}
          </style>
        </div>
      );
    }
  }
);

export default connect(
  null,
  { ...userModel.actions }
)(LoginForm);
