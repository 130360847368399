import Model from "../Model";
import * as socialCandService from "services/socialCandidate";
import {
  BaseInfoEditFieldnames,
  jobStatusEditFieldnames,
  jobExperienceFieldnames,
  educationFieldnames,
  certificateFieldnames
} from "pages/TalentPool/fieldsConfig";
import { convertSubmitJobStatus } from "pages/TalentPool/interfaceDataConvert";
import _ from "underscore";
import {
  cityNamejoint,
  convertProExperience,
  convertWorkExp,
  convertEduExperience,
  convertCertExperience
} from "pages/TalentPool/interfaceDataConvert";
import { history } from "root";
import { Message } from "antd";

export default Model.getInstance(
  class extends Model {
    namespace = "socialCandidate";
    state = {
      candlist: {},
      candlistLoading: false,
      searchCondition: {},

      //简历创建
      createResumeDrawer: {
        visible: false,
        openType: "create", //create或import
        title: "创建社会人选"
      },

      //简历导入
      resumeImport: {
        visible: false,
        finish: false // 解析完成
      },

      candidate: {
        candStatus: {
          statusModal: false,
          nowStatus: undefined,
          selectedStatusValue: {},
          statusReason: ""
        },
        // id: 24866,
        id: "",
        detail: {
          baseInfo: {},
          jobStatus: {},
          educations: [],
          jobExperiences: [],
          certificates: [],
          proExperiences: [],
          resumeAttachment: ""
        },
        positionProgresses: [],
        communicationRecords: {
          list: [],
          addModal: false,
          contactType: "",
          contactContext: ""
        }
      },

      //新增候选人(包括解析的候选人)
      newCandidate: {
        baseInfo: {},
        jobStatus: {},
        educations: [],
        jobExperiences: [],
        certificates: [],
        proExperiences: [],
        resumeAttachment: ""
      },

      profileDetail: {},
      recordJDs: {},
      isClear: false, //收藏的状态是否回显初始值
      newResumeStatus: false, //新简历列表抽屉状态
      newResumeInfo: {}, //新简历信息
      searchNewParams: { "per-page": 10,page:1  }, //搜索新简历的参数
      IsSocial: "social", //新简历所需转态
      exportVisible: false, //沟通记录导出弹窗
      folderFilter: false,
      isSure:true,//是否点击了搜索确认
      
    };

    actions = {
      //即派预览简历
      async getProfileDetail(param) {
        let result = await socialCandService.getProfileDetail(param);
        if (result.code == 0) {
          this.dispatch({
            type: "socialCandidate/updateDetail",
            payload: result.data
          });
        }
        return result;
      },

      //迅致预览简历
      async getSoonfitProfileDetail(param) {
        let result = await socialCandService.getSoonfitProfileDetail(param);
        if (result.code == 0) {
          this.dispatch({
            type: "socialCandidate/updateDetail",
            payload: result.data
          });
        }
        return result;
      },

      async addCommunicate(values) {
        const {
          communicationRecords,
          id
        } = this.getState().socialCandidate.candidate;
        const { contactType, contactContext, list } = communicationRecords;
        let result = await socialCandService.addCommunicateRecord({
          // profile_id: id,
          // communication_type: contactType,
          // content: contactContext
          ...values
        });
        if (result.code === 0) {
          list.unshift(result.data);
          this.dispatch({
            type: "socialCandidate/updateCommunicationRecords",
            payload: {
              addModal: false
              // contactType: "",
              // contactContext: ""
            }
          });
        }
        return result;
      },
      async deleteCommunicate(params) {
        let result = await socialCandService.deleteCommunicateRecord(params);
        if (result.code === 0) {
        }
        return result;
      },
      async commitAudit() {
        const { candStatus, id } = this.getState().socialCandidate.candidate;
        const { selectedStatusValue, statusReason } = candStatus;
        let result = await socialCandService.commitAudit({
          profile_id: id,
          apply_type: selectedStatusValue.key,
          apply_reson: statusReason
        });
        if (result.code === 0) {
          this.dispatch({
            type: "socialCandidate/updateCandStatus",
            payload: {
              statusModal: false,
              statusReason: ""
              // nowStatus: selectedStatusValue
            }
          });
          this.dispatch({
            type: "socialCandidate/updateBaseInfo",
            payload: { audit: 1, status_label: "审核中" }
          });
        }
      },

      async getSocialCandList(param) {
        this.dispatch({
          type: "socialCandidate/updateCandlistLoading",
          payload: true
        });
        const { searchCondition } = this.getState().socialCandidate;
        let result = await socialCandService.candList({
          // "per-page": 10,
          ...searchCondition,
          // position: searchCondition.position && searchCondition.position[1],
          ...param
        });
        if (result.code == 0) {
          this.dispatch({
            type: "socialCandidate/updateCandList",
            payload: result.data
          });
        }
        this.dispatch({
          type: "socialCandidate/updateCandlistLoading",
          payload: false
        });
      },

      //获取迅致人选列表
      async getSoonfitCandList(param) {
        this.dispatch({
          type: "socialCandidate/updateCandlistLoading",
          payload: true
        });
        const { searchCondition } = this.getState().socialCandidate;
        let result = await socialCandService.soonfitCandList({
          ...searchCondition,
          ...param
        });
        if (result.code == 0) {
          this.dispatch({
            type: "socialCandidate/updateCandList",
            payload: result.data
          });
        }
        this.dispatch({
          type: "socialCandidate/updateCandlistLoading",
          payload: false
        });
      },

      async getSocialCandDetail(id) {
        // const { candidate } = this.getState().socialCandidate;
        let result = await socialCandService.candDetail(id);
        if (result.code === 0) {
          result = result.data;
          this.dispatch({
            type: "socialCandidate/updateCandidateDetail",
            payload: {
              baseInfo: result.base_info,
              jobStatus: result.job,
              educations: result.education.map(item => convertEduExperience(item)),
              jobExperiences: result.work.map(item => convertWorkExp(item)),
              certificates: result.certificate.map(item => convertCertExperience(item)),
              proExperiences: result.project.map(pro =>
                convertProExperience(pro)
              ),
              resumeAttachment: result.resume_attachment,
              is_collection: result.is_collection
            }
          });
          this.dispatch({
            type: "socialCandidate/updatePositionProgresses",
            payload: result.profile_jd
          });
          this.dispatch({
            type: "socialCandidate/updateCommunicationRecords",
            payload: { list: result.profile_communication }
          });
        }
        return result;
      },

      //获取迅致人选详情
      async getSoonfitCandDetail(id) {
        // const { candidate } = this.getState().socialCandidate;
        let result = await socialCandService.soonfitCandDetail(id);
        if (result.code === 0) {
          result = result.data;
          this.dispatch({
            type: "socialCandidate/updateCandidateDetail",
            payload: {
              baseInfo: result.base_info,
              jobStatus: result.job,
              educations: result.education.map(item => convertEduExperience(item)),
              jobExperiences: result.work.map(item => convertWorkExp(item)),
              certificates: result.certificate.map(item => convertCertExperience(item)),
              proExperiences: result.project.map(pro =>
                convertProExperience(pro)
              ),
              resumeAttachment: result.resume_attachment || [],
              is_collection: result.is_collection
            }
          });
          this.dispatch({
            type: "socialCandidate/updatePositionProgresses",
            payload: result.profile_jd || []
          });
          this.dispatch({
            type: "socialCandidate/updateCommunicationRecords",
            payload: { list: result.profile_communication || [] }
          });
        }
        return result;
      },

      async editBaseInfo(values) {
        const { candidate } = this.getState().socialCandidate;
        const {
          detail: { baseInfo },
          id
        } = candidate;
        // let convertBaseInfo = _.pick(baseInfo, BaseInfoEditFieldnames);
        const { city = {}, native = {}, tags = [] } = values;
        let result = await socialCandService.editBaseInfo({
          ...values,
          profile_id: id,
          city: `${city.province_label ? city.province_label : ""}${
            city.city_label ? `-${city.city_label}` : ""
          }${city.region_label ? `-${city.region_label}` : ""}`,
          native: `${native.province_label ? native.province_label : ""}${
            native.city_label ? `-${native.city_label}` : ""
          }${native.region_label ? `-${native.region_label}` : ""}`,
          tags: tags.map(item => item.value)
        });
        //编辑成功
        if (result.code === 0) {
          //为了清空编辑后还原异常空
          if(!native.province_label)values.native=undefined;
          if(!city.city_label)values.city=undefined;
          
          this.dispatch({
            type: "socialCandidate/updateBaseInfo",
            payload: { ...values, isEdit: false }
          });
        } else if (result.code === 405) {
          if (values.existedCallBack) {
            values.existedCallBack(result);
          }
        } else {
          Message.error(result.transformError);
        }
        return result;
      },

      async editJobStatus(values) {
        const { candidate } = this.getState().socialCandidate;
        const {
          detail: { jobStatus },
          id
        } = candidate;
        // jobStatusEditFieldnames.push("forward_city");
        // const editJobStatusFields = _.pick(jobStatus, jobStatusEditFieldnames);
        const convertedValues = convertSubmitJobStatus(values);
        let result = await socialCandService.editJobStatus({
          ...convertedValues,
          profile_id: id
        });
        //编辑成功
        if (result.code === 0) {
          this.dispatch({
            type: "socialCandidate/updateJobStatus",
            payload: { ...values, isEdit: false }
          });
        }
        return result;
      },

      //保存教育经历
      async saveEducation(index, values) {
        const { candidate } = this.getState().socialCandidate;
        const {
          detail: { educations },
          id
        } = candidate;
        const education = educations[index];
        const { education_id } = education;
        const { end_time } = values;
        const isCreate = /create/.test(education_id);
        let result = await socialCandService.editOrAddEducation({
          ...values,
          end_time: /\d{4}\/\d{2}/.test(end_time) ? end_time : 0, //如果结束时间是至今，则提交0
          profile_id: id,
          education_id: isCreate ? "" : education_id
        });
        if (result.code === 0) {
          const convertValue = convertEduExperience(values);
          const updateValues = isCreate
            ? {
                ...convertValue,
                education_id: result.data.education_id,
                isEdit: false
              }
            : {
                ...convertValue,
                last_education: result.data.education,
                education_id: education_id,
                isEdit: false
              };
          this.dispatch({
            type: "socialCandidate/updateEducation",
            payload: { index, values: updateValues }
          });
        }
        return result;
      },

      async removeEducation(index) {
        const { candidate } = this.getState().socialCandidate;
        const {
          detail: { educations },
          id
        } = candidate;
        const education = educations[index];
        const { education_id } = education;
        const param = { profile_id: id, type: "education", id: education_id };
        let result = await socialCandService.removeItem(param);
        if (result.code === 0) {
          this.dispatch({
            type: "socialCandidate/cleanEducation",
            payload: { index, lastData: result.data }
          });
        }
        return result;
      },

      async saveJobExperience(index, values) {
        const { candidate } = this.getState().socialCandidate;
        const {
          detail: { jobExperiences },
          id
        } = candidate;
        const jobExperience = jobExperiences[index];
        const { job_id } = jobExperience;
        const isCreate = /create/.test(job_id);
        const { end_time } = values;
        let result = await socialCandService.editOrAddJobExperience({
          ...values,
          end_time: /\d{4}\/\d{2}/.test(end_time) ? end_time : 0, //如果结束时间是至今，则提交0
          profile_id: id,
          job_id: isCreate ? "" : job_id
        });
        if (result.code === 0) {
          const convertValue = convertWorkExp(values);
          const updateValues = isCreate
            ? {
                ...convertValue,
                job_id: result.data.job_id,
                isEdit: false
              }
            : { ...convertValue, isEdit: false, job_id: job_id };
          this.dispatch({
            type: "socialCandidate/updateJobExperience",
            payload: { index, values: updateValues }
          });
        }
        return result;
      },

      async saveProExperience(index, values) {
        const { candidate } = this.getState().socialCandidate;
        const {
          detail: { proExperiences },
          id
        } = candidate;
        const proExperience = proExperiences[index];
        const { project_id } = proExperience;
        const isCreate = /create/.test(project_id);
        const { end_time } = values;
        let result = await socialCandService.editOrAddProExperience({
          ...values,
          end_time: /\d{4}\/\d{2}/.test(end_time) ? end_time : 0, //如果结束时间是至今，则提交0
          profile_id: id,
          project_id: isCreate ? "" : project_id
        });
        if (result.code === 0) {
          const convertValue = convertProExperience(values);
          const updateValues = isCreate
            ? {
                ...convertValue,
                project_id: result.data.project_id,
                isEdit: false
              }
            : { ...convertValue, isEdit: false, project_id: project_id };
          this.dispatch({
            type: "socialCandidate/updateProExperience",
            payload: { index, values: updateValues }
          });
        }
        return result;
      },

      async removeProExperience(index) {
        const { candidate } = this.getState().socialCandidate;
        const {
          detail: { proExperiences },
          id
        } = candidate;
        const proExperience = proExperiences[index];
        const { project_id } = proExperience;
        const param = { profile_id: id, type: "project", id: project_id };
        let result = await socialCandService.removeItem(param);
        if (result.code === 0) {
          this.dispatch({
            type: "socialCandidate/cleanProExperience",
            payload: index
          });
        }
        return result;
      },

      async removeJobExperience(index) {
        const { candidate } = this.getState().socialCandidate;
        const {
          detail: { jobExperiences },
          id
        } = candidate;
        const jobExperience = jobExperiences[index];
        const { job_id } = jobExperience;
        const param = { profile_id: id, type: "job", id: job_id };
        let result = await socialCandService.removeItem(param);
        if (result.code === 0) {
          this.dispatch({
            type: "socialCandidate/cleanJobExperience",
            payload: index
          });
        }
        return result;
      },

      async saveCertificate(index, values) {
        const { candidate } = this.getState().socialCandidate;
        const {
          detail: { certificates },
          id
        } = candidate;
        const certificate = certificates[index];
        const { cert_id } = certificate;
        const isCreate = /create/.test(cert_id);
        let result = await socialCandService.editOrAddCertificate({
          ...values,
          profile_id: id,
          cert_id: isCreate ? "" : cert_id
        });
        if (result.code === 0) {
          const convertValue = convertCertExperience(values);
          const updateValues = isCreate
            ? {
                ...convertValue,
                cert_id: result.data.cert_id,
                isEdit: false
              }
            : { ...convertValue, isEdit: false, cert_id: cert_id };
          this.dispatch({
            type: "socialCandidate/updateCertificate",
            payload: { index, values: updateValues }
          });
        }
        return result;
      },

      async removeCertificate(index) {
        const { candidate } = this.getState().socialCandidate;
        const {
          detail: { certificates },
          id
        } = candidate;
        const certificate = certificates[index];
        const { cert_id } = certificate;
        const param = { profile_id: id, type: "certificate", id: cert_id };
        let result = await socialCandService.removeItem(param);
        if (result.code === 0) {
          this.dispatch({
            type: "socialCandidate/cleanCertificate",
            payload: index
          });
        }
        return result;
      },
      //新增人选
      async createCandidate(param) {
        const {
          createResumeDrawer: { openType },
          newCandidate: { resumeAttachment }
        } = this.getState().socialCandidate;
        console.log("createCandidatessss", param)
        //如果是导入简历，则提交附件参数
        if (openType === "import") {
          param.resume_attachment = resumeAttachment;
        }
        const { forward_city, profile_school, profile_job } = param;
        param = {
          ...param,
          profile_school: profile_school.map(item => ({
            ...item,
            end_time: /\d{4}\/\d{2}/.test(item.end_time) ? item.end_time : 0
          })),
          profile_job: profile_job.map(item => ({
            ...item,
            end_time: /\d{4}\/\d{2}/.test(item.end_time) ? item.end_time : 0
          })),
          forward_city: forward_city.map(item => cityNamejoint(item)).join(",")
        };

        let result = await socialCandService.candCreate(param);
        if (result.code === 0) {
          const candidate = result.data;
          const { candlist } = this.getState().socialCandidate;
          const newlist = [...(candlist.list || [])];
          newlist.unshift(candidate);
          newlist.pop();
          const newCandlist = { ...candlist, list: newlist };
          if (param.needJump) {
            this.dispatch({
              type: "socialCandidate/updateCandList",
              payload: newCandlist
            });
          }
          this.dispatch({
            type: "socialCandidate/updateCreateResumeDrawer",
            payload: { visible: false }
          });
          this.dispatch({
            type: "socialCandidate/updateResumeImport",
            payload: { finish: false }
          });
          //关闭每天都有新简历抽屉
          this.dispatch({
            type: "socialCandidate/setUpdateState",
            payload: {
              filed: "newResumeStatus",
              value: false
            }
          });
          this.dispatch({
            type: "socialCandidate/updateNewCandidate",
            payload: {
              baseInfo: {},
              jobStatus: {},
              educations: [],
              jobExperiences: [],
              certificates: [],
              proExperiences: [],
              resumeAttachment: ""
            }
          });
          if (param.needJump) {
            history.push({
              pathname: "/candidate/talent-pool",
              state: { open: result.data.id, is_intern: false }
            });
          }
        } else if (result.code === 405) {
          if (param.existedCallBack) {
            param.existedCallBack(result);
          }
        } else {
          Message.error(result.transformError);
        }
        return result;
      },

      async getJDList(param) {
        let result = await socialCandService.getJDs(param);
        if (result.code == 0) {
          this.dispatch({
            type: "socialCandidate/updateJDs",
            payload: result.data
          });
        }
        return result;
      },
      async coverResume(param) {
        let result = await socialCandService.coverProfiles(param);
        return result;
      },
      async getNewResumeList(params) {
        let result = await socialCandService.newResumeList(params);
        this.dispatch({
          type: "socialCandidate/setUpdateState",
          payload: { filed: "newResumeInfo", value: result.data }
        });
        return result;
      },
      async exportRecords(params) {
        let result = await socialCandService.exportRecords(params);
        return result;
      },
      //获取迅致人选详情电话邮箱
      async getSoonfitResumeContact(id) {
        let result = await socialCandService.getSoonfitResumeContact(id);
        if (result.code === 0) {
          result = result.data;
          this.dispatch({
            type: "socialCandidate/updateBaseInfo",
            payload: {
              email: result.email,
              phone: result.phone
            }
          });
        }
        return result;
      }
    };

    reducers = {
      updateCandListView(state, { payload: data }) {
        state.candlist.list[data].is_view = 1;
        return { ...state };
      },
      updateCandList(state, { payload: data }) {
        return { ...state, candlist: data };
      },
      updateCandlistLoading(state, { payload: data }) {
        return { ...state, candlistLoading: data };
      },
      updateSearchCondition(state, { payload: data }) {
        const { searchCondition } = state;
        return { ...state, searchCondition: { ...searchCondition, ...data } };
      },
      clearSearchCondition(state) {
        return { ...state, searchCondition: {} };
      },
      selectCandidate(state, { payload: id }) {
        state.candidate.id = id;
        return { ...state };
      },
      updateCandidateDetail(state, { payload: detail }) {
        state.candidate.detail = detail;
        // state.candidate.cloneDetail = JSON.parse(JSON.stringify(detail));
        return { ...state };
      },
      updateBaseInfo(state, { payload: data }) {
        const { baseInfo } = state.candidate.detail;
        state.candidate.detail.baseInfo = { ...baseInfo, ...data };
        return { ...state };
      },
      updateJobStatus(state, { payload: data }) {
        const { jobStatus } = state.candidate.detail;
        state.candidate.detail.jobStatus = { ...jobStatus, ...data };
        return { ...state };
      },
      updateEducation(state, { payload: data }) {
        const { educations } = state.candidate.detail;
        const { index, values } = data;
        const item = { ...educations[index], ...values };
        educations.splice(index, 1, item);
        state.candidate.detail.educations = [...educations];
        return { ...state };
      },
      addEducation(
        state,
        {
          payload: { limit }
        }
      ) {
        const { educations } = state.candidate.detail;
        if (limit && educations.length) {
          const lastEducation = educations[educations.length - 1];
          //每次只能新建一个
          if (/create/.test(lastEducation.education_id)) return state;
        }
        const newEducation = {};
        for (const key of educationFieldnames) {
          newEducation[key] = undefined;
        }
        newEducation.education_id = `create-${new Date().getTime()}`;
        newEducation.isEdit = true;
        educations.push(newEducation);
        state.candidate.detail.educations = [...educations];
        return { ...state };
      },
      cancelEducation(state, { payload: index }) {
        const { educations } = state.candidate.detail;
        const education = educations[index];
        //如果是新建
        if (/create/.test(education.education_id)) {
          educations.splice(index, 1);
        } else {
          //编辑
          const newEducation = { ...education, isEdit: false };
          educations.splice(index, 1, newEducation);
        }
        state.candidate.detail.educations = [...educations];
        return { ...state };
      },
      cleanEducation(state, { payload: data }) {
        const { index, lastData } = data;
        const { educations } = state.candidate.detail;
        educations.splice(index, 1);
        state.candidate.detail.educations = [...educations];
        return { ...state };
      },
      updateJobExperience(state, { payload: data }) {
        const { jobExperiences } = state.candidate.detail;
        const { index, values } = data;
        const item = { ...jobExperiences[index], ...values };
        jobExperiences.splice(index, 1, item);
        state.candidate.detail.jobExperiences = [...jobExperiences];
        return { ...state };
      },
      addJobExperience(
        state,
        {
          payload: { limit }
        }
      ) {
        const { jobExperiences } = state.candidate.detail;
        if (limit && jobExperiences.length) {
          const lastJobExperience = jobExperiences[jobExperiences.length - 1];
          //每次只能新建一个
          if (/create/.test(lastJobExperience.job_id)) return state;
        }
        const newJobExperience = {};
        newJobExperience.job_id = `create-${new Date().getTime()}`;
        newJobExperience.isEdit = true;
        jobExperiences.push(newJobExperience);
        state.candidate.detail.jobExperiences = [...jobExperiences];
        return { ...state };
      },
      cleanJobExperience(state, { payload: index }) {
        const { jobExperiences } = state.candidate.detail;
        // const cloneJobExperiences = state.candidate.cloneDetail.jobExperiences;
        // cloneJobExperiences.splice(index, 1);
        jobExperiences.splice(index, 1);
        state.candidate.detail.jobExperiences = [...jobExperiences];
        return { ...state };
      },
      cancelJobExperience(state, { payload: index }) {
        const { jobExperiences } = state.candidate.detail;
        // const cloneJobExperiences = state.candidate.cloneDetail.jobExperiences;
        const jobExperience = jobExperiences[index];
        //如果是新建
        if (/create/.test(jobExperience.job_id)) {
          jobExperiences.splice(index, 1);
        } else {
          //编辑
          // const cloneItem = cloneJobExperiences[index];
          const newJobExperience = { ...jobExperience, isEdit: false };
          jobExperiences.splice(index, 1, newJobExperience);
        }
        state.candidate.detail.jobExperiences = [...jobExperiences];
        return { ...state };
      },
      updateCertificate(state, { payload: data }) {
        const { certificates } = state.candidate.detail;
        const { index, values } = data;
        const item = { ...certificates[index], ...values };
        certificates.splice(index, 1, item);
        state.candidate.detail.certificates = [...certificates];
        return { ...state };
      },
      addCertificate(
        state,
        {
          payload: { limit }
        }
      ) {
        const { certificates } = state.candidate.detail;
        if (limit && certificates.length) {
          const lastCertificate = certificates[certificates.length - 1];
          //每次只能新建一个
          if (/create/.test(lastCertificate.cert_id)) return state;
        }
        const newCertificate = {};
        for (const key of certificateFieldnames) {
          newCertificate[key] = undefined;
        }
        newCertificate.cert_id = `create-${new Date().getTime()}`;
        newCertificate.isEdit = true;
        certificates.push(newCertificate);
        state.candidate.detail.certificates = [...certificates];
        return { ...state };
      },
      cancelCertificate(state, { payload: index }) {
        const { certificates } = state.candidate.detail;
        const certificate = certificates[index];
        //如果是新建
        if (/create/.test(certificate.cert_id)) {
          certificates.splice(index, 1);
        } else {
          //编辑
          const newCertificate = { ...certificate, isEdit: false };
          certificates.splice(index, 1, newCertificate);
        }
        state.candidate.detail.certificates = [...certificates];
        return { ...state };
      },
      cleanCertificate(state, { payload: index }) {
        const { certificates } = state.candidate.detail;
        certificates.splice(index, 1);
        state.candidate.detail.certificates = [...certificates];
        return { ...state };
      },

      updatePositionProgresses(state, { payload: postionProgresses }) {
        state.candidate.positionProgresses = postionProgresses;
        return { ...state };
      },

      updateCommunicationRecords(state, { payload: data }) {
        const { communicationRecords } = state.candidate;
        state.candidate.communicationRecords = {
          ...communicationRecords,
          ...data
        };
        return { ...state };
      },

      updateCandStatus(state, { payload: data }) {
        const { candStatus } = state.candidate;
        state.candidate.candStatus = { ...candStatus, ...data };
        return { ...state };
      },

      updateCreateResumeDrawer(state, { payload: data }) {
        const { createResumeDrawer } = state;
        return {
          ...state,
          createResumeDrawer: { ...createResumeDrawer, ...data }
        };
      },

      updateResumeImport(state, { payload: data }) {
        const { resumeImport } = state;
        return {
          ...state,
          resumeImport: { ...resumeImport, ...data }
        };
      },

      updateNewCandidate(state, { payload: detail }) {
        state.newCandidate = detail;
        return { ...state };
      },

      updateNewBaseInfo(state, { payload: data }) {
        const { baseInfo } = state.newCandidate;
        state.newCandidate.baseInfo = { ...baseInfo, ...data };
        return { ...state };
      },

      updateNewProExperience(state, { payload: data }) {
        const { proExperiences } = state.newCandidate;
        const { index, values } = data;
        const item = { ...proExperiences[index], ...values };
        proExperiences.splice(index, 1, item);
        state.newCandidate.proExperiences = [...proExperiences];
        return { ...state };
      },

      updateNewJobExperience(state, { payload: data }) {
        const { jobExperiences } = state.newCandidate;
        const { index, values } = data;
        const item = { ...jobExperiences[index], ...values };
        jobExperiences.splice(index, 1, item);
        state.newCandidate.jobExperiences = [...jobExperiences];
        return { ...state };
      },

      updateNewEducation(state, { payload: data }) {
        const { educations } = state.newCandidate;
        const { index, values } = data;
        const item = { ...educations[index], ...values };
        educations.splice(index, 1, item);
        state.newCandidate.educations = [...educations];
        return { ...state };
      },

      updateNewCertificate(state, { payload: data }) {
        const { certificates } = state.newCandidate;
        const { index, values } = data;
        const item = { ...certificates[index], ...values };
        certificates.splice(index, 1, item);
        state.newCandidate.certificates = [...certificates];
        return { ...state };
      },

      addNewEducation(
        state,
        {
          payload: { limit }
        }
      ) {
        const { educations } = state.newCandidate;
        if (limit && educations.length) {
          const lastEducation = educations[educations.length - 1];
          //每次只能新建一个
          if (/create/.test(lastEducation.education_id)) return state;
        }
        const newEducation = {};
        for (const key of educationFieldnames) {
          newEducation[key] = undefined;
        }
        newEducation.education_id = `create-${new Date().getTime()}`;
        newEducation.isEdit = true;
        educations.push(newEducation);
        state.newCandidate.educations = [...educations];
        return { ...state };
      },
      removeNewEducation(state, { payload: index }) {
        const { educations } = state.newCandidate;
        educations.splice(index, 1);
        state.newCandidate.educations = [...educations];
        return { ...state };
      },

      addNewJobExperience(
        state,
        {
          payload: { limit }
        }
      ) {
        const { jobExperiences } = state.newCandidate;
        if (limit && jobExperiences.length) {
          const lastJobExperience = jobExperiences[jobExperiences.length - 1];
          //每次只能新建一个
          if (/create/.test(lastJobExperience.job_id)) return state;
        }
        const newJobExperience = {};
        newJobExperience.job_id = `create-${new Date().getTime()}`;
        newJobExperience.isEdit = true;
        jobExperiences.push(newJobExperience);
        state.newCandidate.jobExperiences = [...jobExperiences];
        return { ...state };
      },

      removeNewJobExperience(state, { payload: index }) {
        const { jobExperiences } = state.newCandidate;
        jobExperiences.splice(index, 1);
        state.newCandidate.jobExperiences = [...jobExperiences];
        return { ...state };
      },

      removeNewProExperience(state, { payload: index }) {
        const { proExperiences } = state.newCandidate;
        proExperiences.splice(index, 1);
        state.newCandidate.proExperiences = [...proExperiences];
        return { ...state };
      },

      addNewProExperience(
        state,
        {
          payload: { limit }
        }
      ) {
        const { proExperiences } = state.newCandidate;
        if (limit && proExperiences.length) {
          const lastProExperience = proExperiences[proExperiences.length - 1];
          //每次只能新建一个
          if (/create/.test(lastProExperience.project_id)) return state;
        }
        const newProExperience = {};
        newProExperience.project_id = `create-${new Date().getTime()}`;
        newProExperience.isEdit = true;
        proExperiences.push(newProExperience);
        state.newCandidate.proExperiences = [...proExperiences];
        return { ...state };
      },

      addNewCertificate(
        state,
        {
          payload: { limit }
        }
      ) {
        const { certificates } = state.newCandidate;
        if (limit && certificates.length) {
          const lastCertificate = certificates[certificates.length - 1];
          //每次只能新建一个
          if (/create/.test(lastCertificate.cert_id)) return state;
        }
        const newCertificate = {};
        for (const key of certificateFieldnames) {
          newCertificate[key] = undefined;
        }
        newCertificate.cert_id = `create-${new Date().getTime()}`;
        newCertificate.isEdit = true;
        certificates.push(newCertificate);
        state.newCandidate.certificates = [...certificates];
        return { ...state };
      },

      removeNewCertificate(state, { payload: index }) {
        const { certificates } = state.newCandidate;
        certificates.splice(index, 1);
        state.newCandidate.certificates = [...certificates];
        return { ...state };
      },

      updateProExperience(state, { payload: data }) {
        const { proExperiences } = state.candidate.detail;
        const { index, values } = data;
        const item = { ...proExperiences[index], ...values };
        proExperiences.splice(index, 1, item);
        state.candidate.detail.proExperiences = [...proExperiences];
        return { ...state };
      },

      addProExperience(
        state,
        {
          payload: { limit }
        }
      ) {
        const { proExperiences } = state.candidate.detail;
        if (limit && proExperiences.length) {
          const lastProExperience = proExperiences[proExperiences.length - 1];
          //每次只能新建一个
          if (/create/.test(lastProExperience.project_id)) return state;
        }
        const newProExperience = {};
        newProExperience.project_id = `create-${new Date().getTime()}`;
        newProExperience.isEdit = true;
        proExperiences.push(newProExperience);
        state.candidate.detail.proExperiences = [...proExperiences];
        return { ...state };
      },

      cleanProExperience(state, { payload: index }) {
        const { proExperiences } = state.candidate.detail;
        // const cloneJobExperiences = state.candidate.cloneDetail.jobExperiences;
        // cloneJobExperiences.splice(index, 1);
        proExperiences.splice(index, 1);
        state.candidate.detail.proExperiences = [...proExperiences];
        return { ...state };
      },
      cancelProExperience(state, { payload: index }) {
        const { proExperiences } = state.candidate.detail;
        // const cloneJobExperiences = state.candidate.cloneDetail.jobExperiences;
        const proExperience = proExperiences[index];
        //如果是新建
        if (/create/.test(proExperience.project_id)) {
          proExperiences.splice(index, 1);
        } else {
          //编辑
          // const cloneItem = cloneJobExperiences[index];
          const newProExperience = { ...proExperience, isEdit: false };
          proExperiences.splice(index, 1, newProExperience);
        }
        state.candidate.detail.proExperiences = [...proExperiences];
        return { ...state };
      },
      updateDetail(state, { payload: data }) {
        return { ...state, profileDetail: data };
      },
      updateJDs(state, { payload: data }) {
        return { ...state, recordJDs: data };
      },
      setUpdateState(state, { payload }) {
        const { filed, value } = payload;
        state[filed] = value;
        return { ...state };
      },
      setUpdateStateIsSocial(state, { payload: data }) {
        debugger;
        state.IsSocial = data.data;
        return { ...state };
      },
    };
  }
);
