import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { Form, Collapse, Row, Col, Input } from "antd";
import PositionVacantModal from "store/reducers/positionVacantM";
import { EditViewSingle } from "components/EditView";
import * as companyService from "services/companyService";
import CustomIcon from "components/CustomIcon";

const FormItem = Form.Item;

/**
 * 联系人信息
 */

class ContactPersonBox extends PureComponent {
  state = {
    isView: true
  };
  editFormRef = formRef => {
    this.editForm = formRef;
  };
  /**
   * @description 打开或关闭编辑联系人
   */
  isOPen = () => {
    this.setState({ isView: !this.state.isView });
  };
  /**
   * @description 编辑保存联系人信息
   */

  onSave = () => {
    const form = this.editForm.props.form;
    const { modPos, positionDetail = {}, getDetail } = this.props;
    const { company = {}, contactor = {} } = positionDetail;
    form.validateFields((err, values) => {
      if (err) {
        return;
      }
      values = {
        company_id: company.id,
        company_contact_id: contactor.id,
        ...values
      };
      companyService.modContact(values).then(res => {
        if (res.code == 0) {
          this.setState({ isView: true });
          getDetail(positionDetail.id);
          form.resetFields();
        }
      });
    });
  };
  render() {
    const { positionDetail = {}, isAll, roles } = this.props;
    return (
      <div className="position-card-contact">
        <EditViewSingle
          title={
            <span
              style={{
                fontSize: 14
              }}
            >
              <CustomIcon
                type="icon-lianxiren"
                style={{
                  fontSize: 20,
                  verticalAlign: "bottom",
                  paddingRight: "7px"
                }}
              />
              <span style={{ lineHeight: "19px" }}>联系人</span>
            </span>
          }
          isView={this.state.isView}
          onEdit={this.isOPen}
          onCancel={this.isOPen}
          onSave={this.onSave}
          isExpand={false}
          disabledEdit={
            positionDetail.status === 3 ||
            positionDetail.status === 4 ||
            positionDetail.status === 5 ||
            ((roles === "consultant" || roles === "consultant360" || roles === "channel") && isAll === true)
          }
          className="drawer-card "
        >
          <div role="view" className="view" style={{ padding: 0 }}>
            <ContactPersonShow />
          </div>
          <div role="edit" className="edit">
            <ContactPersonEdit wrappedComponentRef={this.editFormRef} />
          </div>
        </EditViewSingle>

        <style jsx global>
          {`
            .position-card-contact {
              .ant-collapse-content-box {
                padding: 0;
              }
            }
          `}
        </style>
      </div>
    );
  }
}

const ContactPerson = Form.create()(ContactPersonBox);
export default connect(
  ({ PositionVacantModal, user }) => {
    return { ...PositionVacantModal, roles: user.roles };
  },
  {
    ...PositionVacantModal.actions
  }
)(ContactPerson);

const ContactPersonEdit = Form.create()(
  @connect(
    ({ PositionVacantModal }) => {
      return { ...PositionVacantModal };
    },
    {
      ...PositionVacantModal.actions
    }
  )
  class ContactPersonForm extends PureComponent {
    render() {
      const { positionDetail = {}, detailWidthStatus } = this.props;
      const { getFieldDecorator } = this.props.form;
      const size = "large";
      const formItemLayout = {
        labelCol: { span: 7 },
        wrapperCol: { span: 17 }
      };
      const colItemLayout = {
        span: detailWidthStatus ? 12 : 24
      };
      return (
        <React.Fragment>
          <div className="contact-edit-box">
            <Row gutter={5}>
              <Col {...colItemLayout} style={{ height: 50 }}>
                <FormItem label="名字" {...formItemLayout}>
                  {getFieldDecorator("contactor", {
                    initialValue: (positionDetail.contactor || {}).contactor,
                    rules: [{ required: false }]
                  })(<Input placeholder="请输入" size={size} />)}
                </FormItem>
              </Col>
              <Col {...colItemLayout} style={{ height: 50 }}>
                <FormItem label="职位" {...formItemLayout}>
                  {getFieldDecorator("position", {
                    initialValue: (positionDetail.contactor || {}).position,
                    rules: [{ required: false }]
                  })(<Input placeholder="请输入" size={size} />)}
                </FormItem>
              </Col>
              <Col {...colItemLayout} style={{ height: 50 }}>
                <FormItem label="手机" {...formItemLayout}>
                  {getFieldDecorator("phone", {
                    initialValue: (positionDetail.contactor || {}).phone,
                    rules: [{ required: false }]
                  })(<Input placeholder="请输入" size={size} />)}
                </FormItem>
              </Col>
              <Col {...colItemLayout} style={{ height: 50 }}>
                <FormItem label="邮箱" {...formItemLayout}>
                  {getFieldDecorator("email", {
                    initialValue: (positionDetail.contactor || {}).email,
                    rules: [{ required: false }]
                  })(<Input placeholder="请输入" size={size} />)}
                </FormItem>
              </Col>
              <Col {...colItemLayout} style={{ height: 50 }}>
                <FormItem label="工作电话" {...formItemLayout}>
                  {getFieldDecorator("office_number", {
                    initialValue: (positionDetail.contactor || {})
                      .office_number,
                    rules: []
                  })(
                    <Input
                      type="text"
                      size={size}
                      placeholder="请输入工作电话"
                    />
                  )}
                </FormItem>
              </Col>
            </Row>
          </div>
        </React.Fragment>
      );
    }
  }
);
@connect(
  ({ PositionVacantModal }) => {
    return { ...PositionVacantModal };
  },
  {
    ...PositionVacantModal.actions
  }
)
class ContactPersonShow extends PureComponent {
  render() {
    const { positionDetail = {} } = this.props;
    return (
      <React.Fragment>
        <div className="contact-box collapse-handle-box">
          <div className="name">
            <b>
              {(positionDetail.contactor || {}).contactor}
              {(positionDetail.contactor || {}).position && (
                <span>（{(positionDetail.contactor || {}).position}）</span>
              )}
            </b>
          </div>
          <div className="contact-info">
            {(positionDetail.contactor || {}).phone && (
              <div>
                <svg className="icon" aria-hidden="true">
                  <use xlinkHref="#icon-tablet" />
                </svg>
                <span className="info">
                  {(positionDetail.contactor || {}).phone}
                </span>
              </div>
            )}
            {(positionDetail.contactor || {}).email && (
              <div>
                <svg className="icon" aria-hidden="true">
                  <use xlinkHref="#icon-mail" />
                </svg>
                <span className="info">
                  {(positionDetail.contactor || {}).email}
                </span>
              </div>
            )}
            {(positionDetail.contactor || {}).office_number && (
              <div>
                <CustomIcon type="icon-dianhua" style={{ fontSize: 16 }} />
                <span className="info">
                  {(positionDetail.contactor || {}).office_number}
                </span>
              </div>
            )}
          </div>
        </div>
        <style jsx global>
          {`
            .contact-box {
              padding:0 20px;
              .icon {
                width: 1em;
                height: 1em;
                vertical-align: -0.15em;
                fill: currentColor;
                overflow: hidden;
                margin-right: 10px;
              }
              .ant-collapse > .ant-collapse-item > .ant-collapse-header {
                color: #333333;
                font-size: 16px;
                font-weight: 600;
              }
              b {
                font-size: 16px;
                margin: 8px 0 15px 0;
                display: inline-block;
              }
              .contact-info {
                color: #666666;
                i {
                  margin-right: 9px;
                }
                div {
                  margin-bottom: 10px;
                }
              }
            }
          `}
        </style>
      </React.Fragment>
    );
  }
}
