import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { Collapse, Upload, Icon } from "antd";
import internModel from "store/reducers/internModel";
import CustomIcon from "components/CustomIcon";

const Dragger = Upload.Dragger;
const Panel = Collapse.Panel;

export default class ResumeAffixUpload extends PureComponent {
  // onUploadResumeClick = e => {
  //   e.stopPropagation();
  //   this.props.updateResumeImport({ visible: true });
  // };
  isHtml = resumeAttachment => {
    return resumeAttachment && resumeAttachment.indexOf(".html") !== -1;
  };

  render() {
    const { protocol, host } = window.location;
    const { resumeAttachment, candidateId } = this.props;
    return (
      <React.Fragment>
        <Collapse
          defaultActiveKey={["1"]}
          className="collapse-handle-box resume-affix-empty"
        >
          <Panel
            header={
              <React.Fragment>
                <span>简历附件</span>
                {/* <a className="right" onClick={this.onUploadResumeClick}>
                    上传附件
                  </a> */}
              </React.Fragment>
            }
            key="1"
          >
            {!(resumeAttachment && resumeAttachment.length !== 0) ? (
              <div className="resume-affix-empty-wrapper">
                <img src={require("assets/imgs/empty.png")} />
                <p>暂无简历附件</p>
              </div>
            ) : (
              <div className="file-list">
                {resumeAttachment.map(item => {
                  return (
                    <div>
                      <a
                        target="_blank"
                        href={item.patch}
                      >
                        <CustomIcon type="icon-accessory"/>{item.name}
                      </a>
                      <span className="tip">{item.created_time} {item.created_by} 更新</span>
                    </div>
                  );
                })}
              </div>
            )}
          </Panel>
        </Collapse>
        {/* this.isHtml() ? (
              <iframe
                frameborder="0"
                src={`${protocol}//${host}${resumeAttachment}`}
                width="100%"
                height="100%"
              />
            ) : (            )*/}
        <style jsx global>
          {`
            .resume-affix-empty {
              .ant-collapse-content-box {
                min-height: 520px;
                .resume-affix-empty-wrapper {
                  text-align: center;
                }
                & > div {
                  margin-top: 108px;
                  p {
                    margin-top: 20px;
                    color: #999;
                  }
                }
                .file-list {
                  margin-top: 0;
                  line-height: 2.5;
                  .tip {
                    margin-left: 5px;
                    color: #999999;
                  }
                }
              }
            }
          `}
        </style>
      </React.Fragment>
    );
  }
}
