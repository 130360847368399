import xhr from "./index.js";

/**
 * 数据中心
 */
//数据简报

function dataBrief(param) {
  return xhr({
    url: "admin/v1/data-center/briefing",
    body: param,
    method: "POST"
  });
}
//运营数据
function dataOperate(param) {
  return xhr({
    url: "admin/v1/data-center/operational-data",
    body: param,
    method: "GET"
  });
}
//商机数据
function dataBusiness(param) {
  return xhr({
    url: "admin/v1/data-center/business-data",
    body: param,
    method: "GET"
  });
}
//顾问数据
function dataCounselor(param) {
  return xhr({
    url: "admin/v1/data-center/hh-data",
    body: param,
    method: "GET"
  });
}
//业绩数据
function dataperformance(param) {
  return xhr({
    url: "admin/v1/data-center/performance-data",
    body: param,
    method: "POST"
  });
}
//weekly数据
function weeklyList(param) {
  return xhr({
    url: "admin/v1/data-center/weekly",
    body: param,
    method: "POST"
  });
}
//职位候选人(weekly和业绩简报)
function positionProfile(param) {
  return xhr({
    url: "admin/v1/data-center/performance-jd-profile",
    body: param,
    method: "GET"
  });
}
//业绩简报职位
function performancePosition(param) {
  return xhr({
    url: "admin/v1/data-center/performance-jd",
    body: param,
    method: "GET"
  });
}
//业绩简跟进人选
function performanceProfile(param) {
  return xhr({
    url: "admin/v1/data-center/performance-profile",
    body: param,
    method: "GET"
  });
}
//业绩简跟明细
function performanceDetails(param) {
  return xhr({
    url: "admin/v1/data-center/performance-details",
    body: param,
    method: "GET"
  });
}
export {
  dataBrief,
  dataOperate,
  dataBusiness,
  dataCounselor,
  dataperformance,
  weeklyList,
  positionProfile,
  performancePosition,
  performanceProfile,
  performanceDetails
};
