import React from "react";
import { connect } from "react-redux";
import { Row, Col, Timeline, Modal, Icon, Spin } from "antd";
import styles from "./profile_detail.scss";
import socialCandModel from "store/reducers/socialCandModel";

const TimelineItem = Timeline.Item;

function createMarkup(html = "") {
  if (!html) html = "";
  return { __html: html.replace(/\r\n|\r|\n/g, "<br/>") };
}

// 教育经历
class EduRecord extends React.PureComponent {
  render() {
    const { profileDetail } = this.props;
    if (!profileDetail.edu || profileDetail.edu.length == 0) {
      return null;
    }
    return (
      <div className="profile-section">
        <div className="section-title">
          <img
            className="section icon icon-edu"
            src={require("assets/imgs/profile/icon-edu.png")}
          />
          <span className="section title">教育经历</span>
          <span className="section line" />
        </div>
        <Timeline className="record-timeline" mode="alternate">
          {profileDetail.edu &&
            profileDetail.edu.map((item, index) => {
              return (
                <TimelineItem key={index}>
                  <div className="record">
                    <div className="record-arrow" />
                    <div className="record-info">
                      <div className="record-time">
                        {item.begin_time}-{item.end_time}
                      </div>
                      <div
                        className="record-school ellipsis"
                        title={item.school_name}
                      >
                        {item.school_name}
                      </div>
                    </div>
                    <div className="record-type">
                      <div className="record-from">{item.is_unified}</div>
                      <div className="record-major">
                        专业：{item.major_name}
                      </div>
                    </div>
                  </div>
                </TimelineItem>
              );
            })}
        </Timeline>
      </div>
    );
  }
}
// 项目经历
class ProjectRecord extends React.PureComponent {
  render() {
    const { profileDetail } = this.props;
    if (!profileDetail.project || profileDetail.project.length == 0) {
      return null;
    }
    return (
      <div className="profile-section">
        <div className="section-title">
          <img
            className="section icon icon-project"
            src={require("assets/imgs/profile/icon-project.png")}
          />
          <span className="section title">项目经历</span>
          <span className="section line" />
        </div>
        <div className="work-timeline">
          {profileDetail.project &&
            profileDetail.project.map((item, index) => {
              return (
                <div className="work-line school-work" key={index}>
                  <div className="work-company">
                    <img
                      className="section icon icon-folder"
                      src={require("assets/imgs/profile/icon-folder.png")}
                    />
                    {item.project_name}
                  </div>
                  <div className="work-time">
                    {item.begin_time}-{item.end_time}
                    {item.range ? ` (${item.range})` : ""}
                  </div>
                  <div className="work-info">
                    {item.company_name ? `所属公司：${item.company_name}` : ""}
                  </div>
                  {item.description && (
                    <React.Fragment>
                      <div className="work-title">项目描述：</div>
                      <div
                        className="work-duty"
                        dangerouslySetInnerHTML={createMarkup(item.description)}
                      />
                    </React.Fragment>
                  )}
                  {item.duty && (
                    <React.Fragment>
                      <div className="work-title">责任描述：</div>
                      <div
                        className="work-duty"
                        dangerouslySetInnerHTML={createMarkup(item.duty)}
                      />
                    </React.Fragment>
                  )}
                </div>
              );
            })}
        </div>
      </div>
    );
  }
}
// 工作经历
class WorkRecord extends React.PureComponent {
  render() {
    const { profileDetail } = this.props;
    if (!profileDetail.job || profileDetail.job.length == 0) {
      return null;
    }
    return (
      <div className="profile-section">
        <div className="section-title">
          <img
            className="section icon icon-experience"
            src={require("assets/imgs/profile/icon-experience.png")}
          />
          <span className="section title">工作经历</span>
          <span className="section line" />
        </div>
        <Timeline
          className="work-record-timeline record-timeline"
          mode="alternate"
        >
          {profileDetail.job &&
            profileDetail.job.map((item, index) => {
              return (
                <TimelineItem key={index}>
                  <div className="record">
                    <div className="record-arrow" />
                    <div className="record-info">
                      <div className="record-time">
                        {item.begin_time}-{item.end_time}
                      </div>
                      <div
                        className="record-school ellipsis"
                        title={item.company_name}
                      >
                        {item.company_name}
                      </div>
                    </div>
                  </div>
                </TimelineItem>
              );
            })}
        </Timeline>
        <div className="work-timeline">
          {profileDetail.job &&
            profileDetail.job.map((item, index) => {
              return (
                <div className="work-line" key={index}>
                  <div className="work-company">
                    <img
                      className="icon icon-company"
                      src={require("assets/imgs/profile/icon-company.png")}
                    />
                    {item.company_name}
                    {item.company_scale && `（${item.company_scale}）`}
                  </div>
                  <div className="work-time">
                    {item.begin_time}-{item.end_time}（{item.range}）
                  </div>
                  <div className="work-info">
                    {item.department ? ` 部门：${item.department}` : ""}
                    {item.position ? ` 丨 职位：${item.position}` : ""}
                  </div>
                  {item.duty && (
                    <React.Fragment>
                      <div className="work-title">工作职责：</div>
                      <div
                        className="work-duty"
                        dangerouslySetInnerHTML={createMarkup(item.duty)}
                      />
                    </React.Fragment>
                  )}
                </div>
              );
            })}
        </div>
      </div>
    );
  }
}
// 在校实践
class SchoolPractice extends React.PureComponent {
  render() {
    const { profileDetail } = this.props;
    if (!profileDetail.campus || profileDetail.campus.length == 0) {
      return null;
    }
    return (
      <div className="profile-section">
        <div className="section-title">
          <img
            className="section icon icon-practice"
            src={require("assets/imgs/profile/icon-practice.png")}
          />
          <span className="section title">校园实践</span>
          <span className="section line" />
        </div>
        <div className="work-timeline">
          {profileDetail.campus &&
            profileDetail.campus.map(item => {
              return (
                <div className="work-line campus" key={item.id}>
                  <div className="work-company">
                    <img
                      className="icon icon-campus"
                      src={require("assets/imgs/profile/icon-campus.png")}
                    />
                    {item.name}
                  </div>
                  <div className="work-time">
                    {item.begin_time}-{item.end_time}
                  </div>
                  <div className="work-info">
                    {item.province || item.city
                      ? `城市：${item.province}${item.city}`
                      : ""}
                    {item.position ? `丨 职位：${item.position}` : ""}
                  </div>
                  {item.desc && (
                    <React.Fragment>
                      <div className="work-title">实践描述：</div>
                      <div
                        className="work-duty"
                        dangerouslySetInnerHTML={createMarkup(item.desc)}
                      />
                    </React.Fragment>
                  )}
                </div>
              );
            })}
        </div>
      </div>
    );
  }
}
// 实习经历
class SchoolWork extends React.PureComponent {
  render() {
    const { profileDetail } = this.props;
    if (!profileDetail.intern || profileDetail.intern.length == 0) {
      return null;
    }
    return (
      <div className="profile-section">
        <div className="section-title">
          <img
            className="section icon icon-experience"
            src={require("assets/imgs/profile/icon-experience.png")}
          />
          <span className="section title">实习经历</span>
          <span className="section line" />
        </div>
        <div className="work-timeline">
          {profileDetail.intern &&
            profileDetail.intern.map(item => {
              return (
                <div className="work-line school-work" key={item.id}>
                  <div className="work-company">
                    <img
                      className="icon icon-company"
                      src={require("assets/imgs/profile/icon-company.png")}
                    />
                    {item.company}
                    {item.company_scale ? `（${item.company_scale}）` : ""}
                  </div>
                  <div className="work-time">
                    {item.begin_time}-{item.end_time}
                  </div>
                  <div className="work-info">
                    {item.province || item.city
                      ? `城市：${item.province}${item.city}`
                      : ""}
                    {item.position ? `丨 职位：${item.position}` : ""}
                  </div>
                  {item.work_desc && (
                    <React.Fragment>
                      <div className="work-title">实习描述：</div>
                      <div
                        className="work-duty"
                        dangerouslySetInnerHTML={createMarkup(item.work_desc)}
                      />
                    </React.Fragment>
                  )}
                </div>
              );
            })}
        </div>
      </div>
    );
  }
}
// 实习生期望
class SchoolIntention extends React.PureComponent {
  render() {
    const {
      profileDetail: { job_inten = {} }
    } = this.props;
    if (
      !job_inten.expected_position &&
      !job_inten.intern_time &&
      !job_inten.forward_city &&
      !job_inten.weekly_working &&
      !job_inten.expect_daily_salary &&
      !job_inten.available
    ) {
      return null;
    }
    return (
      <div className="profile-section help-section school-intention">
        <div className="section-title">
          <img
            className="section icon icon-intention"
            src={require("assets/imgs/profile/icon-intention.png")}
          />
          <span className="section title">求职意向</span>
          <span className="section line" />
        </div>
        <Row className="find-to first">
          {job_inten.expected_position && (
            <Col span={12}>期望职位：{job_inten.expected_position}</Col>
          )}
          {job_inten.intern_time && (
            <Col span={12}>实习时间：{job_inten.intern_time}</Col>
          )}
          {job_inten.forward_city && (
            <Col span={12}>期望城市：{job_inten.forward_city}</Col>
          )}
          {job_inten.weekly_working && (
            <Col span={12}>实习天数：{job_inten.weekly_working}/周</Col>
          )}
          {job_inten.expect_daily_salary && (
            <Col span={12}>期望日薪：{job_inten.expect_daily_salary}元</Col>
          )}
          {job_inten.available && (
            <Col span={12}>到岗时间：{job_inten.available}</Col>
          )}
        </Row>
      </div>
    );
  }
}
// 顾问评价
class EvaluateRecord extends React.PureComponent {
  render() {
    const {
      profileDetail: { hh_appraisal = {} }
    } = this.props;
    if (
      !hh_appraisal.avatar &&
      !hh_appraisal.name &&
      !hh_appraisal.update_time &&
      !hh_appraisal.appraisal
    ) {
      return null;
    }
    return (
      <div className="profile-section">
        <div className="section-title">
          <img
            className="section icon icon-evaluate"
            src={require("assets/imgs/profile/icon-evaluate.png")}
          />
          <span className="section title">顾问评价</span>
          <span className="section line" />
        </div>
        <div className="help-ev">
          {/* {profileDetail.hh_appraisal && profileDetail.hh_appraisal.map((item, index) => {
            return ( */}
          <div className="help-item">
            <div className="helper">
              <img
                src={
                  hh_appraisal.avatar
                    ? hh_appraisal.avatar
                    : require("assets/imgs/profile/icon-guwen.png")
                }
              />
              <span>{hh_appraisal.name}</span>
              <span className="right">{hh_appraisal.update_time}</span>
            </div>
            <div className="ev-word">
              <div className="ev-arrow" />
              <div
                dangerouslySetInnerHTML={createMarkup(hh_appraisal.appraisal)}
              />
            </div>
          </div>
          {/* )
          })} */}
        </div>
      </div>
    );
  }
}
// 求职意向
class IntentionRecord extends React.PureComponent {
  render() {
    const {
      profileDetail: { job_inten = [] }
    } = this.props;
    if (!job_inten[0] && !job_inten[1]) {
      return null;
    }
    return (
      <div className="profile-section help-section">
        <div className="section-title">
          <img
            className="section icon icon-intention"
            src={require("assets/imgs/profile/icon-intention.png")}
          />
          <span className="section title">求职意向</span>
          <span className="section line" />
        </div>
        {job_inten[0] && (
          <div className="find-to">
            离职原因：
            <span dangerouslySetInnerHTML={createMarkup(job_inten[0])} />
          </div>
        )}
        {job_inten[1] && (
          <div className="find-to">
            公司期望：
            <span dangerouslySetInnerHTML={createMarkup(job_inten[1])} />
          </div>
        )}
      </div>
    );
  }
}
// 证书
class CertifyRecord extends React.PureComponent {
  render() {
    const { profileDetail } = this.props;
    if (!profileDetail.skills || profileDetail.skills.length == 0) {
      return null;
    }
    return (
      <div className="profile-section">
        <div className="section-title">
          <img
            className="section icon icon-certify"
            src={require("assets/imgs/profile/icon-certify.png")}
          />
          <span className="section title">证书及认证</span>
          <span className="section line short" />
        </div>
        <div className="certi-block">
          {profileDetail.skills &&
            profileDetail.skills.map((item, index) => {
              return (
                <div className="certi" key={index}>
                  <span>{item.name}</span>
                  <span className="right">{item.ext}</span>
                </div>
              );
            })}
        </div>
      </div>
    );
  }
}

export default class ProfileDetail extends React.PureComponent {
  state = { loading: false };

  componentDidUpdate(prevProps) {
    const { visible } = prevProps;
    if (this.props.visible !== visible && this.props.visible === true) {
      const { getProfileDetail, candId } = this.props;
      this.setState({ loading: true });
      getProfileDetail({
        profile_id: candId
      }).then(res => {
        this.setState({ loading: false });
        if (res.code != 0 && res.code != 5112) {
          Modal.error({
            title: "错误提示",
            content: res.data
          });
        }
      });
    }
  }

  onClose = () => {
    this.props.onChange(false);
  };

  altEvent = event => {
    console.log("event", event.altKey);
    if (event.altKey) {
      this.props.onChange(true);
    }
  };

  altEventUp = event => {
    if (!event.altKey) {
      this.props.onChange(false);
    }
  };

  // componentDidMount() {
  //   document.addEventListener("keydown", this.altEvent, false);
  //   document.addEventListener("keyup", this.altEventUp, false);
  // }

  // componentWillUnmount() {
  //   document.removeEventListener("keydown", this.altEvent);
  //   document.removeEventListener("keyup", this.altEventUp);
  // }

  render() {
    const {
      profileDetail: { is_intern },
      profileDetail,
      visible,
      onChange
    } = this.props;
    // default candidate avatar handle
    let imgSrc = require("assets/imgs/man.jpg");
    if (profileDetail.picture) {
      imgSrc = profileDetail.picture;
    } else if (profileDetail.is_intern === 0) {
      imgSrc =
        profileDetail.sex == "男"
          ? require("assets/imgs/man.jpg")
          : require("assets/imgs/woman.jpg");
    } else if (profileDetail.is_intern === 1) {
      imgSrc =
        profileDetail.sex == "男"
          ? require("assets/imgs/student-man.jpg")
          : require("assets/imgs/student-woman.jpg");
    }
    // first base info
    let baseFirsts = [];
    profileDetail.age && baseFirsts.push(profileDetail.age + "岁");
    profileDetail.sex && baseFirsts.push(profileDetail.sex);
    profileDetail.education && baseFirsts.push(profileDetail.education);
    if (!is_intern) {
      profileDetail.worktime &&
        baseFirsts.push(profileDetail.worktime + "年经验");
    } else {
      profileDetail.school && baseFirsts.push(profileDetail.school);
    }
    profileDetail.forward_city && baseFirsts.push(profileDetail.forward_city);
    // second base info
    let baseSeconds = [];
    if (!is_intern) {
      profileDetail.marry && baseSeconds.push(profileDetail.marry);
      profileDetail.salary_expect &&
        baseSeconds.push("期望薪资" + profileDetail.salary_expect + "K/月");
    } else {
      profileDetail.graduation_time &&
        baseSeconds.push(profileDetail.graduation_time + "毕业");
    }
    return (
      <div
        className="profile-detail-layout"
        style={{ display: visible ? "block" : "none" }}
      >
        <div className="profile-detail">
          <div className="detail-header clear">
            <span className="function"> 简历预览</span>
            <Icon type="close" className="right" onClick={this.onClose} />
          </div>
          <Spin spinning={this.state.loading}>
            <div className="detail-content">
              <div className="base-info">
                <img src={imgSrc} />
                <div className="profile">
                  <div className="profile-name">
                    <span className="name">
                      {profileDetail.name}
                      {profileDetail.position_name && " · "}
                    </span>
                    <span className="name">{profileDetail.position_name}</span>
                  </div>
                  <div className="profile-base">
                    <span>{baseFirsts.join(" · ")}</span>
                  </div>
                  <div className="profile-other">
                    <span>{baseSeconds.join(" · ")}</span>
                  </div>
                </div>
              </div>
              <Row gutter={68}>
                <Col span={12}>
                  {is_intern ? (
                    <React.Fragment>
                      <EduRecord profileDetail={profileDetail} />
                      <SchoolPractice profileDetail={profileDetail} />
                      <CertifyRecord profileDetail={profileDetail} />
                      <SchoolIntention profileDetail={profileDetail} />
                      <EvaluateRecord profileDetail={profileDetail} />
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <WorkRecord profileDetail={profileDetail} />
                    </React.Fragment>
                  )}
                </Col>
                <Col span={12}>
                  {is_intern ? (
                    <React.Fragment>
                      <SchoolWork profileDetail={profileDetail} />
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <EduRecord profileDetail={profileDetail} />
                      <ProjectRecord profileDetail={profileDetail} />
                      <IntentionRecord profileDetail={profileDetail} />
                      <CertifyRecord profileDetail={profileDetail} />
                      <EvaluateRecord profileDetail={profileDetail} />
                    </React.Fragment>
                  )}
                </Col>
              </Row>
            </div>
          </Spin>
        </div>
        <style jsx global>
          {styles}
        </style>
      </div>
    );
  }
}
