import Model from "../Model";
import {
positionList,
positionAllotManage
} from "services/positionS";
export default Model.getInstance(
  class extends Model {
    namespace = "PositionAllotModal";
    state = {
      positionData: {},
    };

    actions = {
      async getPositionList(param) {
        const expand = "position_name,company,contactor,province_name,city_name,region_name,status_name,start_time_str,progress_days,jd_profile_nums,source_name,created_by_name,created_by_org_name,hh_name,hh_org_name,already_reserved_num,jd_address";
          let result = await positionList({ ...param,type:2, expand });
        if (result) {
          this.dispatch({
            type: "PositionAllotModal/setUpdateState",
            payload: {filed:"positionData",value:result.data}
          });
        }
      },
      async positionAllot(param) {
        let result = await positionAllotManage({ ...param });
        return result;
      },
    };

    reducers = {
      setUpdateState(state, { payload }) {
        const { filed, value } = payload;
        state[filed] = value;
        return { ...state };
      },
    };
  }
);
