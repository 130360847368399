import Model from "../Model";
import * as candidateAuditService from "services/candidateAudit";

export default Model.getInstance(
  class extends Model {
    namespace = "candidateAudit";

    state = {
      auditList: {},
      auditListLoading: false,
      auditResult: 1,
      rejectReason: "",
      selectedIds: [],
      auditModel: false
    };

    actions = {
      async getAuditList(param) {
        this.dispatch({
          type: "candidateAudit/updateLoading",
          payload: true
        });

        const result = await candidateAuditService.getAuditList(param);
        if (result.code === 0) {
          this.dispatch({
            type: "candidateAudit/updateAuditList",
            payload: result.data
          });
        }
        this.dispatch({
          type: "candidateAudit/updateLoading",
          payload: false
        });
      },

      async audit(id) {
        const {
          selectedIds,
          auditResult,
          rejectReason
        } = this.getState().candidateAudit;

        const result = await candidateAuditService.batchAudit({
          audit_ids: id ? [id] : selectedIds,
          audit_status: auditResult,
          audit_remark: rejectReason
        });

        if (result.code === 0) {
          this.dispatch({
            type: "candidateAudit/updateAuditModel",
            payload: false
          });
          this.dispatch({
            type: "candidateAudit/updateAuditResult",
            payload: { auditResult: 1, rejectReason: "", selectedIds: [] }
          });
        }
        return result;
      }
    };

    reducers = {
      updateAuditList(state, { payload: list }) {
        return { ...state, auditList: list };
      },
      updateLoading(state, { payload: loadding }) {
        return { ...state, auditListLoading: loadding };
      },
      updateSelectedIds(state, { payload: selectedIds }) {
        return { ...state, selectedIds };
      },
      updateAuditResult(state, { payload: data }) {
        return { ...state, ...data };
      },
      updateAuditModel(state, { payload: auditModel }) {
        return { ...state, auditModel };
      }
    };
  }
);
