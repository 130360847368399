import React, { Component } from "react";
import { Cascader } from "antd";

class CityCascade extends Component {
  state = {
    popupVisible: false, // 级联浮层显隐
  };

  currentLevel = 0;     // 当前层级
  hasChange = false;
  myRef = React.createRef();

  componentDidMount() {
    this.setState({
      cityValue: this.props.value
    })
  }

  componentDidUpdate(prevProps) {
    if(prevProps.value != this.props.value && !this.props.value) {
      this.setState({
        cityValue: undefined
      })
    }
    if(prevProps.value != this.props.value) {
      this.setState({
        cityValue: this.props.value
      })
    }
  }

  cityChange = (value, selectedOptions) => {
    const { onChange } = this.props;
    this.currentLevel = selectedOptions.length;
    console.log("dsddddddd")
    // 第一级的时候不能变更值
    if (this.currentLevel != 1 && onChange) {
      onChange(value, selectedOptions);
      this.setState({
        cityValue: value
      })
    }
    this.hasChange = true;
    console.log(":change", value)
  }

  popupVisibleChange = (visible, selectedOptions) => {
    console.log("visible change", visible, selectedOptions)
    // 第一级的时候不能关闭
    let currentVisible = this.currentLevel == 1 && this.hasChange ? true : visible;
    // 未选中要清空
    if (!currentVisible && !selectedOptions) {
      this.setState({
        popupVisible: currentVisible,
      });
      this.myRef.current.setState({inputValue: "", inputFocused: currentVisible});
      return;
    }
    this.setState({
      popupVisible: currentVisible
    }, () => {
      this.hasChange = false;
    })
  }

  render() {
    const { onChange, value, normalStatus, ...props,} = this.props;
    return (
      <React.Fragment>
        <Cascader
          {...props}
          ref={this.myRef}
          changeOnSelect={!normalStatus}
          expandTrigger={props.expandTrigger||"hover"}
          popupVisible={this.state.popupVisible}
          onPopupVisibleChange={this.popupVisibleChange}
          onChange={this.cityChange}
          value={this.state.cityValue}
          notFoundContent="没有找到相关选项"
          showSearch
        />
      </React.Fragment>
    );
  }
}

export default CityCascade;
